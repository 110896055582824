import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "@lodash";
import {
  Typography,
  Card,
  IconButton,
  Icon,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import MenuItemDetailModal from "./MenuItemDetailModalV2";
import { makeStyles } from "@material-ui/styles";
import ExtraCategoriesModal from "./ExtraCategoriesModalV2";
import ExtraCategoryItems from "../menuItemV2List/CategoriesItemsV2/ExtraCategoryItemsV2";
import ExtraCategory from "./components/ExtraCategoryV2";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl, getSortedPositions } from "app/helpers/utilsHelper";
import * as Actions from "app/store/actions";
import AlertDialog from "app/main/common/AlertDialog";
import { setDefaultLanguageV2 } from "app/helpers/utilsHelper";
import {
  deleteMenuV2Item,
  updateMenuItemVisibilityV2,
  sortCatalogGroupModifier,
} from "app/store/menuV2/MenuV2Actions";
import VisibilityBanner from "app/main/common/VisibilityBanner";
import {
  deleteCatalogCombo,
  updateCatalogCombo,
} from "app/store/catalogue/CatalogueActions";
import { updateComboAvailability } from "app/store/combos/CombosActions";
import AddComboModal from "../AddComboModal";
import useClientCurrency from "app/hooks/useClientCurrency";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ProductItem = ({
  menuItem,
  extraCategories,
  index,
  onlyRead,
  currentVenues,
  isFromComboCategory = false,
  onProductPositionChange,
  length,
  onExtraCategoryChange,
}) => {
  const modalRef = useRef(document.createElement("div"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { locale } = useSelector(({ banzzu }) => banzzu.auth.user);
  const menuDetail = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  const [open, setOpen] = useState(false);
  const [openVisibility, setOpenVisibility] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [categorySelected, setCategorySelected] = useState({ _id: "new" });
  const [list, setList] = useState([]);
  const defaultLanguage = setDefaultLanguageV2(locale);
  const getInitialSelectedLanguage = () => defaultLanguage.code;
  const [selectedLanguage, setSelectedLanguage] = useState(
    getInitialSelectedLanguage
  );
  const { languageStrings } = useLanguageHelper();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openComboModal, setOpenComboModal] = useState(false);
  const isCombo = menuItem.comboId;
  const comboData = isCombo ? menuItem.comboId : {};
  const lastIndex = length - 1;
  const { clientCurrency } = useClientCurrency();
  useEffect(() => {
    //dispatch(getMenuItemExtraCategories(menuItem._id));
  }, [menuItem]);
  useEffect(() => {
    setList(extraCategories);
  }, [extraCategories]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const onVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  const onVisibilityClose = () => {
    setOpenVisibility(!openVisibility);
    setAnchorEl(null);
  };
  const executeScroll = (r) => {
    modalRef.current.scrollTo({ top: r.offsetTop, behavior: "smooth" });
  };

  const handleAddClick = (r) => {
    executeScroll(r);
  };
  let imageUrl;

  if (comboData.image) {
    imageUrl = formatImageUrl(comboData.image);
  } else if (menuItem.image) {
    imageUrl = formatImageUrl(menuItem.image);
  } else {
    imageUrl = "assets/images/avatars/noimage.png";
  }

  const onExtraCategoryPositionChange = (action, extraCategory) => {
    if (extraCategory._id) {
      const updatedCategories = [];
      list.forEach((item, i) => {
        updatedCategories[i] = item;
      });
      const { updatedPositions, array } = getSortedPositions(
        action,
        extraCategory._id,
        updatedCategories
      );
      onExtraCategoryChange ? onExtraCategoryChange(array) : setList(array);
      dispatch(
        sortCatalogGroupModifier({
          updatedPositions,
        })
      );
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className={`${classes.paper} overflow-scroll max-h-screen flex flex-col`}
          >
            <h2 id="transition-modal-title" className="pb-32 flex">
              {menuItem.title}
            </h2>
            <MenuItemDetailModal
              productId={menuItem._id}
              categoryId={menuItem.categoryId}
              menuId={menuItem.menuId}
              onClose={() => {
                setOpen(false);
              }}
              currentVenues={currentVenues}
              isCombo={isFromComboCategory}
            />
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={openCategories}
        onClose={() => {
          setOpenCategories(false);
          setSelectedLanguage(getInitialSelectedLanguage());
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCategories}>
          <div
            className={["overflow-x-hidden max-h-640", classes.paper].join(" ")}
            ref={modalRef}
          >
            <h2 id="transition-modal-title" className="pb-32">
              {languageStrings["EXTRA_OPTIONS"]}
            </h2>
            <ExtraCategoriesModal
              menuItem={menuItem}
              category={categorySelected}
              selectedLanguage={selectedLanguage}
              handleLanguageChange={handleLanguageChange}
              onClose={() => setOpenCategories(false)}
            />
            {categorySelected._id !== "new" && (
              <ExtraCategoryItems
                category={categorySelected._id}
                selectedLanguage={selectedLanguage}
                onAddClick={handleAddClick}
                isCombo={isFromComboCategory}
              />
            )}
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openComboModal}
        onClose={() => {
          setOpenComboModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openComboModal}>
          <div
            className={`${classes.paper} md:h-auto h-screen overflow-scroll`}
          >
            <h2 id="transition-modal-title" className="pb-32">
              {languageStrings["SELECT_COMBO"]}
            </h2>
            <AddComboModal
              comboDetail={menuItem}
              initialValue={menuItem.comboId}
              categoryId={categorySelected._id}
              menuId={categorySelected.menuId}
              onClose={() => {
                setOpenComboModal(false);
              }}
              onSaveCombo={(form) => {
                if (menuItem && menuItem.comboId && menuItem._id) {
                  dispatch(
                    updateCatalogCombo({
                      ...form,
                      _id: menuItem._id,
                      comboId: form.comboId,
                      menuId: menuItem.menuId,
                    })
                  );
                }
              }}
            />
          </div>
        </Fade>
      </Modal>
      <Draggable
        key={menuItem._id}
        draggableId={menuItem._id}
        index={index}
        isDragDisabled={openVisibility || onlyRead || isFromComboCategory}
      >
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <VisibilityModalV2
              open={openVisibility}
              onClose={onVisibilityClose}
              data={menuItem}
              showGlobal={false}
              onVisibilityChange={(item = {}) => {
                if (menuItem && menuItem._id) {
                  if (menuItem.comboId) {
                    dispatch(
                      updateComboAvailability(
                        {
                          ...item,
                          _id: menuItem._id,
                        },
                        menuItem.menuId
                      )
                    );
                  } else {
                    dispatch(
                      updateMenuItemVisibilityV2({
                        ...item,
                        menuItemId: menuItem._id,
                        menuId: menuDetail && menuDetail._id,
                      })
                    );
                  }
                }
              }}
            />
            <div className="min-w-full">
              <Card className="m-4">
                <div className="flex flex-wrap sm:flex-no-wrap w-full items-center">
                  <img
                    className="object-cover h-96 w-96 ml-8 mt-8 mb-8"
                    key={isCombo ? comboData._id : menuItem._id}
                    src={imageUrl}
                    alt=""
                  />
                  <div className="w-full ml-8 mr-8 sm:w-full min-w-2/5 justify-center items-center">
                    <VisibilityBanner
                      visibility={menuItem && menuItem.visibility}
                    />

                    <Typography
                      variant="subtitle1 bold"
                      className="mt-12 text-center sm:text-left"
                      component="h3"
                    >
                      {isCombo ? comboData.title : menuItem.title}
                    </Typography>
                    {!isCombo && (
                      <Typography
                        variant="body2"
                        className="text-center sm:text-left"
                        color="textSecondary"
                      >
                        {menuItem.description}
                      </Typography>
                    )}
                  </div>

                  <div className="flex justify-between">
                    <Typography
                      variant="subtitle1"
                      component="h4"
                      className="ml-16 self-center mr-16"
                    >
                      {`${
                        isCombo
                          ? comboData &&
                            comboData.price &&
                            comboData.price.toFixed(2)
                          : menuItem &&
                            menuItem.price &&
                            menuItem.price.toFixed(2)
                      }${clientCurrency.symbol}`}
                    </Typography>
                    <div className="flex center ml-16">
                      <>
                        <IconButton onClick={onVisibilityClick}>
                          <Icon className="material-symbols-outlined text-teal pr-8">
                            visibility
                          </Icon>
                        </IconButton>
                        {isFromComboCategory && length > 1 && index !== 0 && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              onProductPositionChange("increase", menuItem);
                            }}
                          >
                            <Icon color="primary" className="text-14">
                              arrow_upward
                            </Icon>
                          </IconButton>
                        )}
                        {isFromComboCategory && index !== lastIndex && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              onProductPositionChange("decrease", menuItem);
                            }}
                          >
                            <Icon color="primary" className="text-14">
                              arrow_downward
                            </Icon>
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => {
                            if (menuItem.comboId && !isFromComboCategory) {
                              setOpenComboModal(true);
                            } else {
                              setOpen(true);
                            }
                          }}
                          hidden={onlyRead ? true : false}
                        >
                          {/* //here  */}
                          <Icon className="pr-8 text-blue">edit</Icon>
                        </IconButton>
                      </>

                      <IconButton
                        hidden={onlyRead ? true : false}
                        onClick={() => {
                          dispatch(
                            Actions.openDialog({
                              children: (
                                <AlertDialog
                                  onSuccess={() => {
                                    if (isCombo) {
                                      dispatch(
                                        deleteCatalogCombo(
                                          menuItem._id,
                                          menuItem.menuId
                                        )
                                      );
                                    } else {
                                      dispatch(
                                        deleteMenuV2Item(
                                          [menuItem._id],
                                          menuItem.menuId
                                        )
                                      );
                                    }

                                    Actions.closeDialog();
                                  }}
                                  title={
                                    languageStrings[
                                      "CONFIRM_DELETE_ALERT_TITLE"
                                    ]
                                  }
                                  message={
                                    languageStrings["CONFIRM_DELETE_ALERT_BODY"]
                                  }
                                />
                              ),
                            })
                          );
                        }}
                      >
                        <Icon className="pr-8 text-red">delete</Icon>
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className="mt-16 mb-16">
                  <DropableExtraCategory
                    list={list}
                    menuItem={menuItem}
                    setCategorySelected={setCategorySelected}
                    setOpenCategories={setOpenCategories}
                    onlyRead={onlyRead}
                    onExtraCategoryPositionChange={
                      onExtraCategoryPositionChange
                    }
                    isCombo={isFromComboCategory}
                  />
                </div>
              </Card>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
};

const DropableExtraCategory = ({
  list,
  menuItem,
  setCategorySelected,
  setOpenCategories,
  selectedLanguage,
  onlyRead,
  onExtraCategoryPositionChange,
  isCombo,
}) => {
  return (
    <div>
      {list &&
        list.map((extraCategory, index) => (
          <ExtraCategory
            key={extraCategory._id}
            index={index}
            extraCategory={extraCategory}
            onlyRead={onlyRead}
            onEdit={() => {
              setCategorySelected(extraCategory);
              setOpenCategories(true);
            }}
            onDelete={() => {}}
            length={list.length}
            onExtraCategoryPositionChange={onExtraCategoryPositionChange}
            isCombo={isCombo}
          />
        ))}
    </div>
  );
};

export default ProductItem;
