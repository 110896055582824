import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import ListTablePaginated from "../common/List/ListTablePaginated";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListHeader from "app/main/common/List/ListHeader";
import DateRange from "./components/DateRange";
import moment from "moment";
import {
  getRatingList,
  setRatingSearchText,
} from "app/store/rating/RatingActions";
import { ReviewModal } from "./components/ReviewModal";
import { setListLimitPage } from "app/store/pagination/paginationActions";
import { Pagination } from "app/models/Pagination";
import { exportRatingsInExcel } from "./ratingsExcelExport";

const _start = new Date();
_start.setMonth(_start.getMonth() - 1);

function RatingList() {
  const {
    searchText,
    ratingList,
    loading,
    averageRating,
    totalRatings,
    countFiveStarRatings,
  } = useSelector(({ banzzu }) => banzzu.rating);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { ratingList: paginationRatingList } = useSelector(
    ({ banzzu }) => banzzu.pagination
  );
  const { limit = 10, page = 0 } = paginationRatingList;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [reviewModal, setReviewModal] = useState({
    showModal: false,
    rating: 0,
    review: "",
  });
  const [rowsPerPage, setRowsPerPage] = useState(limit);
  const [pageNo, setPageNo] = useState(page);
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });
  const listType = Pagination.RATING;
  useEffect(() => {
    if (user && user._id) {
      const start = new Date();
      const end = new Date();
      start.setMonth(start.getMonth() - 12);
      dispatch(
        getRatingList(user._id, {
          startDate: start,
          endDate: end,
          limit: rowsPerPage,
          page: pageNo,
          searchText,
          order,
        })
      );
    }
  }, [user, pageNo, rowsPerPage, searchText, order, dispatch]);

  const _averageRating = averageRating ? averageRating : 0;
  const totalRating = totalRatings ? totalRatings : 0;
  const _ratings = ratingList && ratingList.length ? ratingList : [];
  const rating5 = countFiveStarRatings ? countFiveStarRatings : 0;

  const handleClick = (item) => {
    if (item && item.review) {
      setReviewModal({
        showModal: true,
        rating: item && item.rating ? item.rating : 0,
        review: item && item.review ? item.review : "",
      });
    }
  };
  const { languageStrings } = useLanguageHelper();
  const setSearchText = (ev) => {
    dispatch(setRatingSearchText(ev));
  };
  const headerRows = {
    data: [
      {
        id: "venueTitle",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.VENUE"],
        sort: true,
        type: "text",
      },
      {
        id: "tableTitle",
        align: "left",
        disablePadding: false,
        label: languageStrings["VIRTUAL_CARD_ORDER_TABLE"],
        sort: true,
        type: "text",
      },

      {
        id: "rating",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.RATING"],
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.DATE"],
        sort: true,
        type: "date",
      },
      {
        id: "review",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.COMMENTS"],
        sort: true,
        type: "truncatedText",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
    searchBy: ["tableTitle", "venueTitle"],
  };
  const getDateSelectedtext = () => {
    let date = "";
    if (startDate instanceof Date && endDate instanceof Date) {
      const _start = moment(startDate).format("DD/MM/YYYY");
      const _end = moment(endDate).format("DD/MM/YYYY");
      date = `${_start} - ${_end}`;
    }

    return date;
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setListLimitPage(dispatch, rowsPerPage, page, listType);
  };
  const handlePageChange = (event, page) => {
    setPageNo(page);
    setListLimitPage(dispatch, rowsPerPage, page, listType);
  };

  const handleRequestSort = (event, property) => {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }

    setOrder({
      direction,
      id,
    });
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136 items-center",
            }}
            header={
              <div className={"flex w-full flex-col"}>
                <div
                  className={
                    "flex w-full items-center flex-row justify-between"
                  }
                >
                  <ListHeader
                    title={languageStrings["AVERAGE_REVIEW"]}
                    totalRatings={languageStrings["TOTAL_RATINGS"]}
                    total5Rating={languageStrings["TOTAL_5_RATINGS"]}
                    setSearchText={setSearchText}
                    searchType={"rating"}
                    filterButton
                    exportButton
                    onTipPress={() => {
                      setShowModal(true);
                    }}
                    onExportFile={() => {
                      exportRatingsInExcel(ratingList, languageStrings);
                    }}
                    averageRating={_averageRating}
                    totalRating={totalRating}
                    total5Ratings={rating5}
                  />
                </div>
                <div className="mt-4">
                  {languageStrings["RANGE"]}:{" "}
                  {startDate && endDate
                    ? `${moment(startDate).format("DD MMM")} - ${moment(
                        endDate
                      ).format("DD MMM")}`
                    : languageStrings["ALL_RESULTS"]}
                </div>
              </div>
            }
            content={
              <ListTablePaginated
                handleClick={handleClick}
                headerRows={headerRows}
                data={_ratings}
                searchText={searchText}
                totalData={totalRatings}
                order={order}
                page={pageNo}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handlePageChange={handlePageChange}
                handleRequestSort={handleRequestSort}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      {/* Date Range Modal */}
      <DateRange
        headerText={languageStrings["FILTER_RANGE"]}
        buttonText={languageStrings["APPLY"]}
        onDateSelect={(start, end) => {
          if (start && end) {
            setStartDate(start);
            setEndDate(end);
          }
        }}
        order={order}
        onCloseModal={() => {
          setShowModal(false);
        }}
        open={showModal}
      />
      <ReviewModal
        onCloseModal={() => {
          setReviewModal({
            showModal: false,
            review: "",
            rating: 0,
          });
        }}
        {...reviewModal}
      />
    </>
  );
}

export default RatingList;
