import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  Icon,
  Typography,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import LoadingOverlay from "react-loading-overlay";
import { editBranding } from "app/store/user/UserActions";
import _ from "@lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { FuseUtils } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { ChromePicker } from "react-color";
import { formatImageUrl } from "app/helpers/utilsHelper";
import { clientsSettings } from "config";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));

function Branding(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const loading = useSelector(({ banzzu }) => banzzu.user.loading);
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { languageStrings } = useLanguageHelper();

  const [tabValue, setTabValue] = useState(0);
  const { form, handleChange, setForm } = useForm();
  const availableLanguages = [
    { label: "LANGUAGE.SPANISH", value: "Spanish" },
    { label: "LANGUAGE.FRENCH", value: "French" },
    { label: "LANGUAGE.ENGLISH", value: "English" },
    { label: "LANGUAGE.CHINESE", value: "Chinese" },
  ];

  useEffect(() => {
    if (user && user.logo && user.settings) {
      setForm({
        logo: user.logo,
        headerImage: user.settings.headerImage,
        color: user.settings.typography,
        color2: user.settings.typography2 || user.settings.typography,
        color3: user.settings.typography3 || user.settings.typography,
        AutoServiceLogoImage: user.settings.autoServiceLogo,
        autoServiceCardBgColor:
          user.settings.autoServiceCardBgColor || "#013334",
        autoServiceCardBorderColor:
          user.settings.autoServiceCardBorderColor || "#02CFD3",
        language: user.defaultLanguage || "Spanish",
      });
    }
  }, [dispatch, user]);
  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  //***   HANDLE CHANGE WHEN IS NEEDED
  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          logo: `data:${file.type};base64,${btoa(reader.result)}`,
          logoFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  //***   HANDLE CHANGE WHEN IS NEEDED
  function handleUploadHeaderChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          headerImage: `data:${file.type};base64,${btoa(reader.result)}`,
          headerFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }
  function handleUploadAutoServiceLogo(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          AutoServiceLogoImage: `data:${file.type};base64,${btoa(
            reader.result
          )}`,
          autoServiceLogo: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {languageStrings["BRANDING_HEADER.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <div>
                {clientsSettings.showSavePreviewButton && (
                  <FuseAnimate animation="transition.slideRightIn" delay={300}>
                    <Button
                      className="whitespace-no-wrap mr-16"
                      variant="contained"
                      onClick={() => {
                        const _form = form;
                        if (_form && _form.headerImage) {
                          delete _form.headerImage;
                        } else if (_form && _form.AutoServiceLogoImage) {
                          delete _form.AutoServiceLogoImage;
                        }
                        dispatch(
                          editBranding(_form, true, user.settings.permalink)
                        );
                      }}
                    >
                      <Icon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      >
                        remove_red_eye
                      </Icon>
                      {languageStrings["GENERAL.SAVE_PREVIEW"]}
                    </Button>
                  </FuseAnimate>
                )}
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    className="whitespace-no-wrap"
                    variant="contained"
                    onClick={() => {
                      const _form = form;
                      if (_form && _form.headerImage) {
                        delete _form.headerImage;
                      }
                      if (_form && _form.AutoServiceLogoImage) {
                        delete _form.AutoServiceLogoImage;
                      }
                      dispatch(editBranding(_form, false));
                    }}
                  >
                    <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                      save
                    </Icon>
                    {languageStrings["GENERAL.SAVE"]}
                  </Button>
                </FuseAnimate>
              </div>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div>
              <div className="flex mt-10 mb-10 pl-40 pr-60 flex-row items-center">
                <Typography variant="h6" className="l-32 w-200">
                  {languageStrings["GENERAL_LANGUAGE"]}
                </Typography>
                <Select
                  style={{ width: "22%" }}
                  className="mx-8 ml-32"
                  native
                  required
                  input={
                    <OutlinedInput
                      name="language"
                      labelWidth={0}
                      id="outlined-venue-native-simple"
                    />
                  }
                  labelId="type-label"
                  id="language"
                  name="language"
                  value={form.language}
                  onChange={(e) => {
                    setForm({ ...form, language: e.target.value });
                  }}
                  label={languageStrings["GENERAL_LANGUAGE"]}
                  placeholder={languageStrings["GENERAL_LANGUAGE"]}
                >
                  {availableLanguages.map((item) => (
                    <option value={item.value} key={item}>
                      {languageStrings[item.label]}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="flex items-center m-32">
                <Typography variant="h6" className="mr-32 w-200">
                  {languageStrings["BRANDING.CHOOSE_COLOR"]}
                </Typography>
                <div className="ml-8 text-center">
                  <Typography variant="subtitle1" className="mb-6">
                    {languageStrings["BRANDING_PRIMARY_COLOUR"]}
                  </Typography>
                  <ChromePicker
                    color={form.color}
                    disableAlpha
                    onChange={(color) => {
                      setForm((form) => ({
                        ...form,
                        color: color.hex,
                      }));
                    }}
                  />
                </div>
                <div className="ml-8 text-center">
                  <Typography variant="subtitle1" className="mb-6">
                    {languageStrings["BRANDING_SECONDARY_COLOUR"]}
                  </Typography>
                  <ChromePicker
                    color={form.color2}
                    disableAlpha
                    onChange={(color) => {
                      setForm((form) => ({
                        ...form,
                        color2: color.hex,
                      }));
                    }}
                  />
                </div>
                <div className="ml-8 text-center">
                  <Typography variant="subtitle1" className="mb-6">
                    {languageStrings["BRANDING_TERTIARY_COLOUR"]}
                  </Typography>
                  <ChromePicker
                    color={form.color3}
                    disableAlpha
                    onChange={(color) => {
                      setForm((form) => ({
                        ...form,
                        color3: color.hex,
                      }));
                    }}
                  />
                </div>
              </div>
              {clientsSettings.showBrandingLogo ? (
                <div className="flex items-center m-32">
                  <Typography variant="h6" className="mr-32 w-200">
                    {languageStrings["BRANDING.UPLOAD_LOGO"]}
                  </Typography>
                  <div>
                    <input
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                      className="hidden"
                      id="button-file"
                      type="file"
                      onChange={handleUploadChange}
                    />
                    <div className="flex justify-center sm:justify-start flex-wrap">
                      <label
                        htmlFor="button-file"
                        className={
                          "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 "
                        }
                      >
                        <Icon fontSize="large" color="action">
                          cloud_upload
                        </Icon>
                      </label>
                      {
                        <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                          <img
                            className="max-w-none w-auto h-full"
                            src={
                              form.logo
                                ? formatImageUrl(form.logo)
                                : "assets/images/avatars/noimage.png"
                            }
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="flex items-center m-32">
                <Typography variant="h6" className="mr-32 w-200">
                  {languageStrings["BRANDING.UPLOAD_HEADER"]}
                </Typography>
                <div>
                  <input
                    accept="image/*"
                    className="hidden"
                    id="button-file-2"
                    type="file"
                    onChange={handleUploadHeaderChange}
                  />
                  <div className="flex justify-center sm:justify-start flex-wrap">
                    <label
                      htmlFor="button-file-2"
                      className={
                        "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 "
                      }
                    >
                      <Icon fontSize="large" color="action">
                        cloud_upload
                      </Icon>
                    </label>
                    {
                      <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                        <img
                          className="max-w-none w-auto h-full"
                          src={
                            form.headerImage
                              ? formatImageUrl(form.headerImage)
                              : "assets/images/avatars/noimage.png"
                          }
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
              {/* // autoservice feature */}
              {user.settings &&
                user.settings.autoServiceFeatureEnabled &&
                user.settings.autoServiceFeatureEnabled && (
                  <>
                    <div className="flex items-center m-32">
                      <Typography variant="h6" className="mr-32 w-200">
                        {languageStrings["BRANDING.AUTOSERVICE_SCREEN_COLOR"]}
                      </Typography>

                      <div className="ml-8 text-center">
                        <Typography variant="subtitle1" className="mb-6">
                          {languageStrings["BRANDING_PRIMARY_COLOUR"]}
                        </Typography>
                        <ChromePicker
                          color={form.autoServiceCardBgColor}
                          disableAlpha
                          onChange={(color) => {
                            setForm((form) => ({
                              ...form,
                              autoServiceCardBgColor: color.hex,
                            }));
                          }}
                        />
                      </div>
                      <div className="ml-8 text-center">
                        <Typography variant="subtitle1" className="mb-6">
                          {languageStrings["BRANDING_SECONDARY_COLOUR"]}
                        </Typography>
                        <ChromePicker
                          color={form.autoServiceCardBorderColor}
                          disableAlpha
                          onChange={(color) => {
                            setForm((form) => ({
                              ...form,
                              autoServiceCardBorderColor: color.hex,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center m-32">
                      <Typography variant="h6" className="mr-32 w-200">
                        {languageStrings["AUTOSERVICE_LOGO"]}
                      </Typography>
                      <div>
                        <input
                          accept="image/*"
                          className="hidden"
                          id="button-file-auto-service"
                          type="file"
                          onChange={handleUploadAutoServiceLogo}
                        />
                        <div className="flex justify-center sm:justify-start flex-wrap">
                          <label
                            htmlFor="button-file-auto-service"
                            className={
                              "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 "
                            }
                          >
                            <Icon fontSize="large" color="action">
                              cloud_upload
                            </Icon>
                          </label>
                          {
                            <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                              <img
                                className="max-w-none w-auto h-full"
                                src={
                                  form.AutoServiceLogoImage
                                    ? formatImageUrl(form.AutoServiceLogoImage)
                                    : "assets/images/avatars/noimage.png"
                                }
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("MenuReducer", reducer)(Branding);
