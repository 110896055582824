import React, { useEffect, useState } from "react";
import { Icon, Tab, Tabs, Typography } from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { Link } from "react-router-dom";
import withReducer from "app/store/withReducer";
import LoadingOverlay from "react-loading-overlay";
import PaymentHistoryDetail from "./components/PaymentHistoryDetail";
import reducer from "app/store/orders/OrdersReducer";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getPaymentHistoryDetail } from "app/store/paymentHistory/paymentHistoryActions";
import useClientCurrency from "app/hooks/useClientCurrency";

const PaymentHistory = (props) => {
  const dispatch = useDispatch();

  const { paymentId } = props.match.params;
  const { loading, paymentDetail } = useSelector(
    ({ banzzu }) => banzzu.paymentHistory
  );
  const [tabValue, setTabValue] = useState(0);
  const { clientCurrency } = useClientCurrency();
  const { languageStrings } = useLanguageHelper();

  useEffect(() => {
    dispatch(getPaymentHistoryDetail(paymentId));
  }, []);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <div className="flex flex-1 w-full items-center justify-between">
            <div className="flex flex-col items-start max-w-full">
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Typography
                  className="normal-case flex items-center sm:mb-12"
                  component={Link}
                  role="button"
                  to="/paymentHistory"
                  color="inherit"
                >
                  <Icon className="mr-4 text-20">arrow_back</Icon>
                  {languageStrings["SIDEBAR_MENUE.PAYMENT_HISTORY"]}
                </Typography>
              </FuseAnimate>
            </div>
          </div>
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.DETAIL"]}
            />
            <Tab
              className="h-64 normal-case"
              label={languageStrings["ORDER_PAGE.PRODUCTS"]}
            />
          </Tabs>
        }
        content={
          paymentDetail &&
          paymentDetail._id && (
            // order.customer &&
            <div className="p-16 sm:p-24 w-full">
              {/*Order Details*/}
              {tabValue === 0 && (
                <div>
                  <div className="flex flex-wrap">
                    <PaymentHistoryDetail />
                  </div>
                </div>
              )}
              {/*Products*/}
              {tabValue === 1 && (
                <div className="table-responsive">
                  <table className="simple">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{languageStrings["ORDER_PAGE.NAME"]}</th>
                        <th>{languageStrings["ORDER_PAGE.EXTRAS"]}</th>
                        <th>{languageStrings["GENERAL.PRICE"]}</th>
                        <th>{languageStrings["ORDER_PAGE.QUANTITY"]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentDetail.orderBasket &&
                        paymentDetail.orderBasket.map((product) => (
                          <tr key={product.id}>
                            <td className="w-80"></td>
                            <td className="w-64">
                              <span className="truncate">{product.title}</span>
                            </td>
                            <td className="w-80">
                              {product &&
                                product.extras &&
                                product.extras.map((item) => {
                                  return (
                                    <p>
                                      <span className="truncate">
                                        {item.title}
                                      </span>
                                    </p>
                                  );
                                })}
                            </td>
                            <td className="w-32">
                              <span className="truncate">
                                {Number(product.price).toFixed(2)}{" "}
                                {`${clientCurrency.symbol}`}
                              </span>
                            </td>
                            <td className="w-32">
                              <span className="truncate">
                                {product.quantity}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default withReducer("OrdersReducer", reducer)(PaymentHistory);
