import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import { getVenueList } from "app/store/venues/VenuesActions";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import History from "@history";
import { FuseUtils } from "@fuse";
import * as Actions from "app/store/actions";
import AlertDialog from "../common/AlertDialog";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import AlertBox from "app/main/common/AlertBox";
import { formatImageUrl } from "app/helpers/utilsHelper";
import {
  createMenuV2,
  editMenuV2,
  getMenuV2Detail,
} from "app/store/menuV2/MenuV2Actions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));

const MenuDetailV2 = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const loading = useSelector(({ banzzu }) => banzzu.menuV2.loading);
  let menu = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { languageStrings } = useLanguageHelper();
  const { productId } = props.match.params;

  useEffect(() => {
    if (productId !== "new") {
      dispatch(getMenuV2Detail(productId));
    }
  }, [dispatch, productId]);
  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);

  if (productId === "new") {
    menu = {
      id: "0",
      title: "",
      isPdf: "0",
      venues: [],
      type: "",
      maxCount: "",
      minCount: "",
      availableOnFutureOrders: false,
      availableOnDelivery: false,
      availableOnCollection: false,
      availableOnOrderFromTable: false,
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);

  useEffect(() => {
    if ((menu && !form) || (menu && form && menu._id !== form._id)) {
      const { permission } = user;
      let form = {
        ...menu,
      };

      if (
        permission &&
        Object.keys(permission).length > 0 &&
        !permission.isMaster
      ) {
        form = {
          ...form,
          venues: [user._id],
        };
      }

      setForm(form);
    }
  }, [form, menu, setForm, user]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty() && !menu.onlyRead;
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "" && form.venues.length > 0;
  }

  function hasNoErrors() {
    return !errorName() && !errorImage() && !errorPDF();
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function errorImage() {
    return !form.image;
  }

  function errorPDF() {
    return form.isPdf === "1" && !form.pdf;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handlePDFUploadChange(e) {
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      setForm((form) => ({
        ...form,
        pdf: `data:${file.type};base64,${btoa(reader.result)}`,
        pdfFile: file,
      }));
    };

    reader.onerror = function () {
      console.log("error on load image");
    };
  }

  function handleAllergenesPDFUploadChange(e) {
    const file = e.target.files[0];

    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      setForm((form) => ({
        ...form,
        allergenesPdf: `data:${file.type};base64,${btoa(reader.result)}`,
        allergenesPdfFile: file,
      }));
    };

    reader.onerror = function () {
      console.log("error on load image");
    };
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  function handleVenueChange(event) {
    let venuesFormatted = form.venues;

    if (event.target.checked) {
      venuesFormatted.push(event.target.value);
    } else {
      venuesFormatted.splice(venuesFormatted.indexOf(event.target.value), 1);
    }

    setForm((form) => ({
      ...form,
      venues: venuesFormatted,
    }));
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  const previousPage = () => {
    History.push({
      pathname: `/menuV2/menuList`,
    });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    onClick={() => {
                      dispatch(
                        Actions.openDialog({
                          children: (
                            <AlertDialog
                              onSuccess={previousPage}
                              title={languageStrings["GENERAL.ALERT_TITLE"]}
                              message={languageStrings["GENERAL.ALERT_DESC"]}
                            />
                          ),
                        })
                      );
                    }}
                  >
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["MENU_DETAIL_PAGE.BACK_BUTTON"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["MENU_DETAIL_PAGE.NEW_MENUE"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["MENU_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              {form.id !== "0" && form.isPdf !== "1" && (
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <div>
                    <Button
                      className="whitespace-no-wrap"
                      variant="contained"
                      disabled={!canBeSubmitted()}
                      onClick={() => {
                        History.push({
                          pathname: `/menuV2/menuDetailPro/${form._id}`,
                        });
                      }}
                    >
                      <Icon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      >
                        add
                      </Icon>
                      {
                        languageStrings[
                          "MENU_DETAIL_PAGE.VIRTUAL_MENU_PRODUCTS"
                        ]
                      }
                    </Button>
                  </div>
                </FuseAnimate>
              )}
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={() => {
                    form.id !== "0"
                      ? dispatch(editMenuV2(form))
                      : dispatch(createMenuV2(form));
                  }}
                >
                  <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                    save
                  </Icon>
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <>
              {menu.onlyRead && (
                <div className="p16 sm:p-24 max-w-2xl">
                  <AlertBox title={languageStrings["MENU.SHARED"]} />
                </div>
              )}
              <div className="p-16 sm:p-24 max-w-2xl">
                {tabValue === 0 && (
                  <div>
                    <input
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                      className="hidden"
                      id="button-file"
                      type="file"
                      onChange={handleUploadChange}
                    />
                    <div className="flex justify-center sm:justify-start flex-wrap">
                      <label
                        htmlFor="button-file"
                        className={
                          "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 " +
                          clsx(
                            menu.onlyRead ? classes.enable : classes.disabled
                          )
                        }
                      >
                        <Icon fontSize="large" color="action">
                          cloud_upload
                        </Icon>
                      </label>
                      {
                        <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                          <img
                            className="max-w-none w-auto h-full"
                            alt="whatson"
                            src={
                              form.image
                                ? formatImageUrl(form.image)
                                : "assets/images/avatars/noimage.png"
                            }
                          />
                        </div>
                      }
                      {errorImage() && (
                        <div className="text-red">
                          {languageStrings["ERRORS.IMAGE_IS_REQUIRED"]}
                        </div>
                      )}
                    </div>
                    <div className="flex">
                      <TextField
                        className="mt-8 mb-16"
                        error={errorName()}
                        required
                        label={languageStrings["GENERAL.TITLE"]}
                        id="title"
                        name="title"
                        disabled={menu.onlyRead}
                        value={form.title}
                        onChange={handleTitleChange}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          maxLength: 100,
                        }}
                        helperText={errorName() ? error.title : ""}
                      />
                    </div>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-venue-native-simple">
                        {languageStrings["MENU_DETAIL_PAGE.MENU_TYPE"]}
                      </InputLabel>
                      <Select
                        className="mt-8 mb-16 mr-8"
                        native
                        fullWidth
                        disabled={menu.onlyRead}
                        value={form.isPdf}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            name="isPdf"
                            labelWidth={100}
                            id="outlined-venue-native-simple"
                          />
                        }
                      >
                        <option value={0}>Virtual Menu</option>
                        <option value={1}>PDF</option>
                      </Select>
                    </FormControl>
                    {form.isPdf === "1" && (
                      <div className="flex justify-center sm:justify-start flex-wrap">
                        <input
                          accept="application/pdf"
                          className="hidden"
                          id="button-pdf"
                          type="file"
                          onChange={handlePDFUploadChange}
                        />
                        <label
                          htmlFor="button-pdf"
                          className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                        >
                          <Icon fontSize="large" color="action">
                            cloud_upload
                          </Icon>
                        </label>
                        {form.pdf ? (
                          !form.pdfFile ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              href={form.pdf}
                              target="_blank"
                              className="ml-16 h-40 items-center flex"
                            >
                              {languageStrings["MENU_DETAIL_PAGE.VIEW_PDF"]}
                            </Button>
                          ) : (
                            <div>{form.pdfFile.name}</div>
                          )
                        ) : (
                          <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                            <img
                              className="max-w-none w-auto h-full"
                              alt="whatson"
                              src={"assets/images/avatars/nopdf.png"}
                            />
                          </div>
                        )}
                        {errorPDF() && (
                          <div className="text-red">
                            {
                              languageStrings[
                                "MENU_DETAIL_PAGE.PDF_IS_REQUIRED"
                              ]
                            }
                          </div>
                        )}
                      </div>
                    )}

                    <div className="mt-8 mb-8 font-bold">
                      {languageStrings["MENU_DETAIL_PAGE.ALLERGENES"]}
                    </div>
                    <div className="flex justify-center sm:justify-start flex-wrap">
                      <input
                        accept="application/pdf"
                        className="hidden"
                        id="button-pdf"
                        type="file"
                        onChange={handleAllergenesPDFUploadChange}
                      />
                      <label
                        htmlFor="button-pdf"
                        className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                      >
                        <Icon fontSize="large" color="action">
                          cloud_upload
                        </Icon>
                      </label>
                      {form.allergenesPdf ? (
                        !form.allergenesPdfFile ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            href={form.allergenesPdf}
                            target="_blank"
                            className="ml-16 h-40 items-center flex"
                          >
                            {languageStrings["MENU_DETAIL_PAGE.VIEW_PDF"]}
                          </Button>
                        ) : (
                          <div>{form.allergenesPdfFile.name}</div>
                        )
                      ) : (
                        <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                          <img
                            className="max-w-none w-auto h-full"
                            alt="whatson"
                            src={"assets/images/avatars/nopdf.png"}
                          />
                        </div>
                      )}
                    </div>
                    {venueList.length > 0 && (
                      <React.Fragment>
                        <div className="mt-8 mb-8 font-bold">
                          {languageStrings["MENU_DETAIL_PAGE.VENUES"]}
                        </div>
                        <FormGroup row>
                          {venueList.map((item) => (
                            <FormControlLabel
                              key={item.title}
                              control={
                                <Checkbox
                                  checked={
                                    form.venues && form.venues.length > 0
                                      ? form.venues.includes(item._id)
                                      : false
                                  }
                                  onChange={handleVenueChange}
                                  value={item._id}
                                />
                              }
                              label={item.title}
                            />
                          ))}
                        </FormGroup>
                      </React.Fragment>
                    )}

                    {user.permission &&
                    (user.permission.hasFutureOrder ||
                      user.permission.hasDelivery ||
                      user.permission.hasCollection ||
                      user.permission.hasOrderFromTable) ? (
                      <React.Fragment>
                        <div className="mt-8 mb-8 font-bold">
                          {
                            languageStrings[
                              "MENU_DETAIL_PAGE.MENU_AVAILABILITY"
                            ]
                          }
                        </div>
                        <FormGroup row>
                          {user.permission.hasFutureOrder && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="availableOnFutureOrders"
                                  onChange={handleChange}
                                  checked={form.availableOnFutureOrders}
                                />
                              }
                              label={
                                languageStrings[
                                  "MENU_DETAIL_PAGE.FUTURE_ORDERS"
                                ]
                              }
                            />
                          )}

                          {user.permission.hasDelivery && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="availableOnDelivery"
                                  onChange={handleChange}
                                  checked={
                                    form.availableOnDelivery ? true : false
                                  }
                                />
                              }
                              label={
                                languageStrings["MENU_DETAIL_PAGE.DELIVERY"]
                              }
                            />
                          )}

                          {user.permission.hasCollection && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="availableOnCollection"
                                  onChange={handleChange}
                                  checked={
                                    form.availableOnCollection ? true : false
                                  }
                                />
                              }
                              label={
                                languageStrings["MENU_DETAIL_PAGE.COLLECTION"]
                              }
                            />
                          )}

                          {user.permission.hasOrderFromTable && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="availableOnOrderFromTable"
                                  onChange={handleChange}
                                  checked={
                                    form.availableOnOrderFromTable
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label={
                                languageStrings[
                                  "MENU_DETAIL_PAGE.ORDER_FROM_TABLE"
                                ]
                              }
                            />
                          )}
                        </FormGroup>
                      </React.Fragment>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default withReducer("MenuReducer", reducer)(MenuDetailV2);
