import MenuListV2 from "./MenuListV2";
import MenuDetailV2 from "./MenuDetailV2";
import MenuItemV2List from "./menuItemV2List/MenuItemV2List";
import MenuItemV2Detail from "./menuItemV2/MenuItemV2Detail";
import MenuV2Category from "./menuV2Category/MenuV2Category";
import MenuDetailProV2 from "./MenuDetailProV2";

export const MenuV2Config = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/menuV2/menuList",
      component: MenuListV2,
    },
    {
      path: "/menuV2/menuDetail/:productId",
      component: MenuDetailV2,
    },
    {
      path: "/menuV2/menuDetailPro/:productId",
      component: MenuDetailProV2,
    },
    {
      path: "/menuV2/menuItemList/:productId",
      component: MenuItemV2List,
    },
    {
      path: "/menuV2/menuCategory/:productId",
      component: MenuV2Category,
    },
    {
      path: "/menuV2/menuItemDetail/:menuId/:productId",
      component: MenuItemV2Detail,
    },
    {
      path: "/menuV2/menuItemDetail/:productId",
      component: MenuItemV2Detail,
    },
  ],
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
