import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getGroupModifierList } from "app/store/catalogue/CatalogueActions";
import { getLabelWith } from "app/helpers/utilsHelper";
import { SearchableDropDown } from "app/main/common/SearchableDropDown";

export const GroupModifierDropdown = ({
  handleChangeModifier = () => {},
  selectedValue,
}) => {
  const dispatch = useDispatch();
  const searchBarRef = useRef(null);
  const { languageStrings } = useLanguageHelper();
  const { groupModifierList } = useSelector(({ banzzu }) => banzzu.catalogue);
  const [value, setValue] = useState("");

  useEffect(() => {
    dispatch(getGroupModifierList());
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const _groupModifierList =
    groupModifierList && groupModifierList.length ? groupModifierList : [];
  const handleChange = (event) => {
    const val = event.target.value;
    const findGroup = _groupModifierList.find((item) => item._id === val);
    if (findGroup && findGroup._id) {
      handleChangeModifier(findGroup, val);
      setValue("");
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    }
  };
  const { labelWith } = getLabelWith(languageStrings["SELECT_GROUP_MODIFIERS"]);
  return (
    <SearchableDropDown
      keyPropFn={(option) => option._id}
      valuePropFn={(option) => `${option.title} - ${option.sku}`}
      list={_groupModifierList}
      selectedValue={value}
      label={languageStrings["SELECT_GROUP_MODIFIERS"]}
      handleChange={handleChange}
      labelWidth={labelWith}
      searchBarRef={searchBarRef}
    />
  );
};
