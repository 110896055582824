import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListHeader from "app/main/common/List/ListHeader";
import {
  deleteProduct,
  getProductList,
  setProductSearchText,
  updateProductVisibility,
} from "app/store/catalogue/CatalogueActions";
import history from "@history";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import useSyncedPage from "app/hooks/useSyncedPage";

const ProductList = () => {
  const {
    searchProductText,
    productList = [],
    loading,
  } = useSelector(({ banzzu }) => banzzu.catalogue);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const dispatch = useDispatch();
  const { currentPage, setCurrentPage } = useSyncedPage();
  const { languageStrings } = useLanguageHelper();
  const [openVisibility, setOpenVisibility] = useState({
    itemId: "",
    show: false,
    data: null,
  });
  //TODO REFACTOR do we need to send client, venueid or is on the tokens?
  useEffect(() => {
    if (user && user._id) dispatch(getProductList(user._id));
  }, [user]);

  const setSearchText = (ev) => {
    dispatch(setProductSearchText(ev));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const headerRows = {
    data: [
      {
        id: "image",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.IMAGE"],
        sort: false,
        type: "image",
      },
      {
        id: "title",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.TITLE"],
        sort: true,
        type: "text",
      },
      {
        id: "sku",
        align: "left",
        disablePadding: true,
        label: languageStrings["CATALOGUE.SKU"],
        sort: true,
        type: "text",
      },
      {
        id: "stock",
        align: "left",
        disablePadding: true,
        label: languageStrings["STOCK"],
        sort: true,
        type: "stockCell",
      },
      {
        id: "price",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.PRICE"],
        sort: true,
        type: "priceCell",
      },
      {
        id: "visibility",
        align: "left",
        disablePadding: false,
        label: languageStrings["VISIBILITY"],
        sort: true,
        type: "visibilityBanner",
      },
      {
        id: "visibility",
        align: "left",
        icon: "visibility",
        disablePadding: false,
        label: languageStrings["ACTION"],
        sort: true,
        type: "singleIcon",
        onClick: (e, id) => {
          const findData = productList.find((item) => item._id === id);
          let data = null;
          if (findData && findData._id) {
            data = findData;
          }
          setOpenVisibility({
            data,
            itemId: id,
            show: true,
          });
        },
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["title", "description"],
  };

  const onHandleDelete = (selected) => {
    dispatch(
      deleteProduct(selected, () => {
        history.replace({
          pathname: history.location.pathname,
          state: { page: 0 },
        });
      })
    );
  };

  const handleClick = (item) => {
    history.push({
      pathname: `/catalogue/productsDetail/${item._id}`,
      state: { page: currentPage },
    });
  };
  const onVisibilityClose = () => {
    setOpenVisibility({
      itemId: "",
      show: !openVisibility.show,
      data: null,
    });
  };
  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  title={languageStrings["CATALOGUE.PRODUCT_LIST"]}
                  setSearchText={setSearchText}
                  searchType={"catalogue"}
                  createButton
                  createUrl={{
                    pathname: "/catalogue/productsDetail/new",
                    state: { page: currentPage },
                  }}
                  buttonText={languageStrings["CATALOGUE.CREATE_PRODUCT"]}
                  searchTextKey={"searchProductText"}
                />
              </div>
            }
            content={
              <ListTable
                headerRows={headerRows}
                data={productList}
                searchText={searchProductText}
                handleDelete={onHandleDelete}
                handleClick={handleClick}
                onPageChange={handlePageChange}
                page={currentPage}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      <VisibilityModalV2
        open={openVisibility.show}
        onClose={onVisibilityClose}
        data={openVisibility.data}
        onVisibilityChange={(item = {}) => {
          const payload = {
            ...item,
            productId: openVisibility.itemId,
          };
          dispatch(updateProductVisibility(payload));
        }}
      />
    </>
  );
};

export default ProductList;
