import React from "react";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const PaymentTypeBadge = ({ backgroundColor, color, text }) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "flex-start",
        padding: "0.5rem",
        backgroundColor,
        color,
        justifyContent: "center",
      }}
      className="text-14 rounded truncate"
    >
      {languageStrings[text]}
    </div>
  );
};

export default PaymentTypeBadge;
