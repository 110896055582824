import History from "@history";
import { axiosInstance as axios } from "app/api/axios";

import { PaymentHistoryType } from "../redux/actionTypes";
import {
  PAYMENT_HISTORY,
  PAYMENT_HISTORY_REFUND,
  getPaymentHistoryUrl,
} from "app/api/Endpoint";
import { toast } from "react-toastify";
let debounceTimeout;

export function setPaymentHistorySearchText(event) {
  return (dispatch) => {
    const searchText = event.target.value;
    dispatch({
      type: PaymentHistoryType.PAYMENT_HISTORY_SEARCH_TEXT,
      payload: {
        searchText,
      },
    });
    clearTimeout(debounceTimeout); // Clear the previous timeout

    debounceTimeout = setTimeout(() => {
      dispatch(getPaymentHistoryList(searchText));
    }, 500);
  };
}
export const getPaymentHistoryList = (
  searchText = "",
  initialDate: string = "",
  finalDate: string = ""
) => {
  return (dispatch) => {
    dispatch({
      type: PaymentHistoryType.PAYMENT_HISTORY_LIST_START,
    });

    const url = PAYMENT_HISTORY;
    const params = {
      searchText,
      initialDate,
      finalDate,
    };
    axios
      .get(url, { params })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getPaymentHistoryListSuccess(dispatch, res.data.data);
        } else {
          getPaymentHistoryListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch((error) => {
        getPaymentHistoryListFail(dispatch, "Something went wrong");
      });
  };
};

const getPaymentHistoryListSuccess = (dispatch, data) => {
  dispatch({
    type: PaymentHistoryType.PAYMENT_HISTORY_LIST_SUCCESS,
    payload: data,
  });
};
const getPaymentHistoryListFail = (dispatch, errorMessage) => {
  dispatch({
    type: PaymentHistoryType.PAYMENT_HISTORY_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  History.push({
    pathname: `/paymentHistory`,
  });
};
export const getPaymentHistoryDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: PaymentHistoryType.PAYMENT_HISTORY_DETAIL_START,
    });

    const url = getPaymentHistoryUrl(id);

    axios
      .get(url)
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.payment &&
          res.data.data.payment._id
        ) {
          console.log({ data: res.data });
          getPaymentHistoryDetailSuccess(dispatch, res.data.data.payment);
        }
      })
      .catch((error) => {
        getPaymentHistoryFail(dispatch, "There was an error connection", error);
      });
  };
};

const getPaymentHistoryFail = (dispatch, errorMessage, error) => {
  dispatch({
    type: PaymentHistoryType.PAYMENT_HISTORY_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  if (error.response && error.response.status === 401) {
    return;
  } else {
    History.push({
      pathname: `/paymentHistory`,
    });
  }
};

const getPaymentHistoryDetailSuccess = (dispatch, data) => {
  dispatch({
    type: PaymentHistoryType.PAYMENT_HISTORY_DETAIL_SUCCESS,
    payload: data,
  });
};
export const paymentHistoryRefund = (amount: number, id: string) => {
  return (dispatch) => {
    dispatch({
      type: PaymentHistoryType.PAYMENT_HISTORY_REFUND_START,
    });

    const url = PAYMENT_HISTORY_REFUND;
    const data = {
      amount,
      id,
    };
    axios
      .post(url, data)
      .then((res) => {
        paymentHistoryRefundSuccess(dispatch, res.data);
        dispatch(getPaymentHistoryList());
      })
      .catch((error) => {
        paymentHistoryRefundFail(dispatch, "There was an error connection");
      });
  };
};

const paymentHistoryRefundFail = (dispatch, errorMessage) => {
  toast.error(`${errorMessage}`);
  dispatch({
    type: PaymentHistoryType.PAYMENT_HISTORY_REFUND_FAIL,
    payload: {
      errorMessage,
    },
  });
  History.push({
    pathname: `/paymentHistory`,
  });
};

const paymentHistoryRefundSuccess = (dispatch, data) => {
  toast.success(`${data.message}`);
  dispatch({
    type: PaymentHistoryType.PAYMENT_HISTORY_REFUND_SUCCESS,
  });
};
