import { ComboActionTypes } from "./../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: ComboState = {
  comboList: [],
  loading: false,
  searchText: "",
  comboDetail: {},
};

interface Action {
  payload: any;
  type: string;
}

const CombosReducer = (
  state: ComboState = INITIAL_STATE,
  action: Action
): ComboState => {
  switch (action.type) {
    case ComboActionTypes.COMBOS_LIST_START:
      return updateObject(state, { loading: true, comboList: [] });

    case ComboActionTypes.COMBOS_LIST_SUCCESS:
      return updateObject(state, {
        comboList: action.payload,
        comboDetail: {},
        loading: false,
      });

    case ComboActionTypes.COMBOS_LIST_FAIL:
      return updateObject(state, { loading: false });

    case ComboActionTypes.SET_COMBO_SEARCH_TEXT:
      return updateObject(state, { searchText: action.payload.searchText });

    case ComboActionTypes.COMBO_DETAIL_START:
      return updateObject(state, { loading: true, comboDetail: {} });

    case ComboActionTypes.COMBO_DETAIL_SUCCESS:
      return updateObject(state, {
        comboDetail: action.payload,
        loading: false,
      });

    case ComboActionTypes.COMBO_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case ComboActionTypes.COMBO_CREATE_START:
      return updateObject(state, { loading: true });

    case ComboActionTypes.COMBO_CREATE_SUCCESS:
      return updateObject(state, { loading: false });

    case ComboActionTypes.COMBO_CREATE_FAIL:
      return updateObject(state, { loading: false });

    case ComboActionTypes.CHANGE_COMBO_STATUS_START:
      return updateObject(state, { loading: true });

    case ComboActionTypes.CHANGE_COMBO_STATUS_SUCCESS:
      return updateObject(state, { loading: false });

    case ComboActionTypes.CHANGE_COMBO_STATUS_FAIL:
      return updateObject(state, { loading: false });

    case ComboActionTypes.COMBO_DELETE_START:
      return updateObject(state, { loading: true });

    case ComboActionTypes.COMBO_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case ComboActionTypes.COMBO_DELETE_FAIL:
      return updateObject(state, { loading: false });
    case ComboActionTypes.CHANGE_COMBO_AVAILABILITY_START:
      return updateObject(state, { loading: true });
    case ComboActionTypes.CHANGE_COMBO_AVAILABILITY_FAIL:
      return updateObject(state, { loading: false });
    case ComboActionTypes.CHANGE_COMBO_AVAILABILITY_SUCCESS:
      return updateObject(state, { loading: false });

    case ComboActionTypes.UPDATE_COMBO_AVAILABILITY_START:
      return updateObject(state, { loading: true });
    case ComboActionTypes.UPDATE_COMBO_AVAILABILITY_SUCCESS:
      return updateObject(state, { loading: false });
    case ComboActionTypes.UPDATE_COMBO_AVAILABILITY_FAIL:
      return updateObject(state, { loading: false });
    case ComboActionTypes.SORT_COMBO_CATEGORY_START:
      return updateObject(state, { loading: true });
    case ComboActionTypes.SORT_COMBO_CATEGORY_SUCCESS:
      return updateObject(state, { loading: false });
    case ComboActionTypes.SORT_COMBO_CATEGORY_FAIL:
      return updateObject(state, { loading: false });
    case ComboActionTypes.SORT_COMBO_CATEGORY_PRODUCT_START:
      return updateObject(state, { loading: true });
    case ComboActionTypes.SORT_COMBO_CATEGORY_PRODUCT_SUCCESS:
      return updateObject(state, { loading: false });
    case ComboActionTypes.SORT_COMBO_CATEGORY_PRODUCT_FAIL:
      return updateObject(state, { loading: false });
    default: {
      return state;
    }
  }
};

export default CombosReducer;
