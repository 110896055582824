import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { SearchableDropDown } from "./SearchableDropDown";
import { getLabelWith } from "app/helpers/utilsHelper";

export const PosComboDropdown = ({
  handleChangeValue = () => {},
  selectedValue,
}) => {
  const { languageStrings } = useLanguageHelper();
  const { combos } = useSelector(({ banzzu }) => banzzu.pos);
  const [value, setValue] = useState("");
  const searchBarRef = useRef(null);
  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const combosList =
    combos && combos.combos && combos.combos.length ? combos.combos : [];

  const handleChange = (event) => {
    const val = event.target.value;
    const findCombo = combosList.find((item) => item.posReference === val);
    if (findCombo && findCombo.posReference) {
      handleChangeValue(findCombo, val);
      setValue(findCombo.posReference);
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    }
  };
  const { labelWith } = getLabelWith(languageStrings["SELECT_COMBO"]);
  return (
    <SearchableDropDown
      list={combosList}
      selectedValue={value}
      label={languageStrings["SELECT_COMBO"]}
      handleChange={handleChange}
      labelWidth={labelWith}
      keyPropFn={(option) => option.posReference}
      valuePropFn={(option) => option.posReferenceTitle}
      searchBarRef={searchBarRef}
    />
  );
};
