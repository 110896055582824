import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { TableCard } from "./TableCard";
import { getTableList } from "app/store/orders/OrdersActions";

const VenueTables = (props) => {
  const tableList = useSelector(({ banzzu }) => banzzu.order.tableList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.selectedVenue !== "0") {
      dispatch(getTableList(props.selectedVenue));
    }
  }, [dispatch, props.selectedVenue]);

  return (
    <div className="w-full px-20">
      <TableCard tableData={tableList} selectedVenue={props.selectedVenue} />
    </div>
  );
};

export default withReducer("MenuReducer", reducer)(VenueTables);
