import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { FC, useState } from "react";
import CustomModal from "./CustomModal";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import RoundedButton from "./RoundedButtton";
import { useDispatch, useSelector } from "react-redux";
import { paymentRefund } from "app/store/orders/OrdersActions";
import { convertCommaToDecimal } from "app/helpers/utilsHelper";
import useClientCurrency from "app/hooks/useClientCurrency";
interface Props {
  showSplitModal?: boolean;
  orderId?: string;
  price?: number;
  refundedAmount?: number;
  onCloseModal?: () => any;
  onRefundPress?: (amount?: number, orderId?: string) => any;
}
const SplitBillModal: FC<Props> = ({
  showSplitModal,
  onCloseModal = () => {},
  onRefundPress,
  price = 0,
  refundedAmount = 0,
  orderId = "",
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("full");
  const [amount, setAmount] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const orderList = useSelector(({ banzzu }) => banzzu.pagination.orderList);
  const { clientCurrency } = useClientCurrency();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const { languageStrings } = useLanguageHelper();
  const getTotalPrice = () => {
    let totalPrice = price;
    if (refundedAmount) {
      totalPrice -= refundedAmount;
    }
    return totalPrice;
  };
  const errorAmount = () => {
    let errorMSg = "";
    let isError = false;
    const _amount = convertCommaToDecimal(amount);
    if (_amount <= 0 && isFocused) {
      errorMSg = languageStrings["SPLIT_BILL_MODEL.AMONT_IS_MANDATORY"];
      isError = true;
    } else if (_amount > getTotalPrice() && isFocused) {
      errorMSg =
        languageStrings[
          "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID"
        ];

      isError = true;
    } else if (!isFocused && value === "split" && !_amount) {
      isError = true;
    }
    return { errorMSg, isError: value === "full" ? false : isError };
  };

  return (
    <CustomModal
      showModal={showSplitModal}
      onCloseModal={() => {
        setIsFocused(false);
        onCloseModal();
      }}
    >
      <div
        className={"flex flex-col bg-white p-24 rounded w-full sm:w-1/3 mx-32"}
      >
        <FormControl>
          <FormLabel
            style={{ marginBottom: "10px" }}
            id="demo-controlled-radio-buttons-group"
          >
            <Typography className="font-semibold text-lg">
              {languageStrings["REFOUND"]}
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="full"
              control={<Radio />}
              label={`${
                languageStrings["FULL_REFOUND"]
              } (${getTotalPrice().toFixed(2)} ${clientCurrency.symbol})`}
            />
            <FormControlLabel
              value="split"
              control={<Radio />}
              label={languageStrings["PARTIAL_REFOUND"]}
            />
          </RadioGroup>
          {value === "split" && (
            <TextField
              label={languageStrings["AMOUNT"]}
              id="longitude"
              name="longitude"
              type={"number"}
              value={amount}
              inputProps={{
                min: 0,
              }}
              onChange={(event) => {
                setIsFocused(true);
                setAmount(event.target.value);
              }}
              variant="outlined"
              fullWidth
              error={!!errorAmount()}
              helperText={errorAmount().errorMSg}
              style={{ marginTop: "2rem" }}
              required
            />
          )}
          <div>
            <RoundedButton
              disabled={errorAmount().isError}
              title={languageStrings["GENERAL.CANCEL_BUTTON"]}
              txtColor={"white"}
              style={{
                backgroundColor: "red",
                borderColor: "transparent",
                height: "4rem",
                marginTop: "20px",
                borderRadius: "10px",
                width: "45%",
                alignSelf: "center",
                marginRight: "10px",
              }}
              onClick={() => {
                onCloseModal();
              }}
            />
            <RoundedButton
              disabled={errorAmount().isError}
              title={languageStrings["GENERAL.CONFIRM_BUTTON"]}
              txtColor={"white"}
              style={{
                backgroundColor: errorAmount().isError
                  ? "lightgray"
                  : "rgb(29, 111, 66)",
                borderColor: "transparent",
                height: "4rem",
                marginTop: "20px",
                borderRadius: "10px",
                width: "45%",
                alignSelf: "center",
                marginLeft: "10px",
              }}
              onClick={() => {
                let _amount = 0;
                if (value === "full") {
                  _amount = getTotalPrice();
                } else {
                  _amount = convertCommaToDecimal(amount);
                }
                if (onRefundPress) onRefundPress(_amount, orderId);
                else dispatch(paymentRefund(_amount, orderId, orderList.limit));

                setIsFocused(false);
                onCloseModal();
                setValue("full");
                setAmount("");
              }}
            />
          </div>
        </FormControl>
      </div>
    </CustomModal>
  );
};

export default SplitBillModal;
