import { paginationActionTypes } from "../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: Pagination = {
  orderList: { limit: 10, page: 0 },
  paymentHistoryList: { limit: 10, page: 0 },
  virtualCartList: { limit: 10, page: 0 },
  loyaltyList: { limit: 10, page: 0 },
  tipList: { limit: 10, page: 0 },
  ratingList: { limit: 10, page: 0 },
};

interface Action {
  payload: any;
  type: string;
}

const PaginationReducer = (
  state: Pagination = INITIAL_STATE,
  action: Action
): Pagination => {
  switch (action.type) {
    case paginationActionTypes.SET_LIST_LIMIT_PAGE_ORDERS:
      return updateObject(state, {
        orderList: action.payload,
      });
    case paginationActionTypes.SET_LIST_LIMIT_PAGE_HISTORY:
      return updateObject(state, {
        paymentHistoryList: action.payload,
      });
    case paginationActionTypes.SET_LIST_LIMIT_PAGE_VIRTUAL_CART:
      return updateObject(state, {
        virtualCartList: action.payload,
      });
    case paginationActionTypes.SET_LIST_LIMIT_PAGE_LOYALTY:
      return updateObject(state, {
        loyaltyList: action.payload,
      });
    case paginationActionTypes.SET_LIST_LIMIT_PAGE_TIPS:
      return updateObject(state, {
        tipList: action.payload,
      });
    case paginationActionTypes.SET_LIST_LIMIT_PAGE_RATING:
      return updateObject(state, {
        ratingList: action.payload,
      });
    case paginationActionTypes.SET_LIST_LIMIT_PAGE_GENERAL:
      return state;
    default: {
      return state;
    }
  }
};

export default PaginationReducer;
