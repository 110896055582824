import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import ListTablePaginated from "../common/List/ListTablePaginated";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListHeader from "app/main/common/List/ListHeader";
import history from "@history";
import { Pagination } from "app/models/Pagination";
import { getLoyaltyClientsList } from "app/store/loyaltyProgram/LoyaltyProgramActions";
import { setListLimitPage } from "app/store/pagination/paginationActions";
import { setLoyaltySearchText } from "app/store/loyaltyProgram/LoyaltyProgramActions";

function LoyaltyProgramList() {
  const { searchText, loading, loyaltyClientList, totalClients } = useSelector(
    ({ banzzu }) => banzzu.loyaltyProgram
  );
  const { loyaltyList } = useSelector(({ banzzu }) => banzzu.pagination);
  const { limit = 10, page = 0 } = loyaltyList;
  const [rowsPerPage, setRowsPerPage] = useState(limit);
  const [pageNo, setPageNo] = useState(page);
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });
  const listType = Pagination.LOYALTY;

  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user._id) {
      dispatch(
        getLoyaltyClientsList(user._id, rowsPerPage, pageNo, searchText, order)
      );
    }
  }, [user, pageNo, rowsPerPage, searchText, order]);

  const _loyaltyClientList =
    loyaltyClientList && loyaltyClientList.length ? loyaltyClientList : [];

  const handleClick = (item) => {
    history.push("/loyaltyDetail/" + item.loyaltyClientId);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setListLimitPage(dispatch, rowsPerPage, page, listType);
  };
  const handlePageChange = (event, page) => {
    setPageNo(page);
    setListLimitPage(dispatch, rowsPerPage, page, listType);
  };

  const setSearchText = (ev) => {
    dispatch(setLoyaltySearchText(ev));
  };

  const handleRequestSort = (event, property) => {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }

    setOrder({
      direction,
      id,
    });
  };

  const { languageStrings } = useLanguageHelper();

  const headerRows = {
    data: [
      {
        id: "venueTitle",
        align: "center",
        disablePadding: false,
        label: languageStrings["GENERAL.VENUE"],
        sort: true,
        type: "loyaltyText",
      },
      {
        id: "name",
        align: "center",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "loyaltyText",
      },
      {
        id: "category",
        align: "center",
        disablePadding: false,
        label: languageStrings["GENERAL.CATEGORY"],
        sort: true,
        type: "loyaltyCategory",
      },
      {
        id: "email",
        align: "center",
        disablePadding: false,
        label: languageStrings["GENERAL.EMAIL"],
        sort: true,
        type: "loyaltyText",
      },
      {
        id: "orderCount",
        align: "center",
        disablePadding: false,
        label: languageStrings["LOYALTY_PROGRAM_CLIENT_ORDER_NUMBER"],
        sort: true,
        type: "loyaltyText",
      },
      {
        id: "clientTotal",
        align: "center",
        disablePadding: false,
        label: languageStrings["GENERAL.TOTAL_SPENT"],
        sort: true,
        type: "loyaltyText",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
    searchBy: ["name", "email"],
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  title={languageStrings["LOYALTY_PROGRAM"]}
                  searchType={"loyaltyProgram"}
                  setSearchText={setSearchText}
                />
              </div>
            }
            content={
              <ListTablePaginated
                handleClick={handleClick}
                headerRows={headerRows}
                data={_loyaltyClientList.map((client) => {
                  if (client.clientTotal) {
                    let obj = {
                      ...client,
                      clientTotal: Number(client.clientTotal).toFixed(2),
                    };
                    return obj;
                  }
                  return client;
                })}
                searchText={searchText}
                totalData={totalClients}
                order={order}
                page={pageNo}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handlePageChange={handlePageChange}
                handleRequestSort={handleRequestSort}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
    </>
  );
}

export default LoyaltyProgramList;
