import { VirtualCardOrdersType } from './../redux/actionTypes'
import { updateObject } from '../utility'

const INITIAL_STATE = {
  orderList: [],
  loading: false,
  searchText: '',
  orderDetail: {},
}

interface Action {
  payload: any
  type: string
}

const VirtualCardOrderReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case VirtualCardOrdersType.VIRTUAL_ORDER_LIST_START:
      return updateObject(state, { loading: true, orderList: [] })
    case VirtualCardOrdersType.VIRTUAL_ORDER_LIST_SUCCESS:
      return updateObject(state, {
        orderList: action.payload.data,
        loading: false,
      })

    case VirtualCardOrdersType.VIRTUAL_ORDER_LIST_FAIL:
      return updateObject(state, { loading: false })

    case VirtualCardOrdersType.VIRTUAL_ORDER_DETAIL_START:
      return updateObject(state, { loading: true, orderDetail: {} })

    case VirtualCardOrdersType.VIRTUAL_ORDER_DETAIL_SUCCESS:
      return updateObject(state, {
        orderDetail: action.payload.data,
        loading: false,
      })

    case VirtualCardOrdersType.VIRTUAL_ORDER_DETAIL_FAIL:
      return updateObject(state, { loading: false })

    case VirtualCardOrdersType.SET_VIRTUAL_ORDER_SEARCH_TEXT:
      return updateObject(state, { searchText: action.payload.searchText })

    default: {
      return state
    }
  }
}

export default VirtualCardOrderReducer
