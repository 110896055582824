import React, { useState, useEffect } from "react";
import {
  Modal,
  Backdrop,
  makeStyles,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import { useForm } from "@fuse/hooks";
import { VISIBILITY } from "app/models/Visibility";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { AddCircle } from "@material-ui/icons";
import VisibilityTabs from "./VisibilityTabs";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "20%",
    minWidth: "20%",
  },
  checked: {
    color: "#ff8330 !important",
  },
  formRoot: {
    "&$checked": {
      color: "#ff8330",
    },
  },
  rootSlider: {
    color: "#ff8330",
  },
}));

const VisibilityModalV2 = ({
  open,
  onClose,
  data,
  onVisibilityChange,
  showGlobal = true,
}) => {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const [tabValue, selectedTabValue] = useState(0);
  const { products } = useSelector(({ banzzu }) => banzzu.pos);
  const isPOSIntegration = products && products.isPOSIntegration ? true : false;

  const visibility = {
    visibility: VISIBILITY.ALWAYS_AVAILABLE,
    visibilityRanges: [
      {
        start: "00:00",
        end: "24:00",
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
    ],
    isVisibilityGlobal: false,
  };
  const { form, handleChange, setForm, resetForm } = useForm(visibility);

  useEffect(() => {
    const isVisibilityGlobal = data && data.isVisibilityGlobal ? true : false;
    if (data && data.visibility) {
      let visible = {
        visibility: data.visibility,
      };
      if (
        data &&
        data.visibilityRanges &&
        data.visibilityRanges.length &&
        Array.isArray(data.visibilityRanges)
      ) {
        visible = {
          ...visible,
          visibilityRanges: data.visibilityRanges,
        };
      }
      setForm((prevForm) => ({
        ...prevForm,
        ...visible,
        isVisibilityGlobal,
      }));
    }
  }, [data, setForm, open]);

  const onSubmit = () => {
    onVisibilityChange(form);
    resetForm && resetForm();
    onClose();
  };
  const tabs =
    form && form.visibilityRanges && form.visibilityRanges.length
      ? form.visibilityRanges
      : [];

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {
        resetForm && resetForm();
        onClose && onClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={clsx(classes.paper, "flex flex-col")}>
        <h2 id="transition-modal-title" className="pb-12">
          {languageStrings["VISIBILITY.TITLE"]}
        </h2>

        <RadioGroup
          name="visibility"
          value={form.visibility}
          onChange={handleChange}
        >
          <>
            <FormControlLabel
              className="text-xs"
              value={VISIBILITY.ALWAYS_AVAILABLE}
              classes={{ root: classes.formRoot }}
              labelPlacement="end"
              control={<Radio classes={{ checked: classes.checked }} />}
              label={languageStrings["VISIBILITY.ALWAYS_VISIBLE"]}
            />
            <FormControlLabel
              className="text-xs"
              value={VISIBILITY.DISABLED}
              classes={{ root: classes.formRoot }}
              labelPlacement="end"
              control={<Radio classes={{ checked: classes.checked }} />}
              label={languageStrings["VISIBILITY.DISABLED"]}
            />
            <FormControlLabel
              className="text-xs"
              value={VISIBILITY.SHOW_ONLY}
              classes={{ root: classes.formRoot }}
              labelPlacement="end"
              control={<Radio classes={{ checked: classes.checked }} />}
              label={languageStrings["VISIBILITY.SHOW_ONLY"]}
            />
          </>
        </RadioGroup>
        {showGlobal && !isPOSIntegration ? (
          <div className="mt-8">
            <FormControlLabel
              control={
                <Checkbox
                  name="isVisibilityGlobal"
                  onChange={handleChange}
                  checked={form && form.isVisibilityGlobal ? true : false}
                />
              }
              label={languageStrings["GLOBALL_VISIBILITY"]}
            />
          </div>
        ) : null}
        {form.visibility === VISIBILITY.SHOW_ONLY && (
          <div className="flex flex-col">
            <div className="flex flex-row items-center pb-12 pt-12">
              <h2>{languageStrings["VISIBILITY.TIME_RANGE"]}</h2>
              <AddCircle
                style={{
                  color: "#ff8330",
                  cursor: "pointer",
                }}
                className="ml-2"
                onClick={() => {
                  const list = [...tabs];
                  list.push({
                    start: "00:00",
                    end: "24:00",
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true,
                  });

                  setForm((prevForm) => ({
                    ...prevForm,
                    visibilityRanges: list,
                  }));
                  selectedTabValue(list.length - 1);
                }}
              />
            </div>
            <VisibilityTabs
              onDelete={(index) => {
                const newList = [...tabs];
                newList.splice(index, 1);
                setForm((prevForm) => ({
                  ...prevForm,
                  visibilityRanges: newList,
                }));
                const switchToIndex = index === 0 ? index : index - 1;
                selectedTabValue(switchToIndex);
              }}
              tabs={tabs}
              tabValue={tabValue}
              selectedTabValue={selectedTabValue}
              handleChange={(newData) => {
                const newList = [...tabs];
                if (newData && tabValue >= 0 && newList && newList[tabValue]) {
                  newList[tabValue] = newData;
                  setForm((prevForm) => ({
                    ...prevForm,
                    visibilityRanges: newList,
                  }));
                }
              }}
            />
          </div>
        )}
        <Button
          variant="contained"
          className="mt-12"
          style={{ backgroundColor: "#ff8330", color: "white" }}
          onClick={onSubmit}
        >
          {languageStrings["GENERAL.SAVE"]}
        </Button>
      </div>
    </Modal>
  );
};
export default VisibilityModalV2;
