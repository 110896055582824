import TipList from "./TipList";

export const TipConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/tip",
      component: TipList,
    },
    // {
    //   path: "/userDetail/:tipId",
    //   component: UserDetail,
    // },
  ],
};
