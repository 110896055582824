import { TIPS } from "app/api/Endpoint";
import { TipsActionTypes } from "app/store/redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";

export function setTipsSearchText(event) {
  return {
    type: TipsActionTypes.SET_TIPS_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
export const getTipList = (clientId, data) => {
  return (dispatch) => {
    dispatch({
      type: TipsActionTypes.TIPS_LIST_START,
    });
    axios
      .get(`${TIPS}/${clientId}`, { params: data })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getTipListSuccess(dispatch, res.data);
        } else {
          getTipsListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getTipsListFail(dispatch, "There was an error connection");
      });
  };
};

const getTipsListFail = (dispatch, errorMessage) => {
  dispatch({
    type: TipsActionTypes.TIPS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getTipListSuccess = (dispatch, data) => {
  dispatch({
    type: TipsActionTypes.TIPS_LIST_SUCCESS,
    payload: data,
  });
};
