import React from "react";
import { TableCell } from "@material-ui/core";
import LoyaltyCategories from "app/main/loyaltyProgram/components/LoyaltyCategories";

const LoyaltyCategoryCell = (props) => {
  const { category } = props;
  return (
    <TableCell className="truncate" component="th" scope="row" style={{textAlign: "center"}}>
      {category && <LoyaltyCategories status={category} />}
    </TableCell>
  );
};
export default LoyaltyCategoryCell;
