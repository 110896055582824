import React, { FC, useState } from "react";
import { Typography, Card, IconButton, Icon } from "@material-ui/core";
import { ExtrasCategory } from "app/models/ExtrasCatergory";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import { useDispatch, useSelector } from "react-redux";
import { updateGroupModifierVisibilityV2 } from "app/store/menuV2/MenuV2Actions";
import VisibilityBanner from "app/main/common/VisibilityBanner";
interface Props {
  extraCategory: ExtrasCategory;
  onEdit: () => void;
  onDelete: () => void;
  index: number;
  onlyRead?: boolean;
  disabled?: boolean;
  length?: number;
  onExtraCategoryPositionChange?: (
    action: string,
    extraCategory: ExtrasCategory
  ) => void;
  isCombo?: boolean;
}

const ExtraCategory = ({
  extraCategory,
  onEdit,
  onDelete,
  index,
  onlyRead,
  disabled,
  length,
  onExtraCategoryPositionChange,
  isCombo,
}: Props) => {
  const menuDetail = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  const dispatch = useDispatch();
  const [openVisibility, setOpenVisibility] = useState<boolean>(false);

  const onVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  const onVisibilityClose = () => {
    setOpenVisibility(!openVisibility);
  };
  const lastIndex = length - 1;
  return (
    <>
      <div>
        <Card className="mt-8 ml-16 pl-8 pr-8 w-11/12 mr-16 bg-grey-lightest">
          <div className="flex w-full justify-between">
            <div className="flex items-center">
              <Typography
                variant="subtitle1"
                component="h3"
                className="ml-16 w-full self-center"
              >
                {extraCategory.title}
              </Typography>
              <div className="mx-4">
                <VisibilityBanner
                  visibility={extraCategory && extraCategory.visibility}
                />
              </div>
            </div>

            <div className="flex justify-center items-center">
              {length && onExtraCategoryPositionChange && (
                <>
                  {length > 1 && index !== 0 && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onExtraCategoryPositionChange &&
                          onExtraCategoryPositionChange(
                            "increase",
                            extraCategory
                          );
                      }}
                    >
                      <Icon color="primary" className="text-14">
                        arrow_upward
                      </Icon>
                    </IconButton>
                  )}
                  {index !== lastIndex && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onExtraCategoryPositionChange &&
                          onExtraCategoryPositionChange(
                            "decrease",
                            extraCategory
                          );
                      }}
                    >
                      <Icon color="primary" className="text-14">
                        arrow_downward
                      </Icon>
                    </IconButton>
                  )}
                </>
              )}
              <IconButton onClick={onVisibilityClick}>
                <Icon className="material-symbols-outlined text-teal pr-8">
                  visibility
                </Icon>
              </IconButton>
              <IconButton onClick={onEdit} hidden={onlyRead ? true : false}>
                <Icon className="pr-16 text-blue">edit</Icon>
              </IconButton>
            </div>
          </div>
        </Card>
      </div>
      <VisibilityModalV2
        open={openVisibility}
        onClose={onVisibilityClose}
        data={extraCategory}
        showGlobal={false}
        onVisibilityChange={(item = {}) => {
          if (extraCategory && extraCategory._id) {
            dispatch(
              updateGroupModifierVisibilityV2({
                ...item,
                extraCategoryId: extraCategory._id,
                menuId: menuDetail && menuDetail._id,
              })
            );
          }
        }}
      />
    </>
  );
};

export default ExtraCategory;
