import React from "react";
import { TableCell } from "@material-ui/core";
import VisibilityBanner from "../../VisibilityBanner";

const VisibilityCell = ({ n = {} } = {}) => {
  const { visibility } = n;
  return (
    <TableCell className="truncate" component="th" scope="row">
      <VisibilityBanner visibility={visibility} />
    </TableCell>
  );
};

export default VisibilityCell;
