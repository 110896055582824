import React from "react";
import { TableCell, Button } from "@material-ui/core";

const SingleBtn = ({ actions, n }) => {
  const tipAmount =
    n && n.tipAmount && Number(n.tipAmount)
      ? Number(Number(n.tipAmount).toFixed(2))
      : 0;
  const total =
    n && n.totalPrice && Number(n.totalPrice)
      ? Number(Number(n.totalPrice).toFixed(2))
      : 0;
  const totalWithTip = total + tipAmount;

  return (
    <TableCell>
      {(!!n.paymentIntentId || n.paidBy === 2) &&
      totalWithTip - Number(n.refundedAmount) > 0 &&
      n.refundedAmount != Number((total + tipAmount).toFixed(2)) ? (
        <Button
          component="a"
          rel="noreferrer noopener"
          role="button"
          className="normal-case"
          variant="contained"
          color="primary"
          onClick={(ev) => {
            actions.onclick(n, ev);
          }}
        >
          {actions.text}
        </Button>
      ) : null}
    </TableCell>
  );
};

export default SingleBtn;
