import { DeliveryInfo, DeliveryPoint } from "./../../models/Venue";
import { axiosInstance as axios } from "app/api/axios";

import { VenuesActionTypes } from "app/store/redux/actionTypes";
import {
  getVenueListUrl,
  getVenueDetailUrl,
  getUserId,
  getDeliveryAvailabilityUrl,
  getCollectionAvailabilityUrl,
  DELIVERY_AVAILABILITY,
  COLLECTION_AVAILABILITY,
  VENUE,
  CLIENT_VENUE,
  VENUE_VALIDATE_EMAIL,
  GET_TAXES,
  AUTO_SERVICES,
} from "app/api/Endpoint";
import { Venue } from "app/models/Venue";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import _ from "@lodash";
import { SAVE_SQUARE_TOKENS } from "app/api/VenueEndpoints";

export function setVenueSearchText(event) {
  return {
    type: VenuesActionTypes.SET_VENUES_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

const resetVenueSuccess = () => ({
  type: VenuesActionTypes.RESET_VENUE_SUCCESS,
});

export const getVenueList = () => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_LIST_START,
    });

    const url = getVenueListUrl();

    axios
      .get(url)
      .then((res) => {
        getVenueListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getVenueListFail(dispatch, "There was an error connection");
      });
  };
};

const getVenueListFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

export const getVenueListSuccess = (dispatch, data) => {
  dispatch({
    type: VenuesActionTypes.VENUES_LIST_SUCCESS,
    payload: data,
  });
};

export const getVenueDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_DETAIL_START,
    });

    const url = getVenueDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getVenueDetailSuccess(dispatch, res.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          getVenueDetailFail(dispatch, "Unauthorized");
        } else {
          getVenueDetailFail(dispatch, "There was an error connection");
        }
      });
  };
};

const getVenueDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/venueList`,
  });
};

const getVenueDetailSuccess = (dispatch, data) => {
  dispatch({
    type: VenuesActionTypes.VENUES_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createVenue = (venue: Venue) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_CREATE_START,
    });

    const request = {
      ...venue,
      clientId: getUserId(),
    };

    axios
      .post(VENUE, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          createVenueSuccess(dispatch, res.data);
        } else {
          createVenueFail(dispatch, res.data.message.description);
        }
      })
      .catch(() => {
        createVenueFail(dispatch, "There was an error connection");
      });
  };
};

const createVenueFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: VenuesActionTypes.VENUES_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createVenueSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);

  data = { data };

  dispatch({
    type: VenuesActionTypes.VENUES_CREATE_SUCCESS,
    payload: data,
  });

  setTimeout(() => dispatch(resetVenueSuccess()), 15000);

  History.push({
    pathname: "/venueList/",
  });
};

export const createClientVenue = (venue: Venue) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_CREATE_START,
    });

    const request = {
      ...venue,
      clientId: getUserId(),
    };

    axios
      .post(CLIENT_VENUE, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          createClientVenueSuccess(dispatch, res.data);
        } else {
          createClientVenueFail(dispatch, res.data.message.description);
        }
      })
      .catch(() => {
        createClientVenueFail(dispatch, "There was an error connection");
      });
  };
};

const createClientVenueFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: VenuesActionTypes.VENUES_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createClientVenueSuccess = (dispatch, data) => {
  data = { data };
  dispatch({
    type: VenuesActionTypes.VENUES_CREATE_SUCCESS,
    payload: data,
  });
};

export const editVenue = (
  venue?: Venue,
  points?: DeliveryPoint[],
  deliveryInfo?: DeliveryInfo[]
) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_EDIT_START,
    });

    if (points) {
      points.forEach((item, i) => {
        item.position = i.toString();
      });
    }

    const {
      _id,
      title,
      email,
      password,
      address,
      facebook,
      instagram,
      twitter,
      website,
      phone,
      postcode,
      latitude,
      longitude,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      minDeliveryPrice,
      minOrderPrice,
      isMaster,
      cif,
      razonSocial,
      autoServices,
      reviewOrderTiming,
      service,
      serviceFee,
      minimumOrderPrice,
      tip,
      hasDiscountVoucher,
    } = venue;

    const payload = {
      venueId: _id,
      title,
      email,
      password,
      cif,
      razonSocial,
      service,
      serviceFee,
      autoServices,
      reviewOrderTiming: Number(reviewOrderTiming),
      contactInfo: {
        address,
        facebook,
        instagram,
        twitter,
        website,
        phone,
        postcode,
        latitude,
        longitude,
      },
      openingHours: {
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      },
      deliveryInfo,
      orderInfo: {
        minDeliveryPrice,
        minOrderPrice,
      },
      deliveryPoints: points,
      minimumOrderPrice,
      tip,
      hasDiscountVoucher,
    };

    axios
      .put(VENUE, payload)
      .then((res) => {
        editVenueSuccess(dispatch, res.data, isMaster);
      })
      .catch((error) => {
        editVenueFail(
          dispatch,
          error.response.data.message || "There was an error connection"
        );
      });
  };
};

const editVenueFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: VenuesActionTypes.VENUES_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editVenueSuccess = (dispatch, data, isMaster) => {
  dispatch({
    type: VenuesActionTypes.VENUES_EDIT_SUCCESS,
    payload: data,
  });
  successAlert(dispatch, data.message);

  History.push({
    pathname: isMaster ? "/venueList/" : "/home",
  });
};

export const saveSquareTokens = (data) => {
  console.log("saveSquareTokensInVenue")
  return (dispatch) => {
    dispatch({ type: VenuesActionTypes.VENUE_ADD_SQUARE_TOKEN_START });
    axios
      .put(SAVE_SQUARE_TOKENS, data)
      .then((res) => {
        console.log('success => ', res)
        if (res && res.data) {
          saveSquareTokensSuccess(dispatch, res.data);
        }
      })
      .catch((error) => {
        console.log('error in => ', error)
        saveSquareTokensFail(dispatch, error);
      });
  };
};

export const saveSquareTokensSuccess = (dispatch, data) => {
  console.log("saveSquareTokensSuccess => ", data)
  const { message = "" } = data;
  successAlert(dispatch, message);
  dispatch({
    type: VenuesActionTypes.VENUE_ADD_SQUARE_TOKEN_SUCCESS,
    payload: data.data,
  });
  History.push({
    pathname: `/venueDetail/${data.data._id}`,
    state: {
      tabValue: 7
    }
  });
};
export const saveSquareTokensFail = (dispatch, data) => {
  console.log("saveSquareTokensFail")
  dispatch({
    type: VenuesActionTypes.VENUE_ADD_SQUARE_TOKEN_FAIL,
    payload: data,
  });
};

export const deleteVenue = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_DELETE_START,
    });

    const ids = _.join(idArray, "','");
    const idsFinal = "'" + ids + "'";

    const request = {
      data: {
        ids: idsFinal,
      },
    };

    axios
      .delete(VENUE, request)
      .then((res) => {
        deleteVenueSuccess(dispatch, res.data);
      })
      .catch(() => {
        deleteVenueFail(dispatch, "There was an error connection");
      });
  };
};

const deleteVenueFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteVenueSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getVenueList());
  dispatch({
    type: VenuesActionTypes.VENUES_DELETE_SUCCESS,
    payload: data,
  });
};

export const updatePoints = (points) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.UPDATE_POINTS_VENUE,
      payload: points,
    });
  };
};

export const addDeliveryAvailability = (
  venueId: string,
  startDate,
  endDate,
  initialTime,
  endTime
) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_ADD_DELIVERY_AVAILABILITY_START,
    });

    const request = {
      venueId,
      startDate,
      endDate,
      initialTime,
      endTime,
    };

    axios
      .post(DELIVERY_AVAILABILITY, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          addDeliveryAvailabilitySuccess(dispatch, res.data, venueId);
        } else {
          addDeliveryAvailabilityFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        addDeliveryAvailabilityFail(dispatch, "There was an error connection");
      });
  };
};

const addDeliveryAvailabilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_ADD_DELIVERY_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const addDeliveryAvailabilitySuccess = (dispatch, data, venueId) => {
  dispatch(getDeliveryAvailability(venueId));
  dispatch({
    type: VenuesActionTypes.VENUES_ADD_DELIVERY_AVAILABILITY_SUCCESS,
    payload: data,
  });
};

export const getDeliveryAvailability = (venueId: string) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_GET_DELIVERY_AVAILABILITY_START,
    });

    const url = getDeliveryAvailabilityUrl(venueId);

    axios
      .get(url)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getDeliveryAvailabilitySuccess(dispatch, res.data);
        } else {
          getDeliveryAvailabilityFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getDeliveryAvailabilityFail(dispatch, "There was an error connection");
      });
  };
};

const getDeliveryAvailabilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_GET_DELIVERY_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getDeliveryAvailabilitySuccess = (dispatch, data) => {
  dispatch({
    type: VenuesActionTypes.VENUES_GET_DELIVERY_AVAILABILITY_SUCCESS,
    payload: data,
  });
};

export const deleteDeliveryAvailability = (
  availabilityID: string,
  venueId: string
) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_DELETE_DELIVERY_AVAILABILITY_START,
    });

    const request = {
      data: {
        id: availabilityID,
        venueId,
      },
    };

    axios
      .delete(DELIVERY_AVAILABILITY, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          deleteDeliveryAvailabilitySuccess(dispatch, res.data, venueId);
        } else {
          deleteDeliveryAvailabilityFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        deleteDeliveryAvailabilityFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const deleteDeliveryAvailabilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_DELETE_DELIVERY_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteDeliveryAvailabilitySuccess = (dispatch, data, venueId) => {
  dispatch(getDeliveryAvailability(venueId));
  dispatch({
    type: VenuesActionTypes.VENUES_DELETE_DELIVERY_AVAILABILITY_SUCCESS,
    payload: data,
  });
};

export const addCollectionAvailability = (
  venueId: string,
  startDate,
  endDate,
  initialTime,
  endTime
) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_ADD_COLLECTION_AVAILABILITY_START,
    });

    const request = {
      venueId,
      startDate,
      endDate,
      initialTime,
      endTime,
    };

    axios
      .post(COLLECTION_AVAILABILITY, request)
      .then((res) => {
        addCollectionAvailabilitySuccess(dispatch, res.data, venueId);
      })
      .catch(() => {
        addCollectionAvailabilityFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const addCollectionAvailabilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_ADD_COLLECTION_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const addCollectionAvailabilitySuccess = (dispatch, data, venueId) => {
  dispatch(getCollectionAvailability(venueId));
  dispatch({
    type: VenuesActionTypes.VENUES_ADD_COLLECTION_AVAILABILITY_SUCCESS,
    payload: data,
  });
};

export const getCollectionAvailability = (venueId: string) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_GET_COLLECTION_AVAILABILITY_START,
    });

    const url = getCollectionAvailabilityUrl(venueId);

    axios
      .get(url)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getCollectionAvailabilitySuccess(dispatch, res.data);
        } else {
          getCollectionAvailabilityFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getDeliveryAvailabilityFail(dispatch, "There was an error connection");
      });
  };
};
export const getTaxes = () => {
  return (dispatch) => {
    axios
      .get(GET_TAXES)
      .then((res) => {
        if (res && res.data) {
          getTaxesSuccess(dispatch, res.data);
        }
      })
      .catch(() => {});
  };
};

const getCollectionAvailabilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_GET_COLLECTION_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getCollectionAvailabilitySuccess = (dispatch, data) => {
  dispatch({
    type: VenuesActionTypes.VENUES_GET_COLLECTION_AVAILABILITY_SUCCESS,
    payload: data,
  });
};

export const deleteCollectionAvailability = (
  availabilityID: string,
  venueId: string
) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_DELETE_COLLECTION_AVAILABILITY_START,
    });

    const request = {
      data: {
        id: availabilityID,
        venueId,
      },
    };

    axios
      .delete(COLLECTION_AVAILABILITY, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          deleteCollectionAvailabilitySuccess(dispatch, res.data, venueId);
        } else {
          deleteCollectionAvailabilityFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        deleteCollectionAvailabilityFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const deleteCollectionAvailabilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_DELETE_COLLECTION_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteCollectionAvailabilitySuccess = (dispatch, data, venueId) => {
  dispatch(getCollectionAvailability(venueId));
  dispatch({
    type: VenuesActionTypes.VENUES_DELETE_COLLECTION_AVAILABILITY_SUCCESS,
    payload: data,
  });
};

export const checkEmail = (email: string) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.CHECK_EMAIL_START,
    });

    const request = {
      email,
    };

    axios
      .post(VENUE_VALIDATE_EMAIL, request)
      .then((res) => {
        checkEmailSuccess(dispatch, res.data);
      })
      .catch(() => {
        checkEmailFail(dispatch, "There was an error connection");
      });
  };
};

const checkEmailFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: VenuesActionTypes.CHECK_EMAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const checkEmailSuccess = (dispatch, data) => {
  dispatch({
    type: VenuesActionTypes.CHECK_EMAIL_SUCCESS,
    payload: data.data,
  });
};

const getTaxesSuccess = (dispatch, data) => {
  dispatch({
    type: VenuesActionTypes.GET_TAXES_SUCCESS,
    payload: data.data,
  });
};
export const setSelectedVenue = (dispatch, selectedVenueId) => {
  dispatch({
    type: VenuesActionTypes.SET_VENUEID_SUCCESS,
    payload: selectedVenueId,
  });
};

export const addAutoServices = (venueId: string, payload) => {
  return async (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_ADD_AUTO_SERVICES_START,
    });

    const request = {
      venueId,
      ...payload,
    };

    await axios
      .post(AUTO_SERVICES, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          addAutoServicesSuccess(dispatch, res.data, venueId);
        } else {
          addAutoServicesFail(dispatch, res.data.errorMessage);
        }
      })
      .catch((error) => {
        addAutoServicesFail(dispatch, "There was an error connection");
      });
  };
};

const addAutoServicesFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_ADD_AUTO_SERVICES_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const addAutoServicesSuccess = (dispatch, data, venueId) => {
  dispatch(getVenueDetail(venueId));
  dispatch({
    type: VenuesActionTypes.VENUES_ADD_AUTO_SERVICES_SUCCESS,
    payload: data,
  });
};

export const deleteAutoService = (autoServiceID: string, venueId: string) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_DELETE_AUTO_SERVICES_START,
    });

    const request = {
      data: {
        id: autoServiceID,
        venueId,
      },
    };

    axios
      .delete(AUTO_SERVICES, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          deleteAutoServiceSuccess(dispatch, res.data, venueId);
        } else {
          deleteAutoServiceFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        deleteAutoServiceFail(dispatch, "There was an error connection");
      });
  };
};

const deleteAutoServiceFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_DELETE_AUTO_SERVICES_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteAutoServiceSuccess = (dispatch, data, venueId) => {
  dispatch(getVenueDetail(venueId));
  dispatch({
    type: VenuesActionTypes.VENUES_DELETE_AUTO_SERVICES_SUCCESS,
    payload: data,
  });
};
export const updateAutoService = (venueId: string, payload) => {
  return (dispatch) => {
    dispatch({
      type: VenuesActionTypes.VENUES_UPDATE_AUTO_SERVICES_START,
    });

    const request = {
      data: {
        id: payload._id,
        venueId,
        ...payload,
      },
    };

    axios
      .put(AUTO_SERVICES, request)
      .then((res) => {
        if (res.data.statusCode === 200) {
          updateAutoServiceSuccess(dispatch, res.data, venueId);
        } else {
          deleteAutoServiceFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        updateAutoServiceFail(dispatch, "There was an error connection");
      });
  };
};

const updateAutoServiceFail = (dispatch, errorMessage) => {
  dispatch({
    type: VenuesActionTypes.VENUES_UPDATE_AUTO_SERVICES_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateAutoServiceSuccess = (dispatch, data, venueId) => {
  dispatch(getVenueDetail(venueId));
  dispatch({
    type: VenuesActionTypes.VENUES_UPDATE_AUTO_SERVICES_SUCCESS,
    payload: data,
  });
};
