import React from "react";
import {
  Icon,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVenue } from "app/store/venues/VenuesActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function OrderFromTableHeader(props) {
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const { languageStrings } = useLanguageHelper();
  const { selectedVenue = {} } = props ? props : {};
  const { _id = "" } = selectedVenue ? selectedVenue : {};
  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        <FuseAnimate animation="transition.expandIn" delay={300}>
          <Icon className="text-32 mr-0 sm:mr-12">store</Icon>
        </FuseAnimate>
        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
          <Typography className="hidden sm:flex" variant="h6">
            {languageStrings["SIDEBAR_MENUE.ORDER_FROM_TABLE_LIST"]}
          </Typography>
        </FuseAnimate>
      </div>
      <FormControl className="w-1/2 " variant="outlined">
        <InputLabel className="mt-10" htmlFor="outlined-venue-native-simple">
          {languageStrings["ORDER_FROM_TABLE.VENUES"]}
        </InputLabel>
        <Select
          value={_id}
          className="mt-10 mb-16 mr-8"
          native
          required
          onChange={(e) => {
            const _venueDetail = venueList.find(
              (item) => item._id === e.target.value
            );
            props.onVenueChange(_venueDetail);
          }}
          input={
            <OutlinedInput
              name="venues"
              labelWidth={100}
              id="outlined-venue-native-simple"
            />
          }
        >
          {venueList.map((venue) => (
            <option value={venue._id}>{venue.title}</option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default OrderFromTableHeader;
