import React, { useContext, useEffect, useState } from "react";
import { formatImageUrl } from "app/helpers/utilsHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  getAutoServiceOrderStatus,
  getAutoServiceOrderStatusById,
} from "app/store/autoService/autoServiceAction";
import LoadingOverlay from "react-loading-overlay";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import {
  Modal,
  Fade,
  Backdrop,
  Icon,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import { SocketContext } from "app/SocketContext";
import { useSocketListener } from "@fuse/hooks";

import banzzuLogo from "../../../images/new_check_logo.png";
import HeaderButton from "./component/HeaderButton";
export default function AutoService() {
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const { setSelectedVenueIO, socket } = useContext(SocketContext);
  const { languageStrings } = useLanguageHelper();
  const [showModal, setShowModal] = useState(false);
  const [ordeList, setOrderList] = useState();
  const [selectedVenue, setSelectedVenue] = useState();
  const [autoServiceVenueList, setAutoServiceVenueList] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { _id } = selectedVenue || {};
  const bgColor = "#fff";

  const primaryColor =
    user.settings && user.settings.autoServiceCardBgColor
      ? user.settings.autoServiceCardBgColor
      : "#013334";
  const SecondaryColor =
    user.settings && user.settings.autoServiceCardBorderColor
      ? user.settings.autoServiceCardBorderColor
      : "#02CFD3";

  useSocketListener(socket, "monitorBoardData", (data) => {
    setOrderList(data[0]);
  });

  useSocketListener(socket, "newOrder", (data) => {
    setOrderList((prevState) => ({
      ...prevState,
      preparedOrders: [data, ...prevState.preparedOrders],
    }));
  });
  const setModalOpen = (toogle) => {
    setShowModal(toogle);
  };
  const setOrderListData = (orderList) => {
    setOrderList(orderList);
    setLoading(false);
  };
  const filterAutoServiceVenue = (array) => {
    return array.filter((venue) => venue.autoServices);
  };
  useEffect(() => {
    dispatch(
      getAutoServiceOrderStatus(
        setModalOpen,
        setOrderListData,
        setSelectedVenueIO,
        languageStrings,
        setLoading
      )
    );
  }, [user]);

  useEffect(() => {
    if (venueList && venueList.length > 0) {
      const venues = filterAutoServiceVenue(venueList);
      setAutoServiceVenueList(venues);
      setSelectedVenue(venues[0]);
    }
  }, [venueList]);

  const onSubmit = async () => {
    if (selectedVenue) {
      setLoading(true);
      dispatch(
        getAutoServiceOrderStatusById(
          selectedVenue._id,
          setOrderListData,
          setSelectedVenueIO,
          languageStrings,
          setLoading
        )
      );
      setModalOpen(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingOverlay
          active={true}
          spinner
          styles={{
            wrapper: {
              width: "100%",
              height: "100%",
              overflow: loading ? "hidden" : "scroll",
            },
          }}
          text="Loading..."
        />
      ) : (
        <>
          {user.settings && user.settings.autoServiceFeatureEnabled ? (
            <div
              className="flex items-center w-full h-full border-2 gap-10  flex-col p-5 overflow-hidden "
              style={{
                backgroundColor: bgColor,
              }}
            >
              <div
                style={{ width: "90%" }}
                className="flex items-center gap-10"
              >
                <HeaderButton
                  heading={"PREPARATION"}
                  bg={primaryColor}
                  borderColor={SecondaryColor}
                />
                <img
                  className="object-contain h-128 xl:h-160"
                  src={
                    user && user.settings && user.settings.autoServiceLogo
                      ? formatImageUrl(user.settings.autoServiceLogo)
                      : "assets/images/avatars/noimage.png"
                  }
                />
                <HeaderButton
                  heading={"READY_TO_PICK"}
                  bg={primaryColor}
                  borderColor={SecondaryColor}
                />
              </div>
              <div
                className="flex w-full  h-full gap-5 pt-24 xxl:pt-64"
                style={{ overflow: "hidden" }}
              >
                <div className="grid grid-cols-4 xl:grid-cols-4  md:grid-cols-4  w-full h-full justify-start  gap-24">
                  {ordeList &&
                    ordeList.preparedOrders.slice(0, 16).map((order, idx) => {
                      return (
                        <div className="flex gap-5 items-center  flex-col w-full  h-full ">
                          <Typography
                            className="text-red-light font-700 font-product_sans"
                            style={{ fontSize: "3vw" }}
                          >
                            {order.ticketNo}
                          </Typography>
                          {order &&
                            order.orderClientName &&
                            order.orderClientName.length > 0 && (
                              <>
                                <div
                                  className="h-1 xl:h-2 w-60"
                                  style={{
                                    background:
                                      "radial-gradient(50% 50% at 50% 50%, #FE6D4E 0%, rgba(255, 255, 255, 0) 100%)",
                                  }}
                                />

                                <Typography
                                  className=" text-red-light font-400 whitespace-no-wrap font-product_sans "
                                  style={{ fontSize: "1.4vw" }}
                                >
                                  {order.orderClientName}
                                </Typography>
                              </>
                            )}
                        </div>
                      );
                    })}
                </div>
                <div
                  className="h-full w-5 xl:w-20"
                  style={{
                    background:
                      "radial-gradient(50% 50% at 50% 50%, #013334 0%, rgba(151, 219, 221, 0) 100%)",
                  }}
                />

                <div className="grid grid-cols-4 xl:grid-cols-4  md:grid-cols-4  w-full h-full justify-start  gap-24 ">
                  {ordeList &&
                    ordeList.readyToPickUp.slice(0, 16).map((order, idx) => {
                      return (
                        <div className="flex gap-5 items-center  flex-col w-full  h-full  ">
                          <Typography
                            className="font-700 font-product_sans"
                            style={{ color: "#338501", fontSize: "3vw" }}
                          >
                            {order.ticketNo}
                          </Typography>
                          {order &&
                            order.orderClientName &&
                            order.orderClientName.length > 0 && (
                              <>
                                <div
                                  className="h-full"
                                  style={{
                                    width: 93,
                                    height: 8,
                                    background:
                                      "radial-gradient(50% 50% at 50% 50%, #338501 0%, rgba(255, 255, 255, 0) 100%)",
                                  }}
                                />
                                <Typography
                                  className="font-400 whitespace-no-wrap font-product_sans "
                                  style={{
                                    color: "#338501",
                                    fontSize: "1.4vw",
                                  }}
                                >
                                  {order.orderClientName}
                                </Typography>
                              </>
                            )}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="flex items-end justify-end w-full flex-col gap-12 px-10  mr-10">
                <Typography
                  className="text-xs md:text-8"
                  style={{ color: "#BABABA" }}
                >
                  Powered By
                </Typography>
                <img className="w-48 h-48" src={banzzuLogo} alt="check_logo" />
              </div>
              <Modal
                open={showModal}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="flex w-full bg-white justify-center items-center flex-col">
                  <h2 id="modal-title">
                    {languageStrings["SELECT_VENUE_TO_PROCEED"]}
                  </h2>
                  <FormControl className="w-1/2 " variant="outlined">
                    <Select
                      value={_id}
                      className="mt-10 mb-16 mr-8"
                      native
                      required
                      onChange={(e) => {
                        const _venueDetail = venueList.find(
                          (item) => item._id === e.target.value
                        );
                        setSelectedVenue(_venueDetail);
                      }}
                      input={
                        <OutlinedInput
                          name="venues"
                          labelWidth={100}
                          id="outlined-venue-native-simple"
                        />
                      }
                    >
                      {autoServiceVenueList &&
                        autoServiceVenueList.map((venue) => (
                          <option value={venue._id}>{venue.title}</option>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    className="whitespace-no-wrap px-10 text-20"
                    variant="contained"
                    onClick={onSubmit}
                  >
                    {languageStrings["SUBMIT"]}
                  </Button>
                </div>
              </Modal>
            </div>
          ) : (
            <div className="flex w-full h-full border-2  flex-col p-5 overflow-hidden "></div>
          )}
        </>
      )}
    </>
  );
}
