export enum STORAGE_KEY {
  ACCESS_TOKEN = "access_token",
  LOCALE = "locale",
  USER_ID = "idUser",
  MASTER_ID = "masterId",
  EXPIRES_AT = "expires_at",
  AUTH_O_SSO_DATA = "auth0.ssodata",
}

/**
 * Retrieves a value from sessionStorage by its key, and parses it as type `T`.
 * @param key The key to look up in sessionStorage.
 * @returns The parsed value as type `T`, or `null` if no value is found for the given key.
 * @template T The type of the value to parse from sessionStorage. Defaults to `unknown`.
 */
export const getStorageItem = (key: STORAGE_KEY): string => {
  const value = window.sessionStorage.getItem(key);
  return value;
};

/**
 * Saves a value to sessionStorage with the given key.
 * @param key The key to use to store the value in sessionStorage.
 * @param value The value to save to sessionStorage.
 */
export const setStorageItem = (key: STORAGE_KEY, value: string) => {
  window.sessionStorage.setItem(key, value);
};

/**
 * Removes a value from sessionStorage by its key.
 * @param key The key to remove from sessionStorage.
 */
export const removeStorageItem = (key: STORAGE_KEY) => {
  window.sessionStorage.removeItem(key);
};
