import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import LoadingOverlay from "react-loading-overlay";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FuseAnimate } from "@fuse";
import DetailCard from "app/main/pricing/components/DetailCard";
import QuantityCard from "app/main/pricing/components/QuantityCard";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getCardList } from "app/store/payment/PaymentActions";
import OptionsCard from "../common/payment/OptionsCard";
import PaymentCard from "app/main/common/payment/PaymentCard";
import { createSubscription } from "app/store/payment/PaymentActions";
import useClientCurrency from "app/hooks/useClientCurrency";

const useStyles = makeStyles((theme) => ({
  header: {
    height: 400,
    background:
      "linear-gradient(to right, " +
      theme.palette.primary.dark +
      " 0%, " +
      theme.palette.primary.main +
      " 100%)",
    color: theme.palette.primary.contrastText,
  },
  badge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
}));

const UpgradeSubscriptionPage = () => {
  const classes = useStyles();

  const cards = useSelector(({ banzzu }) => banzzu.payment.cards);
  const loading = useSelector(({ banzzu }) => banzzu.payment.loading);
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const dispatch = useDispatch();

  const { languageStrings } = useLanguageHelper();

  const [quantityHidden, setQuantityHidden] = useState(false);
  const [previous, setPrevious] = useState("quantity");
  const [cardsHidden, setCardsHidden] = useState(true);
  const [addCardHidden, setAddCardHidden] = useState(true);
  const [venueQuantity, setVenueQuantity] = useState(1);
  const [price, setPrice] = useState(34.95);
  const [selectedCard, setSelectedCard] = useState("");
  const { clientCurrency } = useClientCurrency();
  useEffect(() => {
    dispatch(getCardList());
  }, []);

  useEffect(() => {
    if (cards.length) {
      cards.map((card) => {
        if (card.default) {
          setSelectedCard(card.id);
        }
      });
    }
  }, [cards]);

  const changeQuantity = () => {
    setPrevious("quantity");
    setQuantityHidden(true);
    if (cards.length) {
      setCardsHidden(false);
    } else {
      setAddCardHidden(false);
    }
  };

  const decreaseQuantity = () => {
    if (venueQuantity > 1) {
      setVenueQuantity(venueQuantity - 1);
      setPrice(parseFloat((price - 34.95).toFixed(2)));
    }
  };

  const increaseQuantity = () => {
    setVenueQuantity(venueQuantity + 1);
    setPrice(parseFloat((price + 34.95).toFixed(2)));
  };

  const onCardSelected = () => {
    dispatch(createSubscription({ id: selectedCard }, venueQuantity));
  };

  const onBackPayment = () => {
    if (previous === "cards") {
      setCardsHidden(false);
      setAddCardHidden(true);
      setPrevious("quantity");
    }

    if (previous === "quantity") {
      setQuantityHidden(false);
      setAddCardHidden(true);
      setCardsHidden(true);
    }
  };

  const createNewSubscription = (cardDetails) => {
    dispatch(createSubscription(cardDetails, venueQuantity));
  };

  const addNewCard = () => {
    setPrevious("cards");
    setAddCardHidden(false);
    setCardsHidden(true);
  };

  const onBackCardSelection = () => {
    setQuantityHidden(false);
    setCardsHidden(true);
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <div className={clsx(classes.header, "flex")}>
        <div className="p-24 w-full max-w-2xl mx-auto">
          <div className="text-center my-32 mx-24">
            <FuseAnimate
              animation="transition.slideUpIn"
              duration={400}
              delay={100}
            >
              <>
                <Typography variant="h2" color="inherit" className="font-light">
                  Advanced
                  {quantityHidden && (
                    <div className="flex flex-col justify-center mb-8">
                      <div className="flex flex-row justify-center">
                        <Typography variant="subtitle1" className="font-medium">
                          {user.settings.venuesPaid +
                            " " +
                            languageStrings["PAYMENT_PAGE.VENUE"] +
                            " + "}
                        </Typography>
                        <Typography variant="subtitle1" className="font-medium">
                          {venueQuantity +
                            " " +
                            languageStrings["PAYMENT_PAGE.VENUE"]}
                        </Typography>
                      </div>
                      <Typography
                        variant="h5"
                        className="font-medium"
                        style={{ color: "#f5aa00" }}
                      >
                        {price} {clientCurrency.symbol} /
                        {languageStrings["PAYMENT_PAGE.MONTH"]}
                      </Typography>
                    </div>
                  )}
                </Typography>
              </>
            </FuseAnimate>
          </div>
        </div>
      </div>

      <div className="-mt-192">
        <div className="w-full max-w-2xl mx-auto flex justify-center flex-wrap">
          <DetailCard />

          {!addCardHidden && (
            <PaymentCard
              onBack={onBackPayment}
              onSubmit={createNewSubscription}
            />
          )}

          {!cardsHidden && (
            <OptionsCard
              title={languageStrings["PAYMENT.CHOOSE_PAYMENT_METHOD"]}
              addTitle={languageStrings["PAYMENT.ADD_CARD"]}
              innerCards={
                cards && cards.length > 0
                  ? cards.map((card) => {
                      return {
                        ...card,
                        _id: card.id,
                        title: `**** **** **** ${card.last4}`,
                      };
                    })
                  : []
              }
              onBack={onBackCardSelection}
              addClickHandler={addNewCard}
              selectedCard={selectedCard}
              onSelect={(id) => setSelectedCard(id)}
              onSubmit={onCardSelected}
            />
          )}

          {!quantityHidden && (
            <QuantityCard
              quantity={venueQuantity}
              price={price}
              onSubmit={changeQuantity}
              increaseQuantity={increaseQuantity}
              decreaseQuantity={decreaseQuantity}
              title={
                languageStrings[
                  "PAYMENT.HOW_MANY_RESTAURANTS_DO_YOU_WANT_TO_ADD"
                ]
              }
            />
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default UpgradeSubscriptionPage;
