import React, { useState, useEffect } from "react";
import {
  Button,
  Tab,
  Tabs,
  Icon,
  Typography,
  TextField,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
} from "@material-ui/core";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "@fuse/hooks";
import { FuseAnimate, FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import History from "@history";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { isEmailValid } from "app/helpers/validators";
import {
  createStaff,
  editStaff,
  getStaffDetail,
} from "app/store/staff/StaffActions";
import { getVenueList, checkEmail } from "app/store/venues/VenuesActions";

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function StaffDetail({ match }) {
  const { languageStrings } = useLanguageHelper();
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();

  let staff = useSelector(({ banzzu }) => banzzu.staff.staffDetail);
  const loading = useSelector(({ banzzu }) => banzzu.staff.loading);
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const emailTaken = useSelector(({ banzzu }) => banzzu.venue.emailTaken);

  const { form, handleChange, setForm } = useForm(null);

  const { productId } = match.params;

  if (productId === "new") {
    staff = {
      _id: "0",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      venues: [],
    };
  }

  useEffect(() => {
    dispatch(getVenueList());
    if (productId !== "new") {
      dispatch(getStaffDetail(productId));
    }
  }, []);

  useEffect(() => {
    if ((staff && !form) || (staff && form && staff._id !== form._id)) {
      setForm(staff);
    }
  }, [form, staff, setForm]);

  const canBeSubmitted = () => {
    let formValid =
      form &&
      form.firstName &&
      form.firstName.length > 3 &&
      form.lastName &&
      form.lastName.length > 3 &&
      isEmailValid(form.email) &&
      !emailTaken;

    if (form && form._id === "0") {
      formValid = formValid && form.password && form.password.length > 6;
    }

    return formValid;
  };

  function previousPage() {
    History.push({
      pathname: `/staff-list`,
    });
  }

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  const onPermissionChange = (e) => {
    let venues = [...form.venues];
    const foundIndex = venues.indexOf(e.target.value);
    if (foundIndex > -1) {
      venues.splice(foundIndex, 1);
    } else {
      venues.push(e.target.value);
    }
    setForm((prevForm) => ({ ...prevForm, venues }));
  };

  const handleEmailChange = (e) => {
    handleChange(e);
    if (!isEmailValid(e.target.value)) {
    } else {
      dispatch(checkEmail(e.target.value));
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <div className="flex flex-1 w-full items-center justify-between">
            <div className="flex flex-col items-start max-w-full">
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button onClick={() => previousPage()}>
                  <Icon className="mr-4 text-20">arrow_back</Icon>
                  {languageStrings["STAFF_DETAIL.BACK_LIST"]}
                </Button>
              </FuseAnimate>

              <div className="flex items-center max-w-full">
                <div className="flex flex-col min-w-0">
                  <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                    <Typography className="text-16 sm:text-20 truncate">
                      {form &&
                      form.firstName &&
                      form.firstName.length &&
                      form.lastName &&
                      form.lastName.length
                        ? form.firstName + " " + form.lastName
                        : languageStrings["STAFF_DETAIL.CREATE_STAFF"]}
                    </Typography>
                  </FuseAnimate>
                </div>
              </div>
            </div>

            <FuseAnimate animation="transition.slideRightIn" delay={300}>
              <Button
                className="whitespace-no-wrap"
                variant="contained"
                disabled={!canBeSubmitted()}
                onClick={() => {
                  form._id !== "0"
                    ? dispatch(editStaff(form))
                    : dispatch(createStaff(form));
                }}
              >
                <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                  save
                </Icon>
                {languageStrings["GENERAL.SAVE"]}
              </Button>
            </FuseAnimate>
          </div>
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className={"h-64 normal-case"}
              label={languageStrings["STAFF_DETAIL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              <div>
                <div className="flex">
                  <TextField
                    error={form.firstName && form.firstName.length < 4}
                    className="mt-8 mb-16"
                    required
                    label={languageStrings["GENERAL.FIRST_NAME"]}
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={form.firstName || ""}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    helperText={
                      form.firstName && form.firstName.length < 4
                        ? languageStrings["ERRORS.MINIMUM_4_CHAR"]
                        : ""
                    }
                  />
                  <TextField
                    className="mt-8 mb-16 ml-8"
                    required
                    label={languageStrings["GENERAL.LAST_NAME"]}
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={form.lastName || ""}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="flex">
                  <TextField
                    className="mt-8 mb-16"
                    required
                    label={languageStrings["GENERAL.EMAIL"]}
                    error={
                      (form.email &&
                        form.email.length &&
                        !isEmailValid(form.email)) ||
                      (form._id === "0" && emailTaken)
                    }
                    id="email"
                    name="email"
                    value={form.email || ""}
                    onChange={handleEmailChange}
                    variant="outlined"
                    fullWidth
                    helperText={
                      form.email &&
                      form.email.length &&
                      !isEmailValid(form.email)
                        ? languageStrings["ERRORS.INVALID_EMAIL"]
                        : emailTaken && form._id === "0"
                        ? languageStrings["ERRORS.EMAIL_TAKEN"]
                        : ""
                    }
                  />
                  <TextField
                    className="mt-8 mb-16 ml-8"
                    required
                    error={form.password && form.password.length < 6}
                    label={languageStrings["GENERAL.PASSWORD"]}
                    id="password"
                    type="password"
                    name="password"
                    value={form.password || ""}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    helperText={
                      form.password && form.password.length < 6
                        ? languageStrings["ERRORS.PASSWORD_MUST_BE_7"]
                        : ""
                    }
                  />
                </div>
              </div>
              <Card className="bg-grey-lightest m-8 p-16 flex-1">
                <div className="font-bold mb-12">
                  {languageStrings["STAFF_DETAIL.ASSIGNED_VENUES"]}
                </div>
                {form.venues && (
                  <FormGroup row>
                    {venueList.map((item) => (
                      <FormControlLabel
                        key={item.title}
                        control={
                          <Checkbox
                            checked={form.venues.includes(item._id)}
                            onChange={onPermissionChange}
                            value={item._id}
                            name={item._id}
                          />
                        }
                        label={item.title}
                      />
                    ))}
                  </FormGroup>
                )}
              </Card>
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default StaffDetail;
