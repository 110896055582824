import React, { useState } from "react";
import { Table, TableBody, Typography } from "@material-ui/core";
import { FuseScrollbars } from "@fuse";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ItemCardHeader from "./ItemCardHeader";
import RowComponent from "app/main/common/List/components/RowComponent";
import { useSelector, useDispatch } from "react-redux";
import { paidOnVenue } from "app/store/orders/OrdersActions";
import AlertDialog from "app/main/common/AlertDialog";
import * as Actions from "app/store/actions";
function ItemCard(props) {
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState(props.data);
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });
  const disptch = useDispatch();

  const { languageStrings } = useLanguageHelper();

  function handleRequestSort(event, property) {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }

    setOrder({
      direction,
      id,
    });
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      setSelected(props.data.map((n) => n._id));
      return;
    }
    setSelected([]);
  }

  function handleCheck(event, id) {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleDelete() {
    props.handleDelete(selected);
    setSelected([]);
  }

  function handleDuplicate() {
    props.handleDuplicate(selected);
    setSelected([]);
  }
  return (
    <div style={{ height: "50rem" }} className="w-full flex flex-col">
      <div
        style={{ cursor: "pointer" }}
        className="normal-case flex items-center self-end sm:mb-12 "
        onClick={() => {
          disptch(
            Actions.openDialog({
              children: (
                <AlertDialog
                  onSuccess={() => {
                    disptch(
                      paidOnVenue(
                        props.tableId,
                        props.orderIds,
                        props.selectedVenue,
                        {
                          paymentType: "paidOnVenue",
                          amount: props.pendingAmount,
                        }
                      )
                    );
                    props.onPaidVenue();
                  }}
                  title={languageStrings["GENERAL.CONFIRMATION_TITLE"]}
                  message={languageStrings["PAID_ON_VENUE_MODEL"]}
                />
              ),
            })
          );
        }}
      >
        <Typography
          color="secondary"
          className="normal-case flex items-center self-end sm:mb-12"
        >
          {languageStrings["PAIS_ON_VENUE"]}
        </Typography>
      </div>
      <FuseScrollbars className="flex-grow overflow-x-auto">
        <Table className="min-w-xl" aria-labelledby="tableTitle">
          <ItemCardHeader
            numSelected={selected.length}
            order={order}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            handleDelete={handleDelete}
            duplicate={props.duplicate}
            handleDuplicate={handleDuplicate}
            headerRows={props.headerRows}
            rowCount={props.data && props.data.length}
          />
          <TableBody>
            {data &&
              _.orderBy(data, [(o) => o[order.id]], [order.direction]).map(
                (n) => {
                  const isSelected = selected.indexOf(n._id) !== -1;
                  return (
                    <RowComponent
                      headerRows={props.headerRows}
                      isSelected={isSelected}
                      handleClick={props.handleClick}
                      handleCheck={handleCheck}
                      n={n}
                    />
                  );
                }
              )}
          </TableBody>
        </Table>
      </FuseScrollbars>
    </div>
  );
}

export default ItemCard;
