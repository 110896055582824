import React, { useEffect, useState } from "react";
import { Icon, Button, Select, OutlinedInput } from "@material-ui/core";
import reducer from "app/store/promotion/PromotionsReducer";
import withReducer from "app/store/withReducer";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { PromotionActionTypes } from "app/store/redux/actionTypes";
import { ProductSelectionDropDown } from "./ProductSelectionDropDown";
import { getProductList } from "app/store/catalogue/CatalogueActions";
import { DragDropContext } from "react-beautiful-dnd";
import SelectedProductList from "./SelectedProductList";
import { PROMOTION_TYPE } from "app/models/Promotion";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import {
  createPromotion,
  editPromotion,
} from "app/store/promotion/PromotionsAction";
import { VISIBILITY } from "app/models/Visibility";
const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
export const promotionTypes = {
  "2x1": PROMOTION_TYPE.BUY_2_GET_1,
};
const PromotionForm = (props) => {
  const { languageStrings } = useLanguageHelper();
  const promotion = useSelector(({ banzzu }) => banzzu.promotion);
  const { promotionDetail = {} } = promotion;
  const dispatch = useDispatch();
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [visibility, setVisibility] = useState("");
  const [ranges, setRanges] = useState([]);
  const { productList = [] } = useSelector(({ banzzu }) => banzzu.catalogue);
  const [openVisibility, setOpenVisibility] = useState(false);
  useEffect(() => {
    dispatch(getProductList());
  }, []);

  useEffect(() => {
    if (props.promotionId === "new") {
      dispatch({
        type: PromotionActionTypes.SET_PROMOTION_DETAIL,
        payload: {
          _id: "new",
          title: "",
          type: PROMOTION_TYPE.BUY_2_GET_1,
          visibility: VISIBILITY.ALWAYS_AVAILABLE,
          visibilityRanges: [],
          products: [],
        },
      });
    }
  }, [props.promotionId, dispatch]);

  useEffect(() => {
    if (promotionDetail && promotionDetail.products) {
      setSelectedProducts(promotionDetail.products);
      setSelectedProductIds(getIds(promotionDetail.products));
      setVisibility(promotionDetail.visibility);
      setRanges(promotionDetail.visibilityRanges);
    }
  }, [promotionDetail]);

  const handleValueChange = (key, value) => {
    dispatch({
      type: PromotionActionTypes.SET_PROMOTION_DETAIL,
      payload: { ...promotionDetail, [key]: value },
    });
  };
  const getIds = (list = []) => {
    const ids = [];
    for (let i = 0; i < list.length; i++) {
      const { _id } = list[i];
      ids.push(_id);
    }
    return ids;
  };
  const onGroupDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const list = [...selectedProducts];

    const draggedItemIndex = list.findIndex((item) => item._id === draggableId);

    if (draggedItemIndex !== -1) {
      let [draggedItem] = list.splice(draggedItemIndex, 1);

      list.splice(destination.index, 0, draggedItem);
    }
    const ids = getIds(list);
    setSelectedProductIds(ids);
    setSelectedProducts(list);
  };

  const onSave = () => {
    const newPromotion = {
      _id: props.promotionId,
      title: promotionDetail.title,
      type: promotionDetail.type,
      visibility: visibility,
      visibilityRanges: ranges,
      productIds: selectedProductIds,
    };
    if (promotionDetail._id === "new") {
      dispatch(createPromotion(newPromotion, false, props.page));
    } else {
      dispatch(editPromotion(newPromotion, false, props.page));
    }
  };
  return (
    <div className="p-16 sm:p-24 max-w-xl max-h-screen">
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mr-8"
          required
          label={languageStrings["GENERAL.TITLE"]}
          id="title"
          name="title"
          value={promotionDetail.title}
          onValueChange={(e) => handleValueChange("title", e.target.value)}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
        />

        <Select
          className="mt-8 mb-16 mx-8"
          native
          fullWidth
          input={
            <OutlinedInput
              name="country"
              labelWidth={100}
              id="outlined-venue-native-simple"
            />
          }
          labelId="type-label"
          id="type"
          name="type"
          value={promotionDetail.type}
          onChange={(e) => {
            handleValueChange("type", e.target.value);
          }}
          label={languageStrings["GENERAL.TYPE"]}
          placeholder="Type"
        >
          {Object.entries(promotionTypes).map(([label, value]) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </Select>
      </div>
      <div className="mt-16 pb-16 w-full">
        <ProductSelectionDropDown
          productList={productList}
          handleChangeModifier={(selected, id) => {
            const existingList = [...selectedProducts];
            existingList.push(selected);
            const existingIds = [...selectedProductIds];
            existingIds.push(id);
            setSelectedProductIds(existingIds);
            setSelectedProducts(existingList);
          }}
        />
        <DragDropContext onDragEnd={onGroupDragEnd}>
          <SelectedProductList
            onRemove={(i) => {
              const existingList = [...selectedProducts];
              existingList.splice(i, 1);
              const ids = getIds(existingList);
              setSelectedProductIds(ids);
              setSelectedProducts(existingList);
            }}
            selectedProducts={selectedProducts}
          />
        </DragDropContext>
      </div>
      <div className="mt-16 pb-16 w-full">
        <div className="w-full flex items-center justify-between">
          <Button
            className="mt-16 mb-16 bg-grey-lightest"
            variant="contained"
            onClick={() => {
              setOpenVisibility(true);
            }}
          >
            <Icon className="mr-8">visibility</Icon>
            {languageStrings[`VISIBILITY`]}
          </Button>
          <Button
            className="mt-16 mb-16 bg-grey-lightest"
            variant="contained"
            disabled={false}
            onClick={onSave}
          >
            <Icon className="mr-8">save</Icon>
            {languageStrings[`SAVE_CHANGES`]}
          </Button>
        </div>
      </div>
      <VisibilityModalV2
        open={openVisibility}
        onClose={() => {
          setOpenVisibility(false);
        }}
        data={{ visibility, visibilityRanges: ranges }}
        showGlobal={true}
        onVisibilityChange={(item = {}) => {
          setVisibility(item.visibility);
          setRanges(item.visibilityRanges);
        }}
      />
    </div>
  );
};

export default withReducer("PromotionsReducer", reducer)(PromotionForm);
