import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  Icon,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import { FuseUtils } from "@fuse";
import History from "@history";
import * as Actions from "app/store/actions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import AlertDialog from "app/main/common/AlertDialog";
import CustomInput from "app/main/common/CustomInput";
import {
  createGroupModifier,
  editGroupModifier,
  getGroupModifierDetail,
} from "app/store/catalogue/CatalogueActions";
import SkuButton from "app/main/common/List/components/SkuButton";
import { ModifierDropdown } from "../../common/ModifierDropdown";
import { DragDropContext } from "react-beautiful-dnd";
import ModifierList from "./components/ModifierList";

function GroupModifierDetail(props) {
  const dispatch = useDispatch();
  const { loading } = useSelector(({ banzzu }) => banzzu.catalogue);

  let groupModifierDetail = useSelector(
    ({ banzzu }) => banzzu.catalogue.groupModifierDetail
  );
  const { languageStrings } = useLanguageHelper();
  const { groupModifierId } = props.match.params;
  const page = History.location.state.page || 0;

  useEffect(() => {
    if (groupModifierId !== "new") {
      dispatch(getGroupModifierDetail(groupModifierId, page));
    }
  }, [dispatch, groupModifierId]);

  if (groupModifierId === "new") {
    groupModifierDetail = {
      _id: "0",
      title: "",
      type: "uniSelect",
    };
  }

  const dirtyInitial = {
    title: false,
  };

  const errorInitial = {
    title: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);

  const modifiers =
    form && form.modifiers && form.modifiers.length ? form.modifiers : [];

  useEffect(() => {
    if (
      (groupModifierDetail && !form) ||
      (groupModifierDetail && form && groupModifierDetail._id !== form._id)
    ) {
      setForm(groupModifierDetail);
    }
  }, [form, groupModifierDetail, setForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  //ERRORS
  function hasNoEmpty() {
    return (
      form.title !== "" &&
      form.sku !== "" &&
      form.sku !== undefined &&
      modifiers.length !== 0
    );
  }

  function hasNoErrors() {
    return !errorName();
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleSkuChange(event) {
    handleChange(event);
    debouncedSku(event.target.value);
  }

  const debouncedSku = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function goBack() {
    History.push({
      pathname: `/catalogue/group-modifiers`,
      state: { page },
    });
  }
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const list = [...(form && form.modifiers ? form.modifiers : [])];

    const draggedItemIndex = list.findIndex((item) => item._id === draggableId);

    if (draggedItemIndex !== -1) {
      let [draggedItem] = list.splice(draggedItemIndex, 1);

      list.splice(destination.index, 0, draggedItem);
    }
    setForm((prevForm) => ({
      ...prevForm,
      modifiers: list,
    }));
  };
  const previousPage = () => {
    goBack();
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["SIDEBAR_MENUE.GROUPMODIFIER_LIST"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["CATALOGUE.MODIFIER_NEW"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["CATALOGUE.MODIFIER_DETAIL"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>

              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={() => {
                    form._id !== "0"
                      ? dispatch(editGroupModifier(form, page))
                      : dispatch(createGroupModifier(form, page));
                  }}
                >
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div className="p-16 sm:p-24 max-w-xl max-h-screen">
                  <div>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-venue-native-simple">
                        {languageStrings["EXTRA_CATEGORIES.CATEGORY_TYPES"]}
                      </InputLabel>
                      <Select
                        className="mt-8 mb-16 mr-8"
                        native
                        fullWidth
                        value={form.type}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            name="type"
                            labelWidth={100}
                            id="outlined-venue-native-simple"
                          />
                        }
                      >
                        <option value={"uniSelect"}>Uni Select</option>
                        <option value={"multiSelect"}>Multi Select</option>
                        <option value={"multiselectQuantity"}>
                          Multiselect Quantity
                        </option>
                      </Select>
                    </FormControl>
                  </div>
                  {(form && form.type === "multiSelect") ||
                  form.type === "multiselectQuantity" ? (
                    <div className="flex">
                      <FormControlLabel
                        className="mr-32 min-w-128"
                        control={
                          <Checkbox
                            defaultChecked={form.hasMaxMin}
                            value={form.hasMaxMin}
                            onChange={handleChange}
                            id="hasMaxMin"
                            name="hasMaxMin"
                            color="primary"
                            checked={form.hasMaxMin}
                          />
                        }
                        label={
                          languageStrings["EXTRA_CATEGORIES.ENABLE_QUANTITY"]
                        }
                      />

                      <CustomInput
                        className="mt-8 mb-16 mr-8"
                        required
                        label={
                          languageStrings["EXTRA_CATEGORIES.MINIMUM_QUANTITY"]
                        }
                        id="minQuantity"
                        name="minQuantity"
                        type={"number"}
                        disabled={!form.hasMaxMin}
                        value={form.minQuantity}
                        onValueChange={handleChange}
                        inputProps={{ min: "0" }}
                        errorMessage={languageStrings["ERRORS.MINIMUM_1_CHAR"]}
                        hasError={(error) => error}
                        minLength={1}
                      />

                      <CustomInput
                        className="mt-8 mb-16 mr-8"
                        required
                        label={
                          languageStrings["EXTRA_CATEGORIES.MAXIMUM_QUANTITY"]
                        }
                        disabled={!form.hasMaxMin}
                        id="maxQuantity"
                        type={"number"}
                        name="maxQuantity"
                        inputProps={{ min: "0" }}
                        value={form.maxQuantity}
                        onValueChange={handleChange}
                        errorMessage={languageStrings["ERRORS.MINIMUM_1_CHAR"]}
                        hasError={(error) => error}
                        minLength={1}
                      />
                    </div>
                  ) : null}

                  <div className="flex">
                    <CustomInput
                      className="mt-8 mb-16 mr-8"
                      required
                      label={languageStrings["GENERAL.TITLE"]}
                      id="title"
                      name="title"
                      value={form.title ? form.title : ""}
                      onValueChange={handleTitleChange}
                      errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
                      hasError={(error) =>
                        setDirty((prevState) => {
                          return { ...prevState, title: error };
                        })
                      }
                      minLength={3}
                    />
                  </div>
                  <div className="flex">
                    <CustomInput
                      className="mt-8 mb-16 mr-8"
                      required
                      label={languageStrings["CATALOGUE.SKU"]}
                      id="sku"
                      name="sku"
                      value={form.sku ? form.sku : ""}
                      onValueChange={handleSkuChange}
                      errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
                      hasError={(error) =>
                        setDirty((prevState) => {
                          return { ...prevState, title: error };
                        })
                      }
                      minLength={3}
                    />
                    <SkuButton
                      onCreateRandomString={(value) => {
                        setForm((prevForm) => ({
                          ...prevForm,
                          sku: value,
                        }));
                      }}
                    />
                  </div>
                  <ModifierDropdown
                    handleChangeModifier={(selected) => {
                      const existingList = [
                        ...(form && form.modifiers ? form.modifiers : []),
                      ];
                      existingList.push(selected);
                      setForm((form) => ({
                        ...form,
                        modifiers: existingList,
                      }));
                    }}
                  />
                  <div className="mt-16 mb-16 w-full">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <ModifierList
                        onRemove={(i) => {
                          const existingList = [...modifiers];
                          existingList.splice(i, 1);
                          setForm((form) => ({
                            ...form,
                            modifiers: existingList,
                          }));
                        }}
                        modifiers={modifiers}
                      />
                    </DragDropContext>
                  </div>
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default GroupModifierDetail;
