import { MenuV2ActionTypes } from "../redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";
import _ from "@lodash";
import {
  getUserId,
  MENU_V2_MODIFIER,
  ALLERGEN,
  CATALOG_MENU,
  CATALOG_CATEGORY,
  CATALOG_CATEGORY_ITEM,
  MENU_V2_GROUP_MODIFIER,
  POS_PRODUCT_IMAGE,
  COMBO_CATEGORY,
  SORT_CATALOG_COMBO_CATEGORY,
  SORT_CATALOG_PRODUCT,
  SORT_CATALOG_GROUP_MODIFIER,
  SORT_CATALOG_MODIFIER,
} from "app/api/Endpoint";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import { Menu, MenuItem } from "app/models/Menu";
import { ExtrasCategory } from "app/models/ExtrasCatergory";
import { ExtrasCategoryItem } from "app/models/ExtrasCategoryItem";
import { getComboList } from "app/store/combos/CombosActions";

export const setMenuV2SearchText = (event) => {
  return {
    type: MenuV2ActionTypes.SET_MENU_V2_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
};

export const setMenuV2ItemCategory = (category) => {
  return {
    type: MenuV2ActionTypes.SET_MENU_V2_ITEM_SEARCH_CATEGORY,
    payload: category,
  };
};

export const getMenuV2List = () => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_LIST_START,
    });
    axios
      .get(CATALOG_MENU)
      .then((res) => {
        getMenuV2ListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuV2ListFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuV2ListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuV2ListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_LIST_SUCCESS,
    payload: data,
  });
};

export const getMenuV2Detail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_DETAIL_START,
    });

    const url = CATALOG_MENU + "/" + id;

    axios
      .get(url)
      .then((res) => {
        getMenuV2DetailSuccess(dispatch, res.data.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          getMenuV2DetailFail(dispatch, "Unauthorized");
        } else {
          getMenuV2DetailFail(dispatch, "There was an error connection");
        }
      });
  };
};

const getMenuV2DetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/menuV2/menuList`,
  });
};

const getMenuV2DetailSuccess = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createMenuV2 = (menu: Menu, sucessMessage?: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CREATE_START,
    });

    let formData = new FormData();
    formData.append("userId", getUserId());
    for (let key in menu) {
      if (key !== "image") {
        if (key == "imageFile") {
          formData.append(key, menu[key], menu[key].name);
        } else if (key != "id") {
          formData.append(key, menu[key]);
        }
      }
    }

    axios
      .post(CATALOG_MENU, formData)
      .then((res) => {
        createMenuV2Success(dispatch, res.data, sucessMessage);
      })
      .catch(() => {
        createMenuV2Fail(dispatch, "There was an error connection");
      });
  };
};

const createMenuV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createMenuV2Success = (dispatch, data, sucessMessage?: string) => {
  if (sucessMessage) {
    successAlert(dispatch, sucessMessage || "");
  }
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: `/menuV2/menuDetailPro/${data.data._id}`,
  });
};

export const editMenuV2 = (menu: Menu, isMenuUrl: boolean = true) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_EDIT_START,
    });
    let formData = new FormData();

    for (let key in menu) {
      if (key !== "image") {
        if (key == "_id") {
          formData.append("menuId", menu[key]);
        } else if (key == "imageFile") {
          formData.append(key, menu[key], menu[key].name);
        } else if (key === "menuUrl" && !isMenuUrl) {
          formData.append(key, "");
        } else {
          formData.append(key, menu[key]);
        }
      }
    }

    axios
      .put(CATALOG_MENU, formData)
      .then((res) => {
        editMenuV2Success(dispatch, res.data);
        if (res && res.data && res.data.data && res.data.data._id) {
          dispatch(getMenuV2Detail(res.data.data._id));
        }
      })
      .catch(() => {
        editMenuV2Fail(dispatch, "There was an error connection");
      });
  };
};

const editMenuV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuV2Success = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_EDIT_SUCCESS,
    payload: data,
  });

  // History.push({
  //   pathname: "/menuList/",
  // });
};

export const getMenuItemV2List = (id: string, emptyMenuList = true) => {
  return (dispatch) => {
    emptyMenuList &&
      dispatch({
        type: MenuV2ActionTypes.MENU_V2_ITEM_LIST_START,
      });

    const params = {
      menuId: id,
    };
    axios
      .get(CATALOG_CATEGORY_ITEM, { params })
      .then((res) => {
        getMenuItemV2ListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemV2ListFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuItemV2ListFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

export const getMenuItemV2ListSuccess = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_LIST_SUCCESS,
    payload: data,
  });
};

export const getMenuItemDetailV2 = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_ITEM_DETAIL_START,
    });

    const url = CATALOG_CATEGORY_ITEM + "/" + id;

    axios
      .get(url)
      .then((res) => {
        getMenuItemDetailV2Success(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemDetailV2Fail(dispatch, "There was an error connection");
      });
  };
};

const getMenuItemDetailV2Fail = (dispatch, error) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_DETAIL_FAIL,
    payload: {
      errorMessage: error.message,
    },
  });
  errorAlert(dispatch, error.message);
  History.push({
    pathname: `/menuItemList/${error.menuId}`,
  });
};

const getMenuItemDetailV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_DETAIL_SUCCESS,
    payload: data,
  });
};

export const getMenuItemCategoryV2 = (menu: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_ITEM_CATEGORY_START,
    });

    const params = {
      menuId: menu,
    };

    axios
      .get(CATALOG_CATEGORY, { params })
      .then((res) => {
        getMenuItemCategoryV2Success(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemCategoryV2Fail(dispatch, "There was an error connection");
      });
  };
};

const getMenuItemCategoryV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_CATEGORY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuItemCategoryV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_CATEGORY_SUCCESS,
    payload: data,
  });
};

export const getMenuItemV2Allergenes = () => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_ITEM_ALLERGENES_START,
    });

    axios
      .get(ALLERGEN)
      .then((res) => {
        getMenuItemV2AllergenesSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemV2AllergenesFail(dispatch, "There was an error connection");
      });
  };
};

const getMenuItemV2AllergenesFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_ALLERGENES_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuItemV2AllergenesSuccess = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_ALLERGENES_SUCCESS,
    payload: data,
  });
};

export const getMenuItemExtraCategoriesV2 = (menuItem) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_START,
    });

    const params = {
      menuItemId: menuItem,
    };

    axios
      .get(MENU_V2_GROUP_MODIFIER, { params })
      .then((res) => {
        getMenuItemExtraCategoriesV2Success(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuItemExtraCategoriesV2Fail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const getMenuItemExtraCategoriesV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuItemExtraCategoriesV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_SUCCESS,
    payload: data,
  });
};

export const getMenuExtraCategoryItemsV2 = (categoryId) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_ITEM_START,
    });

    const params = {
      menuItemExtraCategoryId: categoryId,
    };

    axios
      .get(MENU_V2_MODIFIER, { params })
      .then((res) => {
        getMenuExtraCategoryItemsV2Success(dispatch, res.data.data);
      })
      .catch(() => {
        getMenuExtraCategoryItemsV2Fail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const getMenuExtraCategoryItemsV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_ITEM_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getMenuExtraCategoryItemsV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_ITEM_SUCCESS,
    payload: data,
  });
};

export const editMenuV2ItemExtraCategories = (
  category: ExtrasCategory,
  menuItemId: string,
  menuId?: string,
  selectedLanguage: number = 1,
  locale: string = "es"
) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_EDIT_START,
    });

    const request = {
      menuItemExtraCategoryId: category._id,
      language: selectedLanguage,
      languages: category.languages,
      locale,
    };

    axios
      .put(MENU_V2_GROUP_MODIFIER, request)
      .then((res) => {
        editMenuV2ItemExtraCategoriesSuccess(
          dispatch,
          menuItemId,
          category,
          menuId
        );
      })
      .catch((error) => {
        editMenuV2ItemExtraCategoriesFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const editMenuV2ItemExtraCategoriesFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuV2ItemExtraCategoriesSuccess = (
  dispatch,
  menuItemId,
  category,
  menuId
) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_EDIT_SUCCESS,
    payload: category,
  });
  dispatch(getMenuItemV2List(menuId));
};
export const editComboCategory = (
  category: ExtrasCategory,
  menuId: string,
  selectedLanguage: number = 1,
  locale: string = "es"
) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.COMBO_CATEGORY_EDIT_START,
    });

    const request = {
      comboCategoryId: category._id,
      language: selectedLanguage,
      languages: category.languages,
      locale,
    };

    axios
      .put(COMBO_CATEGORY, request)
      .then((res) => {
        editComboCategorySuccess(dispatch, category, menuId);
      })
      .catch((error) => {
        editComboCategoryFail(dispatch, "There was an error connection");
      });
  };
};

const editComboCategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.COMBO_CATEGORY_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editComboCategorySuccess = (dispatch, category, menuId) => {
  dispatch({
    type: MenuV2ActionTypes.COMBO_CATEGORY_EDIT_SUCCESS,
  });
  dispatch(getMenuItemV2List(menuId));
};

export const editMenuV2ExtraCatItems = (
  item: ExtrasCategoryItem,
  isPos?: boolean
) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_START,
    });
    axios
      .put(MENU_V2_MODIFIER, item)
      .then(async (res) => {
        if (item && item._id && isPos === true) {
          let formData = new FormData();

          formData.append("userId", getUserId());
          formData.append("modifierId", item._id);
          formData.append("deleteImage", item["deleteImage"]);
          if (item && item.imageFile) {
            formData.append(
              "imageFile",
              item["imageFile"],
              item["imageFile"].name
            );
          }
          await axios.post(`${MENU_V2_MODIFIER}/pos-image`, formData);
        }
        editMenuV2ExtraCatItemsSuccess(dispatch, res.data, item);
      })
      .catch((error) => {
        editMenuV2ExtraCatItemsFail(dispatch, "There was an error connection");
      });
  };
};

const editMenuV2ExtraCatItemsFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuV2ExtraCatItemsSuccess = (dispatch, data, category) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS,
    payload: category,
  });
  dispatch(getMenuExtraCategoryItemsV2(category.extra_category));
};

export const createMenuV2Item = (menuItem: MenuItem, isPos?: boolean) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_ITEM_CREATE_START,
    });

    axios
      .post(CATALOG_CATEGORY_ITEM, menuItem)
      .then(async (res) => {
        const data = res.data;

        if (data && data.data && data.data._id && isPos === true) {
          let formData = new FormData();
          formData.append("userId", getUserId());
          formData.append("productId", data.data._id);
          formData.append("deleteImage", menuItem["deleteImage"]);
          if (menuItem && menuItem.imageFile) {
            formData.append(
              "imageFile",
              menuItem["imageFile"],
              menuItem["imageFile"].name
            );
          }

          await axios.post(POS_PRODUCT_IMAGE, formData);
        }
        createMenuV2ItemSuccess(dispatch, data, menuItem.idMenu);
      })
      .catch(() => {
        createMenuV2ItemFail(dispatch, "There was an error connection");
      });
  };
};

const createMenuV2ItemFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createMenuV2ItemSuccess = (dispatch, data, idMenu) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_CREATE_SUCCESS,
    payload: data,
  });

  dispatch(getMenuItemV2List(idMenu));
};

export const editMenuV2Item = (menuItem: MenuItem, isPos?: boolean) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_ITEM_EDIT_START,
    });

    axios
      .put(CATALOG_CATEGORY_ITEM, menuItem)
      .then(async (res) => {
        if (menuItem && menuItem._id && isPos) {
          let formData = new FormData();

          formData.append("productId", menuItem._id);
          formData.append("deleteImage", menuItem["deleteImage"]);
          if (menuItem && menuItem.imageFile) {
            formData.append(
              "imageFile",
              menuItem["imageFile"],
              menuItem["imageFile"].name
            );
          }
          await axios.post(POS_PRODUCT_IMAGE, formData);
        }
        editMenuV2ItemSuccess(dispatch, menuItem, menuItem.menuId);
      })
      .catch((error) => {
        console.log({ error });

        editMenuV2ItemFail(dispatch, "There was an error connection");
      });
  };
};

const editMenuV2ItemFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuV2ItemSuccess = (dispatch, data, idMenu) => {
  //successAlert(dispatch, data.message);

  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_EDIT_SUCCESS,
    payload: data,
  });
  dispatch(getMenuItemV2List(idMenu));

  //dispatch(getMenuItemV2List(idMenu));
  // History.push({
  //   pathname: "/menuItemList/" + idMenu,
  // });
};

export const deleteMenuV2 = (idArray: String[]) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_DELETE_START,
    });

    idArray.map((id) => {
      axios
        .delete(CATALOG_MENU, { data: { menuId: id } })
        .then((res) => {
          deleteMenuV2Success(dispatch, res.data);
        })
        .catch(() => {
          deleteMenuV2Fail(dispatch, "There was an error connection");
        });
    });
  };
};

const deleteMenuV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteMenuV2Success = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getMenuV2List());
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_DELETE_SUCCESS,
    payload: data,
  });
};

export const deleteMenuV2Item = (idArray: String[], menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_ITEM_DELETE_START,
      payload: idArray[0],
    });

    idArray.map((id) => {
      axios
        .delete(CATALOG_CATEGORY_ITEM, { data: { menuItemId: id } })
        .then((res) => {
          deleteMenuV2ItemSuccess(dispatch, res.data, menuId);
        })
        .catch(() => {
          deleteMenuV2ItemFail(dispatch, "There was an error connection");
        });
    });
  };
};

const deleteMenuV2ItemFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteMenuV2ItemSuccess = (dispatch, data, menuId) => {
  // successAlert(dispatch, data.message);
  dispatch(getMenuItemV2List(menuId));
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_DELETE_SUCCESS,
    payload: data,
  });
};

export const createMenuV2Category = (
  menu: string,
  title: string,
  description: string,
  position: number,
  type: string,
  languages: {},
  locale: string,
  imageFile?: File
) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CAT_CREATE_START,
    });

    let request = {
      menuId: menu,
      title,
      description,
      position,
      type,
      languages,
      locale,
    };
    const formData = new FormData();
    formData.append("menuData", JSON.stringify(request));
    if (imageFile) {
      formData.append("imageFile", imageFile, imageFile.name);
    }
    axios
      .post(CATALOG_CATEGORY, formData)
      .then((res) => {
        createMenuV2CategorySuccess(dispatch, res.data, menu);
      })
      .catch(() => {
        createMenuV2CategoryFail(dispatch, "There was an error connection");
      });
  };
};

const createMenuV2CategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CAT_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createMenuV2CategorySuccess = (dispatch, data, menuId) => {
  successAlert(dispatch, data.message);
  dispatch(getMenuItemCategoryV2(menuId));
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CAT_CREATE_SUCCESS,
    payload: data,
  });
};

export const editMenuV2Category = (
  id: string,
  title: string,
  menuId: string,
  description: string,
  selectedLanguage: number = 1,
  isDefaultLanguage: boolean = true,
  type: string,
  languages: {},
  locale: string,
  imageFile?: File
) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CAT_EDIT_START,
    });

    let request = {
      menuCategoryId: id,
      title,
      description,
      language: selectedLanguage,
      isDefaultLanguage,
      type,
      languages,
      locale,
    };
    const formData = new FormData();
    formData.append("menuData", JSON.stringify(request));
    if (imageFile) {
      formData.append("imageFile", imageFile, imageFile.name);
    }
    axios
      .put(CATALOG_CATEGORY, formData)
      .then((res) => {
        const data = res.data;
        editMenuV2CategorySuccess(dispatch, data, menuId);
      })
      .catch((error) => {
        editMenuV2CategoryFail(dispatch, "There was an error connection");
      });
  };
};

const editMenuV2CategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CAT_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editMenuV2CategorySuccess = (dispatch, data, menuId) => {
  successAlert(dispatch, data.message);
  dispatch(getMenuItemCategoryV2(menuId));
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CAT_EDIT_SUCCESS,
    payload: data,
  });
};

export const deleteMenuV2Category = (id: string, menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CAT_DELETE_START,
    });

    let request = {
      data: {
        menuCategoryId: id,
      },
    };

    axios
      .delete(CATALOG_CATEGORY, request)
      .then((res) => {
        deleteMenuV2CategorySuccess(dispatch, res.data, menuId);
      })
      .catch(() => {
        deleteMenuV2CategoryFail(dispatch, "There was an error connection");
      });
  };
};

const deleteMenuV2CategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CAT_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteMenuV2CategorySuccess = (dispatch, data, menuId) => {
  successAlert(dispatch, data.message);
  dispatch(getMenuItemCategoryV2(menuId));
  dispatch(getMenuItemV2List(menuId));
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CAT_DELETE_SUCCESS,
    payload: data,
  });
};

export const sortMenuListV2 = (sortOrder, menuId, newMenuItemList) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.SORT_MENU_V2_LIST_START,
      payload: sortOrder,
    });

    const request = {
      ...sortOrder,
      menuId,
      newMenuItemList,
    };

    axios
      .post(`${CATALOG_CATEGORY_ITEM}/sort`, request)
      .then((res) => {
        dispatch(getMenuItemV2List(menuId, false));
        dispatch(getComboList());
        sortMenuListV2Success(dispatch);
      })
      .catch(() => {
        sortMenuListV2Fail(dispatch, "There was an error connection");
      });
  };
};

const sortMenuListV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.SORT_MENU_V2_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortMenuListV2Success = (dispatch) => {
  dispatch({
    type: MenuV2ActionTypes.SORT_MENU_V2_LIST_SUCCESS,
  });
};

export const sortMenuListV2Category = (sortDetails, menuId) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.SORT_MENU_V2_LIST_CATEGORY_START,
    });

    const request = {
      ...sortDetails,
      userId: getUserId(),
    };

    axios
      .post(`${CATALOG_CATEGORY}/sort`, request)
      .then((res) => {
        dispatch(getMenuItemV2List(menuId));

        sortMenuListV2CategorySuccess(dispatch, sortDetails.menuId);
      })
      .catch(() => {
        sortMenuListV2CategoryFail(dispatch, "There was an error connection");
      });
  };
};

const sortMenuListV2CategoryFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.SORT_MENU_V2_LIST_CATEGORY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortMenuListV2CategorySuccess = (dispatch, menuId) => {
  dispatch({
    type: MenuV2ActionTypes.SORT_MENU_V2_LIST_CATEGORY_SUCCESS,
  });
  dispatch(getMenuItemCategoryV2(menuId));
};

export const duplicateMenuV2 = (menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_DUPLICATE_START,
    });

    axios
      .post(`${CATALOG_MENU}/duplicate-menu`, { menuId })
      .then((res) => {
        duplicateMenuV2Success(dispatch, res.data.message);
      })
      .catch(() => {
        duplicateMenuV2Fail(dispatch, "There was an error connection");
      });
  };
};

const duplicateMenuV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_DUPLICATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const duplicateMenuV2Success = (dispatch, message) => {
  successAlert(dispatch, message);
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_DUPLICATE_SUCCESS,
  });
  dispatch(getMenuV2List());
};

export const updateMenuItemVisibilityV2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_ITEM_EDIT_START,
    });

    axios
      .post(`${CATALOG_CATEGORY_ITEM}/availability`, data)
      .then((res) => {
        updateMenuItemVisibilityV2Success(
          dispatch,
          res.data,
          data && data.menuId
        );
      })
      .catch(() => {
        updateMenuItemVisibilityV2Fail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const updateMenuItemVisibilityV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateMenuItemVisibilityV2Success = (dispatch, data, menuId) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_ITEM_EDIT_SUCCESS,
    payload: data.data,
  });
  dispatch(getMenuItemV2List(menuId));
};

export const updateCategoryVisibilityV2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CATEGORY_VISIBILITY_START,
    });
    axios
      .post(`${CATALOG_CATEGORY}/availability`, data)
      .then((res) => {
        updateCategoryVisibilityV2Success(
          dispatch,
          res.data,
          data && data.menuId
        );
      })
      .catch(() => {
        updateCategoryVisibilityV2Fail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const updateCategoryVisibilityV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATEGORY_VISIBILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateCategoryVisibilityV2Success = (dispatch, data, menuId) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATEGORY_VISIBILITY_SUCCESS,
    payload: data.data,
  });
  dispatch(getMenuItemCategoryV2(menuId));
};
export const updateGroupModifierVisibilityV2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_GROUP_MODIFIER_VISIBILITY_START,
    });

    axios
      .post(`${MENU_V2_GROUP_MODIFIER}/availability`, data)
      .then((res) => {
        updateGroupModifierVisibilityV2Success(
          dispatch,
          res.data,
          data && data.menuId
        );
      })
      .catch(() => {
        updateGroupModifierVisibilityV2Fail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const updateGroupModifierVisibilityV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_GROUP_MODIFIER_VISIBILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateGroupModifierVisibilityV2Success = (dispatch, data, menuId) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_GROUP_MODIFIER_VISIBILITY_SUCCESS,
    payload: data.data,
  });
  dispatch(getMenuItemV2List(menuId));
};
export const updateComboCategoryVisibility = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.COMBO_CATEGORY_VISIBILITY_START,
    });

    axios
      .put(`${COMBO_CATEGORY}/availability`, data)
      .then((res) => {
        updateComboCategoryVisibilitySuccess(
          dispatch,
          res.data,
          data && data.menuId
        );
      })
      .catch(() => {
        updateComboCategoryVisibilityFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const updateComboCategoryVisibilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.COMBO_CATEGORY_VISIBILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateComboCategoryVisibilitySuccess = (dispatch, data, menuId) => {
  dispatch({
    type: MenuV2ActionTypes.COMBO_CATEGORY_VISIBILITY_SUCCESS,
    payload: {},
  });
  dispatch(getMenuItemV2List(menuId));
};

export const updateModifierVisibilityV2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_MODIFIER_VISIBILITY_START,
    });

    axios
      .post(`${MENU_V2_MODIFIER}/availability`, data)
      .then((res) => {
        updateModifierVisibilityV2Success(
          dispatch,
          res.data,
          data && data.categoryId
        );
      })
      .catch(() => {
        updateModifierVisibilityV2Fail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const updateModifierVisibilityV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_MODIFIER_VISIBILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateModifierVisibilityV2Success = (dispatch, data, categoryId) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_MODIFIER_VISIBILITY_SUCCESS,
    payload: data.data,
  });
  dispatch(getMenuExtraCategoryItemsV2(categoryId));
};

export const updateMenuVisibilityV2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_EDIT_START,
    });

    axios
      .post(`${CATALOG_MENU}/update-availability`, data)
      .then((res) => {
        dispatch(getMenuV2Detail(data.menuId));
        dispatch(getMenuItemV2List(data.menuId));
        updateMenuVisibilityV2Success(dispatch, res.data);
      })
      .catch(() => {
        updateMenuVisibilityV2Fail(dispatch, "There was an error connection");
      });
  };
};

const updateMenuVisibilityV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateMenuVisibilityV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_EDIT_SUCCESS,
    payload: data.data,
  });
};
const sortCatalogComboCategoryV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATALOG_COMBO_CATEGORY_SORT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortCatalogComboCategoryV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATALOG_COMBO_CATEGORY_SORT_SUCCESS,
    payload: data.data,
  });
};

export const sortCatalogComboCategoryV2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CATALOG_COMBO_CATEGORY_SORT_START,
    });

    axios
      .put(SORT_CATALOG_COMBO_CATEGORY, data)
      .then(() => {
        sortCatalogComboCategoryV2Success(dispatch, data);
      })
      .catch(() => {
        sortCatalogComboCategoryV2Fail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};
const sortCatalogProductV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATALOG_PRODUCT_SORT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortCatalogProductV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATALOG_PRODUCT_SORT_SUCCESS,
    payload: data.data,
  });
};
export const sortCatalogProductV2 = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CATALOG_PRODUCT_SORT_START,
    });

    axios
      .put(SORT_CATALOG_PRODUCT, data)
      .then(() => {
        sortCatalogProductV2Success(dispatch, data);
      })
      .catch(() => {
        sortCatalogProductV2Fail(dispatch, "There was an error connection");
      });
  };
};
const sortCatalogGroupModifierV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATALOG_GROUP_MODIFIER_SORT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortCatalogGroupModifierV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATALOG_GROUP_MODIFIER_SORT_SUCCESS,
    payload: data.data,
  });
};
export const sortCatalogGroupModifier = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CATALOG_GROUP_MODIFIER_SORT_START,
    });

    axios
      .put(SORT_CATALOG_GROUP_MODIFIER, data)
      .then(() => {
        sortCatalogGroupModifierV2Success(dispatch, data);
      })
      .catch(() => {
        sortCatalogGroupModifierV2Fail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};
const sortCatalogModifierV2Fail = (dispatch, errorMessage) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATALOG_MODIFIER_SORT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sortCatalogModifierV2Success = (dispatch, data) => {
  dispatch({
    type: MenuV2ActionTypes.MENU_V2_CATALOG_MODIFIER_SORT_SUCCESS,
    payload: data.data,
  });
};

export const sortCatalogModifier = (data) => {
  return (dispatch) => {
    dispatch({
      type: MenuV2ActionTypes.MENU_V2_CATALOG_MODIFIER_SORT_START,
    });

    axios
      .put(SORT_CATALOG_MODIFIER, data)
      .then(() => {
        sortCatalogModifierV2Success(dispatch, data);
      })
      .catch(() => {
        sortCatalogModifierV2Fail(dispatch, "There was an error connection");
      });
  };
};
