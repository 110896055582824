import { POS_COMBOS, POS_PRODUCTS } from "app/api/Endpoint";
import { PosActionTypes } from "app/store/redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";

export function setPosSearchText(event) {
  return {
    type: PosActionTypes.SET_TIPS_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
export const getPosProductList = (clientId, data) => {
  return (dispatch) => {
    dispatch({
      type: PosActionTypes.PRODUCTS_LIST,
    });
    axios
      .get(`${POS_PRODUCTS}/${clientId}`, { params: data })
      .then((res) => {
        if (res.data && res.data.data) {
          getPosProductListSuccess(dispatch, res.data);
        } else {
          getPosProductListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getPosProductListFail(dispatch, "There was an error connection");
      });
  };
};

const getPosProductListFail = (dispatch, errorMessage) => {
  dispatch({
    type: PosActionTypes.PRODUCTS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getPosProductListSuccess = (dispatch, data) => {
  dispatch({
    type: PosActionTypes.PRODUCTS_LIST_SUCCESS,
    payload: data,
  });
};

export const getPosCombos = () => {
  return (dispatch) => {
    dispatch({
      type: PosActionTypes.COMBO_LIST,
    });
    axios
      .get(`${POS_COMBOS}`)
      .then((res) => {
        if (res.data && res.data.data) {
          getPosCombosSuccess(dispatch, res.data);
        } else {
          getPosCombosFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getPosCombosFail(dispatch, "There was an error connection");
      });
  };
};

const getPosCombosFail = (dispatch, errorMessage) => {
  dispatch({
    type: PosActionTypes.COMBO_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getPosCombosSuccess = (dispatch, data) => {
  dispatch({
    type: PosActionTypes.COMBO_LIST_SUCCESS,
    payload: data,
  });
};
