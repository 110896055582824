import { LOYALTY_PROGRAM } from "app/api/Endpoint";
import { axiosInstance as axios } from "app/api/axios";
import { LoyaltyProgramActionTypes } from "../redux/actionTypes";

export const getLoyaltyClientsList = (
  clientId,
  limit = 10,
  page = 0,
  searchText = "",
  order = {}
) => {
  return (dispatch) => {
    dispatch({
      type: LoyaltyProgramActionTypes.GET_LOYALTY_LIST_START,
    });
    const params = {
      id: clientId,
      limit,
      page,
      searchText,
      order
    };
    axios
      .get(`${LOYALTY_PROGRAM}`, { params })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getLoyaltyClientsListSuccess(dispatch, res.data);
        } else {
          getLoyaltyClientsListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getLoyaltyClientsListFail(dispatch, "There was an error connection");
      });
  };
};

const getLoyaltyClientsListSuccess = (dispatch, data) => {
  dispatch({
    type: LoyaltyProgramActionTypes.GET_LOYALTY_LIST_SUCCESS,
    payload: data,
  });
};

const getLoyaltyClientsListFail = (dispatch, data) => {
  dispatch({
    type: LoyaltyProgramActionTypes.GET_LOYALTY_LIST_FAIL,
    payload: data,
  });
};

export const getLoyaltyClientDetail = (loyaltyClientId: string) => {
  return (dispatch) => {
    dispatch({
      type: LoyaltyProgramActionTypes.GET_LOYALTY_CLIENT_DETAIL_START,
    });
    const url = `${LOYALTY_PROGRAM}/clientDetail/${loyaltyClientId}`;
    axios
      .get(url)
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.loyaltyDetail) {
          getLoyaltyClientDetailSuccess(dispatch, res.data.data.loyaltyDetail);
        }
      })
      .catch((error) => {
        getLoyaltyClientsDetailFail(
          dispatch,
          "There was an error connection",
          error
        );
      });
  };
};

const getLoyaltyClientDetailSuccess = (dispatch, data) => {
  dispatch({
    type: LoyaltyProgramActionTypes.GET_LOYALTY_CLIENT_DETAIL_SUCCESS,
    payload: data,
  });
};

const getLoyaltyClientsDetailFail = (dispatch, errorMessage, data) => {
  dispatch({
    type: LoyaltyProgramActionTypes.GET_LOYALTY_CLIENT_DETAIL_FAIL,
    payload: errorMessage,
  });
};

export const setLoyaltySearchText = (event) => {
  return {
    type: LoyaltyProgramActionTypes.SET_LOYALTY_CLIENT_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
};
