import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import history from "@history";
import {
  STORAGE_KEY,
  getStorageItem,
  removeStorageItem,
} from "app/helpers/storageHelper";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const ResponseInterceptor = (response: AxiosResponse) => {
  return response;
};

const RequestInterceptor = (config: AxiosRequestConfig) => {
  config.headers.Authorization =
    "Bearer " + getStorageItem(STORAGE_KEY.ACCESS_TOKEN);
  return config;
};
axiosInstance.interceptors.request.use(RequestInterceptor);
// Alter defaults after instance has been created
axiosInstance.interceptors.response.use(ResponseInterceptor, (error) => {
  const expectedErrors =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedErrors) {
    console.log("error", error);
    return;
  } else {
    if (error.response.status === 401) {
      removeStorageItem(STORAGE_KEY.ACCESS_TOKEN);
      removeStorageItem(STORAGE_KEY.USER_ID);
      removeStorageItem(STORAGE_KEY.MASTER_ID);
      if (
        !window.location.pathname.includes("/register") &&
        !window.location.pathname.includes("/forgotpassword")
      ) {
        history.replace({
          pathname: "/login",
        });
      }
    }
    return Promise.reject(error);
  }
});
