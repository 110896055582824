import React, { FC } from "react";
import { Typography, Card, IconButton, Icon } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import { Size } from "app/models/Catalogue";

interface IProps {
  size: Size;
  onEdit: () => void;
  onDelete: () => void;
  index: number;
  showEdit?: boolean;
}

const SizesCard: FC<IProps> = ({
  size,
  onEdit,
  onDelete,
  index,
  showEdit = false,
}) => {
  return (
    <Draggable key={size._id} draggableId={size._id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Card className="mt-8 ml-16 pl-8 pr-8 w-11/12 mr-16 bg-grey-lightest">
            <div className="flex">
              <Typography
                variant="subtitle1"
                component="h3"
                className="ml-16 w-full self-center"
              >
                {`${size.title} - ${size.sku}`}
              </Typography>
              {showEdit ? (
                <IconButton onClick={onEdit}>
                  <Icon className="pr-16 text-blue">edit</Icon>
                </IconButton>
              ) : null}
              <IconButton onClick={onDelete}>
                <Icon className="pr-16 text-red">delete</Icon>
              </IconButton>
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default SizesCard;
