import React, { useEffect, useState } from "react";
import { Icon, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";

import MaterialTable from "material-table";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import {
  createMenuV2Category,
  deleteMenuV2Category,
  editMenuV2Category,
  getMenuItemCategoryV2,
  sortMenuListV2Category,
} from "app/store/menuV2/MenuV2Actions";

const MenuV2Category = (props) => {
  const { languageStrings } = useLanguageHelper();

  const [state] = useState({
    columns: [{ title: languageStrings["GENERAL.TITLE"], field: "title" }],
  });

  let [maxPosition, setMaxPosition] = useState(0);
  let [minPosition, setMinPosition] = useState(0);

  const dispatch = useDispatch();
  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemCategory
  );

  const { productId } = props;

  useEffect(() => {
    dispatch(getMenuItemCategoryV2(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    if (categoryList.length > 0) {
      let positions = categoryList.reduce((arr, data) => {
        return [...arr, parseInt(data.position)];
      }, []);

      setMaxPosition(Math.max(...positions));
      setMinPosition(Math.min(...positions));
    }
  }, [categoryList]);

  return (
    <div className="w-full">
      <MaterialTable
        title={languageStrings["MENU_LIST_CATEGORY_PAGE.TITLE"]}
        columns={state.columns}
        data={categoryList}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: languageStrings["GENERAL.ACTIONS"],
          },
          body: {
            emptyDataSourceMessage:
              languageStrings["MATERIAL_TABLE.NO_RECORDS"],
            filterRow: {
              filterTooltip: "Filter",
            },
          },
        }}
        options={{ search: false, pageSize: 10 }}
        icons={{
          Add: () => (
            <Typography
              color="secondary"
              className="normal-case flex items-center sm:mb-12"
            >
              <Icon color="secondary" className="text-32 mr-8">
                add_circle_outline
              </Icon>
              {languageStrings["MENU_CATEGORY_PAGE.ADD_CATEGORY"]}
            </Typography>
          ),
          Delete: () => (
            <Icon color="error" className="text-22">
              delete_outline
            </Icon>
          ),
          Edit: () => (
            <Icon color="secondary" className="text-22">
              edit
            </Icon>
          ),
          Check: () => <Icon className="text-22 text-green">check</Icon>,
          Clear: () => (
            <Icon color="error" className="text-22">
              close
            </Icon>
          ),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              dispatch(createMenuV2Category(productId, newData.title));
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              dispatch(
                editMenuV2Category(
                  newData._id,
                  newData.title,
                  productId,
                  newData.description
                )
              );
              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              dispatch(deleteMenuV2Category(oldData._id, productId));
              resolve();
            }),
        }}
        actions={[
          (rowData) => ({
            icon: () => (
              <Icon color="primary" className="text-14">
                arrow_upward
              </Icon>
            ),
            tooltip: "Move Up",
            onClick: (event, rowData) => {
              const { _id } = rowData;
              let sortDetails = {
                id: _id,
                menuId: productId,
                action: "decrease",
              };
              dispatch(sortMenuListV2Category(sortDetails));
            },
            disabled: minPosition === parseInt(rowData.position),
          }),
          (rowData) => ({
            icon: () => (
              <Icon color="primary" className="text-14">
                arrow_downward
              </Icon>
            ),
            tooltip: "Move Down",
            onClick: (event, rowData) => {
              const { _id } = rowData;
              let sortDetails = {
                id: _id,
                menuId: productId,
                action: "increase",
              };
              dispatch(sortMenuListV2Category(sortDetails));
            },
            disabled: maxPosition === parseInt(rowData.position),
          }),
        ]}
      />
    </div>
  );
};

export default withReducer("MenuReducer", reducer)(MenuV2Category);
