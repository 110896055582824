import GroupModifierDetail from "./GroupModifiersDetail";
import GroupModifierList from "./GroupModifierList";

export const GroupModifierConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/catalogue/group-modifiers",
      component: GroupModifierList,
    },
    {
      path: "/catalogue/group-modifier/:groupModifierId",
      component: GroupModifierDetail,
    },
  ],
};
