import React from "react";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

export const loyaltyCategories = [
  {
    id: "Bronze",
    name: "LOYALTY_CATEGORY_BRONZE",
    color: "#FFF",
    bgColor: "#DB9D51",
  },
  {
    id: "Silver",
    name: "LOYALTY_CATEGORY_SILVER",
    color: "#FFF",
    bgColor: "#D0D9DB",
  },
  {
    id: "Gold",
    name: "LOYALTY_CATEGORY_GOLD",
    color: "#000",
    bgColor: "#FFED4A",
  },
  {
    id: "Platinum",
    name: "LOYALTY_CATEGORY_PLATINUM",
    color: "#FFF",
    bgColor: "#B5CEF5",
  },
];

const LoyaltyCategories = (props) => {
  const status = _.find(loyaltyCategories, { id: props.status });
  const { languageStrings } = useLanguageHelper();
  const {
    bgColor = "transparent",
    color = "transparent",
    name = "",
  } = status || {};

  return (
    <div
      style={{
        display: "inline",
        padding: " 0.4rem",
        backgroundColor: bgColor,
        color,
      }}
      className="text-12 rounded truncate w-16"
    >
      {languageStrings[name]}
    </div>
  );
};

export default LoyaltyCategories;
