import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import useClientCurrency from "app/hooks/useClientCurrency";
interface Props {
  title: string;
  textColor: string;
  orderType: string;
  selectedYear: number;
}
const styles = (theme) => ({
  layoutRoot: {
    display: "inline",
    padding: " 0.4rem",
    backgroundColor: "#E3342F",
    color: "white",
  },
});

const AverageOrderCard = ({
  title,
  textColor,
  orderType,
  selectedYear,
}: Props) => {
  const { orderAnalytics } = useSelector(({ banzzu }) => banzzu.order);
  const [number, setNumber] = useState(0);
  const { clientCurrency } = useClientCurrency();
  useEffect(() => {
    setNumber(0);
    if (
      orderType == "0" &&
      orderAnalytics.orderAvg &&
      orderAnalytics.orderAvg.length > 0
    ) {
      orderAnalytics.orderAvg.map((order) => {
        if (order._id.year == selectedYear) {
          setNumber(order.avgPrice.toFixed(2));
        }
      });
    } else if (
      orderAnalytics.orderTypesAvg &&
      orderAnalytics.orderTypesAvg.length > 0
    ) {
      orderAnalytics.orderTypesAvg.map((order) => {
        if (order._id.type == orderType && order._id.year == selectedYear) {
          setNumber(order.avgPrice.toFixed(2));
        }
      });
    }
  }, [orderAnalytics, orderType, selectedYear]);
  return (
    <div className="widget flex w-full md:w-3/12 md:pr-16 md:pt-0 pt-16">
      <Card className="flex flex-col w-full items-center rounded-8 shadow-none border-1 h-170 p-16">
        <Typography className="h2" color="primary">
          {title}
        </Typography>
        <div className="flex flex-1 flex-col w-full justify-center items-center">
          <Typography
            style={{ fontSize: "5.4rem" }}
            className={`font-300 leading-none mt-8 ${textColor}`}
          >
            {number}
            {clientCurrency.symbol}
          </Typography>
        </div>
      </Card>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(AverageOrderCard);
