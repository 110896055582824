import React from "react";
import _ from "@lodash";
import { Typography, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useSelector } from "react-redux";
import { formatDateTime } from "app/helpers/validators";
import useClientCurrency from "app/hooks/useClientCurrency";

const PaymentHistoryDetail = () => {
  const { languageStrings } = useLanguageHelper();
  const { paymentDetail } = useSelector(({ banzzu }) => banzzu.paymentHistory);
  const tip =
    paymentDetail && paymentDetail.tip && paymentDetail.tip.toFixed
      ? paymentDetail.tip.toFixed(2)
      : 0;
  const { clientCurrency } = useClientCurrency();
  return (
    <div className="pl-16 pr-16 pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex justify-center flex-col">
          {paymentDetail && paymentDetail.refundedAmount > 0 ? (
            <Typography className="h2" color="textSecondary">
              {languageStrings["REFUNDED_AMOUNT"]}:{" "}
              {Number(paymentDetail.refundedAmount).toFixed(2)}{" "}
              {clientCurrency.symbol}
            </Typography>
          ) : null}
          {paymentDetail && paymentDetail.paymentIntentId ? (
            <Typography className="h2" color="textSecondary">
              {languageStrings["STRIPE_ID"]}: {paymentDetail.paymentIntentId}
            </Typography>
          ) : null}
          {tip ? (
            <Typography className="h2" color="textSecondary">
              {languageStrings["TIP"]}: {tip} {clientCurrency.symbol}
            </Typography>
          ) : null}
          <Typography className="h2" color="textSecondary">
            {languageStrings["SIDEBAR_MENUE.PAYMENT_HISTORY"]}{" "}
            {languageStrings["GENERAL.REF"]}
          </Typography>
          <Typography className="h2" color="textSecondary">
            {paymentDetail && paymentDetail.referenceNo
              ? paymentDetail.referenceNo
              : ""}
          </Typography>
        </div>
      </Card>
      <Card className="p-16 mt-10">
        <div className="pb-16 flex items-center">
          <Typography className="h2" color="textSecondary">
            {languageStrings["BILL_DETAIL"]}
          </Typography>
        </div>
        <div className="table-responsive">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["GENERAL.PRICE"]}</th>
                <th>{languageStrings["GENERAL.DATE"]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Typography>
                    {paymentDetail && paymentDetail.price > 0
                      ? `${paymentDetail.price.toFixed(2)} ${
                          clientCurrency.symbol
                        }`
                      : ""}
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {paymentDetail.createdAt
                      ? formatDateTime(paymentDetail.createdAt)
                      : ""}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default PaymentHistoryDetail;
