import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
const SocketContext = createContext();
const SocketProvider = ({ children }) => {
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const { user } = useSelector((state) => state.auth);
  const [socket, setSocket] = useState(null);
  const [selectedVenueIO, setSelectedVenueIO] = useState(null);
  useEffect(() => {
    // const socketUrl = "ws://localhost:9003";
    const socketUrl = process.env.REACT_APP_SOCKET_URL;

    const socketInstance = io(socketUrl, {
      // WARNING: in that case, there is no fallback to long-polling
      transports: ["websocket"], // or [ 'websocket', 'polling' ], which is the same thing
    });

    setSocket(socketInstance);
    socketInstance.on("connect", () => {});

    socketInstance.on("connect_error", (err) => {});

    if (selectedVenueIO && socketInstance) {
      socketInstance.emit("joinRoom", selectedVenueIO);
    }
    return () => {
      socketInstance.emit("leaveRoom", selectedVenueIO);
      socketInstance.removeAllListeners();
      socketInstance.disconnect();
    };
  }, [selectedVenueIO]);

  return (
    <SocketContext.Provider
      value={{ socket, setSelectedVenueIO, selectedVenueIO }}
    >
      {children}
    </SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };
