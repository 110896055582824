import React, { useEffect, useState } from "react";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";
import SkuButton from "app/main/common/List/components/SkuButton";
import { Button } from "@material-ui/core";

const SizesForm = ({
  onClose,
  createParenthesizedType = (data: {
    title: string;
    sku: string;
    _id: string;
    price: string;
  }) => {},
  existingTitle = "",
  existingSku = "",
  existingPrice = "",
}) => {
  const { languageStrings } = useLanguageHelper();
  const [title, setTitle] = useState("");
  const [sku, setSku] = useState("");
  const [price, setPrice] = useState("");
  useEffect(() => {
    setTitle(existingTitle);
    setSku(existingSku);
    setPrice(existingPrice);
  }, [existingTitle, existingSku, existingPrice]);
  const hasError = title === "" || sku === "" || price === "";
  return (
    <div className="flex flex-col">
      <CustomInput
        className="mt-8 mb-16 mr-8 min-w-64"
        required
        label={languageStrings["GENERAL.TITLE"]}
        id="size"
        name="size"
        value={title}
        onValueChange={(e) => {
          setTitle(e.target.value);
        }}
        errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
        hasError={(error) => error}
        minLength={3}
      />
      <CustomInput
        className="mt-8 mb-16"
        label={languageStrings["GENERAL.PRICE"]}
        required
        id="price"
        name="price"
        type={"number"}
        value={price || ""}
        onValueChange={(e) => {
          setPrice(e.target.value);
        }}
      />
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mr-8 min-w-64"
          required
          label={languageStrings["CATALOGUE.SKU"]}
          id="sku"
          name="sku"
          value={sku}
          onValueChange={(e) => {
            setSku(e.target.value);
          }}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) => error}
          minLength={3}
        />
        <div className="mt-8 mb-16 mr-8">
          <SkuButton
            onCreateRandomString={(value) => {
              setSku(value);
            }}
          />
        </div>
      </div>
      <Button
        className="whitespace-no-wrap mt-16"
        variant="contained"
        disabled={hasError}
        onClick={() => {
          createParenthesizedType({
            _id: sku,
            title,
            price,
            sku,
          });
          onClose && onClose();
        }}
      >
        {languageStrings["GENERAL.CREATE"]}
      </Button>
    </div>
  );
};

export default SizesForm;
