import React from "react";

import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

import CustomModal from "app/main/common/CustomModal";

import ItemCard from "./ItemCard";

const OrderFromTableDetail = (props) => {
  const {
    show = false,
    data = [],
    orderIds = [],
    selectedVenue = "",
  } = props || {};

  const { languageStrings } = useLanguageHelper();
  const headerRows = {
    data: [
      {
        id: "title",
        align: "left",
        disablePadding: false,
        label: languageStrings["TABLE_CARD_ITEM"],
        sort: false,
        type: "text-extracategory",
      },
      {
        id: "quantity",
        align: "left",
        disablePadding: false,
        label: languageStrings["QUANTITY"],
        sort: false,
        type: "text",
      },
      {
        id: "unitPrice",
        align: "left",
        disablePadding: false,
        label: languageStrings["UNIT_PRICE"],
        sort: false,
        type: "unitPriceCell",
      },
      {
        id: "price",
        align: "left",
        disablePadding: false,
        label: languageStrings["TOTAL"],
        sort: false,
        type: "priceCell",
      },
    ],
    checkbox: false,
    clickable: false,
    locationSearch: false,
    searchBy: [""],
  };
  return (
    <CustomModal showModal={show} onCloseModal={props.onCloseModal}>
      <div
        className={"flex flex-col bg-white p-24 rounded"}
        style={{ width: "70%" }}
      >
        <ItemCard
          onPaidVenue={props.onCloseModal}
          headerRows={headerRows}
          {...props}
        />
      </div>
    </CustomModal>
  );
};

export default withReducer("MenuReducer", reducer)(OrderFromTableDetail);
