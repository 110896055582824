import React, { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import { useDispatch, useSelector } from "react-redux";
import { updateComboCategoryVisibility } from "app/store/menuV2/MenuV2Actions";
import VisibilityBanner from "app/main/common/VisibilityBanner";
import ProductItem from "../ProductItemV2";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ComboCategory as ComboCategoryType } from "app/models/ComboCategory";
import { Product } from "app/models/Catalogue";
import { ExtrasCategoryItem } from "app/models/ExtrasCategoryItem";

interface Props {
  extraCategory: any; //tTODO
  onEdit: () => void;
  onDelete: () => void;
  onCategoryPositionChange: (
    position: string,
    extraCategory: ComboCategoryType
  ) => void;
  onProductPositionChange: (
    position: string,
    product: Product,
    comboCategory: ComboCategoryType
  ) => void;
  index: number;
  onlyRead?: boolean;
  disabled?: boolean;
  length: number;
  onExtraCategoryChange: (
    productId: string,
    array: ExtrasCategoryItem[]
  ) => void;
}

const ComboCategory = ({
  extraCategory,
  onEdit,
  onDelete,
  index,
  onlyRead,
  disabled,
  onCategoryPositionChange,
  length,
  onProductPositionChange,
  onExtraCategoryChange,
}: Props) => {
  const menuDetail = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  const dispatch = useDispatch();
  const [openVisibility, setOpenVisibility] = useState<boolean>(false);
  const [comboProducts, setComboProducts] = useState([]);
  useEffect(() => {
    setComboProducts(extraCategory.products);
  }, [extraCategory.products]);
  const [expanded, setExpanded] = useState(false);

  const onVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  const onVisibilityClose = () => {
    setOpenVisibility(!openVisibility);
  };
  const handleStopDrag = (event) => {
    event.stopPropagation();
  };
  const lastIndex = length - 1;
  return (
    <>
      <div
        className="mt-8 ml-16 pl-8 pr-8 w-11/12 mr-16"
        onMouseDown={handleStopDrag}
      >
        <Accordion
          defaultExpanded={false}
          onChange={(e, expanded) => {
            setExpanded(expanded);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-grey-lightest"
            style={{ borderWidth: 0 }}
          >
            <div className="flex w-full justify-between">
              <div className="flex items-center">
                <Typography
                  variant="subtitle1"
                  component="h3"
                  className="ml-16 w-full self-center"
                >
                  {extraCategory.title}
                </Typography>
                <div className="mx-4">
                  <VisibilityBanner
                    visibility={extraCategory && extraCategory.visibility}
                  />
                </div>
              </div>
              <div className="flex justify-center items-center">
                {length > 1 && index !== 0 && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onCategoryPositionChange("increase", extraCategory);
                    }}
                  >
                    <Icon color="primary" className="text-14">
                      arrow_upward
                    </Icon>
                  </IconButton>
                )}

                {index !== lastIndex && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onCategoryPositionChange("decrease", extraCategory);
                    }}
                  >
                    <Icon color="primary" className="text-14">
                      arrow_downward
                    </Icon>
                  </IconButton>
                )}
                <IconButton onClick={onVisibilityClick}>
                  <Icon className="material-symbols-outlined text-teal pr-8">
                    visibility
                  </Icon>
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit();
                  }}
                  hidden={onlyRead ? true : false}
                >
                  <Icon className="pr-16 text-blue">edit</Icon>
                </IconButton>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="bg-grey-lighter w-full">
            <div className="w-full">
              {comboProducts &&
                comboProducts.map((menuItem, index) => {
                  return (
                    <div className="w-full">
                      <ProductItem
                        onProductPositionChange={(position, product) =>
                          onProductPositionChange(position, product, menuItem)
                        }
                        key={menuItem._id}
                        extraCategories={menuItem.extraCategories}
                        menuItem={menuItem}
                        index={index}
                        onlyRead={onlyRead}
                        currentVenues={[]}
                        isFromComboCategory={true}
                        length={comboProducts.length || 0}
                        onExtraCategoryChange={(array) =>
                          onExtraCategoryChange(menuItem._id, array)
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <VisibilityModalV2
        open={openVisibility}
        onClose={onVisibilityClose}
        data={extraCategory}
        showGlobal={false}
        onVisibilityChange={(item = {}) => {
          if (extraCategory && extraCategory._id) {
            dispatch(
              updateComboCategoryVisibility({
                ...item,
                comboCategoryId: extraCategory._id,
                menuId: menuDetail && menuDetail._id,
              })
            );
          }
        }}
      />
    </>
  );
};

export default ComboCategory;
