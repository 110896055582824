import { useEffect } from "react";

const useSocketListener = (socket, eventName, callback) => {
  useEffect(() => {
    if (socket) {
      socket.on(eventName, callback);
      return () => {
        if (socket) {
          socket.removeAllListeners();
        }
      };
    }
  }, [socket, eventName, callback]);
};

export default useSocketListener;
