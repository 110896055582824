import { SearchableSelect } from "app/main/common/SearchableSelect";
import * as React from "react";

export const SearchableDropDown = ({
  handleChange,
  selectedValue = "",
  list = [],
  label = "",
  keyPropFn,
  valuePropFn,
  labelWidth = 120,
  searchBarRef,
}) => {
  return (
    <SearchableSelect
      removeSelectionText=""
      maxVisibleOptions={20}
      label={label}
      value={selectedValue}
      onChange={handleChange}
      options={list}
      keyPropFn={keyPropFn}
      valuePropFn={valuePropFn}
      labelWidth={labelWidth}
      inputRef={searchBarRef}
    />
  );
};
