import React, { useEffect } from "react";
import { TableCell, TextField, Icon, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import { editProduct } from "app/store/catalogue/CatalogueActions";

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  container: { maxWidth: "55%" },
}));

const StockCell = (props) => {
  const { product } = props;
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const { form, setForm, handleChange } = useForm(null);
  const client = useSelector(({ banzzu }) => banzzu.auth.user);
  const dispatch = useDispatch();
  useEffect(() => {
    setForm({
      ...product,
    });
  }, [product, setForm]);

  return (
    <TableCell
      className={"truncate max-w-300"}
      component="th"
      scope="row"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {client.stockEnabled && form && form.stockEnabled && (
        <div
          className={clsx(
            "flex flex-row items-center justify-center mt-8 mb-8",
            classes.container
          )}
        >
          <TextField
            className="w-auto"
            label={languageStrings["QUANTITY"]}
            id="stockQuantity"
            name="stockQuantity"
            type={"number"}
            value={form.stockQuantity === 0 ? 0 : form.stockQuantity || ""}
            onChange={handleChange}
            variant="outlined"
            size="small"
          />
          <IconButton
            disabled={!form.stockQuantity || form.stockQuantity < 0}
            onClick={() => {
              dispatch(editProduct(form));
            }}
          >
            <Icon className="material-symbols-outlined text-teal pr-8">
              save
            </Icon>
          </IconButton>
        </div>
      )}
    </TableCell>
  );
};

export default StockCell;
