import { updateObject } from "../utility";
import { ScheduleOrderActionTypes } from "./../redux/actionTypes";

const INITIAL_STATE: ScheduleOrderState = {
  orderList: [],
  totalOrders: 0,
  loading: false,
  searchText: "",
  orderDetail: null,
  tableList: [],
  orderRevenue: {},
  orderAnalytics: {},
};

interface Action {
  payload: any;
  type: string;
}

const ScheduleOrdersReducer = (
  state: ScheduleOrderState = INITIAL_STATE,
  action: Action
): ScheduleOrderState => {
  switch (action.type) {
    case ScheduleOrderActionTypes.SCHEDULE_ORDERS_LIST_START:
      return updateObject(state, {
        loading: true,
        orderList: [],
      });

    case ScheduleOrderActionTypes.SCHEDULE_ORDERS_LIST_SUCCESS:
      return updateObject(state, {
        orderList: action.payload.orders,
        totalOrders: action.payload.totalOrders,
        totalPayment: action.payload.totalPayment,
        loading: false,
      });

    case ScheduleOrderActionTypes.SCHEDULE_ORDERS_LIST_FAIL:
      return updateObject(state, { loading: false });

    case ScheduleOrderActionTypes.SET_ORDER_SEARCH_TEXT:
      return updateObject(state, {
        searchText: action.payload.searchText,
      });

    case ScheduleOrderActionTypes.SCHEDULE_ORDER_DETAIL_START:
      return updateObject(state, {
        loading: true,
        orderDetail: {},
      });

    case ScheduleOrderActionTypes.SCHEDULE_ORDER_DETAIL_SUCCESS:
      return updateObject(state, {
        orderDetail: action.payload.data,
        loading: false,
      });

    case ScheduleOrderActionTypes.SCHEDULE_ORDER_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    default: {
      return state;
    }
  }
};

export default ScheduleOrdersReducer;
