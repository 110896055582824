import React from "react";
import clsx from "clsx";
import _ from "@lodash";
import { FuseAnimate } from "@fuse";
import { Button, Icon, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { changeOrderStatus } from "app/store/orders/OrdersActions";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  orderId: string;
  textPrimary: string;
  iconPrimary: string;
  statusPrimary: string;
  textSecondary?: string;
  iconSecondary?: string;
  statusSecondary?: string;
  orderType?: string;
  orderStatus?: string;
  textTransform?: React.CSSProperties["textTransform"];
  statusReadyToPick: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const OrderCheckButtons = ({
  orderId,
  textPrimary,
  iconPrimary,
  statusPrimary,
  textSecondary,
  iconSecondary,
  statusSecondary,
  textTransform,
  statusReadyToPick,
}: Props) => {
  const classes = useStyles({});
  const dispatch = useDispatch();

  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  return (
    // @ts-ignore
    <FuseAnimate animation="transition.slideRightIn" delay={300}>
      <>
        <div>
          {user.settings && user.settings.autoServiceFeatureEnabled ? (
            <>
              <Button
                color="secondary"
                className="whitespace-no-wrap mr-16"
                variant="contained"
                style={{
                  backgroundColor: "#F2D",
                  textTransform,
                }}
                onClick={() => {
                  dispatch(changeOrderStatus(orderId, statusReadyToPick));
                }}
              >
                <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                  {iconPrimary}
                </Icon>
                {"Ready to pick"}
              </Button>
            </>
          ) : (
            <Button
              color="secondary"
              className="whitespace-no-wrap mr-16"
              variant="contained"
              style={{
                backgroundColor: "#F2D",
                textTransform,
              }}
              onClick={() => {
                dispatch(changeOrderStatus(orderId, statusPrimary));
              }}
            >
              <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                {iconPrimary}
              </Icon>
              {textPrimary}
            </Button>
          )}
          {textSecondary && iconSecondary && statusSecondary && (
            <Button
              color="secondary"
              className="whitespace-no-wrap"
              variant="contained"
              style={{
                backgroundColor: "#34bfa3",
                textTransform,
              }}
              onClick={() =>
                dispatch(changeOrderStatus(orderId, statusSecondary))
              }
            >
              <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                {iconSecondary}
              </Icon>
              {textSecondary}
            </Button>
          )}
        </div>
      </>
    </FuseAnimate>
  );
};

export default OrderCheckButtons;
