import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getComboList } from "app/store/combos/CombosActions";
import { getLabelWith } from "app/helpers/utilsHelper";
import { SearchableDropDown } from "./SearchableDropDown";

export const ComboDropDown = ({
  selectedValue,
  handleChangeValue = () => {},
}) => {
  const dispatch = useDispatch();
  const searchBarRef = useRef(null);
  const { languageStrings } = useLanguageHelper();
  const { comboList = [] } = useSelector(({ banzzu }) => banzzu.combos);
  const [value, setValue] = useState();
  useEffect(() => {
    dispatch(getComboList());
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const _comboList = comboList && comboList.length ? comboList : [];

  const handleChange = (event) => {
    const val = event.target.value;
    const findCombo = _comboList.find((item) => item._id === val);
    if (findCombo && findCombo._id) {
      handleChangeValue(findCombo, val);
      setValue(findCombo._id);
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    }
  };
  const { labelWith } = getLabelWith(languageStrings["SELECT_COMBO"]);
  return (
    <SearchableDropDown
      keyPropFn={(option) => option._id}
      valuePropFn={(option) => `${option.title} - ${option.sku}`}
      list={_comboList}
      selectedValue={value}
      label={languageStrings["SELECT_COMBO"]}
      handleChange={handleChange}
      labelWidth={labelWith}
      searchBarRef={searchBarRef}
    />
  );
};
