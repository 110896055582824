import React from "react";
import _ from "@lodash";
import { useSelector } from "react-redux";
import { Typography, Icon, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import OrdersStatus from "./OrdersStatus";
import moment from "moment";
import { getTipAmount } from "app/helpers/utilsHelper";
import PaymentTypeBadge from "app/main/common/payment/PaymentTypeBadge";
import useClientCurrency from "app/hooks/useClientCurrency";

const OrderStatusDetail = ({
  orderTime,
  orderNumber,
  statuses,
  table,
  discountedAmount,
  discountCode,
  deliveryPrice,
  price,
  orderClientName,
  email,
  whatsApp,
  refundedAmount,
  orderDetail,
  scheduledOrder,
  paidBy = 0,
  partialPayByCash = false,
}) => {
  const { languageStrings } = useLanguageHelper();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const isCashVenue = venueList && venueList.some((venue) => venue.payCash);
  const getAmount = () => {
    let totalPriceWithDiscount = price && Number(price) ? Number(price) : 0;
    if (discountedAmount && Number(discountedAmount)) {
      totalPriceWithDiscount -= Number(discountedAmount);
    }
    return {
      totalPriceWithDiscount: Number(totalPriceWithDiscount.toFixed(2)),
    };
  };
  const isScheduled = (orderDetail && orderDetail.isScheduledOrder) || false;
  const scheduledDate = (orderDetail && orderDetail.scheduledDate) || null;
  const formattedDate = scheduledDate
    ? moment(scheduledDate).format("DD-MM HH:mm")
    : "";
  const formattedScheduledorderCreationDate =
    scheduledOrder && scheduledOrder.createdAt
      ? moment(scheduledOrder.createdAt).format("DD-MM HH:mm")
      : "";
  const { totalPriceWithDiscount } = getAmount();
  const tip = getTipAmount([orderDetail]).totalTip;
  const orderClientAddress =
    (orderDetail && orderDetail.orderClientAddress) || "";
  const { clientCurrency } = useClientCurrency();
  return (
    <div className="pl-16 pr-16 pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex flex-col">
          <div className="pb-16 flex space-x-24">
            <Typography className="h2" color="textSecondary">
              {languageStrings["ORDER_STATUS.ORDER_NUMBER"]}: {orderNumber}
            </Typography>
            {isCashVenue && paidBy && paidBy === 2 && !partialPayByCash ? (
              <PaymentTypeBadge
                backgroundColor={"#7F7F7F"}
                color={"white"}
                text={"GENERAL_CASH"}
              />
            ) : null}
            {isCashVenue && partialPayByCash ? (
              <PaymentTypeBadge
                backgroundColor={"#7F7F7F"}
                color={"white"}
                text={"GENERAL_MIXED_PARTIAL"}
              />
            ) : null}
            {isCashVenue &&
            !partialPayByCash &&
            statuses &&
            statuses[0].status !== "-1" &&
            paidBy === 0 ? (
              <PaymentTypeBadge
                backgroundColor={"#7F7F7F"}
                color={"white"}
                text={"GENERAL_CARD"}
              />
            ) : null}
          </div>
          {refundedAmount && refundedAmount > 0 ? (
            <Typography className="h2" color="textSecondary">
              {languageStrings["REFUNDED_AMOUNT"]}:{" "}
              {Number(refundedAmount).toFixed(2)} {clientCurrency.symbol}
            </Typography>
          ) : null}
          {tip ? (
            <Typography className="h2" color="textSecondary">
              {languageStrings["TIP"]}: {tip} {clientCurrency.symbol}
            </Typography>
          ) : null}
          {orderClientName ? (
            <Typography
              className="h2"
              style={{ marginRight: "20px" }}
              color="textSecondary"
            >
              {languageStrings["GENERAL.NAME"]}: {orderClientName}
            </Typography>
          ) : null}
          {orderClientAddress && orderClientAddress !== "" ? (
            <Typography
              className="h2"
              style={{ marginRight: "20px" }}
              color="textSecondary"
            >
              {languageStrings["GENERAL.ADDRESS"]}: {orderClientAddress}
            </Typography>
          ) : null}

          {email && (
            <Typography
              className="h2"
              style={{ marginRight: "20px" }}
              color="textSecondary"
            >
              {languageStrings["GENERAL.EMAIL"]}: {email}
            </Typography>
          )}
          {whatsApp && (
            <Typography className="h2" color="textSecondary">
              {languageStrings["REGISTER_PAGE.PHONE_NUMBER"]}: {whatsApp}
            </Typography>
          )}
        </div>
        {isScheduled && scheduledDate && (
          <div className="pb-16 flex items-center">
            <Typography
              className="h2"
              style={{ marginRight: "20px" }}
              color="textSecondary"
            >
              {languageStrings["DELIVERY_DATE"]}: {formattedDate}
            </Typography>
          </div>
        )}
        {isScheduled && scheduledDate && scheduledOrder && (
          <div className="pb-16 flex items-center">
            <Typography
              className="h2"
              style={{ marginRight: "20px" }}
              color="textSecondary"
            >
              {languageStrings["SCHEDULE_ORDER_CREATION_DATE"]}:{" "}
              {formattedScheduledorderCreationDate}
            </Typography>
          </div>
        )}
        {orderTime && (
          <div className="pb-16 flex items-center">
            <Icon className="mr-16" color="action">
              access_time
            </Icon>
            <Typography className="h2" color="textSecondary">
              {`${languageStrings["ORDER_STATUS.ORDER_TIME"]}: ${moment(
                orderTime
              ).format("hh:mm:a")}`}
            </Typography>
          </div>
        )}

        {table && (
          <div className="pb-16 flex items-center">
            <Icon className="mr-16" color="action">
              local_dining
            </Icon>
            <Typography className="h2" color="textSecondary">
              {`${languageStrings["ORDER_STATUS.ORDER_TABLE"]}: ${table.title}`}
            </Typography>
          </div>
        )}

        {discountCode && (
          <>
            <div className="flex items-center">
              <Typography className="h4 text-red">
                {`${languageStrings["GENERAL.ORIGINAL_PRICE"]}:`}
              </Typography>
              <Typography className="h4 line-through text-red ml-4 ">
                {price}
              </Typography>
            </div>
            <div className="flex items-center">
              <Typography className="h4" color="primary">
                {`${languageStrings["GENERAL.DISOCUNT_CODE"]}:`}
              </Typography>
              <Typography className="h4 ml-4" color="primary">
                {discountCode}
              </Typography>
            </div>
            <div className="p1-12 pb-16 flex items-center">
              <Typography className="h4" color="primary">
                {`${languageStrings["GENERAL.DISCOUNTED_PRICE"]}:`}
              </Typography>
              <Typography className="h4 ml-4" color="primary">
                {totalPriceWithDiscount < 0 ? 0 : totalPriceWithDiscount}
              </Typography>
            </div>
          </>
        )}

        <div className="table-responsive">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["GENERAL.STATUS"]}</th>
                <th>{languageStrings["ORDER_STATUS.UPDATED_AT"]}</th>
              </tr>
            </thead>
            <tbody>
              {statuses &&
                statuses.map((status) => (
                  <tr key={status.id}>
                    <td>
                      <OrdersStatus status={status.status} />
                    </td>
                    <td>{moment(status.createdAt).format("LLL")}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default OrderStatusDetail;
