import {
  getUserNumberUrl,
  USERS,
  USER_BRANDING,
  getUserId,
  CLIENT,
  PREVIEW_WEB,
  SEND_PREMIUM_LEAD_EMAIL,
  ADD_LEAD_VISITING_PRICING,
  SEND_CONTACT_SUPPORT_EMAIL,
  DOWNLOAD_QR,
  USERS_DETAILS,
  TIPS,
} from "app/api/Endpoint";
import { UsersActionTypes } from "app/store/redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";
import { successAlert } from "app/helpers/alerts";
import { getUserInfo } from "app/store/auth/LoginActions";

export function setUserSearchText(event) {
  return {
    type: UsersActionTypes.SET_USER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getUserList = () => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.USER_LIST_START,
    });

    axios
      .get(USERS)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getUserListSuccess(dispatch, res.data);
        } else {
          getUserListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getUserListFail(dispatch, "There was an error connection");
      });
  };
};
export const getTipList = (clientId) => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.USER_LIST_START,
    });
    axios
      .get(`${TIPS}/${clientId}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getUserListSuccess(dispatch, res.data);
        } else {
          getUserListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getUserListFail(dispatch, "There was an error connection");
      });
  };
};

const getUserListFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.USER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getUserListSuccess = (dispatch, data) => {
  dispatch({
    type: UsersActionTypes.USER_LIST_SUCCESS,
    payload: data,
  });
};
export const getUserDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.USER_DETAILS_START,
    });

    axios
      .get(USERS_DETAILS + "/" + id)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getUserDetailSuccess(dispatch, res.data);
        } else {
          getUserDetailFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getUserDetailFail(dispatch, "There was an error connection");
      });
  };
};

const getUserDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.USER_DETAILS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getUserDetailSuccess = (dispatch, data) => {
  dispatch({
    type: UsersActionTypes.USER_DETAILS_SUCCESS,
    payload: data.data,
  });
};
export const getUserNumber = () => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.USER_PLATFORM_START,
    });

    const url = getUserNumberUrl();

    axios
      .get(url)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getUserNumberSuccess(dispatch, res.data);
        } else {
          getUserNumberFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getUserNumberFail(dispatch, "There was an error connection");
      });
  };
};

const getUserNumberFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.USER_PLATFORM_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getUserNumberSuccess = (dispatch, data) => {
  dispatch({
    type: UsersActionTypes.USER_PLATFORM_SUCCESS,
    payload: data,
  });
};

export const editBranding = (branding, preview, permalink) => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.SET_BRANDING_START,
    });

    let formData = new FormData();
    formData.append("clientId", getUserId());

    for (let key in branding) {
      if (key == "logoFile") {
        formData.append(key, branding[key], branding[key].name);
      } else if (key == "headerFile") {
        formData.append(key, branding[key], branding[key].name);
      } else if (key == "autoServiceLogo") {
        formData.append(key, branding[key], branding[key].name);
      } else {
        formData.append(key, branding[key]);
      }
    }

    axios
      .put(USER_BRANDING, formData)
      .then((res) => {
        editBrandingSuccess(dispatch, res.data, preview, permalink);
      })
      .catch(() => {
        editBrandingFail(dispatch, "There was an error connection");
      });
  };
};

const editBrandingFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.SET_BRANDING_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editBrandingSuccess = (dispatch, data, preview, permalink) => {
  //successAlert(dispatch, data.message);
  dispatch(getUserInfo());
  dispatch({
    type: UsersActionTypes.SET_BRANDING_SUCCESS,
    payload: data,
  });
  if (preview) {
    dispatch(previewWeb());
    window.open(
      process.env.NODE_ENV === "production"
        ? `https://web.banzzu.com/${permalink}`
        : `http://localhost:3001/${permalink}`
    );
  }
};

export const updateClient = (clientData) => {
  return async (dispatch) => {
    dispatch({
      type: UsersActionTypes.UPDATE_USER_START,
    });

    let res = await axios.patch(CLIENT, { ...clientData, userId: getUserId() });

    if (res.data.statusCode === 200) {
      updateClientSuccess(dispatch, res.data);
    } else {
      updateClientFail(
        dispatch,
        res.data.errorMessage || "There was an error connection"
      );
    }
  };
};

const updateClientFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.UPDATE_USER_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateClientSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getUserInfo());
  dispatch({
    type: UsersActionTypes.UPDATE_USER_SUCCESS,
  });
};

export const previewWeb = () => {
  return async (dispatch) => {
    await axios.post(PREVIEW_WEB, { clientId: getUserId() });
  };
};

export const sendPremiumLeadEmail = (clientId) => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.SEND_PREMIUM_LEAD_EMAIL_START,
    });

    axios
      .post(SEND_PREMIUM_LEAD_EMAIL, { clientId })
      .then((res) => {
        if (res.data.statusCode === 200) {
          sendPremiumLeadEmailSuccess(dispatch, res.data);
        } else {
          sendPremiumLeadEmailFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        sendPremiumLeadEmailFail(dispatch, "There was an error connection");
      });
  };
};

const sendPremiumLeadEmailFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.SEND_PREMIUM_LEAD_EMAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sendPremiumLeadEmailSuccess = (dispatch, data) => {
  successAlert(dispatch, data.data.message);
  dispatch({
    type: UsersActionTypes.SEND_PREMIUM_LEAD_EMAIL_SUCCESS,
    payload: data,
  });
};

export const sendContactSupportEmail = (data, languageStrings) => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.SEND_CONTACT_SUPPORT_EMAIL_START,
    });
    axios
      .post(SEND_CONTACT_SUPPORT_EMAIL, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          sendContactSupportEmailSuccess(dispatch, res.data, languageStrings);
        } else {
          sendContactSupportEmailFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        sendContactSupportEmailFail(dispatch, "There was an error connection");
      });
  };
};

const sendContactSupportEmailFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.SEND_CONTACT_SUPPORT_EMAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sendContactSupportEmailSuccess = (dispatch, data, languageStrings) => {
  successAlert(dispatch, languageStrings["CONTACT_SUPPORT.SUCCESS_MESSAGE"]);
  dispatch({
    type: UsersActionTypes.SEND_CONTACT_SUPPORT_EMAIL_SUCCESS,
    payload: data,
  });
  window.location.reload();
};

export const addLeadVisitPricingKlaviyo = () => {
  return async (dispatch) => {
    await axios.post(ADD_LEAD_VISITING_PRICING, { clientId: getUserId() });
  };
};

export const getPermaLinks = () => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.PERMA_LINK_LIST_START,
    });
    const url = CLIENT + "/perma-links";
    axios
      .get(url)
      .then((res) => {
        getPermaLinksSuccess(dispatch, res.data);
      })
      .catch(() => {
        getPermaLinksFail(dispatch, "There was an error connection");
      });
  };
};

const getPermaLinksFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.PERMA_LINK_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getPermaLinksSuccess = (dispatch, data) => {
  dispatch({
    type: UsersActionTypes.PERMA_LINK_LIST_SUCCESS,
    payload: data.data,
  });
};

export const updatePermaLink = (id, permaLink) => {
  return (dispatch) => {
    dispatch({
      type: UsersActionTypes.UPDATE_LINK_START,
    });
    const url = CLIENT + "/change-perma-link";
    axios
      .put(url, { id, permaLink })
      .then((res) => {
        if (res.data.statusCode === 200) {
          updatePermaLinkSuccess(dispatch, res.data);
        } else {
          updatePermaLinkFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        updatePermaLinkFail(dispatch, "There was an error connection");
      });
  };
};

const updatePermaLinkFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.UPDATE_LINK_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updatePermaLinkSuccess = (dispatch, data) => {
  dispatch({
    type: UsersActionTypes.UPDATE_LINK_SUCCESS,
    payload: data.data,
  });
  dispatch(getUserInfo());
  dispatch(getPermaLinks());
};

export const downloadedQr = () => {
  return async (dispatch) => {
    dispatch({
      type: UsersActionTypes.DOWNLOAD_QR_START,
    });

    await axios.patch(DOWNLOAD_QR, {});
  };
};

export const addLastInteractionWithAnalytics = () => {
  return async (dispatch) => {
    dispatch({
      type: UsersActionTypes.ADD_LAST_INTERACTION_START,
    });
    let res = await axios.put(
      CLIENT + "/lastInteractionWithAnalytics/" + getUserId()
    );
    if (res.data.statusCode === 200) {
      addLastInteractionWithAnalyticsSuccess(dispatch, res.data);
    } else {
      addLastInteractionWithAnalyticsFail(
        dispatch,
        res.data.errorMessage || "There was an error connection"
      );
    }
  };
};

const addLastInteractionWithAnalyticsFail = (dispatch, errorMessage) => {
  dispatch({
    type: UsersActionTypes.ADD_LAST_INTERACTION_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const addLastInteractionWithAnalyticsSuccess = (dispatch, data) => {
  dispatch({
    type: UsersActionTypes.ADD_LAST_INTERACTION_SUCCESS,
  });
};
