import { axiosInstance as axios } from "app/api/axios";

import { OrderActionTypes } from "../redux/actionTypes";
import {
  getOrderDetailUrl,
  getTableListUrl,
  getUserId,
  getOrderRevenueUrl,
  ORDER_TABLE,
  ORDERS,
  getOrderAnalyticsUrl,
  PAYMENT_REFOUND,
} from "app/api/Endpoint";
import _ from "@lodash";
import History from "@history";
import { errorAlert } from "app/helpers/alerts";
import {
  EXPORT_ORDERS,
  getDashboardOrderListUrl,
  getOrderTableDetail,
  PAY_ORDER_TABLE,
} from "app/api/OrderEndpoints";
import { setListLimitPage } from "../pagination/paginationActions";
import { toast } from "react-toastify";
import { exportOrders } from "app/helpers/utilsHelper";
import { Pagination } from "app/models/Pagination";

export function setOrderSearchText(event) {
  return {
    type: OrderActionTypes.SET_ORDER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
export interface ExportOrderParams {
  venueId?: string;
  clientId: string;
  startDate: Date;
  endDate: Date;
  paymentType: number;
  languageStrings?: any;
}
export interface OrderParams {
  searchText?: string;
  page?: string | number;
  limit?: string | number;
  status?: string;
  order?: any;
  type?: string;
  initialDate?: string;
  finalDate?: string;
  paidBy?: number;
}

export const getOrderList = ({
  searchText = "",
  page = 1,
  limit = 10,
  status = "0",
  order = {},
  type = "",
  initialDate = "",
  finalDate = "",
  paidBy = 1,
}: OrderParams) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.ORDERS_LIST_START,
    });
    const listType = Pagination.ORDERS;

    setListLimitPage(dispatch, limit, page, listType);
    const url = getDashboardOrderListUrl();
    axios
      .get(
        url +
          `&searchText=${searchText}&page=${page}&limit=${limit}&status=${status}&order=${JSON.stringify(
            order
          )}&initialDate=${initialDate}&finalDate=${finalDate}&paymentType=${paidBy}`
      )
      .then((res) => {
        getOrderListSuccess(dispatch, res.data.data);
      })
      .catch((error) => {
        getOrderListFail(
          dispatch,
          error.response
            ? error.response.data.message
            : "There was an error connection"
        );
      });
  };
};
export const getExportOrderList = (params: ExportOrderParams) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.EXPOER_ORDERS_LIST_START,
    });
    const { languageStrings } = params;

    const payload = {
      ...params,
    };
    delete payload.languageStrings;
    axios
      .get(EXPORT_ORDERS, { params: payload })
      .then((res) => {
        getExportOrderListSuccess(dispatch);
        if (res && res.data && res.data.data && res.data.data.length)
          exportOrders(res.data.data, languageStrings);
        else toast.error(languageStrings["GENERAL.NO_ORDER_LIST"]);
      })
      .catch((error) => {
        toast.error(languageStrings["ERROR_FETCHING_ORDERS"]);
        getExportOrderListFailed(dispatch);
      });
  };
};

const getOrderListFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDERS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDERS_LIST_SUCCESS,
    payload: data,
  });
};
const getExportOrderListSuccess = (dispatch) => {
  dispatch({
    type: OrderActionTypes.EXPOER_ORDERS_LIST_SUCCESS,
  });
};
const getExportOrderListFailed = (dispatch) => {
  dispatch({
    type: OrderActionTypes.EXPOER_ORDERS_LIST_FAILED,
  });
};

export const getOrderDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.ORDER_DETAIL_START,
    });

    const url = getOrderDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getOrderDetailSuccess(dispatch, res.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          getOrderDetailFail(dispatch, "Unauthorized");
        } else {
          getOrderDetailFail(dispatch, "There was an error connection");
        }
      });
  };
};

const getOrderDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/orders`,
  });
};

const getOrderDetailSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDER_DETAIL_SUCCESS,
    payload: data,
  });
};

export const getOrderRevenue = () => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.ORDER_REVENUE_START,
    });

    const url = getOrderRevenueUrl();

    axios
      .get(url)
      .then((res) => {
        getOrderRevenueSuccess(dispatch, res.data);
      })
      .catch((error) => {
        getOrderRevenueFail(
          dispatch,
          error.response
            ? error.response.data.message
            : "There was an error connection"
        );
      });
  };
};

const getOrderRevenueFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDER_REVENUE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getOrderRevenueSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDER_REVENUE_SUCCESS,
    payload: data,
  });
};

export const getOrderAnalytics = () => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.ORDER_ANALYTICS_START,
    });

    const url = getOrderAnalyticsUrl();

    axios
      .get(url)
      .then((res) => {
        getOrderAnalyticsSuccess(dispatch, res.data);
      })
      .catch((error) => {
        getOrderAnalyticsFail(
          dispatch,
          error.response
            ? error.response.data.message
            : "There was an error connection"
        );
      });
  };
};

const getOrderAnalyticsFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.ORDER_ANALYTICS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getOrderAnalyticsSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.ORDER_ANALYTICS_SUCCESS,
    payload: data,
  });
};
export const changeOrderStatus = (
  id: string,
  status: string,
  estimationTime?: any
) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.CHANGE_ORDER_STATUS_START,
    });

    axios
      .patch(ORDERS, {
        id,
        status,
        userId: getUserId(),
        estimationTime,
      })
      .then((res) => {
        changeOrderStatusSuccess(dispatch, res.data, id);
      })
      .catch((error) => {
        changeOrderStatusFail(
          dispatch,
          error.response.data.message || "There was an error connection"
        );
      });
  };
};

const changeOrderStatusFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.CHANGE_ORDER_STATUS_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
};

const changeOrderStatusSuccess = (dispatch, data, orderId) => {
  dispatch({
    type: OrderActionTypes.CHANGE_ORDER_STATUS_SUCCESS,
    payload: data,
  });
  dispatch(getOrderDetail(orderId));
};

export const getTableList = (venueSelected: string) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.TABLE_LIST_START,
    });

    const url = getTableListUrl(venueSelected);

    axios
      .get(url)
      .then((res) => {
        getTableListSuccess(dispatch, res.data);
      })
      .catch(() => {
        getOrderListFail(dispatch, "There was an error connection");
      });
  };
};

const getTableListFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.TABLE_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

export const getTableListSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.TABLE_LIST_SUCCESS,
    payload: data,
  });
};

export const setTableList = (data) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.TABLE_LIST_SUCCESS,
      payload: { data },
    });
  };
};

export const editTable = (
  title: string,
  id: string,
  venueSelected: string,
  enableExtaTarif?: boolean,
  agoraPriceListId?: number
) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.TABLE_UPDATE_START,
    });
    const data: any = { title, id };
    if (enableExtaTarif !== undefined && enableExtaTarif !== null) {
      data.enableExtaTarif = enableExtaTarif;
    }
    if (agoraPriceListId !== undefined && agoraPriceListId !== null) {
      data.agoraPriceListId = agoraPriceListId;
    }
    if (title) {
      axios
        .put(ORDER_TABLE, data)
        .then((res) => {
          editTableSuccess(dispatch);
          dispatch(getTableList(venueSelected));
        })
        .catch((error) => {
          editTableFail(dispatch, "There was an error connection");
        });
    }
  };
};

const editTableFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.TABLE_UPDATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editTableSuccess = (dispatch) => {
  dispatch({
    type: OrderActionTypes.TABLE_UPDATE_SUCCESS,
  });
};

export const deleteTable = (id: string, venueSelected: string) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.TABLE_DELETE_START,
    });

    axios
      .delete(ORDER_TABLE, { data: { id } })
      .then((res) => {
        const data = res.data;
        deleteTableSuccess(dispatch, data);
        dispatch(getTableList(venueSelected));
      })
      .catch((error) => {
        deleteTableFail(dispatch, "There was an error connection");
      });
  };
};
export const paidOnVenue = (
  tableNo: string,
  orderIds: string[],
  venueSelected: string,
  payments: {}
) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.TABLE_DELETE_START,
    });

    axios
      .post(PAY_ORDER_TABLE, {
        tableNo,
        orderIds,
        payments,
      })
      .then((res) => {
        const data = res.data;

        venueSelected && dispatch(getTableList(venueSelected));
      })
      .catch((error) => {
        deleteTableFail(dispatch, "There was an error connection");
      });
  };
};
export const getTableDetail = (
  tableId: string,
  webUrl: string,
  venueId: string
) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.TABLE_DELETE_START,
    });
    const _endPoint = getOrderTableDetail(tableId);
    axios
      .get(_endPoint)
      .then((res) => {
        const data = res.data;

        if (
          data &&
          data.data &&
          data.data.pendingOrdersIds &&
          data.data.pendingOrdersIds.length
        ) {
          window.open(`${webUrl}/virtualbasket/${tableId}/${venueId}`);
        } else {
          window.open(`${webUrl}/${tableId}`);
        }
      })
      .catch((error) => {
        console.log(error, "error");

        // deleteTableFail(dispatch, "There was an error connection");
      });
  };
};

const deleteTableFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.TABLE_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteTableSuccess = (dispatch, data) => {
  dispatch({
    type: OrderActionTypes.TABLE_DELETE_SUCCESS,
  });
};

export const createTable = (item, venueSelected) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.TABLE_CREATE_START,
    });
    if (item.title) {
      const body = {
        title: item.title,
        venueId: venueSelected,
      };

      axios
        .post(ORDER_TABLE, body)
        .then((res) => {
          createTableSuccess(dispatch);
          dispatch(getTableList(venueSelected));
        })
        .catch((error) => {
          createTableFail(dispatch, "There was an error connection");
        });
    }
  };
};

const createTableFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.TABLE_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createTableSuccess = (dispatch) => {
  dispatch({
    type: OrderActionTypes.TABLE_CREATE_SUCCESS,
  });
};
export const paymentRefund = (
  amount: number,
  orderId: string,
  limit: number
) => {
  return (dispatch) => {
    dispatch({
      type: OrderActionTypes.PAYMENT_REFUND_START,
    });

    const body = {
      amount,
      orderId,
    };

    axios
      .post(PAYMENT_REFOUND, body)
      .then((res) => {
        paymentRefundSuccess(dispatch, res.data, limit);
      })
      .catch((error) => {
        paymentRefundFail(dispatch, "There was an error connection");
      });
  };
};

const paymentRefundFail = (dispatch, errorMessage) => {
  dispatch({
    type: OrderActionTypes.PAYMENT_REFUND_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const paymentRefundSuccess = (dispatch, data, limit) => {
  toast.success(`${data.message}`);
  dispatch({
    type: OrderActionTypes.PAYMENT_REFUND_SUCCESS,
  });
  dispatch(
    getOrderList({
      searchText: "",
      page: 0,
      limit: limit || 10,
      status: "0",
      order: {},
    })
  );
};
