import React, { useEffect } from "react";
import {
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { setMenuV2ItemCategory } from "app/store/menuV2/MenuV2Actions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function MenuItemListHeader({ productName, createProductHandler }) {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();

  const searchCategory = useSelector(
    ({ banzzu }) => banzzu.menuV2.searchCategory
  );
  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemCategory
  );

  useEffect(() => {
    if (categoryList.length > 0) {
      dispatch(setMenuV2ItemCategory(categoryList[0]._id));
    }
  }, [dispatch, categoryList]);

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        <FuseAnimate animation="transition.expandIn" delay={300}>
          <Icon className="text-32 mr-0 sm:mr-12">restaurant_menu</Icon>
        </FuseAnimate>
        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
          <Typography className="hidden sm:flex" variant="h6">
            {productName}
          </Typography>
        </FuseAnimate>
      </div>

      <div className="flex flex-1 items-center justify-center px-12">
        <FuseAnimate animation="transition.slideRightIn" delay={300}>
          <FormControl fullWidth variant="outlined">
            <InputLabel className="mt-8" htmlFor="outlined-venue-native-simple">
              {languageStrings["MENU_ITEM_LIST_HEADER_PAGE.SELECT_CATEGORY"]}
            </InputLabel>
            <Select
              className="mt-10 mb-16 mr-8"
              native
              required
              style={{ maxHeight: "40px" }}
              value={searchCategory}
              onChange={(ev) => {
                dispatch(setMenuV2ItemCategory(ev.target.value));
              }}
              input={
                <OutlinedInput
                  name="province"
                  labelWidth={100}
                  id="outlined-venue-native-simple"
                />
              }
            >
              {categoryList &&
                categoryList.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.title}
                  </option>
                ))}
            </Select>
          </FormControl>
        </FuseAnimate>
      </div>
      <div className="flex flex-1 items-center justify-center ">
        <FuseAnimate animation="transition.slideRightIn" delay={300}>
          <>
            <Button
              onClick={createProductHandler}
              className="whitespace-no-wrap"
              variant="contained"
            >
              <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                add_circle_outline
              </Icon>
              <span className="hidden sm:flex">
                {languageStrings["MENU_ITEM_LIST_HEADER_PAGE.CREATE_PRODUCT"]}
              </span>
              <span className="flex sm:hidden">
                {languageStrings["MENU_ITEM_LIST_HEADER_PAGE.PRODUCT"]}
              </span>
            </Button>
          </>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default MenuItemListHeader;
