import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { CreditCard } from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
import { useSelector, useDispatch } from "react-redux";

import * as authActions from "app/auth/store/actions";
import History from "@history";
import { useLanguageHelper } from "../../helpers/LanguageHelper/index";
import { formatImageUrl } from "app/helpers/utilsHelper";
import { clientsSettings } from "config";

function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { locale } = useSelector(({ banzzu }) => banzzu.auth.user);

  const { changeLanguage, languageStrings } = useLanguageHelper();

  const [userMenu, setUserMenu] = useState(null);
  const [languageMenu, setLanguageMenu] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [helpMenu, setHelpMenu] = useState(null);

  useEffect(() => {
    if (locale) {
      setSelectedLanguage(locale);
    }
  }, [locale]);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };
  const helpMenuClick = (event) => {
    setHelpMenu(event.currentTarget);
  };
  const helpMenuClose = () => {
    setHelpMenu(null);
  };
  const languageSelectorClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };

  const changeUserLanguage = (language) => {
    changeLanguage(language);
    setSelectedLanguage(language);
    languageMenuClose();
  };

  const languageMenuClose = () => {
    setLanguageMenu(null);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <React.Fragment>
      <Button className="h-64" onClick={userMenuClick}>
        {clientsSettings.showProfileImage ? (
          <Avatar
            className=""
            alt="user photo"
            src={user.logo && formatImageUrl(user.logo)}
          />
        ) : null}

        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography component="span" className="normal-case font-600 flex">
            {user.email}
          </Typography>
        </div>

        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
          keyboard_arrow_down
        </Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <React.Fragment>
          {/*<MenuItem
            component={Link}
            to="/pages/profile"
            onClick={userMenuClose}
          >
            <ListItemIcon className="min-w-40">
              <Icon>account_circle</Icon>
            </ListItemIcon>
            <ListItemText className="pl-0" primary="My Profile" />
          </MenuItem>*/}

          <MenuItem
            onClick={() => {
              History.push({
                pathname: `/changePassword`,
              });
            }}
          >
            <ListItemIcon className="min-w-40">
              <Icon>lock</Icon>
            </ListItemIcon>
            <ListItemText
              className="pl-0"
              primary={languageStrings["HELP_MENU.CHANGE_PASSWORD"]}
            />
          </MenuItem>

          {user.permission &&
            user.permission.isMaster &&
            clientsSettings.showPaymentMethod && (
              <MenuItem
                onClick={() => {
                  History.push({
                    pathname: `/payment-methods`,
                  });
                  userMenuClose();
                }}
              >
                <ListItemIcon className="min-w-40">
                  <Icon>credit_card</Icon>
                </ListItemIcon>
                <ListItemText
                  className="pl-0"
                  primary={languageStrings["HELP_MENU.PAYMENTS"]}
                />
              </MenuItem>
            )}
          {user &&
            (user.accessLevel == "1" || user.accessLevel == "2") &&
            clientsSettings.showSubscription && (
              <MenuItem
                onClick={() => {
                  History.push({
                    pathname: `/account`,
                  });
                  userMenuClose();
                }}
              >
                <ListItemIcon className="min-w-40">
                  <Icon>subscriptions</Icon>
                </ListItemIcon>
                <ListItemText
                  className="pl-0"
                  primary={languageStrings["ACCOUNT.YOUR_SUBSCRIPTION"]}
                />
              </MenuItem>
            )}
          <MenuItem
            onClick={() => {
              dispatch(authActions.logoutUser());
              userMenuClose();
            }}
          >
            <ListItemIcon className="min-w-40">
              <Icon>exit_to_app</Icon>
            </ListItemIcon>
            <ListItemText
              className="pl-0"
              primary={languageStrings["HELP_MENU.LOGOUT"]}
            />
          </MenuItem>
        </React.Fragment>
      </Popover>

      <HelpIcon
        className="md:inline-flex hidden"
        style={{ fontSize: 26 }}
        onClick={helpMenuClick}
      />
      {clientsSettings.showWhatsappIcon ? (
        <img
          src={"assets/images/whatsapp.png"}
          className="md:hidden inline-flex h-32 w-32"
          onClick={() => window.open("https://wa.me/34613003777")}
        />
      ) : null}

      <Popover
        open={Boolean(helpMenu)}
        anchorEl={helpMenu}
        onClose={helpMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <React.Fragment>
          <MenuItem
            onClick={() => {
              History.push({
                pathname: `/contactSupport`,
              });
              helpMenuClose();
            }}
          >
            <ListItemText
              className="pl-0"
              primary={languageStrings["HELP_MENU.CONTACT_SUPPORT"]}
            />
          </MenuItem>
        </React.Fragment>
      </Popover>
      <Button className="h-64" onClick={languageSelectorClick}>
        <img src={`assets/images/flags/${selectedLanguage}.png`} />
        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography
            component="span"
            className="normal-case font-600 flex text-upercase"
          >
            {selectedLanguage}
          </Typography>
        </div>
      </Button>

      <Popover
        open={Boolean(languageMenu)}
        anchorEl={languageMenu}
        onClose={languageMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        <React.Fragment>
          <MenuItem onClick={() => changeUserLanguage("en")}>
            <ListItemIcon className="min-w-40">
              <img src="assets/images/flags/en.png" />
            </ListItemIcon>
            <ListItemText className="pl-0" primary="English" />
          </MenuItem>

          <MenuItem onClick={() => changeUserLanguage("es")}>
            <ListItemIcon className="min-w-40">
              <img src="assets/images/flags/es.png" />
            </ListItemIcon>
            <ListItemText className="pl-0" primary="Spanish" />
          </MenuItem>
          <MenuItem onClick={() => changeUserLanguage("fr")}>
            <ListItemIcon className="min-w-40">
              <img src="assets/images/flags/fr.png" />
            </ListItemIcon>
            <ListItemText className="pl-0" primary="French" />
          </MenuItem>
          <MenuItem onClick={() => changeUserLanguage("zh")}>
            <ListItemIcon className="min-w-40">
              <img src="assets/images/flags/zh.png" />
            </ListItemIcon>
            <ListItemText className="pl-0" primary="Chinese" />
          </MenuItem>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default UserMenu;
