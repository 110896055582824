import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  FormGroup,
} from "@material-ui/core";
import { useForm } from "@fuse/hooks";
import { useSelector, useDispatch } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import {
  formatImageUrl,
  handleMenuTitle,
  setDefaultLanguage,
  setDefaultLanguageV2,
} from "app/helpers/utilsHelper";
import { ComboDropDown } from "app/main/common/ComboDropDown";
import { PosComboDropdown } from "../common/PosComboDropDown";
import { getPosCombos } from "app/store/pos/PosActions";
import LanguageDropdown from "./menuDetailV2Pro/components/LanguageDropdownV2";
import CustomInput from "../common/CustomInput";
import { FuseUtils } from "@fuse";
import _ from "@lodash";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const AddComboModal = (props) => {
  const {
    onClose,
    onSaveCombo,
    initialValue,
    comboDetail = {},
    Allergenes,
  } = props;

  const { combos } = useSelector(({ banzzu }) => banzzu.pos);
  const dispatch = useDispatch();
  const isPOSIntegration = combos && combos.isPOSIntegration ? true : false;

  const classes = useStyles();
  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemCategory
  );
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { locale } = user;

  const { languageStrings } = useLanguageHelper();

  const defaultLanguage = setDefaultLanguageV2(locale);
  const getInitialSelectedLanguage = () => defaultLanguage.code;
  const [selectedLanguage, setSelectedLanguage] = useState(
    getInitialSelectedLanguage
  );
  const isDefaultLanguage = setDefaultLanguage(selectedLanguage, locale);

  let { selectedLanguages } = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2Detail
  );
  const availableLanguages = selectedLanguages || ["Spanish"];

  const { form, handleChange, setForm } = useForm(null);

  const dirtyInitial = {
    title: false,
    description: false,
  };
  const [dirty, setDirty] = useState(dirtyInitial);

  if (form != null && form.categoryId === "0" && categoryList[0] != null) {
    setForm((form) => ({
      ...form,
      categoryId: categoryList[0]._id,
    }));
  }
  useEffect(() => {
    dispatch(getPosCombos());
  }, []);
  useEffect(() => {
    if (
      (comboDetail && !form) ||
      (comboDetail && form && comboDetail._id !== form._id)
    ) {
      setForm({
        ...comboDetail,
        isDefaultLanguage,
        languages: {},
      });
    }
  }, [form, comboDetail, setForm]);

  useEffect(() => {
    setForm((form) => ({
      ...form,
      isDefaultLanguage,
    }));
  }, [selectedLanguage]);

  const canBeSubmitted = () => {
    return form.comboId !== undefined;
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setForm((prevForm) => ({
      ...prevForm,
      language,
    }));
  };

  const handleNameChange = (language, field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      languages: {
        ...prevForm.languages,
        [language]: {
          ...prevForm.languages[language],
          [field]: value,
        },
      },
    }));
    form[field] = value;
  };

  const handleChangeProduct = (_product, field) => {
    if (
      _product &&
      _product.price >= 0 &&
      Number(_product.price) >= 0 &&
      _product._id
    ) {
      const productTitle = _product.title;
      const description =
        _product && _product.description ? _product.description : "";
      setForm((prevForm) => ({
        ...prevForm,
        title: productTitle,
        comboId: _product._id,
        languages: {
          ...prevForm.languages,
          [selectedLanguage]: {
            ...prevForm.languages[selectedLanguage],
            title: productTitle,
            description,
          },
        },
        productDetail: _product,
      }));
    }
  };
  const onComboSelect = (foundObj) => {
    let _price = 0;
    let title = "";
    let posReference = "";
    let posReferenceTitle = "";
    if (
      foundObj &&
      foundObj.price >= 0 &&
      Number(foundObj.price) >= 0 &&
      foundObj.posReference
    ) {
      _price = Number(Number(foundObj.price).toFixed(2));
      title = foundObj.title;
      posReference = foundObj.posReference;
      posReferenceTitle = foundObj.posReferenceTitle;
      setForm((prevForm) => ({
        ...prevForm,
        title,
        comboId: posReference,
        posReference,
        posReferenceTitle,
        languages: {
          ...prevForm.languages,
          [selectedLanguage]: {
            ...prevForm.languages[selectedLanguage],
            title,
          },
        },
        productDetail: foundObj,
      }));
    }
  };

  const handleProductTitle = (product, language) => {
    const posName = "";
    const languageTitle = handleMenuTitle(product, language, locale, posName);
    setForm((prevForm) => ({
      ...prevForm,
      title: languageTitle.title,
      description: languageTitle.description,
    }));
  };
  function handleUploadChange(e) {
    const initialFile = e.target.files[0];

    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          posImage: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }
  const deleteMenuItemImage = () => {
    setForm((form) => ({
      ...form,
      image: null,
      posImage: null,
      imageFile: null,
      deleteImage: true,
    }));
  };
  if (!form) {
    return <div />;
  }
  const _comboId = form && form.comboId ? form.comboId : "";
  const posImage = form && form.posImage ? form.posImage : "";
  const productImage = isPOSIntegration ? posImage : form.image;
  const handleAllergenesChange = (event) => {
    let allergenesFormatted;
    if (event.target.checked) {
      const allergenes = `${form.allergenes},${event.target.value},`;
      allergenesFormatted = _.replace(allergenes, ",,", ",");
    } else {
      const allergenes = _.replace(
        form.allergenes,
        `${event.target.value},`,
        ""
      );
      allergenesFormatted = _.replace(allergenes, ",,", ",");

      if (!_.startsWith(allergenesFormatted, ",")) {
        allergenesFormatted = "," + allergenesFormatted;
      }
      if (!_.endsWith(allergenesFormatted, ",")) {
        allergenesFormatted = allergenesFormatted + ",";
      }
    }

    setForm((form) => ({
      ...form,
      allergenes: allergenesFormatted,
    }));
  };
  return (
    <div className="p-16 sm:p-24  max-h-screen min-w-[620px]">
      {isPOSIntegration ? (
        <>
          <input
            accept="image/jpg, image/jpeg, image/png, image/gif"
            className="hidden"
            id={`button-file-${_comboId}`}
            type="file"
            onChange={handleUploadChange}
          />
          <label
            htmlFor={`button-file-${_comboId}`}
            className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
          >
            <Icon fontSize="large" color="action">
              cloud_upload
            </Icon>
          </label>
          {
            <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
              <img
                className="max-w-none w-auto h-full"
                alt="whatson"
                src={
                  productImage
                    ? formatImageUrl(productImage)
                    : "assets/images/avatars/noimage.png"
                }
              />
              {productImage && (
                <Icon
                  onClick={deleteMenuItemImage}
                  className="rounded-4 shadow-5 mb-2 text-2xl cursor-pointer absolute bottom-0"
                  style={{
                    color: "#f4516c",
                    backgroundColor: "#fff",
                  }}
                >
                  delete
                </Icon>
              )}
            </div>
          }
        </>
      ) : null}
      <LanguageDropdown
        languages={availableLanguages}
        onChange={handleLanguageChange}
        selectedLanguage={selectedLanguage}
        menuElement={comboDetail ? comboDetail : {}}
        handleLanguageTitle={handleProductTitle}
      />
      {isPOSIntegration ? (
        <PosComboDropdown
          selectedValue={form && form.posReference ? form.posReference : ""}
          handleChangeValue={onComboSelect}
        />
      ) : (
        <ComboDropDown
          selectedValue={initialValue}
          handleChangeValue={(item) => handleChangeProduct(item)}
        />
      )}
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mr-8"
          required
          label={languageStrings["GENERAL.TITLE"]}
          id="title"
          name="title"
          value={
            form.languages &&
            form.languages[selectedLanguage] &&
            form.languages[selectedLanguage].title !== undefined
              ? form.languages[selectedLanguage].title
              : form.title
              ? form.title
              : ""
          }
          onValueChange={(e) =>
            handleNameChange(selectedLanguage, "title", e.target.value)
          }
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) =>
            setDirty((prevState) => {
              return { ...prevState, title: error };
            })
          }
          minLength={3}
        />
      </div>
      <CustomInput
        className="mt-8 mb-16 mr-8"
        label={languageStrings["GENERAL.DESCRIPTION"]}
        id="description"
        name="description"
        maxLength={1000}
        value={
          form.languages &&
          form.languages[selectedLanguage] &&
          form.languages[selectedLanguage].description !== undefined
            ? form.languages[selectedLanguage].description
            : form.description
            ? form.description
            : ""
        }
        onValueChange={(e) =>
          handleNameChange(selectedLanguage, "description", e.target.value)
        }
        errorMessage={languageStrings["ERRORS.MINIMUM_10_CHAR"]}
        hasError={(error) => error}
      />

      {isPOSIntegration && Allergenes && (
        <div className="mt-20 mb-8">
          <div className="font-bold">{languageStrings["ALLERGENE.TITLE"]}</div>
          <FormGroup className="flex-row">
            {Allergenes.map((item) => (
              <FormControlLabel
                key={item.title}
                control={
                  <Checkbox
                    checked={
                      form.allergenes
                        ? form.allergenes.includes(`,${item._id},`)
                        : false
                    }
                    onChange={handleAllergenesChange}
                    value={item._id}
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </div>
      )}
      <div className="flex flex-row">
        <div className="ml-auto mt-4">
          <FormControlLabel
            control={
              <Checkbox
                name="isPopular"
                onChange={handleChange}
                checked={form.isPopular ? true : false}
              />
            }
            label={languageStrings["MENU_PRODUCT_FAVORITE"]}
          />
        </div>
      </div>
      <Button
        className="whitespace-no-wrap mt-16 mb-12"
        variant="contained"
        disabled={!canBeSubmitted()}
        onClick={() => {
          const _form = { ...form, isPos: isPOSIntegration };
          if (isPOSIntegration) {
            _form.comboId = _form.posReference;
          }
          onSaveCombo(_form);
          onClose();
        }}
      >
        <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>save</Icon>
        {languageStrings["GENERAL.SAVE"]}
      </Button>
    </div>
  );
};

export default withReducer("combosReducer", reducer)(AddComboModal);
