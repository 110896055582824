import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  Fade,
  Modal,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useSelector, useDispatch } from "react-redux";
import { getVenueList } from "app/store/venues/VenuesActions";
import { getExportOrderList } from "app/store/orders/OrdersActions";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  "availability--datepicker": {
    border: "1px solid #ddd",
  },
  root: {
    "& .rbc-header": {
      padding: "12px 6px",
      fontWeight: 600,
      fontSize: 1000,
    },
    "& .rbc-label": {
      padding: "8px 6px",
    },
    "& .rbc-today": {
      backgroundColor: "transparent",
    },
    "& .rbc-header.rbc-today, & .rbc-month-view .rbc-day-bg.rbc-today": {
      borderBottom: "2px solid " + theme.palette.secondary.main + "!important",
    },
    "& .rbc-month-view, & .rbc-time-view, & .rbc-agenda-view": {
      padding: 24,
      [theme.breakpoints.down("sm")]: {
        padding: 16,
      },
      // ...theme.mixins.border(0),
    },
    "& .rbc-agenda-view table": {
      // ...theme.mixins.border(1),
      "& thead > tr > th": {
        // ...theme.mixins.borderBottom(0),
      },
      "& tbody > tr > td": {
        padding: "12px 6px",
        "& + td": {
          // ...theme.mixins.borderLeft(1),
        },
      },
    },
    "& .rbc-time-view": {
      "& .rbc-time-header": {
        // ...theme.mixins.border(1),
      },
      "& .rbc-time-content": {
        flex: "0 1 auto",

        // ...theme.mixins.border(1),
      },
    },
    "& .rbc-month-view": {
      "& > .rbc-row": {
        // ...theme.mixins.border(1),
      },
      "& .rbc-month-row": {
        // ...theme.mixins.border(1),
        borderWidth: "0 1px 1px 1px!important",
        minHeight: 128,
      },
      "& .rbc-header + .rbc-header": {
        // ...theme.mixins.borderLeft(1),
      },
      "& .rbc-header": {
        // ...theme.mixins.borderBottom(0),
      },
      "& .rbc-day-bg + .rbc-day-bg": {
        // ...theme.mixins.borderLeft(1),
      },
    },
    "& .rbc-day-slot .rbc-time-slot": {
      // ...theme.mixins.borderTop(1),
      opacity: 0.5,
    },
    "& .rbc-time-header > .rbc-row > * + *": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-time-content > * + * > *": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-day-bg + .rbc-day-bg": {
      // ...theme.mixins.borderLeft(1),
    },
    "& .rbc-time-header > .rbc-row:first-child": {
      // ...theme.mixins.borderBottom(1),
    },
    "& .rbc-timeslot-group": {
      minHeight: 64,
      // ...theme.mixins.borderBottom(1),
    },
    "& .rbc-date-cell": {
      padding: 8,
      fontSize: 16,
      fontWeight: 400,
      opacity: 0.5,
      "& > a": {
        color: "inherit",
      },
    },
    "& .rbc-event": {
      borderRadius: 4,
      padding: "4px 8px",
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: theme.shadows[0],
      transitionProperty: "box-shadow",
      transitionDuration: theme.transitions.duration.short,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      position: "relative",
      "&:hover": {
        boxShadow: theme.shadows[2],
      },
    },
    "& .rbc-row-segment": {
      padding: "0 4px 4px 4px",
    },
    "& .rbc-off-range-bg": {
      backgroundColor:
        theme.palette.type === "light"
          ? "rgba(0,0,0,0.03)"
          : "rgba(0,0,0,0.16)",
    },
    "& .rbc-show-more": {
      color: theme.palette.secondary.main,
      background: "transparent",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event": {
      position: "static",
    },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:first-child":
      {
        left: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
    "& .rbc-addons-dnd .rbc-addons-dnd-resizable-month-event .rbc-addons-dnd-resize-month-event-anchor:last-child":
      {
        right: 0,
        top: 0,
        bottom: 0,
        height: "auto",
      },
  },
  addButton: {
    position: "absolute",
    right: 12,
    top: 172,
    zIndex: 99,
  },
}));

function OrderFilter(props) {
  const classes = useStyles(props);
  const { languageStrings } = useLanguageHelper();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [venue, setVenue] = useState({ id: "All-Venues", name: "All Venues" });
  const [error, setError] = useState({ endDateError: "", startDateError: "" });
  const dispatch = useDispatch();

  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const _venueList = venueList && venueList.length ? venueList : [];
  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);

  const smallerEndDate = (startDate, endDate) => {
    if (startDate > endDate) {
      setError({
        endDateError:
          languageStrings["VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER"],
        startDateError:
          languageStrings["VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS"],
      });
    } else {
      setError({
        endDateError: "",
        startDateError: "",
      });
    }
  };

  const handleStartDateChange = (startDate) => {
    setStartDate(startDate);
  };
  const handleEndDateChange = (endDate) => {
    smallerEndDate(startDate, endDate);
    setEndDate(endDate);
  };
  const exportDataHandler = () => {
    let venueID = venue.id;
    if (venueID === "All-Venues") venueID = "";
    props.onCloseModal();

    dispatch(
      getExportOrderList({
        venueId: venueID,
        clientId: user._id,
        startDate,
        endDate,
        languageStrings,
      })
    );
  };

  const handleVenuChange = (event, val) => {
    if (event.target.value === "All-Venues") {
      setVenue({ id: "All-Venues", name: "All Venues" });
      return;
    }
    const [venueName = {}] =
      _venueList.filter((item) => item._id === event.target.value) || [];
    const { title = "" } = venueName || {};
    setVenue({ id: event.target.value, name: title });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={() => {
        setStartDate(new Date());
        setEndDate(new Date());
        setVenue({ id: "All-Venues", name: "All Venues" });
        setError({ endDateError: "", startDateError: "" });
        props.onCloseModal();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">
            {languageStrings["VENUE_DETAIL_PAGE.ALERT_TIME.TITLE"]}
          </h2>
          <p id="transition-modal-description">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="flex">
                <DatePicker
                  error={error.startDateError !== ""}
                  className="mt-32 mb-32"
                  margin="normal"
                  fullWidth
                  id="dateFrom"
                  name="startDate"
                  label={languageStrings["GENERAL.DATE"]}
                  value={startDate}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  onChange={handleStartDateChange}
                  helperText={
                    error.startDateError !== "" ? error.startDateError : ""
                  }
                />
                <label className="mt-52 mb-32 mx-2">
                  {languageStrings["GENERAL.TO"]}
                </label>

                <DatePicker
                  error={error.endDateError !== ""}
                  className={"mt-32 mb-32"}
                  margin="normal"
                  fullWidth
                  id="endDate"
                  name="endDate"
                  label={languageStrings["GENERAL.DATE"]}
                  value={endDate}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  onChange={handleEndDateChange}
                  helperText={
                    error.endDateError !== "" ? error.endDateError : ""
                  }
                />
              </div>
            </MuiPickersUtilsProvider>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {languageStrings["CALENDER_APP_PAGE.SELECT_VENUE"]}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={venue.id}
                label="Venue"
                inputVariant="outlined"
                onChange={handleVenuChange}
              >
                <MenuItem value={"All-Venues"}>
                  {languageStrings["GENERAL.ALL_VENUES"]}
                </MenuItem>
                {_venueList.map((item, index) => {
                  return <MenuItem value={item._id}>{item.title}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <div className="flex mt-64 justify-evenly">
              <div
                style={{
                  color: "white",
                  textDecoration: "none",
                  padding: "10px",
                  borderRadius: "10px",
                  backgroundColor: "#1D6F42",
                  cursor: "pointer",
                }}
                onClick={exportDataHandler}
              >
                {languageStrings["GENERAL.EXPORT_XLS"]}
              </div>
            </div>
          </p>
        </div>
      </Fade>
    </Modal>
  );
}

export default OrderFilter;
