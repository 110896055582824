import { RatingActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: RatingState = {
  ratingList: [],
  averageRating: 0,
  loading: false,
  searchText: "",
  androidUsers: "",
  iosUsers: "",
  userDetails: {},
  totalRatings: 0,
  countFiveStarRatings: 0,
};

interface Action {
  payload: any;
  type: string;
}

const RatingReducer = (
  state: RatingState = INITIAL_STATE,
  action: Action
): RatingState => {
  switch (action.type) {
    case RatingActionTypes.RATINGS_LIST_START: {
      return { ...state, loading: true, ratingList: [], averageRating: 0 };
    }
    case RatingActionTypes.RATINGS_LIST_SUCCESS: {
      const list =
        action.payload && action.payload.data && action.payload.data.ratings
          ? action.payload.data.ratings
          : [];
      const avg =
        action.payload &&
        action.payload.data &&
        action.payload.data.averageRating
          ? action.payload.data.averageRating
          : 0;
      const totalDocuments =
        action.payload &&
        action.payload.data &&
        action.payload.data.totalRatings
          ? action.payload.data.totalRatings
          : 0;
      const fiveStarRatings =
        action.payload &&
        action.payload.data &&
        action.payload.data.countFiveStarRatings
          ? action.payload.data.countFiveStarRatings
          : 0;
      return {
        ...state,
        ratingList: list,
        averageRating: avg,
        totalRatings: totalDocuments,
        countFiveStarRatings: fiveStarRatings,
        loading: false,
      };
    }
    case RatingActionTypes.RATINGS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case RatingActionTypes.SET_RATINGS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    default: {
      return state;
    }
  }
};

export default RatingReducer;
