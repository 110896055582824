import React, { useState, useEffect } from "react";
import _ from "@lodash";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Icon,
  IconButton,
  Backdrop,
  Fade,
  Modal,
} from "@material-ui/core";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import ProductItem from "./ProductItemV2";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import MenuItemDetailModal from "./MenuItemDetailModalV2";
import * as Actions from "app/store/actions";
import AlertDialog from "app/main/common/AlertDialog";
import {
  deleteMenuV2Category,
  getMenuItemV2ListSuccess,
  sortMenuListV2,
  updateCategoryVisibilityV2,
} from "app/store/menuV2/MenuV2Actions";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import VisibilityBanner from "app/main/common/VisibilityBanner";
import AddComboModal from "../AddComboModal";
import { createCatalogCombo } from "app/store/catalogue/CatalogueActions";
import ComboItem from "./ComboItemV2";
import { getSortedAllergenes } from "app/helpers/utilsHelper";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  accordion: {
    backgroundColor: "#edf2f7",
  },
}));

const CategoryItem = ({
  category,
  productId,
  onClick,
  index,
  onlyRead,
  defaultExpanded,
  currentVenues,
}) => {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const [openVisibility, setOpenVisibility] = useState(false);
  const [openComboModal, setOpenComboModal] = useState(false);
  const menuList = useSelector(({ banzzu }) => banzzu.menuV2.menuV2ItemList);
  const menuDetail = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  const menuListFiltered = menuList.filter(
    (menuItem) => menuItem.categoryId == category._id
  );

  const [list, setList] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const allergenesList = useSelector(
    ({ banzzu }) => banzzu.menu.menuItemAllergenes
  );
  const Allergenes = getSortedAllergenes({ allergenesList, languageStrings });

  useEffect(() => {
    setList(menuListFiltered);
  }, [expanded, menuList]);

  const [open, setOpen] = useState(false);
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let sortOrder = {
      from: source.index,
      to: destination.index,
      id: draggableId,
      categoryId: list.find((menu) => menu._id === draggableId).categoryId,
    };
    const { from, to } = sortOrder;

    const sortedCategoryList = [...list];
    let movedMenu = sortedCategoryList.splice(from, 1);
    sortedCategoryList.splice(to, 0, _.first(movedMenu));
    const _menuListFiltered = sortedCategoryList.filter(
      (menuItem) => menuItem.categoryId == category._id
    );

    getMenuItemV2ListSuccess(dispatch, _menuListFiltered);
    dispatch(sortMenuListV2(sortOrder, productId, _menuListFiltered));
  };
  const onVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };
  const onVisibilityClose = () => {
    setOpenVisibility(!openVisibility);
  };
  return (
    <>
      <Draggable
        key={category._id}
        draggableId={category._id}
        index={index}
        isDragDisabled={onlyRead || expanded}
      >
        {(provided, snapshot) => (
          <div
            className="mb-8"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <VisibilityModalV2
              open={openVisibility}
              onClose={onVisibilityClose}
              data={category}
              showGlobal={false}
              onVisibilityChange={(item = {}) => {
                dispatch(
                  updateCategoryVisibilityV2({
                    ...item,
                    categoryId: category._id,
                    menuId: menuDetail && menuDetail._id,
                  })
                );
              }}
            />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openComboModal}
              onClose={() => {
                setOpenComboModal(false);
              }}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openComboModal}>
                <div
                  className={`${classes.paper} md:h-auto h-screen overflow-scroll`}
                  style={{ maxWidth: "700px", maxHeight: "80%" }}
                >
                  <h2 id="transition-modal-title" className="pb-32">
                    {languageStrings["ADD_COMBO"]}
                  </h2>
                  <AddComboModal
                    Allergenes={Allergenes}
                    productId={"new"}
                    comboDetail={{}}
                    categoryId={category._id}
                    menuId={category.menuId}
                    onClose={() => {
                      setOpenComboModal(false);
                    }}
                    onSaveCombo={(form) => {
                      const data = {
                        ...form,
                        categoryId: category._id,
                        menuId: category.menuId,
                        comboId: form.comboId,
                      };
                      dispatch(createCatalogCombo(data));
                    }}
                  />
                </div>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div
                  className={`${classes.paper} md:h-auto h-screen overflow-scroll`}
                >
                  <h2 id="transition-modal-title" className="pb-32">
                    {languageStrings["MENU_DETAIL_PAGE.NEW_PRODUCT"]}
                  </h2>
                  <MenuItemDetailModal
                    productId={"new"}
                    categoryId={category._id}
                    menuId={category.menuId}
                    onClose={() => {
                      setOpen(false);
                    }}
                  />
                </div>
              </Fade>
            </Modal>
            <Accordion
              defaultExpanded={defaultExpanded}
              onChange={(e, expanded) => {
                setExpanded(expanded);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="flex items-center">
                  <div className="justify-start">
                    <Typography variant="subtitle2" component="h3">
                      {category.title}
                    </Typography>
                  </div>
                  <div className="mx-4">
                    <VisibilityBanner
                      visibility={category && category.visibility}
                    />
                  </div>
                  <IconButton
                    onClick={() => onClick()}
                    hidden={onlyRead ? true : false}
                  >
                    <Icon className="pr-8 text-blue">edit</Icon>
                  </IconButton>
                  <IconButton onClick={onVisibilityClick}>
                    <Icon className="material-symbols-outlined text-teal pr-8">
                      visibility
                    </Icon>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      dispatch(
                        Actions.openDialog({
                          children: (
                            <AlertDialog
                              onSuccess={() => {
                                dispatch(
                                  deleteMenuV2Category(
                                    [category._id],
                                    category.menuId
                                  )
                                );

                                Actions.closeDialog();
                              }}
                              title={
                                languageStrings["CONFIRM_DELETE_ALERT_TITLE"]
                              }
                              message={
                                languageStrings["CONFIRM_DELETE_ALERT_BODY"]
                              }
                            />
                          ),
                        })
                      );
                    }}
                    hidden={onlyRead ? true : false}
                  >
                    <Icon className="pr-16 text-red">delete</Icon>
                  </IconButton>
                </div>
              </AccordionSummary>
              <AccordionDetails className="bg-grey-lighter">
                <div className="w-full">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <DropableProductItem
                      list={list}
                      onlyRead={onlyRead}
                      currentVenues={currentVenues}
                    />
                  </DragDropContext>

                  <Button
                    className="mt-16 ml-8 bg-white"
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                    }}
                    hidden={onlyRead ? true : false}
                  >
                    <Icon className="mr-8">save</Icon>
                    {`${languageStrings["ADD_PRODUCT_IN"]} ${category.title}`}
                  </Button>
                  <Button
                    className="mt-16 bg-grey-lightest ml-40"
                    variant="contained"
                    onClick={() => setOpenComboModal(true)}
                    hidden={false}
                  >
                    <Icon className="mr-8">save</Icon>
                    {languageStrings["ADD_COMBO"]}
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </Draggable>
    </>
  );
};

const DropableProductItem = ({ list, onlyRead, currentVenues }) => {
  return (
    <Droppable droppableId="list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {list.map((menuItem, index) => {
            if (menuItem.comboId) {
              return (
                <ComboItem
                  key={menuItem._id}
                  menuItem={menuItem}
                  index={index}
                  onlyRead={onlyRead}
                  currentVenues={currentVenues}
                />
              );
            } else {
              return (
                <ProductItem
                  key={menuItem._id}
                  extraCategories={menuItem.extraCategories}
                  menuItem={menuItem}
                  index={index}
                  onlyRead={onlyRead}
                  currentVenues={currentVenues}
                />
              );
            }
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default withReducer("GalleryReducer", reducer)(CategoryItem);
