import { MenuActionTypes } from "./../redux/actionTypes";
import { updateObject } from "../utility";
import _ from "@lodash";

const INITIAL_STATE: MenuState = {
  menuList: [],
  menuItemList: [],
  menuDetail: {},
  menuItemDetail: {},
  menuExtraCategories: [],
  menuExtraCategoryItems: [],
  menuItemCategory: [],
  menuItemAllergenes: [],
  loading: false,
  searchText: "",
  searchCategory: "",
};

interface Action {
  payload: any;
  type: string;
}

const OffersReducer = (
  state: MenuState = INITIAL_STATE,
  action: Action
): MenuState => {
  switch (action.type) {
    case MenuActionTypes.MENU_LIST_START:
      return updateObject(state, { loading: true, menuList: [] });

    case MenuActionTypes.MENU_LIST_SUCCESS:
      return updateObject(state, {
        menuList: action.payload,
        menuDetail: {},
        loading: false,
      });

    case MenuActionTypes.MENU_LIST_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.SORT_MENU_LIST_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuActionTypes.SORT_MENU_LIST_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.SET_MENU_SEARCH_TEXT:
      return updateObject(state, { searchText: action.payload.searchText });

    case MenuActionTypes.SET_MENU_ITEM_SEARCH_CATEGORY:
      return updateObject(state, { searchCategory: action.payload });

    case MenuActionTypes.MENU_DETAIL_START:
      return updateObject(state, { loading: true, menuDetail: {} });

    case MenuActionTypes.MENU_DETAIL_SUCCESS:
      return updateObject(state, {
        menuDetail: action.payload,
        loading: false,
      });

    case MenuActionTypes.MENU_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_CREATE_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_CREATE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_CREATE_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_EDIT_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_EDIT_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_EDIT_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_DELETE_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_DELETE_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_ITEM_LIST_START:
      return updateObject(state, { loading: true, menuItemList: [] });

    case MenuActionTypes.MENU_ITEM_LIST_SUCCESS:
      return updateObject(state, {
        menuItemList: action.payload,
        loading: false,
      });

    case MenuActionTypes.MENU_ITEM_LIST_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_ITEM_DETAIL_START:
      return updateObject(state, { loading: true, menuItemDetail: {} });

    case MenuActionTypes.MENU_ITEM_DETAIL_SUCCESS:
      return updateObject(state, {
        menuItemDetail: action.payload,
        loading: false,
      });

    case MenuActionTypes.MENU_ITEM_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_ITEM_CATEGORY_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_ITEM_CATEGORY_SUCCESS:
      return updateObject(state, {
        menuItemCategory: action.payload,
        loading: false,
      });

    case MenuActionTypes.MENU_ITEM_CATEGORY_FAIL:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_ITEM_ALLERGENES_START:
      return updateObject(state, { loading: true, menuItemAllergenes: [] });

    case MenuActionTypes.MENU_ITEM_ALLERGENES_SUCCESS:
      return updateObject(state, {
        menuItemAllergenes: action.payload,
        loading: false,
      });

    case MenuActionTypes.MENU_ITEM_ALLERGENES_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_ITEM_CREATE_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_ITEM_CREATE_SUCCESS:
      return updateObject(state, {
        menuItemList: [...state.menuItemList, action.payload.data],
        loading: false,
      });

    case MenuActionTypes.MENU_ITEM_CREATE_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_ITEM_EDIT_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_ITEM_EDIT_SUCCESS:
      return updateObject(state, {
        menuItemList: state.menuItemList.map((menuItem) => {
          if (menuItem._id === action.payload._id) {
            return {
              ...menuItem,
              title: action.payload.title,
              description: action.payload.description,
              image: action.payload.image,
              price: action.payload.price,
              visibility: action.payload.visibility,
            };
          }
          return menuItem;
        }),
        loading: false,
      });

    case MenuActionTypes.MENU_ITEM_EDIT_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_ITEM_DELETE_START:
      let newMenuItemList = state.menuItemList;
      let newIndex = state.menuItemList.findIndex(
        (menuItem) => menuItem._id === action.payload
      );

      if (newIndex >= 0) {
        newMenuItemList.splice(newIndex, 1);
      }

      return updateObject(state, {
        menuItemList: state.menuItemList.filter(
          (menuItem) => menuItem._id !== action.payload
        ),
        loading: false,
      });

    case MenuActionTypes.MENU_ITEM_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_ITEM_DELETE_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.GET_MENU_EXTRA_CATEGORY_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.GET_MENU_EXTRA_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        menuExtraCategories: action.payload,
      });

    case MenuActionTypes.GET_MENU_EXTRA_CATEGORY_FAIL:
      return updateObject(state, { loading: true });

    case MenuActionTypes.GET_MENU_EXTRA_CATEGORY_ITEM_START:
      return updateObject(state, { loading: true, menuExtraCategoryItems: [] });

    case MenuActionTypes.GET_MENU_EXTRA_CATEGORY_ITEM_SUCCESS:
      return updateObject(state, {
        loading: false,
        menuExtraCategoryItems: action.payload,
      });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_CREATE_SUCCESS:
      return updateObject(state, {
        menuItemList: state.menuItemList.map((menuItem) => {
          if (menuItem._id === action.payload.menuItemId) {
            if (
              menuItem.extraCategories &&
              menuItem.extraCategories.length > 0
            ) {
              const item = menuItem.extraCategories.find(
                (ex) => ex._id == action.payload._id
              );
              if (!item) {
                menuItem.extraCategories.push(action.payload);
              }
            } else {
              menuItem.extraCategories = [action.payload];
            }
          }
          return menuItem;
        }),
        loading: false,
      });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_EDIT_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_EDIT_SUCCESS:
      return updateObject(state, {
        menuItemList: state.menuItemList.map((menuItem) => {
          if (menuItem._id === action.payload.menuItemId) {
            let newIndex = menuItem.extraCategories.findIndex(
              (extraCategory) => extraCategory._id === action.payload._id
            );
            menuItem.extraCategories[newIndex] = action.payload;
          }
          return menuItem;
        }),
        loading: false,
      });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_DELETE_SUCCESS:
      return updateObject(state, {
        menuItemList: state.menuItemList.map((menuItem) => {
          if (menuItem.extraCategories) {
            let newIndex = menuItem.extraCategories.findIndex(
              (extraCategory) => extraCategory._id === action.payload
            );

            if (newIndex >= 0) {
              menuItem.extraCategories.splice(newIndex, 1);
            }
          }
          return menuItem;
        }),
        loading: false,
      });

    case MenuActionTypes.SORT_EXTRA_CATEGORIES_START: {
      const { to, from, menuItemId } = action.payload;
      const sortedCategories = [
        ...state.menuItemList.find((item) => item._id === menuItemId)
          .extraCategories,
      ];
      const movedMenu = sortedCategories.splice(from, 1);
      sortedCategories.splice(to, 0, _.first(movedMenu));

      const menuItemList = state.menuItemList.map((menuItem) => {
        if (menuItem._id === menuItemId) {
          return {
            ...menuItem,
            extraCategories: sortedCategories,
          };
        }
        return menuItem;
      });

      return updateObject(state, { menuItemList });
    }

    case MenuActionTypes.SORT_MENU_LIST_START: {
      const { to, from, categoryId } = action.payload;

      let fromId = "";
      let toId = "";
      state.menuItemList.map((item) => {
        if (item.categoryId === categoryId && item.position === from) {
          fromId = item._id;
        }
        if (item.categoryId === categoryId && item.position === to) {
          toId = item._id;
        }
      });

      const sortedMenuItems = state.menuItemList.map((item) => {
        if (item._id === fromId) {
          return {
            ...item,
            position: to,
          };
        }
        if (item._id === toId) {
          return {
            ...item,
            position: from,
          };
        }
        return item;
      });

      return updateObject(state, {
        menuItemList: sortedMenuItems,
        loading: true,
      });
    }

    case MenuActionTypes.MENU_EXTRA_CATEGORY_EDIT_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_DELETE_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_DELETE_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_CAT_DELETE_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_CAT_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_CAT_DELETE_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.GET_MENU_EXTRA_CATEGORY_ITEM_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_START:
      return updateObject(state, { loading: true });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_FAIL:
      return updateObject(state, { loading: false });

    case MenuActionTypes.MENU_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuActionTypes.REMOVE_MENU_EXTRA_CATEGORY_SUCCESS:
      return updateObject(state, { loading: false, menuExtraCategories: [] });

    default:
      return state;
  }
};

export default OffersReducer;
