import { PromotionActionTypes } from "./../redux/actionTypes";
import { updateObject } from "../utility";
import { VISIBILITY } from "app/models/Visibility";
import { PROMOTION_TYPE } from "app/models/Promotion";

const INITIAL_STATE: PromotionState = {
  promotionList: [],
  promotionDetail: {
    _id: "new",
    title: "",
    type: PROMOTION_TYPE.BUY_2_GET_1,
    visibility: VISIBILITY.ALWAYS_AVAILABLE,
    visibilityRanges: [],
    products: [],
  },
  loading: false,
  searchText: "",
};

interface Action {
  payload: any;
  type: string;
}

const PromotionsReducer = (
  state: PromotionState = INITIAL_STATE,
  action: Action
): PromotionState => {
  switch (action.type) {
    case PromotionActionTypes.PROMOTIONS_LIST_START:
      return updateObject(state, {
        loading: true,
        promotionList: [],
      });

    case PromotionActionTypes.PROMOTIONS_LIST_SUCCESS:
      return updateObject(state, {
        promotionList: action.payload,
        loading: false,
      });

    case PromotionActionTypes.PROMOTIONS_LIST_FAIL:
      return updateObject(state, { loading: false });

    case PromotionActionTypes.SET_PROMOTION_SEARCH_TEXT:
      return updateObject(state, {
        searchText: action.payload.searchText,
      });

    case PromotionActionTypes.PROMOTION_DETAIL_START:
      return updateObject(state, {
        loading: true,
        promotionDetail: {},
      });

    case PromotionActionTypes.PROMOTION_DETAIL_SUCCESS:
      return updateObject(state, {
        promotionDetail: action.payload,
        loading: false,
      });

    case PromotionActionTypes.PROMOTION_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case PromotionActionTypes.CHANGE_PROMOTION_STATUS_START:
      return updateObject(state, { loading: true });

    case PromotionActionTypes.CHANGE_PROMOTION_STATUS_SUCCESS:
      return updateObject(state, { loading: false });
    case PromotionActionTypes.SET_PROMOTION_DETAIL:
      return updateObject(state, { promotionDetail: action.payload });
    default: {
      return state;
    }
  }
};

export default PromotionsReducer;
