import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import { FuseUtils } from "@fuse";
import History from "@history";
import * as Actions from "app/store/actions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl } from "app/helpers/utilsHelper";
import AlertDialog from "app/main/common/AlertDialog";
import CustomInput from "app/main/common/CustomInput";
import {
  editModifier,
  createModifier,
  getModifierDetail,
} from "app/store/catalogue/CatalogueActions";
import SkuButton from "app/main/common/List/components/SkuButton";

function ModifierDetail(props) {
  const dispatch = useDispatch();
  const loading = useSelector(({ banzzu }) => banzzu.catalogue.loading);
  let modifier = useSelector(({ banzzu }) => banzzu.catalogue.modifierDetail);

  const { languageStrings } = useLanguageHelper();
  const { modifierId } = props.match.params;
  const page = History.location.state.page || 0;

  useEffect(() => {
    if (modifierId !== "new") {
      dispatch(getModifierDetail(modifierId, page));
    }
  }, [dispatch, modifierId]);

  if (modifierId === "new") {
    modifier = {
      _id: "0",
      title: "",
    };
  }

  const dirtyInitial = {
    title: false,
  };

  const errorInitial = {
    title: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);
  const { form: initialForm, setForm: setInitialForm } = useForm(null);

  useEffect(() => {
    if (
      (modifier && !form) ||
      (modifier && form && modifier._id !== form._id)
    ) {
      setForm(modifier);
      setInitialForm(modifier);
    }
  }, [form, modifier, setForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "" && form.sku !== "" && form.sku !== undefined;
  }

  function hasNoErrors() {
    return !errorName();
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
          deleteImage: "false",
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleSkuChange(event) {
    handleChange(event);
    debouncedSku(event.target.value);
  }

  const debouncedSku = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function goBack() {
    History.push({
      pathname: `/catalogue/modifiers`,
      state: { page },
    });
  }
  const deleteModifierImage = () => {
    setForm((form) => ({
      ...form,
      image: null,
      imageFile: null,
      deleteImage: true,
    }));
  };
  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["CATALOGUE.MODIFIER_LIST"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["CATALOGUE.MODIFIER_NEW"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["CATALOGUE.MODIFIER_DETAIL"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={() => {
                    form._id !== "0"
                      ? dispatch(editModifier(form, page))
                      : dispatch(createModifier(form, page));
                  }}
                >
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div className="p-16 sm:p-24 max-w-xl max-h-screen">
                  <div className="flex flex-row justify-center sm:justify-start flex-wrap">
                    <input
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                      className="hidden"
                      id={`button-file-${modifierId}`}
                      type="file"
                      onChange={handleUploadChange}
                    />
                    <label
                      htmlFor={`button-file-${modifierId}`}
                      className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                    >
                      <Icon fontSize="large" color="action">
                        cloud_upload
                      </Icon>
                    </label>
                    {
                      <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                        <img
                          className="max-w-none w-auto h-full"
                          alt="whatson"
                          src={
                            form.image
                              ? formatImageUrl(form.image)
                              : "assets/images/avatars/noimage.png"
                          }
                        />
                        {form.image && (
                          <Icon
                            onClick={deleteModifierImage}
                            className="rounded-4 shadow-5 mb-2 text-2xl cursor-pointer absolute bottom-0"
                            style={{
                              color: "#f4516c",
                              backgroundColor: "#fff",
                            }}
                          >
                            delete
                          </Icon>
                        )}
                      </div>
                    }
                  </div>
                  <div className="flex">
                    <CustomInput
                      className="mt-8 mb-16 mr-8"
                      required
                      label={languageStrings["GENERAL.TITLE"]}
                      id="title"
                      name="title"
                      value={form.title ? form.title : ""}
                      onValueChange={handleTitleChange}
                      errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
                      hasError={(error) =>
                        setDirty((prevState) => {
                          return { ...prevState, title: error };
                        })
                      }
                      minLength={3}
                    />

                    <TextField
                      className="mt-8 ml-8 mb-16"
                      label={languageStrings["GENERAL.PRICE"]}
                      required
                      id="price"
                      name="price"
                      type={"number"}
                      value={form.price || ""}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="flex">
                    <CustomInput
                      className="mt-8 mb-16 mr-8"
                      required
                      label={languageStrings["CATALOGUE.SKU"]}
                      id="sku"
                      name="sku"
                      value={form.sku ? form.sku : ""}
                      onValueChange={handleSkuChange}
                      errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
                      hasError={(error) =>
                        setDirty((prevState) => {
                          return { ...prevState, title: error };
                        })
                      }
                      minLength={3}
                    />
                    <SkuButton
                      onCreateRandomString={(value) => {
                        setForm((prevForm) => ({
                          ...prevForm,
                          sku: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("VenuesReducer", reducer)(ModifierDetail);
