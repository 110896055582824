import React from "react";
import withReducer from "app/store/withReducer";
import reducer from "app/store/promotion/PromotionsReducer";
import { useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { Button, Icon, Typography } from "@material-ui/core";
import history from "@history";
import { FuseAnimate } from "@fuse";

const PromotionDetailHeader = (props) => {
  const promotion = useSelector(
    ({ banzzu }) => banzzu.promotion.promotionDetail
  );
  const { languageStrings } = useLanguageHelper();
  const previousPage = () => {
    history.push({
      pathname: `/catalogue/promotions`,
      state: { page: props.page },
    });
  };
  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex flex-col items-start max-w-full">
        <FuseAnimate animation="transition.slideRightIn" delay={300}>
          <Button onClick={() => previousPage()}>
            <Icon className="mr-4 text-20">arrow_back</Icon>
            {languageStrings["SIDEBAR_MENUE.PROMOTIONS"]}
          </Button>
        </FuseAnimate>

        <div className="flex items-center max-w-full">
          <div className="flex flex-col min-w-0">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="text-16 sm:text-20 truncate">
                {promotion._id === "new"
                  ? languageStrings["NEW_PROMOTION"]
                  : promotion.title}
              </Typography>
            </FuseAnimate>
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography variant="caption">
                {languageStrings["PROMOTION_DETAIL"]}
              </Typography>
            </FuseAnimate>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withReducer("PromotionsReducer", reducer)(PromotionDetailHeader);
