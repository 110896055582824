import moment from "moment";
import { utils, writeFile } from "xlsx";

export const exportRatingsInExcel = (ratingList, languageStrings) => {
  const data = ratingList.map((item) => {
    return {
      [languageStrings["GENERAL.VENUE"]]: item.venueTitle,
      [languageStrings["VIRTUAL_CARD_ORDER_TABLE"]]: item.tableTitle,
      [languageStrings["GENERAL.RATING"]]: item.rating,
      [languageStrings["GENERAL.DATE"]]: moment(item.createdAt).format(
        "DD/MM/YYYY"
      ),
      [languageStrings["GENERAL.TIME"]]: moment(item.createdAt).format("HH:mm"),
      [languageStrings["GENERAL.COMMENTS"]]: item.review,
    };
  });
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, "rating-list-file.xlsx");
};
