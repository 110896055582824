import { KioskActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: KioskState = {
  branding: null,
  loading: false,
};

interface Action {
  payload: any;
  type: string;
}

const KioskReducer = (
  state: KioskState = INITIAL_STATE,
  action: Action
): KioskState => {
  switch (action.type) {
    case KioskActionTypes.CREATE_KIOSK_BRANDING_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case KioskActionTypes.CREATE_KIOSK_BRANDING_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case KioskActionTypes.CREATE_KIOSK_BRANDING_SUCCESS: {
      return {
        ...state,
        loading: false,
        branding: action.payload,
      };
    }
    case KioskActionTypes.GET_KIOSK_BRANDING_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case KioskActionTypes.GET_KIOSK_BRANDING_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case KioskActionTypes.GET_KIOSK_BRANDING_SUCCESS: {
      return {
        ...state,
        loading: false,
        branding: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default KioskReducer;
