import React from "react";
import { TableCell } from "@material-ui/core";

const VenueRange = ({ n, obj }) => {
  return (
    <TableCell component="th" scope="row">
      {n.firstRange} - {n.lastRange}
    </TableCell>
  );
};

export default VenueRange;
