import React from "react";
import { TableCell } from "@material-ui/core";

const TruncatedText = ({ n, obj, type }) => {
  return (
    <TableCell component="th" scope="row" style={{ width: "30%" }}>
      <p className={"lineClamp2"}>{n[obj.id]}</p>
    </TableCell>
  );
};

export default TruncatedText;
