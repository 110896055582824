import React from "react";
import { TableCell } from "@material-ui/core";
import PaymentType from "../../payment/PaymentType";

const PaymentTypeOrderListCell = (props) => {
  const { n } = props;

  const definePaymentType = () => {
    if (n && n.partialPayByCash) return 3;
    else if (n && n.paidBy === 2 && !n.partialPayByCash) return 2;
    else if (n && !n.partialPayByCash && n.orderStatus !== "-1") return 1;
    else return 0;
  };

  const paymentType = definePaymentType();

  return (
    <TableCell className="truncate" component="th" scope="row">
      <PaymentType paymentType={paymentType} />
    </TableCell>
  );
};

export default PaymentTypeOrderListCell;
