export default {
  "ALLERGENE.CELERY": "Apio",
  "ALLERGENE.CEREALS": "Cereales",
  "ALLERGENE.CRUSTACEANS": "Crustáceos",
  "ALLERGENE.EGGS": "Huevos",
  "ALLERGENE.FISH": "Pescado",
  "ALLERGENE.LUPIN": "Altramuces",
  "ALLERGENE.MILK": "Leche",
  "ALLERGENE.MOLLUSCS": "Moluscos",
  "ALLERGENE.MUSTARD": "Mostaza",
  "ALLERGENE.NUTS": "Productos con cáscara",
  "ALLERGENE.PEANUTS": "Cacachuetes",
  "ALLERGENE.SESAME_SEEDS": "Semillas de Sésamo",
  "ALLERGENE.SULPHER_DIOXIDE": "Dióxido de Azufre",
  "ALLERGENE.TITLE": "Alérgenos",
  "ALLERGENE.SOYA": "Soja",
  "HOME_PAGE.RESTAURANT": "Restaurante",
  "HOME_PAGE.YOUR_CODE": "Tu código QR",
  "HOME_PAGE.PRINT": "Imprimir ",
  "HOME_PAGE.DOWNLOAD": "Descargar ",
  "HOME_PAGE.CHANGE_URL": "Cambiar URL",
  "HOME_PAGE.URL_TAKEN": "El url introducido ya existe",
  "HOME_PAGE.ANDROID_USER": "Usuarios Android",
  "HOME_PAGE.IOS_USER": "Usuarios IOS",
  "HOME_PAGE.ANALYTICS": "Estadísticas",
  "HOME_PAGE.USER_REGISTERED": "Usuarios Registrados",
  "HOME_PAGE.ORDERS_AMOUNT": "Ingresos Pedidos Online",
  "HOME_PAGE.PENDING_ORDERS": "Pedidos Pendientes",
  FUTURE_ORDERS: "Programado",
  "HOME_PAGE.PROGRESS_TITLE": "Tu cuenta, tus posibilidades",
  "HOME_PAGE.PROGRESS_DESCRIPTION":
    "Nos encanta ver tu progreso, por eso te indicamos todas las funcionalidades que aún tienes por descubrir... ¡y las que ya has descubierto!",
  "HOME_PAGE.HIDE_TEXT": "Esconder objetivos completados",
  "HOME_PAGE.SHOW_TEXT": "Mostrar objetivos completados",
  "HOME_PAGE_GOALS.RESTAURANT_LOGO": "Sube el logotipo de tu restaurante",
  "HOME_PAGE_GOALS.CUSTOMIZE_COLOR": "Personaliza los colores de tu web",
  "HOME_PAGE_GOALS.CUSTOMIZE_HEADER": "Elige una cabecera para tu web",
  "HOME_PAGE_GOALS.PREVIEW_WEB": "Previsualiza tu web",
  "HOME_PAGE_GOALS.CREATE_FIRST_CATEGORY":
    "Crea la primera categoría de tu menú",
  "HOME_PAGE_GOALS.UPLOAD_FIRST_MENU": "Sube el primer plato de tu carta",
  "HOME_PAGE_GOALS.ADD_ALLERGENS": "¡Añade alérgenos a la carta!",
  "HOME_PAGE_GOALS.UPLOAD_PRODUCT_PHOTOS": "Sube fotos de tus productos",
  "HOME_PAGE_GOALS.ADD_EXTRA_OPTIONS": "Añade opciones extra a tus platos",
  "HOME_PAGE_GOALS.CREATE_SECOND_CATEGORY": "Crea una segunda categoría",

  "HOME_PAGE_GOALS.ADD_STRIPE_KEY":
    "Añade tu Clave stripe para recibir pagos online",
  "GENERAL.STRIPE_PUB_KEY": "Stripe Clave Publicable",
  "HOME_PAGE_GOALS.ACTIVATE_ORDER_FROM_TABLE": "Activa Pedidos desde la mesa",
  "HOME_PAGE_GOALS.ACTIVATE_DELIVERY": "Activa Pedidos a Domicilio",
  "HOME_PAGE_GOALS.ACTIVATE_PICK_UP": "Activa Recogida en Local",
  "HOME_PAGE_GOALS.CREATE_FIRST_TABLE": "Crea tu primera mesa",
  "HOME_PAGE_GOALS.RECEIVE_FIRST_ORDER": "Recibe tu primer pedido",
  "HOME_PAGE_GOALS.ADD_PICKUP_AVAILABILITY":
    "Crea tu primera disponibilidad para pedidos a recoger",
  "HOME_PAGE_GOALS.ADD_DELIVERY_AVAILABILITY":
    "Crea tu primera disponibilidad para pedidos a domicilio",
  "HOME_PAGE_GOALS.ADD_DELIVERY_AREA": "Configura tu zona de reparto",
  "HOME_PAGE.SELECT_YEAR": "Seleccionar año",

  "BOOKING_DETAIL_PAGE.BACK_BUTTON": "Lista Reservas",
  "BOOKING_DETAIL_PAGE.N_PEOPLE": "N. Personas",
  "BOOKING_DETAIL_PAGE.SELECT_VENUE": "Selecciona Local",
  "BOOKING_DETAIL_PAGE.TITLE": "Detalle Reserva",
  "BOOKING_LIST_HEADER_PAGE.TITLE": "Lista de Confirmaciones",
  "BRANDING_HEADER.TITLE": "Personaliza tu pagina web",
  "BRANDING.UPLOAD_LOGO": "Sube tu logo",
  "BRANDING.UPLOAD_HEADER": "Sube tu imagen principal",
  "BRANDING.CHOOSE_COLOR": "Elige tus colores corporativos",
  "CALENDER_APP_PAGE.SELECT_VENUE": "Selecciona Local",
  "CALENDER_HEADER_PAGE.TITLE": "Calendario",
  "CHANGE_PASSWORD_PAGE.TITLE": "Cambiar Contraseña",
  "DRAW_DETAIL_PAGE.BACK_BUTTON": "Lista Sorteos",
  "DRAW_DETAIL_PAGE.EVENT_DURATIONL": "Finalización Evento",
  "DRAW_DETAIL_PAGE.NEW_DRAW": "Nuevo Sorteo",
  "DRAW_DETAIL_PAGE.SELECT_DRAW": "Selecciona Sorteo",
  "DRAW_DETAIL_PAGE.TITLE": "Detalle Sorteo",
  "DRAW_LIST_HEADER_PAGE.TITLE": "Sorteo",
  "DRAW_LIST_PAGE.BUTTON_TEXT": "Crear Sorteo",
  "DRAW_LIST_PAGE.TITLE": "Sorteos",

  "EXTRA_CATEGORIES.CATEGORY_TYPES": "Tipo de categoria",
  "EXTRA_CATEGORIES.MINIMUM_QUANTITY": "Cantidad Mínima",
  "EXTRA_CATEGORIES.MAXIMUM_QUANTITY": "Cantidad Máxima",
  "EXTRA_CATEGORIES.ENABLE_QUANTITY": "Activar Limitación",
  "EXTRA_CATEGORIES.ADD": "Nueva elección",
  "EXTRA_CATEGORIES.EXTRA_PRICE": "Precio Extra",
  "EXTRA_CATEGORIES.SELECTED": "Preseleccionado",
  "EXTRA_CATEGORIES.CONNECT_CATEGORY": "Categoria Relacionada",

  "MATERIAL_TABLE.DELETE": "Estas seguro de eliminar esta fila?",
  "MATERIAL_TABLE.NO_RECORDS": "Sin datos que mostrar",
  "MATERIAL_TABLE.ROW_SELECTED": "{0} fila(s) seleccionadas",
  "MATERIAL_TABLE.DISPLAYED_ROWS": "{from}-{to} de {count}",
  "MATERIAL_TABLE.ROWS": "Filas",

  "ERRORS.STRIPE_ID_INVALID": "La clave stripe introducida es invalida",
  "ERRORS.STRIPE_KEY_INVALID": "The stripe public key introduced is invalid",
  "ERRORS.REDSYS_SECRET_KEY":
    "La clave secreta de redsys introducida no es válida",
  "ERRORS.REDSYS_MERCHANT_CODE":
    "El código de comerciante redsys introducido no es válido",
  "ERRORS.REDSYS_MERCHANT_NAME":
    "El nombre del comerciante redsys introducido no es válido",
  "ERRORS.IMAGE_IS_REQUIRED": "Imagen es obligatoria",
  "ERRORS.FIELD_IS_REQUIRED": "Field Required",
  "ERRORS.INVALID_EMAIL": "Email incorrecto",
  "ERRORS.INVALID_PHONE": "Teléfono incorrecto",
  "ERRORS.INVALID_URL": "Url incorrecto",
  "ERRORS.INVALID_LOGIN": "Login incorrecto. Por favor intente de nuevo",
  "ERRORS.MINIMUM_10_CHAR": "Tamaño mínimo 10 caracteres",
  "ERRORS.MINIMUM_3_CHAR": "Tamaño mínimo 3 caracteres",
  "ERRORS.MINIMUM_1_CHAR": "Tamaño mínimo 1 caracter",
  "ERRORS.MINIMUM_4_CHAR": "Tamaño mínimo 4 caracteres",
  "ERRORS.MINIMUM_6_CHAR": "Tamaño mínimo 6 caracteres",
  "ERRORS.EMAIL_TAKEN": "El email introducido ya existe",
  "ERRORS.MUST_BE_NUMBER": "Debe ser un número",
  "ERRORS.ORIGINAL_PRICE_SMALLER":
    "Precio original debe ser superior al precio de descuento",
  "ERRORS.REDUCED_PRICE_GREATER":
    "Precio de descuento debe ser inferior al precio original",
  "ERRORS.SELECT_ONE": "Selecciona al menos una opcion",
  "ERRORS.PASSWORD_MUST_BE_7": "La contraseña debe tener más de 6 caracteres",
  "GALLERY_DETAIL_PAGE.SELECT_VENUE": "Selecciona Local",
  "GALLERY_DETAIL_PAGE.TITLE": "Galería",
  "GENERAL.ADDRESS": "Dirección",
  "GENERAL.DUPLICATE": "Duplicar",
  "GENERAL.CITY": "Ciudad",
  "GENERAL.CREATE": "Crear",
  "GENERAL.JANUARY": "Enero",
  "GENERAL.FEBRUARY": "Febrero",
  "GENERAL.MARCH": "Marzo",
  "GENERAL.APRIL": "Abril",
  "GENERAL.MAY": "Mayo",
  "GENERAL.JUNE": "Junio",
  "GENERAL.JULY": "Julio",
  "GENERAL.AUGUST": "Agosto",
  "GENERAL.SEPTEMBER": "Septiembre",
  "GENERAL.OCTOBER": "Octubre",
  "GENERAL.NOVEMBER": "Noviembre",
  "GENERAL.DECEMBER": "Diciembre",
  "GENERAL.ADD_BUTTON": "Añadir",
  "GENERAL.BASIC_INFO": "Info básica",
  "GENERAL.CANCEL_BUTTON": "Cancelar",
  "GENERAL.CLIENT": "Cliente",
  "GENERAL.COMMENTS": "Comentarios",
  "GENERAL.CONFIRM_BUTTON": "Confirmar",
  "GENERAL.CONTACT_INFO": "Información de Contacto",
  "GENERAL.DATE": "Fecha",
  "GENERAL.SCHEDULE_DATE_TIME": "Schedule Time",
  "GENERAL.DELETE": "Eliminar",
  "GENERAL.DENY_BUTTON": "Cancelar",
  "GENERAL.DESCRIPTION": "Descripción",
  "GENERAL.DETAIL": "Detalle",
  "GENERAL.EMAIL": "Email",
  "GENERAL.FRIDAY": "Viernes",
  "GENERAL.IMAGE": "Imagen",
  "GENERAL.MONDAY": "Lunes",
  "GENERAL.NAME": "Nombre",
  "GENERAL.NUMBER": "Número",
  "GENERAL.OPENING_HOURS": "Horas de Apertura",
  "GENERAL.DELIVERY_INFO": "Envío a Domicilio",
  "GENERAL.DELIVERY_TIME": "Horario Reparto",
  "GENERAL.MIN_DELIVERY_PRICE": "Importe Envío",
  "GENERAL.MIN_ORDER_PRICE": "Importe mínimo pedido",
  "GENERAL.COLLECTION_TIME": "Horario Recogida",
  "GENERAL.ORIGINAL_PRICE": "Precio Original",
  "GENERAL.DISCOUNTED_PRICE": "Precio Descontado",
  "GENERAL.PASSWORD": "Contraseña",
  "GENERAL.EXPORT": "Exportar",
  "GENERAL.EXPORT_CSV": "Descarga csv",
  "GENERAL.EXPORT_XLS": "Descarga xls",
  "GENERAL.PHONE": "Teléfono",
  "GENERAL.POINTS": "Puntos",
  "GENERAL.POSTCODE": "Código Postal",
  "GENERAL.PRICE": "Importe",
  "GENERAL.REDUCED_PRICE": "Precio Descuento",
  "GENERAL.ROWS_PER_PAGE": "Registros por página",
  "GENERAL.SATURDAY": "Sábado",
  "GENERAL.SAVE": "Guardar",
  "GENERAL.SAVE_PREVIEW": "Guardar y previsualizar",
  "GENERAL.SAVE_BUTTON": "Guardar",
  "GENERAL.SEARCH": "Buscar",
  "GENERAL.SEND": "Enviar",
  "GENERAL.SUNDAY": "Domingo",
  "GENERAL.THURSDAY": "Jueves",
  "GENERAL.TIME": "Hora",
  "GENERAL.TITLE": "Título",
  "GENERAL.TUESDAY": "Martes",
  "GENERAL.TYPE": "Tipo",
  "GENERAL.USER_LIST": "Lista usuarios",
  "GENERAL.WINNERS_LIST": "Ganadores",
  "GENERAL.VENUE": "Local",
  "GENERAL.STATUS": "Estado",
  "GENERAL.WEBSITE": "Página web",
  "GENERAL.WEDNESDAY": "Miércoles",
  "GENERAL.CATEGORY": "Categoría",
  "GENERAL.ITEMS": "Items",
  "GENERAL.ACTIONS": "Acciones",
  "GENERAL.REF": "Ref #",
  "GENERAL.ALL_VENUES": "Todos los locales",
  "GENERAL.ALERT_TITLE": "Confirmación",
  "GENERAL.ORDER_TYPE": "Tipo Pedido",
  "GENERAL.LATITUDE": "Latitud",
  "GENERAL.LONGITUDE": "Longitud",
  "GENERAL.NO_ORDER_LIST": "No hay órdenes para esta selección",
  "ORDER_FROM_TABLE.TITLE_ERROR": "Falta el título",
  "GENERAL.ALERT_DESC":
    "¿Estás seguro quieres retroceder sin guardar los cambios?",
  "GENERAL.AREA_REMOVE_ALERT_DESC":
    "¿Estás seguro de que quieres eliminar esta zona?",
  "GENERAL.REMOVE": "Eliminar",
  "GENERAL.NO_OF_PEOPLE": "N. personas",
  "GENERAL.PLATFORM": "Plataforma",
  "GENERAL.STRIPE_KEY": "Stripe Clave Secreta",
  "GENERAL.REDSYS_KEY": "Clave secreta",
  "GENERAL.MERCHENT_CODE": "Código de comerciante",
  "GENERAL.MERCHENT_NAME": "Nombre del comerciante",
  "GENERAL.REDSYS_IS_TEST_MODE": "Modo Pruebas",
  TIME_SETTINGS_COLLECTION: "Recogida",
  TIME_SETTINGS_DELIVERY: "Entrega",
  "GENERAL.MIN": "min",
  "GENERAL.ENTER_TIME": "Introduzca tiempo por defecto",
  "GENERAL.COLLECTION_ORRDER_CONFIRMATION_TITLE": "Tiempo estimado de recogida",
  "GENERAL.DELIVERY_ORRDER_CONFIRMATION_TITLE": "Tiempo estimado de entrega",
  "GENERAL.ESTIMATED_TIME": "Tiempo Estimado",

  "GENERAL.ADV_TITLE": "Ventajas",
  "GENERAL.DIS_ADV_TITLE": "Desventajas",
  "GENERAL.USER_GUIDE": "Guía del usuario",
  "GENERAL.REDSYS_ADV1":
    "Comisiones bajas: Entre 0,15-0,3% dependiendo del trato recibido",
  "GENERAL.REDSYS_ADV2": "con tu banco",
  "GENERAL.REDSYS_ADV3":
    "Trabajando con la mayoría del banco: como Banco Sabadell, ING Direct.",
  "GENERAL.REDSYS_ADV4": "BBVA, Caixabank, Santander, Banco Popular.",
  "GENERAL.REDSYS_DIS_ADV1":
    "Tarjeta de pago no almacenada: sus clientes deben introducir su método de pago en cada compra.",
  "GENERAL.REDSYS_DIS_ADV2":
    "Procesos de revisión prolongados para ser aceptado y debe solicitarlo a su banco actual o su solicitud puede ser rechazada.",

  "GENERAL.STRIPE_ADV1":
    "Rápido y fácil de configurar para comenzar a recibir sus pedidos en línea.",
  "GENERAL.STRIPE_ADV2":
    "Cuadro de mando intuitivo y moderno donde gestiona todas tus transacciones de dinero.",
  "GENERAL.STRIPE_ADV3":
    "Trabaja con todos los bancos y recibirás el dinero en tu cuenta pocos días después de que se realice la transacción.",
  "GENERAL.STRIPE_ADV4":
    "Tarjetas de pago almacenadas de forma segura: por lo que su usuario debe introducir su tarjeta solo la primera vez que realiza la compra.",
  "GENERAL.STRIPE_DIS_ADV1": "Comisiones superiores: 1.4% + .",

  "GENERAL.TO": "Hasta",
  "GENERAL.YES": "Si",
  "GENERAL.FIRST_NAME": "Nombre",
  "GENERAL.LAST_NAME": "Apellido",
  "GENERAL.YOUR_ARE_THERE": "¡Ya casi estas!",
  "GENERAL.ONLY_THE_LAST":
    "Solo falta el último ingrediente para disfrutar totalmente de Banzzu",
  "GENERAL.MAKE_DEFAULT": "Hacer por defecto",
  "GENERAL.DEFAULT": "Defecto",
  "GENERAL.BACK": "Atras",
  "GENERAL.PAY": "Pagar",
  "GENERAL.FULL_NAME": "Nombre completo",
  "GENERAL.CONTINUE": "Continuar",
  "GENERAL.DELIVERY_COST": "Coste de envio",
  "GENERAL.ADDRESS_LIST": "Direcciones",
  "GENERAL.AVERAGE_ORDER": "Pedido Medio",
  "GENERAL.TOP_FIVE_DISHES": "Top 15 Platos",
  "GENERAL.ICOME_PER_DAY": "Ingresos acumulados por día",
  "GENERAL.CODE": "Código",
  "GENERAL.PERCENTAGE": "Porcentaje",
  "GENERAL.QUANTITY": "Importe",
  "GENERAL.EXPIREABLE": "Caducidad",
  "GENERAL.AMOUNT": "Cantidad",
  "GENERAL.USED": "Usado",
  "GENERAL.MINIMUM_QUANTITY": "Cantidad Mínima",
  "GENERAL.ACTIVATE": "Activa",
  "GENERAL.DISOCUNT_CODE": "Código de Descuento",
  "GENERAL.MINIMUM_AMOUNT": "Cantidad Minima",
  "GENERAL.EXPIRY_DATE": "Fecha Caducidad",
  "GENERAL.USABILITY": "Usabilidad",
  "GENERAL.SINGLE_USE": "Uso único",
  "GENERAL.MULTIPLE_USE": "Usos Multiples",
  "GENERAL.CONFIRMATION_TITLE": "Confirmación",
  "GENERAL.PAYMENT_METHOD": "Método de Pago",
  "GENERAL.CONFIRMATION_SUBTITLE":
    "¿Te gustaría solicitar información sobre este servicio adicional?",
  "GENERAL.TOTAL_SPENT": "Gasto total",

  "LOGIN_PAGE.LOGIN_BUTTON": "Iniciar sesión",
  "LOGIN_PAGE.LOGIN_TO_ACCOUNT": "LOGIN EN TU CUENTA",
  "LOGIN_PAGE.REGISTER_LINK": "¿No tienes una cuenta? Regístrate",
  "LOGIN_PAGE.FORGOT_PASSWORD": "¿Recuperar contraseña?",
  "LOGIN_PAGE.ARE_YOU_SURE.ARE_YOU_SURE":
    "Estas seguro quieres recuperar tu contraseña?",
  "LOGIN_PAGE.CONFIRM": "Confirmar",
  "LOGIN_PAGE.CANCEL": "Cancelar",
  "LOGIN_PAGE.ENTER_VALID_EMAIL": "Introduzca un correo electrónico válido",
  "LOGIN_PAGE_.EMAIL_EXIST": "El correo electrónico no existe",
  "LOGIN_PAGE_.CHECK_EMAIL": "Consultar su correo electrónico",
  "LOGIN_PAGE_.REMEMBER_ME": "Recuérdame",

  "FORGOT_PASSWORD_PAGE.RESET_PASSWORD": "Reinicia tu contraseña",
  "FORGOT_PASSWORD_PAGE.SAVE_BUTTON": "Guardar",
  "FORGOT_PASSWORD_PAGE.PASSWORD": "Nueva contraseña",
  "FORGOT_PASSWORD_PAGE.CONFIRM_PASSWORD": "Confirmar contraseña",
  FORGOT_PASSWORD_PAGE_TOKEN_EXPIRED: "El token ha caducado",
  "FORGOT_PASSWORD_PAGE.SUCCESS": "Contraseña cambiada con éxito",
  "FORGOT_PASSWORD_PAGE.PASSWORD_MUST_BE_7":
    "La contraseña debe tener más de 6 caracteres",
  "FORGOT_PASSWORD_PAGE.CONFIRM_PASSWORD_MUST_BE_7":
    "Confirmar contraseña debe tener más de 6 caracteres",
  "FORGOT_PASSWORD_PAGE.PASSWORD_SAME":
    "Contraseña y confirmar la contraseña debe ser la misma",

  "MENU_CATEGORY_PAGE.ADD_CATEGORY": "Añadir Categoría",
  "MENU_CATEGORY_PAGE.PRODUCT_LIST": "Lista Productos",
  MENU_NEW_MENU_CREATED: "Tu menu se ha creado correctamente",
  "MENU_DETAIL_PAGE.CREATE_CATEGORY": "Crea Categoria",
  "MENU_DETAIL_PAGE.UPDATE_CATEGORY": "Actualiza Categoría",
  "MENU_DETAIL_PAGE.CREATE_EXTRA_CATEGORY": "Crea categoría extra",
  "MENU_DETAIL_PAGE.BACK_BUTTON": "Lista Menus",
  "MENU_DETAIL_PAGE.MENU_TYPE": "Tipo de Menú",
  "MENU_DETAIL_PAGE.NEW_MENUE": "Nuevo Menú",
  "MENU_DETAIL_PAGE.NEW_PRODUCT": "Nuevo Producto",
  "MENU_DETAIL_PAGE.PDF_IS_REQUIRED": "PDF es obligatorio",
  "MENU_DETAIL_PAGE.TITLE": "Menú Detalle",
  "MENU_DETAIL_PAGE.HELP": "Necesitas ayuda",
  "MENU_DETAIL_PAGE.MENU_SHARED":
    "Este menú es compartido y no puede ser editado",
  "MENU_DETAIL_PAGE.VENUES": "Locales",
  "MENU_DETAIL_PAGE.VIEW_PDF": "Ver PDF",
  "MENU_DETAIL_PAGE.DELETE_CATEGORY": "Elimina Categoría",
  "MENU_DETAIL_PAGE.VIRTUAL_MENU_PRODUCTS": "Productos Menú Virtual",
  "MENU_DETAIL_PAGE.MENU_AVAILABILITY": "Menú Disponible en:",
  "MENU_DETAIL_PAGE.FUTURE_ORDERS": "Pedidos Futuras",
  "MENU_DETAIL_PAGE.DELIVERY": "Envío a Domicilio",
  "MENU_DETAIL_PAGE.COLLECTION": "Recogida en Local",
  "MENU_DETAIL_PAGE.ALLERGENES": "PDF Alérgenos (Opcional)",
  "MENU_DETAIL_PAGE.ORDER_FROM_TABLE": "Pedir desde la mesa",

  "MENU_ITEM_DETAIL_PAGE.PRODUCT_DETAIL": "Detalle Productos",
  "MENU_ITEM_DETAIL_PAGE.NEW_PRODUCT": "Nuevo Producto",
  "MENU_ITEM_DETAIL_PAGE.PRODUCT_LIST": "Lista Productos",
  "MENU_ITEM_DETAIL_PAGE.SELECT_CATEGORY": "Selecciona Categoría",
  "MENU_ITEM_LIST_HEADER_PAGE.SELECT_CATEGORY": "Selecciona Categoría",
  "MENU_ITEM_LIST_HEADER_PAGE.CREATE_PRODUCT": "Crear Productos",
  "MENU_ITEM_LIST_HEADER_PAGE.PRODUCT": "Productos",
  "MENU_ITEM_LIST_PAGE.PRODUCT_LIST": "Lista Productos",
  "MENU_ITEM_LIST_PAGE.CATEGORIES": " Lista Categorías",
  "MENU_LIST_PAGE.BUTTON_TEXT": "Crear Menú",
  "MENU_LIST_PAGE.TITLE": "Lista Menú",
  "MENU_LIST_CATEGORY_PAGE.TITLE": "Menú Categorías",
  MENU_URL: "URL Menú",
  MENU_URL_ERROR: "URL no válida",

  "NAVBAR.MASTER_ACCOUNT": "Cuenta Admin",
  "NOTIFICATION_DETAIL_PAGE.BUTTON_BACK": "Notificación Push",
  "NOTIFICATION_DETAIL_PAGE.TITLE": "Mandar Notificación",
  "NOTIFICATION_LIST_PAGE.TITLE": "Notificación Push",
  "OFFER_DETAIL_PAGE.BACK_BUTTON": "Lista Ofertas",
  "OFFER_DETAIL_PAGE.DISABLE_PRICE": "Precio deshabilitado",
  "OFFER_DETAIL_PAGE.NEW_OFFER": "Oferta",
  "OFFER_DETAIL_PAGE.SELECT_VENUE": "Selecciona Local",
  "OFFER_DETAIL_PAGE.TITLE": "Detalle Oferta",
  "OFFER_LIST_HEADER_PAGE.BUTTON_TEXT": "Crear Ofertas",
  "OFFER_LIST_HEADER_PAGE.TITLE": "Lista Ofertas",
  "ORDER_LIST_PAGE.TITLE": "Pedidos",
  "ORDER_LIST_PAGE.ALL_STATUS": "Todos los estados",
  "ORDER_LIST_PAGE.ALL_TYPES": "Todos los tipos",
  "ORDER_LIST_PAGE.STATUS_PLACEHOLDER": "Selecciona estado",
  "ORDER_LIST_PAGE.TYPES_PLACEHOLDER": "Selecciona tipo",
  "ORDER_PAGE.CUSTOMER": "Customer",
  "ORDER_PAGE.EMAIL": "Email",
  "ORDER_PAGE.ID": "ID",
  "ORDER_PAGE.NAME": "Nombre",
  "ORDER_PAGE.STATUS_BUTTON.CONFIRM": "Aceptar Pedidos",
  "ORDER_PAGE.STATUS_BUTTON.CANCEL": "Cancelar",
  "ORDER_PAGE.STATUS_BUTTON.COLLECT": "Listo para Recoger",
  "ORDER_PAGE.STATUS_BUTTON.COLLECTD": "Pedido Recogido",
  "ORDER_PAGE.STATUS_BUTTON.START_PREPARING": "Preparando",
  "ORDER_PAGE.STATUS_BUTTON.START_DELIVERY": "Comenzar Envío",
  "ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED": "Completada",
  "ORDER_PAGE.EXTRAS": "Extras",
  "ORDER_PAGE.ORDER": "Pedido",
  "ORDER_PAGE.ORDERS": "Pedidos",
  "ORDER_PAGE.ORDER_DETAIL": "Detalle Pedido",
  "ORDER_PAGE.PHONE": "Teléfono",
  "ORDER_PAGE.PRODUCTS": "Productos",
  "ORDER_PAGE.QUANTITY": "Cantidad",
  "ORDER_PAGE.DELIVERY": "Dirección Envío",
  "ORDER_PAGE.COMMENTS": "Comentarios",

  "SCHEDULE_ORDER_LIST_PAGE.TITLE": "Programar órdenes",
  "SCHEDULE_ORDER_PAGE.ORDERS": "Programar órdenes",

  "REWARD_DETAIL_PAGE.BACK_BUTTON": "Lista Recompensas",
  "REWARD_DETAIL_PAGE.NEW_REWARD": "Nueva Recompensa",
  "REWARD_DETAIL_PAGE.TITLE": "Detalle Recompensa",
  "REWARD_LIST_PAGE.BUTTON_TEXT": "Crear Recompensas",
  "REWARD_LIST_PAGE.TITLE": "Recompensas",
  "SIDEBAR_MENUE.CREATE_MENUE": "Crear Menú",
  "SIDEBAR_MENUE.CREATE_OFFER": "Crear Oferta",
  "SIDEBAR_MENUE.CREATE_WHATSON": "Crear Noticia",
  "SIDEBAR_MENUE.HOME": "Inicio",
  "SIDEBAR_MENUE.ANALYTICS": "Analíticas",
  "SIDEBAR_MENUE.DRAWS": "Sorteos",
  "SIDEBAR_MENUE.DRAWS_CREATE": "Crear Sorteo",
  "SIDEBAR_MENUE.DRAWS_LIST": "Lista Sorteos",
  "SIDEBAR_MENUE.GALLERY": "Galería",
  "SIDEBAR_MENUE.LOYALTY_PROGRAM": "Programa de Fidelización",
  "SIDEBAR_MENUE.MENUE_LIST": "Lista Menú",
  "SIDEBAR_MENUE.MENUE_TITLE": "Menú",
  "SIDEBAR_MENUE.MENUE_TITLE_V2": "Menú V2",
  "SIDEBAR_MENUE.NOTIFICATIONS": "Notificación",
  "SIDEBAR_MENUE.NOTIFICATIONS_PUSH": "Notificacion Push",
  "SIDEBAR_MENUE.NOTIFICATIONS_SEND": "Enviar Push",
  "SIDEBAR_MENUE.OFFERS": "Ofertas",
  "SIDEBAR_MENUE.OFFER_LIST": "Lista Ofertas",
  "SIDEBAR_MENUE.ORDERS": "Pedidos",
  "SIDEBAR_MENUE.ORDERS_LIST": "Lista Pedidos",
  "SIDEBAR_MENUE.SCHEDULE_ORDERS": "Órdenes Programadas",
  "SIDEBAR_MENUE.PROFILE": "Perfil",
  "SIDEBAR_MENUE.BRANDING": "Imagen Coorporativa",
  "SIDEBAR_MENUE.CATALOGUE": "Catálogo",
  "SIDEBAR_MENUE.PRODUCTS": "Productos",
  "SIDEBAR_MENUE.GROUPMODIFIERS": "Grupo Modificadores",
  "SIDEBAR_MENUE.MODIFIERS": "Modificadores",
  "SIDEBAR_MENUE.RESERVATION": "Reservas",
  "SIDEBAR_MENUE.RESERVATION_BOOKING": "Calendario de Reservas",
  "SIDEBAR_MENUE.RESERVATION_CONFIRM": "Confirma Reservas",
  "SIDEBAR_MENUE.REWARDS_CREATE": "Crear Recompensas",
  "SIDEBAR_MENUE.REWARDS_LIST": "Lista Recompensas",
  "SIDEBAR_MENUE.TITLE": "Menú",
  "SIDEBAR_MENUE.TRANSACTION": "Lista Transacciones",
  "SIDEBAR_MENUE.USER_LIST": "Usuarios",
  "SIDEBAR_MENUE.VENUE": "Locales",
  "SIDEBAR_MENUE.VENUE_DETAILS": "Crear Local",
  "SIDEBAR_MENUE.VENUE_LIST": "Lista Locales",
  "SIDEBAR_MENUE.VOUCHER": "Cupones",
  "SIDEBAR_MENUE.VOUCHER_CREATE_VOUCHER": "Crear Cupón",
  "SIDEBAR_MENUE.VOUCHER_LIST": "Lista Cupones",
  "SIDEBAR_MENUE.WHATSON": "Noticias",
  "SIDEBAR_MENUE.WHATSON_lIST": "Lista Noticias",
  "SIDEBAR_MENUE.ORDER_FROM_TABLE": "Pedir desde la mesa",
  "SIDEBAR_MENUE.ORDER_FROM_TABLE_LIST": "Lista Mesas",
  "SIDEBAR_MENUE.CONFIGURATION": "Configuracion inicial",
  "SIDEBAR_MENUE.ONLINE_ORDERS": "Configurar Pedidos Online",
  "SIDEBAR_MENUE.STAFF": "Empleados",
  "SIDEBAR_MENUE.STAFF_LIST": "Lista Empleados",
  "SIDEBAR_MENUE.CREATE_STAFF": "Crear Empleado",
  "SIDEBAR_MENUE.MERCHANDISING": "Servicios Adicionales",
  "SIDEBAR_MENUE.PaymentOrdeFromTable": "Payment Orde From Table",
  "SIDEBAR_MENUE.VirtualCartOrder": "Histórico de cuentas",
  "SIDEBAR_MENUE.RATINGS": "Reviews",
  "SIDEBAR_MENUE.LOYALTY": "Programa fidelización",
  "SIDEBAR_MENUE.PROMOTIONS": "Promociones",
  "SIDEBAR_MENUE.PROMOTION_LIST": "Lista de Promociones",
  "SIDEBAR_MENUE.CREATE_PROMOTION": "Crear Promoción",
  "SIDEBAR_MENUE.KIOSK_MODE": "Modo Kiosko",

  "STAFF_DETAIL.BASIC_INFO": "Info básica",
  "STAFF_DETAIL.PERMISSIONS": "Permissions and Roles",
  "STAFF_DETAIL.BACK_LIST": "Lista Empleados",
  "STAFF_DETAIL.CREATE_STAFF": "Crear Empleado",
  "STAFF_DETAIL.ASSIGNED_VENUES": "Locales asignados",

  "ORDER_FROM_TABLE.LIST": "Listado",
  "ORDER_FROM_TABLE.CREATE_TABLE": "Crear mesa",
  "ORDER_FROM_TABLE.DOWNLOAD_QR": "Descargar QR",
  "ORDER_FROM_TABLE.VENUES": "Locales",

  "TRANSACTION_LIST_PAGE.TITLE": "Transacciones",
  "USER_LIST_PAGE.TITLE": "Lista Usuarios",

  "VENUE_DETAIL_PAGE.BUTTON_BACK": "Lista Locales",
  "VENUE_DETAIL_PAGE.MAIN_TITLE": "Nuevo Local",
  "VENUE_DETAIL_PAGE.DLIVERY_AREA_TITLE": "Zona de Reparto",
  "VENUE_DETAIL_PAGE.REMOVE_AREA": "Eliminar área de envío",
  "VENUE_DETAIL_PAGE.CREATE_AREA": "Crear Zona de Reparto",
  "VENUE_DETAIL_PAGE.AREA": "Zona",
  "VENUE_DETAIL_PAGE.CLEAR_AREA": "Borrar Zona de Reparto",
  "VENUE_DETAIL_PAGE.ALERT_TIME.TITLE": "Añadir disponibilidad",
  "VENUE_DETAIL_PAGE.ALERT_TIME.INITIAL_TIME": "Hora Inicio",
  "VENUE_DETAIL_PAGE.ALERT_TIME.END_TIME": "Hora Fin",
  "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_TITLE": "Eliminar Disponibilidad",
  "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_SUBTITLE":
    "¿Estás seguro de que quieres eliminar esta disponibilidad?",
  "VENUE_DETAIL_PAGE.PASSWORD":
    "Contraseña (Rellena este campo unicamente si quieres cambiar tu contraseña)",
  "VENUE_DETAIL_PAGE.VALID_URL": "Por favor introduzca URL valido",
  "VENUE_DETAIL_PAGE.TITLE": "Detalle Local",
  "VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS":
    "Fecha de inicio debe ser inferior a fecha fin",
  "VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER":
    "Fecha fin debe ser superior a la fecha de inicio",
  "VENUE_DETAIL_PAGE.DATE_RANGE":
    "El rango de fecha no puede ser superior a 30 dias",
  "VENUE_DETAIL_PAGE.DELIVERY_ACTIVATION_ALERT":
    "Por favor activa las ordenes online para poder explorar esta sección completa",

  "VISIBILITY.ALWAYS_VISIBLE": "Siempre Disponible",
  "VISIBILITY.DISABLED": "Desactivado",
  "VISIBILITY.SHOW_ONLY": "Mostrar sólo",
  "VISIBILITY.PARTIALY_DISABLED": "Parcialmente Desactivado",
  "VISIBILITY.TITLE": "Visibilidad",
  "VISIBILITY.TIME_RANGE": "Disponibilidad",

  "VENUE_LIST_HEADER_PAGE.CREATE_NEW_VENUE": "Crear Local",
  "VENUE_LIST_HEADER_PAGE.NEW": "Nuevo",
  "VENUE_LIST_HEADER_PAGE.TITLE": "Lista Locales",
  "VOUCHER_DETAIL_PAGE.BUTTON_BACK": "Lista Cupones",
  "VOUCHER_DETAIL_PAGE.NEW_VOUCHER": "Crear Cupón",
  "VOUCHER_DETAIL_PAGE.TITLE": "Detalle Cupón",
  "VOUCHER_DETAIL_PAGE.VOUCHER_TYPE": "Selecciona Nº Sellos",
  "VOUCHER_LIST_HEADER_PAGE.BUTTON_TEXT": "Crear Cupón",
  "VOUCHER_LIST_HEADER_PAGE.NEW": "Nuevo",
  "VOUCHER_LIST_HEADER_PAGE.TITLE": "Lista Cupones",
  "WHATS_ON_LIST_HEADER_PAGE.BUTTON_TEXT": "Crear Noticia",
  "WHATS_ON_LIST_HEADER_PAGE.MAIN_TITLE": "Nueva Noticia",
  "WHATS_ON_LIST_HEADER_PAGE.TITLE": "Noticias",
  "WHAT_ON_DETAIL_PAGE.BACK_BUTTON": "Lista Noticias",
  "WHAT_ON_DETAIL_PAGE.DATE_FROM": "Desde",
  "WHAT_ON_DETAIL_PAGE.DATE_TO": "Hasta",
  "WHAT_ON_DETAIL_PAGE.EVENT_DURATIONL": "Duración Evento",
  "WHAT_ON_DETAIL_PAGE.SELECT_VENUE": "Selecciona Local",
  "WHAT_ON_DETAIL_PAGE.TITLE": "Detalle Noticia",
  "WHAT_ON_DETAIL_PAGE.ONE_DAY": "1 Día",
  "WHAT_ON_DETAIL_PAGE.MULTIPLE_DAYS": "Varios Días",

  "ORDER_TYPE.CASH": "Pago en efectivo",
  "ORDER_TYPE.DELIVERY": "A Domicilio",
  "ORDER_TYPE.COLLECT": "Recogida Local",
  "ORDER_TYPE.FUTURE_ORDER": "Pedido Futuro",
  "ORDER_TYPE.ORDER_FROM_TABLE": "Pedir desde la mesa",

  "ORDER_STATUS.ORDER_NUMBER": "Referencia Pedido",
  "ORDER_STATUS.UPDATED_AT": "Fecha Creación",
  "ORDER_STATUS.PAYMENT_BY_CASH": "Pagar en Efectivo",
  "ORDER_STATUS.PAYMENT_ACCEPTED": "Pago Aceptado",
  "ORDER_STATUS.ORDER_ACCEPTED": "Pedido Aceptado",
  "ORDER_STATUS.PREPARING": "Preparando Pedido",
  "ORDER_STATUS.ON_DELIVERY": "En Envío",
  "ORDER_STATUS.DELIVERED": "Completado",
  "ORDER_STATUS.CANCELED": "Cancelado",
  "ORDER_STATUS.COLLECT": "Listo para Recoger",
  "ORDER_STATUS.COLLECTED": "Pedido Recogido",
  "ORDER_STATUS.ORDER_TIME": "Pedido Hora",
  "ORDER_STATUS.ORDER_TABLE": "Pedido Mesa",

  "USER_DETAIL.BACK_LIST": "Lista de Usuarios",
  "USER_DETAIL.ORDER_LIST": "Pedidos",
  "USER_DETAIL.REWARD_LIST": "Recompensas",
  "USER_DETAIL.LOYALTY_LIST": "Programa de fidelización",
  "USER_DETAIL.BOOKING_LIST": "Reservas",
  "USER_DETAIL.DRAW_LIST": "Sorteos",
  "USER_DETAIL.ADDRESS_LIST": "Direcciones",
  "VENUE.ADD_LONGITUDE_ALERT":
    "Por favor añada latitud y longitud en la información de contacto",
  "MENU.SHARED":
    "Este menú es compartido con otros locales, no puede ser editado",
  "TABLE_HEAD.CONFIRMATION_TITLE": "Confirmacion",
  "TABLE_HEAD.CONFIRMATION_DESCRIPTION":
    "¿Estás seguro quieres eliminar este registro?",
  "PRICE_REDUCE_CELL.DISABLED_PRICE": "Precio deshabilitado",

  "REGISTER_PAGE.TITLE": "Regístrate Gratuitamente",
  "REGISTER_PAGE.SUBTITLE":
    "Empieza a ofrecer pedidos online sin comisiones, a fidelizar tú mismo a tus clientes y a aumentar tus ingresos",
  "REGISTER_PAGE.FIRST_NAME": "Nombre",
  "REGISTER_PAGE.LAST_NAME": "Apellido",
  "REGISTER_PAGE.PHONE_NUMBER": "Teléfono",
  "REGISTER_PAGE.EMAIL": "Email",
  "REGISTER_PAGE.TELEPHONE": "Teléfono",
  "REGISTER_PAGE.PASSWORD": "Contraseña",
  "REGISTER_PAGE.TERMS_CONDITIONS": "Términos y Condiciones",
  "REGISTER_PAGE.PRIVACY_POLICY": "Política de privacidad",
  "REGISTER_PAGE.ACCEPT": "Aceptar",
  "REGISTER_PAGE.BUTTON_TEXT": "Continuar",
  "REGISTER_PAGE.LOGIN_LINK": "¿Ya tienes una cuenta?",
  "REGISTER_PAGE.LOGIN": "Login",
  "REGISTER_PAGE.NEW_IN_BANZZU": "¿Nuevo en Banzzu?",
  "REGISTER_PAGE.SUB_TITLE_1": "Conquista el universo digital de tu local ",
  "REGISTER_PAGE.SUB_TITLE_2": "y descubre todas tus posibilidades",

  "REGISTER_STEPS.REGISTER_RESTAURANT": "Registra tu restaurante",
  "REGISTER_STEPS.LOCATION": "Ubicación",
  "REGISTER_STEPS.SELECT_SERVICES": "Selección de Servicios",
  "REGISTER_STEPS.ADDITIONAL_INFORMATION": "Información Adicional",
  "REGISTER_STEPS.RESTAURANT_NAME": "Nombre del Restaurante",
  "REGISTER_STEPS.TELEPHONE": "Teléfono del Restaurante",
  "REGISTER_STEPS.ADDRESS": "Dirección",
  "REGISTER_STEPS.POSTAL_CODE": "Código Postal",
  "REGISTER_STEPS.MAP_TITLE":
    "¿Quieres cambiar la ubicación de tu restaurante?",
  "REGISTER_STEPS.MOVE_PIN": "Mueve el pin en el mapa",
  "REGISTER_STEPS.SERVICES_TITLE": "¿Qué servicios ofreces?",
  "REGISTER_STEPS.PICK_UP_SERVICE": "Envío a domicilio",
  "REGISTER_STEPS.DELIVERY_SERVICE": "Recogida en local",
  "REGISTER_STEPS.CONGRATULATION": "Felicidades!",
  "REGISTER_STEPS.CONGRATULATION_DESCRIPTION": "Tu cuenta ha sido configurada",
  "REGISTER_STEPS.PROCEED": "Continuar",
  "REGISTER_STEPS.SELECT_COUNTRY": "Selecciona País",
  "REGISTER_STEPS.BACK": "Atrás",
  "REGISTER_STEPS.NEXT": "Siguiente",
  "REGISTER_STEPS.SUBMIT": "Finalizar",

  "PAYMENT_SETTINGS_PAGE.SETTINGS": "Pedidos Online",
  "PAYMENT_SETTINGS_PAGE.TITLE": "El ajuste",
  "PAYMENT_SETTINGS_PAGE.ORDER_SETTINGS": "Pedidos Online",
  "PAYMENT_SETTINGS_PAGE.DISCOUNT_VOUCHERS": "Códigos de Descuento",
  "PAYMENT_SETTINGS_PAGE.PAYMENT_INTEGRATION": "Integración de Pagos",
  "PAYMENT_SETTINGS_PAGE.CREATE_DISCOUNT_VOUCHER": "Crear Cupón Descuento",
  "PAYMENT_SETTINGS_PAGE.EDIT_DISCOUNT_VOUCHER": "Editar Cupón Descuento",
  "PAYMENT_SETTINGS_PAGE.HELPER_TEXT":
    "Activando las siguientes opciones las diferentes pedidos online estarán disponible para sus clientes",
  "PAYMENT_SETTINGS_PAGE.ONLINE_ORDERS_TITLE":
    "Activa o desactiva los pedidos online",
  "PAYMENT_SETTINGS_PAGE.ALERT_HELPER_TEXT":
    "Añada la Secret Key de su cuenta de stripe en la integracion de pagos para poder activar estas opciones",
  "PAYMENT_SETTINGS_PAGE.ALERT_STRIPE":
    "Introduce la Secret Key de tu cuenta stripe y compruebe que los pagos funcionen correctamente",
  "PAYMENT_SETTINGS_PAGE.DELIVERY": "A Domicilio",
  "PAYMENT_SETTINGS_PAGE.COLLECTION": "Recogida en Local",
  "PAYMENT_SETTINGS_PAGE.ORDER_FROM_TABLE": "Pedir desde la mesa",
  "PAYMENT_SETTINGS_PAGE.MENU_AVAILABILITY":
    "Disponible en los siguientes menús:",
  "PAYMENT_SETTINGS_PAGE.STRIP_KEY_ERROR":
    "Los pedidos online no pueden activarse hasta que hayas configurado tu clave stripe correctamente. Pulsa aquí para configurarla",
  "PAYMENT_SETTINGS_PAGE.PAY_BY_CASH": "Paga en efectivo",
  "PAYMENT_SETTINGS_PAGE.PAY_BY_CARD": "Paga con tarjeta",
  "PAYMENT_SETTINGS_PAGE.ONE_ACTIVE_PAYMENT":
    "Una de las opciones debe estar activada",
  "PAYMENT_SETTINGS_PAGE.PAYMENT_METHODS": "Metodos de Pago",
  "PAYMENT_SETTINGS_PAGE.ADD_STRIPE_KEY":
    "Añade una clave de stripe valida para activar esta opcion",

  "PAYMENT_PAGE.VENUE": "Restaurantes",
  "PAYMENT_PAGE.MONTH": "mes",
  "PAYMENT_PAGE.RESTAURANT": "Restaurante",
  "PAYMENT_PAGE.IVA": "Impuestos no incluidos",
  "PAYMENT_PAGE.EXTRA_RESTAURANT": "Restaurante Extra",
  "PAYMENT.CHOOSE_PAYMENT_METHOD": "Elige metodo de pago",
  "PAYMENT.ADD_CARD": "Añadir tarjeta",
  "PAYMENT.HOW_MANY_RESTAURANTS": "¿ Cuantos restaurantes tienes?",
  "PAYMENT.HOW_MANY_RESTAURANTS_DO_YOU_WANT_TO_ADD":
    "¿ Cuantos restaurantes quieres añadir?",

  "HELP_MENU.CONTACT_SUPPORT": "Contactar Soporte",
  "HELP_MENU.PAYMENTS": "Metodos de pago",
  "HELP_MENU.CHANGE_PASSWORD": "Cambiar contraseña",
  "HELP_MENU.LOGOUT": "Salir",

  "CONTACT_SUPPORT.TITLE": "Contáctanos",
  "CONTACT_SUPPORT.SUB_TITLE":
    "Utiliza el siguiente formulario, o envianos un mail a ",
  "CONTACT_SUPPORT.SUBJECT": "Motivo",
  "CONTACT_SUPPORT.MESSAGE": "Mensaje",
  "CONTACT_SUPPORT.SEND": "Enviar",
  "CONTACT_SUPPORT.SUCCESS_MESSAGE": "Tu mensaje ha sido enviado correctamente",

  "PRICING.UPGRADE": "incluye mas restaurantes",
  "PRICING.PLANES": "Planes",
  "PRICING.THE_MOST_ADVANCED":
    "El más avanzado y sofisticado software para tu restaurante conprecios claros y transparentes.",
  "PRICING.YOU_PLANE": "TU PLAN",
  "PRICING.PER_MONTH": "AL MES",
  "PRICING.STANDARD": "Standard",
  "PRICING.BASIC_ANALYTICS": "Analíticas Básicas",
  "PRICING.PAGE": "Página",
  "PRICING.CUSTOM_WEB": "web personalizada",
  "PRICING.FOR_YOUR_BUSINESS": "para tu negocio",
  "PRICING.ACCOMMODATION": "Alojamiento",
  "PRICING.FREE_HOSTING": "web gratuito",
  "PRICING.YOUR_OWN_INTERACTIVE_MENU": "Tu propia carta interactiva",
  "PRICING.ADD_IMAGES_OF_YOUR_PRODUCT":
    "Añade imágenes de tus productos, descripciones, precios,ingredientes y alérgenos de cada uno de tus platos.",
  "PRICING.CUSTOM_QR_CODE": "Código QR",
  "PRICING.WITH_YOUR_MENU": "personalizado con tu menú",
  "PRICING.24th_SUPPORT": "Soporte 24h ",
  "PRICING.BY_CHAT_AND_EMAIL": "por chat y correo electrónico",
  "PRICING.ACCESS_ALL_RESTAURANT": "Acceso a todos los datos del restaurante:",
  "PRICING.ADDRESS_PHONE": "dirección, teléfonos, redes sociales, sitio web…",
  "PRICING.INCLUDE_ONE_VENUE": "Incluye un local",
  "PRICING.YOUR_PLAN": "TU PLAN",
  "PRICING.ADVANCED": "Advanced",
  "PRICING.BEFORE": "Antes",
  "PRICING.EVERYTHING_INCLUDE": "Todo lo incluido en el plan Gratuito",
  "PRICING.PROP_ANALYTICS": "Analíticas Pro",
  "PRICING.USER_CREATION_REGISTRATION": "Creación y registro de usuarios",
  "PRICING.TO_RECIVE": "Recibir",
  "PRICING.HOME_DELIVER_ORDER": "Pedidos a domicilio",
  "PRICING.ORDER_TO_COLLECT": " Orders to collect",
  "PRICING.ORDER_FROM_TABLE": "Pedidos desde la mesa",
  "PRICING.LET_YOUR_IMGINATION": "Deja volar tu imaginación y crea",
  "PRICING.SLEEP_PACE_FULLY": "Duerme tranquilo con la seguridad de nuestro",
  "PRICING.ANTI_FRAUD_SYSTEM": " sistema antifraude",
  "PRICING.UNLIMITED_VENUES": "Locales Ilimitados",
  "PRICING.PER_VENUE": "por local",
  "PRICING.RECIVE_ORDERES_WITH_PAYMENT": "Recibir pedidos con pago en",
  "PRICING.CASH_CARD": "efectivo/tarjeta",
  "PRICING.INTEGRATION": "Integración Pasarela de Pagos",
  "PRICING.PRINTING_ORDER_BY_PRINTER":
    "Impresión de pedidos impresora bluetooth",
  "PRICING.UPGRADE_": "Mejorar",
  "PRICING.SINCE": "Desde",
  "PRICING.EVERY_THING_INCLUDED": "Todo lo incluido en el plan Advanced",
  "PRICING.WE_ARE_CREATE": "Creamos tu totalmente optimizada para",
  "PRICING.IOS_AND_ANDROID": "IOS y Android",
  "PRICING.DONT_MISS":
    "No te pierdas nada gracias al sistema de notificaciones push en tu smartphone",
  "PRICING.PUBLICATION_IN": " Publicación en",
  "PRICING.APP_STORE": "App Store ",
  "PRICING.AND": "y ",
  "PRICING.INCLUDE": " incluida",
  "PRICING.ORGANIZE": " Organiza",
  "PRICING.EXCLUSIVE_CONTESTS": "concursos exclusivos",
  "PRICING.FOR_ALL_APP_USERS": "para todos los usuarios de la app.",
  "PRICING.MAINTAIN_A_CLOSE_RELATIONSHIP":
    " Mantén un trato cercano con tus clientes a través del ",
  "PRICING.LOYALTY_PROGRAM": "programa de fidelización ",
  "PRICING.EXCLUSIVE_OFFERS": "las ofertas exclusivas",
  "PRICING.MANAGE_YOUR_RESTAURANTS_BOOKINGS":
    " Gestiona las reservas de tu restaurante desde tu smartphone",
  "PRICING.AND_MUCH_MORE": "Y mucho más....",
  "PRICING.REQUEST_INFORMATION": "Solicitar Información",

  "PRICING.FREQUENT_QUESTIONS": "Preguntas Frecuentes",
  "PRICING.HOW_CAN_I_UPGRADE_MY_PLAN": "¿Cómo puedo aumentar mi plan?",
  "PRICING.IF_YOU_WANT_TO_UNLOCK":
    "Si deseas desbloquear las funcionalidades de pedidos -sean desde donde sean-, cartas o menús ilimitados y pedidos desde los dispositivos, simplemente ve arriba y escoge cualquiera entre los planes Advanced o Premium, según tus necesidades.",
  "PRICING.WHAT_ARE_AVAILABLE_PAYMENT_METHOD":
    "¿Cuáles son los métodos de pago disponibles?",
  "PRICING.YOU_CAN_PAY":
    " Puedes pagar con tarjeta de crédito desde todos los países, si tienes problemas con el pago ponte en contacto con nosotros y lo solucionaremos lo antes posible",
  "PRICING.WHO_CAN_ACCESS_MY_QR_CODE": "¿Quién puede acceder a mi código QR?",
  "PRICING.YOUR_CODE_CAN":
    "Tu código puede ser escaneado por cualquier persona. Lo que significa que cuanto más distribuyas y publiques tu código, más personas verán tu restaurante y tu carta.",
  "PRICING.HOW_CURRENT_ACCURATE":
    "¿Qué tan actuales y precisos son los datos recogidos?",
  "PRICING.ALL_DATA_IS_COLLECTED":
    "Todos los datos son recogidos en tiempo real, por lo que generamos las estadísticas diariamente. Nos esforzamos cada día para proporcionarte la información más    actualizada y real para tu negocio.",
  "PRICING.CAN_I_EDIT": "¿Puedo editar la carta yo mismo?",
  "PRICING.YES_YOU_WILL_HAVE_ACCESS":
    "¡Sí! Tendrás acceso a un gestor muy sencillo, donde podrás cambiar platos, fotos, precios, descripciones y mucho más...",
  "PRICING.WHAT_ARE_THE_FEES": "¿Cuáles son las comisiones?",
  "PRICING.AT_BANZZU_WE_DO_NOT":
    "En Banzzu no aplicamos ningún tipo de comisión, lo que ves es lo que pagas.",
  "PRICING.IF_I_AM_OTHER":
    "¿Si estoy con otras empresas de reparto, puedo trabajar con Banzzu?",
  "PRICING.YES_OUR_RECOMMENDATION":
    "¡Si! Nuestra recomendación es seguir dándote a conocer por esa vía y pasar todos los nuevos clientes a Banzzu, ya que con nosotros no pagarás comisiones.",
  "PRICING.CAN_THE_CUSTOMER":
    "¿El cliente puede pedir desde su dispositivo? y pagar desde el mismo?",
  "PRICING.YES_THANK_TO_BANZZU":
    "Gracias a Banzzu todos tus clientes podrán hacer sus pedidos desde su propio smartphone, incluido el pago de ellos",
  "PRICING.WHAT_TYPE_OF_RESTAURANT":
    "¿Qué tipo de restaurante es el más indicado para trabajar con Banzzu?",
  "PRICING.BANZZU_IS_SOFTWARE":
    " Banzzu es un software diseñado para todo tipo de restaurantes. Digitaliza tu restaurante y aplica las nuevas mejoras tecnológicas, no importa qué tipo de restaurante tengas, empieza a optimizar tu negocio con Banzzu.",
  "PRICING.CAN_I_CREATE": "¿Puedo crear varios menús?",
  "PRICING.YES_ONE_OF":
    "¡Sí! Uno de los beneficios de nuestros planes Advanced y Premium son las cartas ilimitadas. Deja volar tu imaginación y crea menús de día y noche o de festivos.Todo es posible con Banzzu.",

  "UPGRADE_MODAL.YOUR_PLAN_IS_TOO_SMALL": "Tu plan te queda pequeño",
  "UPGRADE_MODAL.ITS_SEEMS":
    "¡Ups! Parece que estas funciones no están incluidas en tu plan actual.",
  "UPGRADE_MODAL.INCREASE_PLAN":
    "Aumenta tu plan y disfruta de todas las posibilidades que te ofrece Banzzu.",
  "UPGRADE_MODAL.UPGRADE_MY_PLAN": "Aumentar mi Plan",
  "UPGRADE_MODAL.I_STICK": "Me quedo con mi plan",

  "PRICING.14_DAY_FREE_TRAIL": "14 días gratuitos de prueba",
  "PRICING.GET_THIS_PLAN": "Obtén este plan",
  "PRICING.START_FREE_TRAIL": "Empezar Prueba Gratuita",
  "PRICING.YOU_WILL_NOT_BE":
    "No se te cobrara en el periodo de 14 días gratuito, puedes cancelar en cualquier momento tu suscripción",

  "TOOLBAR.VIEW_WEB": "Visualiza tu Web",
  "TOOLBAR.IMPROVE_PLAN": "Mejora tu plan",

  "MERCHANDISE.ITEM.QR_TITLE": "Tu Código QR Personalizado",
  "MERCHANDISE.ITEM.QR_TEXT": "¡Escanéame para pedir!",
  "MERCHANDISE.ITEM.QR_SUB_TITLE":
    "Te imaginas tu QR en todas las mesas? ¡ahora es posible!",
  "MERCHANDISE.ITEM.QR_DESCRIPTION":
    "Adquiere tu código QR en pegatinas personalizables. Te las enviamos listas para que tu sólo te encargues de pegarlas y que tus clientes las escaneen.",
  "MERCHANDISE.ITEM.QR_CUSTOMIZE": "Personaliza tu pegatina QR",
  "MERCHANDISE.ITEM.QR_QUANTITY_TITLE":
    "¿Cuántas pegatinas QR quieres imprimir?",
  "MERCHANDISE.ITEM.ADDRESS_TITLE": "Introduce la dirección de envío",
  "MERCHANDISE.ITEM.SEND_TO_RESTAURANT": "Usar dirección del restaurante",

  "MERCHANDISE.ITEM.CREATE_MENUE": "Creamos Tu Carta",
  "MERCHANDISE.ITEM.CREATE_MENUE_DESCRIPTION":
    "Te ayudamos a tener tu carta online perfecta sin que te preocupes de nada. Tan solo necesitamos que nos mandes un PDF con tu menu y nosotros lo digitalizamos por ti.",
  "MERCHANDISE.ITEM.DELIVERY_FLEET": "Tu Flota de Repartidores",
  "MERCHANDISE.ITEM.DELIVERY_FLEET_DESCRIPTION":
    "Ahora disponer de tu propia flota contratada por horas es posible. Te proveemos de tantos riders y tantas horas como requieras para cubrir con tus necesidades.",
  "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF": "Date a Conocer Online",
  "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF_DESCRIPTION":
    "Promociona tu carta digital en el comparador de deliveries Delitbee y obtén mas visitas",
  "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE":
    "Creamos Tu Web Corporativa",
  "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE_DESCRIPTION":
    "Seguro que aparte del apartado de pedidos que creas con Banzzu, vas a querer una web corporativa dónde mostrar el encanto único de tu restaurante.",

  "MERCHANDISE.UP_SELL_SEND_EMAIL_MESSAGE":
    "Información solicitada correctamente, nos pondremos en contacto para darte mas información.",
  "MERCHANDISE.WANT_IT": "Lo quiero",

  "CATALOGUE.CREATE_PRODUCT": "Crear Producto",
  "CATALOGUE.CREATE_MODIFIER": "Crear Modificador",
  "CATALOGUE.CREATE_GROUPMODIFIER": "Crear Grupo Modificador",
  "CATALOGUE.SKU": "SKU",
  "CATALOGUE.MULTISIZE": "MultiTamaño",
  "CATALOGUE.SIMPLE": "Simple",
  "CATALOGUE.PRODUCT_LIST": "Lista de Productos",
  "CATALOGUE.PRODUCT_DETAIL": "Detalle Producto",
  "CATALOGUE.PRODUCT_NEW": "Nuevo Producto",
  "CATALOGUE.ADD_TAMAÑO": "Añadir Tamaño",
  "CATALOGUE.MODIFIER_LIST": "Lista de Modificadores",
  "CATALOGUE.MODIFIER_DETAIL": "Detalle Modificador",
  "CATALOGUE.MODIFIER_NEW": "Nuevo Modificador",

  "ACCOUNT.ARE_YOU_SURE":
    "Estas seguro que quieres eliminar tu cuenta, perderás todos los menus que hayas creado?",
  "ACCOUNT.DELETE_ACCOUNT": "Eliminar Cuenta",
  "ACCOUNT.YOUR_CURRENT_SUBSCRIPTION": "Tu suscripción actual es Gratuita",
  "ACCOUNT.YOUR_SUBSCRIPTION": "Tu suscripción",
  "ACCOUNT.CURRENT_SUBSCRIPTION_IS_ADVANCED":
    "Tu suscripción actual es Advanced",
  "ACCOUNT.CANCEL_SUBSCRIPTION": "Cancelar Suscripción",
  "ACCOUNT.ARE_YOU_SURE_TO_CANCEL_SUBSCRIPTION":
    "Estas seguro que quieres cancelar tu suscripción actual y volver al plan gratuito?",

  "SETUP_ACCOUNT.ACCOUNT_ALREADY_SETUPED": "Account already setuped",
  "SETUP_ACCOUNT.TITLE":
    "Empezar nunca había sido tan fácil, tan solo selecciona la contraseña de tu cuenta",
  "SETUP_ACCOUNT.PASSWORD": "Contraseña",
  "SETUP_ACCOUNT.CONFIRM_PASSWORD": "Confirmar Contraseña",
  "SETUP_ACCOUNT.SAVE_BUTTON": "Continuar",

  "LANGUAGE.SPANISH": "Español",
  "LANGUAGE.ENGLISH": "Inglés",
  "LANGUAGE.ITALIAN": "Italiano",
  "LANGUAGE.FRENCH": "Francés",
  "LANGUAGE.GERMAN": "Alemán",
  "LANGUAGE.CATALAN": "Catalán",
  "LANGUAGE.EUSKERA": "Euskera",
  "LANGUAGE.MANDARIN": "Mandarín",
  "LANGUAGE.JAPANESE": "Japonés",
  "LANGUAGE.PORTUGUESE": "Portugués",
  "LANGUAGE.RUSSIAN": "Ruso",
  "LANGUAGE.GREEK": "Griego",
  "LANGUAGE.CHINESE": "Chino",

  SELECT_TAX: "Seleccionar Impuesto",
  Virtual_Basket: "Carrito Virtual",
  TOTAL: "Total",
  PAIS_ON_VENUE: "Pagado en local",
  QUANTITY: "Cantidad",
  UNIT_PRICE: "Precio unitario",
  BILLS: "Cuentas",
  PAID_ON_VENUE_MODEL:
    "¿Estás seguro de que quieres marcar la cuenta como pagada?",
  PENDIGN_ORDERS: "Pendiente de Pago",
  "NEW.ORDER_FROM_TABLE": "QR Mesas",
  VIRTUAL_CARD_ORDER: "Virtual Card Orders",
  VIRTUAL_CARD_ORDER_TABLE: "Mesa",
  VIRTUAL_CARD_ORDER_UPDATE_ON: "Fecha Creación",
  STRIPE_CONNECTED_ACCOUNT: "Cuenta Stripe Connect",

  PARTIAL_PAYMENT: "Pago Parcial",
  PAID_ON_VENUE: "Pagado en Local",
  FULL_PAYMENT: "Pago completo",
  TABLE_CARD_ITEM: "Productos",

  SAVE_CHANGES: "Guardar Cambios",
  ADD_EXTRA_OPTIONS: "Añadir opciones extra",
  ADD_PRODUCT_IN: "Añadir Producto en",
  VIRTUAL_CARD_ORDER_NUMBER: "Número de cuenta",

  ROZAN_SOCIAL: "Razon social",
  CIF: "CIF",
  MENU: "Carta",
  AUTO_SERVICES: "Autoservicio",
  PREPARE_MINUTES: "Prepare Minutes",
  RANGE: "Rango",
  EXTRA_TIME: "Tiempo extra",
  BEHAVIOUR: "Comportamiento",
  ADD_RANGE: "Añadir Rango",
  AUTOSERVICE_ALERT_TITLE: "Confirmar Eliminación",
  AUTOSERVICE_ALERT_BODY: "¿Estás seguro de que quieres eliminar?",
  DELETE_TABLE_CONFIRMATION_MESSAGE:
    "¿Estás seguro de que quieres eliminar esta fila?",
  MENU_ID: "Menu Id",
  CONFIRM_DELETE_ALERT_TITLE: "Confirmar Eliminación",
  CONFIRM_DELETE_ALERT_BODY: "¿Estás seguro de que quieres eliminar?",
  PARTIAL_DELIVERY: "Entrega Parcial",
  IN_PROGRESS: "En Progreso",
  WHATSAPP: "Whatsapp",
  SERVICE: "Servicio Extra",
  REFOUND: "Reembolso",
  FULL_REFOUND: "Reembolso total",
  PARTIAL_REFOUND: "Reembolso parcial",
  AMOUNT: "Importe",
  CONFIRMATION: "Confirmación",
  "SPLIT_BILL_MODEL.AMONT_IS_MANDATORY": "The amount is required",
  "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID":
    "The amount exceeds the amount to be paid",
  TICKET_NUMBER: "Ticket #",
  REFUNDED_AMOUNT: "Importe reembolsado",
  TIP: "Propina",
  ENABLE: "Activar",
  DISABLE: "Desactivar",
  DRINKS: "Bebida",
  FOOD: "Comida",
  BILL_DETAIL: "Detalle de la factura",
  TIPS_RANGE: "Seleccionar fechas",
  SHOW_TIPS: "Mostrar Propinas",
  TIPS_LIST: "Lista de Propinas",
  TOTAL_TIPS: "Propinas Totales",
  FETCH_PRODUCTS: "Actualizar Productos",
  DISCONNECTED_PRODUCTS: "Desconectado del TPV",
  POS_PRODUCTS: "Producto TPV",
  ALL_CATEGORIES: "Todas las categorías",
  ERROR_FETCHING_ORDERS: "Error actualizando órdenes",
  "SIDEBAR_MENUE.PAYMENT_HISTORY": "Histórico de pagos",
  STRIPE_ID: "Stripe ID",
  PAID_ON_CASH: "Efectivo",
  PARTIAL_PAYMENT_CASH: "Pago parcial en efectivo",
  PAYMENT_TYPE: "Tipo de pago",
  GENERAL_CASH: "Efectivo",
  GENERAL_CARD: "Tarjeta",
  GENERAL_MIXED_PARTIAL: "Efectivo y tarjeta",
  GENERAL_LANGUAGE: "Idioma",
  GENERAL_LANGUAGES: "Idiomas",
  MENU_CATEGORY_DIVIDE: "Divide en categorías",
  MENU_PRODUCT_FAVORITE: "Añadir como popular",
  BRANDING_PRIMARY_COLOUR: "Color primario",
  BRANDING_SECONDARY_COLOUR: "Color secundario",
  BRANDING_TERTIARY_COLOUR: "Color terciario",
  "GENERAL.RATING": "Review",
  AVERAGE_RATING: "Valoración media",
  LOYALTY_PROGRAM: "Programa de fidelización",
  RATING_RANGE: "Gama de valores",
  PAY_BY_CARD: "Tarjeta",
  COMPLETE_ORDER: "Entrega Completa",
  LOYALTY_CATEGORY_BRONZE: "Bronce",
  LOYALTY_CATEGORY_SILVER: "Plata",
  LOYALTY_CATEGORY_GOLD: "Oro",
  LOYALTY_CATEGORY_PLATINUM: "Platino",
  LOYALTY_PROGRAM_DETAIL: "Detalle del cliente",
  LOYALTY_PROGRAM_CLIENT_AVERAGE: "Ticket promedio",
  LOYALTY_PROGRAM_CLIENT_TIP_NUMBER: "Número de propinas",
  LOYALTY_PROGRAM_CLIENT_TIP_TOTAL: "Total de propinas",
  LOYALTY_PROGRAM_CLIENT_ORDER_NUMBER: "Número de pedidos",
  LOYALTY_PROGRAM_CLIENT_ORDER_TOTAL: "Gasto total",
  LOYALTY_PROGRAM_CLIENT_FREQUENCY: "Frecuencia de pedidos",
  LOYALTY_PROGRAM_FREQUENCY_HIGH: "Alta",
  LOYALTY_PROGRAM_FREQUENCY_MEDIUM: "Media",
  LOYALTY_PROGRAM_FREQUENCY_LOW: "Baja",
  VISIBILITY_DESCRIPTION: "Descripción de visibilidad",
  SET_MENU_VISIBILITY: "Horario Menú",
  DELIVERY_DATE: "Fecha de entrega",
  DELIVERY_TIME: "Tiempo de entrega",
  MENU_VISIBILITY_DESCRIPTION: "Menú Desactivado - Descripción",
  GENERATE_SKU: "Generar SKU",
  SELECT_MODIFIERS: "Seleccionar Modificadores",
  SELECT_GROUP_MODIFIERS: "Seleccionar modificadores de grupo",
  SELECT_PRODUCT: "Seleccionar Producto",
  ACTION: "Acción",
  VISIBILITY: "Visibilidad",
  PRINT_TICKET: "Ticket Impresión",
  GLOBALL_VISIBILITY: "Visibilidad Global",
  TICKET_PRINT: "Ticket Impresión",
  POPUP: "Pop-up",
  POPUP_SWITCH: "Habilitar Pop-up",
  PROMOTION_DETAIL: "Detalle de la promoción",
  NEW_PROMOTION: "Nueva promoción",
  TOTAL_RATINGS: "Nº de Reviews",
  TOTAL_5_RATINGS: "Reviews 5 estrellas",
  FILTER: "Filtrar",
  FILTER_RANGE: "Rango de filtrado",
  APPLY: "Aplicar",
  REVIEW: "Revisar",
  READY_TO_PICK: "RECOGER",
  PREPARATION: "EN PREPARACIÓN",
  SELECT_VENUE_TO_PROCEED: "Selecciona el lugar para continuar",
  SUBMIT: "Entregar",
  "BRANDING.AUTOSERVICE_SCREEN_COLOR":
    "Elija el color de la pantalla de su servicio automático",
  AUTOSERVICE_SCREEN_LOGO: "Carga del logotipo de la pantalla AutoService",

  COMBO_LIST: "Lista de combo",
  COMBOS: "Combos",
  COMBO_DETAIL: "Detalle del combo",
  CREATE_COMBO: "Crear combo",
  NEW_COMBO: "Nuevo combo",
  NEW_CATEGORY: "Nueva categoría",
  ADD_PRODUCT_TO_CATEGORY: "Agregar producto en categoría",
  ADD_PRODUCT: "Agregar producto",
  CATEGORIES: "Categorías",
  COMBO_SUPPLEMENT_PRICE: "Precio del suplemento Combo",
  SELECT_COMBO: "Seleccionar Combo",
  ADD_COMBO: "Agregar Combo",

  TOTAL_AMOUNT: "Importe total",
  SELECT_PAYMENT_TYPE: "Seleccionar tipo de pago",
  "GENERAL.START_DATE": "Fecha de inicio",
  "GENERAL.END_DATE": "Fecha de fin",
  GENERAL_ALL: "Todos",
  ALL_RESULTS: "Todos los resultados",
  AVERAGE_REVIEW: "Valoración media",
  NUMBER_OF_PAYMENTS: "Nº de pagos",
  POS_TITLE: "Título de TPV",
  "SIDEBAR_MENUE.GROUPMODIFIER_LIST": "LISTA DE GRUPO MODIFICADORES",
  Table_Tarif: "tabla de tarifas",
  CATEGORY_SORTED: "Categoría ordenada",
  PRODUCT_SORTED: "Producto ordenado",
  SORTING_FAILED: "Fallo en la ordenación",
  FOR_FREE: "Gratis",
  AUTOSERVICE_LOGO: "Autoservicio Subir logotipo de pantalla",
  STOCK: "Existencias",
  STOCK_QUANTITY: "Cantidad de stock",
  POS_PROMOTIONS: "Promociones TPV",
  GENERAL_PRODUCTS: "Productos",
  GENERAL_COMBOS: "Combos",
  "MENU_CATEGORY_PAGE.COMBO_LIST": "Lista de combos",

  MESSAGE_NO_DATA_FOUND: "No hay datos",
  EXTRA_OPTIONS: "Opciones extras",
  KIOSK_SETTINGS: "Configuración del quiosco",
  MAIN_COLOR: "Color Principal",
  SECONDARY_COLOR: "Color secundario",
  LEFT_COLOR: "Color Izquierdo",
  RIGHT_COLOR: "Color Derecho",
  UPLOAD_ENTRY_LOGO_IMAGE: "Imagen del logotipo de entrada",
  UPLOAD_ENTRY_LOGO_VIDEO: "Vídeo del logotipo de entrada",
  UPLOAD_NAME_SCREEN_LOGO: "Logotipo de la pantalla de nombre",
  UPLOAD_ORDER_TYPE_LOGO: "Subir logo de tipo de pedido",
  UPLOAD_CATEGORY_LOGO: "Subir logo de categoría",
  UPLOAD_PAYMENT_LOGO: "Subir logo de pago",
  UPLOAD_AUTO_SERVICE_LOGO: "Subir logo de servicio automático",
  TABLES: "Mesas",
  MENUS: "Menús",
  SCHEDULE_ORDER_CREATION_DATE: "Fecha creación orden programada",
  SCHEDULE_ORDER_CREATION_TIME: "Hora creación orden programada",
};
