import AutoService from "./AutoService";

export const AutoServiceConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
          folded: false,
        },
        toolbar: {
          display: false,
          style: "fixed",
          position: "below",
        },
      },
    },
  },
  routes: [
    {
      path: "/autoservice",
      component: AutoService,
    },
  ],
};
