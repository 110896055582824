import React from "react";
import { TableCell } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  red: {
    color: "red",
    fontWeight: "bold",
  },
}));
const getAmount = (data = {}) => {
  const totalWithoutDiscount = Number(data.price);
  let totalWithDiscount = totalWithoutDiscount;

  if (
    data &&
    data.discountVoucherAmount &&
    Number(data.discountVoucherAmount)
  ) {
    totalWithDiscount =
      Number(totalWithoutDiscount) - Number(data.discountVoucherAmount);
  }
  if (totalWithDiscount < 0) {
    totalWithDiscount = 0;
  }
  return { totalWithDiscount, totalWithoutDiscount };
};
const DiscountedAmountCell = ({ data, dataTypes }) => {
  const classes = useStyles();
  const { totalWithDiscount = 0, totalWithoutDiscount = 0 } = getAmount(data);
  const fullyDiscounted =
    data.price === 0 && totalWithoutDiscount === totalWithDiscount;
  return (
    <TableCell className="truncate" component="th" scope="row">
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        {fullyDiscounted && <span>0.00</span>}
        {data && !fullyDiscounted && !data.discountVoucherAmount && (
          <span>{totalWithDiscount.toFixed(2)}</span>
        )}
        {data && !fullyDiscounted && data.discountVoucherAmount && (
          <span>{totalWithDiscount.toFixed(2)}</span>
        )}
        {data &&
          data.discountVoucherAmount &&
          data.discountVoucherAmount !== "0" && (
            <strike className={clsx(classes.red)}>
              {totalWithoutDiscount.toFixed(2)}
            </strike>
          )}
      </div>
    </TableCell>
  );
};

export default DiscountedAmountCell;
