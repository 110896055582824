import React from "react";
import { TableCell, Icon, Button, IconButton } from "@material-ui/core";

const SingleIcon = ({ icon, onClick }) => {
  return (
    <TableCell>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          onClick && onClick();
        }}
      >
        <Icon className="material-symbols-outlined text-teal pr-8">{icon}</Icon>
      </IconButton>
    </TableCell>
  );
};

export default SingleIcon;
