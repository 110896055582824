import { paginationActionTypes } from "../redux/actionTypes";
import { Pagination } from "app/models/Pagination";

export const setListLimitPage = (
  dispatch,
  limit: number | string = 10,
  page: number | string = 0,
  listType: Pagination = 1
) => {
  let actionType;

  switch (listType) {
    case 1:
      actionType = paginationActionTypes.SET_LIST_LIMIT_PAGE_ORDERS;
      break;
    case 2:
      actionType = paginationActionTypes.SET_LIST_LIMIT_PAGE_HISTORY;
      break;
    case 3:
      actionType = paginationActionTypes.SET_LIST_LIMIT_PAGE_VIRTUAL_CART;
      break;
    case 4:
      actionType = paginationActionTypes.SET_LIST_LIMIT_PAGE_GENERAL;
      break;
    case 5:
      actionType = paginationActionTypes.SET_LIST_LIMIT_PAGE_LOYALTY;
      break;
    case 6:
      actionType = paginationActionTypes.SET_LIST_LIMIT_PAGE_TIPS;
      break;
    case 7:
      actionType = paginationActionTypes.SET_LIST_LIMIT_PAGE_RATING;
      break;
  }

  dispatch({
    type: actionType,
    payload: {
      limit: limit,
      page: page,
    },
  });
};
