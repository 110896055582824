import LoyaltyProgramList from "./LoyaltyProgramList";
import LoyaltyProgram from "./LoyaltyProgram";

export const LoyaltyProgramConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/loyalty",
      component: LoyaltyProgramList,
    },
    {
      path: "/loyaltyDetail/:loyaltyClientId",
      component: LoyaltyProgram,
    },
  ],
};
