import React, { useState, useEffect } from "react";
import {
  Modal,
  Backdrop,
  makeStyles,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useForm } from "@fuse/hooks";
import { VISIBILITY } from "app/models/Visibility";
import { updateMenuItemVisibility } from "app/store/menu/MenuActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { MultiSlider } from "./MultiSlider";
import { AddCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  checked: {
    color: "#ff8330 !important",
  },
  formRoot: {
    "&$checked": {
      color: "#ff8330",
    },
  },
  rootSlider: {
    color: "#ff8330",
  },
}));

export default function VisibilityModal({ open, onClose, menuItem }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();

  const [value, setValue] = useState([0, 48]);
  const [timeRange, setTimeRange] = useState([
    {
      id: 0,
      start: "00:00",
      end: "24:00",
    },
  ]);
  const [updatedHours] = useState([
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
    "24:00",
  ]);

  const visibility = {
    visibility: VISIBILITY.ALWAYS_AVAILABLE,
    start: "00:00",
    end: "24:00",
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    visibilityRanges: [{ start: "00:00", end: "24:00" }],
  };

  useEffect(() => {
    if (menuItem.visibility) {
      let visible = {
        visibility: menuItem.visibility,
      };
      if (menuItem.visibilityTime) {
        // const newValue = [
        //   updatedHours.indexOf(
        //     menuItem.visibilityTime.start.replace(/:[^:]+$/, "")
        //   ),
        //   updatedHours.indexOf(
        //     menuItem.visibilityTime.end.replace(/:[^:]+$/, "")
        //   ),
        // ];
        // setValue(newValue);
        visible = {
          ...visible,
          ...menuItem.visibilityTime,
        };
        if (
          visible &&
          !visible.visibilityRanges &&
          menuItem.visibilityTime.start &&
          menuItem.visibilityTime.end
        ) {
          visible.visibilityRanges = [
            {
              start: menuItem.visibilityTime.start,
              end: menuItem.visibilityTime.end,
            },
          ];
        }
      }
      setForm((prevForm) => ({
        ...prevForm,
        ...visible,
      }));
    }
  }, []);
  const { form, handleChange, setForm } = useForm(visibility);

  const handleSliderChange = (event, newValue, index) => {
    const _list = form && form.visibilityRanges ? form.visibilityRanges : [];
    _list[index] = {
      start: updatedHours[newValue[0]],
      end: updatedHours[newValue[1]],
    };

    setForm((prevForm) => ({
      ...prevForm,
      start: updatedHours[newValue[0]],
      end: updatedHours[newValue[1]],
      visibilityRanges: _list,
    }));
  };
  const removeRange = (index) => {
    const listRanges = form.visibilityRanges;
    listRanges.splice(index, 1);
    setForm((prevForm) => ({
      ...prevForm,
      visibilityRanges: listRanges,
    }));
  };
  const valuetext = (value) => {
    return `${value}°C`;
  };

  const onSubmit = () => {
    dispatch(
      updateMenuItemVisibility({
        ...form,
        start: form.start + ":00",
        end: form.end + ":00",
        menuItemId: menuItem._id,
      })
    );
    onClose();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={clsx(classes.paper, "flex flex-col")}>
        <h2 id="transition-modal-title" className="pb-12">
          {languageStrings["VISIBILITY.TITLE"]}
        </h2>

        <RadioGroup
          name="visibility"
          value={form.visibility}
          onChange={handleChange}
        >
          <>
            <FormControlLabel
              className="text-xs"
              value={VISIBILITY.ALWAYS_AVAILABLE}
              classes={{ root: classes.formRoot }}
              labelPlacement="end"
              control={<Radio classes={{ checked: classes.checked }} />}
              label={languageStrings["VISIBILITY.ALWAYS_VISIBLE"]}
            />
            <FormControlLabel
              className="text-xs"
              value={VISIBILITY.DISABLED}
              classes={{ root: classes.formRoot }}
              labelPlacement="end"
              control={<Radio classes={{ checked: classes.checked }} />}
              label={languageStrings["VISIBILITY.DISABLED"]}
            />
            <FormControlLabel
              className="text-xs"
              value={VISIBILITY.SHOW_ONLY}
              classes={{ root: classes.formRoot }}
              labelPlacement="end"
              control={<Radio classes={{ checked: classes.checked }} />}
              label={languageStrings["VISIBILITY.SHOW_ONLY"]}
            />
          </>
        </RadioGroup>
        {form.visibility === VISIBILITY.SHOW_ONLY && (
          <div>
            <div className="flex flex-row items-center pb-12 pt-12">
              <h2>{languageStrings["VISIBILITY.TIME_RANGE"]}</h2>
              <AddCircle
                style={{
                  color: "#ff8330",
                }}
                className="ml-2"
                onClick={() => {
                  const _list =
                    form && form.visibilityRanges ? form.visibilityRanges : [];
                  _list.push({
                    start: "00:00",
                    end: "24:00",
                  });
                  setForm((prevForm) => ({
                    ...prevForm,
                    visibilityRanges: _list,
                  }));
                }}
              />
            </div>

            {form &&
              form.visibilityRanges &&
              form.visibilityRanges.map((item, index) => {
                return (
                  <MultiSlider
                    onChange={(event, newValue) => {
                      handleSliderChange(event, newValue, index);
                    }}
                    form={item}
                    onDelete={() => {
                      removeRange(index);
                    }}
                    updatedHours={updatedHours}
                    data={form.visibilityRanges}
                  />
                );
              })}

            <div className="flex">
              <FormControlLabel
                labelPlacement="end"
                className="w-1/2"
                name="monday"
                classes={{ root: classes.formRoot }}
                control={
                  <Checkbox
                    classes={{ checked: classes.checked }}
                    disableRipple
                    checked={form.monday}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={languageStrings["GENERAL.MONDAY"]}
              />
              <FormControlLabel
                labelPlacement="end"
                className="w-1/2"
                name="tuesday"
                classes={{ root: classes.formRoot }}
                control={
                  <Checkbox
                    classes={{ checked: classes.checked }}
                    disableRipple
                    checked={form.tuesday}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={languageStrings["GENERAL.TUESDAY"]}
              />
            </div>
            <div className="flex">
              <FormControlLabel
                labelPlacement="end"
                className="w-1/2"
                name="wednesday"
                checked={form.wednesday}
                classes={{ root: classes.formRoot }}
                control={
                  <Checkbox
                    classes={{ checked: classes.checked }}
                    disableRipple
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={languageStrings["GENERAL.WEDNESDAY"]}
              />
              <FormControlLabel
                labelPlacement="end"
                className="w-1/2"
                name="thursday"
                checked={form.thursday}
                classes={{ root: classes.formRoot }}
                control={
                  <Checkbox
                    classes={{ checked: classes.checked }}
                    disableRipple
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={languageStrings["GENERAL.THURSDAY"]}
              />
            </div>
            <div className="flex">
              <FormControlLabel
                labelPlacement="end"
                className="w-1/2"
                name="friday"
                checked={form.friday}
                classes={{ root: classes.formRoot }}
                control={
                  <Checkbox
                    classes={{ checked: classes.checked }}
                    disableRipple
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={languageStrings["GENERAL.FRIDAY"]}
              />
              <FormControlLabel
                labelPlacement="end"
                className="w-1/2"
                name="saturday"
                classes={{ root: classes.formRoot }}
                control={
                  <Checkbox
                    classes={{ checked: classes.checked }}
                    disableRipple
                    checked={form.saturday}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={languageStrings["GENERAL.SATURDAY"]}
              />
            </div>
            <FormControlLabel
              labelPlacement="end"
              className="w-1/2"
              name="sunday"
              classes={{ root: classes.formRoot }}
              control={
                <Checkbox
                  checked={form.sunday}
                  classes={{ checked: classes.checked }}
                  disableRipple
                  onChange={handleChange}
                  color="primary"
                />
              }
              label={languageStrings["GENERAL.SUNDAY"]}
            />
          </div>
        )}
        <Button
          variant="contained"
          className="mt-12"
          style={{ backgroundColor: "#ff8330", color: "white" }}
          onClick={onSubmit}
        >
          {languageStrings["GENERAL.SAVE"]}
        </Button>
      </div>
    </Modal>
  );
}
