import ComboList from "./ComboList";
import ComboDetail from "./ComboDetail";

export const ComboConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/catalogue/combos",
      component: ComboList,
    },
    {
      path: "/catalogue/comboDetail/:comboId",
      component: ComboDetail,
    },
  ],
};
