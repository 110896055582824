import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getModifierList } from "app/store/catalogue/CatalogueActions";
import { getLabelWith } from "app/helpers/utilsHelper";
import { SearchableDropDown } from "app/main/common/SearchableDropDown";
import useClientCurrency from "app/hooks/useClientCurrency";
export const ModifierDropdown = ({ handleChangeModifier = () => {} }) => {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();
  const { modifierList } = useSelector(({ banzzu }) => banzzu.catalogue);
  const [value, setValue] = useState("");
  const searchBarRef = useRef(null);
  const { clientCurrency } = useClientCurrency();
  useEffect(() => {
    dispatch(getModifierList());
  }, []);

  const _modifierList = modifierList && modifierList.length ? modifierList : [];
  const handleChange = (event) => {
    const val = event.target.value;
    setValue(val);
    const findGroup = _modifierList.find((item) => item._id === val);
    if (findGroup && findGroup._id) {
      handleChangeModifier(findGroup, val);
      setValue("");
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    }
  };

  const { labelWith } = getLabelWith(languageStrings["SELECT_MODIFIERS"]);
  return (
    <SearchableDropDown
      keyPropFn={(option) => option._id}
      valuePropFn={(option) =>
        `${option.title} - ${option.sku}${
          option.price > 0 ? ` - ${option.price}${clientCurrency.symbol}` : ""
        }`
      }
      list={_modifierList}
      selectedValue={value}
      label={languageStrings["SELECT_MODIFIERS"]}
      handleChange={handleChange}
      labelWidth={labelWith}
      searchBarRef={searchBarRef}
    />
  );
};
