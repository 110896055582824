import React, { useState } from "react";
import { Icon, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable, { Column } from "material-table";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl } from "app/helpers/utilsHelper";
import {
  setDefaultLanguage,
  getSortedPositions,
} from "app/helpers/utilsHelper";
import {
  editMenuV2ExtraCatItems,
  updateModifierVisibilityV2,
  sortCatalogModifier,
} from "app/store/menuV2/MenuV2Actions";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import VisibilityBanner from "app/main/common/VisibilityBanner";
import { FuseUtils } from "@fuse";

const ExtraCategoriesTable = ({
  items,
  connectedCategoryItem,
  category,
  connectedCategoryTitle,
  selectedLanguage,
  onAddClick,
  isCombo,
  onCatalogModifierChange,
}) => {
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  let { locale } = useSelector(({ banzzu }) => banzzu.auth.user);
  const isDefaultLanguage = setDefaultLanguage(selectedLanguage, locale);
  const { products } = useSelector(({ banzzu }) => banzzu.pos);
  const isPOSIntegration = products && products.isPOSIntegration ? true : false;

  const [openVisibility, setOpenVisibility] = useState({
    itemId: "",
    show: false,
    data: null,
  });

  const handleLanguageTitle = (itemData) => {
    let translatedTitle;
    if (isDefaultLanguage) {
      translatedTitle = itemData.title;
    } else {
      if (itemData && itemData.multiTitle) {
        const languageObj = itemData.multiTitle.find(
          (item) => Number(item.language) === selectedLanguage
        );
        translatedTitle = languageObj ? languageObj.title : itemData.title;
      } else {
        translatedTitle = itemData.title;
      }
    }
    return translatedTitle;
  };
  const getUpdatedMultiTitle = (itemData) => {
    const multiTitle =
      itemData && itemData.multiTitle && itemData.multiTitle.length > 0
        ? [...itemData.multiTitle]
        : [];

    const index = multiTitle.findIndex(
      (item) => Number(item.language) === selectedLanguage
    );

    if (index !== -1) {
      multiTitle[index].title = itemData.title;
    } else {
      multiTitle.push({ language: selectedLanguage, title: itemData.title });
    }
    return multiTitle;
  };

  const onVisibilityClose = () => {
    setOpenVisibility({
      itemId: "",
      show: !openVisibility.show,
      data: null,
    });
  };
  const columns: Column[] = [
    ...(isDefaultLanguage
      ? [
          {
            field: "image",
            title: "",
            editComponent: (props) => {
              let { image: itemImage, _id } = props.rowData;

              return (
                <div className="flex justify-center sm:justify-start flex-wrap">
                  {isPOSIntegration ? (
                    <>
                      <input
                        accept="image/jpg, image/jpeg, image/png, image/gif"
                        className="hidden"
                        id={`button-file-${_id}`}
                        type="file"
                        onChange={(e) => {
                          FuseUtils.lighterImage(e.target.files[0]).then(
                            (file) => {
                              const reader = new FileReader();
                              reader.readAsBinaryString(file);

                              reader.onload = () => {
                                props.onChange({
                                  image: `data:${file.type};base64,${btoa(
                                    reader.result.toString()
                                  )}`,
                                  imageFile: file,
                                  delete: false,
                                });
                              };

                              reader.onerror = function () {
                                console.log("error on load image");
                              };
                            }
                          );
                        }}
                      />

                      <label
                        htmlFor={`button-file-${_id}`}
                        className="flex items-center justify-center relative w-48 h-48 rounded-4 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                      >
                        <Icon fontSize="large" color="action">
                          cloud_upload
                        </Icon>
                      </label>
                    </>
                  ) : null}
                  <div className="flex items-center w-48 h-48 justify-center relative rounded-4 overflow-hidden shadow-1 ml-6">
                    <img
                      className="max-w-none w-auto h-full"
                      alt="item"
                      src={
                        itemImage
                          ? itemImage.image
                            ? itemImage.image
                            : typeof itemImage == "string"
                            ? itemImage
                            : "assets/images/default-image-placeholder.png"
                          : "assets/images/default-image-placeholder.png"
                      }
                    />
                    {itemImage && isPOSIntegration ? (
                      <div
                        className="mb-2 cursor-pointer absolute bottom-0"
                        style={{ height: 22, width: 24 }}
                        onClick={() => {
                          props.onChange({ image: null, delete: true });
                        }}
                      >
                        <Icon
                          onClick={() => {}}
                          className="rounded-4 shadow-5 mb-2 text-2xl "
                          style={{
                            color: "#f4516c",
                            backgroundColor: "#fff",
                            objectFit: "cover",
                            maxHeight: "100%",
                            maxWidth: "100%",
                          }}
                        >
                          clear
                        </Icon>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            },
            render: (rowData) => (
              <div className="flex items-center">
                {items.length > 1 && rowData.index !== 0 && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onPositionChange("increase", rowData);
                    }}
                  >
                    <Icon color="primary" className="text-14">
                      arrow_upward
                    </Icon>
                  </IconButton>
                )}
                {rowData.index !== items.length - 1 && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onPositionChange("decrease", rowData);
                    }}
                  >
                    <Icon color="primary" className="text-14">
                      arrow_downward
                    </Icon>
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    if (rowData && rowData._id) {
                      setOpenVisibility({
                        show: true,
                        itemId: rowData._id,
                        data: rowData,
                      });
                    }
                  }}
                >
                  <Icon className="material-symbols-outlined text-teal pr-8">
                    visibility
                  </Icon>
                </IconButton>
                {rowData.image && (
                  <div className={"w-40 h-40"}>
                    <img
                      src={formatImageUrl(rowData.image)}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                        borderRadius: 4,
                      }}
                    />
                  </div>
                )}
                <div className="mx-4">
                  <VisibilityBanner
                    visibility={rowData && rowData.visibility}
                  />
                </div>
              </div>
            ),
          },
        ]
      : [
          {
            render: (rowData) => (
              <>
                {rowData.image && (
                  <div className={"w-40 h-40"}>
                    <img
                      src={formatImageUrl(rowData.image)}
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                        borderRadius: 4,
                      }}
                    />
                  </div>
                )}
              </>
            ),
          },
        ]),
    {
      title: languageStrings["GENERAL.TITLE"],
      field: "title",
    },
    ...(isPOSIntegration
      ? [
          {
            title: languageStrings["POS_TITLE"],
            field: "posTitle",
            editable: "never" as const,
          },
        ]
      : []),

    {
      title: languageStrings["EXTRA_CATEGORIES.EXTRA_PRICE"],
      field: "price",
      editable: "never" as const,
    },
  ];
  const [state, seState] = useState({ columns });

  let ref;

  const onPositionChange = (action, rowData) => {
    if (rowData._id && onCatalogModifierChange) {
      const updatedItems = [];
      items.forEach((item, i) => {
        updatedItems[i] = item;
      });
      const { updatedPositions, array } = getSortedPositions(
        action,
        rowData._id,
        updatedItems
      );

      onCatalogModifierChange(array);
      dispatch(
        sortCatalogModifier({
          updatedPositions,
          category,
        })
      );
    }
  };

  return (
    <>
      <div
        ref={(r?: any) => {
          ref = r;
        }}
        className={!isDefaultLanguage ? "w-max" : ""}
      >
        {items && (
          <MaterialTable
            title={
              !connectedCategoryTitle
                ? languageStrings["GENERAL.ITEMS"]
                : connectedCategoryTitle
            }
            columns={state.columns}
            localization={{
              pagination: {
                labelDisplayedRows:
                  languageStrings["MATERIAL_TABLE.DISPLAYED_ROWS"],
                labelRowsSelect: languageStrings["MATERIAL_TABLE.ROWS"],
              },
              toolbar: {
                nRowsSelected: languageStrings["MATERIAL_TABLE.ROW_SELECTED"],
              },
              header: {
                actions: languageStrings["GENERAL.ACTIONS"],
              },
              body: {
                emptyDataSourceMessage:
                  languageStrings["MATERIAL_TABLE.NO_RECORDS"],
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
            }}
            data={items.map((item, index) => {
              return {
                ...item,
                title: handleLanguageTitle(item),
                price: item.price
                  ? parseFloat(item.price).toFixed(2)
                  : item.price,
                image: item.image || "",
                index,
              };
            })}
            options={{
              search: false,
              pageSize: 5,
              doubleHorizontalScroll: true,
            }}
            icons={{
              Edit: () => (
                <Icon color="secondary" className="text-22">
                  edit
                </Icon>
              ),
              Check: () => <Icon className="text-22 text-green">check</Icon>,
              Clear: () => (
                <div>
                  <Icon color="error" className="text-22">
                    close
                  </Icon>
                </div>
              ),
            }}
            editable={{
              isDeletable: () => false,
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  const multiTitle = getUpdatedMultiTitle(newData);
                  let request = {
                    ...newData,
                    multiTitle,
                    selectedLanguage,
                    extra_category: category,
                    isDefaultLanguage,
                    image: null,
                    imageFile: newData.image.imageFile,
                    deleteImage: newData.image.delete,
                  };
                  dispatch(editMenuV2ExtraCatItems(request, isPOSIntegration));
                  resolve();
                }),
            }}
          />
        )}
      </div>
      <VisibilityModalV2
        open={openVisibility.show}
        showGlobal={false}
        onClose={onVisibilityClose}
        data={openVisibility.data}
        onVisibilityChange={(item = {}) => {
          const payload = {
            ...item,
            modifierId: openVisibility.itemId,
            categoryId: category,
          };
          dispatch(updateModifierVisibilityV2(payload));
        }}
      />
    </>
  );
};

export default ExtraCategoriesTable;
