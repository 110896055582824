import React, { useEffect } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FuseAnimate, FuseAnimateGroup } from "@fuse";
import history from "@history";
import {
  addLeadVisitPricingKlaviyo,
  sendPremiumLeadEmail,
} from "./../../store/user/UserActions";
import FaqItem from "./components/FaqItem";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import useClientCurrency from "app/hooks/useClientCurrency";

const useStyles = makeStyles((theme) => ({
  header: {
    height: 500,
    background:
      "linear-gradient(to right, " +
      theme.palette.primary.dark +
      " 0%, " +
      theme.palette.primary.main +
      " 100%)",
    color: theme.palette.primary.contrastText,
  },
  badge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
}));

function Pricing() {
  const dispatch = useDispatch();
  const classes = useStyles();
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { clientCurrency } = useClientCurrency();
  const { languageStrings } = useLanguageHelper();

  useEffect(() => {
    dispatch(addLeadVisitPricingKlaviyo());
  }, []);

  return (
    <div>
      <div className={clsx(classes.header, "flex")}>
        <div className="p-24 w-full max-w-2xl mx-auto">
          <div className="text-center my-64 mx-24">
            <FuseAnimate
              animation="transition.slideUpIn"
              duration={400}
              delay={100}
            >
              <Typography variant="h2" color="inherit" className="font-light">
                {languageStrings["PRICING.PLANES"]}
              </Typography>
            </FuseAnimate>

            <FuseAnimate duration={400} delay={600}>
              <Typography
                variant="subtitle1"
                color="inherit"
                className="opacity-75 mt-16 mx-auto max-w-512"
              >
                {languageStrings["PRICING.THE_MOST_ADVANCED"]}
              </Typography>
            </FuseAnimate>
          </div>
        </div>
      </div>

      <div className="-mt-192">
        <div className="w-full max-w-2xl mx-auto">
          <FuseAnimateGroup
            enter={{
              animation: "transition.slideUpBigIn",
            }}
            className="flex justify-center flex-wrap"
          >
            <div className="w-full max-w-320 sm:w-1/3 p-12">
              <Card className="relative mt-64">
                {user.accessLevel == "1" && (
                  <div className="absolute top-0 inset-x-0 flex justify-center">
                    <div className={clsx(classes.badge, "py-4 px-8")}>
                      <Typography variant="caption" color="inherit">
                        {languageStrings["PRICING.YOU_PLANE"]}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="pt-48 pb-24 text-center">
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    className="text-20 font-bold"
                  >
                    {languageStrings["PRICING.STANDARD"]}
                  </Typography>
                </div>

                <CardContent className="text-center p-0">
                  <div className="flex flex-col">
                    <div className="flex justify-center mb-8">
                      <Typography className="text-56 ml-4 font-light text-orange leading-none">
                        0
                      </Typography>
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        className="font-medium text-orange"
                      >
                        {clientCurrency.symbol}
                      </Typography>
                    </div>
                    <Typography
                      color="textSecondary"
                      className="font-medium text-16 text-orange"
                    >
                      {languageStrings["PRICING.PER_MONTH"]}
                    </Typography>
                  </div>
                  <div className="flex flex-col p-32">
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.BASIC_ANALYTICS"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.PAGE"]}
                      <b>{languageStrings["PRICING.CUSTOM_WEB"]}</b>
                      {languageStrings["PRICING.FOR_YOUR_BUSINESS"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.ACCOMMODATION"]}</b>
                      {languageStrings["PRICING.FREE_HOSTING"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16" v>
                      <b>
                        {languageStrings["PRICING.YOUR_OWN_INTERACTIVE_MENU"]}:
                      </b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.ADD_IMAGES_OF_YOUR_PRODUCT"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16" v>
                      <b>{languageStrings["PRICING.CUSTOM_QR_CODE"]} </b>
                      {languageStrings["PRICING.WITH_YOUR_MENU"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.24th_SUPPORT"]}</b>
                      {languageStrings["PRICING.BY_CHAT_AND_EMAIL"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.ACCESS_ALL_RESTAURANT"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.ADDRESS_PHONE"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.INCLUDE_ONE_VENUE"]}</b>
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="w-full max-w-320 sm:w-1/3 p-12">
              <Card className="relative" raised>
                {user.accessLevel == "2" && (
                  <div className="absolute top-0 inset-x-0 flex justify-center">
                    <div className={clsx(classes.badge, "py-4 px-8")}>
                      <Typography variant="caption" color="inherit">
                        {languageStrings["PRICING.YOUR_PLAN"]}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="pt-48 pb-24 text-center">
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    className="text-20 font-bold"
                  >
                    {languageStrings["PRICING.ADVANCED"]}
                  </Typography>
                </div>

                <CardContent className="text-center p-0">
                  <div className="flex flex-col">
                    <Typography className="text-32 text-grey ml-4 font-light leading-none line-through mb-8">
                      {languageStrings["PRICING.BEFORE"]}: 74.95
                    </Typography>
                    <div className="flex justify-center mb-8">
                      <Typography className="text-56 text-orange ml-4 font-light leading-none">
                        34.95
                      </Typography>
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        className="font-medium text-orange"
                      >
                        {clientCurrency.symbol}
                      </Typography>
                    </div>
                    <Typography
                      color="textSecondary"
                      className="font-medium text-16 text-orange"
                    >
                      {languageStrings["PRICING.PER_MONTH"]}
                    </Typography>
                    {user.accessLevel == "1" &&
                      user.settings &&
                      !user.settings.cancelSubscription && (
                        <Typography
                          variant="subtitle1"
                          color="inherit"
                          className="text-20 font-bold"
                        >
                          {languageStrings["PRICING.14_DAY_FREE_TRAIL"]}
                        </Typography>
                      )}
                  </div>

                  <div className="flex flex-col p-32">
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.EVERYTHING_INCLUDE"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.PROP_ANALYTICS"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.USER_CREATION_REGISTRATION"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.TO_RECIVE"]}
                      <b>{languageStrings["PRICING.HOME_DELIVER_ORDER"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.TO_RECIVE"]}
                      <b>{languageStrings["PRICING.ORDER_TO_COLLECT"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.TO_RECIVE"]}
                      <b>{languageStrings["PRICING.ORDER_FROM_TABLE"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.LET_YOUR_IMGINATION"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.SLEEP_PACE_FULLY"]}
                      <b>{languageStrings["PRICING.ANTI_FRAUD_SYSTEM"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.UNLIMITED_VENUES"]}</b>
                      (+34.95€ {languageStrings["PRICING.PER_VENUE"]})
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.RECIVE_ORDERES_WITH_PAYMENT"]}
                      <b>{languageStrings["PRICING.CASH_CARD"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.INTEGRATION"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.PRINTING_ORDER_BY_PRINTER"]}
                    </Typography>
                  </div>
                </CardContent>

                {user.accessLevel == "1" && (
                  <div className="flex justify-center pb-32">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="w-128"
                      onClick={() => history.push("payment")}
                    >
                      {user.settings && user.settings.cancelSubscription
                        ? languageStrings["PRICING.GET_THIS_PLAN"]
                        : languageStrings["PRICING.START_FREE_TRAIL"]}
                    </Button>
                  </div>
                )}

                {user.settings &&
                  user.accessLevel != "1" &&
                  user.settings.venuesPaid && (
                    <div className="flex justify-center pb-32">
                      <Button
                        variant="contained"
                        color="secondary"
                        className="w-128"
                        onClick={() => history.push("upgrade")}
                      >
                        {languageStrings["PRICING.UPGRADE"]}
                      </Button>
                    </div>
                  )}
              </Card>
            </div>

            <div className="w-full max-w-320 sm:w-1/3 p-12">
              <Card className="relative mt-64">
                {user.accessLevel == "3" && (
                  <div className="absolute top-0 inset-x-0 flex justify-center">
                    <div className={clsx(classes.badge, "py-4 px-8")}>
                      <Typography variant="caption" color="inherit">
                        {languageStrings["PRICING.YOU_PLANE"]}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="pt-48 pb-24 text-center">
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    className="text-20 font-bold"
                  >
                    Premium
                  </Typography>
                </div>

                <CardContent className="text-center p-0">
                  <div className="flex flex-col">
                    <Typography className="text-32 text-grey ml-4 font-light leading-none line-through mb-8">
                      {languageStrings["PRICING.BEFORE"]}: 94.95
                    </Typography>
                    <div className="flex justify-center mb-8">
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        className="font-medium text-orange"
                      >
                        {languageStrings["PRICING.SINCE"]}
                      </Typography>
                      <Typography className="text-56 ml-4 font-light text-orange leading-none">
                        44.95
                      </Typography>
                      <Typography
                        variant="h5"
                        color="textSecondary"
                        className="font-medium text-orange"
                      >
                        {clientCurrency.symbol}
                      </Typography>
                    </div>
                    <Typography
                      color="textSecondary"
                      className="font-medium text-16 text-orange"
                    >
                      {languageStrings["PRICING.PER_MONTH"]}
                    </Typography>
                  </div>

                  <div className="flex flex-col p-32">
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.EVERY_THING_INCLUDED"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.WE_ARE_CREATE"]}
                      <b> {languageStrings["PRICING.IOS_AND_ANDROID"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.DONT_MISS"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.PUBLICATION_IN"]}
                      <b>{languageStrings["PRICING.APP_STORE"]}</b>
                      {languageStrings["PRICING.AND"]} <b>Google Play</b>
                      {languageStrings["PRICING.INCLUDE"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.ORGANIZE"]}
                      <b> {languageStrings["PRICING.EXCLUSIVE_CONTESTS"]}</b>
                      {languageStrings["PRICING.FOR_ALL_APP_USERS"]}
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {languageStrings["PRICING.MAINTAIN_A_CLOSE_RELATIONSHIP"]}
                      <b>{languageStrings["PRICING.LOYALTY_PROGRAM"]}</b>
                      {languageStrings["PRICING.AND"]}
                      <b>{languageStrings["PRICING.EXCLUSIVE_OFFERS"]}</b>
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      {
                        languageStrings[
                          "PRICING.MANAGE_YOUR_RESTAURANTS_BOOKINGS"
                        ]
                      }
                    </Typography>
                    <Typography color="textSecondary" className="mb-16">
                      <b>{languageStrings["PRICING.AND_MUCH_MORE"]}</b>
                    </Typography>
                  </div>
                </CardContent>

                <div className="flex justify-center pb-32">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="w-128"
                    onClick={() => {
                      dispatch(sendPremiumLeadEmail(user._id));
                    }}
                  >
                    {languageStrings["PRICING.REQUEST_INFORMATION"]}
                  </Button>
                </div>
              </Card>
            </div>
          </FuseAnimateGroup>

          <div className="flex flex-col items-center py-96 text-center sm:text-left max-w-xl mx-auto">
            <Typography variant="h4" className="pb-32 font-light">
              {languageStrings["PRICING.FREQUENT_QUESTIONS"]}
            </Typography>

            <div className="flex flex-wrap w-full">
              <FaqItem
                title={languageStrings["PRICING.HOW_CAN_I_UPGRADE_MY_PLAN"]}
                description={languageStrings["PRICING.IF_YOU_WANT_TO_UNLOCK"]}
              />
              <FaqItem
                title={
                  languageStrings["PRICING.WHAT_ARE_AVAILABLE_PAYMENT_METHOD"]
                }
                description={languageStrings["PRICING.YOU_CAN_PAY"]}
              />
              <FaqItem
                title={languageStrings["PRICING.WHO_CAN_ACCESS_MY_QR_CODE"]}
                description={languageStrings["PRICING.YOUR_CODE_CAN"]}
              />
              <FaqItem
                title={languageStrings["PRICING.HOW_CURRENT_ACCURATE"]}
                description={languageStrings["PRICING.ALL_DATA_IS_COLLECTED"]}
              />
              <FaqItem
                title={languageStrings["PRICING.CAN_I_EDIT"]}
                description={
                  languageStrings["PRICING.YES_YOU_WILL_HAVE_ACCESS"]
                }
              />
              <FaqItem
                title={languageStrings["PRICING.WHAT_ARE_THE_FEES"]}
                description={languageStrings["PRICING.AT_BANZZU_WE_DO_NOT"]}
              />
              <FaqItem
                title={languageStrings["PRICING.IF_I_AM_OTHER"]}
                description={languageStrings["PRICING.YES_OUR_RECOMMENDATION"]}
              />
              <FaqItem
                title={languageStrings["PRICING.CAN_THE_CUSTOMER"]}
                description={languageStrings["PRICING.YES_THANK_TO_BANZZU"]}
              />
              <FaqItem
                title={languageStrings["PRICING.WHAT_TYPE_OF_RESTAURANT"]}
                description={languageStrings["PRICING.BANZZU_IS_SOFTWARE"]}
              />
              <FaqItem
                title={languageStrings["PRICING.CAN_I_CREATE"]}
                description={languageStrings["PRICING.YES_ONE_OF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
