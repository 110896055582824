import ProductDetail from "./ProductDetail";
import ProductList from "./ProductList";

export const ProductConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/catalogue/products",
      component: ProductList,
    },
    {
      path: "/catalogue/productsDetail/:productId",
      component: ProductDetail,
    },
  ],
};
