import React from "react";
import { TableCell } from "@material-ui/core";
import PaymentType from "../../payment/PaymentType";

const PaymentTypeCell = (props) => {
  const { paymentIntent } = props;
  return (
    <TableCell className="truncate" component="th" scope="row">
      <PaymentType paymentType = {paymentIntent ? 1 : 2}/>
    </TableCell>
  );
};

export default PaymentTypeCell;
