import PromotionDetail from "./PromotionDetail";
import PromotionList from "./PromotionList";

export const PromotionListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/catalogue/promotions",
      component: PromotionList,
    },
    {
      path: "/catalogue/promotionDetail/:promotionId",
      component: PromotionDetail,
    },
  ],
};
