import React from "react";
import { AppBar, Hidden, Toolbar, Button, Icon } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import NavbarMobileToggleButton from "app/fuse-layouts/shared-components/NavbarMobileToggleButton";
import UserMenu from "app/fuse-layouts/shared-components/UserMenu";
import { useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { previewWeb } from "app/store/user/UserActions";
import history from "@history";
import { clientsSettings } from "config";

const useStyles = makeStyles((theme) => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
}));

function ToolbarLayout1(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);

  const dispatch = useDispatch();

  const { languageStrings } = useLanguageHelper();

  const classes = useStyles(props);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar id="fuse-toolbar" className="flex relative z-10" color="default">
        <Toolbar className="p-0 justify-between md:justify-end">
          {config.navbar.display && config.navbar.position === "left" && (
            <Hidden lgUp>
              <div className="flex">
                <NavbarMobileToggleButton className="w-64 h-64 p-0" />
                <div className={classes.separator} />
              </div>
            </Hidden>
          )}

          <div className="flex mr-32 items-center">
            <div className="flex flex-1 justify-center">
              {user &&
                user.permission &&
                user.permission.hasWebsite &&
                user.settings &&
                user.settings.permalink &&
                clientsSettings.showWebViewButton && (
                  <Button
                    className="bg-blue-light text-white m-12 whitespace-no-wrap"
                    onClick={() => {
                      dispatch(previewWeb());
                      window.open(
                        `${process.env.REACT_APP_WEB_LINK}/${user.settings.permalink}`
                      );
                    }}
                  >
                    <Hidden mdUp>
                      <Icon>public</Icon>
                    </Hidden>
                    <Hidden smDown>
                      {languageStrings["TOOLBAR.VIEW_WEB"]}
                    </Hidden>
                  </Button>
                )}
            </div>
            {user &&
              user.permission &&
              user.permission.hasWebsite &&
              user.permission.isMaster &&
              user.settings &&
              user.settings.permalink &&
              clientsSettings.showUpgradePlan && (
                <div className="flex flex-1 justify-center">
                  <Button
                    className="bg-orange-light text-white m-12 whitespace-no-wrap"
                    onClick={() => history.push("/pricing")}
                  >
                    <Hidden mdUp>
                      <Icon>cloud_upload</Icon>
                    </Hidden>
                    <Hidden smDown>
                      {languageStrings["TOOLBAR.IMPROVE_PLAN"]}
                    </Hidden>
                  </Button>
                </div>
              )}
            <UserMenu />
          </div>
          {config.navbar.display && config.navbar.position === "right" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton />
            </Hidden>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default ToolbarLayout1;
