import React, { Component, useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { connect, useSelector } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import { userListHeaders } from "./components/data";
import { getUserList, setUserSearchText } from "app/store/user/UserActions";
import { translate, useLanguageHelper } from "app/helpers/LanguageHelper/index";
import UpgradeModal from "../common/UpgradeModal";
import history from "@history";
import { ExportFiles } from "../common/FileExport";
import moment from "moment";

function UserList(props) {
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.getUserList();
    if (user.accessLevel == "1") {
      setOpen(true);
    }
  }, []);

  const setSearchText = (ev) => {
    props.setUserSearchText(ev);
  };
  const handleClick = (item) => {
    history.push("/userDetail/" + item._id);
  };
  const { languageStrings } = useLanguageHelper();

  const headerRows = {
    data: [
      {
        id: "platform",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.PLATFORM"],
        sort: true,
        type: "icon",
      },
      {
        id: ["firstName", "lastName"],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
      {
        id: "email",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.EMAIL"],
        sort: true,
        type: "text",
      },
      {
        id: "totalPoints",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.POINTS"],
        sort: true,
        type: "text",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
    searchBy: ["firstName", "lastName", "email"],
  };

  const exportFiles = () => {
    const usersData = [];
    props.userList.forEach((user) => {
      const usersObj = {};

      const name = `${user.firstName || ""} ${user.lastName || ""}`;
      const platform = user && user.platform === 1 ? "android" : "iphone";

      usersObj["platform"] = platform;
      usersObj["name"] = name;
      usersObj["email"] = user.email;
      usersObj["points"] = user.totalPoints;
      usersObj["createdAt"] = moment(user.createdAt).format("L");
      usersObj["createdTime"] = moment(user.createdAt).format("LT");

      usersData.push(usersObj);
    });
    return usersData;
  };
  return (
    <>
      <UpgradeModal
        open={open}
        setOpen={() => {
          history.goBack();
          setOpen(false);
        }}
      />
      <LoadingOverlay
        active={props.loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: props.loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div style={{ flex: 1, filter: open ? "blur(3px)" : "none" }}>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  title={languageStrings["USER_LIST_PAGE.TITLE"]}
                  setSearchText={setSearchText}
                  searchType={"user"}
                />

                {/* <ExportFiles
                  fileName={"user-list"}
                  data={exportFiles()}
                  header={userListHeaders}
                /> */}
              </div>
            }
            content={
              <ListTable
                handleClick={handleClick}
                headerRows={headerRows}
                data={props.userList}
                searchText={props.searchText}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.user.loading,
    userList: state.banzzu.user.userAppList,
    searchText: state.banzzu.user.searchText,
  };
};
const mapDispatchToProps = {
  getUserList,
  setUserSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(UserList));
