import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListHeader from "app/main/common/List/ListHeader";
import {
  deleteGroupModifier,
  getGroupModifierList,
  setGroupModifierSearchText,
  updateGroupModifierVisibility,
} from "app/store/catalogue/CatalogueActions";
import history from "@history";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import useSyncedPage from "app/hooks/useSyncedPage";

const GroupModifierList = () => {
  const { searchGroupModifierText, groupModifierList, loading } = useSelector(
    ({ banzzu }) => banzzu.catalogue
  );
  const [openVisibility, setOpenVisibility] = useState({
    itemId: "",
    show: false,
    data: null,
  });
  const dispatch = useDispatch();
  const { currentPage, setCurrentPage } = useSyncedPage();
  const { languageStrings } = useLanguageHelper();
  useEffect(() => {
    dispatch(getGroupModifierList());
  }, []);

  const setSearchText = (ev) => {
    dispatch(setGroupModifierSearchText(ev));
  };

  const headerRows = {
    data: [
      {
        id: "title",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.TITLE"],
        sort: true,
        type: "text",
      },
      {
        id: "sku",
        align: "left",
        disablePadding: true,
        label: languageStrings["CATALOGUE.SKU"],
        sort: true,
        type: "text",
      },
      {
        id: "type",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.TYPE"],
        sort: true,
        type: "type-text",
      },
      {
        id: "visibility",
        align: "left",
        disablePadding: false,
        label: languageStrings["VISIBILITY"],
        sort: true,
        type: "visibilityBanner",
      },
      {
        id: "visibility",
        align: "left",
        icon: "visibility",
        disablePadding: false,
        label: languageStrings["ACTION"],
        sort: true,
        type: "singleIcon",
        onClick: (e, id) => {
          const findData = groupModifierList.find((item) => item._id === id);
          let data = null;
          if (findData && findData._id) {
            data = findData;
          }
          setOpenVisibility({
            data,
            itemId: id,
            show: true,
          });
        },
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["title"],
  };
  const onHandleDelete = (selected) => {
    dispatch(
      deleteGroupModifier(selected, () => {
        history.replace({
          pathname: history.location.pathname,
          state: { page: 0 },
        });
      })
    );
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleClick = (item) => {
    history.push({
      pathname: `/catalogue/group-modifier/${item._id}`,
      state: { page: currentPage },
    });
  };
  const onVisibilityClose = () => {
    setOpenVisibility({
      itemId: "",
      show: !openVisibility.show,
      data: null,
    });
  };
  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  title={languageStrings["SIDEBAR_MENUE.GROUPMODIFIERS"]}
                  setSearchText={setSearchText}
                  searchType={"catalogue"}
                  searchTextKey={"searchGroupModifierText"}
                  createButton
                  createUrl={{
                    pathname: "/catalogue/group-modifier/new",
                    state: { page: currentPage },
                  }}
                  buttonText={languageStrings["CATALOGUE.CREATE_GROUPMODIFIER"]}
                />
              </div>
            }
            content={
              <ListTable
                headerRows={headerRows}
                data={groupModifierList}
                searchText={searchGroupModifierText}
                handleDelete={onHandleDelete}
                handleClick={handleClick}
                onPageChange={handlePageChange}
                page={currentPage}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      <VisibilityModalV2
        open={openVisibility.show}
        onClose={onVisibilityClose}
        data={openVisibility.data}
        onVisibilityChange={(item = {}) => {
          const payload = {
            ...item,
            groupId: openVisibility.itemId,
          };
          dispatch(updateGroupModifierVisibility(payload));
        }}
      />
    </>
  );
};

export default GroupModifierList;
