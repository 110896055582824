import {
  ARRANGE_PRODUCT_SIZE,
  CATALOG_GROUP_MODIFIER,
  CATALOG_GROUP_MODIFIER_DETAIL,
  CATALOG_MODIFIER,
  CATALOG_MODIFIER_DETAIL,
  CATALOG_PRODUCT,
  CATALOG_PRODUCT_DETAIL,
  PRODUCT_SIZE,
  CATALOG_COMBOS_URL,
  getUserId,
  POS_COMBO_IMAGE,
} from "./../../api/Endpoint";
import { CatalogueActionTypes } from "./../redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";
import { getMenuItemV2List } from "app/store/menuV2/MenuV2Actions";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import _ from "@lodash";
import { GroupModifier, Modifier, Product } from "app/models/Catalogue";

//PRODUCTS

export function setProductSearchText(event) {
  return {
    type: CatalogueActionTypes.SET_PRODUCT_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getProductList = () => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.PRODUCT_LIST_START,
    });

    axios
      .get(CATALOG_PRODUCT)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getProductListSuccess(dispatch, res.data);
        } else {
          getProductListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getProductListFail(dispatch, "There was an error connection");
      });
  };
};

const getProductListFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.PRODUCT_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getProductListSuccess = (dispatch, data) => {
  dispatch({
    type: CatalogueActionTypes.PRODUCT_LIST_SUCCESS,
    payload: data,
  });
};

export const getProductDetail = (id: string, page: number) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.PRODUCT_DETAIL_START,
    });

    //TODO REFACTOR URL
    const url = `${CATALOG_PRODUCT_DETAIL}/${id}`;

    axios
      .get(url)
      .then((res) => {
        getProductDetailSuccess(dispatch, res.data.data);
        dispatch(getProductSizes({ productId: id }));
      })
      .catch(() => {
        getProductDetailFail(dispatch, "There was an error connection", page);
      });
  };
};

const getProductDetailFail = (dispatch, errorMessage, page) => {
  dispatch({
    type: CatalogueActionTypes.PRODUCT_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/catalogue/products`,
    state: { page: page },
  });
};

const getProductDetailSuccess = (dispatch, data) => {
  dispatch({
    type: CatalogueActionTypes.PRODUCT_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createProduct = (product: Product, page = 0) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.PRODUCT_CREATE_START,
    });

    let formData = new FormData();

    for (let key in product) {
      if (key !== "groupModifiers") {
        if (key == "imageFile") {
          formData.append(key, product[key], product[key].name);
        } else if (key === "skuList" || key === "groupIds") {
          formData.append(key, JSON.stringify(product[key]));
        } else if (key != "id") {
          formData.append(key, product[key]);
        }
      }
    }

    axios
      .post(CATALOG_PRODUCT, formData)
      .then((res) => {
        const data = res.data;
        data.page = page;
        createProductSuccess(dispatch, data);
      })
      .catch((error) => {
        let errMsg = "There was an error connection";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errMsg = error.response.data.message;
        }
        createProductFail(dispatch, errMsg);
      });
  };
};

const createProductFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: CatalogueActionTypes.PRODUCT_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createProductSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: CatalogueActionTypes.PRODUCT_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/catalogue/products",
    state: { page: data.page },
  });
};

export const editProduct = (product: Product, page = 0) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.PRODUCT_EDIT_START,
    });

    let formData = new FormData();

    for (let key in product) {
      if (key !== "groupModifiers") {
        if (key == "_id") {
          formData.append("productId", product[key]);
        } else if (key === "skuList" || key === "groupIds") {
          formData.append(key, JSON.stringify(product[key]));
        } else if (key == "imageFile" && product[key]) {
          formData.append(key, product[key], product[key].name);
        } else if (key !== "image") {
          formData.append(key, product[key]);
        }
      }
    }

    //TODO REFACTOR URL
    axios
      .put(CATALOG_PRODUCT, formData)
      .then((res) => {
        const data = res.data;
        data.page = page;
        editProductSuccess(dispatch, data);
      })
      .catch((error) => {
        let errMsg = "There was an error connection";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errMsg = error.response.data.message;
        }
        editProductFail(dispatch, errMsg);
      });
  };
};

const editProductFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: CatalogueActionTypes.PRODUCT_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editProductSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: CatalogueActionTypes.PRODUCT_EDIT_SUCCESS,
    payload: data,
  });

  History.push({
    pathname: "/catalogue/products",
    state: { page: data.page },
  });
};

export const deleteProduct = (idArray: String[], callback: () => void) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.PRODUCT_DELETE_START,
    });

    axios
      .delete(CATALOG_PRODUCT, { data: { productIds: idArray } })
      .then((res) => {
        callback();
        deleteProductSuccess(dispatch, res.data);
      })
      .catch(() => {
        deleteProductFail(dispatch, "There was an error connection");
      });
  };
};

const deleteProductFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.PRODUCT_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteProductSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getProductList());
  dispatch({
    type: CatalogueActionTypes.PRODUCT_DELETE_FAIL,
    payload: data,
  });
};
export const updateProductVisibility = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.PRODUCT_VISIBILITY_START,
    });

    axios
      .post(`${CATALOG_PRODUCT}/visibility`, data)
      .then((res) => {
        updateProductVisibilitySuccess(dispatch, res.data);
      })
      .catch(() => {
        updateProductVisibilityFail(dispatch, "There was an error connection");
      });
  };
};

const updateProductVisibilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.PRODUCT_VISIBILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateProductVisibilitySuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getProductList());
  dispatch({
    type: CatalogueActionTypes.PRODUCT_VISIBILITY_SUCCESS,
    payload: data,
  });
};

///MODIFIER

export function setModifierSearchText(event) {
  return {
    type: CatalogueActionTypes.SET_MODIFIER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getModifierList = () => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.MODIFIER_LIST_START,
    });

    axios
      .get(CATALOG_MODIFIER)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getModifierListSuccess(dispatch, res.data);
        } else {
          getModifierListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getModifierListFail(dispatch, "There was an error connection");
      });
  };
};

const getModifierListFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.MODIFIER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getModifierListSuccess = (dispatch, data) => {
  dispatch({
    type: CatalogueActionTypes.MODIFIER_LIST_SUCCESS,
    payload: data,
  });
};

export const getModifierDetail = (id: string, page: number) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.MODIFIER_DETAIL_START,
    });

    const url = `${CATALOG_MODIFIER_DETAIL}/${id}`;

    axios
      .get(url)
      .then((res) => {
        getModifierDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getModifierDetailFail(dispatch, "There was an error connection", page);
      });
  };
};

const getModifierDetailFail = (dispatch, errorMessage, page) => {
  dispatch({
    type: CatalogueActionTypes.MODIFIER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/catalogue/modifiers`,
    state: { page },
  });
};

const getModifierDetailSuccess = (dispatch, data) => {
  dispatch({
    type: CatalogueActionTypes.MODIFIER_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createModifier = (modifier: Modifier, page: number) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.MODIFIER_CREATE_START,
    });

    const keys = ["title", "price", "sku"];

    let formData = new FormData();

    for (let key in modifier) {
      if (key == "imageFile") {
        formData.append(key, modifier[key], modifier[key].name);
      } else if (keys.includes(key)) {
        formData.append(key, modifier[key]);
      }
    }

    //TODO CHANGE URL
    axios
      .post(CATALOG_MODIFIER, formData)
      .then((res) => {
        const data = res.data;
        data.page = page;
        createModifierSuccess(dispatch, data);
      })
      .catch((error) => {
        let errMsg = "There was an error connection";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errMsg = error.response.data.message;
        }
        createModifierFail(dispatch, errMsg);
      });
  };
};

const createModifierFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: CatalogueActionTypes.MODIFIER_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createModifierSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: CatalogueActionTypes.MODIFIER_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/catalogue/modifiers",
    state: { page: data.page },
  });
};

export const editModifier = (modifier: Modifier, page: number) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.MODIFIER_EDIT_START,
    });

    let formData = new FormData();
    const keys = ["title", "price", "sku", "deleteImage"];
    for (let key in modifier) {
      if (key == "_id") {
        formData.append("modifierId", modifier[key]);
      } else if (key == "imageFile" && modifier[key]) {
        formData.append(key, modifier[key], modifier[key].name);
      } else if (keys.includes(key)) {
        formData.append(key, modifier[key]);
      }
    }

    //TODO REFACTOR URL
    axios
      .put(CATALOG_MODIFIER, formData)
      .then((res) => {
        const data = res.data;
        data.page = page;
        editModifierSuccess(dispatch, data);
      })
      .catch((error) => {
        let errMsg = "There was an error connection";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errMsg = error.response.data.message;
        }
        editModifierFail(dispatch, errMsg);
      });
  };
};

const editModifierFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: CatalogueActionTypes.MODIFIER_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editModifierSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: CatalogueActionTypes.MODIFIER_EDIT_SUCCESS,
    payload: data,
  });

  History.push({
    pathname: "/catalogue/modifiers",
    state: { page: data.page },
  });
};

export const deleteModifier = (modifierIds: String[], callback: () => void) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.MODIFIER_DELETE_START,
    });

    axios
      .delete(CATALOG_MODIFIER, { data: { modifierIds } })
      .then((res) => {
        callback();
        deleteModifierSuccess(dispatch, res.data);
      })
      .catch(() => {
        deleteModifierFail(dispatch, "There was an error connection");
      });
  };
};

const deleteModifierFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.MODIFIER_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteModifierSuccess = (dispatch, data) => {
  successAlert(dispatch, "Deleted Successfully");
  dispatch(getModifierList());
  dispatch({
    type: CatalogueActionTypes.MODIFIER_DELETE_FAIL,
    payload: data,
  });
};

export const updateModifierVisibility = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.MODIFIER_VISIBILITY_START,
    });

    axios
      .post(`${CATALOG_MODIFIER_DETAIL}/visibility`, data)
      .then((res) => {
        updateModifierVisibilitySuccess(dispatch, res.data);
      })
      .catch(() => {
        updateModifierVisibilityFail(dispatch, "There was an error connection");
      });
  };
};

const updateModifierVisibilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.MODIFIER_VISIBILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateModifierVisibilitySuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getModifierList());
  dispatch({
    type: CatalogueActionTypes.MODIFIER_VISIBILITY_SUCCESS,
    payload: data,
  });
};

/// GROUP MODIFIER

export function setGroupModifierSearchText(event) {
  return {
    type: CatalogueActionTypes.SET_GROUP_MODIFIER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getGroupModifierList = () => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.GROUP_MODIFIER_LIST_START,
    });
    axios
      .get(CATALOG_GROUP_MODIFIER)
      .then((res) => {
        if (res.data.statusCode === 200) {
          getGroupModifierListSuccess(dispatch, res.data);
        } else {
          getGroupModifierListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getGroupModifierListFail(dispatch, "There was an error connection");
      });
  };
};

const getGroupModifierListFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getGroupModifierListSuccess = (dispatch, data) => {
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_LIST_SUCCESS,
    payload: data,
  });
};

export const getGroupModifierDetail = (id: string, page: number) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.GROUP_MODIFIER_DETAIL_START,
    });

    const url = `${CATALOG_GROUP_MODIFIER_DETAIL}/${id}`;

    axios
      .get(url)
      .then((res) => {
        getGroupModifierDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getGroupModifierDetailFail(
          dispatch,
          "There was an error connection",
          page
        );
      });
  };
};

const getGroupModifierDetailFail = (dispatch, errorMessage, page) => {
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/catalogue/groupModifiers`,
    state: { page },
  });
};

const getGroupModifierDetailSuccess = (dispatch, data) => {
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createGroupModifier = (
  groupModifier: GroupModifier,
  page: number
) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.GROUP_MODIFIER_CREATE_START,
    });
    const _modifiers =
      groupModifier && groupModifier.modifiers ? groupModifier.modifiers : [];
    const ids = [];
    for (let i = 0; i < _modifiers.length; i++) {
      const item = _modifiers[i];
      ids.push(item._id);
    }
    const payload: GroupModifier = {
      ...groupModifier,
      modifiers: ids,
    };
    axios
      .post(CATALOG_GROUP_MODIFIER, payload)
      .then((res) => {
        const data = res.data;
        data.page = page;
        createGroupModifierSuccess(dispatch, data);
      })
      .catch((error) => {
        let errMsg = "There was an error connection";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errMsg = error.response.data.message;
        }
        createGroupModifierFail(dispatch, errMsg);
      });
  };
};

const createGroupModifierFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createGroupModifierSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/catalogue/group-modifiers",
    state: { page: data.page },
  });
};

export const editGroupModifier = (
  groupModifier: GroupModifier,
  page: number
) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.GROUP_MODIFIER_EDIT_START,
    });
    const _modifiers =
      groupModifier && groupModifier.modifiers ? groupModifier.modifiers : [];
    const ids = [];
    for (let i = 0; i < _modifiers.length; i++) {
      const item = _modifiers[i];
      ids.push(item._id);
    }
    const payload: GroupModifier = {
      ...groupModifier,
      modifiers: ids,
    };
    axios
      .put(CATALOG_GROUP_MODIFIER, payload)
      .then((res) => {
        const data = res.data;
        data.page = page;
        editGroupModifierSuccess(dispatch, data);
      })
      .catch((error) => {
        let errMsg = "There was an error connection";
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errMsg = error.response.data.message;
        }
        editGroupModifierFail(dispatch, errMsg);
      });
  };
};

const editGroupModifierFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_EDIT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editGroupModifierSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_EDIT_SUCCESS,
    payload: data,
  });

  History.push({
    pathname: "/catalogue/group-modifiers",
    state: { page: data.page },
  });
};

export const deleteGroupModifier = (
  groupModifierIds: String[],
  callback: () => void
) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.GROUP_MODIFIER_DELETE_START,
    });

    axios
      .delete(CATALOG_GROUP_MODIFIER, { data: { groupModifierIds } })
      .then((res) => {
        callback();
        deleteGroupModifierSuccess(dispatch, res.data);
      })
      .catch(() => {
        deleteGroupModifierFail(dispatch, "There was an error connection");
      });
  };
};

const deleteGroupModifierFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteGroupModifierSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getGroupModifierList());
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_DELETE_FAIL,
    payload: data,
  });
};
export const updateGroupModifierVisibility = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.GROUP_MODIFIER_VISIBILITY_START,
    });

    axios
      .post(`${CATALOG_GROUP_MODIFIER}/visibility`, data)
      .then((res) => {
        updateGroupModifierVisibilitySuccess(dispatch, res.data);
      })
      .catch(() => {
        updateGroupModifierVisibilityFail(
          dispatch,
          "There was an error connection"
        );
      });
  };
};

const updateGroupModifierVisibilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_VISIBILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateGroupModifierVisibilitySuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getGroupModifierList());
  dispatch({
    type: CatalogueActionTypes.GROUP_MODIFIER_VISIBILITY_SUCCESS,
    payload: data,
  });
};

//Size Actions
export const getProductSizes = (params) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.GET_PRODUCT_SIZES_START,
    });

    axios
      .get(`${PRODUCT_SIZE}`, { params })
      .then((res) => {
        if (res.data && res.data.data && Array.isArray(res.data.data)) {
          getProductSizesSuccess(dispatch, res.data.data);
        } else {
          getProductSizesSuccess(dispatch, []);
        }
      })
      .catch(() => {
        getProductSizesFail(dispatch, "There was an error connection");
      });
  };
};

const getProductSizesFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.GET_PRODUCT_SIZES_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const getProductSizesSuccess = (dispatch, data) => {
  dispatch({
    type: CatalogueActionTypes.GET_PRODUCT_SIZES_SUCCESS,
    payload: data,
  });
};
export const createSize = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.CREATE_SIZE_START,
    });

    axios
      .post(`${PRODUCT_SIZE}`, data)
      .then((res) => {
        createSizeSuccess(dispatch, res.data, data.productId);
      })
      .catch(() => {
        createSizeFail(dispatch, "There was an error connection");
      });
  };
};

const createSizeFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: CatalogueActionTypes.CREATE_SIZE_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const createSizeSuccess = (dispatch, data, productId) => {
  successAlert(dispatch, data.message);
  dispatch(getProductSizes({ productId }));
  dispatch({
    type: CatalogueActionTypes.CREATE_SIZE_SUCCESS,
    payload: data,
  });
};

export const deleteSize = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.DELETE_SIZE_START,
    });

    axios
      .delete(`${PRODUCT_SIZE}`, { data })
      .then((res) => {
        deleteSizeSuccess(dispatch, res.data, data.productId);
      })
      .catch(() => {
        deleteSizeFail(dispatch, "There was an error connection");
      });
  };
};

const deleteSizeFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.DELETE_SIZE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteSizeSuccess = (dispatch, data, productId) => {
  successAlert(dispatch, data.message);
  dispatch(getProductSizes({ productId }));
  dispatch({
    type: CatalogueActionTypes.DELETE_SIZE_SUCCESS,
    payload: data,
  });
};

export const updateSize = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.UPDATE_SIZE_START,
    });

    axios
      .put(`${PRODUCT_SIZE}`, data)
      .then((res) => {
        updateSizeSuccess(dispatch, res.data, data.productId);
      })
      .catch(() => {
        updateSizeFail(dispatch, "There was an error connection");
      });
  };
};

const updateSizeFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.UPDATE_SIZE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateSizeSuccess = (dispatch, data, productId) => {
  successAlert(dispatch, data.message);
  dispatch(getProductSizes({ productId }));
  dispatch({
    type: CatalogueActionTypes.UPDATE_SIZE_SUCCESS,
    payload: data,
  });
};

export const arrangeSizes = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.ARRANGE_SIZES_START,
    });

    axios
      .put(`${ARRANGE_PRODUCT_SIZE}`, data)
      .then((res) => {
        arrangeSizesSuccess(dispatch, res.data, data.productId);
      })
      .catch(() => {
        arrangeSizesFail(dispatch, "There was an error connection");
      });
  };
};

const arrangeSizesFail = (dispatch, errorMessage) => {
  dispatch({
    type: CatalogueActionTypes.ARRANGE_SIZES_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const arrangeSizesSuccess = (dispatch, data, productId) => {
  successAlert(dispatch, data.message);
  dispatch(getProductSizes({ productId }));
  dispatch({
    type: CatalogueActionTypes.ARRANGE_SIZES_SUCCESS,
    payload: data,
  });
};

const createCatalogComboSuccess = (dispatch, menuId) => {
  dispatch(getMenuItemV2List(menuId, false));
  dispatch({
    type: CatalogueActionTypes.CATALOG_COMBO_CREATE_SUCCESS,
  });
};
const createCatalogComboFail = (dispatch) => {
  dispatch({
    type: CatalogueActionTypes.CATALOG_COMBO_CREATE_FAIL,
  });
};
const deleteCatalogComboSuccess = (dispatch, menuId) => {
  dispatch(getMenuItemV2List(menuId, false));
  dispatch({
    type: CatalogueActionTypes.CATALOG_COMBO_DELETE_SUCCESS,
  });
};
const deleteCatalogComboFail = (dispatch) => {
  dispatch({
    type: CatalogueActionTypes.CATALOG_COMBO_DELETE_FAIL,
  });
};
const getCatalogCombosSuccess = (dispatch, data) => {
  dispatch({
    type: CatalogueActionTypes.CATALOG_COMBO_LIST_SUCCESS,
    payload: data,
  });
};
const getCatalogCombosFail = (dispatch) => {
  dispatch({
    type: CatalogueActionTypes.CATALOG_COMBO_LIST_FAIL,
  });
};
export const getCatalogCombosList = (menuId) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.CATALOG_COMBO_LIST_START,
    });

    axios
      .get(CATALOG_COMBOS_URL, { params: { menuId } })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getCatalogCombosSuccess(dispatch, res.data.data);
        } else {
          getCatalogCombosFail(dispatch);
        }
      })
      .catch(() => {
        getCatalogCombosFail(dispatch);
      });
  };
};
export const deleteCatalogCombo = (_id, menuId) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.CATALOG_COMBO_DELETE_START,
    });

    axios
      .delete(CATALOG_COMBOS_URL, { data: { _id } })
      .then((res) => {
        if (res.data.statusCode === 200) {
          deleteCatalogComboSuccess(dispatch, menuId);
        } else {
          deleteCatalogComboFail(dispatch);
        }
      })
      .catch(() => {
        deleteCatalogComboFail(dispatch);
      });
  };
};
export const createCatalogCombo = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.CATALOG_COMBO_CREATE_START,
    });

    axios
      .post(CATALOG_COMBOS_URL, data)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          if (
            res.data &&
            res.data.data &&
            res.data.data._id &&
            data.isPos === true
          ) {
            let formData = new FormData();
            formData.append("userId", getUserId());
            formData.append("productId", res.data.data._id);
            formData.append("deleteImage", data["deleteImage"]);
            if (data && data.imageFile) {
              formData.append(
                "imageFile",
                data["imageFile"],
                data["imageFile"].name
              );
            }

            await axios.post(POS_COMBO_IMAGE, formData);
          }
          createCatalogComboSuccess(dispatch, data.menuId);
        } else {
          createCatalogComboFail(dispatch);
        }
      })
      .catch((error) => {
        createCatalogComboFail(dispatch);
      });
  };
};
const updateCatalogComboSuccess = (dispatch, menuId) => {
  dispatch(getMenuItemV2List(menuId, false));
  dispatch({
    type: CatalogueActionTypes.CATALOG_COMBO_UPDATE_SUCCESS,
  });
};
const updateCatalogComboFail = (dispatch) => {
  dispatch({
    type: CatalogueActionTypes.CATALOG_COMBO_UPDATE_FAIL,
  });
};
export const updateCatalogCombo = (data) => {
  return (dispatch) => {
    dispatch({
      type: CatalogueActionTypes.CATALOG_COMBO_UPDATE_START,
    });

    axios
      .put(CATALOG_COMBOS_URL, data)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          if (data && data._id && data.isPos) {
            let formData = new FormData();

            formData.append("productId", data._id);
            formData.append("deleteImage", data["deleteImage"]);
            if (data && data.imageFile) {
              formData.append(
                "imageFile",
                data["imageFile"],
                data["imageFile"].name
              );
            }
            await axios.post(POS_COMBO_IMAGE, formData);
          }
          updateCatalogComboSuccess(dispatch, data.menuId);
        } else {
          updateCatalogComboFail(dispatch);
        }
      })
      .catch(() => {
        updateCatalogComboFail(dispatch);
      });
  };
};
