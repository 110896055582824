import PaymentHistory from "./PaymentHistory";
import PaymentHistoryList from "./PaymentHistoryList";

export const PaymentHistoryConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/paymentHistory",
      component: PaymentHistoryList,
    },
    {
      path: "/paymentDetail/:paymentId",
      component: PaymentHistory,
    },
  ],
};
