import { OrderActionTypes } from "./../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE: OrderState = {
  orderList: [],
  totalOrders: 0,
  loading: false,
  searchText: "",
  orderDetail: null,
  tableList: [],
  orderRevenue: {},
  orderAnalytics: {},
};

interface Action {
  payload: any;
  type: string;
}

const OrdersReducer = (
  state: OrderState = INITIAL_STATE,
  action: Action
): OrderState => {
  switch (action.type) {
    case OrderActionTypes.ORDERS_LIST_START:
      return updateObject(state, {
        loading: true,
        orderList: [],
      });

    case OrderActionTypes.ORDERS_LIST_SUCCESS:
      return updateObject(state, {
        orderList: action.payload.orders,
        totalOrders: action.payload.totalOrders,
        totalPayment: action.payload.totalPayment,
        loading: false,
      });
    case OrderActionTypes.EXPOER_ORDERS_LIST_START:
      return updateObject(state, {
        loading: true,
      });
    case OrderActionTypes.EXPOER_ORDERS_LIST_SUCCESS:
      return updateObject(state, {
        loading: false,
      });
    case OrderActionTypes.EXPOER_ORDERS_LIST_FAILED:
      return updateObject(state, {
        loading: false,
      });

    case OrderActionTypes.ORDERS_LIST_FAIL:
      return updateObject(state, { loading: false });

    case OrderActionTypes.TABLE_LIST_START:
      return updateObject(state, {
        loading: true,
        tableList: [],
      });

    case OrderActionTypes.TABLE_LIST_SUCCESS:
      return updateObject(state, {
        tableList: action.payload.data,
        loading: false,
      });

    case OrderActionTypes.TABLE_LIST_FAIL:
      return updateObject(state, { loading: false });

    case OrderActionTypes.ORDER_REVENUE_START:
      return updateObject(state, { loading: true });

    case OrderActionTypes.ORDER_REVENUE_SUCCESS:
      return updateObject(state, {
        orderRevenue: action.payload.data,
        loading: false,
      });

    case OrderActionTypes.ORDER_REVENUE_FAIL:
      return updateObject(state, { loading: false });

    case OrderActionTypes.ORDER_ANALYTICS_START:
      return updateObject(state, { loading: true });

    case OrderActionTypes.ORDER_ANALYTICS_SUCCESS:
      return updateObject(state, {
        orderAnalytics: action.payload.data,
        loading: false,
      });

    case OrderActionTypes.ORDER_ANALYTICS_FAIL:
      return updateObject(state, { loading: false });

    case OrderActionTypes.SET_ORDER_SEARCH_TEXT:
      return updateObject(state, {
        searchText: action.payload.searchText,
      });

    case OrderActionTypes.ORDER_DETAIL_START:
      return updateObject(state, {
        loading: true,
        orderDetail: {},
      });

    case OrderActionTypes.ORDER_DETAIL_SUCCESS:
      return updateObject(state, {
        orderDetail: action.payload.data,
        loading: false,
      });

    case OrderActionTypes.ORDER_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case OrderActionTypes.CHANGE_ORDER_STATUS_START:
      return updateObject(state, { loading: true });

    case OrderActionTypes.CHANGE_ORDER_STATUS_SUCCESS:
      return updateObject(state, { loading: false });

    case OrderActionTypes.PAYMENT_REFUND_FAIL:
      return updateObject(state, { loading: false });

    case OrderActionTypes.PAYMENT_REFUND_START:
      return updateObject(state, { loading: true });

    case OrderActionTypes.PAYMENT_REFUND_SUCCESS:
      return updateObject(state, { loading: false });

    default: {
      return state;
    }
  }
};

export default OrdersReducer;
