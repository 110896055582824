import { clientsSettings } from "config";
import React from "react";

const _route = clientsSettings.showRegistration
  ? [
      {
        path: "/register/:locale?/:code?",
        component: React.lazy(() => import("./RegisterPage")),
      },
      {
        path: "/setup-account/:locale/:id",
        component: React.lazy(() => import("./SetupAccountPage")),
      },
    ]
  : [
      {
        path: "/setup-account/:locale/:id",
        component: React.lazy(() => import("./SetupAccountPage")),
      },
    ];
export const RegisterPageConfig = {
  settings: {
    layout: {
      config: {
        scroll: "content",
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        mode: "fullwidth",
      },
    },
  },
  routes: _route,
};
