import { Product } from "./Catalogue";
import { VisibilityRange } from "./VisibilityRange";

export interface Promotion {
  _id?: string;
  title: string;
  type: string;
  visibility: string;
  visibilityRanges: VisibilityRange[];
  products?: Product[];
}

export enum PROMOTION_TYPE {
  BUY_2_GET_1 = "1",
}
