import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { languagesListData } from "./data";
import { useLanguageHelper } from "app/helpers/LanguageHelper";

const LanguageDropdown = ({
  languages,
  selectedLanguage,
  onChange,
  handleLanguageTitle,
  menuElement,
}) => {
  const { languageStrings } = useLanguageHelper();
  const defaultValue = {
    code: 1,
    name: "Spanish",
    locale: "es",
    label: "LANGUAGE.SPANISH",
  };

  return (
    <Tabs
      value={selectedLanguage}
      onChange={(e, newValue) => {
        onChange(newValue);
        handleLanguageTitle(menuElement, newValue);
      }}
      indicatorColor="secondary"
      textColor="secondary"
      variant="scrollable"
      scrollButtons="auto"
      classes={{ root: "w-full h-64 mb-10" }}
    >
      {languages.map((item) => {
        const languageData =
          languagesListData.find((language) => language.name === item) ||
          defaultValue;
        return (
          <Tab
            key={languageData.code}
            className="h-64 normal-case min-w-32 mr-10"
            label={languageStrings[languageData.label]}
            value={languageData.code}
          />
        );
      })}
    </Tabs>
  );
};

export default LanguageDropdown;
