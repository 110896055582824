import ModifierDetail from "./ModifierDetail";
import ModifierList from "./ModifierList";

export const ModifierConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/catalogue/modifiers",
      component: ModifierList,
    },
    {
      path: "/catalogue/modifiersDetail/:modifierId",
      component: ModifierDetail,
    },
    {
      path: "/catalogue/modifiersDetail/:modifierId",
      component: ModifierDetail,
    },
  ],
};
