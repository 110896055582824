import { PosActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: POSState = {
  products: { products: [], categories: [], isPOSIntegration: false },
  combos: { combos: [], categories: [], isPOSIntegration: false },
  loading: false,
  searchText: "",
  androidUsers: "",
  iosUsers: "",
  userDetails: {},
};

interface Action {
  payload: any;
  type: string;
}

const PosReducer = (
  state: POSState = INITIAL_STATE,
  action: Action
): POSState => {
  switch (action.type) {
    case PosActionTypes.PRODUCTS_LIST: {
      return {
        ...state,
        loading: true,
        products: {
          categories: [],
          products: [],
          isPOSIntegration: state.products.isPOSIntegration,
        },
      };
    }
    case PosActionTypes.PRODUCTS_LIST_SUCCESS: {
      return {
        ...state,
        products: action.payload.data,
        loading: false,
      };
    }
    case PosActionTypes.PRODUCTS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case PosActionTypes.COMBO_LIST: {
      return {
        ...state,
        loading: true,
        combos: {
          categories: [],
          combos: [],
          isPOSIntegration: false,
        },
      };
    }
    case PosActionTypes.COMBO_LIST_SUCCESS: {
      return {
        ...state,
        combos: action.payload.data,
        loading: false,
      };
    }
    case PosActionTypes.COMBO_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case PosActionTypes.SET_TIPS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    default: {
      return state;
    }
  }
};

export default PosReducer;
