import ProductPOSList from "./ProductPOSList";

export const ProductsPOSConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/products",
      component: ProductPOSList,
    },
  ],
};
