import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import history from "@history";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import { translate } from "app/helpers/LanguageHelper/index";
import { clientsSettings } from "config";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import {
  deleteMenuV2,
  duplicateMenuV2,
  getMenuV2List,
  setMenuV2SearchText,
} from "app/store/menuV2/MenuV2Actions";

const MenuListV2 = () => {
  const dispatch = useDispatch();
  const {
    loading = false,
    menuV2List: _menuList = [],
    searchText = "",
  } = useSelector((state) => state.banzzu.menuV2);
  const user = useSelector((state) => state.auth.user);
  const { languageStrings } = useLanguageHelper();

  useEffect(() => {
    dispatch(getMenuV2List());
  }, [dispatch]);

  const onHandleDelete = (selected) => {
    dispatch(deleteMenuV2(selected));
  };

  const handleClick = (item) => {
    history.push("/menuV2/menuDetailPro/" + item._id);
  };

  const setSearchText = (ev) => {
    dispatch(setMenuV2SearchText(ev));
  };

  const duplicateMenuHandler = (selected) => {
    dispatch(duplicateMenuV2(selected));
  };
  const menuList = _menuList && _menuList.length ? _menuList : [];
  const headerRows = {
    data: [
      {
        id: "image",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.IMAGE"],
        sort: false,
        type: "image",
      },
      {
        id: "title",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.NAME"],
        sort: true,
        type: "text",
      },
      {
        id: "visibility",
        align: "left",
        disablePadding: false,
        label: languageStrings["VISIBILITY"],
        sort: true,
        type: "visibilityBanner",
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["title"],
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <ListHeader
            title={languageStrings["MENU_LIST_PAGE.TITLE"]}
            setSearchText={setSearchText}
            createUrl={"/menuV2/menuDetailPro/new"}
            searchType={"menu"}
            createButton
            checkCreateMenu={user.accessLevel === "1" && menuList.length === 0}
            buttonText={languageStrings["MENU_LIST_PAGE.BUTTON_TEXT"]}
            limited={menuList.length >= 1}
          />
        }
        content={
          <ListTable
            handleClick={handleClick}
            handleDelete={onHandleDelete}
            handleDuplicate={duplicateMenuHandler}
            duplicate
            headerRows={headerRows}
            data={menuList}
            searchText={searchText}
          />
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default translate()(MenuListV2);
