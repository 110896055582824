import React from "react";
import { TableCell } from "@material-ui/core";
import { getPromotionLabel } from "app/helpers/utilsHelper";
const PromotionType = (props) => {
  const { n } = props;

  return (
    <TableCell className="truncate" component="th" scope="row">
      {getPromotionLabel(n.type)}
    </TableCell>
  );
};

export default PromotionType;
