import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
function useClientCurrency() {
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const [clientCurrency, setClientCurrency] = useState({
    symbol: "€",
    title: '"Euro"',
  });

  useEffect(() => {
    if (
      user.currencyId &&
      user.currencyId.symbol &&
      user.currencyId.symbol !== clientCurrency.symbol
    ) {
      setClientCurrency(user.currencyId);
    }
  }, [user]);

  return { clientCurrency };
}

export default useClientCurrency;
