import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import withReducer from "app/store/withReducer";
import LoadingOverlay from "react-loading-overlay";
import reducer from "app/store/promotion/PromotionsReducer";
import { getPromotionDetail } from "app/store/promotion/PromotionsAction";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import PromotionForm from "./components/PromotionForm";
import POSPromotionDetailHeader from "./components/POSPromotionDetailHeader";
import { Tab, Tabs } from "@material-ui/core";
import History from "@history";

const PromotionsPOSDetails = (props) => {
  const dispatch = useDispatch();
  const { promotionId } = props.match.params;
  const loading = useSelector(({ banzzu }) => banzzu.promotion.loading);
  const [tabValue, setTabValue] = useState(0);
  const { languageStrings } = useLanguageHelper();
  const page = History.location.state.page || 0;

  useEffect(() => {
    if (promotionId !== "new") {
      dispatch(getPromotionDetail(promotionId, page));
    }
  }, [dispatch, promotionId]);

  const handleChangeTab = (event, tabValue) => {
    setTabValue(tabValue);
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={<POSPromotionDetailHeader page={page} />}
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          <div className="p-16 sm:p-24 max-w-2xl">
            {tabValue === 0 && (
              <PromotionForm promotionId={promotionId} page={page} />
            )}
          </div>
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default withReducer("PromotionsReducer", reducer)(PromotionsPOSDetails);
