import { KIOSK_BRANDING } from "./../../api/Endpoint";
import { KioskActionTypes } from "./../redux/actionTypes";
import { axiosInstanceV2 as axios } from "app/api/axiosV2";
import _ from "@lodash";

export const getKioskBranding = (clientId: string) => {
  return (dispatch) => {
    dispatch({
      type: KioskActionTypes.GET_KIOSK_BRANDING_START,
    });
    const GET_BRANDING_URL = KIOSK_BRANDING + "/" + clientId;
    axios
      .get(GET_BRANDING_URL)
      .then((res) => {
        getKioskBrandingSuccess(dispatch, res.data.result);
      })
      .catch(() => {
        getKioskBrandingFail(dispatch, "There was an error connection");
      });
  };
};

const getKioskBrandingFail = (dispatch, errorMessage) => {
  dispatch({
    type: KioskActionTypes.GET_KIOSK_BRANDING_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getKioskBrandingSuccess = (dispatch, data) => {
  dispatch({
    type: KioskActionTypes.GET_KIOSK_BRANDING_SUCCESS,
    payload: data,
  });
};

export const createKioskBranding = (branding) => {
  return (dispatch) => {
    dispatch({
      type: KioskActionTypes.CREATE_KIOSK_BRANDING_START,
    });
    console.log("branding => ", branding);

    let formData = new FormData();
    if (branding.entryLogo.imageFile) {
      formData.append(
        "entryLogoImage",
        branding.entryLogo.imageFile,
        branding.entryLogo.imageFile.name
      );
    } else {
      formData.append("entryLogo[image]", branding.entryLogo.image);
    }

    if (branding.entryLogo.videoFile) {
      formData.append(
        "entryLogoVideo",
        branding.entryLogo.videoFile,
        branding.entryLogo.videoFile.name
      );
    } else {
      formData.append("entryLogo[video]", branding.entryLogo.video);
    }

    if (branding["nameScreenLogoFile"]) {
      formData.append(
        "nameScreenLogo",
        branding["nameScreenLogoFile"],
        branding["nameScreenLogoFile"].name
      );
    } else {
      formData.append("nameScreenLogo", branding["nameScreenLogo"]);
    }

    if (branding["orderTypeLogoFile"]) {
      formData.append(
        "orderTypeLogo",
        branding["orderTypeLogoFile"],
        branding["orderTypeLogoFile"].name
      );
    } else {
      formData.append("orderTypeLogo", branding["orderTypeLogo"]);
    }

    if (branding["paymentLogoFile"]) {
      formData.append(
        "paymentLogo",
        branding["paymentLogoFile"],
        branding["paymentLogoFile"].name
      );
    } else {
      formData.append("paymentLogo", branding["paymentLogo"]);
    }

    if (branding["autoserviceLogoFile"]) {
      formData.append(
        "autoserviceLogo",
        branding["autoserviceLogoFile"],
        branding["autoserviceLogoFile"].name
      );
    } else {
      formData.append("autoserviceLogo", branding["autoserviceLogo"]);
    }
    formData.append("entryLogo[type]", branding.entryLogo.type);
    formData.append("colors[main]", branding.colors.main);
    formData.append("colors[secondary]", branding.colors.secondary);
    formData.append("colors[left]", branding.colors.left);
    formData.append("colors[right]", branding.colors.right);
    formData.append("tableId", branding.tableId);
    formData.append("venueId", branding.venueId);
    formData.append("menuId", branding.menuId);
    formData.append("clientId", branding.clientId);
    axios
      .post(KIOSK_BRANDING, formData)
      .then((res) => {
        const data = res.data.result || {};
        createKioskBrandingSuccess(dispatch, data);
      })
      .catch(() => {
        createKioskBrandingFail(dispatch, "There was an error connection");
      });
  };
};

const createKioskBrandingFail = (dispatch, errorMessage) => {
  dispatch({
    type: KioskActionTypes.CREATE_KIOSK_BRANDING_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createKioskBrandingSuccess = (dispatch, data) => {
  dispatch({
    type: KioskActionTypes.CREATE_KIOSK_BRANDING_SUCCESS,
    payload: data,
  });
};
