import React from "react";
import _ from "@lodash";
import { Typography, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useSelector } from "react-redux";
import LoyaltyCategories from "./LoyaltyCategories";
import useClientCurrency from "app/hooks/useClientCurrency";

const LoyaltyProgramDetail = () => {
  const { languageStrings } = useLanguageHelper();
  const { paymentDetail } = useSelector(({ banzzu }) => banzzu.paymentHistory);
  const { loading, loyaltyClientDetail } = useSelector(
    ({ banzzu }) => banzzu.loyaltyProgram
  );
  const { clientCurrency } = useClientCurrency();
  const checkFrequency = (frequency: string = "") => {
    let translation = "";
    switch (frequency) {
      case "high":
        translation = "LOYALTY_PROGRAM_FREQUENCY_HIGH";
        break;
      case "medium":
        translation = "LOYALTY_PROGRAM_FREQUENCY_MEDIUM";
        break;
      case "low":
        translation = "LOYALTY_PROGRAM_FREQUENCY_LOW";
        break;
    }
    return languageStrings[translation];
  };
  return (
    loyaltyClientDetail && (
      <div className="pl-16 pr-16 pb-48 flex-1">
        <Card className="p-16">
          <div className="pb-16 flex justify-center flex-col">
            {loyaltyClientDetail && loyaltyClientDetail.name ? (
              <Typography className="h2" color="textSecondary">
                {languageStrings["GENERAL.NAME"]}: {loyaltyClientDetail.name}
              </Typography>
            ) : null}
            {loyaltyClientDetail && loyaltyClientDetail.email ? (
              <Typography className="h2" color="textSecondary">
                {languageStrings["GENERAL.EMAIL"]}: {loyaltyClientDetail.email}
              </Typography>
            ) : null}
          </div>
        </Card>
        <Card className="p-16 mt-10">
          <div className="pb-16 flex items-center">
            <Typography className="h2" color="textSecondary">
              {languageStrings["LOYALTY_PROGRAM_DETAIL"]}
            </Typography>
          </div>
          <div className="table-responsive">
            <table className="simple">
              <thead>
                <tr>
                  <th>{languageStrings["GENERAL.CATEGORY"]}</th>
                  <th>
                    {languageStrings["LOYALTY_PROGRAM_CLIENT_ORDER_NUMBER"]}
                  </th>
                  <th>
                    {languageStrings["LOYALTY_PROGRAM_CLIENT_ORDER_TOTAL"]}
                  </th>
                  <th>{languageStrings["LOYALTY_PROGRAM_CLIENT_AVERAGE"]}</th>
                  <th>
                    {languageStrings["LOYALTY_PROGRAM_CLIENT_TIP_NUMBER"]}
                  </th>
                  <th>{languageStrings["LOYALTY_PROGRAM_CLIENT_TIP_TOTAL"]}</th>
                  <th>{languageStrings["LOYALTY_PROGRAM_CLIENT_FREQUENCY"]}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {loyaltyClientDetail && loyaltyClientDetail.category && (
                      <LoyaltyCategories
                        status={loyaltyClientDetail.category}
                      />
                    )}
                  </td>
                  <td>
                    {loyaltyClientDetail && loyaltyClientDetail.orderCount && (
                      <Typography>{loyaltyClientDetail.orderCount}</Typography>
                    )}
                  </td>
                  <td>
                    {loyaltyClientDetail && loyaltyClientDetail.clientTotal && (
                      <Typography>
                        {Number(loyaltyClientDetail.clientTotal).toFixed(2)}
                        {clientCurrency.symbol}
                      </Typography>
                    )}
                  </td>
                  <td>
                    {loyaltyClientDetail &&
                      loyaltyClientDetail.clientAverage && (
                        <Typography>
                          {Number(loyaltyClientDetail.clientAverage).toFixed(2)}{" "}
                          {clientCurrency.symbol}
                        </Typography>
                      )}
                  </td>
                  <td>
                    {loyaltyClientDetail && loyaltyClientDetail.tipAmount && (
                      <Typography>{loyaltyClientDetail.tipAmount}</Typography>
                    )}
                  </td>
                  <td>
                    {loyaltyClientDetail && loyaltyClientDetail.totalTips && (
                      <Typography>
                        {Number(loyaltyClientDetail.totalTips).toFixed(2)}{" "}
                        {clientCurrency.symbol}
                      </Typography>
                    )}
                  </td>
                  <td>
                    {loyaltyClientDetail && loyaltyClientDetail.frequency && (
                      <Typography>
                        {checkFrequency(loyaltyClientDetail.frequency)}
                      </Typography>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      </div>
    )
  );
};

export default LoyaltyProgramDetail;
