import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getProductList } from "app/store/catalogue/CatalogueActions";
import { getLabelWith } from "app/helpers/utilsHelper";
import { SearchableDropDown } from "./SearchableDropDown";

export const ProductDropDown = ({
  selectedValue,
  handleChangeValue = () => {},
}) => {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();
  const { productList } = useSelector(({ banzzu }) => banzzu.catalogue);
  const [value, setValue] = useState();
  const searchBarRef = useRef(null);
  useEffect(() => {
    dispatch(getProductList());
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const _productList = productList && productList.length ? productList : [];

  const handleChange = (event) => {
    const val = event.target.value;
    const findProduct = _productList.find((item) => item._id === val);
    if (findProduct && findProduct._id) {
      handleChangeValue(findProduct, val);
      setValue("");
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    }
  };
  const { labelWith } = getLabelWith(languageStrings["SELECT_PRODUCT"]);
  return (
    <SearchableDropDown
      keyPropFn={(option) => option._id}
      valuePropFn={(option) => `${option.title} - ${option.sku}`}
      list={_productList}
      selectedValue={value && value._id}
      label={languageStrings["SELECT_PRODUCT"]}
      handleChange={handleChange}
      labelWidth={labelWith}
      searchBarRef={searchBarRef}
    />
  );
};
