import React, { FC, useEffect, useState } from "react";
import { makeStyles, Slider, Typography } from "@material-ui/core";
import { timeList } from "./timeData";

const useStyles = makeStyles((theme) => ({
  rootSlider: {
    color: "#ff8330",
    flex: 2,
  },
}));
interface IProps {
  onChange?: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
  start: string;
  end: string;
}
export const TimeSlider: FC<IProps> = ({ onChange, start = "", end = "" }) => {
  const classes = useStyles();
  const [value, setValue] = useState([0, 48]);
  const [updatedHours] = useState(timeList);
  const valuetext = (value) => {
    return `${value}°C`;
  };
  const getValue = () => {
    const _start = updatedHours.indexOf(start.replace(/:[^:]+$/, ""));
    const _end = updatedHours.indexOf(end.replace(/:[^:]+$/, ""));
    const filteredStart = _start >= 0 ? _start : updatedHours.indexOf(start);
    const filteredEnd = _end >= 0 ? _end : updatedHours.indexOf(end);
    const newValue = [filteredStart, filteredEnd];
    setValue(newValue);
  };
  useEffect(getValue, [start, end]);
  return (
    <div>
      <Typography
        variant="body1"
        style={{
          color: "#ff8330",
          fontSize: 14,
          fontWeight: "bold",
          textAlign: "right",
        }}
      >
        {`${start}  -  ${end}`}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Slider
          min={0}
          max={48}
          classes={{ root: classes.rootSlider }}
          value={value}
          onChange={(event, newValue) => {
            onChange(event, newValue);
          }}
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
        />
      </div>
    </div>
  );
};
