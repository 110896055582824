import React from "react";
import { TableCell } from "@material-ui/core";
import useClientCurrency from "app/hooks/useClientCurrency";

const PriceCell = (props) => {
  const { n } = props;
  const price = n && n.price && Number(n.price) ? Number(n.price) : 0;
  const { clientCurrency } = useClientCurrency();
  return (
    <TableCell className="truncate" component="th" scope="row">
      <font>
        {price.toFixed(2)} {`${clientCurrency.symbol}`}
      </font>
    </TableCell>
  );
};

export default PriceCell;
