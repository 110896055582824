import { CatalogueActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: CatalogueState = {
  loading: false,
  productDetail: {},
  productList: [],
  productSizes: [],
  modifierDetail: {},
  modifierList: [],
  groupModifierList: [],
  groupModifierDetail: {},
  searchGroupModifierText: "",
  searchModifierText: "",
  searchProductText: "",
  catalogCombos: [],
};

interface Action {
  payload: any;
  type: string;
}

const CatalogueReducer = (
  state: CatalogueState = INITIAL_STATE,
  action: Action
): CatalogueState => {
  switch (action.type) {
    case CatalogueActionTypes.PRODUCT_LIST_START: {
      return {
        ...state,
        productList: [],
        loading: true,
      };
    }
    case CatalogueActionTypes.PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        productList: action.payload.data,
        loading: false,
      };
    }
    case CatalogueActionTypes.PRODUCT_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.PRODUCT_DETAIL_START: {
      return {
        ...state,
        productDetail: {},
        loading: true,
      };
    }
    case CatalogueActionTypes.PRODUCT_DETAIL_SUCCESS: {
      return {
        ...state,
        productDetail: action.payload,
        loading: false,
      };
    }
    case CatalogueActionTypes.PRODUCT_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.PRODUCT_CREATE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.PRODUCT_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.PRODUCT_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.PRODUCT_DELETE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.PRODUCT_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.PRODUCT_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.SET_PRODUCT_SEARCH_TEXT: {
      return {
        ...state,
        searchProductText: action.payload.searchText,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_LIST_START: {
      return {
        ...state,
        loading: true,
        catalogCombos: [],
      };
    }

    case CatalogueActionTypes.CATALOG_COMBO_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        catalogCombos: action.payload,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_CREATE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_DELETE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.MODIFIER_LIST_START: {
      return {
        ...state,
        modifierList: [],
        loading: true,
      };
    }
    case CatalogueActionTypes.MODIFIER_LIST_SUCCESS: {
      return {
        ...state,
        modifierList: action.payload.data,
        loading: false,
      };
    }
    case CatalogueActionTypes.MODIFIER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.MODIFIER_DETAIL_START: {
      return {
        ...state,
        modifierDetail: {},
        loading: true,
      };
    }
    case CatalogueActionTypes.MODIFIER_DETAIL_SUCCESS: {
      return {
        ...state,
        modifierDetail: action.payload,
        loading: false,
      };
    }
    case CatalogueActionTypes.MODIFIER_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.MODIFIER_CREATE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.MODIFIER_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.MODIFIER_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.MODIFIER_EDIT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.PRODUCT_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.MODIFIER_EDIT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.MODIFIER_DELETE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.MODIFIER_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.MODIFIER_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.SET_MODIFIER_SEARCH_TEXT: {
      return {
        ...state,
        searchModifierText: action.payload.searchText,
      };
    }

    case CatalogueActionTypes.GROUP_MODIFIER_LIST_START: {
      return {
        ...state,
        groupModifierList: [],
        loading: true,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_LIST_SUCCESS: {
      return {
        ...state,
        groupModifierList: action.payload.data,
        loading: false,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.GROUP_MODIFIER_DETAIL_START: {
      return {
        ...state,
        groupModifierDetail: {},
        loading: true,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_DETAIL_SUCCESS: {
      return {
        ...state,
        groupModifierDetail: action.payload,
        loading: false,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.GROUP_MODIFIER_CREATE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.GROUP_MODIFIER_EDIT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_EDIT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.GROUP_MODIFIER_DELETE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.GROUP_MODIFIER_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.SET_GROUP_MODIFIER_SEARCH_TEXT: {
      return {
        ...state,
        searchGroupModifierText: action.payload.searchText,
      };
    }
    case CatalogueActionTypes.PRODUCT_VISIBILITY_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.PRODUCT_VISIBILITY_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.PRODUCT_VISIBILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.GET_PRODUCT_SIZES_START: {
      return {
        ...state,
        loading: true,
        productSizes: [],
      };
    }
    case CatalogueActionTypes.GET_PRODUCT_SIZES_SUCCESS: {
      return {
        ...state,
        loading: false,
        productSizes: action.payload,
      };
    }

    case CatalogueActionTypes.GET_PRODUCT_SIZES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.CREATE_SIZE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.CREATE_SIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.CREATE_SIZE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.DELETE_SIZE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.DELETE_SIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.DELETE_SIZE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.UPDATE_SIZE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.UPDATE_SIZE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.UPDATE_SIZE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.ARRANGE_SIZES_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.ARRANGE_SIZES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case CatalogueActionTypes.ARRANGE_SIZES_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.EMPTY_SKU_LIST: {
      return {
        ...state,
        productSizes: [],
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_UPDATE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_UPDATE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CatalogueActionTypes.CATALOG_COMBO_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default CatalogueReducer;
