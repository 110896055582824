import { axiosInstance as axios } from "app/api/axios";
import { PromotionActionTypes } from "../redux/actionTypes";
import { PROMOTIONS_URL, getPromotionDetailUrl } from "app/api/Endpoint";
import { Promotion } from "app/models/Promotion";
import { successAlert, errorAlert } from "app/helpers/alerts";
import History from "@history";

export function setPromotionSearchText(event) {
  return {
    type: PromotionActionTypes.SET_PROMOTION_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
const getPromotionListFail = (dispatch, errorMessage) => {
  dispatch({
    type: PromotionActionTypes.PROMOTIONS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getPromotionListSuccess = (dispatch, data) => {
  dispatch({
    type: PromotionActionTypes.PROMOTIONS_LIST_SUCCESS,
    payload: data,
  });
};

export const getPromotionList = () => {
  return (dispatch) => {
    dispatch({
      type: PromotionActionTypes.PROMOTIONS_LIST_START,
    });

    axios
      .get(PROMOTIONS_URL)
      .then((res) => {
        getPromotionListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getPromotionListFail(dispatch, "There was an error connection");
      });
  };
};
const getPromotionDetailFail = (dispatch, errorMessage, page) => {
  dispatch({
    type: PromotionActionTypes.PROMOTION_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  History.push({
    pathname: `/catalogue/promotions`,
    state: { page },
  });
};

const getPromotionDetailSuccess = (dispatch, data) => {
  dispatch({
    type: PromotionActionTypes.PROMOTION_DETAIL_SUCCESS,
    payload: data,
  });
};
export const getPromotionDetail = (id: string, page: number) => {
  return (dispatch) => {
    dispatch({
      type: PromotionActionTypes.PROMOTION_DETAIL_START,
    });

    const url = getPromotionDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getPromotionDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getPromotionDetailFail(dispatch, "There was an error connection", page);
      });
  };
};

const createPromotionFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: PromotionActionTypes.PROMOTION_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createPromotionSuccess = (dispatch, data, isPos) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: PromotionActionTypes.PROMOTION_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: isPos ? "/pos/promotions" : "/catalogue/promotions",
    state: { page: data.page || 0 },
  });
};
export const createPromotion = (
  promotion: Promotion,
  isPos: boolean,
  page: number
) => {
  return (dispatch) => {
    dispatch({
      type: PromotionActionTypes.PROMOTION_CREATE_START,
    });
    axios
      .post(PROMOTIONS_URL, promotion)
      .then((res) => {
        const data = res.data;
        data.page = page;
        createPromotionSuccess(dispatch, data, isPos);
      })
      .catch(() => {
        createPromotionFail(dispatch, "There was an error connection");
      });
  };
};
const editPromotionFail = (dispatch, errorMessage) => {
  dispatch({
    type: PromotionActionTypes.CHANGE_PROMOTION_STATUS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editPromotionSuccess = (dispatch, data, isPos) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: PromotionActionTypes.CHANGE_PROMOTION_STATUS_SUCCESS,
    payload: data,
  });

  History.push({
    pathname: isPos ? "/pos/promotions" : "/catalogue/promotions",
    state: { page: data.page || 0 },
  });
};
export const editPromotion = (
  offer: Promotion,
  isPos: boolean,
  page: number
) => {
  return (dispatch) => {
    dispatch({
      type: PromotionActionTypes.CHANGE_PROMOTION_STATUS_START,
    });

    axios
      .put(PROMOTIONS_URL, offer)
      .then((res) => {
        const data = res.data;
        data.page = page;
        editPromotionSuccess(dispatch, data, isPos);
      })
      .catch(() => {
        editPromotionFail(dispatch, "There was an error connection");
      });
  };
};

const deletePromotionFail = (dispatch, errorMessage) => {
  dispatch({
    type: PromotionActionTypes.PROMOTION_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};
const deletePromotionSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getPromotionList());
  dispatch({
    type: PromotionActionTypes.PROMOTION_DELETE_SUCCESS,
    payload: data,
  });
};
export const deletePromotion = (idArray: String[], callback: () => void) => {
  return (dispatch) => {
    dispatch({
      type: PromotionActionTypes.PROMOTION_DELETE_START,
    });
    axios
      .delete(PROMOTIONS_URL, { data: { promotionIds: idArray } })
      .then((res) => {
        deletePromotionSuccess(dispatch, res.data);
        callback();
      })
      .catch(() => {
        deletePromotionFail(dispatch, "There was an error connection");
      });
  };
};
