import { TipsActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: TipState = {
  tipList: {
    tips: [],
    totalTip: 0,
  },
  loading: false,
  searchText: "",
  androidUsers: "",
  iosUsers: "",
  userDetails: {},
  totalTips: 0,
};

interface Action {
  payload: any;
  type: string;
}

const TipReducer = (
  state: TipState = INITIAL_STATE,
  action: Action
): TipState => {
  switch (action.type) {
    case TipsActionTypes.TIPS_LIST_START: {
      return { ...state, loading: true };
    }
    case TipsActionTypes.TIPS_LIST_SUCCESS: {
      const totalTips =
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.totalDocuments
          ? action.payload.data.totalDocuments
          : 0;
      return {
        ...state,
        tipList: action.payload.data,
        totalTips: totalTips,
        loading: false,
      };
    }
    case TipsActionTypes.TIPS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case TipsActionTypes.SET_TIPS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    default: {
      return state;
    }
  }
};

export default TipReducer;
