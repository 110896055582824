import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Modal,
  Backdrop,
  Fade,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import LoadingOverlay from "react-loading-overlay";
import CustomInput from "app/main/common/CustomInput";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { makeStyles } from "@material-ui/styles";
import SkuButton from "app/main/common/List/components/SkuButton";
import { useDebounce } from "@fuse/hooks";
import ComboCategoryForm from "./components/ComboCategoryForm";
import { getProductList } from "app/store/catalogue/CatalogueActions";
import CategoryList from "./components/CategoryList";
import {
  generateRandomString,
  getSortedAllergenes,
} from "app/helpers/utilsHelper";
import {
  createCombo,
  getComboDetail,
  editCombo,
  sortComboCategories,
} from "app/store/combos/CombosActions";
import { formatImageUrl } from "app/helpers/utilsHelper";
import ComboDetailHeader from "./components/ComboDetailHeader";
import { FuseUtils } from "@fuse";
import _ from "@lodash";
import { getMenuItemAllergenes } from "app/store/menu/MenuActions";
import History from "@history";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const DEFAULT_CATEGORY = {
  title: "",
  products: [],
  _id: "",
};
function ComboDetail(props) {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();
  const { comboId } = props.match.params;
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const { loading, comboDetail } = useSelector(({ banzzu }) => banzzu.combos);
  const page = History.location.state.page || 0;

  useEffect(() => {
    if (comboDetail._id) {
      setCategories(comboDetail.comboCategories);
    }
  }, [comboDetail]);
  const [openCategory, setOpenCategory] = useState(DEFAULT_CATEGORY);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (comboId !== "new") {
      dispatch(getComboDetail(comboId, page));
    }
  }, [dispatch, comboId]);

  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
    description: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);
  const { form: initialForm, setForm: setInitialForm } = useForm(null);
  const allergenesList = useSelector(
    ({ banzzu }) => banzzu.menu.menuItemAllergenes
  );
  const Allergenes = getSortedAllergenes({ allergenesList, languageStrings });
  useEffect(() => {
    if (
      (comboDetail && !form) ||
      (comboDetail && form && comboDetail._id !== form._id)
    ) {
      setForm(comboDetail);
      setInitialForm(comboDetail);
    }
  }, [form, comboDetail, setForm]);
  useEffect(() => {
    dispatch(getMenuItemAllergenes());
  }, [dispatch]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty() && hasNoSku();
  }

  //ERRORS
  function hasNoEmpty() {
    return (
      form.title !== "" &&
      form.title !== undefined &&
      form.price !== "" &&
      form.price !== undefined &&
      form.price !== undefined &&
      form.sku !== undefined &&
      form.sku !== "" &&
      categories !== undefined &&
      categories.length !== 0
    );
  }

  function hasNoSku() {
    if (form.sku !== undefined && form.sku !== "") {
      return true;
    }
    return false;
  }

  function hasNoErrors() {
    return !errorName();
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }
  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  useEffect(() => {
    if (user && user._id) dispatch(getProductList(user._id));
  }, [user]);

  const addProduct = (product, categoryId) => {
    setCategories((prevCategories) => {
      const updatedCategories = prevCategories.map((category) => {
        if (category._id === categoryId) {
          return {
            ...category,
            products: [...category.products, product],
          };
        }
        return category;
      });
      return updatedCategories;
    });
  };
  const deleteProduct = (product, categoryId) => {
    setCategories((prevCategories) => {
      const updatedCategories = prevCategories.map((category) => {
        if (category._id === categoryId) {
          return {
            ...category,
            products: category.products.filter(
              (p) => p.productDetail._id !== product._id
            ),
          };
        }
        return category;
      });
      return updatedCategories;
    });
  };
  const onSaveCategory = (title) => {
    const updatedCategories =
      openCategory._id !== ""
        ? categories.map((category) =>
            category._id === openCategory._id
              ? { ...category, title }
              : category
          )
        : [
            ...categories,
            {
              _id: generateRandomString(),
              title,
              products: [],
            },
          ];
    setCategories(updatedCategories);
    setOpen(false);
    setOpenCategory(DEFAULT_CATEGORY);
  };
  const onSaveCombo = () => {
    const comboData = {
      ...form,
      comboCategories: categories,
    };
    const action = comboId === "new" ? createCombo : editCombo;
    dispatch(action(comboData, page));
  };
  const onDeleteImage = () => {
    setForm((form) => ({
      ...form,
      image: null,
      imageFile: null,
      deleteImage: true,
    }));
  };
  const handleUploadChange = (e) => {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
          deleteImage: "false",
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  };
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let sortOrder = {
      from: source.index,
      to: destination.index,
      id: draggableId,
    };
    const { from, to } = sortOrder;

    const sortedCategoryList = [...categories];
    const moved = sortedCategoryList.splice(from, 1);
    sortedCategoryList.splice(to, 0, _.first(moved));
    setCategories(sortedCategoryList);
    dispatch(sortComboCategories(sortedCategoryList, languageStrings));
  };
  const handleAllergenesChange = (event) => {
    let allergenesFormatted;
    if (event.target.checked) {
      const allergenes = `${form.allergenes},${event.target.value},`;
      allergenesFormatted = _.replace(allergenes, ",,", ",");
    } else {
      const allergenes = _.replace(
        form.allergenes,
        `${event.target.value},`,
        ""
      );
      allergenesFormatted = _.replace(allergenes, ",,", ",");

      if (!_.startsWith(allergenesFormatted, ",")) {
        allergenesFormatted = "," + allergenesFormatted;
      }
      if (!_.endsWith(allergenesFormatted, ",")) {
        allergenesFormatted = allergenesFormatted + ",";
      }
    }

    setForm((form) => ({
      ...form,
      allergenes: allergenesFormatted,
    }));
  };
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <ComboDetailHeader
              disabled={!canBeSubmitted()}
              onSaveCombo={onSaveCombo}
              form={form}
              initialForm={initialForm}
              page={page}
            />
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24">
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    className={`${classes.paper} md:h-auto h-screen overflow-scroll`}
                  >
                    <h2 id="transition-modal-title" className="pb-32">
                      {languageStrings["NEW_CATEGORY"]}
                    </h2>
                    <ComboCategoryForm
                      initialTitle={openCategory.title}
                      onClose={(title) => {
                        onSaveCategory(title);
                      }}
                    />
                  </div>
                </Fade>
              </Modal>
              {tabValue === 0 && (
                <div className="p-16 sm:p-24 max-w-xl max-h-screen">
                  <div className="flex flex-row justify-center sm:justify-start flex-wrap">
                    <input
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                      className="hidden"
                      id={`button-file-${comboId}`}
                      type="file"
                      onChange={handleUploadChange}
                    />
                    <label
                      htmlFor={`button-file-${comboId}`}
                      className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                    >
                      <Icon fontSize="large" color="action">
                        cloud_upload
                      </Icon>
                    </label>
                    <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                      <img
                        className="max-w-none w-auto h-full"
                        alt="whatson"
                        src={
                          form.image
                            ? formatImageUrl(form.image)
                            : "assets/images/avatars/noimage.png"
                        }
                      />
                      {form.image && (
                        <Icon
                          onClick={onDeleteImage}
                          className="rounded-4 shadow-5 mb-2 text-2xl cursor-pointer absolute bottom-0"
                          style={{
                            color: "#f4516c",
                            backgroundColor: "#fff",
                          }}
                        >
                          delete
                        </Icon>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <CustomInput
                      className="mt-8 mb-16 mr-8"
                      required
                      label={languageStrings["GENERAL.TITLE"]}
                      id="title"
                      name="title"
                      value={form.title ? form.title : ""}
                      onValueChange={handleTitleChange}
                      errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
                      hasError={(error) =>
                        setDirty((prevState) => {
                          return { ...prevState, title: error };
                        })
                      }
                      minLength={3}
                    />

                    <TextField
                      className="mt-8 ml-8 mb-16"
                      label={languageStrings["GENERAL.PRICE"]}
                      required
                      id="price"
                      name="price"
                      type={"number"}
                      value={form.price || ""}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </div>

                  <div className="mt-12">
                    <div className="flex">
                      <CustomInput
                        className="mt-8 mb-16 mr-8"
                        required
                        label={languageStrings["CATALOGUE.SKU"]}
                        id="sku"
                        name="sku"
                        value={form && form.sku ? form.sku : ""}
                        onValueChange={handleChange}
                        errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
                        hasError={(error) =>
                          setDirty((prevState) => {
                            return { ...prevState, sku: error };
                          })
                        }
                      />
                      <SkuButton
                        onCreateRandomString={(value) => {
                          setForm((prevForm) => ({
                            ...prevForm,
                            sku: value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-16 pb-16 w-full">
                    <div className="w-full flex items-center">
                      <Button
                        className="mt-16 mb-16 bg-grey-lightest ml-20"
                        variant="contained"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <Icon className="mr-8">save</Icon>
                        {languageStrings["MENU_CATEGORY_PAGE.ADD_CATEGORY"]}
                      </Button>
                    </div>
                  </div>
                  <div className="mt-16 pb-16 w-full">
                    {categories && (
                      <CategoryList
                        onDragEnd={onDragEnd}
                        data={categories}
                        onDeleteCategory={(cat) => {
                          setCategories((prev) =>
                            prev.filter((c) => c._id !== cat._id)
                          );
                        }}
                        onCategoryUpdate={(cat) => {
                          setCategories((prevCategories) =>
                            prevCategories.map((category) =>
                              category._id === cat._id ? cat : category
                            )
                          );
                        }}
                        addProduct={addProduct}
                        deleteProduct={deleteProduct}
                        onEditCategory={(cat) => {
                          setOpenCategory(cat);
                          setOpen(true);
                        }}
                      />
                    )}
                    <div className="mt-20 mb-8">
                      <div className="font-bold">
                        {languageStrings["ALLERGENE.TITLE"]}
                      </div>
                      <FormGroup row>
                        {Allergenes.map((item) => (
                          <FormControlLabel
                            key={item.title}
                            control={
                              <Checkbox
                                checked={
                                  form.allergenes
                                    ? form.allergenes.includes(`,${item._id},`)
                                    : false
                                }
                                onChange={handleAllergenesChange}
                                value={item._id}
                              />
                            }
                            label={item.label}
                          />
                        ))}
                      </FormGroup>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("CombosReducer", reducer)(ComboDetail);
