import React from "react";
import { VISIBILITY } from "app/models/Visibility";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { Typography } from "@material-ui/core";

const VisibilityBanner = ({ visibility }) => {
  const { languageStrings } = useLanguageHelper();

  return (
    <>
      {visibility === VISIBILITY.DISABLED && (
        <span
          style={{ width: "4rem", fontSize: 10 }}
          className="bg-red p-6 rounded-12 text-white text-center"
        >
          {languageStrings["VISIBILITY.DISABLED"]}
        </span>
      )}

      {visibility === VISIBILITY.SHOW_ONLY && (
        <Typography
          variant="body1"
          style={{ maxWidth: "14rem", fontSize: 10 }}
          className="bg-yellow p-4 rounded-12 text-black text-center whitespace-no-wrap"
        >
          {languageStrings["VISIBILITY.PARTIALY_DISABLED"]}
        </Typography>
      )}
      {visibility === VISIBILITY.POS_DISCONNECTED && (
        <Typography
          variant="body1"
          style={{ maxWidth: "14rem", fontSize: 10 }}
          className="bg-orange-dark p-4 rounded-12 text-black text-center whitespace-no-wrap"
        >
          {languageStrings["DISCONNECTED_PRODUCTS"]}
        </Typography>
      )}
    </>
  );
};

export default VisibilityBanner;
