import React from "react";

import {
  FormControl,
  Typography,
  Select,
  OutlinedInput,
  InputLabel,
} from "@material-ui/core";

const HomeHeader = (props) => {
  return (
    <div className={"flex w-full items-center justify-between"}>
      <div className="flex items-center">
        <Typography className="sm:flex" variant="h6">
          {props.title}
        </Typography>
      </div>
      <div className="flex w-1/6">
        {props.selectors &&
          props.selectors.length > 0 &&
          props.selectors.map((selector, index) => (
            <div
              className={`flex flex-1 items-center justify-center px-12 ${
                props.isAutoWidth ? "flex-none" : "flex-1"
              }`}
            >
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  className="mt-10"
                  htmlFor="outlined-venue-native-simple"
                >
                  {selector.title}
                </InputLabel>
                <Select
                  className="mt-10 mb-16 mr-8"
                  native
                  required
                  style={{ maxHeight: "40px" }}
                  onChange={selector.onChange}
                  input={
                    <OutlinedInput
                      name="province"
                      labelWidth={100}
                      id="outlined-venue-native-simple"
                    />
                  }
                >
                  {index === 1 ? (
                    <option value="0">{selector.firstOption}</option>
                  ) : null}
                  {selector.values.map((value) => (
                    <option value={value[selector.keys[0]]}>
                      {value[selector.keys[1]]}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
      </div>
    </div>
  );
};

export default HomeHeader;
