import React from "react";
import _ from "@lodash";
import { Typography, Icon, Card } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useSelector } from "react-redux";
import { getTipAmount } from "app/helpers/utilsHelper";
import { formatDateTime } from "app/helpers/validators";
import useClientCurrency from "app/hooks/useClientCurrency";

const VirtualCardOrderStatusDetail = ({
  orderNumber,
  paymentsDetails,
  table,
  orderDetails = [],
}) => {
  const { languageStrings } = useLanguageHelper();
  const local = useSelector(({ banzzu }) => banzzu.auth.user.locale);
  const { totalTip = 0 } = getTipAmount(paymentsDetails);
  const { clientCurrency } = useClientCurrency();
  const paymentTypeMethod = (type) => {
    let paymentType = "Partial Payment";
    if (type === "Partial Payment") {
      paymentType = languageStrings["PARTIAL_PAYMENT"];
    } else if (type === "Full Payment") {
      paymentType = languageStrings["FULL_PAYMENT"];
    } else {
      paymentType = languageStrings["PAID_ON_VENUE"];
    }
    return paymentType;
  };
  return (
    <div className="pl-16 pr-16 pb-48 flex-1">
      <Card className="p-16">
        <div className="pb-16 flex items-center">
          <Typography className="h2" color="textSecondary">
            {languageStrings["VIRTUAL_CARD_ORDER_NUMBER"]}: {orderNumber}
          </Typography>
        </div>
        {totalTip ? (
          <div className="pb-16 flex items-center">
            <Icon className="mr-16" color="action">
              attach_money
            </Icon>
            <Typography className="h2" color="textSecondary">
              {languageStrings["TIP"]}: {totalTip} {clientCurrency.symbol}
            </Typography>
          </div>
        ) : null}
        {table && (
          <div className="pb-16 flex items-center">
            <Icon className="mr-16" color="action">
              local_dining
            </Icon>
            <Typography className="h2" color="textSecondary">
              {`${languageStrings["VIRTUAL_CARD_ORDER_TABLE"]} : ${
                table.title || ""
              }`}
            </Typography>
          </div>
        )}
        <div className="table-responsive">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["GENERAL.STATUS"]}</th>
                <th>{languageStrings["GENERAL.AMOUNT"]}</th>
                <th>{languageStrings["GENERAL.DATE"]}</th>
              </tr>
            </thead>
            <tbody>
              {paymentsDetails &&
                paymentsDetails.map((payment) => {
                  return (
                    <tr key={payment.id}>
                      <td>
                        <Typography>
                          {paymentTypeMethod(payment.paymentType)}
                        </Typography>
                      </td>
                      <td>
                        <Typography>
                          {Number(payment.amount).toFixed(2)} {clientCurrency.symbol}
                        </Typography>
                      </td>
                      <td>
                        <Typography>
                          {payment.updatedAt
                            ? formatDateTime(payment.updatedAt)
                            : ""}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
      <Card className="p-16 mt-10">
        <div className="pb-16 flex items-center">
          <Typography className="h2" color="textSecondary">
            {languageStrings["BILL_DETAIL"]}
          </Typography>
        </div>
        <div className="table-responsive">
          <table className="simple">
            <thead>
              <tr>
                <th>{languageStrings["GENERAL.REF"]}</th>
                <th>{languageStrings["GENERAL.DATE"]}</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails &&
                orderDetails.map((order) => {
                  return (
                    <tr key={order.id}>
                      <td>
                        <Typography>{order.referenceNo}</Typography>
                      </td>
                      <td>
                        <Typography>
                          {order.createdAt
                            ? formatDateTime(order.createdAt)
                            : ""}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default VirtualCardOrderStatusDetail;
