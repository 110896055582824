import { LoyaltyProgramActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: LoyaltyClientState = {
  loyaltyClientList: [],
  loyaltyClientDetail: {
    loyaltyClientId: "",
    venueTitle: "",
    email: "",
    category: "",
    name: "",
    clientAverage: 0,
    clientTotal: 0,
    venueAverage: 0,
  },
  loading: false,
  searchText: "",
  totalClients: 0,
};

interface Action {
  payload: any;
  type: string;
}
const LoyaltyProgramReducer = (
  state: LoyaltyClientState = INITIAL_STATE,
  action: Action
): LoyaltyClientState => {
  switch (action.type) {
    case LoyaltyProgramActionTypes.GET_LOYALTY_LIST_START: {
      return { ...state, loading: true, loyaltyClientList: [] };
    }
    case LoyaltyProgramActionTypes.GET_LOYALTY_LIST_SUCCESS: {
      const list =
        action.payload &&
        action.payload.data &&
        action.payload.data.loyaltyClients
          ? action.payload.data.loyaltyClients
          : [];
      const totalDocuments =
        action.payload &&
        action.payload.data &&
        action.payload.data.totalDocuments
          ? action.payload.data.totalDocuments
          : 0;
      return {
        ...state,
        loyaltyClientList: list,
        totalClients: totalDocuments,
        loading: false,
      };
    }
    case LoyaltyProgramActionTypes.GET_LOYALTY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case LoyaltyProgramActionTypes.GET_LOYALTY_CLIENT_DETAIL_START: {
      return { ...state, loading: true };
    }
    case LoyaltyProgramActionTypes.GET_LOYALTY_CLIENT_DETAIL_SUCCESS: {
      const list = action.payload;
      return {
        ...state,
        loyaltyClientDetail: list,
        loading: false,
      };
    }
    case LoyaltyProgramActionTypes.GET_LOYALTY_CLIENT_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case LoyaltyProgramActionTypes.SET_LOYALTY_CLIENT_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }
    default: {
      return state;
    }
  }
};

export default LoyaltyProgramReducer;
