import React from "react";
import { TableCell } from "@material-ui/core";
import { getTypeText } from "app/helpers/utilsHelper";

const TypeText = ({ n, obj, type }) => {
  return (
    <TableCell
      component="th"
      scope="row"
      style={type === "loyalty" ? { textAlign: "center" } : {}}
    >
      <div>{getTypeText(n[obj.id])}</div>
    </TableCell>
  );
};

export default TypeText;
