import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import { getVenueList } from "app/store/venues/VenuesActions";
import LoadingOverlay from "react-loading-overlay";
import {
  getOfferDetail,
  createOffer,
  editOffer,
} from "app/store/offers/OffersActions";
import clsx from "clsx";
import { FuseUtils } from "@fuse";
import History from "@history";
import * as Actions from "app/store/actions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import AlertDialog from "../common/AlertDialog";
import { formatImageUrl } from "app/helpers/utilsHelper";
import useClientCurrency from "app/hooks/useClientCurrency";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));

function OfferDetail(props) {
  const classes = useStyles();
  const { clientCurrency } = useClientCurrency();
  const dispatch = useDispatch();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const loading = useSelector(({ banzzu }) => banzzu.offer.loading);
  let offer = useSelector(({ banzzu }) => banzzu.offer.offerDetail);
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { productId } = props.match.params;
  const { languageStrings } = useLanguageHelper();

  useEffect(() => {
    if (productId !== "new") {
      dispatch(getOfferDetail(productId));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);

  if (productId === "new") {
    offer = {
      id: "0",
      venueId: "0",
      title: "",
      description: "",
      password: "",
      originalPrice: "",
      reducedPrice: "",
      hidePrice: false,
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
    description: "",
    originalPrice: "",
    reducedPrice: "",
  };

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  const { form, handleChange, setForm } = useForm(null);
  const {
    form: initialForm,
    handleChange: initialFormHandleChange,
    setForm: setInitialForm,
  } = useForm(null);

  useEffect(() => {
    if ((offer && !form) || (offer && form && offer._id !== form._id)) {
      setForm(offer);
      setInitialForm(offer);
    }
  }, [form, offer, setForm, setInitialForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty() && !form.onlyRead;
  }

  //ERRORS
  function hasNoEmpty() {
    return (
      form.title !== "" &&
      form.email !== "" &&
      form.description !== "" &&
      (form.hidePrice ||
        (form.originalPrice !== "" && form.originalPrice !== ""))
    );
  }

  function hasNoErrors() {
    return (
      !errorName() &&
      !errorDescription() &&
      !errorPriceReduced() &&
      !errorPriceOriginal() &&
      !errorImage()
    );
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  function errorPriceOriginal() {
    return (
      (!form.hidePrice && dirty.originalPrice && !Number(form.originalPrice)) ||
      Number(form.originalPrice) < Number(form.reducedPrice)
    );
  }

  function errorPriceReduced() {
    return (
      (!form.hidePrice && dirty.reducedPrice && !Number(form.reducedPrice)) ||
      Number(form.originalPrice) < Number(form.reducedPrice)
    );
  }

  function errorImage() {
    return !form.image;
  }

  function errorDescription() {
    return dirty.description && form.description.length < 10;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleTitleChange(event) {
    event.preventDefault();
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handlePriceOriginalChange(event) {
    event.preventDefault();
    handleChange(event);
    debouncedPriceOriginal(event.target.value, form);
  }

  const debouncedPriceOriginal = useDebounce((originalPrice, form) => {
    if (originalPrice != 0) {
      setDirty((prevState) => {
        return { ...prevState, originalPrice: true };
      });

      if (!Number(originalPrice)) {
        setError((prevState) => {
          return {
            ...prevState,
            originalPrice: languageStrings["ERRORS.MUST_BE_NUMBER"],
          };
        });
      }

      if (
        !form.priceReduced ||
        Number(form.reducedPrice) > Number(originalPrice)
      ) {
        setError((prevState) => {
          return {
            ...prevState,
            originalPrice: languageStrings["ERRORS.ORIGINAL_PRICE_SMALLER"],
          };
        });
      }
    }
  }, 500);

  function handlePriceReducedChange(event) {
    handleChange(event);
    debouncedPriceReduced(event.target.value, form);
  }

  const debouncedPriceReduced = useDebounce((reducedPrice, form) => {
    if (reducedPrice != 0) {
      setDirty((prevState) => {
        return { ...prevState, reducedPrice: true };
      });
      if (!Number(reducedPrice)) {
        setError((prevState) => {
          return {
            ...prevState,
            reducedPrice: languageStrings["ERRORS.MUST_BE_NUMBER"],
          };
        });
      }

      if (
        !form.originalPrice ||
        Number(reducedPrice) > Number(form.originalPrice)
      ) {
        setError((prevState) => {
          return {
            ...prevState,
            reducedPrice: languageStrings["ERRORS.REDUCED_PRICE_GREATER"],
          };
        });
      }
    }
  }, 500);

  function handleDescriptionChange(event) {
    handleChange(event);
    debouncedDescription(event.target.value);
  }

  const debouncedDescription = useDebounce((description) => {
    setDirty((prevState) => {
      return { ...prevState, description: true };
    });
    if (description.length < 10) {
      setError((prevState) => {
        return {
          ...prevState,
          description: languageStrings["ERRORS.MINIMUM_10_CHAR"],
        };
      });
    }
  }, 500);

  function goBack() {
    History.push({
      pathname: `/offerList`,
    });
  }

  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };

  const onSubmit = () => {
    if (form.id !== "0") {
      dispatch(editOffer(form));
    } else {
      let finalForm = {
        ...form,
      };
      if (
        user &&
        user.permission &&
        !user.permission.isMaster &&
        venueList.length &&
        form.venueId == "0"
      ) {
        finalForm = {
          ...form,
          venueId: venueList[0]._id,
        };
      }
      dispatch(createOffer(finalForm));
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["OFFER_DETAIL_PAGE.BACK_BUTTON"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["OFFER_DETAIL_PAGE.NEW_OFFER"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["OFFER_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={onSubmit}
                >
                  <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                    save
                  </Icon>
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24">
              <div>
                <input
                  accept="image/jpg, image/jpeg, image/png, image/gif"
                  className="hidden"
                  id="button-file"
                  type="file"
                  disabled={form.onlyRead}
                  onChange={handleUploadChange}
                />
                <div className="flex justify-center sm:justify-start flex-wrap">
                  <label
                    htmlFor="button-file"
                    className={clsx(
                      form.onlyRead ? classes.enable : classes.disabled,
                      "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                    )}
                  >
                    <Icon fontSize="large" color="action">
                      cloud_upload
                    </Icon>
                  </label>
                  {
                    <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                      <img
                        className="max-w-none w-auto h-full"
                        alt="offer"
                        src={
                          form.image
                            ? formatImageUrl(form.image)
                            : "assets/images/avatars/noimage.png"
                        }
                      />
                    </div>
                  }
                  {errorImage() && (
                    <div className="text-red">
                      {languageStrings["ERRORS.IMAGE_IS_REQUIRED"]}
                    </div>
                  )}
                </div>
                <div className="flex">
                  <TextField
                    className="mt-8 mb-16 mr-8"
                    error={errorName()}
                    required
                    label={languageStrings["GENERAL.TITLE"]}
                    id="title"
                    name="title"
                    disabled={form.onlyRead}
                    value={form.title}
                    onChange={handleTitleChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      maxLength: 100,
                    }}
                    helperText={errorName() ? error.title : ""}
                  />

                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-venue-native-simple">
                      {languageStrings["OFFER_DETAIL_PAGE.SELECT_VENUE"]}
                    </InputLabel>
                    <Select
                      className="flex mt-8 mb-16 ml-8 min-w-128"
                      native
                      disabled={form.onlyRead}
                      value={form.venueId ? form.venueId : "0"}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          name="venueId"
                          labelWidth={100}
                          id="outlined-venue-native-simple"
                        />
                      }
                    >
                      {user.permission && user.permission.isMaster && (
                        <option value={0}>
                          {languageStrings["GENERAL.ALL_VENUES"]}
                        </option>
                      )}

                      {venueList &&
                        venueList.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.title}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="flex">
                  <FormControlLabel
                    className="mr-32 min-w-128"
                    control={
                      <Checkbox
                        disabled={form.onlyRead}
                        checked={form.hidePrice ? true : false}
                        onChange={handleChange}
                        value="hidePrice"
                        id="hidePrice"
                        name="hidePrice"
                        color="primary"
                      />
                    }
                    label={languageStrings["OFFER_DETAIL_PAGE.DISABLE_PRICE"]}
                  />

                  <TextField
                    className="mt-8 mb-16 mr-8"
                    error={errorPriceOriginal()}
                    required
                    label={languageStrings["GENERAL.ORIGINAL_PRICE"]}
                    id="originalPrice"
                    name="originalPrice"
                    value={form.originalPrice}
                    onChange={handlePriceOriginalChange}
                    disabled={form.hidePrice}
                    helperText={errorPriceOriginal() ? error.originalPrice : ""}
                    InputProps={{
                      maxLength: 15,
                      disabled: form.onlyRead,
                      startAdornment: (
                        <InputAdornment position="start">
                          {clientCurrency.symbol}
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    className="mt-8 mb-16 ml-8"
                    required
                    error={errorPriceReduced()}
                    label={languageStrings["GENERAL.REDUCED_PRICE"]}
                    id="reducedPrice"
                    name="reducedPrice"
                    value={form.reducedPrice}
                    onChange={handlePriceReducedChange}
                    disabled={form.hidePrice}
                    InputProps={{
                      maxLength: 15,
                      disabled: form.onlyRead,
                      startAdornment: (
                        <InputAdornment position="start">
                          {clientCurrency.symbol}
                        </InputAdornment>
                      ),
                    }}
                    helperText={errorPriceReduced() ? error.reducedPrice : ""}
                    variant="outlined"
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        {clientCurrency.symbol}
                      </InputAdornment>
                    }
                  />
                </div>
                <TextField
                  className="mt-8 mb-16 mr-8"
                  error={errorDescription()}
                  required
                  disabled={form.onlyRead}
                  label={languageStrings["GENERAL.DESCRIPTION"]}
                  multiline
                  rows={8}
                  id="description"
                  name="description"
                  value={form.description}
                  onChange={handleDescriptionChange}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    maxLength: 4000,
                  }}
                  helperText={errorDescription() ? error.description : ""}
                />
              </div>
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("VenuesReducer", reducer)(OfferDetail);
