import PromotionsPOSDetails from "./PromotionsPOSDetails";
import PromotionsPOSList from "./PromotionsPOSList";

export const PromotionsPOSConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/pos/promotions",
      component: PromotionsPOSList,
    },
    {
      path: "/pos/promotionDetail/:promotionId",
      component: PromotionsPOSDetails,
    },
  ],
};
