import React, { useEffect, useState } from "react";
import { Switch, Icon, Typography } from "@material-ui/core";
import { formatImageUrl } from "app/helpers/utilsHelper";
import { useDispatch, useSelector } from "react-redux";
import { getVenuePopup } from "app/store/venuePopup/VenuePopupActions";
import { FuseUtils } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper";

const PopupTab = ({ form, setForm }) => {
  const { languageStrings } = useLanguageHelper();
  const venuePopup = useSelector((state) => state.banzzu.VenuePopupReducer);
  const dispatch = useDispatch();
  const [isVenuePopupActive, setIsVenuePopupActive] = useState(
    venuePopup.venuePopupActive
  );
  useEffect(() => {
    if (form._id) {
      dispatch(getVenuePopup(form._id));
    }
  }, [dispatch, form._id]);

  useEffect(() => {
    venuePopup &&
      form &&
      form._id &&
      setForm((prevForm) => ({
        ...prevForm,
        popupData: {
          ...prevForm.popupData,
          venueId: form._id,
          venuePopupActive: venuePopup.venuePopupActive,
          popupImage: venuePopup.popupImage,
        },
      }));
    setIsVenuePopupActive(venuePopup.venuePopupActive);
  }, [venuePopup.venuePopupActive]);

  const handlePopupChange = (event) => {
    const { checked } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      popupData: { ...prevForm.popupData, venuePopupActive: checked },
    }));
    setIsVenuePopupActive(checked);
  };

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        setForm((prevForm) => ({
          ...prevForm,
          popupData: {
            ...prevForm.popupData,
            popupImage: `data:${file.type};base64,${btoa(
              reader.result.toString()
            )}`,
            popupImageFile: file,
          },
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  return (
    <>
      <div className="flex items-center">
        <Typography>{languageStrings["POPUP_SWITCH"]}</Typography>
        {venuePopup && (
          <Switch
            defaultChecked={form.popupData.venuePopupActive}
            defaultValue={form.popupData.venuePopupActive}
            checked={form.popupData && form.popupData.venuePopupActive}
            value={form.popupData && form.popupData.venuePopupActive}
            onChange={(event) => handlePopupChange(event)}
            size="small"
            disabled={false}
          />
        )}
      </div>
      {form.popupData && form.popupData.venuePopupActive && (
        <div>
          <input
            accept="image/jpg, image/jpeg, image/png, image/gif"
            className="hidden"
            id="button-file"
            type="file"
            onChange={(e) => handleUploadChange(e)}
          />
          <div className="flex justify-center sm:justify-start flex-row mt-24">
            <label
              htmlFor="button-file"
              className={
                "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 "
              }
            >
              <Icon fontSize="large" color="action">
                cloud_upload
              </Icon>
            </label>
            <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
              <img
                className="max-w-none w-auto h-full"
                alt="popup"
                src={
                  form.popupData && form.popupData.popupImage
                    ? formatImageUrl(form.popupData.popupImage)
                    : "assets/images/avatars/noimage.png"
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupTab;
