import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import ListTablePaginated from "../common/List/ListTablePaginated";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import history from "@history";
import { getTipList, setTipsSearchText } from "app/store/tip/TipActions";
import ListHeader from "app/main/common/List/ListHeader";
import DateRange from "./components/DateRange";
import moment from "moment";
import { setListLimitPage } from "app/store/pagination/paginationActions";
import { Pagination } from "app/models/Pagination";

const _start = new Date();
_start.setMonth(_start.getMonth() - 1);

function TipList() {
  const { searchText, tipList, totalTips, loading } = useSelector(
    ({ banzzu }) => banzzu.tip
  );
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { tipList: tipListPagination } = useSelector(
    ({ banzzu }) => banzzu.pagination
  );
  const { limit = 10, page = 0 } = tipListPagination;
  const [rowsPerPage, setRowsPerPage] = useState(limit);
  const [pageNo, setPageNo] = useState(page);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [allTips, setAllTips] = useState([]);
  const [startDate, setStartDate] = useState(_start);
  const [endDate, setEndDate] = useState(new Date());
  const [order, setOrder] = useState({
    direction: "asc",
    id: null,
  });
  const listType = Pagination.TIP;

  useEffect(() => {
    if (user && user._id) {
      const start = startDate;
      const end = endDate;
      dispatch(
        getTipList(user._id, {
          startDate: start,
          endDate: end,
          limit: rowsPerPage,
          page: pageNo,
          order,
        })
      );
    }
  }, [user, order, rowsPerPage, pageNo]);
  const _tips =
    tipList && tipList.tips && tipList.tips.length ? tipList.tips : [];
  const _totalTips = tipList && tipList.totalTip ? tipList.totalTip : 0;
  useEffect(() => {
    setAllTips(_tips);
  }, [tipList]);

  const handleClick = (item) => {
    history.push("/userDetail/" + item._id);
  };
  const { languageStrings } = useLanguageHelper();
  const setSearchText = (ev) => {
    dispatch(setTipsSearchText(ev));
  };
  const headerRows = {
    data: [
      {
        id: "referenceNo",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.REF"],
        sort: true,
        type: "text",
      },
      {
        id: "amount",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.PRICE"],
        sort: false,
        type: "priceCell",
      },

      {
        id: "venueTitle",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.VENUE"],
        sort: true,
        type: "text",
      },
      {
        id: "tableTitle",
        align: "left",
        disablePadding: false,
        label: languageStrings["VIRTUAL_CARD_ORDER_TABLE"],
        sort: true,
        type: "text",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.DATE"],
        sort: true,
        type: "date",
      },
    ],
    checkbox: false,
    clickable: false,
    locationSearch: false,
    searchBy: ["referenceNo"],
  };
  const getDateSelectedtext = () => {
    let date = "";
    if (startDate instanceof Date && endDate instanceof Date) {
      const _start = moment(startDate).format("DD/MM/YYYY");
      const _end = moment(endDate).format("DD/MM/YYYY");
      date = `${_start} - ${_end}`;
    }

    return date;
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setListLimitPage(dispatch, rowsPerPage, page, listType);
  };

  const handlePageChange = (event, page) => {
    setPageNo(page);
    setListLimitPage(dispatch, rowsPerPage, page, listType);
  };

  const handleRequestSort = (event, property) => {
    const id = property;
    let direction = "desc";

    if (order.id === property && order.direction === "desc") {
      direction = "asc";
    }

    setOrder({
      direction,
      id,
    });
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                <ListHeader
                  date={getDateSelectedtext()}
                  title={languageStrings["TOTAL_TIPS"]}
                  setSearchText={setSearchText}
                  searchType={"tip"}
                  tipButton
                  onTipPress={() => {
                    setShowModal(true);
                  }}
                  totalTips={_totalTips}
                />
              </div>
            }
            content={
              <ListTablePaginated
                handleClick={handleClick}
                headerRows={headerRows}
                data={allTips}
                searchText={searchText}
                totalData={totalTips}
                order={order}
                page={pageNo}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handlePageChange={handlePageChange}
                handleRequestSort={handleRequestSort}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      <DateRange
        onDateSelect={(start, end) => {
          if (start && end) {
            setStartDate(start);
            setEndDate(end);
          }
        }}
        onCloseModal={() => {
          setShowModal(false);
        }}
        open={showModal}
        order={order}
        page={pageNo}
        limit={rowsPerPage}
      />
    </>
  );
}

export default TipList;
