import React from "react";
import { Typography } from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

const HeaderButton = ({ heading, bg, borderColor }) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <div
      className={`w-full py-12 md:py-8 xxl:py-32 text-center font-product_sans `}
      style={{
        backgroundColor: bg,
        boxShadow: `${borderColor} 6px 6px`,
        borderRadius: "20px 20px 25px 20px",
      }}
    >
      <Typography
        className="text-white font-700 font-product_sans"
        style={{ fontSize: "1.5vw" }}
      >
        {languageStrings[heading]}
      </Typography>
    </div>
  );
};

export default HeaderButton;
