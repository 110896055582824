import React from "react";
import clsx from "clsx";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

export const orderTypes = [
  {
    id: 0,
    name: "ORDER_TYPE.CASH",
    color: "#FFF",
    bgColor: "#1F9D55",
  },
  {
    id: 1,
    name: "ORDER_TYPE.COLLECT",
    color: "#FFF",
    bgColor: "#1F9D55",
  },
  {
    id: 2,
    name: "ORDER_TYPE.DELIVERY",
    color: "#FFF",
    bgColor: "#F6993F",
  },
  {
    id: 3,
    name: "ORDER_TYPE.ORDER_FROM_TABLE",
    color: "#FFF",
    bgColor: "#0398fc",
  },
  {
    id: 4,
    name: "ORDER_TYPE.FUTURE_ORDER",
    color: "#FFF",
    bgColor: "#F699",
  },
];

const OrdersType = (props) => {
  const { languageStrings } = useLanguageHelper();

  const orderType = _.find(orderTypes, { id: Number(props.orderType) });

  return (
    <div
      style={{
        display: "inline",
        padding: " 0.4rem",
        color: orderType.color,
        backgroundColor: orderType.bgColor,
      }}
      className="inline text-12 rounded truncate"
    >
      {languageStrings[orderType.name]}
    </div>
  );
};

export default OrdersType;
