import React, { useState } from "react";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import ListTable from "app/main/common/List/ListTable";
import { getOrderList } from "app/store/orders/OrdersActions";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Backdrop, Button, Fade, Modal, TextField } from "@material-ui/core";
import { useDebounce, useForm } from "@fuse/hooks";
import {
  addAutoServices,
  deleteAutoService,
  updateAutoService,
} from "app/store/venues/VenuesActions";
import AlertDialog from "app/main/common/AlertDialog";
import * as Actions from "app/store/actions";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    borderRadius: 5,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
    padding: "16px 32px 24px",
    width: "30rem",
  },
}));
export const AutoServices = ({ productId }) => {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();
  let venue = useSelector(({ banzzu }) => banzzu.venue.venueDetail);

  const [open, setOpen] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [serviceUpdate, setServiceUpdate] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const classes = useStyles();
  const { form, handleChange, setForm } = useForm({
    firstRange: 0,
    lastRange: 0,
    extraTime: 0,
  });

  const onSubmitRanges = () => {
    if (serviceUpdate) {
      dispatch(updateAutoService(venue._id, form));
    } else {
      dispatch(addAutoServices(venue._id, form));
    }
    setForm({ firstRange: 0, lastRange: 0, extraTime: 0 });
    setOpen(false);
    setServiceUpdate(false);
  };
  const handleDeleteServiceClick = (item) => {
    dispatch(
      Actions.openDialog({
        children: (
          <AlertDialog
            onSuccess={() => {
              dispatch(deleteAutoService(item._id, venue._id));

              Actions.closeDialog();
            }}
            title={languageStrings["AUTOSERVICE_ALERT_TITLE"]}
            message={languageStrings["AUTOSERVICE_ALERT_BODY"]}
          />
        ),
      })
    );
    // setSelectedServiceId(item.id);
  };
  const handleEditServiceClick = (item) => {
    setServiceUpdate(true);
    setForm(item);
    setOpen(true);
  };

  const headerRows = {
    data: [
      {
        id: "range",
        align: "left",
        disablePadding: true,
        label: languageStrings["RANGE"],
        sort: false,
        type: "range",
      },
      {
        id: "extraTime",
        align: "left",
        disablePadding: true,
        label: languageStrings["EXTRA_TIME"],
        sort: false,
        type: "text",
      },
      {
        id: [""],
        align: "left",
        disablePadding: false,
        label: languageStrings["BEHAVIOUR"],
        actions: [
          {
            iconName: "edit",
            onclick: handleEditServiceClick,
          },

          {
            iconName: "deleteOutline",
            onclick: handleDeleteServiceClick,
          },
        ],
        sort: false,
        type: "actions",
      },
    ],
    checkbox: false,
    clickable: false,
    locationSearch: false,
    searchBy: [""],
  };
  const canBeSubmitted = () => {
    return (
      form &&
      form.firstRange &&
      form.lastRange &&
      form.extraTime &&
      form.extraTime > 0 &&
      Number(form.lastRange) > Number(form.firstRange)
    );
  };

  const handleClick = () => {};
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          {languageStrings["ADD_RANGE"]}
        </Button>
      </div>
      <ListTable
        handleClick={handleClick}
        headerRows={headerRows}
        data={venue.autoServicesList}
        searchText={""}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title" className="pb-32">
              {/* {languageStrings["MENU_CATEGORY_PAGE.ADD_CATEGORY"]} */}
            </h2>
            <p id="transition-modal-description">
              <TextField
                className="mt-8 mb-16 mr-8"
                required
                label={"First Range"}
                id="firstRange"
                name="firstRange"
                type="number"
                value={form.firstRange}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: 100,
                }}
              />
              <TextField
                className="mt-8 mb-16 mr-8"
                required
                label={"Last Range"}
                id="lastRange "
                name="lastRange"
                type="number"
                value={form.lastRange}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: 100,
                }}
              />
              <TextField
                className="mt-8 mb-16 mr-8"
                required
                label={languageStrings["EXTRA_TIME"]}
                id="extraTime"
                name="extraTime"
                type="number"
                value={form.extraTime}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                inputProps={{
                  maxLength: 100,
                }}
              />
            </p>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className="whitespace-no-wrap"
                variant="contained"
                disabled={!canBeSubmitted()}
                onClick={onSubmitRanges}
              >
                {languageStrings["GENERAL.SAVE"]}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
