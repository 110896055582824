import React, { useEffect } from "react";
import { FuseAuthorization, FuseLayout, FuseTheme } from "@fuse";
import Provider from "react-redux/es/components/Provider";
import { LanguageProvider } from "./helpers/LanguageHelper/index";
import { Router } from "react-router-dom";
import jssExtend from "jss-extend";
import history from "@history";
import { Auth } from "./auth";
import store from "./store";
import AppContext from "./AppContext";
import routes from "./fuse-configs/routesConfig";
import { create } from "jss";
import {
  StylesProvider,
  jssPreset,
  createGenerateClassName,
} from "@material-ui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";

import FCMNotification from "../app/main/common/FCMNotification";
import { clientsSettings } from "config";
import { SocketProvider } from "./SocketContext";
const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const generateClassName = createGenerateClassName();

const App = () => {
  useEffect(() => {
    hotjar.initialize(2389654);
    const messaging = firebase.messaging.isSupported()
      ? firebase.messaging()
      : null;
    clientsSettings.showNotificationAlert &&
      messaging &&
      messaging.onMessage((message) => {
        const { title, click_action } = message.notification;
        toast(<FCMNotification title={title} url={click_action} />, {
          closeButton: false,
          closeOnClick: false,
        });
      });
  }, []);

  history.listen((location) => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
      ReactGA.pageview(location.pathname + location.search);
    }
  });

  return (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <SocketProvider>
            <LanguageProvider initialLocale={"en"}>
              <Auth>
                <Router history={history}>
                  <FuseAuthorization>
                    <FuseTheme>
                      <FuseLayout />
                      <ToastContainer />
                    </FuseTheme>
                  </FuseAuthorization>
                </Router>
              </Auth>
            </LanguageProvider>
          </SocketProvider>
        </Provider>
      </StylesProvider>
    </AppContext.Provider>
  );
};

export default App;
