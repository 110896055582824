import React, { useState, useRef } from "react";
import clsx from "clsx";
import {
  Card,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Input,
  Select,
  Typography,
} from "@material-ui/core";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useLanguageHelper } from "app/helpers/LanguageHelper/useLanguageHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0.4rem",
    marginBottom: "0.4rem",
    "& .Mui-focused": {
      color: theme.palette.primary.dark,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.primary.dark,
    },
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

const PaymentCard = ({ onBack, onSubmit, showText }) => {
  const Classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const [state, setState] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
    month: "01",
    year: "20",
  });
  const [focus, setFocus] = useState("");
  const [numberError, setnumberError] = useState(true);
  const [cvcError, setCvcError] = useState(true);
  const [nameError, setNameError] = useState(true);

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  const canBeSubmited = () => {
    return !numberError && !cvcError && !nameError;
  };

  const handleInputChange = (e) => {
    if (e.target.name === "number") {
      e.target.value = Math.max(0, parseInt(e.target.value))
        .toString()
        .slice(0, 16);
      if (e.target.value.length == 16) {
        setnumberError(false);
      } else {
        setnumberError(true);
      }
    }

    if (e.target.name === "cvc") {
      e.target.value = e.target.value.toString().slice(0, 3);

      if (e.target.value.length !== 3) {
        setCvcError(true);
      } else {
        setCvcError(false);
      }
    }

    if (e.target.name === "name") {
      if (e.target.value.length > 5) {
        setNameError(false);
      } else {
        setNameError(true);
      }
    }

    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const saveCardDetails = () => {
    const cardDetails = {
      cardNumber: state.number,
      expMonth: state.month,
      expYear: state.year,
      cvc: state.cvc,
    };

    onSubmit(cardDetails);
  };

  return (
    <Card
      className="mx-24 mt-12 mb-24 md:mb-0 p-24 justify-center shadow-lg md:w-1/3 w-full"
      style={{ borderRadius: "2rem" }}
    >
      {showText && (
        <Typography variant="body1" className="text-center mt-12 px-32">
          {languageStrings["PRICING.YOU_WILL_NOT_BE"]}
        </Typography>
      )}
      <div className="mx-6 flex flex-col items-center justify-center mt-5">
        <Cards
          cvc={state.cvc}
          expiry={state.expiry}
          focused={focus}
          name={state.name}
          number={state.number}
        />
        <div className="mt-24 flex flex-col items-center w-full px-32">
          <TextField
            label={languageStrings["GENERAL.FULL_NAME"]}
            name="name"
            className={clsx(Classes.root, "w-full")}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
          />

          <TextField
            label={languageStrings["GENERAL.NUMBER"]}
            name="number"
            type="number"
            value={state.number}
            className={clsx(Classes.root, Classes.number, "w-full")}
            onFocus={handleInputFocus}
            onChange={handleInputChange}
            inputProps={{ maxLength: 16 }}
          />

          <div className="flex justify-between">
            <FormControl
              className={clsx(Classes.rootMonth, "w-1/4")}
              variant={"outlined"}
            >
              <InputLabel className="mt-4 -ml-6" htmlFor={"month"}>
                {"MM"}
              </InputLabel>
              <Select
                name="month"
                native
                value={state.month}
                onChange={handleInputChange}
                input={<Input id="month" />}
              >
                {[
                  "01",
                  "02",
                  "03",
                  "04",
                  "05",
                  "06",
                  "07",
                  "08",
                  "09",
                  "10",
                  "11",
                  "12",
                ].map((month) => {
                  return (
                    <option className="text-center mt-6 mb-6" value={month}>
                      {month}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              className={clsx(Classes.rootMonth, "w-1/4")}
              variant={"outlined"}
            >
              <InputLabel className="mt-4 -ml-6" htmlFor={"year"}>
                {"YY"}
              </InputLabel>
              <Select
                name="year"
                native
                value={state.year}
                onChange={handleInputChange}
                input={<Input id="year" />}
              >
                {[...Array(21).keys()].map((year) => {
                  return (
                    <option className="text-center mt-6 mb-6" value={20 + year}>
                      {20 + year}
                    </option>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              label={"CVC"}
              name="cvc"
              type="number"
              className={clsx(Classes.root, Classes.number, "w-1/4")}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex flex-row justify-around w-full">
          <Button variant="contained" className={"mt-12"} onClick={onBack}>
            {languageStrings["GENERAL.BACK"]}
          </Button>
          <Button
            variant="contained"
            className={"mt-12"}
            onClick={saveCardDetails}
            style={{
              backgroundColor: !canBeSubmited() ? "" : "#f5aa00",
              color: !canBeSubmited() ? "" : "white",
            }}
            disabled={!canBeSubmited()}
          >
            {languageStrings["GENERAL.PAY"]}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default PaymentCard;
