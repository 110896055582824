import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Card, IconButton, Icon } from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import * as Actions from "app/store/actions";
import AlertDialog from "app/main/common/AlertDialog";
import useClientCurrency from "app/hooks/useClientCurrency";
const Product = ({ product, index, deleteProduct }) => {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();
  const { clientCurrency } = useClientCurrency();
  return (
    <Draggable key={product._id} draggableId={product._id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="min-w-full">
            <Card className="m-4">
              <div className="flex flex-wrap sm:flex-no-wrap w-full items-center">
                <div className="w-full ml-8 mr-8 sm:w-full min-w-2/5 justify-center items-center">
                  <Typography
                    variant="subtitle1 bold"
                    className="mt-12 text-center sm:text-left"
                    component="h3"
                  >
                    {`${product.title}${product.sku ? `-${product.sku}` : ""}`}
                    {product.comboSupplementPrice &&
                      `-${product.comboSupplementPrice} ${clientCurrency.symbol}`}
                  </Typography>
                </div>

                <div className="flex justify-between">
                  <div className="flex center ml-16">
                    <IconButton
                      hidden={false}
                      onClick={() => {
                        dispatch(
                          Actions.openDialog({
                            children: (
                              <AlertDialog
                                onSuccess={() => {
                                  deleteProduct(product);
                                  Actions.closeDialog();
                                }}
                                title={
                                  languageStrings["CONFIRM_DELETE_ALERT_TITLE"]
                                }
                                message={
                                  languageStrings["CONFIRM_DELETE_ALERT_BODY"]
                                }
                              />
                            ),
                          })
                        );
                      }}
                    >
                      <Icon className="pr-8 text-red">delete</Icon>
                    </IconButton>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default Product;
