import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import history from "@history";
import { connect } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTablePaginated from "app/main/common/List/ListTablePaginated";
import {
  getOrderList,
  getExportOrderList,
  setOrderSearchText,
} from "app/store/orders/OrdersActions";
import { translate } from "app/helpers/LanguageHelper/index";
import { checkOrderStatuses, orderStatuses } from "./components/OrdersStatus";
import { orderTypes } from "./components/OrdersType";
import UpgradeModal from "../common/UpgradeModal";
import moment from "moment";
import { orderListHeaders } from "../user/components/data";
import OrderFilter from "../common/OrderFilter";
import { ExportModal } from "../common/ExportModal";
import { clientsSettings } from "config";
import SplitBillModal from "../common/SplitBillModal";
import DateRange from "./components/DateRange";

class OrderList extends Component {
  state = {
    orderList: [],
    orderStatus: "0",
    orderType: "0",
    order: {},
    rowsPerPage: 10,
    page: 0,
    orderTypesValues: [],
    open: false,
    showModal: false,
    showSplitBillmodel: false,
    price: 0,
    refundedAmount: 0,
    orderId: "",
    isCashOrder: false,
    startDate: "",
    endDate: "",
    paymentType: "",
    showModalDateRange: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { userPermissions, languageStrings } = props;
    if (userPermissions) {
      let orderTypesValues = orderTypes.filter((type) => {
        if (userPermissions.hasCollection && type.id === 1) {
          return type;
        }

        if (userPermissions.hasDelivery && type.id === 2) {
          return type;
        }

        if (userPermissions.hasOrderFromTable && type.id === 3) {
          return type;
        }

        if (userPermissions.hasFutureOrder && type.id === 4) {
          return type;
        }
      });

      orderTypesValues = orderTypesValues.map((orderType) => ({
        ...orderType,
        name: languageStrings[orderType.name],
      }));
      return {
        ...state,
        orderTypesValues,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.orderList.length !== this.props.orderList.length) {
      this.setState({
        orderList: this.props.orderList.map((order) => ({
          ...order,
          totalPrice: order.price + order.deliveryPrice,
        })),
      });
    }
    const { user } = this.props;

    if (this.state.open === false && user.accessLevel == "1") {
      this.setState({
        open: true,
      });
    }
  }

  componentDidMount() {
    this.props.getOrderList({
      searchText: "",
      page: 0,
      limit: 10,
      order: {},
      status: "0",
    });
  }

  handleClick = (item) => {
    history.push("/order/" + item._id);
  };

  handleRowsPerPageChange = (event) => {
    this.setState({ rowsPerPage: event.target.value }, () => {
      this.filterOrderList();
    });
  };

  handlePageChange = (event, page) => {
    this.setState({ page }, () => this.filterOrderList());
  };

  handleSearch = (searchText) => {
    this.props.setOrderSearchText(searchText);
    this.setState({ page: 0 }, () => this.filterOrderList());
  };

  handleRequestSort = (event, property) => {
    const id = property;
    let direction = "desc";

    if (
      this.state.order.id === property &&
      this.state.order.direction === "desc"
    ) {
      direction = "asc";
    }

    this.setState({
      order: {
        direction,
        id,
      },
    });
    this.setState({ page: 0 }, () => this.filterOrderList());
  };

  filterByStatus = (ev) => {
    let { value } = ev.target;

    this.setState({ orderStatus: value });
    this.setState({ page: 0 }, () => this.filterOrderList());
  };

  filterByType = (ev) => {
    let { value } = ev.target;

    this.setState({ orderType: value });
    this.setState({ page: 0 }, () => this.filterOrderList());
  };

  filterOrderList = () => {
    let { orderList } = this.props;
    let { orderStatus, orderType, order, page, rowsPerPage } = this.state;
    let { totalOrders } = this.props;
    let filteredOrderList = orderList;
    const pageNumber =
      page * rowsPerPage > totalOrders
        ? Math.ceil(totalOrders / rowsPerPage - 1)
        : page;
    if (page * rowsPerPage > totalOrders)
      this.setState({
        page: Math.ceil(totalOrders / rowsPerPage - 1),
      });

    this.props.getOrderList({
      searchText: this.props.searchText,
      page: pageNumber,
      limit: rowsPerPage,
      order,
      status: orderStatus,
      type: orderType,
      initialDate: this.state.startDate,
      finalDate: this.state.endDate,
      paidBy: this.state.paymentType,
    });

    this.setState({
      orderList: filteredOrderList.map((order) => ({
        ...order,
        totalPrice: order.price + order.deliveryPrice,
      })),
    });
  };

  onRefund = (data, event) => {
    let tipAmount = data && data.tipAmount > 0 ? data.tipAmount : 0;
    this.setState({
      showSplitBillmodel: true,
      price: Number(Number(data.price + tipAmount).toFixed(2)),
      refundedAmount: data && data.refundedAmount ? data.refundedAmount : 0,
      orderId: data._id,
      isCashOrder: data && data.paidBy && data.paidBy === 2 ? true : false,
    });
    event.stopPropagation();
  };

  render() {
    const { languageStrings, venueList = [] } = this.props;
    const isCashVenue = venueList && venueList.some((venue) => venue.payCash);
    const isScheduledOrderVenue =
      venueList && venueList.some((venue) => venue.isScheduleOrdersEnabled);

    const headerRows = {
      data: [
        {
          id: "ticketNo",
          align: "left",
          disablePadding: true,
          label: languageStrings["TICKET_NUMBER"],
          sort: true,
          type: "text",
        },
        {
          id: "referenceNo",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.REF"],
          sort: true,
          type: "text",
        },
        {
          id: "venueTitle",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.VENUE"],
          sort: true,
          type: "text",
        },
        ...(clientsSettings.showOrderTypeColumn
          ? [
              {
                id: "type",
                align: "left",
                disablePadding: false,
                label: languageStrings["GENERAL.ORDER_TYPE"],
                sort: true,
                type: "orderType",
              },
            ]
          : []),
        {
          id: ["totalPrice", "discountedPrice"],
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.PRICE"],
          sort: true,
          type: "discountCell",
        },
        {
          id: ["refundedAmount"],
          align: "left",
          disablePadding: false,
          label: languageStrings["REFUNDED_AMOUNT"],
          sort: true,
          type: "refundedAmountCell",
        },
        ...(isCashVenue
          ? [
              {
                id: [""],
                align: "left",
                disablePadding: false,
                label: languageStrings["PAYMENT_TYPE"],
                sort: true,
                type: "paymentTypeOrderList",
              },
            ]
          : []),
        {
          id: "status",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.STATUS"],
          sort: true,
          type: "orderStatus",
        },
        ...(isScheduledOrderVenue
          ? [
              {
                id: "scheduledOrderCreatedAt",
                align: "left",
                disablePadding: false,
                label: languageStrings["SCHEDULE_ORDER_CREATION_DATE"],
                sort: false,
                type: "time",
              },
            ]
          : []),
        {
          id: "createdAt",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.DATE"],
          sort: true,
          type: "time",
        },
        {
          id: [""],
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.ACTIONS"],
          actions: {
            text: languageStrings["REFOUND"],
            onclick: this.onRefund,
          },
          sort: true,
          type: "singleBtn",
        },
      ],
      checkbox: false,
      clickable: true,
      locationSearch: false,
      searchBy: ["referenceNo"],
    };
    const _orderStatuses = clientsSettings.isCheckOrderStatuses
      ? checkOrderStatuses
      : orderStatuses;
    const orderStatusValues = _orderStatuses.map((status) => {
      return {
        ...status,
        name: languageStrings[status.name],
      };
    });

    const exportFiles = () => {
      const usersData = [];
      this.state.orderList.forEach((ord) => {
        const orderStausText = orderStatuses.filter(
          (item) => item.id === Number(ord.orderStatus)
        )[0].name;
        const orderTypeText = orderTypes.filter(
          (item) => item.id === Number(ord.type)
        )[0].name;
        const usersObj = {};
        usersObj["referenceNo"] = ord.referenceNo;
        usersObj["venueTitle"] = ord.venueTitle;
        usersObj["orderType"] = languageStrings[orderTypeText];
        usersObj["totalPrice"] = Number(ord.totalPrice);
        usersObj["refundedAmount"] = Number(ord.refundedAmount);
        usersObj["orderStatus"] = languageStrings[orderStausText];
        usersObj["createdDate"] = moment(ord.createdAt).format("L");
        usersObj["createdTime"] = moment(ord.createdAt).format("LT");

        usersData.push(usersObj);
      });
      return usersData;
    };
    return (
      <>
        <UpgradeModal
          open={this.state.open}
          setOpen={() => {
            history.goBack();
            this.setState({
              open: false,
            });
          }}
        />
        {/* <RefoundAlert/> */}
        <LoadingOverlay
          active={this.props.loading}
          spinner
          styles={{
            wrapper: {
              width: "100%",
              height: "100%",
              overflow: this.props.loading ? "hidden" : "scroll",
            },
          }}
          text="Loading..."
        >
          <div
            style={{
              flex: 1,
              filter: this.state.open ? "blur(3px)" : "none",
            }}
          >
            <FusePageCarded
              classes={{
                content: "flex",
                header: "min-h-72 h-72 sm:h-136 sm:min-h-136 items-center",
              }}
              header={
                <div className={"flex w-full flex-col"}>
                  <div
                    className={
                      "flex w-full items-center flex-row justify-between"
                    }
                  >
                    <ListHeader
                      title={""}
                      numberOfOrder={
                        languageStrings["LOYALTY_PROGRAM_CLIENT_ORDER_NUMBER"]
                      }
                      totalAmountOfOrders={languageStrings["TOTAL_AMOUNT"]}
                      setSearchText={this.handleSearch}
                      searchType={"order"}
                      selectors={[
                        {
                          onChange: this.filterByStatus,
                          values: orderStatusValues,
                          keys: ["id", "name"],
                          title:
                            languageStrings[
                              "ORDER_LIST_PAGE.STATUS_PLACEHOLDER"
                            ],
                          firstOption:
                            languageStrings["ORDER_LIST_PAGE.ALL_STATUS"],
                        },
                        ...(clientsSettings.showOrderTypeButton
                          ? [
                              {
                                onChange: this.filterByType,
                                values: this.state.orderTypesValues,
                                keys: ["id", "name"],
                                title:
                                  languageStrings[
                                    "ORDER_LIST_PAGE.TYPES_PLACEHOLDER"
                                  ],
                                firstOption:
                                  languageStrings["ORDER_LIST_PAGE.ALL_TYPES"],
                              },
                            ]
                          : []),
                      ]}
                      filterButtonOrder
                      exportButton
                      onTipPress={() => {
                        this.setState({ showModalDateRange: true });
                      }}
                      onExportFile={() => {
                        const { user } = this.props;
                        this.props.getExportOrderList({
                          clientId: user._id,
                          startDate: this.state.startDate
                            ? this.state.startDate
                            : new Date() - 1,
                          endDate: this.state.endDate
                            ? this.state.endDate
                            : new Date(),
                          paymentType: this.state.paymentType,
                          languageStrings,
                        });
                      }}
                      numberOfOrders={this.props.totalOrders}
                      totalPaymentOfOrders={this.props.totalPayment}
                    />
                  </div>
                  <div>
                    {languageStrings["RANGE"]}:{" "}
                    {this.state.startDate && this.state.endDate
                      ? ` ${moment(this.state.startDate).format(
                          "DD MMM"
                        )} - ${moment(this.state.endDate).format("DD MMM")}`
                      : languageStrings["ALL_RESULTS"]}
                  </div>
                </div>
              }
              content={
                <ListTablePaginated
                  handleClick={this.handleClick}
                  headerRows={headerRows}
                  data={this.state.orderList.map((order) => {
                    let obj = {
                      ...order,
                      totalPrice: order.totalPrice
                        ? Number(order.totalPrice).toFixed(2)
                        : null,
                    };

                    if (order.discountVoucherAmount) {
                      obj.discountedPrice = (
                        Number(order.totalPrice) -
                        Number(order.discountVoucherAmount)
                      ).toFixed(2);
                    }

                    if (
                      order.scheduledOrderId &&
                      order.scheduledOrderId.createdAt
                    ) {
                      obj.scheduledOrderCreatedAt = moment(
                        order.scheduledOrderId.createdAt
                      );
                    } else {
                      obj.scheduledOrderCreatedAt = "";
                    }

                    return obj;
                  })}
                  totalData={this.props.totalOrders}
                  searchText={this.props.searchText}
                  order={this.state.order}
                  handleRequestSort={this.handleRequestSort}
                  page={this.state.page}
                  rowsPerPage={this.state.rowsPerPage}
                  handleRowsPerPageChange={this.handleRowsPerPageChange}
                  handlePageChange={this.handlePageChange}
                />
              }
              innerScroll
            />
          </div>
          <OrderFilter
            onCloseModal={() => {
              this.setState({ showModal: false });
            }}
            open={this.state.showModal}
            {...this.props}
          />
          <DateRange
            headerText={languageStrings["FILTER_RANGE"]}
            buttonText={languageStrings["APPLY"]}
            onDateSelect={(start, end, paymentType) => {
              this.setState(
                {
                  startDate: start,
                  endDate: end,
                  paymentType: paymentType,
                  showModalDateRange: false,
                },
                () => {
                  this.filterOrderList();
                }
              );
            }}
            onCloseModal={() => {
              this.setState({ showModalDateRange: false });
            }}
            open={this.state.showModalDateRange}
          />
        </LoadingOverlay>
        <SplitBillModal
          orderId={this.state.orderId}
          price={this.state.price}
          showSplitModal={this.state.showSplitBillmodel}
          refundedAmount={this.state.refundedAmount}
          onCloseModal={() => {
            this.setState({
              showSplitBillmodel: false,
              price: 0,
              refundedAmount: 0,
            });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.order.loading,
    orderList: state.banzzu.order.orderList,
    totalOrders: state.banzzu.order.totalOrders,
    totalPayment: state.banzzu.order.totalPayment,
    searchText: state.banzzu.order.searchText,
    userPermissions: state.banzzu.auth.user.permission,
    user: state.banzzu.auth.user,
    venueList: state.banzzu.venue.venueList,
    limitState: state.banzzu.pagination.orderList.limit,
    pageState: state.banzzu.pagination.orderList.page,
  };
};
const mapDispatchToProps = {
  getOrderList,
  getExportOrderList,
  setOrderSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(OrderList));
