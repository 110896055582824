import { useState, useEffect } from "react";
import history from "@history";

function useSyncedPage() {
  const [currentPage, setCurrentPage] = useState(() => {
    if (history.location.state && history.location.state.page !== undefined) {
      return history.location.state.page;
    }
    return 0;
  });

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.state && location.state.page !== undefined) {
        setCurrentPage(location.state.page);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return { currentPage, setCurrentPage };
}

export default useSyncedPage;
