export default {
  "ALLERGENE.CELERY": "芹菜",
  "ALLERGENE.CEREALS": "谷物",
  "ALLERGENE.CRUSTACEANS": "甲壳类动物",
  "ALLERGENE.EGGS": "鸡蛋",
  "ALLERGENE.FISH": "鱼",
  "ALLERGENE.LUPIN": "羽扇豆",
  "ALLERGENE.MILK": "牛奶",
  "ALLERGENE.MOLLUSCS": "软体动物",
  "ALLERGENE.MUSTARD": "芥末",
  "ALLERGENE.NUTS": "坚果类产品",
  "ALLERGENE.PEANUTS": "花生",
  "ALLERGENE.SESAME_SEEDS": "芝麻",
  "ALLERGENE.SULPHER_DIOXIDE": "二氧化硫",
  "ALLERGENE.TITLE": "过敏原",
  "ALLERGENE.SOYA": "大豆",
  "HOME_PAGE.RESTAURANT": "餐厅",
  "HOME_PAGE.YOUR_CODE": "你的二维码",
  "HOME_PAGE.PRINT": "打印 ",
  "HOME_PAGE.DOWNLOAD": "下载 ",
  "HOME_PAGE.CHANGE_URL": "更改网址",
  "HOME_PAGE.URL_TAKEN": "输入的网址已存在",
  "HOME_PAGE.ANDROID_USER": "安卓用户",
  "HOME_PAGE.IOS_USER": "IOS用户",
  "HOME_PAGE.ANALYTICS": "统计数据",
  "HOME_PAGE.USER_REGISTERED": "注册用户",
  "HOME_PAGE.ORDERS_AMOUNT": "线上订单收入",
  "HOME_PAGE.PENDING_ORDERS": "待处理订单",
  FUTURE_ORDERS: "已安排",
  "HOME_PAGE.PROGRESS_TITLE": "您的账户，无限可能",
  "HOME_PAGE.PROGRESS_DESCRIPTION":
    "我们喜欢看到您的进步，这就是为什么我们要向您展示您尚未发现的所有功能......以及您已经发现的功能！ ",
  "HOME_PAGE.HIDE_TEXT": "隐藏已完成的目标",
  "HOME_PAGE.SHOW_TEXT": "显示已完成的目标",
  "HOME_PAGE_GOALS.RESTAURANT_LOGO": "上传你餐厅的图标",
  "HOME_PAGE_GOALS.CUSTOMIZE_COLOR": "自定义你网站的颜色",
  "HOME_PAGE_GOALS.CUSTOMIZE_HEADER": "选择你网站的标题",
  "HOME_PAGE_GOALS.PREVIEW_WEB": "预览你的网站",
  "HOME_PAGE_GOALS.CREATE_FIRST_CATEGORY": "创建菜单的第一个类别",
  "HOME_PAGE_GOALS.UPLOAD_FIRST_MENU": "上传你菜单的第一个菜品",
  "HOME_PAGE_GOALS.ADD_ALLERGENS": "在菜单中添加过敏原",
  "HOME_PAGE_GOALS.UPLOAD_PRODUCT_PHOTOS": "上传菜品的照片",
  "HOME_PAGE_GOALS.ADD_EXTRA_OPTIONS": "添加额外的选项",
  "HOME_PAGE_GOALS.CREATE_SECOND_CATEGORY": "创建第二个类别",
  "HOME_PAGE_GOALS.ADD_STRIPE_KEY": "输入你Stripe密码以接受在线支付",
  "GENERAL.STRIPE_PUB_KEY": "Stripe Clave Publicable",
  "HOME_PAGE_GOALS.ACTIVATE_ORDER_FROM_TABLE": "激活桌上点餐",
  "HOME_PAGE_GOALS.ACTIVATE_DELIVERY": "激活外卖服务",
  "HOME_PAGE_GOALS.ACTIVATE_PICK_UP": "激活到店取餐",
  "HOME_PAGE_GOALS.CREATE_FIRST_TABLE": "创建你的第一张桌子",
  "HOME_PAGE_GOALS.RECEIVE_FIRST_ORDER": "接受你的第一个订单",
  "HOME_PAGE_GOALS.ADD_PICKUP_AVAILABILITY": "创建待取的订单的取货时间",
  "HOME_PAGE_GOALS.ADD_DELIVERY_AVAILABILITY": "创建外卖订单的外送时间",
  "HOME_PAGE_GOALS.ADD_DELIVERY_AREA": "设置你配送的区域",
  "HOME_PAGE.SELECT_YEAR": "选择年份",
  "BOOKING_DETAIL_PAGE.BACK_BUTTON": "预定列表",
  "BOOKING_DETAIL_PAGE.N_PEOPLE": "人数",
  "BOOKING_DETAIL_PAGE.SELECT_VENUE": "选择门店",
  "BOOKING_DETAIL_PAGE.TITLE": "预定详情",
  "BOOKING_LIST_HEADER_PAGE.TITLE": "确定列表",
  "BRANDING_HEADER.TITLE": "自定义你的网站",
  "BRANDING.UPLOAD_LOGO": "上传你的图标",
  "BRANDING.UPLOAD_HEADER": "上传主要的图片",
  "BRANDING.CHOOSE_COLOR": "选择你品牌颜色",
  "CALENDER_APP_PAGE.SELECT_VENUE": "选择门店",
  "CALENDER_HEADER_PAGE.TITLE": "日历",
  "CHANGE_PASSWORD_PAGE.TITLE": "更改密码",
  "DRAW_DETAIL_PAGE.BACK_BUTTON": "抽奖列表",
  "DRAW_DETAIL_PAGE.EVENT_DURATIONL": "活动结束",
  "DRAW_DETAIL_PAGE.NEW_DRAW": "新抽奖",
  "DRAW_DETAIL_PAGE.SELECT_DRAW": "选择抽奖",
  "DRAW_DETAIL_PAGE.TITLE": "抽奖详情",
  "DRAW_LIST_HEADER_PAGE.TITLE": "抽奖",
  "DRAW_LIST_PAGE.BUTTON_TEXT": "创建抽奖",
  "DRAW_LIST_PAGE.TITLE": "抽奖",
  "EXTRA_CATEGORIES.CATEGORY_TYPES": "类别",
  "EXTRA_CATEGORIES.MINIMUM_QUANTITY": "最小数量",
  "EXTRA_CATEGORIES.MAXIMUM_QUANTITY": "最大数量",
  "EXTRA_CATEGORIES.ENABLE_QUANTITY": "限制数量",
  "EXTRA_CATEGORIES.ADD": "Nueva elección",
  "EXTRA_CATEGORIES.EXTRA_PRICE": "额外价格",
  "EXTRA_CATEGORIES.SELECTED": "预选",
  "EXTRA_CATEGORIES.CONNECT_CATEGORY": "相关类别",
  "MATERIAL_TABLE.DELETE": "你确定要删除这一行吗?",
  "MATERIAL_TABLE.NO_RECORDS": "无相关数据",
  "MATERIAL_TABLE.ROW_SELECTED": "{0} 行已选择",
  "MATERIAL_TABLE.DISPLAYED_ROWS": "{from}-{to} de {count}",
  "MATERIAL_TABLE.ROWS": "行",
  "ERRORS.STRIPE_ID_INVALID": "Strip密钥无效",
  "ERRORS.STRIPE_KEY_INVALID": "Stripe公钥无效",
  "ERRORS.REDSYS_SECRET_KEY": "Redsys密钥无效",
  "ERRORS.REDSYS_MERCHANT_CODE": "Redsys商户代码无效",
  "ERRORS.REDSYS_MERCHANT_NAME": "Redsys商户名称无效",
  "ERRORS.IMAGE_IS_REQUIRED": "必须上传图片",
  "ERRORS.FIELD_IS_REQUIRED": "必填项",
  "ERRORS.INVALID_EMAIL": "邮箱不正确",
  "ERRORS.INVALID_PHONE": "电话不正确",
  "ERRORS.INVALID_URL": "网址不正确",
  "ERRORS.INVALID_LOGIN": "登录无效。请重试",
  "ERRORS.MINIMUM_10_CHAR": "最少10个字符",
  "ERRORS.MINIMUM_3_CHAR": "最少3个字符",
  "ERRORS.MINIMUM_1_CHAR": "最少1个字符",
  "ERRORS.MINIMUM_4_CHAR": "最少4个字符",
  "ERRORS.MINIMUM_6_CHAR": "最少6个字符",
  "ERRORS.EMAIL_TAKEN": "邮箱已存在",
  "ERRORS.MUST_BE_NUMBER": "必须是一个数字",
  "ERRORS.ORIGINAL_PRICE_SMALLER": "原价必须高于折扣价",
  "ERRORS.REDUCED_PRICE_GREATER": "折扣价必须低于原价",
  "ERRORS.SELECT_ONE": "至少选择一个选项",
  "ERRORS.PASSWORD_MUST_BE_7": "密码必须超过6个字符",
  "GALLERY_DETAIL_PAGE.SELECT_VENUE": "选择门店",
  "GALLERY_DETAIL_PAGE.TITLE": "图片库",
  "GENERAL.ADDRESS": "地址",
  "GENERAL.DUPLICATE": "复制",
  "GENERAL.CITY": "城市",
  "GENERAL.CREATE": "创建",
  "GENERAL.JANUARY": "一月",
  "GENERAL.FEBRUARY": "二月",
  "GENERAL.MARCH": "三月",
  "GENERAL.APRIL": "四月",
  "GENERAL.MAY": "五月",
  "GENERAL.JUNE": "六月",
  "GENERAL.JULY": "七月",
  "GENERAL.AUGUST": "八月",
  "GENERAL.SEPTEMBER": "九月",
  "GENERAL.OCTOBER": "十月",
  "GENERAL.NOVEMBER": "十一月",
  "GENERAL.DECEMBER": "十二月",
  "GENERAL.ADD_BUTTON": "添加",
  "GENERAL.BASIC_INFO": "基本信息",
  "GENERAL.CANCEL_BUTTON": "取消",
  "GENERAL.CLIENT": "客户",
  "GENERAL.COMMENTS": "评论",
  "GENERAL.CONFIRM_BUTTON": "确认",
  "GENERAL.CONTACT_INFO": "联系人信息",
  "GENERAL.DATE": "日期",
  "GENERAL.SCHEDULE_DATE_TIME": "设置时间",
  "GENERAL.DELETE": "删除",
  "GENERAL.DENY_BUTTON": "取消",
  "GENERAL.DESCRIPTION": "描述",
  "GENERAL.DETAIL": "详情",
  "GENERAL.EMAIL": "邮箱",
  "GENERAL.FRIDAY": "周五",
  "GENERAL.IMAGE": "图片",
  "GENERAL.MONDAY": "周一",
  "GENERAL.NAME": "名字",
  "GENERAL.NUMBER": "数量",
  "GENERAL.OPENING_HOURS": "营业时间",
  "GENERAL.DELIVERY_INFO": "送货上门",
  "GENERAL.DELIVERY_TIME": "送货时间",
  "GENERAL.MIN_DELIVERY_PRICE": "送货",
  "GENERAL.MIN_ORDER_PRICE": "最低订单金额",
  "GENERAL.COLLECTION_TIME": "取货时间",
  "GENERAL.ORIGINAL_PRICE": "原价",
  "GENERAL.DISCOUNTED_PRICE": "折扣价",
  "GENERAL.PASSWORD": "密码",
  "GENERAL.EXPORT": "导出",
  "GENERAL.EXPORT_CSV": "下载 csv",
  "GENERAL.EXPORT_XLS": "下载 xls",
  "GENERAL.PHONE": "电话",
  "GENERAL.POINTS": "积分",
  "GENERAL.POSTCODE": "邮编",
  "GENERAL.PRICE": "金额",
  "GENERAL.REDUCED_PRICE": "折扣价",
  "GENERAL.ROWS_PER_PAGE": "每页记录数量",
  "GENERAL.SATURDAY": "周六",
  "GENERAL.SAVE": "保存",
  "GENERAL.SAVE_PREVIEW": "保存并预览",
  "GENERAL.SAVE_BUTTON": "保存",
  "GENERAL.SEARCH": "搜索",
  "GENERAL.SEND": "发送",
  "GENERAL.SUNDAY": "周日",
  "GENERAL.THURSDAY": "周四",
  "GENERAL.TIME": "时间",
  "GENERAL.TITLE": "标题",
  "GENERAL.TUESDAY": "周二",
  "GENERAL.TYPE": "类型",
  "GENERAL.USER_LIST": "用户列表",
  "GENERAL.WINNERS_LIST": "获奖者",
  "GENERAL.VENUE": "门店",
  "GENERAL.STATUS": "状态",
  "GENERAL.WEBSITE": "网站",
  "GENERAL.WEDNESDAY": "周三",
  "GENERAL.CATEGORY": "类别",
  "GENERAL.ITEMS": "物品",
  "GENERAL.ACTIONS": "操作",
  "GENERAL.REF": "编号",
  "GENERAL.ALL_VENUES": "所有门店",
  "GENERAL.ALERT_TITLE": "确认",
  "GENERAL.ORDER_TYPE": "订单类型",
  "GENERAL.LATITUDE": "纬度",
  "GENERAL.LONGITUDE": "经度",
  "GENERAL.NO_ORDER_LIST": "此选项没有订单",
  "ORDER_FROM_TABLE.TITLE_ERROR": "缺少标题",
  "GENERAL.ALERT_DESC": "你确定要在不保存的情况下返回吗?",
  "GENERAL.AREA_REMOVE_ALERT_DESC": "你确定要删除这个区域吗?",
  "GENERAL.REMOVE": "删除",
  "GENERAL.NO_OF_PEOPLE": "人数",
  "GENERAL.PLATFORM": "平台",
  "GENERAL.STRIPE_KEY": "Stripe密钥",
  "GENERAL.REDSYS_KEY": "密钥",
  "GENERAL.MERCHENT_CODE": "商户代码",
  "GENERAL.MERCHENT_NAME": "商户名称",
  "GENERAL.REDSYS_IS_TEST_MODE": "测试模式",
  TIME_SETTINGS_COLLECTION: "已取",
  TIME_SETTINGS_DELIVERY: "一交货",
  "GENERAL.MIN": "分钟",
  "GENERAL.ENTER_TIME": "输入默认时间",
  "GENERAL.COLLECTION_ORRDER_CONFIRMATION_TITLE": "预计取货时间",
  "GENERAL.DELIVERY_ORRDER_CONFIRMATION_TITLE": "预计交货时间",
  "GENERAL.ESTIMATED_TIME": "预计时间",
  "GENERAL.ADV_TITLE": "优点",
  "GENERAL.DIS_ADV_TITLE": "缺点",
  "GENERAL.USER_GUIDE": "用户指南",
  "GENERAL.REDSYS_ADV1": "低佣金（0.15%-0.3%），取决于达成的协议",
  "GENERAL.REDSYS_ADV2": "和你的银行",
  "GENERAL.REDSYS_ADV3": "与大多数银行合作，比如Banco Sabadell，ING Directo",
  "GENERAL.REDSYS_ADV4": "BBVA, Caixabank, Santander, Banco Popular.",
  "GENERAL.REDSYS_DIS_ADV1":
    "未保存支付卡信息：客户必须在每次交易时输入支付方式。",
  "GENERAL.REDSYS_DIS_ADV2":
    "审查过程漫长，您必须向当前银行提出申请，否则您的申请可能会被拒绝。",
  "GENERAL.STRIPE_ADV1": "设置简单快捷，即可开始在线接收订单.",
  "GENERAL.STRIPE_ADV2": "直观现代的控制面板，让您管理所有资金信息",
  "GENERAL.STRIPE_ADV3":
    "它适用于所有银行，交易完成后几天内资金就会到您的账户中",
  "GENERAL.STRIPE_ADV4": "安全存储支付卡：用户只需在首次购物时输入支付信息即可",
  "GENERAL.STRIPE_DIS_ADV1": "高佣金：1.4%以上",
  "GENERAL.TO": "至",
  "GENERAL.YES": "是",
  "GENERAL.FIRST_NAME": "名字",
  "GENERAL.LAST_NAME": "姓",
  "GENERAL.YOUR_ARE_THERE": "快结束啦",
  "GENERAL.ONLY_THE_LAST": "只差最后一步就可以享受Banzzu",
  "GENERAL.MAKE_DEFAULT": "设为默认",
  "GENERAL.DEFAULT": "默认",
  "GENERAL.BACK": "返回",
  "GENERAL.PAY": "支付",
  "GENERAL.FULL_NAME": "全称",
  "GENERAL.CONTINUE": "继续",
  "GENERAL.DELIVERY_COST": "送货费",
  "GENERAL.ADDRESS_LIST": "地址",
  "GENERAL.AVERAGE_ORDER": "平均订单",
  "GENERAL.TOP_FIVE_DISHES": "最受欢迎的的15道菜",
  "GENERAL.ICOME_PER_DAY": "每日累计收入",
  "GENERAL.CODE": "折扣代码",
  "GENERAL.PERCENTAGE": "百分比",
  "GENERAL.QUANTITY": "金额",
  "GENERAL.EXPIREABLE": "有效期",
  "GENERAL.AMOUNT": "数量",
  "GENERAL.USED": "已使用",
  "GENERAL.MINIMUM_QUANTITY": "最小数量",
  "GENERAL.ACTIVATE": "激活",
  "GENERAL.DISOCUNT_CODE": "折扣代码",
  "GENERAL.MINIMUM_AMOUNT": "最低金额",
  "GENERAL.EXPIRY_DATE": "有效期",
  "GENERAL.USABILITY": "用法",
  "GENERAL.SINGLE_USE": "单次使用",
  "GENERAL.MULTIPLE_USE": "多次使用",
  "GENERAL.CONFIRMATION_TITLE": "确认",
  "GENERAL.PAYMENT_METHOD": "支付方式",
  "GENERAL.CONFIRMATION_SUBTITLE": "你想要查询此额外服务的信息吗?",
  "GENERAL.TOTAL_SPENT": "总花费",
  "LOGIN_PAGE.LOGIN_BUTTON": "登录",
  "LOGIN_PAGE.LOGIN_TO_ACCOUNT": "登录到你的账户",
  "LOGIN_PAGE.REGISTER_LINK": "没有账户？注册一个",
  "LOGIN_PAGE.FORGOT_PASSWORD": "找回密码?",
  "LOGIN_PAGE.ARE_YOU_SURE.ARE_YOU_SURE": "你确定要找回密码?",
  "LOGIN_PAGE.CONFIRM": "确认",
  "LOGIN_PAGE.CANCEL": "取消",
  "LOGIN_PAGE.ENTER_VALID_EMAIL": "输入有效的电子邮箱",
  "LOGIN_PAGE_.EMAIL_EXIST": "邮箱不存在",
  "LOGIN_PAGE_.CHECK_EMAIL": "查看你的邮箱",
  "LOGIN_PAGE_.REMEMBER_ME": "记住我",
  "FORGOT_PASSWORD_PAGE.RESET_PASSWORD": "重置密码",
  "FORGOT_PASSWORD_PAGE.SAVE_BUTTON": "保存",
  "FORGOT_PASSWORD_PAGE.PASSWORD": "新密码",
  "FORGOT_PASSWORD_PAGE.CONFIRM_PASSWORD": "确认密码",
  FORGOT_PASSWORD_PAGE_TOKEN_EXPIRED: "令牌已过期",
  "FORGOT_PASSWORD_PAGE.SUCCESS": "密码更改成功",
  "FORGOT_PASSWORD_PAGE.PASSWORD_MUST_BE_7": "密码必须超过6个字符",
  "FORGOT_PASSWORD_PAGE.CONFIRM_PASSWORD_MUST_BE_7": "确认密码必须超过6个字符",
  "FORGOT_PASSWORD_PAGE.PASSWORD_SAME": "密码和确认密码必须相同",
  "MENU_CATEGORY_PAGE.ADD_CATEGORY": "添加类别",
  "MENU_CATEGORY_PAGE.PRODUCT_LIST": "产品列表",
  MENU_NEW_MENU_CREATED: "你的菜单已经成功创建",
  "MENU_DETAIL_PAGE.CREATE_CATEGORY": "创建类别",
  "MENU_DETAIL_PAGE.UPDATE_CATEGORY": "更新列表",
  "MENU_DETAIL_PAGE.CREATE_EXTRA_CATEGORY": "创建额外类别",
  "MENU_DETAIL_PAGE.BACK_BUTTON": "菜单列表",
  "MENU_DETAIL_PAGE.MENU_TYPE": "菜单类别",
  "MENU_DETAIL_PAGE.NEW_MENUE": "新菜单",
  "MENU_DETAIL_PAGE.NEW_PRODUCT": "新产品",
  "MENU_DETAIL_PAGE.PDF_IS_REQUIRED": "PDF是必须项",
  "MENU_DETAIL_PAGE.TITLE": "菜单详情",
  "MENU_DETAIL_PAGE.HELP": "你需要帮助",
  "MENU_DETAIL_PAGE.MENU_SHARED": "此菜单是共享的，无法编辑",
  "MENU_DETAIL_PAGE.VENUES": "门店",
  "MENU_DETAIL_PAGE.VIEW_PDF": "查看 PDF",
  "MENU_DETAIL_PAGE.DELETE_CATEGORY": "删除类别",
  "MENU_DETAIL_PAGE.VIRTUAL_MENU_PRODUCTS": " 虚拟菜单的菜品",
  "MENU_DETAIL_PAGE.MENU_AVAILABILITY": " 菜单可见在",
  "MENU_DETAIL_PAGE.FUTURE_ORDERS": "未来订单",
  "MENU_DETAIL_PAGE.DELIVERY": "送货上门",
  "MENU_DETAIL_PAGE.COLLECTION": "店内自取",
  "MENU_DETAIL_PAGE.ALLERGENES": "过敏原 PDF（可选）",
  "MENU_DETAIL_PAGE.ORDER_FROM_TABLE": "从桌上点餐",
  "MENU_ITEM_DETAIL_PAGE.PRODUCT_DETAIL": "产品详情",
  "MENU_ITEM_DETAIL_PAGE.NEW_PRODUCT": "新产品",
  "MENU_ITEM_DETAIL_PAGE.PRODUCT_LIST": "产品列表",
  "MENU_ITEM_DETAIL_PAGE.SELECT_CATEGORY": "选择类别",
  "MENU_ITEM_LIST_HEADER_PAGE.SELECT_CATEGORY": "选择类别",
  "MENU_ITEM_LIST_HEADER_PAGE.CREATE_PRODUCT": "创建产品",
  "MENU_ITEM_LIST_HEADER_PAGE.PRODUCT": "产品",
  "MENU_ITEM_LIST_PAGE.PRODUCT_LIST": "产品列表",
  "MENU_ITEM_LIST_PAGE.CATEGORIES": "类别列表",
  "MENU_LIST_PAGE.BUTTON_TEXT": "创建菜单",
  "MENU_LIST_PAGE.TITLE": "菜单列表",
  "MENU_LIST_CATEGORY_PAGE.TITLE": "菜单类别",
  MENU_URL: "菜单 URL",
  MENU_URL_ERROR: "无效的 URL",
  "NAVBAR.MASTER_ACCOUNT": "管理员账户",
  "NOTIFICATION_DETAIL_PAGE.BUTTON_BACK": "推送通知",
  "NOTIFICATION_DETAIL_PAGE.TITLE": "发送通知",
  "NOTIFICATION_LIST_PAGE.TITLE": "推送通知",
  "OFFER_DETAIL_PAGE.BACK_BUTTON": "优惠列表",
  "OFFER_DETAIL_PAGE.DISABLE_PRICE": "失效价格",
  "OFFER_DETAIL_PAGE.NEW_OFFER": "优惠",
  "OFFER_DETAIL_PAGE.SELECT_VENUE": "选择门店",
  "OFFER_DETAIL_PAGE.TITLE": "优惠详情",
  "OFFER_LIST_HEADER_PAGE.BUTTON_TEXT": "创建优惠",
  "OFFER_LIST_HEADER_PAGE.TITLE": "优惠列表",
  "ORDER_LIST_PAGE.TITLE": "订单",
  "ORDER_LIST_PAGE.ALL_STATUS": "所有状态",
  "ORDER_LIST_PAGE.ALL_TYPES": "所有类型",
  "ORDER_LIST_PAGE.STATUS_PLACEHOLDER": "选择状态",
  "ORDER_LIST_PAGE.TYPES_PLACEHOLDER": "选择类型",
  "ORDER_PAGE.CUSTOMER": "客户",
  "ORDER_PAGE.EMAIL": "邮箱",

  "ORDER_PAGE.ID": "ID",
  "ORDER_PAGE.NAME": "姓名",
  "ORDER_PAGE.STATUS_BUTTON.CONFIRM": "接受订单",
  "ORDER_PAGE.STATUS_BUTTON.CANCEL": "取消",
  "ORDER_PAGE.STATUS_BUTTON.COLLECT": "代取",
  "ORDER_PAGE.STATUS_BUTTON.COLLECTD": "已取",
  "ORDER_PAGE.STATUS_BUTTON.START_PREPARING": "准备中",
  "ORDER_PAGE.STATUS_BUTTON.START_DELIVERY": "开始送货",
  "ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED": "完成",
  "ORDER_PAGE.EXTRAS": "额外",
  "ORDER_PAGE.ORDER": "订单",
  "ORDER_PAGE.ORDERS": "订单",
  "ORDER_PAGE.ORDER_DETAIL": "订单详情",
  "ORDER_PAGE.PHONE": "电话",
  "ORDER_PAGE.PRODUCTS": "产品",
  "ORDER_PAGE.QUANTITY": "数量",
  "ORDER_PAGE.DELIVERY": "送货地址",
  "ORDER_PAGE.COMMENTS": "评论",
  "SCHEDULE_ORDER_LIST_PAGE.TITLE": "安排订单",
  "SCHEDULE_ORDER_PAGE.ORDERS": "安排订单",
  "REWARD_DETAIL_PAGE.BACK_BUTTON": "奖励列表",
  "REWARD_DETAIL_PAGE.NEW_REWARD": "新奖励",
  "REWARD_DETAIL_PAGE.TITLE": "奖励详情",
  "REWARD_LIST_PAGE.BUTTON_TEXT": "创建奖励",
  "REWARD_LIST_PAGE.TITLE": "奖励",
  "SIDEBAR_MENUE.CREATE_MENUE": "创建菜单",
  "SIDEBAR_MENUE.CREATE_OFFER": "创建优惠",
  "SIDEBAR_MENUE.CREATE_WHATSON": "创建消息",
  "SIDEBAR_MENUE.HOME": "首页",
  "SIDEBAR_MENUE.ANALYTICS": "数据分析",
  "SIDEBAR_MENUE.DRAWS": "抽奖",
  "SIDEBAR_MENUE.DRAWS_CREATE": "创建抽奖",
  "SIDEBAR_MENUE.DRAWS_LIST": "抽奖列表",
  "SIDEBAR_MENUE.GALLERY": "图片",
  "SIDEBAR_MENUE.LOYALTY_PROGRAM": "会员卡",
  "SIDEBAR_MENUE.MENUE_LIST": "菜单列表",
  "SIDEBAR_MENUE.MENUE_TITLE": "菜单",
  "SIDEBAR_MENUE.MENUE_TITLE_V2": "菜单 V2",
  "SIDEBAR_MENUE.NOTIFICATIONS": "通知",
  "SIDEBAR_MENUE.NOTIFICATIONS_PUSH": "推送通知",
  "SIDEBAR_MENUE.NOTIFICATIONS_SEND": "发送推送",
  "SIDEBAR_MENUE.OFFERS": "优惠",
  "SIDEBAR_MENUE.OFFER_LIST": "优惠列表",

  "SIDEBAR_MENUE.ORDERS": "订单",
  "SIDEBAR_MENUE.ORDERS_LIST": "订单列表",
  "SIDEBAR_MENUE.SCHEDULE_ORDERS": "安排订单",
  "SIDEBAR_MENUE.PROFILE": "个人资料",
  "SIDEBAR_MENUE.BRANDING": "品牌形象",
  "SIDEBAR_MENUE.CATALOGUE": "目录",
  "SIDEBAR_MENUE.PRODUCTS": "产品",
  "SIDEBAR_MENUE.GROUPMODIFIERS": " 选项组",
  "SIDEBAR_MENUE.MODIFIERS": "选项",
  "SIDEBAR_MENUE.RESERVATION": "预订",
  "SIDEBAR_MENUE.RESERVATION_BOOKING": "预订日历",
  "SIDEBAR_MENUE.RESERVATION_CONFIRM": "确认预订",
  "SIDEBAR_MENUE.REWARDS_CREATE": "创建奖励",
  "SIDEBAR_MENUE.REWARDS_LIST": "奖励列表",
  "SIDEBAR_MENUE.TITLE": "菜单",
  "SIDEBAR_MENUE.TRANSACTION": "交易列表",
  "SIDEBAR_MENUE.USER_LIST": "用户",
  "SIDEBAR_MENUE.VENUE": "门店",
  "SIDEBAR_MENUE.VENUE_DETAILS": "创建门店",
  "SIDEBAR_MENUE.VENUE_LIST": "门店列表",
  "SIDEBAR_MENUE.VOUCHER": "优惠券",
  "SIDEBAR_MENUE.VOUCHER_CREATE_VOUCHER": "创建优惠券",
  "SIDEBAR_MENUE.VOUCHER_LIST": "优惠券列表",
  "SIDEBAR_MENUE.WHATSON": "消息",
  "SIDEBAR_MENUE.WHATSON_lIST": "消息列表",
  "SIDEBAR_MENUE.ORDER_FROM_TABLE": "从桌上点餐",
  "SIDEBAR_MENUE.ORDER_FROM_TABLE_LIST": "桌子列表",
  "SIDEBAR_MENUE.CONFIGURATION": "初始配置",
  "SIDEBAR_MENUE.ONLINE_ORDERS": "设置线上订单",
  "SIDEBAR_MENUE.STAFF": "员工",
  "SIDEBAR_MENUE.STAFF_LIST": "员工列表",
  "SIDEBAR_MENUE.CREATE_STAFF": "创建员工",
  "SIDEBAR_MENUE.MERCHANDISING": "附加服务",
  "SIDEBAR_MENUE.PaymentOrdeFromTable": " 桌上支付 ",
  "SIDEBAR_MENUE.VirtualCartOrder": "账单历史",
  "SIDEBAR_MENUE.RATINGS": "评价",
  "SIDEBAR_MENUE.LOYALTY": "会员项目",
  "SIDEBAR_MENUE.PROMOTIONS": "促销",
  "SIDEBAR_MENUE.PROMOTION_LIST": "促销列表",
  "SIDEBAR_MENUE.CREATE_PROMOTION": "创建促销",
  "STAFF_DETAIL.BASIC_INFO": "基本信息",
  "STAFF_DETAIL.PERMISSIONS": "权限和角色",
  "STAFF_DETAIL.BACK_LIST": "员工列表",

  "STAFF_DETAIL.CREATE_STAFF": "创建员工",
  "STAFF_DETAIL.ASSIGNED_VENUES": "指定门店",
  "ORDER_FROM_TABLE.LIST": "列表",
  "ORDER_FROM_TABLE.CREATE_TABLE": "创建桌子",
  "ORDER_FROM_TABLE.DOWNLOAD_QR": "下载二维码",
  "ORDER_FROM_TABLE.VENUES": "门店",
  "TRANSACTION_LIST_PAGE.TITLE": "交易",
  "USER_LIST_PAGE.TITLE": "用户列表",
  "VENUE_DETAIL_PAGE.BUTTON_BACK": "门店列表",
  "VENUE_DETAIL_PAGE.MAIN_TITLE": "新门店",
  "VENUE_DETAIL_PAGE.DLIVERY_AREA_TITLE": "配送区域",
  "VENUE_DETAIL_PAGE.REMOVE_AREA": "删除配送区域",
  "VENUE_DETAIL_PAGE.CREATE_AREA": "创建配送区域",
  "VENUE_DETAIL_PAGE.AREA": "区域",
  "VENUE_DETAIL_PAGE.CLEAR_AREA": "清除配送区域",
  "VENUE_DETAIL_PAGE.ALERT_TIME.TITLE": "添加营业时间",
  "VENUE_DETAIL_PAGE.ALERT_TIME.INITIAL_TIME": "开始时间",
  "VENUE_DETAIL_PAGE.ALERT_TIME.END_TIME": "结束时间",
  "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_TITLE": "删除营业时间",
  "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_SUBTITLE": "确定要删除此营业时间吗？",
  "VENUE_DETAIL_PAGE.PASSWORD": "密码（如果想更改密码，请填写此字段）",
  "VENUE_DETAIL_PAGE.VALID_URL": "请输入有效的 URL",
  "VENUE_DETAIL_PAGE.TITLE": "门店详情",
  "VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS": "开始日期应小于结束日期",
  "VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER": "结束日期应大于开始日期",
  "VENUE_DETAIL_PAGE.DATE_RANGE": "日期范围不能超过 30 天",
  "VENUE_DETAIL_PAGE.DELIVERY_ACTIVATION_ALERT":
    "请激活在线订单，以便全面了解本部分内容。 ",
  "VISIBILITY.ALWAYS_VISIBLE": "始终可见",
  "VISIBILITY.DISABLED": "已禁用",
  "VISIBILITY.SHOW_ONLY": "仅显示",
  "VISIBILITY.PARTIALY_DISABLED": "部分禁用",
  "VISIBILITY.TITLE": "有效时间段",
  "VISIBILITY.TIME_RANGE": "营业时间",
  "VENUE_LIST_HEADER_PAGE.CREATE_NEW_VENUE": "创建门店",
  "VENUE_LIST_HEADER_PAGE.NEW": "新的",
  "VENUE_LIST_HEADER_PAGE.TITLE": "门店列表",

  "VOUCHER_DETAIL_PAGE.BUTTON_BACK": "优惠券列表",
  "VOUCHER_DETAIL_PAGE.NEW_VOUCHER": "创建优惠券",
  "VOUCHER_DETAIL_PAGE.TITLE": "优惠券详情",
  "VOUCHER_DETAIL_PAGE.VOUCHER_TYPE": "选择优惠券数量",
  "VOUCHER_LIST_HEADER_PAGE.BUTTON_TEXT": "创建优惠券",
  "VOUCHER_LIST_HEADER_PAGE.NEW": "新建",
  "VOUCHER_LIST_HEADER_PAGE.TITLE": "优惠券列表",
  "WHATS_ON_LIST_HEADER_PAGE.BUTTON_TEXT": "创建消息",
  "WHATS_ON_LIST_HEADER_PAGE.MAIN_TITLE": "新消息",
  "WHATS_ON_LIST_HEADER_PAGE.TITLE": "消息",
  "WHAT_ON_DETAIL_PAGE.BACK_BUTTON": "消息列表",
  "WHAT_ON_DETAIL_PAGE.DATE_FROM": "开始日期",
  "WHAT_ON_DETAIL_PAGE.DATE_TO": "结束日期",
  "WHAT_ON_DETAIL_PAGE.EVENT_DURATIONL": "活动时长",
  "WHAT_ON_DETAIL_PAGE.SELECT_VENUE": "选择地点",
  "WHAT_ON_DETAIL_PAGE.TITLE": "消息详情",
  "WHAT_ON_DETAIL_PAGE.ONE_DAY": "1 天",
  "WHAT_ON_DETAIL_PAGE.MULTIPLE_DAYS": "多天",
  "ORDER_TYPE.CASH": "现金支付",
  "ORDER_TYPE.DELIVERY": "送货上门",
  "ORDER_TYPE.COLLECT": "到店自取",
  "ORDER_TYPE.FUTURE_ORDER": "预约订单",
  "ORDER_TYPE.ORDER_FROM_TABLE": "桌上点餐",
  "ORDER_STATUS.ORDER_NUMBER": "订单编号",
  "ORDER_STATUS.UPDATED_AT": "创建日期",
  "ORDER_STATUS.PAYMENT_BY_CASH": "现金支付",
  "ORDER_STATUS.PAYMENT_ACCEPTED": "已接受支付",
  "ORDER_STATUS.ORDER_ACCEPTED": "订单已接受",
  "ORDER_STATUS.PREPARING": "订单准备中",
  "ORDER_STATUS.ON_DELIVERY": "正在配送中",
  "ORDER_STATUS.DELIVERED": "已完成",
  "ORDER_STATUS.CANCELED": "已取消",
  "ORDER_STATUS.COLLECT": "已准备好取货",
  "ORDER_STATUS.COLLECTED": "已取货",
  "ORDER_STATUS.ORDER_TIME": "订单时间",
  "ORDER_STATUS.ORDER_TABLE": "餐桌订单",
  "USER_DETAIL.BACK_LIST": "用户列表",
  "USER_DETAIL.ORDER_LIST": "订单",
  "USER_DETAIL.REWARD_LIST": "奖励",
  "USER_DETAIL.LOYALTY_LIST": "会员计划",
  "USER_DETAIL.BOOKING_LIST": "预订",
  "USER_DETAIL.DRAW_LIST": "抽奖",
  "USER_DETAIL.ADDRESS_LIST": "地址",

  "VENUE.ADD_LONGITUDE_ALERT": "请在联系方式中添加经度和纬度",
  "MENU.SHARED": "该菜单与其他地点共享，不能编辑",
  "TABLE_HEAD.CONFIRMATION_TITLE": "确认",
  "TABLE_HEAD.CONFIRMATION_DESCRIPTION": "您确定要删除此记录吗？",
  "PRICE_REDUCE_CELL.DISABLED_PRICE": "价格已禁用",
  "REGISTER_PAGE.TITLE": "免费注册",
  "REGISTER_PAGE.SUBTITLE":
    " 开始提供免佣金在线订单服务，建立客户忠诚度并增加收入。",
  "REGISTER_PAGE.FIRST_NAME": "名字",
  "REGISTER_PAGE.LAST_NAME": "姓氏",
  "REGISTER_PAGE.PHONE_NUMBER": "电话",
  "REGISTER_PAGE.EMAIL": "电子邮件",
  "REGISTER_PAGE.TELEPHONE": "电话",
  "REGISTER_PAGE.PASSWORD": "密码",
  "REGISTER_PAGE.TERMS_CONDITIONS": "条款和条件",
  "REGISTER_PAGE.PRIVACY_POLICY": "隐私政策",
  "REGISTER_PAGE.ACCEPT": "接受",
  "REGISTER_PAGE.BUTTON_TEXT": "继续",
  "REGISTER_PAGE.LOGIN_LINK": "已有账户？",
  "REGISTER_PAGE.LOGIN": "登录",
  "REGISTER_PAGE.NEW_IN_BANZZU": "新用户？",
  "REGISTER_PAGE.SUB_TITLE_1": " 征服您餐厅的数字宇宙",
  "REGISTER_PAGE.SUB_TITLE_2": " 并探索所有可能",
  "REGISTER_STEPS.REGISTER_RESTAURANT": "注册您的餐厅",
  "REGISTER_STEPS.LOCATION": "位置",
  "REGISTER_STEPS.SELECT_SERVICES": "选择服务",
  "REGISTER_STEPS.ADDITIONAL_INFORMATION": "附加信息",
  "REGISTER_STEPS.RESTAURANT_NAME": "餐厅名称",
  "REGISTER_STEPS.TELEPHONE": "餐厅电话",
  "REGISTER_STEPS.ADDRESS": "地址",
  "REGISTER_STEPS.POSTAL_CODE": "邮编",
  "REGISTER_STEPS.MAP_TITLE": "您想更改餐厅位置吗？",
  "REGISTER_STEPS.MOVE_PIN": "在地图上移动定位针",
  "REGISTER_STEPS.SERVICES_TITLE": "您提供哪些服务？",
  "REGISTER_STEPS.PICK_UP_SERVICE": "送货上门",
  "REGISTER_STEPS.DELIVERY_SERVICE": "店内自取",

  "REGISTER_STEPS.CONGRATULATION": "恭喜！",
  "REGISTER_STEPS.CONGRATULATION_DESCRIPTION": "您的账户已设置完成",
  "REGISTER_STEPS.PROCEED": "继续",
  "REGISTER_STEPS.SELECT_COUNTRY": "选择国家",
  "REGISTER_STEPS.BACK": "返回",
  "REGISTER_STEPS.NEXT": "下一步",
  "REGISTER_STEPS.SUBMIT": "提交",
  "PAYMENT_SETTINGS_PAGE.SETTINGS": "在线订单设置",
  "PAYMENT_SETTINGS_PAGE.TITLE": "设置",
  "PAYMENT_SETTINGS_PAGE.ORDER_SETTINGS": "线上订单设置",
  "PAYMENT_SETTINGS_PAGE.DISCOUNT_VOUCHERS": "折扣码",
  "PAYMENT_SETTINGS_PAGE.PAYMENT_INTEGRATION": "嵌入支付功能",
  "PAYMENT_SETTINGS_PAGE.CREATE_DISCOUNT_VOUCHER": "创建折扣券",
  "PAYMENT_SETTINGS_PAGE.EDIT_DISCOUNT_VOUCHER": "编辑折扣券",
  "PAYMENT_SETTINGS_PAGE.HELPER_TEXT":
    "通过启用以下选项，不同的线上订单将可供您的客户使用",
  "PAYMENT_SETTINGS_PAGE.ONLINE_ORDERS_TITLE": "启用或禁用线上订单",
  "PAYMENT_SETTINGS_PAGE.ALERT_HELPER_TEXT":
    " 在支付网关中添加 stripe 账户的密匙，以便激活这些选项",
  "PAYMENT_SETTINGS_PAGE.ALERT_STRIPE":
    "请输入您的Stripe账户的密钥，并确保支付功能正常运作",
  "PAYMENT_SETTINGS_PAGE.DELIVERY": "送货上门",
  "PAYMENT_SETTINGS_PAGE.COLLECTION": "店内自取",
  "PAYMENT_SETTINGS_PAGE.ORDER_FROM_TABLE": "桌上点餐",
  "PAYMENT_SETTINGS_PAGE.MENU_AVAILABILITY": "适用于以下菜单：",
  "PAYMENT_SETTINGS_PAGE.STRIP_KEY_ERROR":
    "在正确配置您的Stripe密钥之前，无法启用线上订单。点击此处进行配置。",
  "PAYMENT_SETTINGS_PAGE.PAY_BY_CASH": "现金支付",
  "PAYMENT_SETTINGS_PAGE.PAY_BY_CARD": "银行卡支付",
  "PAYMENT_SETTINGS_PAGE.ONE_ACTIVE_PAYMENT": "至少要激活其中一种支付方式",
  "PAYMENT_SETTINGS_PAGE.PAYMENT_METHODS": "支付方式",
  "PAYMENT_SETTINGS_PAGE.ADD_STRIPE_KEY": "添加有效的Stripe密钥以启用此选项",
  "PAYMENT_PAGE.VENUE": "餐厅",

  "PAYMENT_PAGE.MONTH": "月",
  "PAYMENT_PAGE.RESTAURANT": "餐厅",
  "PAYMENT_PAGE.IVA": "不含税",
  "PAYMENT_PAGE.EXTRA_RESTAURANT": "额外餐厅",
  "PAYMENT.CHOOSE_PAYMENT_METHOD": "选择支付方式",
  "PAYMENT.ADD_CARD": "添加银行卡",
  "PAYMENT.HOW_MANY_RESTAURANTS": "你有多少家餐厅？",
  "PAYMENT.HOW_MANY_RESTAURANTS_DO_YOU_WANT_TO_ADD": "你想添加多少家餐厅？",
  "HELP_MENU.CONTACT_SUPPORT": "联系客服",
  "HELP_MENU.PAYMENTS": "支付方式",
  "HELP_MENU.CHANGE_PASSWORD": "修改密码",
  "HELP_MENU.LOGOUT": "登出",
  "CONTACT_SUPPORT.TITLE": "联系我们",
  "CONTACT_SUPPORT.SUB_TITLE": "请使用以下表单或发送电子邮件至",
  "CONTACT_SUPPORT.SUBJECT": "主题",
  "CONTACT_SUPPORT.MESSAGE": "消息",
  "CONTACT_SUPPORT.SEND": "发送",
  "CONTACT_SUPPORT.SUCCESS_MESSAGE": "您的消息已成功发送",
  "PRICING.UPGRADE": "包括更多餐厅",
  "PRICING.PLANES": "计划",
  "PRICING.THE_MOST_ADVANCED": " 为您的餐厅提供功能全面并且价格透明的软件",
  "PRICING.YOU_PLANE": "您的计划",
  "PRICING.PER_MONTH": "每月",
  "PRICING.STANDARD": "标准版",
  "PRICING.BASIC_ANALYTICS": "基础数据分析",
  "PRICING.PAGE": "页面",
  "PRICING.CUSTOM_WEB": "定制网页",
  "PRICING.FOR_YOUR_BUSINESS": "为了您的生意",
  "PRICING.ACCOMMODATION": "住宿",
  "PRICING.FREE_HOSTING": "免费托管",
  "PRICING.YOUR_OWN_INTERACTIVE_MENU": "您自己的互动菜单",
  "PRICING.ADD_IMAGES_OF_YOUR_PRODUCT":
    "添加您产品的图像、描述、价格、成分和过敏原信息。",
  "PRICING.CUSTOM_QR_CODE": "定制二维码",
  "PRICING.WITH_YOUR_MENU": "菜单定制完成",
  "PRICING.24th_SUPPORT": "24小时支持",
  "PRICING.BY_CHAT_AND_EMAIL": "通过聊天和电子邮件",
  "PRICING.ACCESS_ALL_RESTAURANT": "访问所有餐厅的所有数据：",

  "PRICING.ADDRESS_PHONE": "地址、电话、社交媒体、网站...",
  "PRICING.INCLUDE_ONE_VENUE": "加入一个门店",
  "PRICING.YOUR_PLAN": "你的计划",
  "PRICING.ADVANCED": "高级版",
  "PRICING.BEFORE": "之前",
  "PRICING.EVERYTHING_INCLUDE": " 免费计划中包含的所有内容",
  "PRICING.PROP_ANALYTICS": "专业数据分析",
  "PRICING.USER_CREATION_REGISTRATION": "用户创建和注册",
  "PRICING.TO_RECIVE": "接收",
  "PRICING.HOME_DELIVER_ORDER": "外卖订单",
  "PRICING.ORDER_TO_COLLECT": "自取订单",
  "PRICING.ORDER_FROM_TABLE": "桌边点餐",
  "PRICING.LET_YOUR_IMGINATION": "释放你的想象力，创造",
  "PRICING.SLEEP_PACE_FULLY": " 我们为您保驾护航",
  "PRICING.ANTI_FRAUD_SYSTEM": "防欺诈系统",
  "PRICING.UNLIMITED_VENUES": "不限门店数量",
  "PRICING.PER_VENUE": "每个门店",
  "PRICING.RECIVE_ORDERES_WITH_PAYMENT": " Recibir pedidos con pago en ",
  "PRICING.CASH_CARD": "现金/卡支付",
  "PRICING.INTEGRATION": "嵌入支付网关",
  "PRICING.PRINTING_ORDER_BY_PRINTER":
    " Impresión de pedidos impresora bluetooth ",
  "PRICING.UPGRADE_": "升级",
  "PRICING.SINCE": "自",
  "PRICING.EVERY_THING_INCLUDED": "高级计划中包含的所有内容",
  "PRICING.WE_ARE_CREATE": "Creamos tu totalmente optimizada para ",
  "PRICING.IOS_AND_ANDROID": "iOS 和 Android",
  "PRICING.DONT_MISS": "手机的推送让您不错过任何瞬间",
  "PRICING.PUBLICATION_IN": "发布在",
  "PRICING.APP_STORE": "App Store",
  "PRICING.AND": "和",
  "PRICING.INCLUDE": "包括",
  "PRICING.ORGANIZE": "组织",
  "PRICING.EXCLUSIVE_CONTESTS": "独家比赛",
  "PRICING.FOR_ALL_APP_USERS": "适用于所有用户。",
  "PRICING.MAINTAIN_A_CLOSE_RELATIONSHIP": " 通过以下方式与您的客户更加亲近",
  "PRICING.LOYALTY_PROGRAM": "会员计划",
  "PRICING.EXCLUSIVE_OFFERS": "独家优惠",

  "PRICING.MANAGE_YOUR_RESTAURANTS_BOOKINGS": "通过智能手机管理您餐厅的预订",
  "PRICING.AND_MUCH_MORE": "还有更多......",
  "PRICING.REQUEST_INFORMATION": "请求信息",
  "PRICING.FREQUENT_QUESTIONS": "常见问题",
  "PRICING.HOW_CAN_I_UPGRADE_MY_PLAN": "如何升级我的计划？",
  "PRICING.IF_YOU_WANT_TO_UNLOCK":
    "如果您想解锁订单（无论是从任何地方订购）、无限菜单或菜单以及从设备订购的功能，只需选择上面的Advanced或Premium计划，根据您的需求。",
  "PRICING.WHAT_ARE_AVAILABLE_PAYMENT_METHOD": "有哪些可用的支付方式？",
  "PRICING.YOU_CAN_PAY":
    "您可以使用信用卡支付，来自所有国家。如果支付有问题，请与我们联系，我们将尽快解决。",
  "PRICING.WHO_CAN_ACCESS_MY_QR_CODE": "谁可以访问我的QR码？",
  "PRICING.YOUR_CODE_CAN":
    "任何人都可以扫描您的代码。这意味着您在越多地方发布和传播您的代码，越多人会看到您的餐厅和菜单。",
  "PRICING.HOW_CURRENT_ACCURATE": "收集的数据有多新和准确？",
  "PRICING.ALL_DATA_IS_COLLECTED":
    "所有数据都是实时收集的，因此我们每天生成统计数据。我们每天努力提供您业务的最新和最真实的信息。",
  "PRICING.CAN_I_EDIT": "我可以自己编辑菜单吗？",
  "PRICING.YES_YOU_WILL_HAVE_ACCESS":
    "是的！您将可以访问一个非常简单的管理器，可以更改菜品、照片、价格、描述等等...",
  "PRICING.WHAT_ARE_THE_FEES": "费用是多少？",
  "PRICING.AT_BANZZU_WE_DO_NOT":
    "在Banzzu，我们不收取任何佣金，您看到的就是您支付的。",
  "PRICING.IF_I_AM_OTHER": "如果我与其他配送公司合作，我可以使用Banzzu吗？",
  "PRICING.YES_OUR_RECOMMENDATION":
    "是的！我们建议您继续通过这种方式推广，并将所有新客户引荐到Banzzu，因为我们不收取佣金。",
  "PRICING.CAN_THE_CUSTOMER":
    "客户可以从他们的设备上下订单吗？并且从同一个设备支付？",
  "PRICING.YES_THANK_TO_BANZZU":
    "感谢Banzzu，您的所有客户都可以从自己的智能手机下订单，包括付款。",

  "PRICING.WHAT_TYPE_OF_RESTAURANT": "什么类型的餐厅最适合使用Banzzu？",
  "PRICING.BANZZU_IS_SOFTWARE":
    "Banzzu是一款为各种类型的餐厅设计的软件。将您的餐厅数字化，并应用新的技术改进，无论您经营什么类型的餐厅，都可以通过Banzzu开始优化您的业务。",
  "PRICING.CAN_I_CREATE": "我可以创建多个菜单吗？",
  "PRICING.YES_ONE_OF":
    "是的！我们的Advanced和Premium计划之一的好处是无限菜单。放飞您的想象力，创建白天、晚上或节假日菜单。Banzzu让一切成为可能。",
  "UPGRADE_MODAL.YOUR_PLAN_IS_TOO_SMALL": "您的计划太小了",
  "UPGRADE_MODAL.ITS_SEEMS": "哎呀！似乎您当前的计划不包括这些功能。",
  "UPGRADE_MODAL.INCREASE_PLAN": "升级您的计划，享受Banzzu提供的所有可能性。",
  "UPGRADE_MODAL.UPGRADE_MY_PLAN": "升级我的计划",
  "UPGRADE_MODAL.I_STICK": "我继续使用我的计划",
  "PRICING.14_DAY_FREE_TRAIL": "14天免费试用",
  "PRICING.GET_THIS_PLAN": "获取此计划",
  "PRICING.START_FREE_TRAIL": "开始免费试用",
  "PRICING.YOU_WILL_NOT_BE": "在免费试用期间不会收取费用，您可以随时取消订阅。",
  "TOOLBAR.VIEW_WEB": "查看您的网站",
  "TOOLBAR.IMPROVE_PLAN": "改善您的计划",
  "MERCHANDISE.ITEM.QR_TITLE": "您的定制QR码",
  "MERCHANDISE.ITEM.QR_TEXT": "扫描我来下单！",
  "MERCHANDISE.ITEM.QR_SUB_TITLE":
    "想象一下您的QR码在所有桌子上？现在是可能的！",
  "MERCHANDISE.ITEM.QR_DESCRIPTION":
    "购买定制标签上的QR码。我们会将它们发送给您，您只需贴上，让您的客户扫描。",
  "MERCHANDISE.ITEM.QR_CUSTOMIZE": "定制您的QR码标签",
  "MERCHANDISE.ITEM.QR_QUANTITY_TITLE": "您想要打印多少个QR码标签？",
  "MERCHANDISE.ITEM.ADDRESS_TITLE": "输入邮寄地址",
  "MERCHANDISE.ITEM.SEND_TO_RESTAURANT": "使用餐厅地址",
  "MERCHANDISE.ITEM.CREATE_MENUE": "我们为您创建菜单",
  "MERCHANDISE.ITEM.CREATE_MENUE_DESCRIPTION":
    "我们帮助您轻松拥有完美的在线菜单，您只需向我们发送您的菜单PDF文件，我们将为您进行数字化处理。",
  "MERCHANDISE.ITEM.DELIVERY_FLEET": "您的送餐车队",

  "MERCHANDISE.ITEM.DELIVERY_FLEET_DESCRIPTION":
    "现在你可以按小时雇佣自己的送餐队伍。我们为您提供所需的送餐员和工作时间，以满足您的需求。",
  "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF": "在线介绍",
  "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF_DESCRIPTION":
    "在Delitbee的送餐比价平台上推广您的数字菜单，吸引更多客户。",
  "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE": "创建您的企业网站",
  "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE_DESCRIPTION":
    "除了使用Banzzu创建的订餐部分外，您可能还想要一个展示您餐厅独特魅力的企业网站。",
  "MERCHANDISE.UP_SELL_SEND_EMAIL_MESSAGE":
    "已成功接收您的信息请求。我们将尽快与您联系，提供更多详细信息。",
  "MERCHANDISE.WANT_IT": "我要它",
  "CATALOGUE.CREATE_PRODUCT": "创建产品",
  "CATALOGUE.CREATE_MODIFIER": "创建修改器",
  "CATALOGUE.CREATE_GROUPMODIFIER": "创建修改器组",
  "CATALOGUE.SKU": "SKU",
  "CATALOGUE.MULTISIZE": "多尺寸",
  "CATALOGUE.SIMPLE": "简单",
  "CATALOGUE.PRODUCT_LIST": "产品列表",
  "CATALOGUE.PRODUCT_DETAIL": "产品详情",
  "CATALOGUE.PRODUCT_NEW": "新产品",
  "CATALOGUE.ADD_TAMAÑO": "添加尺寸",
  "CATALOGUE.MODIFIER_LIST": "修改器列表",
  "CATALOGUE.MODIFIER_DETAIL": "修改器详情",
  "CATALOGUE.MODIFIER_NEW": "新修改器",
  "ACCOUNT.ARE_YOU_SURE": "您确定要删除您的账户吗？您将失去您创建的所有菜单！",
  "ACCOUNT.DELETE_ACCOUNT": "删除账户",
  "ACCOUNT.YOUR_CURRENT_SUBSCRIPTION": "您当前的订阅是免费的",
  "ACCOUNT.YOUR_SUBSCRIPTION": "您的订阅",
  "ACCOUNT.CURRENT_SUBSCRIPTION_IS_ADVANCED": "您当前的订阅是高级版",
  "ACCOUNT.CANCEL_SUBSCRIPTION": "取消订阅",
  "ACCOUNT.ARE_YOU_SURE_TO_CANCEL_SUBSCRIPTION":
    "您确定要取消当前的订阅，并返回免费计划吗？",
  "SETUP_ACCOUNT.ACCOUNT_ALREADY_SETUPED": "账户已设置",
  "SETUP_ACCOUNT.TITLE": "开始从未如此简单。只需设置您账户的密码。",

  "SETUP_ACCOUNT.PASSWORD": "密码",
  "SETUP_ACCOUNT.CONFIRM_PASSWORD": "确认密码",
  "SETUP_ACCOUNT.SAVE_BUTTON": "保存",
  "LANGUAGE.SPANISH": "西班牙语",
  "LANGUAGE.ENGLISH": "英语",
  "LANGUAGE.ITALIAN": "意大利语",
  "LANGUAGE.FRENCH": "法语",
  "LANGUAGE.GERMAN": "德语",
  "LANGUAGE.CATALAN": "加泰罗尼亚语",
  "LANGUAGE.EUSKERA": "巴斯克语",
  "LANGUAGE.MANDARIN": "普通话",
  "LANGUAGE.JAPANESE": "日语",
  "LANGUAGE.PORTUGUESE": "葡萄牙语",
  "LANGUAGE.RUSSIAN": "俄语",
  "LANGUAGE.GREEK": "希腊语",
  "LANGUAGE.CHINESE": "中文",
  SELECT_TAX: "选择税项",
  Virtual_Basket: "虚拟购物篮",
  TOTAL: "总计",
  PAIS_ON_VENUE: "地点支付",
  QUANTITY: "数量",
  UNIT_PRICE: "单价",
  BILLS: "账单",
  PAID_ON_VENUE_MODEL: "确定要标记此账单为已支付吗？",
  PENDIGN_ORDERS: "待支付订单",
  "NEW.ORDER_FROM_TABLE": "桌台订单",
  VIRTUAL_CARD_ORDER: "虚拟卡订单",
  VIRTUAL_CARD_ORDER_TABLE: "桌台",
  VIRTUAL_CARD_ORDER_UPDATE_ON: "创建日期",
  STRIPE_CONNECTED_ACCOUNT: "Stripe 连接账户",
  PARTIAL_PAYMENT: "部分支付",
  PAID_ON_VENUE: "地点支付",
  FULL_PAYMENT: "全额支付",
  TABLE_CARD_ITEM: "产品",
  SAVE_CHANGES: "保存更改",
  ADD_EXTRA_OPTIONS: "添加额外选项",
  ADD_PRODUCT_IN: "在...中添加产品",
  VIRTUAL_CARD_ORDER_NUMBER: "账号编号",
  ROZAN_SOCIAL: "公司名称",
  CIF: "CIF（税号）",
  MENU: "菜单",
  AUTO_SERVICES: "自动服务",
  PREPARE_MINUTES: "准备时间（分钟）",

  RANGE: "范围",
  EXTRA_TIME: "额外时间",
  BEHAVIOUR: "行为",
  ADD_RANGE: "添加范围",
  AUTOSERVICE_ALERT_TITLE: "确认删除",
  AUTOSERVICE_ALERT_BODY: "您确定要删除吗？",
  DELETE_TABLE_CONFIRMATION_MESSAGE: "您确定要删除此行吗？",
  MENU_ID: "菜单ID",
  CONFIRM_DELETE_ALERT_TITLE: "确认删除",
  CONFIRM_DELETE_ALERT_BODY: "您确定要删除吗？",
  PARTIAL_DELIVERY: "部分交付",
  IN_PROGRESS: "进行中",
  WHATSAPP: "WhatsApp",
  SERVICE: "额外服务",
  REFOUND: "退款",
  FULL_REFOUND: "全额退款",
  PARTIAL_REFOUND: "部分退款",
  AMOUNT: "金额",
  CONFIRMATION: "确认",
  "SPLIT_BILL_MODEL.AMONT_IS_MANDATORY": "金额是必填项",
  "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID":
    "金额超过应付金额",
  TICKET_NUMBER: "票号",
  REFUNDED_AMOUNT: "退款金额",
  TIP: "小费",
  ENABLE: "启用",
  DISABLE: "禁用",
  DRINKS: "饮料",
  FOOD: "食物",
  BILL_DETAIL: "账单明细",
  TIPS_RANGE: "选择日期",
  SHOW_TIPS: "显示小费",
  TIPS_LIST: "小费列表",
  TOTAL_TIPS: "总小费",
  FETCH_PRODUCTS: "更新产品",
  DISCONNECTED_PRODUCTS: "与POS断开连接",
  POS_PRODUCTS: "POS产品",
  ALL_CATEGORIES: "所有类别",
  ERROR_FETCHING_ORDERS: "更新订单时出错",
  "SIDEBAR_MENUE.PAYMENT_HISTORY": "付款历史",
  STRIPE_ID: "Stripe ID",
  PAID_ON_CASH: "现金支付",

  PARTIAL_PAYMENT_CASH: "现金部分支付",
  PAYMENT_TYPE: "支付类别",
  GENERAL_CASH: "现金支付",
  GENERAL_CARD: "信用卡",
  GENERAL_MIXED_PARTIAL: "现金和银行卡",
  GENERAL_LANGUAGE: "语言",
  GENERAL_LANGUAGES: "语言",
  MENU_CATEGORY_DIVIDE: "划分类别",
  MENU_PRODUCT_FAVORITE: "添加至推荐菜",
  BRANDING_PRIMARY_COLOUR: "主色调",
  BRANDING_SECONDARY_COLOUR: "辅助颜色",
  BRANDING_TERTIARY_COLOUR: "三级颜色",
  "GENERAL.RATING": "评论",
  AVERAGE_RATING: "均分",
  LOYALTY_PROGRAM: "会员计划",
  RATING_RANGE: "数值范围",
  PAY_BY_CARD: "银行卡",
  COMPLETE_ORDER: "已全部交货",
  LOYALTY_CATEGORY_BRONZE: "青铜",
  LOYALTY_CATEGORY_SILVER: "白银",
  LOYALTY_CATEGORY_GOLD: "黄金",
  LOYALTY_CATEGORY_PLATINUM: "铂金",
  LOYALTY_PROGRAM_DETAIL: "客户详情",
  LOYALTY_PROGRAM_CLIENT_AVERAGE: "客单价",
  LOYALTY_PROGRAM_CLIENT_TIP_NUMBER: "小费数",
  LOYALTY_PROGRAM_CLIENT_TIP_TOTAL: "小费总数",
  LOYALTY_PROGRAM_CLIENT_ORDER_NUMBER: "订单数",
  LOYALTY_PROGRAM_CLIENT_ORDER_TOTAL: "总开支",
  LOYALTY_PROGRAM_CLIENT_FREQUENCY: "订单频次",
  LOYALTY_PROGRAM_FREQUENCY_HIGH: "高",
  LOYALTY_PROGRAM_FREQUENCY_MEDIUM: "中",
  LOYALTY_PROGRAM_FREQUENCY_LOW: "低",
  VISIBILITY_DESCRIPTION: "可见时间段描述",
  SET_MENU_VISIBILITY: "菜单有效期",
  DELIVERY_DATE: "送货日期",
  DELIVERY_TIME: "送花时间",
  MENU_VISIBILITY_DESCRIPTION: "禁用菜单-说明",
  GENERATE_SKU: "生成产品编号",
  SELECT_MODIFIERS: "选择选项",
  SELECT_GROUP_MODIFIERS: "选择选项组",
  SELECT_PRODUCT: "选择产品",
  ACTION: "操作",
  VISIBILITY: "可见时间段",
  PRINT_TICKET: "打印票据",

  GLOBALL_VISIBILITY: "将可见性应用于同一产品",
  TICKET_PRINT: "票据打印",
  POPUP: "弹出窗口",
  POPUP_SWITCH: "启用弹出窗口",
  PROMOTION_DETAIL: "促销详情",
  NEW_PROMOTION: "新促销",
  TOTAL_RATINGS: "总评分数",
  TOTAL_5_RATINGS: "5星评分数",
  FILTER: "筛选",
  FILTER_RANGE: "筛选范围",
  APPLY: "应用",
  REVIEW: "评价",
  READY_TO_PICK: "准备好取货",
  PREPARATION: "准备中",
  SELECT_VENUE_TO_PROCEED: "选择门店以便继续",
  SUBMIT: "提交",
  "BRANDING.AUTOSERVICE_SCREEN_COLOR": "选择自助取货屏幕颜色",
  AUTOSERVICE_SCREEN_LOGO: "上传自助取货屏幕Logo",
  COMBO_LIST: "套餐列表",
  COMBOS: "套餐",
  COMBO_DETAIL: "套餐详情",
  CREATE_COMBO: "创建套餐",
  NEW_COMBO: "新套餐",
  NEW_CATEGORY: "新类别",
  ADD_PRODUCT_TO_CATEGORY: "添加产品到类别",
  ADD_PRODUCT: "添加产品",
  CATEGORIES: "类别",
  COMBO_SUPPLEMENT_PRICE: "套餐附加价格",
  SELECT_COMBO: "选择套餐",
  ADD_COMBO: "添加套餐",
  TOTAL_AMOUNT: "总金额",
  SELECT_PAYMENT_TYPE: "选择支付类型",
  "GENERAL.START_DATE": "开始日期",
  "GENERAL.END_DATE": "结束日期",
  GENERAL_ALL: "全部",
  ALL_RESULTS: "所有结果",
  AVERAGE_REVIEW: "平均评价",
  NUMBER_OF_PAYMENTS: "支付次数",
  POS_TITLE: "POS标题",
  "SIDEBAR_MENUE.GROUPMODIFIER_LIST": "选项组列表",
  Table_Tarif: "价格表",
  CATEGORY_SORTED: "类别已排序",
  PRODUCT_SORTED: "产品已排序",
  SORTING_FAILED: "排序失败",
  FOR_FREE: "免费",
  AUTOSERVICE_LOGO: "上传自助服务Logo",
  STOCK: "库存",
  STOCK_QUANTITY: "库存数量",
  POS_PROMOTIONS: "POS促销",
  GENERAL_PRODUCTS: "产品",
  GENERAL_COMBOS: "套餐",
  "MENU_CATEGORY_PAGE.COMBO_LIST": "套餐列表",
  MESSAGE_NO_DATA_FOUND: "未找到数据",
  EXTRA_OPTIONS: "额外选项",
  KIOSK_SETTINGS: "自助服务设置",
  MAIN_COLOR: "主颜色",
  SECONDARY_COLOR: "次颜色",
  LEFT_COLOR: "左侧颜色",
  RIGHT_COLOR: "右侧颜色",
  UPLOAD_ENTRY_LOGO_IMAGE: "上传入口标志图片",
  UPLOAD_ENTRY_LOGO_VIDEO: "上传入口标志视频",
  UPLOAD_NAME_SCREEN_LOGO: "名称屏幕徽标",
  UPLOAD_ORDER_TYPE_LOGO: "上传订单类型标志",
  UPLOAD_CATEGORY_LOGO: "上传类别标志",
  UPLOAD_PAYMENT_LOGO: "上传支付标志",
  UPLOAD_AUTO_SERVICE_LOGO: "上传自动服务标志",
  TABLES: "表格",
  MENUS: "菜单",
  ENTRY_LOGO_TYPE: "入口标志类型",
  "SIDEBAR_MENUE.KIOSK_MODE": "自助点餐机",
  SCHEDULE_ORDER_CREATION_DATE: "计划订单创建日期",
  SCHEDULE_ORDER_CREATION_TIME: "排定訂單建立時間",
};
