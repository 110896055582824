import moment from "moment";

export const isEmailValid = (email: string): boolean => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const formatDate = (date) => {
  try {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  } catch (ex) {}
};

export const formatDateTime = (date) => {
  try {
    const momentWrappedDate = moment(date);
    if (!momentWrappedDate.isValid()) {
      return "";
    }
    return momentWrappedDate.format("DD/MM/YYYY - kk:mm");
  } catch (ex) {}
};

export const createDate = (date, time) => {
  try {
    const day = date.substring(0, 2);
    const month = date.substring(3, 5);
    const year = date.substring(6);

    const hour = time.substring(0, 2);
    const minute = time.substring(3);

    const d = new Date(year, month - 1, day, hour, minute);

    return d;
  } catch (ex) {}
};
