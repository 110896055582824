import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";

import { FusePageCarded } from "@fuse";
import { Tab, Tabs } from "@material-ui/core";
import History from "@history";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { errorAlert } from "app/helpers/alerts";
import MenuListHeader from "./MenuItemV2ListHeader";
import MenuCategory from "../menuV2Category/MenuV2Category";
import MenuCategoryHeader from "../menuV2Category/MenuV2CategoryHeader";
import DraggableListTable from "app/main/common/DraggableList/DraggableListTable";
import {
  deleteMenuV2Item,
  getMenuItemCategoryV2,
  getMenuItemV2List,
  getMenuV2Detail,
  sortMenuListV2,
} from "app/store/menuV2/MenuV2Actions";

const MenuItemV2List = ({ match }) => {
  const [tabValue, setTabValue] = useState(0);

  let loading = useSelector(({ banzzu }) => banzzu.menuV2.loading);
  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemCategory
  );
  const menuDetail = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  const searchCategory = useSelector(
    ({ banzzu }) => banzzu.menuV2.searchCategory
  );
  const menuList = useSelector(({ banzzu }) => banzzu.menuV2.menuV2ItemList);
  const [list, setList] = useState(menuList);

  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();

  const { productId } = match.params;

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  const createProductHandler = () => {
    if (categoryList.length < 1) {
      errorAlert(dispatch, "Please add at least one category");
      setTabValue(1);
    } else {
      History.push({
        pathname: `/menuItemDetail/${productId}/new/`,
      });
    }
  };

  const onHandleDelete = (selected) => {
    dispatch(deleteMenuV2Item(selected, productId));
  };

  const handleClick = (item) =>
    History.push("/menuV2/menuItemDetail/" + productId + "/" + item._id);

  const sortVoucherList = (sortOrder) => {
    let menuOrder = {
      ...sortOrder,
      categoryId: list.find((menu) => menu._id === sortOrder.id).categoryId,
    };

    const { from, to } = sortOrder;

    const sortedMenuList = [...list];
    let movedMenu = sortedMenuList.splice(from, 1);
    sortedMenuList.splice(to, 0, _.first(movedMenu));
    setList(sortedMenuList);

    dispatch(sortMenuListV2(menuOrder, productId));
  };

  useEffect(() => {
    let newData =
      (searchCategory && searchCategory.length === 0) || searchCategory === "0"
        ? menuList
        : _.filter(menuList, (item) => item.categoryId === searchCategory);

    setList(newData);
  }, [menuList, searchCategory]);

  useEffect(() => {
    dispatch(getMenuItemCategoryV2(productId));
    dispatch(getMenuItemV2List(productId));
    dispatch(getMenuV2Detail(productId));
  }, [dispatch, productId]);

  const headerRows = {
    data: [
      {
        id: "image",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.IMAGE"],
        sort: true,
        type: "image",
      },
      {
        id: "title",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.TITLE"],
        sort: true,
        type: "text",
      },
      {
        id: "price",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.PRICE"],
        sort: true,
        type: "text",
      },
    ],
    checkbox: true,
    clickable: true,
  };

  const duplicateMenuHandler = (selected) => {};

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          content: "flex",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          <>
            {tabValue === 0 && (
              <MenuListHeader
                productName={menuDetail.title}
                createProductHandler={createProductHandler}
              />
            )}
            {tabValue === 1 && (
              <MenuCategoryHeader productName={menuDetail.title} />
            )}
          </>
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["MENU_ITEM_LIST_PAGE.PRODUCT_LIST"]}
            />
            <Tab
              className="h-64 normal-case"
              label={languageStrings["MENU_ITEM_LIST_PAGE.CATEGORIES"]}
            />
          </Tabs>
        }
        content={
          <>
            {tabValue === 0 && (
              <DraggableListTable
                handleClick={handleClick}
                handleDelete={onHandleDelete}
                handleDuplicate={duplicateMenuHandler}
                duplicate
                headerRows={headerRows}
                data={list}
                sortList={sortVoucherList}
              />
            )}
            {tabValue === 1 && <MenuCategory productId={productId} />}
          </>
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default MenuItemV2List;
