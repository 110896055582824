import React from "react";
import { Typography } from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CategoryItem from "./CategoryItem";

const CategoryList = ({
  data,
  comboId,
  onDeleteCategory,
  addProduct,
  deleteProduct,
  onEditCategory,
  onDragEnd,
  onCategoryUpdate,
}) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <>
      <Typography variant="h5" component="h4" className="mb-16">
        {languageStrings["CATEGORIES"]}
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {data &&
                data.map((category, index) => {
                  return (
                    <CategoryItem
                      key={category._id}
                      index={index}
                      category={category}
                      comboId={comboId}
                      onDeleteCategory={onDeleteCategory}
                      onDeleteProduct={(product) => {
                        deleteProduct(product, category._id);
                      }}
                      onSaveProduct={(product) => {
                        addProduct(product, category._id);
                      }}
                      deleteProduct={(product) => {
                        deleteProduct(product, category._id);
                      }}
                      onEditCategory={onEditCategory}
                      onCategoryUpdate={onCategoryUpdate}
                    />
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default withReducer("combosReducer", reducer)(CategoryList);
