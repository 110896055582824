import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  Icon,
  Typography,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import LoadingOverlay from "react-loading-overlay";
import {
  createKioskBranding,
  getKioskBranding,
} from "app/store/kiosk/KioskActions";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { FuseUtils } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { ChromePicker } from "react-color";
import FilePicker from "./components/FilePicker";
import { getVenueList } from "app/store/venues/VenuesActions";
import { getTableList } from "app/store/orders/OrdersActions";
import { getMenuV2List } from "app/store/menuV2/MenuV2Actions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
}));

function KioskSettings() {
  const classes = useStyles();
  const { form, setForm } = useForm({
    colors: {
      main: "",
      secondary: "",
      left: "",
      right: "",
    },
    entryLogo: {
      type: "image",
      video: null,
      image: null,
    },
    nameScreenLogo: null,
    orderTypeLogo: null,
    paymentLogo: null,
    autoserviceLogo: null,
  });
  const dispatch = useDispatch();
  const loading = useSelector(({ banzzu }) => banzzu.user.loading);
  const clientId = useSelector(({ banzzu }) => banzzu.auth.user._id);
  const { languageStrings } = useLanguageHelper();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const venueLoading = useSelector(({ banzzu }) => banzzu.venue.loading);
  const tableList = useSelector(({ banzzu }) => banzzu.order.tableList);
  const tableLoading = useSelector(({ banzzu }) => banzzu.order.loading);
  const { loading: menuLoading = false, menuV2List: menuList = [] } =
    useSelector((state) => state.banzzu.menuV2);
  const kioskLoading = useSelector(({ banzzu }) => banzzu.kiosk.loading);
  const branding = useSelector(({ banzzu }) => banzzu.kiosk.branding);
  const [tabValue, setTabValue] = useState(0);
  const [selectedVenue, setSelectedVenue] = useState({});
  const [selectedTable, setSelectedTable] = useState({});
  const [selectedMenu, setSelectedMenu] = useState({});
  const { _id = "" } = selectedVenue ? selectedVenue : {};

  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);
  useEffect(() => {
    if (selectedVenue._id && selectedVenue._id !== "") {
      dispatch(getTableList(selectedVenue._id));
    }
  }, [dispatch, selectedVenue]);
  useEffect(() => {
    if (venueList.length > 0 && _id === "") {
      setSelectedVenue(venueList[0]);
    }
  }, [_id, venueList]);
  useEffect(() => {
    if (tableList.length > 0 && !selectedTable._id) {
      setSelectedTable(tableList[0]);
    }
  }, [selectedTable._id, tableList]);

  useEffect(() => {
    if (menuList.length > 0 && !selectedMenu._id) {
      setSelectedMenu(menuList[0]);
    }
  }, [menuList, selectedMenu._id]);
  useEffect(() => {
    dispatch(getMenuV2List());
  }, [dispatch]);

  useEffect(() => {
    if (clientId) {
      dispatch(getKioskBranding(clientId));
    }
  }, [dispatch, clientId]);

  useEffect(() => {
    if (branding && branding._id) {
      setForm({
        _id: branding._id,
        colors: {
          main: branding.colors.main,
          secondary: branding.colors.secondary,
          left: branding.colors.left,
          right: branding.colors.right,
        },
        entryLogo: {
          type: branding.entryLogo.type,
          video: branding.entryLogo.video,
          image: branding.entryLogo.image,
        },
        nameScreenLogo: branding.nameScreenLogo,
        orderTypeLogo: branding.orderTypeLogo,
        paymentLogo: branding.paymentLogo,
        autoserviceLogo: branding.autoserviceLogo,
      });
      setSelectedMenu(branding.menuId);
      setSelectedTable(branding.tableId);
      setSelectedVenue(branding.venueId);
    }
  }, [dispatch, branding, setForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }
  const onFileChange = (e, name, type, entryLogo) => {
    e.preventDefault();
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    if (type === "video") {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (entryLogo) {
          setForm((form) => ({
            ...form,
            entryLogo: {
              ...form.entryLogo,
              [name]: e.target.result,
              [`${name}File`]: initialFile,
            },
          }));
        } else {
          setForm((form) => ({
            ...form,
            [name]: e.target.result,
            [`${name}File`]: initialFile,
          }));
        }
      };
      reader.readAsDataURL(initialFile);
    } else {
      FuseUtils.lighterImage(initialFile).then((file) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => {
          if (entryLogo) {
            setForm((form) => ({
              ...form,
              entryLogo: {
                ...form.entryLogo,
                [name]: `data:${file.type};base64,${btoa(reader.result)}`,
                [`${name}File`]: file,
              },
            }));
          } else {
            setForm((form) => ({
              ...form,
              [name]: `data:${file.type};base64,${btoa(reader.result)}`,
              [`${name}File`]: file,
            }));
          }
        };

        reader.onerror = function () {
          console.log("error on load image");
        };
      });
    }
  };
  const onLanguageChange = (e, name) => {
    onFileChange(e, name);
  };

  const canBeSubmitted = () => {
    return (
      form.colors.right &&
      form.colors.left &&
      form.colors.main &&
      form.colors.secondary &&
      form.entryLogo.type &&
      (form.entryLogo.video || form.entryLogo.image) &&
      form.nameScreenLogo &&
      form.orderTypeLogo &&
      form.paymentLogo &&
      form.autoserviceLogo &&
      selectedMenu &&
      selectedVenue &&
      selectedTable
    );
  };
  const isLoading =
    loading || menuLoading || venueLoading || tableLoading || kioskLoading;

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: isLoading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {languageStrings["KIOSK_SETTINGS"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <div>
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    className="whitespace-no-wrap"
                    variant="contained"
                    disabled={!canBeSubmitted()}
                    onClick={() => {
                      const _form = {
                        ...form,
                        clientId,
                        venueId: selectedVenue._id,
                        menuId: selectedMenu._id,
                        tableId: selectedTable._id,
                      };
                      dispatch(createKioskBranding(_form));
                    }}
                  >
                    <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                      save
                    </Icon>
                    {languageStrings["GENERAL.SAVE"]}
                  </Button>
                </FuseAnimate>
              </div>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div>
              <div className="flex flex-1 w-full items-center justify-between"></div>
              <div className="flex flex-1 w-full items-center justify-between">
                <FormControl className="w-full ml-10 mr-10 " variant="outlined">
                  <InputLabel
                    className="mt-10 ml-10 mr-10"
                    htmlFor="outlined-venue-native-simple"
                  >
                    {languageStrings["ORDER_FROM_TABLE.VENUES"]}
                  </InputLabel>
                  <Select
                    value={_id}
                    className="mt-10 mb-16 mr-8"
                    native
                    required
                    onChange={(e) => {
                      const _venueDetail = venueList.find(
                        (item) => item._id === e.target.value
                      );
                      setSelectedVenue(_venueDetail);
                    }}
                    input={
                      <OutlinedInput
                        name="venues"
                        labelWidth={100}
                        id="outlined-venue-native-simple"
                      />
                    }
                  >
                    {venueList.map((venue) => (
                      <option value={venue._id}>{venue.title}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex flex-1 w-full items-center justify-between">
                <FormControl className="w-full ml-10 mr-10 " variant="outlined">
                  <InputLabel
                    className="mt-3 ml-10 mr-10"
                    htmlFor="outlined-venue-native-simple"
                  >
                    {languageStrings["TABLES"]}
                  </InputLabel>
                  <Select
                    value={selectedTable._id ? selectedTable._id : ""}
                    className="mt-3 mb-16 mr-8"
                    native
                    required
                    onChange={(e) => {
                      const _tableDetail = tableList.find(
                        (item) => item._id === e.target.value
                      );
                      setSelectedTable(_tableDetail);
                    }}
                    input={
                      <OutlinedInput
                        name="tables"
                        labelWidth={100}
                        id="outlined-venue-native-simple"
                      />
                    }
                  >
                    {tableList.map((table) => (
                      <option value={table._id}>{table.title}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex flex-1 w-full items-center justify-between">
                <FormControl className="w-full ml-10 mr-10 " variant="outlined">
                  <InputLabel
                    className="mt-3 ml-10 mr-10"
                    htmlFor="outlined-venue-native-simple"
                  >
                    {languageStrings["MENUS"]}
                  </InputLabel>
                  <Select
                    value={selectedMenu._id ? selectedMenu._id : ""}
                    className="mt-3 mb-16 mr-8"
                    native
                    required
                    onChange={(e) => {
                      const _menuDetail = menuList.find(
                        (item) => item._id === e.target.value
                      );
                      setSelectedMenu(_menuDetail);
                    }}
                    input={
                      <OutlinedInput
                        name="tables"
                        labelWidth={100}
                        id="outlined-venue-native-simple"
                      />
                    }
                  >
                    {menuList.map((menu) => (
                      <option value={menu._id}>{menu.title}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex flex-1 w-full items-center justify-between">
                <FormControl className="w-full ml-10 mr-10 " variant="outlined">
                  <InputLabel
                    className="mt-3 ml-10 mr-10"
                    htmlFor="outlined-venue-native-simple"
                  >
                    {languageStrings["ENTRY_LOGO_TYPE"]}
                  </InputLabel>
                  <Select
                    value={form.entryLogo.type}
                    className="mt-3 mb-16 mr-8"
                    native
                    required
                    onChange={(e) => {
                      setForm((form) => ({
                        ...form,
                        entryLogo: {
                          ...form.entryLogo,
                          type: e.target.value,
                        },
                      }));
                    }}
                    input={
                      <OutlinedInput
                        name="entryLogoType"
                        labelWidth={150}
                        id="outlined-venue-native-simple"
                      />
                    }
                  >
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                  </Select>
                </FormControl>
              </div>
              <Typography variant="h6" className="ml-32 mt-10 ">
                {languageStrings["BRANDING.CHOOSE_COLOR"]}
              </Typography>
              <div className="flex items-center m-32 justify-between max-w-3/4 mb-40">
                <div className="ml-8 text-center">
                  <Typography variant="subtitle1" className="mb-6">
                    {languageStrings["MAIN_COLOR"]}
                  </Typography>
                  <ChromePicker
                    color={form.colors.main}
                    disableAlpha
                    onChange={(color) => {
                      setForm((form) => ({
                        ...form,
                        colors: {
                          ...form.colors,
                          main: color.hex,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="ml-8 text-center">
                  <Typography variant="subtitle1" className="mb-6">
                    {languageStrings["SECONDARY_COLOR"]}
                  </Typography>
                  <ChromePicker
                    color={form.colors.secondary}
                    disableAlpha
                    onChange={(color) => {
                      setForm((form) => ({
                        ...form,
                        colors: {
                          ...form.colors,
                          secondary: color.hex,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="ml-8 text-center">
                  <Typography variant="subtitle1" className="mb-6">
                    {languageStrings["LEFT_COLOR"]}
                  </Typography>
                  <ChromePicker
                    color={form.colors.left}
                    disableAlpha
                    onChange={(color) => {
                      setForm((form) => ({
                        ...form,
                        colors: {
                          ...form.colors,
                          left: color.hex,
                        },
                      }));
                    }}
                  />
                </div>
                <div className="ml-8 text-center">
                  <Typography variant="subtitle1" className="mb-6">
                    {languageStrings["RIGHT_COLOR"]}
                  </Typography>
                  <ChromePicker
                    color={form.colors.right}
                    disableAlpha
                    onChange={(color) => {
                      setForm((form) => ({
                        ...form,
                        colors: {
                          ...form.colors,
                          right: color.hex,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-1 w-full items-center justify-between mb-10">
                {form.entryLogo.type === "video" ? (
                  <FilePicker
                    title={languageStrings["UPLOAD_ENTRY_LOGO_VIDEO"]}
                    file={form.entryLogo.video}
                    type="video"
                    onChange={(e) => {
                      onFileChange(e, "video", "video", true);
                    }}
                    name={"entryLogoVideo"}
                  />
                ) : (
                  <FilePicker
                    title={languageStrings["UPLOAD_ENTRY_LOGO_IMAGE"]}
                    file={form.entryLogo.image}
                    onChange={(e) => {
                      onFileChange(e, "image", "image", true);
                    }}
                    name={"entryLogoImage"}
                  />
                )}
                <FilePicker
                  title={languageStrings["UPLOAD_NAME_SCREEN_LOGO"]}
                  file={form.nameScreenLogo}
                  onChange={(e) => {
                    onLanguageChange(e, "nameScreenLogo");
                  }}
                  name={"nameScreenLogo"}
                />
              </div>
              <div className="flex flex-1 w-full items-center justify-between mb-10">
                <FilePicker
                  title={languageStrings["UPLOAD_ORDER_TYPE_LOGO"]}
                  file={form.orderTypeLogo}
                  onChange={(e) => {
                    onFileChange(e, "orderTypeLogo");
                  }}
                  name={"orderTypeLogo"}
                />
                <FilePicker
                  title={languageStrings["UPLOAD_PAYMENT_LOGO"]}
                  file={form.paymentLogo}
                  onChange={(e) => {
                    onFileChange(e, "paymentLogo");
                  }}
                  name={"paymentLogo"}
                />
              </div>
              <div className="flex flex-1 w-full items-center justify-between mb-10">
                <FilePicker
                  title={languageStrings["UPLOAD_AUTO_SERVICE_LOGO"]}
                  file={form.autoserviceLogo}
                  onChange={(e) => {
                    onFileChange(e, "autoserviceLogo");
                  }}
                  name={"autoserviceLogo"}
                />
              </div>
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("MenuReducer", reducer)(KioskSettings);
