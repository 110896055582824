import { VenuesActionTypes } from "./../redux/actionTypes";

const INITIAL_STATE: VenueState = {
  venueList: [],
  venueDetail: {},
  loading: false,
  success: false,
  searchText: "",
  deliveryAvailability: [],
  collectionAvailability: [],
  autoServicesList:[],
  emailTaken: false,
  taxes: [],
  selectedVenue: {},
};

interface Action {
  payload: any;
  type: string;
}

const VenuesReducer = (
  state: VenueState = INITIAL_STATE,
  action: Action
): VenueState => {
  switch (action.type) {
    case VenuesActionTypes.VENUES_LIST_START: {
      return { ...state, loading: true };
    }
    case VenuesActionTypes.VENUES_LIST_SUCCESS: {
      return {
        ...state,
        venueList: action.payload,
        loading: false,
      };
    }
    case VenuesActionTypes.VENUES_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VenuesActionTypes.SET_VENUES_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    }

    case VenuesActionTypes.VENUES_DETAIL_START: {
      return { ...state, loading: true, venueDetail: {} };
    }
    case VenuesActionTypes.VENUES_DETAIL_SUCCESS: {
      return {
        ...state,
        venueDetail: action.payload,
        loading: false,
      };
    }
    case VenuesActionTypes.VENUES_DETAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VenuesActionTypes.VENUES_CREATE_START: {
      return { ...state, loading: true, success: false };
    }
    case VenuesActionTypes.VENUES_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case VenuesActionTypes.VENUES_CREATE_FAIL: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }

    case VenuesActionTypes.RESET_VENUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: false,
      };
    }

    case VenuesActionTypes.VENUES_EDIT_START: {
      return { ...state, loading: true };
    }
    case VenuesActionTypes.VENUES_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case VenuesActionTypes.VENUES_EDIT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VenuesActionTypes.UPDATE_POINTS_VENUE: {
      const _state = state.venueDetail;
      const newDeliveryInfo = [..._state.deliveryInfo, action.payload];

      return {
        ...state,
        venueDetail: {
          ...state.venueDetail,
          deliveryInfo: newDeliveryInfo,
          // deliveryPoints: action.payload,
        },
      };
    }
    case VenuesActionTypes.VENUES_GET_DELIVERY_AVAILABILITY_START: {
      return {
        ...state,
        loading: true,
        deliveryAvailability: [],
      };
    }

    case VenuesActionTypes.VENUES_GET_DELIVERY_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        deliveryAvailability: action.payload.data,
      };
    }

    case VenuesActionTypes.VENUES_GET_COLLECTION_AVAILABILITY_START: {
      return {
        ...state,
        loading: true,
        collectionAvailability: [],
      };
    }

    case VenuesActionTypes.VENUES_GET_COLLECTION_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        collectionAvailability: action.payload.data,
      };
    }

    case VenuesActionTypes.VENUES_GET_COLLECTION_AVAILABILITY_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VenuesActionTypes.CHECK_EMAIL_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case VenuesActionTypes.CHECK_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        emailTaken: action.payload.emailExists,
      };
    }

    case VenuesActionTypes.CHECK_EMAIL_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case VenuesActionTypes.GET_TAXES_SUCCESS: {
      return {
        ...state,
        taxes: action.payload,
      };
    }

    case VenuesActionTypes.SET_VENUEID_SUCCESS: {
      return {
        ...state,
        selectedVenue: action.payload,
      };
    }
    // square
    case VenuesActionTypes.VENUE_ADD_SQUARE_TOKEN_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case VenuesActionTypes.VENUE_ADD_SQUARE_TOKEN_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case VenuesActionTypes.VENUE_ADD_SQUARE_TOKEN_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default VenuesReducer;
