import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  Icon,
  Typography,
  makeStyles,
  FormHelperText,
  Card,
} from "@material-ui/core";
import clsx from "clsx";
import LoadingOverlay from "react-loading-overlay";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";

import { updateClient } from "app/store/user/UserActions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import StripeGuide from "./components/StripeGuide";
import OrderCard from "./components/OrderCard";
import { getMenuList } from "app/store/menu/MenuActions";
import { PaymentTypeCard } from "./components/PaymentTypeCard";
import { paymentTypeMenu } from "./components/PaymentCardmenu";
import CustomModal from "app/main/common/CustomModal";
import DiscountVoucherTab from "./components/DiscountVoucherTab";
import EstCard from "./components/EstCard";
import { clientsSettings } from "config";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));
const isDev =
  process.env.REACT_APP_ENVIRONMENT === "staging" ||
  process.env.REACT_APP_ENVIRONMENT === "development";
const PaymentOnline = (props) => {
  const classes = useStyles();

  const { tab } = props.match.params;
  const checkTab = () => {
    if (clientsSettings.isCheck) {
      return tabValue === 0;
    }
    return tabValue === 1;
  };

  const dispatch = useDispatch();
  const loading = useSelector(({ banzzu }) => banzzu.offer.loading);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  let menuList = useSelector(({ banzzu }) => banzzu.menu.menuList);
  const { languageStrings } = useLanguageHelper();
  const [tabValue, setTabValue] = useState(parseInt(tab) || 0);
  const [showModal, setShowModal] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [createCouponVisible, setCreateCouponVisible] = useState(false);
  const [isTestMode, setIsTestMode] = useState(false);

  const errorInitial = {
    stripId: "",
  };

  const [error, setError] = useState(errorInitial);
  const {
    permission,
    stripeId,
    stripePublicKey,
    settings,
    redsysSettings,
    estimationTimeSettings,
  } = user;
  const {
    secretKey = null,
    merchantCode = null,
    merchantName = null,
    isProd = false,
  } = redsysSettings || {};
  const {
    collectionSettings = { isEnabled: false, time: 30 },
    deliverySettings = { isEnabled: false, time: 30 },
  } = estimationTimeSettings || {};
  const {
    delivery = { payByCard: false, payByCash: false },
    collection = { payByCard: false, payByCash: false },
    orderFromTable = { payByCard: false, payByCash: false },
  } = settings || {};

  const { form, handleChange, setForm } = useForm(null);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  const devStripeIdCheck =
    form && form.stripeId && form.stripeId.includes("sk_test");

  const devStripeKeyCheck =
    form && form.stripePublicKey && form.stripePublicKey.includes("pk_test");

  useEffect(() => {
    dispatch(getMenuList());
  }, []);

  useEffect(() => {
    if (permission) {
      let permissions = {
        hasDelivery: permission.hasDelivery,
        hasCollection: permission.hasCollection,
        hasOrderFromTable: permission.hasOrderFromTable,
        hasDiscountVoucher: permission.hasDiscountVoucher,
        stripeId,
        stripePublicKey,
      };

      if (
        (permissions && !form) ||
        (permissions && form && permissions._id !== form._id)
      ) {
        setForm({
          ...permissions,
          ...settings,
          delivery,
          collection,
          orderFromTable,
          secretKey,
          merchantCode,
          merchantName,
          isProd,
          collectionSettings,
          deliverySettings,
        });
        setIsTestMode(!isProd);
      }
    }
  }, [form, permission, stripeId, setForm]);
  useEffect(() => {
    if (form && permission && menuList.length && !form["menuList"]) {
      setForm({
        ...form,
        menuList,
      });
    }
  }, [permission, menuList, form, setForm]);

  useEffect(() => {
    if (
      form &&
      form.stripeId &&
      (!form.stripeId.includes("sk_live") ||
        (isDev && !form.stripeId.includes("sk_test")))
    ) {
      setError((prevState) => {
        return {
          ...prevState,
          stripeId: languageStrings["ERRORS.STRIPE_ID_INVALID"],
        };
      });
    }
  }, [stripeId, languageStrings]);

  useEffect(() => {
    if (
      form &&
      form.stripePublicKey &&
      (!form.stripePublicKey.includes("sk_live") ||
        (isDev && !form.stripePublicKey.includes("pk_test")))
    ) {
      setError((prevState) => {
        return {
          ...prevState,
          stripePublicKey: languageStrings["ERRORS.STRIPE_KEY_INVALID"],
        };
      });
    }
  }, [stripePublicKey, languageStrings]);
  function canBeSubmitted() {
    return hasNoErrors();
    // && hasNoEmpty();
  }

  function hasNoEmpty() {
    let deliveryErrors = true;
    let collectionErrors = true;
    let orderFromTableErrors = true;
    let hasErrors = true;

    if (paymentType === "Redsys") {
      hasErrors =
        form &&
        form.merchantCode !== "" &&
        form.merchantName !== "" &&
        form.secretKey !== "";
    } else {
      hasErrors =
        form &&
        form.stripeId &&
        form.stripePublicKey &&
        form.stripeId !== "" &&
        form.stripePublicKey !== "" &&
        (form.stripeId.includes("sk_live") || (isDev && devStripeIdCheck)) &&
        (form.stripePublicKey.includes("pk_live") ||
          (isDev && devStripeKeyCheck));
    }
    if (form && form.hasDelivery && form.delivery && form.delivery.payByCard) {
      deliveryErrors = hasErrors;
    }

    if (
      form &&
      form.hasCollection &&
      form.collection &&
      form.collection.payByCard
    ) {
      collectionErrors = hasErrors;
    }
    if (
      form &&
      form.hasOrderFromTable &&
      form.orderFromTable &&
      form.orderFromTable.payByCard
    ) {
      orderFromTableErrors = hasErrors;
    }
    return deliveryErrors && collectionErrors && orderFromTableErrors;
  }
  const isBothSwitchDisabled = () => {
    let deliveryErrors = true;
    let collectionErrors = true;
    let orderFromTableErrors = true;
    if (form && form.hasDelivery) {
      deliveryErrors =
        form.delivery &&
        (form.delivery.payByCard !== false ||
          form.delivery.payByCash !== false);
    }
    if (form && form.hasCollection) {
      collectionErrors =
        (form.collection && form.collection.payByCard !== false) ||
        form.collection.payByCash !== false;
    }
    if (form && form.hasOrderFromTable) {
      orderFromTableErrors =
        form.orderFromTable &&
        (form.orderFromTable.payByCard !== false ||
          form.orderFromTable.payByCash !== false);
    }

    return deliveryErrors && collectionErrors && orderFromTableErrors;
  };
  function hasNoErrors() {
    return hasNoEmpty() && isBothSwitchDisabled();
  }
  function getCheckError() {
    return (
      form &&
      form.stripeId &&
      form.stripePublicKey &&
      form.stripeId !== "" &&
      form.stripePublicKey !== "" &&
      (form.stripeId.includes("sk_live") || (isDev && devStripeIdCheck)) &&
      (form.stripePublicKey.includes("pk_live") || (isDev && devStripeKeyCheck))
    );
  }
  function errorStripeId() {
    return form && form.stripeId && !form.stripeId.includes("sk_live");
  }

  function errorStripePublicKey() {
    return (
      form && form.stripePublicKey && !form.stripePublicKey.includes("pk_live")
    );
  }
  function handleMenuChange(event) {
    const { currentTarget: input } = event;
    if (input.name == "delivery") {
      let list = form.menuList.map((item) => {
        if (item._id == input.value) {
          return {
            ...item,
            availableOnDelivery: !item.availableOnDelivery,
          };
        } else {
          return item;
        }
      });
      setForm((form) => ({
        ...form,
        menuList: list,
      }));
    }
    if (input.name == "collection") {
      let list = form.menuList.map((item) => {
        if (item._id == input.value) {
          return {
            ...item,
            availableOnCollection: !item.availableOnCollection,
          };
        } else {
          return item;
        }
      });
      setForm((form) => ({
        ...form,
        menuList: list,
      }));
    }
    if (input.name == "orderFromTable") {
      let list = form.menuList.map((item) => {
        if (item._id == input.value) {
          return {
            ...item,
            availableOnOrderFromTable: !item.availableOnOrderFromTable,
          };
        } else {
          return item;
        }
      });
      setForm((form) => ({
        ...form,
        menuList: list,
      }));
    }
  }
  const getRadioButtonValue = () => {
    let RadioBtn = "";
    if (form && form["redsys"]) {
      RadioBtn = "Redsys";
    } else {
      RadioBtn = "Stripe";
    }
    return RadioBtn;
  };
  useEffect(() => {
    setPaymentType(getRadioButtonValue());
  }, [form]);

  const handleCardPayChange = (paymentType = "", { payByCard, payByCash }) => {
    setForm({
      ...form,
      [paymentType]: { ...form[paymentType], payByCard: !payByCard },
    });
  };
  const handleCashPayChange = (paymentType = "", { payByCash, payByCard }) => {
    setForm({
      ...form,
      [paymentType]: { ...form[paymentType], payByCash: !payByCash },
    });
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <FusePageCarded
          classes={{
            toolbar: "p-0",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            form && (
              <div className="flex flex-1 w-full items-center justify-between">
                <div className="flex flex-col items-start max-w-full">
                  <div className="flex items-center max-w-full">
                    <div className="flex flex-col min-w-0">
                      <FuseAnimate
                        animation="transition.slideLeftIn"
                        delay={300}
                      >
                        <Typography className="text-16 sm:text-20 truncate">
                          {languageStrings["PAYMENT_SETTINGS_PAGE.SETTINGS"]}
                        </Typography>
                      </FuseAnimate>
                      <FuseAnimate
                        animation="transition.slideLeftIn"
                        delay={300}
                      >
                        <Typography variant="caption">
                          {languageStrings["PAYMENT_SETTINGS_PAGE.TITLE"]}
                        </Typography>
                      </FuseAnimate>
                    </div>
                  </div>
                </div>
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    className="whitespace-no-wrap"
                    variant="contained"
                    onClick={() => {
                      dispatch(updateClient({ ...form, isProd: !isTestMode }));
                    }}
                    disabled={
                      clientsSettings.isCheck
                        ? !getCheckError()
                        : !canBeSubmitted()
                    }
                  >
                    <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                      save
                    </Icon>
                    {languageStrings["GENERAL.SAVE"]}
                  </Button>
                </FuseAnimate>
              </div>
            )
          }
          contentToolbar={
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ root: "w-full h-64" }}
            >
              {clientsSettings.showOrderTab && (
                <Tab
                  className="h-64 normal-case"
                  label={
                    languageStrings["PAYMENT_SETTINGS_PAGE.ORDER_SETTINGS"]
                  }
                />
              )}

              <Tab
                className="h-64 normal-case"
                label={
                  languageStrings["PAYMENT_SETTINGS_PAGE.PAYMENT_INTEGRATION"]
                }
              />
              {clientsSettings.showDiscountVouchers && (
                <Tab
                  className="h-64 normal-case"
                  label={
                    languageStrings["PAYMENT_SETTINGS_PAGE.DISCOUNT_VOUCHERS"]
                  }
                />
              )}
            </Tabs>
          }
          content={
            form && (
              <div className="p-16 sm:p-24">
                <div>
                  {tabValue === 0 && !clientsSettings.isCheck && (
                    <>
                      <Typography variant="subtitle1" className="my-12">
                        {
                          languageStrings[
                            "PAYMENT_SETTINGS_PAGE.ONLINE_ORDERS_TITLE"
                          ]
                        }
                      </Typography>
                      <div
                        className={
                          "flex md:flex-row  md:justify-between flex-col"
                        }
                      >
                        <OrderCard
                          name={"hasDelivery"}
                          title={"PAYMENT_SETTINGS_PAGE.DELIVERY"}
                          image={"assets/images/deliveryOrder.svg"}
                          user={user}
                          form={form}
                          onChange={handleChange}
                          onMenuChange={handleMenuChange}
                          paymentType={"delivery"}
                          onCardPayChange={() =>
                            handleCardPayChange("delivery", form.delivery)
                          }
                          onCashPayChange={() =>
                            handleCashPayChange("delivery", form.delivery)
                          }
                        />
                        <OrderCard
                          name="hasCollection"
                          title={"PAYMENT_SETTINGS_PAGE.COLLECTION"}
                          image={"assets/images/collectionOrder.svg"}
                          user={user}
                          form={form}
                          onChange={handleChange}
                          onMenuChange={handleMenuChange}
                          paymentType={"collection"}
                          onCardPayChange={() =>
                            handleCardPayChange("collection", form.collection)
                          }
                          onCashPayChange={(event) =>
                            handleCashPayChange("collection", form.collection)
                          }
                        />
                        <OrderCard
                          name="hasOrderFromTable"
                          title={"PAYMENT_SETTINGS_PAGE.ORDER_FROM_TABLE"}
                          image={"assets/images/fromTableOrder.svg"}
                          user={user}
                          form={form}
                          onChange={handleChange}
                          onMenuChange={handleMenuChange}
                          paymentType={"orderFromTable"}
                          onCardPayChange={() =>
                            handleCardPayChange(
                              "orderFromTable",
                              form.orderFromTable
                            )
                          }
                          onCashPayChange={() =>
                            handleCashPayChange(
                              "orderFromTable",
                              form.orderFromTable
                            )
                          }
                        />
                      </div>

                      {!hasNoEmpty() && (
                        <FormHelperText
                          className={"mt-8 text-red cursor-pointer text-14"}
                          onClick={() => {
                            setTabValue(1);
                          }}
                        >
                          {
                            languageStrings[
                              "PAYMENT_SETTINGS_PAGE.STRIP_KEY_ERROR"
                            ]
                          }
                        </FormHelperText>
                      )}
                      <div
                        className={
                          "flex md:flex-row  md:justify-between flex-col"
                        }
                      >
                        <div className={"flex  flex-col w-full py-20 px-12"}>
                          <Typography variant="subtitle1" className="my-12">
                            {languageStrings["GENERAL.ESTIMATED_TIME"]}
                          </Typography>
                          <Card
                            className={
                              "flex flex-1 md:flex-row  md:justify-between flex-col w-full p-20"
                            }
                          >
                            <EstCard
                              name="Collection"
                              title={"TIME_SETTINGS_COLLECTION"}
                              image={"assets/images/backgrounds/payByCash.svg"}
                              form={form}
                              setForm={setForm}
                              stripeId={form.stripId}
                              value={form.collectionSettings.time}
                              checked={form.collectionSettings.isEnabled}
                              onChange={(e) => {
                                const prevData = form.collectionSettings;
                                const val = parseInt(e.target.value);

                                setForm({
                                  ...form,
                                  collectionSettings: {
                                    ...prevData,
                                    time: val,
                                  },
                                });
                              }}
                              onSwitchChange={(e) => {
                                const prevData = form.collectionSettings;
                                const toggle =
                                  !form.collectionSettings.isEnabled;

                                setForm({
                                  ...form,
                                  collectionSettings: {
                                    ...prevData,
                                    isEnabled: toggle,
                                  },
                                });
                              }}
                            />

                            <EstCard
                              name="Delivery"
                              title={"TIME_SETTINGS_DELIVERY"}
                              image={"assets/images/backgrounds/payByCard.svg"}
                              form={form}
                              setForm={setForm}
                              stripeId={form.stripId}
                              value={form.deliverySettings.time}
                              checked={form.deliverySettings.isEnabled}
                              onChange={(e) => {
                                const prevData = form.deliverySettings;
                                const val = parseInt(e.target.value);

                                setForm({
                                  ...form,
                                  deliverySettings: {
                                    ...prevData,
                                    time: val,
                                  },
                                });
                              }}
                              onSwitchChange={() => {
                                const prevData = form.deliverySettings;
                                const toggle = !form.deliverySettings.isEnabled;
                                setForm({
                                  ...form,
                                  deliverySettings: {
                                    ...prevData,
                                    isEnabled: toggle,
                                  },
                                });
                              }}
                            />
                          </Card>
                        </div>
                      </div>
                    </>
                  )}
                  {checkTab() && (
                    <>
                      <div class="grid grid-cols-2 gap-10">
                        {paymentTypeMenu.map((item) => {
                          if (
                            item.type === "Redsys" &&
                            clientsSettings.isCheck
                          ) {
                            return null;
                          }
                          return (
                            <PaymentTypeCard
                              onUserGuidePress={() => {
                                setShowModal(!showModal);
                              }}
                              handleChange={handleChange}
                              form={form}
                              onChange={() => {
                                setIsTestMode(!isTestMode);
                              }}
                              testMode={isTestMode}
                              {...item}
                              value={getRadioButtonValue()}
                              checked={getRadioButtonValue() === item.type}
                              onClick={() => {
                                if (item.type === "Redsys") {
                                  form["redsys"] = true;
                                } else {
                                  form["redsys"] = false;
                                }
                                setPaymentType(item.type);
                              }}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}

                  {tabValue === 2 && !clientsSettings.isCheck && (
                    <DiscountVoucherTab
                      createCouponVisible={createCouponVisible}
                      toggleModal={() =>
                        setCreateCouponVisible(!createCouponVisible)
                      }
                      languageStrings={languageStrings}
                      form={form}
                      handleChange={handleChange}
                      setForm={setForm}
                    />
                  )}
                </div>
              </div>
            )
          }
          innerScroll
        />
      </LoadingOverlay>
      <CustomModal
        showModal={showModal}
        onCloseModal={() => setShowModal(false)}
      >
        <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-x-auto md:max-w-2xl h-640">
          <StripeGuide />
        </div>
      </CustomModal>
    </>
  );
};

// export const PaymentOnline = () => {
//   console.log("running once");
//   return <>tax here</>;
// };

export default PaymentOnline;
