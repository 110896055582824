import React, { useEffect, useState, useRef } from "react";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getLabelWith } from "app/helpers/utilsHelper";
import { SearchableDropDown } from "app/main/common/SearchableDropDown";

export const ProductSelectionDropDown = ({
  productList = [],
  handleChangeModifier = () => {},
  selectedValue,
}) => {
  const { languageStrings } = useLanguageHelper();
  const [value, setValue] = useState("");
  const searchBarRef = useRef(null);
  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  const handleChange = (event) => {
    const val = event.target.value;
    const findGroup = productList.find((item) => item._id === val);
    if (findGroup && findGroup._id) {
      handleChangeModifier(findGroup, val);
      setValue("");
      if (searchBarRef.current) {
        searchBarRef.current.focus();
      }
    }
  };
  const { labelWith } = getLabelWith(languageStrings["SELECT_GROUP_MODIFIERS"]);
  return (
    <SearchableDropDown
      keyPropFn={(option) => option._id}
      valuePropFn={(option) => `${option.title} - ${option.sku}`}
      list={productList}
      selectedValue={value}
      label={languageStrings["SELECT_PRODUCT"]}
      handleChange={handleChange}
      labelWidth={labelWith}
      searchBarRef={searchBarRef}
    />
  );
};
