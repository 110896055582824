import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExtraCategoriesTable from "./ExtraCategoriesTableV2";
import { getMenuExtraCategoryItemsV2 } from "app/store/menuV2/MenuV2Actions";

const ExtraCategoryItemsV2 = ({
  category,
  onAddClick,
  selectedLanguage,
  isCombo,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMenuExtraCategoryItemsV2(category));
  }, [category, dispatch]);

  const CategoryItems = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ExtraCategoryItems
  );

  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    setCategoriesData(CategoryItems);
  }, [CategoryItems]);

  const onCatalogModifierChange = (array) => {
    setCategoriesData([{ items: array }]);
  };
  return (
    <div>
      {categoriesData.length === 0 && (
        <ExtraCategoriesTable
          items={[]}
          selectedLanguage={Number(selectedLanguage)}
          category={category}
          onAddClick={onAddClick}
          onCatalogModifierChange={onCatalogModifierChange}
        />
      )}

      {categoriesData.map((categoryItem) => (
        <ExtraCategoriesTable
          key={categoryItem._id}
          items={categoryItem.items.sort((a, b) => a.position - b.position)}
          category={category}
          selectedLanguage={Number(selectedLanguage)}
          connectedCategoryItem={categoryItem.connectedCategoryItem}
          connectedCategoryTitle={categoryItem.connectedCategoryTitle}
          onAddClick={onAddClick}
          isCombo={isCombo}
          onCatalogModifierChange={onCatalogModifierChange}
        />
      ))}
    </div>
  );
};

export default ExtraCategoryItemsV2;
