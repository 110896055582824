import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";
import { useForm } from "@fuse/hooks";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button, Icon } from "@material-ui/core";
import LanguageDropdown from "./components/LanguageDropdownV2";
import { handleMenuTitle, setDefaultLanguage } from "app/helpers/utilsHelper";
import { getMenuItemList } from "app/store/menu/MenuActions";
import {
  editComboCategory,
  editMenuV2ItemExtraCategories,
} from "app/store/menuV2/MenuV2Actions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const ExtraCategoriesModal = ({
  menuItem,
  onClose,
  category,
  selectedLanguage,
  handleLanguageChange,
  isCombo = false,
}) => {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const { selectedLanguages } = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2Detail
  );
  const availableLanguages = selectedLanguages || ["Spanish"];
  let { permission, locale } = useSelector(({ banzzu }) => banzzu.auth.user);
  const dispatch = useDispatch();

  let initialCategory;
  const isDefaultLanguage = setDefaultLanguage(selectedLanguage, locale);

  if (category._id === "new") {
    initialCategory = {
      menuItem: menuItem._id,
      title: "",
      type: "uniSelect",
      minQuantity: "",
      maxQuantity: "",
      description: "",
      hasMaxMin: true,
      connectedCategory: "",
      groupModifierId: "",
      languages: {},
    };
  } else {
    initialCategory = { ...category, menuItem: menuItem._id, languages: {} };
  }
  const canBeSubmitted = () => {
    return hasNoEmpty();
  };

  //ERRORS
  function hasNoEmpty() {
    return categoryForm.title !== "" && categoryForm.groupModifierId !== "";
  }
  const {
    form: categoryForm,
    handleChange: handleCategoryChange,
    setForm: setCategoryForm,
  } = useForm(initialCategory);

  if (!categoryForm) return null;

  const handleCategoryTitle = (product, language) => {
    const languageTitle = handleMenuTitle(product, language, locale);
    setCategoryForm((prevForm) => ({
      ...prevForm,
      title: languageTitle.title,
      description: languageTitle.description,
    }));
  };

  function handleTitleChange(language, field, value) {
    setCategoryForm((prevForm) => ({
      ...prevForm,
      languages: {
        ...prevForm.languages,
        [language]: {
          ...prevForm.languages[language],
          [field]: value,
        },
      },
    }));
  }
  const handleChangeExtra = (selectedCategory) => {
    if (selectedCategory && selectedCategory._id) {
      const title = selectedCategory.title;

      setCategoryForm((form) => ({
        ...form,
        title,
        groupModifierId: selectedCategory._id,
        type: selectedCategory.type,
        maxQuantity: selectedCategory.maxQuantity,
        minQuantity: selectedCategory.minQuantity,
        hasMaxMin: selectedCategory.hasMaxMin,
      }));
    }
  };

  return (
    categoryForm && (
      <div className="max-w-xl">
        <LanguageDropdown
          languages={availableLanguages}
          onChange={handleLanguageChange}
          selectedLanguage={selectedLanguage}
          menuElement={category}
          handleLanguageTitle={handleCategoryTitle}
        />
        <div className="flex">
          <CustomInput
            className="mt-8 mb-16 mr-8 min-w-64"
            required
            label={languageStrings["GENERAL.TITLE"]}
            id="title"
            name="title"
            value={
              categoryForm.languages &&
              categoryForm.languages[selectedLanguage] &&
              categoryForm.languages[selectedLanguage].title !== undefined
                ? categoryForm.languages[selectedLanguage].title
                : categoryForm.title
            }
            onValueChange={(e) => {
              handleTitleChange(selectedLanguage, "title", e.target.value);
            }}
            errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
            hasError={(error) => error}
            minLength={3}
          />
        </div>
        <CustomInput
          className="mt-8 mb-16 mr-8"
          fullWidth
          label={languageStrings["GENERAL.DESCRIPTION"]}
          id="description"
          name="description"
          value={
            categoryForm.languages &&
            categoryForm.languages[selectedLanguage] &&
            categoryForm.languages[selectedLanguage].description !== undefined
              ? categoryForm.languages[selectedLanguage].description
              : categoryForm.description
          }
          onValueChange={(e) => {
            handleTitleChange(selectedLanguage, "description", e.target.value);
          }}
          hasError={(error) => error}
        />
        <Button
          className="whitespace-no-wrap mt-16 mb-16"
          variant="contained"
          disabled={!canBeSubmitted()}
          onClick={() => {
            if (isCombo) {
              dispatch(
                editComboCategory(
                  categoryForm,
                  menuItem.menuId,
                  Number(selectedLanguage),
                  locale
                )
              );
            } else {
              dispatch(
                editMenuV2ItemExtraCategories(
                  categoryForm,
                  menuItem._id,
                  menuItem.menuId,
                  Number(selectedLanguage),
                  locale
                )
              );
            }
            menuItem &&
              menuItem.menuId &&
              dispatch(getMenuItemList(menuItem.menuId));
            onClose();
          }}
        >
          <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
            save
          </Icon>
          {languageStrings["GENERAL.SAVE"]}
        </Button>
      </div>
    )
  );
};

export default ExtraCategoriesModal;
