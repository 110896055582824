import React from "react";
import { TableCell } from "@material-ui/core";
import PaymentType from "../../payment/PaymentType";

const PaymentTypeOrderCell = (props) => {
  const { paymentsDetails } = props;

  const definePaymentType = () => {
    let paymentTypes = [];
    let firstType;
    for (let i = 0; i < paymentsDetails.length; i++) {
      if (paymentsDetails[i].paidBy)
        paymentTypes.push(paymentsDetails[i].paidBy);
      else paymentTypes.push(1);
    }
    firstType = paymentTypes[0];
    if (paymentTypes.length > 1) {
      for (let i = 0; i < paymentTypes.length; i++) {
        if (paymentTypes[i] !== firstType) return 3;
      }
    }
    return firstType;
  };

  const paymentType = definePaymentType();

  console.log(paymentType, "definepaymentType");

  return (
    <TableCell className="truncate" component="th" scope="row">
      <PaymentType paymentType={paymentType} />
    </TableCell>
  );
};

export default PaymentTypeOrderCell;
