export const languagesListData = [
  {
    name: "Spanish",
    disabled: true,
    label: "LANGUAGE.SPANISH",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/ES.svg",
    code: 1,
    locale: "es",
  },
  {
    name: "English",
    disabled: false,
    label: "LANGUAGE.ENGLISH",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GB.svg",
    code: 2,
    locale: "en",
  },
  {
    name: "French",
    disabled: false,
    label: "LANGUAGE.FRENCH",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/FR.svg",
    code: 3,
    locale: "fr",
  },
  {
    name: "Euskera",
    disabled: false,
    label: "LANGUAGE.EUSKERA",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2d/Flag_of_the_Basque_Country.svg/1000px-Flag_of_the_Basque_Country.svg.png",
    code: 4,
  },
  {
    name: "Catalan",
    disabled: false,
    label: "LANGUAGE.CATALAN",
    flage: "https/....",
    code: 5,
  },
  {
    name: "German",
    disabled: false,
    label: "LANGUAGE.GERMAN",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/DE.svg",
    code: 6,
  },
  {
    name: "Italian",
    disabled: false,
    label: "LANGUAGE.ITALIAN",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/IT.svg",
    code: 7,
  },
  {
    name: "Mandarin",
    disabled: false,
    label: "LANGUAGE.MANDARIN",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CN.svg",
    code: 8,
  },
  {
    name: "Japanese",
    disabled: false,
    label: "LANGUAGE.JAPANESE",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/JP.svg",
    code: 9,
  },
  {
    name: "Portuguese",
    disabled: false,
    label: "LANGUAGE.PORTUGUESE",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/PT.svg",
    code: 10,
  },
  {
    name: "Russian",
    disabled: false,
    label: "LANGUAGE.RUSSIAN",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/RU.svg",
    code: 11,
  },
  {
    name: "Greek",
    disabled: false,
    label: "LANGUAGE.GREEK",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/GR.svg",
    code: 12,
  },
  {
    name: "Chinese",
    disabled: false,
    label: "LANGUAGE.CHINESE",
    flag: "https://catamphetamine.gitlab.io/country-flag-icons/3x2/CN.svg",
    code: 13,
    locale: "zh",
  },
];
