import { venuePopupActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: PopupState = {
    venueId: "",
    venuePopupActive: false,
    popupImage: "",
    visitedUser: [],
};

const VenuePopupReducer = (state: PopupState = INITIAL_STATE, action) => {
    switch (action.type) {
        case venuePopupActionTypes.UPDATE_VENUE_POPUP: {
            return {
                ...state,
                loading: true,
                venueId: "",
                venuePopupActive: false,
                popupImage: "",
                visitedUser: [],
            };
        }
        case venuePopupActionTypes.UPDATE_VENUE_POPUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                venueId: action.payload.venueId,
                venuePopupActive: action.payload.popupActive,
                popupImage: action.payload.popupImage,
                visitedUser: action.payload.visitedUser,
            };
        }
        case venuePopupActionTypes.GET_VENUE_POPUP: {
            return {
                ...state,
                loading: true,
                venueId: "",
                venuePopupActive: false,
                popupImage: "",
                visitedUser: [],
            };
        }
        case venuePopupActionTypes.UPDATE_VENUE_POPUP_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }
        case venuePopupActionTypes.GET_VENUE_POPUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                venueId: action.payload.venueId,
                venuePopupActive: action.payload.popupActive,
                popupImage: action.payload.popupImage,
                visitedUser: action.payload.visitedUser,
            };
        }
        case venuePopupActionTypes.GET_VENUE_POPUP_FAIL: {
            return {
                ...state,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default VenuePopupReducer;
