import { getUserId } from "./Endpoint";

export const SCHEDULE_ORDERS = "schedule-order";
export const SCHEDULE_ORDER_DASHBOARD = "/schedule-orders/dashboard";

export const getScheduleOrderListUrl = (): string => {
  return encodeURI(SCHEDULE_ORDERS + "?userId=" + getUserId());
};
export const getDashboardScheduleOrderListUrl = (): string => {
  return encodeURI(SCHEDULE_ORDER_DASHBOARD + "?userId=" + getUserId());
};

export const getScheduleOrderDetailUrl = (id: string): string => {
  return encodeURI(SCHEDULE_ORDERS + "/" + id);
};
