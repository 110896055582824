import moment from "moment";
import { utils, writeFile } from "xlsx";

export const exportPaymentHistoryInExcel = (paymentList, languageStrings) => {
  const data = paymentList.map((item) => {
    return {
      [languageStrings["GENERAL.REF"]]: item.referenceNo,
      [languageStrings["VIRTUAL_CARD_ORDER_TABLE"]]: item.tableTitle,
      [languageStrings["GENERAL.PRICE"]]: item.totalPrice,
      [languageStrings["GENERAL.DATE"]]: moment(item.createdAt).format(
        "DD/MM/YYYY"
      ),
      [languageStrings["GENERAL.TIME"]]: moment(item.createdAt).format("HH:mm"),
    };
  });
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, "payment-history-file.xlsx");
};
