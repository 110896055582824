import React, { Component } from "react";
import { FuseUtils } from "@fuse";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/AppContext";
import { getUserInfo } from "app/store/auth/LoginActions";
import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";
class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: false,
      routes,
      publicRoutes: ["/register/es", "/register"],
    };
  }

  componentDidMount() {
    if (this.checkUser()) {
      this.redirectRoute();
    } else {
      this.props.getUserInfo();
    }
  }

  componentDidUpdate() {
    if (this.checkUser()) {
      this.redirectRoute();
    }
  }

  checkUser = () => {
    return (
      !getStorageItem(STORAGE_KEY.USER_ID) ||
      !getStorageItem(STORAGE_KEY.MASTER_ID) ||
      !getStorageItem(STORAGE_KEY.ACCESS_TOKEN) ||
      getStorageItem(STORAGE_KEY.USER_ID) === "undefined" ||
      getStorageItem(STORAGE_KEY.ACCESS_TOKEN) === "undefined"
    );
  };

  static getDerivedStateFromProps(props, state) {
    const { location, userRole } = props;
    const { pathname } = location;

    const matched = matchRoutes(state.routes, pathname)[0];
    return {
      accessGranted: matched
        ? FuseUtils.hasPermission(matched.route.auth, userRole)
        : true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  redirectRoute() {
    const { history, location } = this.props;
    if (
      !location.pathname.includes("/register") &&
      !location.pathname.includes("/forgotpassword") &&
      !location.pathname.includes("/setup-account")
    ) {
      history.replace({
        pathname: "/login",
      });
    }
  }

  render() {
    return this.state.accessGranted ? (
      <React.Fragment>{this.props.children}</React.Fragment>
    ) : null;
  }
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role,
  };
}
const mapDispatchToProps = {
  getUserInfo,
};

FuseAuthorization.contextType = AppContext;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FuseAuthorization)
);
