import Order from "./Order";
import OrderList from "./OrderList";

export const ScheduleOrderConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/schedule-orders",
      component: OrderList,
    },
    {
      path: "/schedule-order/:productId",
      component: Order,
    },
  ],
};
