import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";

import {
  Button,
  Icon,
  FormControl,
  Typography,
  Paper,
  Select,
  Input,
  OutlinedInput,
  InputLabel,
} from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import history from "@history";
import UpgradeModal from "../UpgradeModal";
import useClientCurrency from "app/hooks/useClientCurrency";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function ListHeader(props) {
  const classes = useStyles();
  const searchTextKey =
    props && props.searchTextKey ? props.searchTextKey : "searchText";

  const searchText = useSelector(
    ({ banzzu }) => banzzu[props.searchType][searchTextKey]
  );
  const [open, setOpen] = useState(false);
  const { clientCurrency } = useClientCurrency();
  const { languageStrings } = useLanguageHelper();
  const mainTheme = useSelector(({ fuse }) => fuse.settings.mainTheme);
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const handleCreateUrl = (createUrl) => {
    if (createUrl.state && createUrl.state.page) {
      history.push({
        pathname: createUrl.pathname,
        state: createUrl.state,
      });
    } else {
      history.push(createUrl);
    }
  };
  return (
    <>
      <UpgradeModal open={open} setOpen={setOpen} goBack />
      <div className="flex flex-1 w-full items-center justify-between">
        <div className="flex flex-col items-center mr-12">
          {/* <FuseAnimate animation="transition.expandIn" delay={300}>
            <Icon className="text-32 mr-0 sm:mr-12">store</Icon>
          </FuseAnimate> */}
          <FuseAnimate animation="transition.slideLeftIn" delay={300}>
            <Typography className="hidden sm:flex" variant="h6">
              {props.title}
            </Typography>
          </FuseAnimate>
          {props && props.totalTips && Number(props.totalTips) > 0 ? (
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex" variant="h5">
                {Number(props.totalTips).toFixed(2)} {clientCurrency.symbol}
              </Typography>
            </FuseAnimate>
          ) : null}
          {props && props.averageRating && Number(props.averageRating) > 0 ? (
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex" variant="h5">
                {Number(props.averageRating).toFixed(2)}
              </Typography>
            </FuseAnimate>
          ) : null}
        </div>
        {props && props.numberOfOrder && (
          <div className="flex flex-col items-center mr-12">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex" variant="h6">
                {props.numberOfOrder}
              </Typography>
            </FuseAnimate>
            {props &&
            props.numberOfOrders &&
            Number(props.numberOfOrders) > 0 ? (
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="hidden sm:flex" variant="h5">
                  {Number(props.numberOfOrders)}
                </Typography>
              </FuseAnimate>
            ) : null}
          </div>
        )}
        {props && props.totalAmountOfOrders && (
          <div className="flex flex-col items-center mr-12">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex" variant="h6">
                {props.totalAmountOfOrders}
              </Typography>
            </FuseAnimate>
            {props &&
            props.totalPaymentOfOrders &&
            Number(props.totalPaymentOfOrders) > 0 ? (
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="hidden sm:flex" variant="h5">
                  {Number(props.totalPaymentOfOrders).toFixed(2)}
                </Typography>
              </FuseAnimate>
            ) : null}
          </div>
        )}
        {props && props.numberOfPayments && (
          <div className="flex flex-col items-center mr-12">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex" variant="h6">
                {props.numberOfPayments}
              </Typography>
            </FuseAnimate>
            {props &&
            props.totalPaymentCountByClient &&
            Number(props.totalPaymentCountByClient) > 0 ? (
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="hidden sm:flex" variant="h5">
                  {Number(props.totalPaymentCountByClient)}
                </Typography>
              </FuseAnimate>
            ) : null}
          </div>
        )}
        {props && props.totalAmountOfPayments && (
          <div className="flex flex-col items-center mr-12">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex" variant="h6">
                {props.totalAmountOfPayments}
              </Typography>
            </FuseAnimate>
            {props && props.totalPayment && Number(props.totalPayment) > 0 ? (
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="hidden sm:flex" variant="h5">
                  {Number(props.totalPayment).toFixed(2)}
                </Typography>
              </FuseAnimate>
            ) : null}
          </div>
        )}
        {props && props.totalRatings && (
          <div className="flex flex-col items-center mr-12">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex" variant="h6">
                {props.totalRatings}
              </Typography>
            </FuseAnimate>
            {props && props.totalRating && Number(props.totalRating) > 0 ? (
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="hidden sm:flex" variant="h5">
                  {Number(props.totalRating)}
                </Typography>
              </FuseAnimate>
            ) : null}
          </div>
        )}
        {props && props.total5Rating && (
          <div className="flex flex-col items-center">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="hidden sm:flex" variant="h6">
                {props.total5Rating}
              </Typography>
            </FuseAnimate>
            {props && props.total5Ratings && Number(props.total5Ratings) > 0 ? (
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <Typography className="hidden sm:flex" variant="h5">
                  {Number(props.total5Ratings)}
                </Typography>
              </FuseAnimate>
            ) : null}
          </div>
        )}
        {props.setSearchText && (
          <div className="flex flex-1 items-center justify-center px-12">
            <ThemeProvider theme={mainTheme}>
              <FuseAnimate animation="transition.slideDownIn" delay={300}>
                <Paper
                  className="flex items-center w-full max-w-512 px-8 py-4 rounded-8"
                  elevation={1}
                >
                  <Icon className="mr-8" color="action">
                    search
                  </Icon>

                  <Input
                    placeholder={languageStrings["GENERAL.SEARCH"]}
                    className="flex flex-1"
                    disableUnderline
                    fullWidth
                    value={searchText}
                    inputProps={{
                      "aria-label": "Search",
                    }}
                    onChange={(ev) => props.setSearchText(ev)}
                  />
                </Paper>
              </FuseAnimate>
            </ThemeProvider>
          </div>
        )}
        {props.selectors &&
          props.selectors.length > 0 &&
          props.selectors.map((selector) => (
            <div
              className={`flex flex-1 items-center justify-center px-12 ${
                props.isAutoWidth ? "flex-none" : "flex-1"
              }`}
            >
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    className="mt-10"
                    htmlFor="outlined-venue-native-simple"
                  >
                    {selector.title}
                  </InputLabel>
                  <Select
                    className="mt-10 mb-16 mr-8"
                    native
                    required
                    style={{ maxHeight: "40px" }}
                    onChange={selector.onChange}
                    input={
                      <OutlinedInput
                        name="province"
                        labelWidth={100}
                        id="outlined-venue-native-simple"
                      />
                    }
                  >
                    <option value="0">{selector.firstOption}</option>
                    {selector.values.map((value) => (
                      <option value={value[selector.keys[0]]}>
                        {value[selector.keys[1]]}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </FuseAnimate>
            </div>
          ))}
        {props.createButton && (
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <Button
              component={Link}
              onClick={() => {
                if (user.accessLevel === "1" && props.limited) {
                  setOpen(true);
                } else if (props.checkCreateMenu) {
                  handleCreateUrl(props.createUrl);
                } else {
                  handleCreateUrl(props.createUrl);
                }
              }}
              className="whitespace-no-wrap"
              variant="contained"
            >
              <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                add_circle_outline
              </Icon>
              <span className="hidden sm:flex">{props.buttonText}</span>
              <span className="flex sm:hidden">Crear</span>
            </Button>
          </FuseAnimate>
        )}
        {props.tipButton ? (
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <div className="flex justify-center items-center">
              <span className="flex mr-4"> {props.date}</span>

              <Button
                onClick={props.onTipPress}
                className="whitespace-no-wrap"
                variant="contained"
              >
                <span className="flex">{languageStrings["TIPS_RANGE"]}</span>
              </Button>
            </div>
          </FuseAnimate>
        ) : null}
        {props && props.ratingButton ? (
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <div className="flex justify-center items-center">
              <span className="flex mr-4"> {props.date}</span>

              <Button
                onClick={props.onTipPress}
                className="whitespace-no-wrap"
                variant="contained"
              >
                <span className="flex">{languageStrings["RATING_RANGE"]}</span>
              </Button>
            </div>
          </FuseAnimate>
        ) : null}
        {props && props.filterButton ? (
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <div className="flex justify-center items-center px-4">
              <span className="flex mr-4"> {props.date}</span>

              <Button
                onClick={props.onTipPress}
                className="whitespace-no-wrap"
                variant="contained"
              >
                <span className="flex">{languageStrings["FILTER"]}</span>
              </Button>
            </div>
          </FuseAnimate>
        ) : null}
        {props && props.filterButtonOrder ? (
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <div className="flex justify-center items-center px-4">
              <span className="flex mr-4"> {props.date}</span>

              <Button
                onClick={props.onTipPress}
                className="whitespace-no-wrap"
                variant="contained"
              >
                <span className="flex">{languageStrings["FILTER"]}</span>
              </Button>
            </div>
          </FuseAnimate>
        ) : null}
        {props && props.exportButton ? (
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <div className="flex justify-center items-center px-4">
              <Button
                onClick={props.onExportFile}
                className="whitespace-no-wrap"
                variant="contained"
              >
                <span className="flex">
                  {languageStrings["GENERAL.EXPORT"]}
                </span>
              </Button>
            </div>
          </FuseAnimate>
        ) : null}
        {props.fetchButton ? (
          <FuseAnimate animation="transition.slideRightIn" delay={300}>
            <div className="flex justify-center items-center">
              <Button
                onClick={props.onFetchProducts}
                className="whitespace-no-wrap"
                variant="contained"
              >
                <span className="flex">
                  {languageStrings["FETCH_PRODUCTS"]}
                </span>
              </Button>
            </div>
          </FuseAnimate>
        ) : null}
      </div>
    </>
  );
}

export default ListHeader;
