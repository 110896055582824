import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "@lodash";
import {
  Typography,
  Card,
  IconButton,
  Icon,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import { Draggable } from "react-beautiful-dnd";
import MenuItemDetailModal from "./MenuItemDetailModalV2";
import { makeStyles } from "@material-ui/styles";
import ExtraCategoriesModal from "./ExtraCategoriesModalV2";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl, getSortedPositions } from "app/helpers/utilsHelper";
import * as Actions from "app/store/actions";
import AlertDialog from "app/main/common/AlertDialog";
import { setDefaultLanguageV2 } from "app/helpers/utilsHelper";
import {
  deleteMenuV2Item,
  updateMenuItemVisibilityV2,
  sortCatalogComboCategoryV2,
  sortCatalogProductV2,
} from "app/store/menuV2/MenuV2Actions";
import VisibilityBanner from "app/main/common/VisibilityBanner";
import {
  deleteCatalogCombo,
  updateCatalogCombo,
} from "app/store/catalogue/CatalogueActions";
import { updateComboAvailability } from "app/store/combos/CombosActions";
import AddComboModal from "../AddComboModal";
import ComboCategory from "./components/ComboCategoryV2 ";
import { getSortedAllergenes } from "app/helpers/utilsHelper";
import useClientCurrency from "app/hooks/useClientCurrency";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ComboItem = ({ menuItem, index, onlyRead, currentVenues }) => {
  const modalRef = useRef(document.createElement("div"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { locale } = useSelector(({ banzzu }) => banzzu.auth.user);
  const menuDetail = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  const [open, setOpen] = useState(false);
  const [openVisibility, setOpenVisibility] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [categorySelected, setCategorySelected] = useState({ _id: "new" });
  const defaultLanguage = setDefaultLanguageV2(locale);
  const getInitialSelectedLanguage = () => defaultLanguage.code;
  const [selectedLanguage, setSelectedLanguage] = useState(
    getInitialSelectedLanguage
  );
  const { languageStrings } = useLanguageHelper();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openComboModal, setOpenComboModal] = useState(false);
  const isCombo = menuItem.comboId;
  const comboData = isCombo ? menuItem.comboId : {};
  const [comboCategories, setComboCategories] = useState([]);
  const allergenesList = useSelector(
    ({ banzzu }) => banzzu.menu.menuItemAllergenes
  );
  const Allergenes = getSortedAllergenes({ allergenesList, languageStrings });
  const { clientCurrency } = useClientCurrency();
  useEffect(() => {
    const { comboCategories = [] } = menuItem && menuItem._id ? menuItem : {};
    setComboCategories(comboCategories);
  }, [menuItem._id]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const onVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  const onVisibilityClose = () => {
    setOpenVisibility(!openVisibility);
    setAnchorEl(null);
  };
  const executeScroll = (r) => {
    modalRef.current.scrollTo({ top: r.offsetTop, behavior: "smooth" });
  };

  const handleAddClick = (r) => {
    executeScroll(r);
  };
  let imageUrl;
  const {
    title = "",
    description = "",
    image = "",
    price = 0,
  } = menuItem && menuItem._id ? menuItem : {};
  if (image) {
    imageUrl = formatImageUrl(image);
  } else {
    imageUrl = "assets/images/avatars/noimage.png";
  }
  const onCategoryPositionChange = (action, comboCategory) => {
    const updatedComboCategories = _.cloneDeep(comboCategories);
    const { updatedPositions, array } = getSortedPositions(
      action,
      comboCategory._id,
      updatedComboCategories
    );
    setComboCategories(array);
    dispatch(
      sortCatalogComboCategoryV2({
        updatedPositions,
      })
    );
  };

  const onProductPositionChange = (action, catalogProduct, comboCategory) => {
    if (isCombo) {
      const comboCategoryId = comboCategory.comboCategory;
      const selectedCategory = comboCategories.find(
        (item) => item._id === comboCategoryId
      );
      if (!selectedCategory) return;
      const updatedProducts = [];
      selectedCategory.products.forEach((item, i) => {
        updatedProducts[i] = item;
      });
      const { updatedPositions, array } = getSortedPositions(
        action,
        catalogProduct._id,
        updatedProducts
      );
      const updatedComboCategories = comboCategories.map((item) => {
        if (item._id === comboCategoryId) {
          return {
            ...item,
            products: array,
          };
        }
        return item;
      });
      setComboCategories(updatedComboCategories);
      dispatch(
        sortCatalogProductV2({
          updatedPositions,
        })
      );
    }
  };
  const onExtraCategoryChange = (comboCategoryId, productId, array) => {
    if (isCombo) {
      const updatedComboCategories = comboCategories.map((comboCategory) => {
        if (comboCategory._id === comboCategoryId) {
          const updatedProducts = comboCategory.products.map((product) => {
            if (product._id === productId) {
              return {
                ...product,
                extraCategories: array,
              };
            }
            return product;
          });
          return {
            ...comboCategory,
            products: updatedProducts,
          };
        }
        return comboCategory;
      });
      setComboCategories(updatedComboCategories);
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`${classes.paper} overflow-scroll max-h-screen`}>
            <h2 id="transition-modal-title" className="pb-32">
              {menuItem.title}
            </h2>
            <MenuItemDetailModal
              productId={menuItem._id}
              categoryId={menuItem.categoryId}
              menuId={menuItem.menuId}
              onClose={() => {
                setOpen(false);
              }}
              currentVenues={
                currentVenues && currentVenues.length ? currentVenues : []
              }
              isCombo={true}
            />
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={openCategories}
        onClose={() => {
          setOpenCategories(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCategories}>
          <div
            className={["overflow-x-hidden max-h-640", classes.paper].join(" ")}
            ref={modalRef}
          >
            <h2 id="transition-modal-title" className="pb-32">
              {languageStrings["EXTRA_OPTIONS"]}
            </h2>
            <ExtraCategoriesModal
              menuItem={menuItem}
              category={categorySelected}
              selectedLanguage={selectedLanguage}
              handleLanguageChange={handleLanguageChange}
              onClose={() => {
                setOpenCategories(false);
              }}
              isCombo={true}
            />
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openComboModal}
        onClose={() => {
          setOpenComboModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openComboModal}>
          <div
            className={`${classes.paper} md:h-auto h-screen overflow-scroll`}
            style={{ maxWidth: "700px", maxHeight: "80%" }}
          >
            <h2 id="transition-modal-title" className="pb-32">
              {languageStrings["SELECT_COMBO"]}
            </h2>
            <AddComboModal
              Allergenes={Allergenes}
              comboDetail={menuItem}
              initialValue={menuItem.comboId}
              categoryId={categorySelected._id}
              menuId={categorySelected.menuId}
              onClose={() => {
                setOpenComboModal(false);
              }}
              onSaveCombo={(form) => {
                if (menuItem && menuItem.comboId && menuItem._id) {
                  dispatch(
                    updateCatalogCombo({
                      ...form,
                      _id: menuItem._id,
                      comboId: form.comboId,
                      menuId: menuItem.menuId,
                    })
                  );
                }
              }}
            />
          </div>
        </Fade>
      </Modal>
      <Draggable key={menuItem._id} draggableId={menuItem._id} index={index}>
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <VisibilityModalV2
              open={openVisibility}
              onClose={onVisibilityClose}
              data={menuItem}
              showGlobal={false}
              onVisibilityChange={(item = {}) => {
                if (menuItem && menuItem._id) {
                  if (menuItem.comboId) {
                    dispatch(
                      updateComboAvailability(
                        {
                          ...item,
                          _id: menuItem._id,
                        },
                        menuItem.menuId
                      )
                    );
                  } else {
                    dispatch(
                      updateMenuItemVisibilityV2({
                        ...item,
                        menuItemId: menuItem._id,
                        menuId: menuDetail && menuDetail._id,
                      })
                    );
                  }
                }
              }}
            />
            <div className="min-w-full">
              <Card className="m-4">
                <div className="flex flex-wrap sm:flex-no-wrap w-full items-center">
                  <img
                    className="object-cover h-96 w-96 ml-8 mt-8 mb-8"
                    key={isCombo ? comboData._id : menuItem._id}
                    src={imageUrl}
                    alt=""
                  />
                  <div className="w-full ml-8 mr-8 sm:w-full min-w-2/5 justify-center items-center">
                    <VisibilityBanner
                      visibility={menuItem && menuItem.visibility}
                    />

                    <Typography
                      variant="subtitle1 bold"
                      className="mt-12 text-center sm:text-left"
                      component="h3"
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="text-center sm:text-left"
                      color="textSecondary"
                    >
                      {description}
                    </Typography>
                  </div>

                  <div className="flex justify-between">
                    <Typography
                      variant="subtitle1"
                      component="h4"
                      className="ml-16 self-center mr-16"
                    >
                      {`${price.toFixed(2)}${clientCurrency.symbol}`}
                    </Typography>
                    <div className="flex center ml-16">
                      <>
                        <IconButton onClick={onVisibilityClick}>
                          <Icon className="material-symbols-outlined text-teal pr-8">
                            visibility
                          </Icon>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            if (menuItem.comboId) {
                              setOpenComboModal(true);
                            } else {
                              setOpen(true);
                            }
                          }}
                          hidden={onlyRead ? true : false}
                        >
                          {/* //here  */}
                          <Icon className="pr-8 text-blue">edit</Icon>
                        </IconButton>
                      </>

                      <IconButton
                        hidden={onlyRead ? true : false}
                        onClick={() => {
                          dispatch(
                            Actions.openDialog({
                              children: (
                                <AlertDialog
                                  onSuccess={() => {
                                    if (isCombo) {
                                      dispatch(
                                        deleteCatalogCombo(
                                          menuItem._id,
                                          menuItem.menuId
                                        )
                                      );
                                    } else {
                                      dispatch(
                                        deleteMenuV2Item(
                                          [menuItem._id],
                                          menuItem.menuId
                                        )
                                      );
                                    }

                                    Actions.closeDialog();
                                  }}
                                  title={
                                    languageStrings[
                                      "CONFIRM_DELETE_ALERT_TITLE"
                                    ]
                                  }
                                  message={
                                    languageStrings["CONFIRM_DELETE_ALERT_BODY"]
                                  }
                                />
                              ),
                            })
                          );
                        }}
                      >
                        <Icon className="pr-8 text-red">delete</Icon>
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className="mt-16 mb-16">
                  <DropableExtraCategory
                    list={comboCategories}
                    menuItem={menuItem}
                    setCategorySelected={setCategorySelected}
                    setOpenCategories={setOpenCategories}
                    onlyRead={onlyRead}
                    onCategoryPositionChange={onCategoryPositionChange}
                    onProductPositionChange={onProductPositionChange}
                    onExtraCategoryChange={onExtraCategoryChange}
                    setSelectedLanguage={setSelectedLanguage}
                    getInitialSelectedLanguage={getInitialSelectedLanguage}
                  />
                </div>
              </Card>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
};

const DropableExtraCategory = ({
  list,
  setCategorySelected,
  setOpenCategories,
  onlyRead,
  onCategoryPositionChange,
  onProductPositionChange,
  onExtraCategoryChange,
  setSelectedLanguage,
  getInitialSelectedLanguage,
}) => {
  return (
    <div>
      {list &&
        list.map((extraCategory, index) => (
          <ComboCategory
            key={extraCategory._id}
            index={index}
            extraCategory={extraCategory}
            onlyRead={onlyRead}
            onEdit={() => {
              setSelectedLanguage(getInitialSelectedLanguage());
              setCategorySelected(extraCategory);
              setOpenCategories(true);
            }}
            onDelete={() => {}}
            onCategoryPositionChange={onCategoryPositionChange}
            length={list.length || 0}
            onProductPositionChange={onProductPositionChange}
            onExtraCategoryChange={(productId, array) =>
              onExtraCategoryChange(extraCategory._id, productId, array)
            }
          />
        ))}
    </div>
  );
};

export default ComboItem;
