import React from "react";

import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import SizesCard from "./SizesCard";
import AlertDialog from "app/main/common/AlertDialog";
import * as Actions from "app/store/actions";
import { deleteSize } from "app/store/catalogue/CatalogueActions";

const SizesSection = ({
  list = [],
  onEditPress,
  onRemove = (val: number) => {},
  productId = "",
  // menuItem,
  // setCategorySelected,
  // setOpenCategories,
  // selectedLanguage,
  // onlyRead,
}) => {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();

  return (
    <Droppable droppableId="list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {list &&
            list.map((item, index) => (
              <SizesCard
                key={item._id}
                index={index}
                size={{
                  ...item,
                  _id: item._id,
                  isEditing: true,
                }}
                showEdit
                onEdit={() => {
                  onEditPress && onEditPress(item);
                }}
                onDelete={() => {
                  dispatch(
                    Actions.openDialog({
                      children: (
                        <AlertDialog
                          onSuccess={() => {
                            if (
                              productId &&
                              productId !== "0" &&
                              item &&
                              item._id !== item.sku
                            ) {
                              dispatch(
                                deleteSize({ sizeId: item._id, productId })
                              );
                            } else {
                              onRemove(index);
                            }
                            Actions.closeDialog();
                          }}
                          title={languageStrings["CONFIRM_DELETE_ALERT_TITLE"]}
                          message={languageStrings["CONFIRM_DELETE_ALERT_BODY"]}
                        />
                      ),
                    })
                  );
                }}
              />
            ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default SizesSection;
