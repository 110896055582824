import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import OrderFromTableDetail from "./OrderFromTableDetail";
import { useSelector } from "react-redux";
import useClientCurrency from "app/hooks/useClientCurrency";

const useStyles = makeStyles({
  cardContainer: {
    alignItems: "center",
    justifyContent: "center",
    height: "15rem",
    flex: 1,
    margin: "2.5rem",
    cursor: "pointer",
  },
});
export const TableCard = ({ tableData = [], selectedVenue = "" }) => {
  const classes = useStyles();
  const [modal, setModal] = useState({
    show: false,
    data: [],
    orderIds: [],
    tableId: "",
    pendingAmount: 0,
  });
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const getOrderIds = (_orders = []) => _orders.map((item) => item._id);
  const { clientCurrency } = useClientCurrency();
  const extraFee = () => {
    let extraFees = 0;
    if (venueList && venueList.length && selectedVenue) {
      const venueDetail = venueList.find((item) => item._id === selectedVenue);
      if (
        venueDetail &&
        venueDetail.venueService &&
        venueDetail.venueService.serviceFee
      ) {
        extraFees = venueDetail.venueService.serviceFee;
      }
    }
    return extraFees;
  };
  const totalAmount = (pendingOrdersIds = [], amountPaid = 0) => {
    let total = 0;
    pendingOrdersIds.map((item) => {
      total += Number(item.price);
    });
    total -= amountPaid;

    let fee = extraFee();
    if (fee) {
      total += fee;
    }

    return total.toFixed(2);
  };

  return (
    <>
      <Grid
        container
        style={{ marginTop: 5, marginBottom: 5 }}
        spacing={1}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {tableData.map((item, index) => {
          const {
            pendingOrders = [],
            pendingOrdersIds = [],
            amountPaid = 0,
          } = item || {};
          return (
            <Grid item xs={6} sm={4} md={2} key={index}>
              <div
                className={classes.cardContainer}
                onClick={() => {
                  pendingOrders &&
                    pendingOrders.length &&
                    setModal({
                      show: true,
                      data: pendingOrders || [],
                      orderIds: getOrderIds(pendingOrdersIds),
                      tableId: item._id,
                      pendingAmount: totalAmount(
                        pendingOrders || [],
                        amountPaid
                      ),
                    });
                }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  style={{ fontWeight: 800 }}
                  sx={{ mb: 4 }}
                >
                  {item.title}
                </Typography>

                <div
                  className="flex item-center justify-center"
                  style={{ height: "90%", position: "relative" }}
                >
                  {pendingOrders && pendingOrders.length ? (
                    <Typography
                      align="center"
                      variant={"subtitle1"}
                      style={{
                        fontWeight: 800,
                        position: "absolute",
                        top: "37%",
                        width: "100%",
                      }}
                      sx={{ mb: 4 }}
                    >
                      {totalAmount(pendingOrders || [], amountPaid)}{" "}
                      {clientCurrency.symbol}
                    </Typography>
                  ) : null}
                  <img
                    src={`assets/images/${
                      pendingOrders && pendingOrders.length
                        ? "tableRed"
                        : "tableGreen"
                    }.png`}
                    alt="Table"
                  />
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <OrderFromTableDetail
        {...modal}
        onCloseModal={() => {
          setModal({ show: false, data: [], orderIds: [], tableId: "" });
        }}
        selectedVenue={selectedVenue}
      />
    </>
  );
};
