import React from "react";
import { TableRow } from "@material-ui/core";

import ImageCell from "./ImageCell";
import CheckBoxCell from "./CheckBoxCell";
import SuccessAndDangerCell from "./SuccessAndDangerCell";
import PriceReduceCell from "./PriceReduceCell";
import DiscountedAmountCell from "./DiscountedAmountCell";
import IconCell from "./IconCell";
import IconCtaCell from "./IconCtaCell";
import DateTimeCell from "./DateTimeCell";
import DateCell from "./DateCell";
import MenuTypeCell from "./MenuTypeCell";
import OrderStatusCell from "./OrderStatusCell";
import OrderTypeCell from "./OrderTypeCell";
import DateComparison from "./DateComparison";
import PriceCell from "./PriceCell";
import UnitPriceCell from "./UnitPriceCell";
import TextWithExtracategory from "./TextWithExtracategory";
import VenueRange from "./VenueRange";
import ActionsCell from "./ActionCell";
import SingleBtn from "./SingleBtn";
import PaymentTypeCell from "./PaymentTypeCell";
import PaymentTypeOrderCell from "./PaymentTypeOrderCell";
import PaymentTypeOrderListCell from "./PaymentTypeOrderListCell";
import LoyaltyCategoryCell from "./LoyaltyCategoryCell";
import TruncatedText from "./TruncatedText";
import TypeText from "./TypeText";
import RefundedAmountCell from "./RefundedAmountCell";
import SingleIcon from "./SingleIcon";
import VisibilityCell from "./VisibilityCell";
import PromotionType from "./PromotionType";
import StockCell from "./StockCell";

const RowComponent = ({
  isSelected,
  headerRows,
  handleCheck,
  handleClick,
  n,
}) => {
  return (
    <TableRow
      className={headerRows.clickable ? "h-64 cursor-pointer" : "h-64"}
      hover={headerRows.clickable}
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      key={n._id}
      selected={isSelected}
      onClick={(event) => headerRows.clickable && handleClick(n)}
    >
      {headerRows.checkbox && (
        <CheckBoxCell
          isSelected={isSelected}
          onClick={(event) => event.stopPropagation()}
          onChange={(event) => handleCheck(event, n._id)}
        />
      )}
      {headerRows.data.map((obj) => {
        switch (obj.type) {
          case "image":
            return <ImageCell key={n._id} src={n.image} alt={n.title} />;

          case "text":
            return <SuccessAndDangerCell key={n._id} n={n} obj={obj} />;
          case "type-text":
            return <TypeText key={n._id} n={n} obj={obj} />;
          case "truncatedText":
            return <TruncatedText key={n._id} n={n} obj={obj} />;
          case "text-extracategory":
            return <TextWithExtracategory key={n._id} n={n} obj={obj} />;

          case "date":
            return <DateCell key={n._id} date={n[obj.id]} />;

          case "time":
            return <DateTimeCell key={n._id} date={n[obj.id]} />;

          case "dateComparison":
            return <DateComparison key={n._id} data={n} />;

          case "reducedPrice":
            return <PriceReduceCell key={n._id} n={n} />;
          case "priceCell":
            return <PriceCell key={n._id} n={n} />;
          case "unitPriceCell":
            return <UnitPriceCell key={n._id} n={n} />;

          case "discountCell":
            return (
              <DiscountedAmountCell key={n._id} data={n} dataTypes={obj.id} />
            );
          case "refundedAmountCell":
            return <RefundedAmountCell key={n._id} n={n} />;

          case "orderType":
            return <OrderTypeCell key={n._id} orderType={n[obj.id]} />;

          case "orderStatus":
            return <OrderStatusCell key={n._id} orderStatus={n.orderStatus} />;

          case "paymentType":
            return (
              <PaymentTypeCell key={n._id} paymentIntent={n.paymentIntentId} />
            );

          case "paymentTypeOrder":
            return (
              <PaymentTypeOrderCell
                key={n._id}
                paymentsDetails={n.paymentsDetails}
              />
            );

          case "loyaltyCategory":
            return (
              <LoyaltyCategoryCell
                key={n.loyaltyClientId}
                category={n.category}
              />
            );

          case "loyaltyText":
            return (
              <SuccessAndDangerCell
                key={n._id}
                n={n}
                obj={obj}
                type={"loyalty"}
              />
            );

          case "paymentTypeOrderList":
            return <PaymentTypeOrderListCell key={n._id} n={n} />;

          case "icon":
            return <IconCell key={n._id} platform={n.platform} />;

          case "bool":
            return <MenuTypeCell key={n._id} n={n.isPdf} />;
          case "singleIcon":
            return (
              <SingleIcon
                key={n._id}
                icon={obj.icon}
                onClick={(e) => {
                  e && e.preventDefault();
                  obj.onClick(e, n._id);
                }}
              />
            );

          case "iconCta":
            return (
              <IconCtaCell
                key={n._id}
                icon={obj.icon}
                onClick={(e) => obj.onClick(e, n._id)}
              />
            );
          case "range":
            return <VenueRange key={n._id} n={n} obj={obj} />;
          case "actions":
            return <ActionsCell actions={obj.actions} n={n} />;
          case "singleBtn":
            return <SingleBtn actions={obj.actions} n={n} />;
          case "visibilityBanner":
            return <VisibilityCell actions={obj.actions} n={n} />;
          case "promotionType":
            return <PromotionType actions={obj.actions} n={n} />;
          case "stockCell":
            return <StockCell key={n._id} product={n} />;
          default:
            return null;
        }
      })}
    </TableRow>
  );
};

export default RowComponent;
