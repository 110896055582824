import React, { useEffect, useState } from "react";
import { Icon, Typography, Switch } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import MaterialTable from "material-table";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getVenueList } from "app/store/venues/VenuesActions";
import {
  getTableList,
  editTable,
  deleteTable,
  createTable,
  setTableList,
} from "app/store/orders/OrdersActions";
import QRCode from "qrcode.react";
import history from "@history";
import { sortTitle } from "app/helpers/utilsHelper";
import { toast } from "react-toastify";
const OrderFromTableList = (props) => {
  const { languageStrings } = useLanguageHelper();

  const dispatch = useDispatch();
  const tableList = useSelector(({ banzzu }) => banzzu.order.tableList);
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  const { settings, permission, accessLevel, checkTables } = useSelector(
    ({ banzzu }) => banzzu.auth.user
  );

  const integrationType = (settings && settings.integrationType) || false;
  const { venueSelected = "0" } = props || {};
  const [state, setState] = useState({});
  const [tableListData, setTableListData] = useState([]);
  const showCreateButton =
    (checkTables && integrationType !== "none") || !integrationType;

  useEffect(() => {
    if (accessLevel && permission && Object.keys(permission).length > 0) {
      const check =
        permission.hasOrderFromTable === true &&
        (accessLevel === "2" || accessLevel === "3")
          ? true
          : false;

      if (!check) {
        history.replace({
          pathname: "/home",
        });
      }
    }
  }, [permission, accessLevel]);

  const onPriceListIdChange = (priceListId, rowData) => {
    const tempTableData = [...tableList];
    setTableListData(tempTableData);
    const updatedTableList = tempTableData.map((item) =>
      item.tableData.id === rowData.tableData.id
        ? {
            ...item,
            agoraPriceListId: priceListId,
          }
        : item
    );
    dispatch(setTableList(updatedTableList));
  };

  const onPriceListIdBlur = (priceListId, rowData) => {
    const tableRow = tableListData.find(
      (item) => item.tableData.id === rowData.tableData.id
    );
    if (parseInt(priceListId) !== tableRow.agoraPriceListId) {
      onPriceListIdUpdate(parseInt(priceListId), rowData);
    }
  };

  useEffect(() => {
    if (settings && Object.keys(settings).length > 0 && settings.permalink) {
      const webUrl = `${process.env.REACT_APP_WEB_LINK}/${settings.permalink}`;

      setState({
        columns: [
          {
            title: languageStrings["GENERAL.TITLE"],
            field: "title",
            customSort: (a, b) => sortTitle(a, b),
          },
          {
            field: "url",
            title: "",
            editable: "never",
            render: (rowData) => {
              return (
                rowData && (
                  <>
                    <QRCode
                      id={rowData._id}
                      value={`${webUrl}/${rowData._id}`}
                    />
                    <div style={{ display: "none" }}>
                      <QRCode
                        bgColor={"transparent"}
                        size={400}
                        id={`${rowData._id}-1`}
                        value={`${webUrl}/${rowData._id}`}
                      />
                    </div>
                  </>
                )
              );
            },
          },
          {
            field: "code",
            title: "",
            editable: "never",
            render: (rowData) => {
              return (
                rowData && (
                  <a
                    style={{ textDecorationColor: "black" }}
                    color="black"
                    href={`${webUrl}/${rowData._id}`}
                    target="_blank"
                  >
                    <div
                      style={{ color: "black" }}
                    >{`${webUrl}/${rowData._id}`}</div>
                  </a>
                )
              );
            },
          },

          {
            field: "enableExtaTarif",
            title:
              integrationType === "revo" &&
              settings &&
              settings.revoExtraTraifEnabled
                ? "Table Tarif"
                : "",
            editable: "never",
            render: (rowData) => {
              return (
                rowData &&
                integrationType === "revo" &&
                settings.revoExtraTraifEnabled && (
                  <div style={{ color: "black" }}>
                    <Switch
                      name={"tarif"}
                      checked={rowData.enableExtaTarif || false}
                      onChange={(e) => onTarifChange(e.target.checked, rowData)}
                      aria-label={"tarif"}
                    />
                  </div>
                )
              );
            },
          },
          {
            field: "agoraPriceListId",
            title: integrationType === "agora" && settings ? "Table Tarif" : "",
            editable: "",
            render: (rowData, i) => {
              return (
                rowData &&
                integrationType === "agora" && (
                  <div className="h-32">
                    <input
                      type="number"
                      className="h-full w-1/2 bg-gray-100 border border-gray-200 p-4 rounded-4"
                      placeholder="Price list id"
                      value={rowData.agoraPriceListId}
                      onKeyDown={(e) => {
                        if (["-", "+", "e", ","].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        onPriceListIdChange(e.target.value, rowData)
                      }
                      onBlur={(e) => onPriceListIdBlur(e.target.value, rowData)}
                    />
                  </div>
                )
              );
            },
          },

          {
            field: "download",
            title: "",
            editable: "never",
            render: (rowData) => {
              return (
                rowData && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      downloadQR(rowData.title.replace(/ /g, ""), rowData._id);
                    }}
                  >
                    {languageStrings["ORDER_FROM_TABLE.DOWNLOAD_QR"]}
                  </button>
                )
              );
            },
          },
        ],
      });
    }
  }, [settings, venueList, venueSelected, tableList]);
  useEffect(() => {
    dispatch(getVenueList());
  }, [dispatch]);

  useEffect(() => {
    if (venueList.length > 0 && venueSelected === "0") {
      props.setInitialVenue(venueList[0]);
    }
  }, [venueList]);

  useEffect(() => {
    if (venueSelected !== "0") {
      dispatch(getTableList(venueSelected));
    }
  }, [dispatch, venueSelected]);

  const downloadQR = (venueTitle, id) => {
    const img = document.getElementById(`${id}-1`);
    if (img) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = 800;
      canvas.height = 800;
      ctx.drawImage(img, 0, 0, 800, 800);

      const url = canvas
        .toDataURL("image/png", 1.0)
        .replace("image/png", "image/octet-stream");

      let downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = `${venueTitle}-${id}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const onTarifChange = async (checked, rowData) => {
    const newData = {
      ...rowData,
      enableExtaTarif: checked,
    };

    dispatch(
      editTable(
        newData.title,
        newData._id,
        venueSelected,
        newData.enableExtaTarif
      )
    );
  };

  const onPriceListIdUpdate = async (agoraPriceListId, rowData) => {
    const newData = {
      ...rowData,
      agoraPriceListId,
    };

    dispatch(
      editTable(
        newData.title,
        newData._id,
        venueSelected,
        null, // enableExtaTarif
        newData.agoraPriceListId
      )
    );
  };
  return (
    <div className="w-full px-20">
      <MaterialTable
        title={""}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} of {count}",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: languageStrings["GENERAL.ACTIONS"],
          },
          body: {
            emptyDataSourceMessage:
              languageStrings["MATERIAL_TABLE.NO_RECORDS"],
            filterRow: {
              filterTooltip: "Filter",
            },
            editRow: {
              deleteText: languageStrings["DELETE_TABLE_CONFIRMATION_MESSAGE"],
            },
          },
        }}
        columns={state.columns}
        data={tableList}
        options={{ search: false, pageSize: 5 }}
        icons={{
          Add: () => {
            if (showCreateButton) {
              return (
                <Typography
                  color="secondary"
                  className="normal-case flex items-center sm:mb-12"
                >
                  <Icon color="secondary" className="text-32 mr-8">
                    add_circle_outline
                  </Icon>
                  {languageStrings["ORDER_FROM_TABLE.CREATE_TABLE"]}
                </Typography>
              );
            } else return null;
          },
          Delete: () => (
            <Icon color="error" className="text-22">
              delete_outline
            </Icon>
          ),
          Edit: () => (
            <Icon color="secondary" className="text-22">
              edit
            </Icon>
          ),
          Check: () => <Icon className="text-22 text-green">check</Icon>,
          Clear: () => (
            <Icon color="error" className="text-22">
              close
            </Icon>
          ),
        }}
        editable={{
          onRowAdd: (newData) => {
            return new Promise((resolve, reject) => {
              if (newData.title === undefined || newData.title === "") {
                toast.error(languageStrings["ORDER_FROM_TABLE.TITLE_ERROR"]);
                reject();
              } else {
                dispatch(createTable(newData, venueSelected));
                resolve();
              }
            });
          },
          onRowUpdate: (newData) => {
            return new Promise((resolve, reject) => {
              try {
                if (newData.title === undefined || newData.title === "") {
                  toast.error(languageStrings["ORDER_FROM_TABLE.TITLE_ERROR"]);
                  reject();
                  return;
                }
                const enableExtaTarif = newData.enableExtaTarif || false;
                dispatch(
                  editTable(
                    newData.title,
                    newData._id,
                    venueSelected,
                    enableExtaTarif
                  )
                );
                resolve();
              } catch (error) {
                reject(error);
              }
            });
          },
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              dispatch(deleteTable(oldData._id, venueSelected));
              resolve();
            }),
        }}
      />
    </div>
  );
};

export default withReducer("MenuReducer", reducer)(OrderFromTableList);
