export const AuthActionTypes = {
  USER_INFO_START: "USER_INFO_START",
  USER_INFO_SUCCESS: "USER_INFO_SUCCESS",
  USER_INFO_FAIL: "USER_INFO_FAIL",

  CHANGE_LANGUAGE_START: "CHANGE_LANGUAGE_START",
  CHANGE_LANGUAGE_SUCCESS: "CHANGE_LANGUAGE_SUCCESS",
  CHANGE_LANGUAGE_FAIL: "CHANGE_LANGUAGE_FAIL",

  LOGIN_USER_START: "LOGIN_USER_START",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",

  VALIDATE_CREATE_ACCOUNT_START: "VALIDATE_CREATE_ACCOUNT_START",
  VALIDATE_CREATE_ACCOUNT_SUCCESS: "VALIDATE_CREATE_ACCOUNT_SUCCESS",
  VALIDATE_CREATE_ACCOUNT_FAIL: "VALIDATE_CREATE_ACCOUNT_FAIL",

  LOGOUT_USER_START: "LOGOUT_USER_START",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAIL: "LOGOUT_USER_FAIL",

  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",

  TOKEN_REGISTER_START: "TOKEN_REGISTER_START",
  TOKEN_REGISTER_SUCCESS: "TOKEN_REGISTER_SUCCESS",
  TOKEN_REGISTER_FAIL: "TOKEN_REGISTER_FAIL",

  CREATE_USER_START: "CREATE_USER_START",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",

  UPDATE_USER_GOALS_START: "UPDATE_USER_GOALS_START",
  UPDATE_USER_GOALS_SUCCESS: "UPDATE_USER_GOALS_SUCCESS",
  UPDATE_USER_GOALS_FAIL: "UPDATE_USER_GOALS_FAIL",

  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAIL: "FORGOT_PASSWORD_FAIL",

  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

  CREATE_PASSWORD_START: "CREATE_PASSWORD_START",
  CREATE_PASSWORD_SUCCESS: "CREATE_PASSWORD_SUCCESS",
  CREATE_PASSWORD_FAIL: "CREATE_PASSWORD_FAIL",

  CHECK_VALID_TOKEN_START: "CHECK_VALID_TOKEN_START",
  CHECK_VALID_TOKEN_SUCCESS: "CHECK_VALID_TOKEN_SUCCESS",
  CHECK_VALID_TOKEN_FAIL: "CHECK_VALID_TOKEN_FAIL",

  DELETE_CLIENT_START: "DELETE_CLIENT_START",
  DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAIL: "DELETE_CLIENT_FAIL",

  CANCEL_CLIENT_SUBSCRIPTION_START: "CANCEL_CLIENT_SUBSCRIPTION_START",
  CANCEL_CLIENT_SUBSCRIPTION_SUCCESS: "CANCEL_CLIENT_SUBSCRIPTION_SUCCESS",
  CANCEL_CLIENT_SUBSCRIPTION_FAIL: "CANCEL_CLIENT_SUBSCRIPTION_FAIL",
};

export const VenuesActionTypes = {
  VENUES_LIST_START: "VENUES_LIST_START",
  VENUES_LIST_SUCCESS: "VENUES_LIST_SUCCESS",
  VENUES_LIST_FAIL: "VENUES_LIST_FAIL",

  SET_VENUES_SEARCH_TEXT: "SET_VENUES_SEARCH_TEXT",

  UPDATE_POINTS_VENUE: "UPDATE_POINTS_VENUE",

  VENUES_DETAIL_START: "VENUES_DETAIL_START",
  VENUES_DETAIL_SUCCESS: "VENUES_DETAIL_SUCCESS",
  VENUES_DETAIL_FAIL: "VENUES_DETAIL_FAIL",

  VENUES_CREATE_START: "VENUES_CREATE_START",
  VENUES_CREATE_SUCCESS: "VENUES_CREATE_SUCCESS",
  VENUES_CREATE_FAIL: "VENUES_CREATE_FAIL",

  VENUES_EDIT_START: "VENUES_EDIT_START",
  VENUES_EDIT_SUCCESS: "VENUES_EDIT_SUCCESS",
  VENUES_EDIT_FAIL: "VENUES_EDIT_FAIL",

  VENUES_DELETE_START: "VENUES_DELETE_START",
  VENUES_DELETE_SUCCESS: "VENUES_DETAIL_SUCCESS",
  VENUES_DELETE_FAIL: "VENUES_DELETE_FAIL",

  VENUE_ADD_SQUARE_TOKEN_START: "ADD_SQUARE_TOKEN_VENUE_START",
  VENUE_ADD_SQUARE_TOKEN_SUCCESS: "ADD_SQUARE_TOKEN_SUCCESS",
  VENUE_ADD_SQUARE_TOKEN_FAIL: "ADD_SQUARE_TOKEN_FAIL",

  VENUES_ADD_DELIVERY_AVAILABILITY_START:
    "VENUES_ADD_DELIVERY_AVAILABILITY_START",
  VENUES_ADD_DELIVERY_AVAILABILITY_SUCCESS:
    "VENUES_ADD_DELIVERY_AVAILABILITY_SUCCES",
  VENUES_ADD_DELIVERY_AVAILABILITY_FAIL:
    "VENUES_ADD_DELIVERY_AVAILABILITY_FAIL",

  VENUES_GET_DELIVERY_AVAILABILITY_START:
    "VENUES_GET_DELIVERY_AVAILABILITY_START",
  VENUES_GET_DELIVERY_AVAILABILITY_SUCCESS:
    "VENUES_GET_DELIVERY_AVAILABILITY_SUCCES",
  VENUES_GET_DELIVERY_AVAILABILITY_FAIL:
    "VENUES_GET_DELIVERY_AVAILABILITY_FAIL",

  VENUES_DELETE_DELIVERY_AVAILABILITY_START:
    "VENUES_DELETE_DELIVERY_AVAILABILITY_START",
  VENUES_DELETE_DELIVERY_AVAILABILITY_SUCCESS:
    "VENUES_DELETE_DELIVERY_AVAILABILITY_SUCCES",
  VENUES_DELETE_DELIVERY_AVAILABILITY_FAIL:
    "VENUES_DELETE_DELIVERY_AVAILABILITY_FAIL",

  VENUES_ADD_COLLECTION_AVAILABILITY_START:
    "VENUES_ADD_COLLECTION_AVAILABILITY_START",
  VENUES_ADD_COLLECTION_AVAILABILITY_SUCCESS:
    "VENUES_ADD_COLLECTION_AVAILABILITY_SUCCES",
  VENUES_ADD_COLLECTION_AVAILABILITY_FAIL:
    "VENUES_ADD_COLLECTION_AVAILABILITY_FAIL",

  VENUES_GET_COLLECTION_AVAILABILITY_START:
    "VENUES_GET_COLLECTION_AVAILABILITY_START",
  VENUES_GET_COLLECTION_AVAILABILITY_SUCCESS:
    "VENUES_GET_COLLECTION_AVAILABILITY_SUCCES",
  VENUES_GET_COLLECTION_AVAILABILITY_FAIL:
    "VENUES_GET_COLLECTION_AVAILABILITY_FAIL",

  VENUES_DELETE_COLLECTION_AVAILABILITY_START:
    "VENUES_DELETE_COLLECTION_AVAILABILITY_START",
  VENUES_DELETE_COLLECTION_AVAILABILITY_SUCCESS:
    "VENUES_DELETE_COLLECTION_AVAILABILITY_SUCCES",
  VENUES_DELETE_COLLECTION_AVAILABILITY_FAIL:
    "VENUES_DELETE_COLLECTION_AVAILABILITY_FAIL",

  RESET_VENUE_SUCCESS: "RESET_VENUE_SUCCESS",

  CHECK_EMAIL_START: "CHECK_EMAIL_START",
  CHECK_EMAIL_FAIL: "CHECK_EMAIL_FAIL",
  CHECK_EMAIL_SUCCESS: "CHECK_EMAIL_SUCCESS",

  GET_TAXES_START: "GET_TAXES_START",
  GET_TAXES_FAIL: "GET_TAXES_FAIL",
  GET_TAXES_SUCCESS: "GET_TAXES_SUCCESS",

  SET_VENUEID_START: "SET_VENUEID_START",
  SET_VENUEID_FAIL: "SET_VENUEID_FAIL",
  SET_VENUEID_SUCCESS: "SET_VENUEID_SUCCESS",

  VENUES_ADD_AUTO_SERVICES_START: "VENUES_ADD_AUTO_SERVICES_START",
  VENUES_ADD_AUTO_SERVICES_SUCCESS: "VENUES_ADD_AUTO_SERVICES_SUCCES",
  VENUES_ADD_AUTO_SERVICES_FAIL: "VENUES_ADD_AUTO_SERVICES_FAIL",

  VENUES_DELETE_AUTO_SERVICES_START: "VENUES_DELETE_AUTO_SERVICES_START",
  VENUES_DELETE_AUTO_SERVICES_SUCCESS: "VENUES_DELETE_AUTO_SERVICES_SUCCES",
  VENUES_DELETE_AUTO_SERVICES_FAIL: "VENUES_DELETE_AUTO_SERVICES_FAIL",

  VENUES_UPDATE_AUTO_SERVICES_START: "VENUES_UPDATE_AUTO_SERVICES_START",
  VENUES_UPDATE_AUTO_SERVICES_SUCCESS: "VENUES_UPDATE_AUTO_SERVICES_SUCCES",
  VENUES_UPDATE_AUTO_SERVICES_FAIL: "VENUES_UPDATE_AUTO_SERVICES_FAIL",
};

export const DrawActionTypes = {
  DRAW_LIST_START: "DRAW_LIST_START",
  DRAW_LIST_SUCCESS: "DRAW_LIST_SUCCESS",
  DRAW_LIST_FAIL: "DRAW_LIST_FAIL",

  SET_DRAW_SEARCH_TEXT: "SET_DRAW_SEARCH_TEXT",

  DRAW_DETAIL_START: "DRAW_DETAIL_START",
  DRAW_DETAIL_SUCCESS: "DRAW_DETAIL_SUCCESS",
  DRAW_DETAIL_FAIL: "DRAW_DETAIL_FAIL",

  DRAW_CREATE_START: "DRAW_CREATE_START",
  DRAW_CREATE_SUCCESS: "DRAW_CREATE_SUCCESS",
  DRAW_CREATE_FAIL: "DRAW_CREATE_FAIL",

  DRAW_EDIT_START: "DRAW_EDIT_START",
  DRAW_EDIT_SUCCESS: "DRAW_EDIT_SUCCESS",
  DRAW_EDIT_FAIL: "DRAW_EDIT_FAIL",

  DRAW_DELETE_START: "DRAW_DELETE_START",
  DRAW_DELETE_SUCCESS: "DRAW_DETAIL_SUCCESS",
  DRAW_DELETE_FAIL: "DRAW_DELETE_FAIL",
};

export const VoucherActionTypes = {
  VOUCHER_LIST_START: "VOUCHER_LIST_START",
  VOUCHER_LIST_SUCCESS: "VOUCHER_LIST_SUCCESS",
  VOUCHER_LIST_FAIL: "VOUCHER_LIST_FAIL",

  SORT_VOUCHER_LIST_START: "SORT_VOUCHER_LIST_START",
  SORT_VOUCHER_LIST_SUCCESS: "SORT_VOUCHER_LIST_SUCCESS",
  SORT_VOUCHER_LIST_FAIL: "SORT_VOUCHER_LIST_FAIL",

  VOUCHER_DETAIL_START: "VOUCHER_DETAIL_START",
  VOUCHER_DETAIL_SUCCESS: "VOUCHER_DETAIL_SUCCESS",
  VOUCHER_DETAIL_FAIL: "VOUCHER_DETAIL_FAIL",

  VOUCHER_CREATE_START: "VOUCHER_CREATE_START",
  VOUCHER_CREATE_SUCCESS: "VOUCHER_CREATE_SUCCESS",
  VOUCHER_CREATE_FAIL: "VOUCHER_CREATE_FAIL",

  VOUCHER_EDIT_START: "VOUCHER_EDIT_START",
  VOUCHER_EDIT_SUCCESS: "VOUCHER_EDIT_SUCCESS",
  VOUCHER_EDIT_FAIL: "VOUCHER_EDIT_FAIL",

  VOUCHER_DELETE_START: "VOUCHER_DELETE_START",
  VOUCHER_DELETE_SUCCESS: "VOUCHER_DETAIL_SUCCESS",
  VOUCHER_DELETE_FAIL: "VOUCHER_DELETE_FAIL",
};

export const OffersActionTypes = {
  OFFERS_LIST_START: "OFFERS_LIST_START",
  OFFERS_LIST_SUCCESS: "OFFERS_LIST_SUCCESS",
  OFFERS_LIST_FAIL: "OFFERS_LIST_FAIL",

  SORT_OFFER_LIST_START: "SORT_OFFER_LIST_START",
  SORT_OFFER_LIST_FAIL: "SORT_OFFER_LIST_FAIL",
  SORT_OFFER_LIST_SUCCESS: "SORT_OFFER_LIST_SUCCESS",

  SET_OFFERS_SEARCH_TEXT: "SET_OFFERS_SEARCH_TEXT",

  OFFERS_DETAIL_START: "OFFERS_DETAIL_START",
  OFFERS_DETAIL_SUCCESS: "OFFERS_DETAIL_SUCCESS",
  OFFERS_DETAIL_FAIL: "OFFERS_DETAIL_FAIL",

  OFFERS_CREATE_START: "OFFERS_CREATE_START",
  OFFERS_CREATE_SUCCESS: "OFFERS_CREATE_SUCCESS",
  OFFERS_CREATE_FAIL: "OFFERS_CREATE_FAIL",

  OFFERS_EDIT_START: "OFFERS_EDIT_START",
  OFFERS_EDIT_SUCCESS: "OFFERS_EDIT_SUCCESS",
  OFFERS_EDIT_FAIL: "OFFERS_EDIT_FAIL",

  OFFERS_DELETE_START: "OFFERS_DELETE_START",
  OFFERS_DELETE_SUCCESS: "OFFERS_DELETE_SUCCESS",
  OFFERS_DELETE_FAIL: "OFFERS_DELETE_FAIL",
};

export const UsersActionTypes = {
  USER_LIST_START: "USER_LIST_START",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAIL: "USER_LIST_FAIL",

  USER_DETAILS_START: "USER_DETAILS_START",
  USER_DETAILS_SUCCESS: "USER_DETAILS_SUCCESS",
  USER_DETAILS_FAIL: "USER_DETAILS_FAIL",

  DOWNLOAD_QR_START: "DOWNLOAD_QR_START",

  USER_PLATFORM_START: "USER_PLATFORM_START",
  USER_PLATFORM_SUCCESS: "USER_PLATFORM_SUCCESS",
  USER_PLATFORM_FAIL: "USER_PLATFORM_FAIL",

  SET_BRANDING_START: "SET_BRANDING_START",
  SET_BRANDING_SUCCESS: "SET_BRANDING_SUCCESS",
  SET_BRANDING_FAIL: "SET_BRANDING_FAIL",

  SET_USER_SEARCH_TEXT: "SET_USER_SEARCH_TEXT",

  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",

  SEND_PREMIUM_LEAD_EMAIL_START: "SEND_PREMIUM_LEAD_EMAIL_START",
  SEND_PREMIUM_LEAD_EMAIL_SUCCESS: "SEND_PREMIUM_LEAD_EMAIL_SUCCESS",
  SEND_PREMIUM_LEAD_EMAIL_FAIL: "SEND_PREMIUM_LEAD_EMAIL_FAIL",

  SEND_CONTACT_SUPPORT_EMAIL_START: "SEND_CONTACT_SUPPORT_EMAIL_START",
  SEND_CONTACT_SUPPORT_EMAIL_SUCCESS: "SEND_CONTACT_SUPPORT_EMAIL_SUCCESS",
  SEND_CONTACT_SUPPORT_EMAIL_FAIL: "SEND_CONTACT_SUPPORT_EMAIL_FAIL",

  PERMA_LINK_LIST_START: "PERMA_LINK_LIST_START",
  PERMA_LINK_LIST_SUCCESS: "PERMA_LINK_LIST_SUCCESS",
  PERMA_LINK_LIST_FAIL: "PERMA_LINK_LIST_FAIL",

  UPDATE_LINK_START: "UPDATE_LINK_START",
  UPDATE_LINK_SUCCESS: "UPDATE_LINK_SUCCESS",
  UPDATE_LINK_FAIL: "UPDATE_LINK_FAIL",

  ADD_LAST_INTERACTION_START: "ADD_LAST_INTERACTION_START",
  ADD_LAST_INTERACTION_SUCCESS: "ADD_LAST_INTERACTION_SUCCESS",
  ADD_LAST_INTERACTION_FAIL: "ADD_LAST_INTERACTION_FAIL",
};
export const TipsActionTypes = {
  TIPS_LIST_START: "TIPS_LIST_START",
  TIPS_LIST_SUCCESS: "TIPS_LIST_SUCCESS",
  TIPS_LIST_FAIL: "TIPS_LIST_FAIL",

  SET_TIPS_SEARCH_TEXT: "SET_TIPS_SEARCH_TEXT",
};
export const RatingActionTypes = {
  RATINGS_LIST_START: "RATINGS_LIST_START",
  RATINGS_LIST_SUCCESS: "RATINGS_LIST_SUCCESS",
  RATINGS_LIST_FAIL: "RATINGS_LIST_FAIL",

  SET_RATINGS_SEARCH_TEXT: "SET_RATINGS_SEARCH_TEXT",
};
export const PosActionTypes = {
  PRODUCTS_LIST: "PRODUCTS_LIST",
  PRODUCTS_LIST_SUCCESS: "PRODUCTS_LIST_SUCCESS",
  PRODUCTS_LIST_FAIL: "PRODUCTS_LIST_FAIL",

  COMBO_LIST: "COMBO_LIST",
  COMBO_LIST_SUCCESS: "COMBO_LIST_SUCCESS",
  COMBO_LIST_FAIL: "COMBO_LIST_FAIL",

  SET_TIPS_SEARCH_TEXT: "SET_TIPS_SEARCH_TEXT",
};

export const ImageActionTypes = {
  IMAGE_UPLOAD_START: "IMAGE_UPLOAD_START",
  IMAGE_UPLOAD_SUCCESS: "IMAGE_UPLOAD_SUCCESS",
  IMAGE_UPLOAD_FAIL: "IMAGE_UPLOAD_FAIL",
};

export const WhatsOnActionTypes = {
  WHATSON_LIST_START: "WHATSON_LIST_START",
  WHATSON_LIST_SUCCESS: "WHATSON_LIST_SUCCESS",
  WHATSON_LIST_FAIL: "WHATSON_LIST_FAIL",

  SET_WHATSON_SEARCH_TEXT: "SET_WHATSON_SEARCH_TEXT",

  WHATSON_DETAIL_START: "WHATSON_DETAIL_START",
  WHATSON_DETAIL_SUCCESS: "WHATSON_DETAIL_SUCCESS",
  WHATSON_DETAIL_FAIL: "WHATSON_DETAIL_FAIL",

  WHATSON_CREATE_START: "WHATSON_CREATE_START",
  WHATSON_CREATE_SUCCESS: "WHATSON_CREATE_SUCCESS",
  WHATSON_CREATE_FAIL: "WHATSON_CREATE_FAIL",

  WHATSON_EDIT_START: "WHATSON_EDIT_START",
  WHATSON_EDIT_SUCCESS: "WHATSON_EDIT_SUCCESS",
  WHATSON_EDIT_FAIL: "WHATSON_EDIT_FAIL",

  WHATSON_DELETE_START: "WHATSON_DELETE_START",
  WHATSON_DELETE_SUCCESS: "WHATSON_DELETE_SUCCESS",
  WHATSON_DELETE_FAIL: "WHATSON_DELETE_FAIL",
};

export const GalleryActionTypes = {
  GALLERY_LIST_START: "GALLERY_LIST_START",
  GALLERY_LIST_SUCCESS: "GALLERY_LIST_SUCCESS",
  GALLERY_LIST_FAIL: "GALLERY_LIST_FAIL",

  GALLERY_CREATE_START: "GALLERY_CREATE_START",
  GALLERY_CREATE_SUCCESS: "GALLERY_CREATE_SUCCESS",
  GALLERY_CREATE_FAIL: "GALLERY_CREATE_FAIL",

  GALLERY_DELETE_START: "GALLERY_DELETE_START",
  GALLERY_DELETE_SUCCESS: "GALLERY_DELETE_SUCCESS",
  GALLERY_DELETE_FAIL: "GALLERY_DELETE_FAIL",
};

export const ReservationActionTypes = {
  RESERVATION_LIST_START: "RESERVATION_LIST_START",
  RESERVATION_LIST_SUCCESS: "RESERVATION_LIST_SUCCESS",
  RESERVATION_LIST_FAIL: "RESERVATION_LIST_FAIL",

  RESERVATION_DETAIL_START: "RESERVATION_DETAIL_START",
  RESERVATION_DETAIL_SUCCESS: "RESERVATION_DETAIL_SUCCESS",
  RESERVATION_DETAIL_FAIL: "RESERVATION_DETAIL_FAIL",

  RESERVATION_CREATE_START: "RESERVATION_CREATE_START",
  RESERVATION_CREATE_SUCCESS: "RESERVATION_CREATE_SUCCESS",
  RESERVATION_CREATE_FAIL: "RESERVATION_CREATE_FAIL",

  RESERVATION_EDIT_START: "RESERVATION_EDIT_START",
  RESERVATION_EDIT_SUCCESS: "RESERVATION_EDIT_SUCCESS",
  RESERVATION_EDIT_FAIL: "RESERVATION_EDIT_FAIL",

  CHANGE_RESERVATION_START: "CHANGE_RESERVATION_START",
  CHANGE_RESERVATION_FAIL: "CHANGE_RESERVATION_FAIL",
  CHANGE_RESERVATION_SUCCESS: "CHANGE_RESERVATION_SUCCESS",

  SET_RESERVATION_SEARCH_TEXT: "SET_RESERVATION_SEARCH_TEXT",
};

export const MenuActionTypes = {
  MENU_LIST_START: "MENU_LIST_START",
  MENU_LIST_SUCCESS: "MENU_LIST_SUCCESS",
  MENU_LIST_FAIL: "MENU_LIST_FAIL",

  DELETE_MENU_ITEM_IMAGE: "DELETE_MENU_ITEM_IMAGE",

  MENU_DUPLICATE_START: "MENU_DUPLICATE_START",
  MENU_DUPLICATE_FAIL: "MENU_DUPLICATE_FAIL",
  MENU_DUPLICATE_SUCCESS: "MENU_DUPLICATE_SUCCESS",

  MENU_ITEM_DUPLICATE_START: "MENU_ITEM_DUPLICATE_START",
  MENU_ITEM_DUPLICATE_FAIL: "MENU_ITEM_DUPLICATE_FAIL",
  MENU_ITEM_DUPLICATE_SUCCESS: "MENU_ITEM_DUPLICATE_SUCCESS",

  SORT_MENU_LIST_START: "SORT_MENU_LIST_START",
  SORT_MENU_LIST_SUCCESS: "SORT_MENU_LIST_SUCCESS",
  SORT_MENU_LIST_FAIL: "SORT_MENU_LIST_FAIL",

  SORT_EXTRA_CATEGORIES_START: "SORT_EXTRA_CATEGORIES_START",
  SORT_EXTRA_CATEGORIES_SUCCESS: "SORT_EXTRA_CATEGORIES_SUCCESS",
  SORT_EXTRA_CATEGORIES_FAIL: "SORT_EXTRA_CATEGORIES_FAIL",

  SORT_EXTRA_ITEMS_START: "SORT_EXTRA_ITEMS_START",
  SORT_EXTRA_ITEMS_SUCCESS: "SORT_EXTRA_ITEMS_SUCCESS",
  SORT_EXTRA_ITEMS_FAIL: "SORT_EXTRA_ITEMS_FAIL",

  SORT_MENU_LIST_CATEGORY_START: "SORT_MENU_LIST_CATEGORY_START",
  SORT_MENU_LIST_CATEGORY_SUCCESS: "SORT_MENU_LIST_CATEGORY_SUCCESS",
  SORT_MENU_LIST_CATEGORY_FAIL: "SORT_MENU_LIST_CATEGORY_FAIL",

  SET_MENU_SEARCH_TEXT: "SET_MENU_SEARCH_TEXT",
  SET_MENU_ITEM_SEARCH_CATEGORY: "SET_MENU_ITEM_SEARCH_CATEGORY",

  MENU_DETAIL_START: "MENU_DETAIL_START",
  MENU_DETAIL_SUCCESS: "MENU_DETAIL_SUCCESS",
  MENU_DETAIL_FAIL: "MENU_DETAIL_FAIL",

  MENU_CREATE_START: "MENU_CREATE_START",
  MENU_CREATE_SUCCESS: "MENU_CREATE_SUCCESS",
  MENU_CREATE_FAIL: "MENU_CREATE_FAIL",

  GET_MENU_EXTRA_CATEGORY_START: "GET_MENU_EXTRA_CATEGORY_START",
  GET_MENU_EXTRA_CATEGORY_SUCCESS: "GET_MENU_EXTRA_CATEGORY_SUCCESS",
  GET_MENU_EXTRA_CATEGORY_FAIL: "GET_MENU_EXTRA_CATEGORY_FAIL",

  REMOVE_MENU_EXTRA_CATEGORY_SUCCESS: "REMOVE_MENU_EXTRA_CATEGORY_SUCCESS",

  MENU_EXTRA_CATEGORY_CREATE_START: "MENU_EXTRA_CATEGORY_CREATE_START",
  MENU_EXTRA_CATEGORY_CREATE_SUCCESS: "MENU_EXTRA_CATEGORY_CREATE_SUCCESS",
  MENU_EXTRA_CATEGORY_CREATE_FAIL: "MENU_EXTRA_CATEGORY_CREATE_FAIL",

  MENU_EXTRA_CATEGORY_EDIT_START: "MENU_EXTRA_CATEGORY_EDIT_START",
  MENU_EXTRA_CATEGORY_EDIT_SUCCESS: "MENU_EXTRA_CATEGORY_EDIT_SUCCESS",
  MENU_EXTRA_CATEGORY_EDIT_FAIL: "MENU_EXTRA_CATEGORY_EDIT_FAIL",

  MENU_EXTRA_CATEGORY_DELETE_START: "MENU_EXTRA_CATEGORY_DELETE_START",
  MENU_EXTRA_CATEGORY_DELETE_SUCCESS: "MENU_EXTRA_CATEGORY_DELETE_SUCCESS",
  MENU_EXTRA_CATEGORY_DELETE_FAIL: "MENU_EXTRA_CATEGORY_DELETE_FAIL",

  GET_MENU_EXTRA_CATEGORY_ITEM_START: "GET_MENU_EXTRA_CATEGORY_ITEM_START",
  GET_MENU_EXTRA_CATEGORY_ITEM_SUCCESS: "GET_MENU_EXTRA_CATEGORY_ITEM_SUCCESS",
  GET_MENU_EXTRA_CATEGORY_ITEM_FAIL: "GET_MENU_EXTRA_CATEGORY_ITEM_FAIL",

  MENU_EXTRA_CATEGORY_ITEM_CREATE_START:
    "MENU_EXTRA_CATEGORY_ITEM_CREATE_START",
  MENU_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS:
    "MENU_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS",
  MENU_EXTRA_CATEGORY_ITEM_CREATE_FAIL: "MENU_EXTRA_CATEGORY_ITEM_CREATE_FAIL",

  MENU_EXTRA_CATEGORY_ITEM_DELETE_START:
    "MENU_EXTRA_CATEGORY_ITEM_DELETE_START",
  MENU_EXTRA_CATEGORY_ITEM_DELETE_SUCCESS:
    "MENU_EXTRA_CATEGORY_ITEM_DELETE_SUCCESS",
  MENU_EXTRA_CATEGORY_ITEM_DELETE_FAIL: "MENU_EXTRA_CATEGORY_ITEM_DELETE_FAIL",

  MENU_EDIT_START: "MENU_EDIT_START",
  MENU_EDIT_SUCCESS: "MENU_EDIT_SUCCESS",
  MENU_EDIT_FAIL: "MENU_EDIT_FAIL",

  MENU_DELETE_START: "MENU_DELETE_START",
  MENU_DELETE_SUCCESS: "MENU_DELETE_SUCCESS",
  MENU_DELETE_FAIL: "MENU_DELETE_FAIL",

  MENU_ITEM_LIST_START: "MENU_ITEM_LIST_START",
  MENU_ITEM_LIST_SUCCESS: "MENU_ITEM_LIST_SUCCESS",
  MENU_ITEM_LIST_FAIL: "MENU_ITEM_LIST_FAIL",

  MENU_ITEM_DETAIL_START: "MENU_ITEM_DETAIL_START",
  MENU_ITEM_DETAIL_SUCCESS: "MENU_ITEM_DETAIL_SUCCESS",
  MENU_ITEM_DETAIL_FAIL: "MENU_ITEM_DETAIL_FAIL",

  MENU_ITEM_CATEGORY_START: "MENU_ITEM_CATEGORY_START",
  MENU_ITEM_CATEGORY_SUCCESS: "MENU_ITEM_CATEGORY_SUCCESS",
  MENU_ITEM_CATEGORY_FAIL: "MENU_ITEM_CATEGORY_FAIL",

  MENU_ITEM_ALLERGENES_START: "MENU_ITEM_ALLERGENES_START",
  MENU_ITEM_ALLERGENES_SUCCESS: "MENU_ITEM_ALLERGENES_SUCCESS",
  MENU_ITEM_ALLERGENES_FAIL: "MENU_ITEM_ALLERGENES_FAIL",

  MENU_ITEM_CREATE_START: "MENU_ITEM_CREATE_START",
  MENU_ITEM_CREATE_SUCCESS: "MENU_ITEM_CREATE_SUCCESS",
  MENU_ITEM_CREATE_FAIL: "MENU_ITEM_CREATE_FAIL",

  MENU_ITEM_EDIT_START: "MENU_ITEM_EDIT_START",
  MENU_ITEM_EDIT_SUCCESS: "MENU_ITEM_EDIT_SUCCESS",
  MENU_ITEM_EDIT_FAIL: "MENU_ITEM_EDIT_FAIL",

  MENU_ITEM_DELETE_START: "MENU_ITEM_DELETE_START",
  MENU_ITEM_DELETE_SUCCESS: "MENU_ITEM_DELETE_SUCCESS",
  MENU_ITEM_DELETE_FAIL: "MENU_ITEM_DELETE_FAIL",

  MENU_CAT_CREATE_START: "MENU_CAT_CREATE_START",
  MENU_CAT_CREATE_SUCCESS: "MENU_CAT_CREATE_SUCCESS",
  MENU_CAT_CREATE_FAIL: "MENU_CAT_CREATE_FAIL",

  MENU_CAT_EDIT_START: "MENU_CAT_EDIT_START",
  MENU_CAT_EDIT_SUCCESS: "MENU_CAT_EDIT_SUCCESS",
  MENU_CAT_EDIT_FAIL: "MENU_CAT_EDIT_FAIL",

  MENU_CAT_DELETE_START: "MENU_CAT_DELETE_START",
  MENU_CAT_DELETE_SUCCESS: "MENU_CAT_DELETE_SUCCESS",
  MENU_CAT_DELETE_FAIL: "MENU_CAT_DELETE_FAIL",
};
export const MenuV2ActionTypes = {
  MENU_V2_LIST_START: "MENU_V2_LIST_START",
  MENU_V2_LIST_SUCCESS: "MENU_V2_LIST_SUCCESS",
  MENU_V2_LIST_FAIL: "MENU_V2_LIST_FAIL",

  DELETE_MENU_V2_ITEM_IMAGE: "DELETE_MENU_V2_ITEM_IMAGE",

  SORT_MENU_V2_LIST_START: "SORT_MENU_V2_LIST_START",
  SORT_MENU_V2_LIST_SUCCESS: "SORT_MENU_V2_LIST_SUCCESS",
  SORT_MENU_V2_LIST_FAIL: "SORT_MENU_V2_LIST_FAIL",

  SORT_MENU_V2_LIST_CATEGORY_START: "SORT_MENU_V2_LIST_CATEGORY_START",
  SORT_MENU_V2_LIST_CATEGORY_SUCCESS: "SORT_MENU_V2_LIST_CATEGORY_SUCCESS",
  SORT_MENU_V2_LIST_CATEGORY_FAIL: "SORT_MENU_V2_LIST_CATEGORY_FAIL",

  SET_MENU_V2_SEARCH_TEXT: "SET_MENU_V2_SEARCH_TEXT",
  SET_MENU_V2_ITEM_SEARCH_CATEGORY: "SET_MENU_V2_ITEM_SEARCH_CATEGORY",

  MENU_V2_DETAIL_START: "MENU_V2_DETAIL_START",
  MENU_V2_DETAIL_SUCCESS: "MENU_V2_DETAIL_SUCCESS",
  MENU_V2_DETAIL_FAIL: "MENU_V2_DETAIL_FAIL",

  MENU_V2_CREATE_START: "MENU_V2_CREATE_START",
  MENU_V2_CREATE_SUCCESS: "MENU_V2_CREATE_SUCCESS",
  MENU_V2_CREATE_FAIL: "MENU_V2_CREATE_FAIL",

  GET_MENU_V2_EXTRA_CATEGORY_START: "GET_MENU_V2_EXTRA_CATEGORY_START",
  GET_MENU_V2_EXTRA_CATEGORY_SUCCESS: "GET_MENU_V2_EXTRA_CATEGORY_SUCCESS",
  GET_MENU_V2_EXTRA_CATEGORY_FAIL: "GET_MENU_V2_EXTRA_CATEGORY_FAIL",

  MENU_V2_EXTRA_CATEGORY_EDIT_START: "MENU_V2_EXTRA_CATEGORY_EDIT_START",
  MENU_V2_EXTRA_CATEGORY_EDIT_SUCCESS: "MENU_V2_EXTRA_CATEGORY_EDIT_SUCCESS",
  MENU_V2_EXTRA_CATEGORY_EDIT_FAIL: "MENU_V2_EXTRA_CATEGORY_EDIT_FAIL",

  COMBO_CATEGORY_EDIT_START: "COMBO_CATEGORY_EDIT_START",
  COMBO_CATEGORY_EDIT_SUCCESS: "COMBO_CATEGORY_EDIT_SUCCESS",
  COMBO_CATEGORY_EDIT_FAIL: "COMBO_CATEGORY_EDIT_FAIL",

  COMBO_CATEGORY_VISIBILITY_START: "COMBO_CATEGORY_VISIBILITY_START",
  COMBO_CATEGORY_VISIBILITY_SUCCESS: "COMBO_CATEGORY_VISIBILITY_SUCCESS",
  COMBO_CATEGORY_VISIBILITY_FAIL: "COMBO_CATEGORY_VISIBILITY_FAIL",

  GET_MENU_V2_EXTRA_CATEGORY_ITEM_START:
    "GET_MENU_V2_EXTRA_CATEGORY_ITEM_START",
  GET_MENU_V2_EXTRA_CATEGORY_ITEM_SUCCESS:
    "GET_MENU_V2_EXTRA_CATEGORY_ITEM_SUCCESS",
  GET_MENU_V2_EXTRA_CATEGORY_ITEM_FAIL: "GET_MENU_V2_EXTRA_CATEGORY_ITEM_FAIL",

  MENU_V2_EDIT_START: "MENU_V2_EDIT_START",
  MENU_V2_EDIT_SUCCESS: "MENU_V2_EDIT_SUCCESS",
  MENU_V2_EDIT_FAIL: "MENU_V2_EDIT_FAIL",

  MENU_V2_DELETE_START: "MENU_V2_DELETE_START",
  MENU_V2_DELETE_SUCCESS: "MENU_V2_DELETE_SUCCESS",
  MENU_V2_DELETE_FAIL: "MENU_V2_DELETE_FAIL",

  MENU_V2_ITEM_LIST_START: "MENU_V2_ITEM_LIST_START",
  MENU_V2_ITEM_LIST_SUCCESS: "MENU_V2_ITEM_LIST_SUCCESS",
  MENU_V2_ITEM_LIST_FAIL: "MENU_V2_ITEM_LIST_FAIL",

  MENU_V2_ITEM_DETAIL_START: "MENU_V2_ITEM_DETAIL_START",
  MENU_V2_ITEM_DETAIL_SUCCESS: "MENU_V2_ITEM_DETAIL_SUCCESS",
  MENU_V2_ITEM_DETAIL_FAIL: "MENU_V2_ITEM_DETAIL_FAIL",

  MENU_V2_ITEM_CATEGORY_START: "MENU_V2_ITEM_CATEGORY_START",
  MENU_V2_ITEM_CATEGORY_SUCCESS: "MENU_V2_ITEM_CATEGORY_SUCCESS",
  MENU_V2_ITEM_CATEGORY_FAIL: "MENU_V2_ITEM_CATEGORY_FAIL",

  MENU_V2_ITEM_ALLERGENES_START: "MENU_V2_ITEM_ALLERGENES_START",
  MENU_V2_ITEM_ALLERGENES_SUCCESS: "MENU_V2_ITEM_ALLERGENES_SUCCESS",
  MENU_V2_ITEM_ALLERGENES_FAIL: "MENU_V2_ITEM_ALLERGENES_FAIL",

  MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_START:
    "MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_START",
  MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS:
    "MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS",
  MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_FAIL:
    "MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_FAIL",

  MENU_V2_ITEM_CREATE_START: "MENU_V2_ITEM_CREATE_START",
  MENU_V2_ITEM_CREATE_SUCCESS: "MENU_V2_ITEM_CREATE_SUCCESS",
  MENU_V2_ITEM_CREATE_FAIL: "MENU_V2_ITEM_CREATE_FAIL",

  MENU_V2_DUPLICATE_START: "MENU_V2_DUPLICATE_START",
  MENU_V2_DUPLICATE_SUCCESS: "MENU_V2_DUPLICATE_SUCCESS",
  MENU_V2_DUPLICATE_FAIL: "MENU_V2_DUPLICATE_FAIL",

  MENU_V2_ITEM_EDIT_START: "MENU_V2_ITEM_EDIT_START",
  MENU_V2_ITEM_EDIT_SUCCESS: "MENU_V2_ITEM_EDIT_SUCCESS",
  MENU_V2_ITEM_EDIT_FAIL: "MENU_V2_ITEM_EDIT_FAIL",

  MENU_V2_ITEM_VISIBILITY_START: "MENU_V2_ITEM_VISIBILITY_START",
  MENU_V2_ITEM_VISIBILITY_SUCCESS: "MENU_V2_ITEM_VISIBILITY_SUCCESS",
  MENU_V2_ITEM_VISIBILITY_FAIL: "MENU_V2_ITEM_VISIBILITY_FAIL",

  MENU_V2_CATEGORY_EDIT_START: "MENU_V2_CATEGORY_EDIT_START",
  MENU_V2_CATEGORY_EDIT_SUCCESS: "MENU_V2_CATEGORY_EDIT_SUCCESS",
  MENU_V2_CATEGORY_EDIT_FAIL: "MENU_V2_CATEGORY_EDIT_FAIL",

  MENU_V2_CATEGORY_VISIBILITY_START: "MENU_V2_CATEGORY_VISIBILITY_START",
  MENU_V2_CATEGORY_VISIBILITY_SUCCESS: "MENU_V2_CATEGORY_VISIBILITY_SUCCESS",
  MENU_V2_CATEGORY_VISIBILITY_FAIL: "MENU_V2_CATEGORY_VISIBILITY_FAIL",

  MENU_V2_GROUP_MODIFIER_EDIT_START: "MENU_V2_GROUP_MODIFIER_EDIT_START",
  MENU_V2_GROUP_MODIFIER_EDIT_SUCCESS: "MENU_V2_GROUP_MODIFIER_EDIT_SUCCESS",
  MENU_V2_GROUP_MODIFIER_EDIT_FAIL: "MENU_V2_GROUP_MODIFIER_EDIT_FAIL",

  MENU_V2_GROUP_MODIFIER_VISIBILITY_START:
    "MENU_V2_GROUP_MODIFIER_VISIBILITY_START",
  MENU_V2_GROUP_MODIFIER_VISIBILITY_SUCCESS:
    "MENU_V2_GROUP_MODIFIER_VISIBILITY_SUCCESS",
  MENU_V2_GROUP_MODIFIER_VISIBILITY_FAIL:
    "MENU_V2_GROUP_MODIFIER_VISIBILITY_FAIL",

  MENU_V2_MODIFIER_EDIT_START: "MENU_V2_MODIFIER_EDIT_START",
  MENU_V2_MODIFIER_EDIT_SUCCESS: "MENU_V2_MODIFIER_EDIT_SUCCESS",
  MENU_V2_MODIFIER_EDIT_FAIL: "MENU_V2_MODIFIER_EDIT_FAIL",

  MENU_V2_MODIFIER_VISIBILITY_START: "MENU_V2_MODIFIER_VISIBILITY_START",
  MENU_V2_MODIFIER_VISIBILITY_SUCCESS: "MENU_V2_MODIFIER_VISIBILITY_SUCCESS",
  MENU_V2_MODIFIER_VISIBILITY_FAIL: "MENU_V2_MODIFIER_VISIBILITY_FAIL",

  MENU_V2_ITEM_DELETE_START: "MENU_V2_ITEM_DELETE_START",
  MENU_V2_ITEM_DELETE_SUCCESS: "MENU_V2_ITEM_DELETE_SUCCESS",
  MENU_V2_ITEM_DELETE_FAIL: "MENU_V2_ITEM_DELETE_FAIL",

  MENU_V2_CAT_CREATE_START: "MENU_V2_CAT_CREATE_START",
  MENU_V2_CAT_CREATE_SUCCESS: "MENU_V2_CAT_CREATE_SUCCESS",
  MENU_V2_CAT_CREATE_FAIL: "MENU_V2_CAT_CREATE_FAIL",

  MENU_V2_CAT_EDIT_START: "MENU_V2_CAT_EDIT_START",
  MENU_V2_CAT_EDIT_SUCCESS: "MENU_V2_CAT_EDIT_SUCCESS",
  MENU_V2_CAT_EDIT_FAIL: "MENU_V2_CAT_EDIT_FAIL",

  MENU_V2_CAT_DELETE_START: "MENU_V2_CAT_DELETE_START",
  MENU_V2_CAT_DELETE_SUCCESS: "MENU_V2_CAT_DELETE_SUCCESS",
  MENU_V2_CAT_DELETE_FAIL: "MENU_V2_CAT_DELETE_FAIL",

  MENU_V2_CATALOG_COMBO_CATEGORY_SORT_START:
    "MENU_V2_CATALOG_COMBO_CATEGORY_SORT_START",
  MENU_V2_CATALOG_COMBO_CATEGORY_SORT_SUCCESS:
    "MENU_V2_CATALOG_COMBO_CATEGORY_SORT_SUCCESS",
  MENU_V2_CATALOG_COMBO_CATEGORY_SORT_FAIL:
    "MENU_V2_CATALOG_COMBO_CATEGORY_SORT_FAIL",
  MENU_V2_CATALOG_PRODUCT_SORT_START: "MENU_V2_CATALOG_PRODUCT_SORT_START",
  MENU_V2_CATALOG_PRODUCT_SORT_SUCCESS: "MENU_V2_CATALOG_PRODUCT_SORT_SUCCESS",
  MENU_V2_CATALOG_PRODUCT_SORT_FAIL: "MENU_V2_CATALOG_PRODUCT_SORT_FAIL",
  MENU_V2_CATALOG_GROUP_MODIFIER_SORT_START:
    "MENU_V2_CATALOG_GROUP_MODIFIER_SORT_START",
  MENU_V2_CATALOG_GROUP_MODIFIER_SORT_SUCCESS:
    "MENU_V2_CATALOG_GROUP_MODIFIER_SORT_SUCCESS",
  MENU_V2_CATALOG_GROUP_MODIFIER_SORT_FAIL:
    "MENU_V2_CATALOG_GROUP_MODIFIER_SORT_FAIL",

  MENU_V2_CATALOG_MODIFIER_SORT_START: "MENU_V2_CATALOG_MODIFIER_SORT_START",
  MENU_V2_CATALOG_MODIFIER_SORT_SUCCESS:
    "MENU_V2_CATALOG_MODIFIER_SORT_SUCCESS",
  MENU_V2_CATALOG_MODIFIER_SORT_FAIL: "MENU_V2_CATALOG_MODIFIER_SORT_FAIL",
};

export const LoyaltyActionTypes = {
  LOYALTY_LIST_START: "LOYALTY_LIST_START",
  LOYALTY_LIST_SUCCESS: "LOYALTY_LIST_SUCCESS",
  LOYALTY_LIST_FAIL: "LOYALTY_LIST_FAIL",

  TRANSACTION_LIST_START: "TRANSACTION_LIST_START",
  TRANSACTION_LIST_SUCCESS: "TRANSACTION_LIST_SUCCESS",
  TRANSACTION_LIST_FAIL: "TRANSACTION_LIST_FAIL",

  SET_TRANSACTION_SEARCH_TEXT: "SET_TRANSACTION_SEARCH_TEXT",

  LOYALTY_REWARD_DETAIL_START: "LOYALTY_REWARD_DETAIL_START",
  LOYALTY_REWARD_DETAIL_SUCCESS: "LOYALTY_REWARD_DETAIL_SUCCESS",
  LOYALTY_REWARD_DETAIL_FAIL: "LOYALTY_REWARD_DETAIL_FAIL",

  LOYALTY_REWARD_CREATE_START: "LOYALTY_REWARD_CREATE_START",
  LOYALTY_REWARD_CREATE_SUCCESS: "LOYALTY_CREATE_SUCCESS",
  LOYALTY_REWARD_CREATE_FAIL: "LOYALTY_CREATE_FAIL",

  LOYALTY_REWARD_EDIT_START: "LOYALTY_REWARD_EDIT_START",
  LOYALTY_REWARD_EDIT_SUCCESS: "LOYALTY_REWARD_EDIT_SUCCESS",
  LOYALTY_REWARD_EDIT_FAIL: "LOYALTY_REWARD_EDIT_FAIL",

  LOYALTY_REWARD_DELETE_START: "LOYALTY_REWARD_DELETE_START",
  LOYALTY_REWARD_DELETE_SUCCESS: "LOYALTY_REWARD_DELETE_SUCCESS",
  LOYALTY_REWARD_DELETE_FAIL: "LOYALTY_REWARD_DELETE_FAIL",
};

export const CatalogueActionTypes = {
  PRODUCT_LIST_START: "PRODUCT_LIST_START",
  PRODUCT_LIST_SUCCESS: "PRODUCT_LIST_SUCCESS",
  PRODUCT_LIST_FAIL: "PRODUCT_LIST_FAIL",

  PRODUCT_DETAIL_START: "PRODUCT_DETAIL_START",
  PRODUCT_DETAIL_SUCCESS: "PRODUCT_DETAIL_SUCCESS",
  PRODUCT_DETAIL_FAIL: "PRODUCT_DETAIL_FAIL",

  PRODUCT_CREATE_START: "PRODUCT_CREATE_START",
  PRODUCT_CREATE_SUCCESS: "PRODUCT_CREATE_SUCCESS",
  PRODUCT_CREATE_FAIL: "PRODUCT_CREATE_FAIL",

  PRODUCT_EDIT_START: "PRODUCT_EDIT_START",
  PRODUCT_EDIT_SUCCESS: "PRODUCT_EDIT_SUCCESS",
  PRODUCT_EDIT_FAIL: "PRODUCT_EDIT_FAIL",

  PRODUCT_DELETE_START: "PRODUCT_DELETE_START",
  PRODUCT_DELETE_SUCCESS: "PRODUCT_DELETE_SUCCESS",
  PRODUCT_DELETE_FAIL: "PRODUCT_DELETE_FAIL",

  PRODUCT_VISIBILITY_START: "PRODUCT_VISIBILITY_START",
  PRODUCT_VISIBILITY_SUCCESS: "PRODUCT_VISIBILITY_SUCCESS",
  PRODUCT_VISIBILITY_FAIL: "PRODUCT_VISIBILITY_FAIL",

  GROUP_MODIFIER_VISIBILITY_START: "GROUP_MODIFIER_VISIBILITY_START",
  GROUP_MODIFIER_VISIBILITY_SUCCESS: "GROUP_MODIFIER_VISIBILITY_SUCCESS",
  GROUP_MODIFIER_VISIBILITY_FAIL: "GROUP_MODIFIER_VISIBILITY_FAIL",

  MODIFIER_VISIBILITY_START: "MODIFIER_VISIBILITY_START",
  MODIFIER_VISIBILITY_SUCCESS: "MODIFIER_VISIBILITY_SUCCESS",
  MODIFIER_VISIBILITY_FAIL: "MODIFIER_VISIBILITY_FAIL",

  SET_PRODUCT_SEARCH_TEXT: "SET_PRODUCT_SEARCH_TEXT",

  MODIFIER_LIST_START: "MODIFIER_LIST_START",
  MODIFIER_LIST_SUCCESS: "MODIFIER_LIST_SUCCESS",
  MODIFIER_LIST_FAIL: "MODIFIER_LIST_FAIL",

  MODIFIER_DETAIL_START: "MODIFIER_DETAIL_START",
  MODIFIER_DETAIL_SUCCESS: "MODIFIER_DETAIL_SUCCESS",
  MODIFIER_DETAIL_FAIL: "MODIFIER_DETAIL_FAIL",

  MODIFIER_CREATE_START: "PRODUCT_CREATE_START",
  MODIFIER_CREATE_SUCCESS: "PRODUCT_CREATE_SUCCESS",
  MODIFIER_CREATE_FAIL: "PRODUCT_CREATE_FAIL",

  MODIFIER_EDIT_START: "PRODUCT_EDIT_START",
  MODIFIER_EDIT_SUCCESS: "PRODUCT_EDIT_SUCCESS",
  MODIFIER_EDIT_FAIL: "PRODUCT_EDIT_FAIL",

  MODIFIER_DELETE_START: "PRODUCT_DELETE_START",
  MODIFIER_DELETE_SUCCESS: "MODIFIER_DELETE_SUCCESS",
  MODIFIER_DELETE_FAIL: "MODIFIER_DELETE_FAIL",

  SET_MODIFIER_SEARCH_TEXT: "SET_MODIFIER_SEARCH_TEXT",

  GROUP_MODIFIER_LIST_START: "GROUP_MODIFIER_LIST_START",
  GROUP_MODIFIER_LIST_SUCCESS: "GROUP_MODIFIER_LIST_SUCCESS",
  GROUP_MODIFIER_LIST_FAIL: "GROUP_MODIFIER_LIST_FAIL",

  GROUP_MODIFIER_DETAIL_START: "GROUP_MODIFIER_DETAIL_START",
  GROUP_MODIFIER_DETAIL_SUCCESS: "GROUP_MODIFIER_DETAIL_SUCCESS",
  GROUP_MODIFIER_DETAIL_FAIL: "GROUP_MODIFIER_DETAIL_FAIL",

  GROUP_MODIFIER_CREATE_START: "GROUP_MODIFIER_CREATE_START",
  GROUP_MODIFIER_CREATE_SUCCESS: "GROUP_MODIFIER_CREATE_SUCCESS",
  GROUP_MODIFIER_CREATE_FAIL: "GROUP_MODIFIER_CREATE_FAIL",

  GROUP_MODIFIER_EDIT_START: "GROUP_MODIFIER_EDIT_START",
  GROUP_MODIFIER_EDIT_SUCCESS: "GROUP_MODIFIER_EDIT_SUCCESS",
  GROUP_MODIFIER_EDIT_FAIL: "GROUP_MODIFIER_EDIT_FAIL",

  GROUP_MODIFIER_DELETE_START: "GROUP_MODIFIER_DELETE_START",
  GROUP_MODIFIER_DELETE_SUCCESS: "GROUP_MODIFIER_DELETE_SUCCESS",
  GROUP_MODIFIER_DELETE_FAIL: "GROUP_MODIFIER_DELETE_FAIL",

  SET_GROUP_MODIFIER_SEARCH_TEXT: "SET_GROUP_MODIFIER_SEARCH_TEXT",

  //Size
  GET_PRODUCT_SIZES_START: "GET_PRODUCT_SIZES_START",
  GET_PRODUCT_SIZES_SUCCESS: "GET_PRODUCT_SIZES_SUCCESS",
  GET_PRODUCT_SIZES_FAIL: "GET_PRODUCT_SIZES_FAIL",

  CREATE_SIZE_START: "CREATE_SIZE_START",
  CREATE_SIZE_SUCCESS: "CREATE_SIZE_SUCCESS",
  CREATE_SIZE_FAIL: "CREATE_SIZE_FAIL",

  DELETE_SIZE_START: "DELETE_SIZE_START",
  DELETE_SIZE_SUCCESS: "DELETE_SIZE_SUCCESS",
  DELETE_SIZE_FAIL: "DELETE_SIZE_FAIL",

  UPDATE_SIZE_START: "UPDATE_SIZE_START",
  UPDATE_SIZE_SUCCESS: "UPDATE_SIZE_SUCCESS",
  UPDATE_SIZE_FAIL: "UPDATE_SIZE_FAIL",

  ARRANGE_SIZES_START: "ARRANGE_SIZES_START",
  ARRANGE_SIZES_SUCCESS: "ARRANGE_SIZES_SUCCESS",
  ARRANGE_SIZES_FAIL: "ARRANGE_SIZES_FAIL",

  EMPTY_SKU_LIST: "EMPTY_SKU_LIST",

  CATALOG_COMBO_LIST_START: "CATALOG_COMBO_LIST_START",
  CATALOG_COMBO_LIST_SUCCESS: "CATALOG_COMBO_LIST_SUCCESS",
  CATALOG_COMBO_LIST_FAIL: "CATALOG_COMBO_LIST_FAIL",

  CATALOG_COMBO_DELETE_START: "CATALOG_COMBO_DELETE_START",
  CATALOG_COMBO_DELETE_SUCCESS: "CATALOG_COMBO_DELETE_SUCCESS",
  CATALOG_COMBO_DELETE_FAIL: "CATALOG_COMBO_DELETE_FAIL",

  CATALOG_COMBO_CREATE_START: "CATALOG_COMBO_CREATE_START",
  CATALOG_COMBO_CREATE_SUCCESS: "CATALOG_COMBO_CREATE_SUCCESS",
  CATALOG_COMBO_CREATE_FAIL: "CATALOG_COMBO_CREATE_FAIL",

  CATALOG_COMBO_UPDATE_START: "CATALOG_COMBO_UPDATE_START",
  CATALOG_COMBO_UPDATE_SUCCESS: "CATALOG_COMBO_UPDATE_SUCCESS",
  CATALOG_COMBO_UPDATE_FAIL: "CATALOG_COMBO_UPDATE_FAIL",
};

export const NotificationActionTypes = {
  NOTIFICATION_LIST_START: "NOTIFICATION_LIST_START",
  NOTIFICATION_LIST_SUCCESS: "NOTIFICATION_LIST_SUCCESS",
  NOTIFICATION_LIST_FAIL: "NOTIFICATION_LIST_FAIL",

  SET_NOTIFICATION_SEARCH_TEXT: "SET_NOTIFICATION_SEARCH_TEXT",

  NOTIFICATION_CREATE_START: "NOTIFICATION_CREATE_START",
  NOTIFICATION_CREATE_SUCCESS: "NOTIFICATION_CREATE_SUCCESS",
  NOTIFICATION_CREATE_FAIL: "NOTIFICATION_CREATE_FAIL",
};

export const OrderActionTypes = {
  ORDERS_LIST_START: "ORDERS_LIST_START",
  ORDERS_LIST_SUCCESS: "ORDERS_LIST_SUCCESS",
  ORDERS_LIST_FAIL: "ORDERS_LIST_FAIL",

  SET_ORDER_SEARCH_TEXT: "SET_ORDER_SEARCH_TEXT",

  ORDER_DETAIL_START: "ORDER_DETAIL_START",
  ORDER_DETAIL_SUCCESS: "ORDER_DETAIL_SUCCESS",
  ORDER_DETAIL_FAIL: "ORDER_DETAIL_FAIL",

  ORDER_REVENUE_START: "ORDER_REVENUE_START",
  ORDER_REVENUE_SUCCESS: "ORDER_REVENUE_SUCCESS",
  ORDER_REVENUE_FAIL: "ORDER_REVENUE_FAIL",

  ORDER_ANALYTICS_START: "ORDER_ANALYTICS_START",
  ORDER_ANALYTICS_SUCCESS: "ORDER_ANALYTICS_SUCCESS",
  ORDER_ANALYTICS_FAIL: "ORDER_REVENUE_FAIL",

  CHANGE_ORDER_STATUS_START: "CHANGE_ORDER_STATUS_START",
  CHANGE_ORDER_STATUS_SUCCESS: "CHANGE_ORDER_STATUS_SUCCESS",
  CHANGE_ORDER_STATUS_FAIL: "CHANGE_ORDER_STATUS_FAIL",

  TABLE_LIST_START: "TABLE_LIST_START",
  TABLE_LIST_SUCCESS: "TABLE_LIST_SUCCESS",
  TABLE_LIST_FAIL: "TABLE_LIST_FAIL",

  TABLE_DELETE_START: "TABLE_DELETE_START",
  TABLE_DELETE_SUCCESS: "TABLE_DELETE_SUCCESS",
  TABLE_DELETE_FAIL: "TABLE_DELETE_FAIL",

  TABLE_CREATE_START: "TABLE_CREATE_START",
  TABLE_CREATE_SUCCESS: "TABLE_CREATE_SUCCESS",
  TABLE_CREATE_FAIL: "TABLE_CREATE_FAIL",

  TABLE_UPDATE_START: "TABLE_UPDATE_START",
  TABLE_UPDATE_SUCCESS: "TABLE_UPDATE_SUCCESS",
  TABLE_UPDATE_FAIL: "TABLE_UPDATE_FAIL",

  PAYMENT_REFUND_START: "PAYMENT_REFUND_START",
  PAYMENT_REFUND_SUCCESS: "PAYMENT_REFUND_SUCCESS",
  PAYMENT_REFUND_FAIL: "PAYMENT_REFUND_FAIL",

  EXPOER_ORDERS_LIST_START: "EXPOER_ORDERS_LIST_START",
  EXPOER_ORDERS_LIST_SUCCESS: "EXPOER_ORDERS_LIST_SUCCESS",
  EXPOER_ORDERS_LIST_FAILED: "EXPOER_ORDERS_LIST_FAILED",
};

export const ScheduleOrderActionTypes = {
  SCHEDULE_ORDERS_LIST_START: "SCHEDULE_ORDERS_LIST_START",
  SCHEDULE_ORDERS_LIST_SUCCESS: "SCHEDULE_ORDERS_LIST_SUCCESS",
  SCHEDULE_ORDERS_LIST_FAIL: "SCHEDULE_ORDERS_LIST_FAIL",

  SET_ORDER_SEARCH_TEXT: "SET_SCHEDULE_ORDER_SEARCH_TEXT",

  SCHEDULE_ORDER_DETAIL_START: "SCHEDULE_ORDER_DETAIL_START",
  SCHEDULE_ORDER_DETAIL_SUCCESS: "SCHEDULE_ORDER_DETAIL_SUCCESS",
  SCHEDULE_ORDER_DETAIL_FAIL: "SCHEDULE_ORDER_DETAIL_FAIL",
};

export const PaymentActionTypes = {
  CREATE_SUBSCRIPTION_START: "CREATE_SUBSCRIPTION_START",
  CREATE_SUBSCRIPTION_SUCCESS: "CREATE_SUBSCRIPTION_SUCCESS",
  CREATE_SUBSCRIPTION_FAIL: "CREATE_SUBSCRIPTION_FAIL",

  RESET_SUBSCRIPTION_SUCCESS: "RESET_SUBSCRIPTION_SUCCESS",

  GET_CARD_LIST_START: "GET_CARD_LIST_START",
  GET_CARD_LIST_SUCCESS: "GET_CARD_LIST_SUCCESS",
  GET_CARD_LIST_FAIL: "GET_CARD_LIST_FAIL",

  SAVE_CARD_DETAILS_START: "SAVE_CARD_DETAILS_START",
  SAVE_CARD_DETAILS_SUCCESS: "SAVE_CARD_DETAILS_SUCCESS",
  SAVE_CARD_DETAILS_FAIL: "SAVE_CARD_DETAILS_FAIL",

  DELETE_CARD_DETAILS_START: "DELETE_CARD_DETAILS_START",
  DELETE_CARD_DETAILS_SUCCESS: "DELETE_CARD_DETAILS_SUCCESS",
  DELETE_CARD_DETAILS_FAIL: "DELETE_CARD_DETAILS_FAIL",

  SET_DEFAULT_CARD_START: "SET_DEFAULT_CARD_START",
  SET_DEFAULT_CARD_SUCCESS: "SET_DEFAULT_CARD_SUCCESS",
  SET_DEFAULT_CARD_FAIL: "SET_DEFAULT_CARD_FAIL",
};

export const StaffActionTypes = {
  STAFF_LIST_START: "STAFF_LIST_START",
  STAFF_LIST_SUCCESS: "STAFF_LIST_SUCCESS",
  STAFF_LIST_FAIL: "STAFF_LIST_FAIL",

  SET_STAFF_SEARCH_TEXT: "SET_STAFF_SEARCH_TEXT",

  UPDATE_STAFF_START: "UPDATE_STAFF_START",
  UPDATE_STAFF_SUCCESS: "UPDATE_STAFF_SUCCESS",
  UPDATE_STAFF_FAIL: "UPDATE_STAFF_FAIL",

  CREATE_STAFF_START: "CREATE_STAFF_START",
  CREATE_STAFF_SUCCESS: "CREATE_STAFF_SUCCESS",
  CREATE_STAFF_FAIL: "CREATE_STAFF_FAIL",

  EDIT_STAFF_START: "EDIT_STAFF_START",
  EDIT_STAFF_SUCCESS: "EDIT_STAFF_SUCCESS",
  EDIT_STAFF_FAIL: "EDIT_STAFF_FAIL",

  STAFF_DETAIL_START: "STAFF_DETAIL_START",
  STAFF_DETAIL_SUCCESS: "STAFF_DETAIL_SUCCESS",
  STAFF_DETAIL_FAIL: "STAFF_DETAIL_FAIL",

  STAFF_DELETE_START: "STAFF_DELETE_START",
  STAFF_DELETE_SUCCESS: "STAFF_DELETE_SUCCESS",
  STAFF_DELETE_FAIL: "STAFF_DELETE_FAIL",
};

export const MerchantActionTypes = {
  PURCHASE_PRODUCT_START: "PURCHASE_PRODUCT_START",
  PURCHASE_PRODUCT_SUCCESS: "PURCHASE_PRODUCT_SUCCESS",
  PURCHASE_PRODUCT_FAIL: "PURCHASE_PRODUCT_FAIL",

  CLICKED_QR_START: "CLICKED_QR_START",
  CLICKED_QR_SUCCESS: "CLICKED_QR_SUCCESS",
  CLICKED_QR_FAIL: "CLICKED_QR_FAIL",

  GET_CART_SECRET_START: "GET_CART_SECRET_START",
  GET_CART_SECRET_SUCCESS: "GET_CART_SECRET_SUCCESS",
  GET_CART_SECRET_FAIL: "GET_CART_SECRET_FAIL",

  SEND_UP_SELL_EMAIL_START: "SEND_UP_SELL_EMAIL_START",
  SEND_UP_SELL_EMAIL_SUCCESS: "SEND_UP_SELL_EMAIL_SUCCESS",
  SEND_UP_SELL_EMAIL_FAIL: "SEND_UP_SELL_EMAIL_FAIL",
};

export const DiscountVoucherActionTypes = {
  DISCOUNT_VOUCHER_LIST_START: "DISCOUNT_VOUCHER_LIST_START",
  DISCOUNT_VOUCHER_LIST_SUCCESS: "DISCOUNT_VOUCHER_LIST_SUCCESS",
  DISCOUNT_VOUCHER_LIST_FAIL: "DISCOUNT_VOUCHER_LIST_FAIL",

  DISCOUNT_VOUCHER_DETAIL_START: "DISCOUNT_VOUCHER_DETAIL_START",
  DISCOUNT_VOUCHER_DETAIL_SUCCESS: "DISCOUNT_VOUCHER_DETAIL_SUCCESS",
  DISCOUNT_VOUCHER_DETAIL_FAIL: "DISCOUNT_VOUCHER_DETAIL_FAIL",

  DISCOUNT_VOUCHER_CREATE_START: "DISCOUNT_VOUCHER_CREATE_START",
  DISCOUNT_VOUCHER_CREATE_SUCCESS: "DISCOUNT_VOUCHER_CREATE_SUCCESS",
  DISCOUNT_VOUCHER_CREATE_FAIL: "DISCOUNT_VOUCHER_CREATE_FAIL",

  DISCOUNT_VOUCHER_EDIT_START: "DISCOUNT_VOUCHER_EDIT_START",
  DISCOUNT_VOUCHER_EDIT_SUCCESS: "DISCOUNT_VOUCHER_EDIT_SUCCESS",
  DISCOUNT_VOUCHER_EDIT_FAIL: "DISCOUNT_VOUCHER_EDIT_FAIL",

  DISCOUNT_VOUCHER_DELETE_START: "DISCOUNT_VOUCHER_DELETE_START",
  DISCOUNT_VOUCHER_DELETE_SUCCESS: "DISCOUNT_VOUCHER_DELETE_SUCCESS",
  DISCOUNT_VOUCHER_DELETE_FAIL: "DISCOUNT_VOUCHER_DELETE_FAIL",

  VOUCHER_DELETE_START: "VOUCHER_DELETE_START",
  VOUCHER_DELETE_FAIL: "VOUCHER_DELETE_FAIL",
  VOUCHER_DELETE_SUCCESS: "VOUCHER_DELETE_SUCCESS",
};

export const VirtualCardOrdersType = {
  VIRTUAL_ORDER_LIST_START: "VIRTUAL_ORDER_LIST_START",
  VIRTUAL_ORDER_LIST_SUCCESS: "VIRTUAL_ORDER_LIST_SUCCESS",
  VIRTUAL_ORDER_LIST_FAIL: "VIRTUAL_ORDER_LIST_FAIL",

  SET_VIRTUAL_ORDER_SEARCH_TEXT: "SET_VIRTUAL_ORDER_SEARCH_TEXT",

  VIRTUAL_ORDER_DETAIL_START: "VIRTUAL_ORDER_DETAIL_START",
  VIRTUAL_ORDER_DETAIL_SUCCESS: "VIRTUAL_ORDER_DETAIL_SUCCESS",
  VIRTUAL_ORDER_DETAIL_FAIL: "VIRTUAL_ORDER_DETAIL_FAIL",
};
export const PaymentHistoryType = {
  PAYMENT_HISTORY_LIST_START: "PAYMENT_HISTORY_LIST_START",
  PAYMENT_HISTORY_LIST_SUCCESS: "PAYMENT_HISTORY_LIST_SUCCESS",
  PAYMENT_HISTORY_LIST_FAIL: "PAYMENT_HISTORY_LIST_FAIL",

  PAYMENT_HISTORY_SEARCH_TEXT: "PAYMENT_HISTORY_SEARCH_TEXT",

  PAYMENT_HISTORY_DETAIL_START: "PAYMENT_HISTORY_DETAIL_START",
  PAYMENT_HISTORY_DETAIL_SUCCESS: "PAYMENT_HISTORY_DETAIL_SUCCESS",
  PAYMENT_HISTORY_DETAIL_FAIL: "PAYMENT_HISTORY_DETAIL_FAIL",

  PAYMENT_HISTORY_REFUND_START: "PAYMENT_HISTORY_REFUND_START",
  PAYMENT_HISTORY_REFUND_SUCCESS: "PAYMENT_HISTORY_REFUND_SUCCESS",
  PAYMENT_HISTORY_REFUND_FAIL: "PAYMENT_HISTORY_REFUND_FAIL",
};

export const paginationActionTypes = {
  SET_LIST_LIMIT_PAGE_ORDERS: "SET_LIST_LIMIT_PAGE_ORDERS",
  SET_LIST_LIMIT_PAGE_HISTORY: "SET_LIST_LIMIT_PAGE_HISTORY",
  SET_LIST_LIMIT_PAGE_VIRTUAL_CART: "SET_LIST_LIMIT_PAGE_VIRTUAL_CART",
  SET_LIST_LIMIT_PAGE_GENERAL: "SET_LIST_LIMIT_PAGE_GENERAL",
  SET_LIST_LIMIT_PAGE_LOYALTY: "SET_LIST_LIMIT_PAGE_LOYALTY",
  SET_LIST_LIMIT_PAGE_TIPS: "SET_LIST_LIMIT_PAGE_TIPS",
  SET_LIST_LIMIT_PAGE_RATING: "SET_LIST_LIMIT_PAGE_RATING",
};

export const LoyaltyProgramActionTypes = {
  GET_LOYALTY_LIST_START: "LOYALTY_LIST_START",
  GET_LOYALTY_LIST_SUCCESS: "GET_LOYALTY_LIST_SUCCESS",
  GET_LOYALTY_LIST_FAIL: "GET_LOYALTY_LIST_FAIL",
  GET_LOYALTY_CLIENT_DETAIL_START: "GET_LOYALTY_CLIENT_DETAIL_START",
  GET_LOYALTY_CLIENT_DETAIL_SUCCESS: "GET_LOYALTY_CLIENT_DETAIL_SUCCESS",
  GET_LOYALTY_CLIENT_DETAIL_FAIL: "GET_LOYALTY_CLIENT_DETAIL_FAIL",
  SET_LOYALTY_CLIENT_SEARCH_TEXT: "SET_LOYALTY_CLIENT_SEARCH_TEXT",
};
export const venuePopupActionTypes = {
  UPDATE_VENUE_POPUP: "UPDATE_VENUE_POPUP",
  UPDATE_VENUE_POPUP_SUCCESS: "UPDATE_VENUE_POPUP_SUCCESS",
  UPDATE_VENUE_POPUP_FAIL: "UPDATE_VENUE_POPUP_FAIL",
  GET_VENUE_POPUP: "GET_VENUE_POPUP",
  GET_VENUE_POPUP_SUCCESS: "GET_VENUE_POPUP_SUCCESS",
  GET_VENUE_POPUP_FAIL: "GET_VENUE_POPUP_FAIL",
};

export const PromotionActionTypes = {
  PROMOTIONS_LIST_START: "PROMOTIONS_LIST_START",
  PROMOTIONS_LIST_SUCCESS: "PROMOTIONS_LIST_SUCCESS",
  PROMOTIONS_LIST_FAIL: "PROMOTIONS_LIST_FAIL",

  SET_PROMOTION_SEARCH_TEXT: "SET_PROMOTION_SEARCH_TEXT",

  PROMOTION_DETAIL_START: "PROMOTION_DETAIL_START",
  PROMOTION_DETAIL_SUCCESS: "PROMOTION_DETAIL_SUCCESS",
  PROMOTION_DETAIL_FAIL: "PROMOTION_DETAIL_FAIL",

  CHANGE_PROMOTION_STATUS_START: "CHANGE_PROMOTION_STATUS_START",
  CHANGE_PROMOTION_STATUS_SUCCESS: "CHANGE_PROMOTION_STATUS_SUCCESS",
  CHANGE_PROMOTION_STATUS_FAIL: "CHANGE_PROMOTION_STATUS_FAIL",

  PROMOTION_DELETE_START: "PROMOTION_DELETE_START",
  PROMOTION_DELETE_SUCCESS: "PROMOTION_DELETE_SUCCESS",
  PROMOTION_DELETE_FAIL: "PROMOTION_DELETE_FAIL",

  PROMOTION_CREATE_START: "PROMOTION_CREATE_START",
  PROMOTION_CREATE_SUCCESS: "PROMOTION_CREATE_SUCCESS",
  PROMOTION_CREATE_FAIL: "PROMOTION_CREATE_FAIL",

  SET_PROMOTION_DETAIL: "SET_PROMOTION_DETAIL",
};
export const ComboActionTypes = {
  COMBOS_LIST_START: "COMBOS_LIST_START",
  COMBOS_LIST_SUCCESS: "COMBOS_LIST_SUCCESS",
  COMBOS_LIST_FAIL: "COMBOS_LIST_FAIL",

  SET_COMBO_SEARCH_TEXT: "SET_COMBO_SEARCH_TEXT",

  COMBO_DETAIL_START: "COMBO_DETAIL_START",
  COMBO_DETAIL_SUCCESS: "COMBO_DETAIL_SUCCESS",
  COMBO_DETAIL_FAIL: "COMBO_DETAIL_FAIL",

  CHANGE_COMBO_STATUS_START: "CHANGE_COMBO_STATUS_START",
  CHANGE_COMBO_STATUS_SUCCESS: "CHANGE_COMBO_STATUS_SUCCESS",
  CHANGE_COMBO_STATUS_FAIL: "CHANGE_COMBO_STATUS_FAIL",

  COMBO_DELETE_START: "COMBO_DELETE_START",
  COMBO_DELETE_SUCCESS: "COMBO_DELETE_SUCCESS",
  COMBO_DELETE_FAIL: "COMBO_DELETE_FAIL",

  COMBO_CREATE_START: "COMBO_CREATE_START",
  COMBO_CREATE_SUCCESS: "COMBO_CREATE_SUCCESS",
  COMBO_CREATE_FAIL: "COMBO_CREATE_FAIL",

  SET_COMBO_DETAIL: "SET_COMBO_DETAIL",

  CHANGE_COMBO_AVAILABILITY_START: "CHANGE_COMBO_AVAILABILITY_START",
  CHANGE_COMBO_AVAILABILITY_SUCCESS: "CHANGE_COMBO_AVAILABILITY_SUCCESS",
  CHANGE_COMBO_AVAILABILITY_FAIL: "CHANGE_COMBO_AVAILABILITY_FAIL",

  UPDATE_COMBO_AVAILABILITY_START: "UPDATE_COMBO_AVAILABILITY_START",
  UPDATE_COMBO_AVAILABILITY_SUCCESS: "UPDATE_COMBO_AVAILABILITY_SUCCESS",
  UPDATE_COMBO_AVAILABILITY_FAIL: "UPDATE_COMBO_AVAILABILITY_FAIL",

  SORT_COMBO_CATEGORY_START: "ORT_COMBO_CATEGORY_START",
  SORT_COMBO_CATEGORY_SUCCESS: "SORT_COMBO_CATEGORY_SUCCESS",
  SORT_COMBO_CATEGORY_FAIL: "SORT_COMBO_CATEGORY_FAIL",

  SORT_COMBO_CATEGORY_PRODUCT_START: "ORT_COMBO_CATEGORY_PRODUCT_START",
  SORT_COMBO_CATEGORY_PRODUCT_SUCCESS: "SORT_COMBO_CATEGORY_PRODUCT_SUCCESS",
  SORT_COMBO_CATEGORY_PRODUCT_FAIL: "SORT_COMBO_CATEGORY_PRODUCT_FAIL",
};

export const KioskActionTypes = {
  CREATE_KIOSK_BRANDING_START: "CREATE_KIOSK_BRANDING_START",
  CREATE_KIOSK_BRANDING_SUCCESS: "CREATE_KIOSK_BRANDING_SUCCESS",
  CREATE_KIOSK_BRANDING_FAIL: "CREATE_KIOSK_BRANDING_FAIL",

  GET_KIOSK_BRANDING_START: "GET_KIOSK_BRANDING_START",
  GET_KIOSK_BRANDING_SUCCESS: "GET_KIOSK_BRANDING_SUCCESS",
  GET_KIOSK_BRANDING_FAIL: "GET_KIOSK_BRANDING_FAIL",
};
