import React, { FC } from "react";

import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import SizesCard from "./SizesCard";
import AlertDialog from "app/main/common/AlertDialog";
import * as Actions from "app/store/actions";

interface IProps {
  modifiers: any[];
  onRemove: (index: number) => void;
}
const ModifierList: FC<IProps> = ({ modifiers = [], onRemove = () => {} }) => {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();
  return (
    <Droppable droppableId="list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {modifiers &&
            modifiers.map((extraCategory, index) => (
              <SizesCard
                key={extraCategory._id}
                index={index}
                size={extraCategory}
                showEdit={false}
                onEdit={() => {
                  // setCategorySelected(extraCategory);
                  // setOpenCategories(true);
                }}
                onDelete={() => {
                  dispatch(
                    Actions.openDialog({
                      children: (
                        <AlertDialog
                          onSuccess={() => {
                            onRemove(index);
                            Actions.closeDialog();
                          }}
                          title={languageStrings["CONFIRM_DELETE_ALERT_TITLE"]}
                          message={languageStrings["CONFIRM_DELETE_ALERT_BODY"]}
                        />
                      ),
                    })
                  );
                }}
              />
            ))}
        </div>
      )}
    </Droppable>
  );
};

export default ModifierList;
