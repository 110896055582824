import { MenuV2ActionTypes } from "../redux/actionTypes";
import { updateObject } from "../utility";
import _ from "@lodash";

const INITIAL_STATE: MenuV2State = {
  menuV2List: [],
  menuV2ItemList: [],
  menuV2Detail: {},
  menuV2ItemDetail: {},
  menuV2ExtraCategories: [],
  menuV2ExtraCategoryItems: [],
  menuV2ItemCategory: [],
  menuV2ItemAllergenes: [],
  loading: false,
  searchText: "",
  searchCategoryV2: "",
};

interface Action {
  payload: any;
  type: string;
}

const MenV2Reducer = (
  state: MenuV2State = INITIAL_STATE,
  action: Action
): MenuV2State => {
  switch (action.type) {
    case MenuV2ActionTypes.MENU_V2_LIST_START:
      return updateObject(state, { loading: true, menuV2List: [] });

    case MenuV2ActionTypes.MENU_V2_LIST_SUCCESS:
      return updateObject(state, {
        menuV2List: action.payload,
        menuV2Detail: {},
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_LIST_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.SORT_MENU_V2_LIST_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.SORT_MENU_V2_LIST_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.SET_MENU_V2_SEARCH_TEXT:
      return updateObject(state, { searchText: action.payload.searchText });

    case MenuV2ActionTypes.SET_MENU_V2_ITEM_SEARCH_CATEGORY:
      return updateObject(state, { searchCategoryV2: action.payload });

    case MenuV2ActionTypes.MENU_V2_DETAIL_START:
      return updateObject(state, { loading: true, menuV2Detail: {} });

    case MenuV2ActionTypes.MENU_V2_DETAIL_SUCCESS:
      return updateObject(state, {
        menuV2Detail: action.payload,
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_CREATE_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_CREATE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_CREATE_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_EDIT_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_EDIT_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_EDIT_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_DELETE_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_DELETE_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_ITEM_LIST_START:
      return updateObject(state, { loading: true, menuV2ItemList: [] });

    case MenuV2ActionTypes.MENU_V2_ITEM_LIST_SUCCESS:
      return updateObject(state, {
        menuV2ItemList: action.payload,
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_ITEM_LIST_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_ITEM_DETAIL_START:
      return updateObject(state, { loading: true, menuV2ItemDetail: {} });

    case MenuV2ActionTypes.MENU_V2_ITEM_DETAIL_SUCCESS:
      return updateObject(state, {
        menuV2ItemDetail: action.payload,
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_ITEM_DETAIL_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_ITEM_CATEGORY_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_ITEM_CATEGORY_SUCCESS:
      return updateObject(state, {
        menuV2ItemCategory: action.payload,
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_ITEM_CATEGORY_FAIL:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_ITEM_ALLERGENES_START:
      return updateObject(state, { loading: true, menuV2ItemAllergenes: [] });

    case MenuV2ActionTypes.MENU_V2_ITEM_ALLERGENES_SUCCESS:
      return updateObject(state, {
        menuV2ItemAllergenes: action.payload,
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_ITEM_ALLERGENES_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_ITEM_CREATE_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_ITEM_CREATE_SUCCESS:
      return updateObject(state, {
        menuV2ItemList: [...state.menuV2ItemList, action.payload.data],
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_ITEM_CREATE_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_ITEM_EDIT_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_ITEM_EDIT_SUCCESS:
      return updateObject(state, {
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_ITEM_EDIT_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_ITEM_DELETE_START:
      let newMenuItemList = state.menuV2ItemList;
      let newIndex = state.menuV2ItemList.findIndex(
        (menuItem) => menuItem._id === action.payload
      );

      if (newIndex >= 0) {
        newMenuItemList.splice(newIndex, 1);
      }

      return updateObject(state, {
        menuItemList: state.menuV2ItemList.filter(
          (menuItem) => menuItem._id !== action.payload
        ),
        loading: false,
      });

    case MenuV2ActionTypes.MENU_V2_ITEM_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_ITEM_DELETE_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        menuV2ExtraCategories: action.payload,
      });

    case MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_FAIL:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_ITEM_START:
      return updateObject(state, {
        loading: true,
        menuV2ExtraCategoryItems: [],
      });

    case MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_ITEM_SUCCESS:
      return updateObject(state, {
        loading: false,
        menuV2ExtraCategoryItems: action.payload,
      });
    case MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_EDIT_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_EDIT_SUCCESS:
      return updateObject(state, {
        menuV2ItemList: state.menuV2ItemList.map((menuItem) => {
          if (menuItem._id === action.payload.menuItemId) {
            let newIndex = menuItem.extraCategories.findIndex(
              (extraCategory) => extraCategory._id === action.payload._id
            );
            menuItem.extraCategories[newIndex] = action.payload;
          }
          return menuItem;
        }),
        loading: false,
      });
    case MenuV2ActionTypes.SORT_MENU_V2_LIST_START: {
      const { to, from, categoryId } = action.payload;

      let fromId = "";
      let toId = "";
      state.menuV2ItemList.map((item) => {
        if (item.categoryId === categoryId && item.position === from) {
          fromId = item._id;
        }
        if (item.categoryId === categoryId && item.position === to) {
          toId = item._id;
        }
      });

      const sortedMenuItems = state.menuV2ItemList.map((item) => {
        if (item._id === fromId) {
          return {
            ...item,
            position: to,
          };
        }
        if (item._id === toId) {
          return {
            ...item,
            position: from,
          };
        }
        return item;
      });

      return updateObject(state, {
        menuItemList: sortedMenuItems,
        loading: true,
      });
    }

    case MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_EDIT_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_CAT_DELETE_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_CAT_DELETE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_CAT_DELETE_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.GET_MENU_V2_EXTRA_CATEGORY_ITEM_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_EXTRA_CATEGORY_ITEM_CREATE_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_CATEGORY_VISIBILITY_START:
      return updateObject(state, { loading: true });

    case MenuV2ActionTypes.MENU_V2_CATEGORY_VISIBILITY_SUCCESS:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_CATEGORY_VISIBILITY_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_ITEM_VISIBILITY_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.MENU_V2_ITEM_VISIBILITY_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_ITEM_VISIBILITY_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_GROUP_MODIFIER_VISIBILITY_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.MENU_V2_GROUP_MODIFIER_VISIBILITY_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_GROUP_MODIFIER_VISIBILITY_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.MENU_V2_MODIFIER_VISIBILITY_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.MENU_V2_MODIFIER_VISIBILITY_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_MODIFIER_VISIBILITY_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.COMBO_CATEGORY_EDIT_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.COMBO_CATEGORY_EDIT_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.COMBO_CATEGORY_EDIT_FAIL:
      return updateObject(state, { loading: false });

    case MenuV2ActionTypes.COMBO_CATEGORY_VISIBILITY_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.COMBO_CATEGORY_VISIBILITY_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.COMBO_CATEGORY_VISIBILITY_FAIL:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_CATALOG_COMBO_CATEGORY_SORT_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.MENU_V2_CATALOG_COMBO_CATEGORY_SORT_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_CATALOG_COMBO_CATEGORY_SORT_FAIL:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_CATALOG_PRODUCT_SORT_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.MENU_V2_CATALOG_PRODUCT_SORT_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_CATALOG_PRODUCT_SORT_FAIL:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_CATALOG_GROUP_MODIFIER_SORT_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.MENU_V2_CATALOG_GROUP_MODIFIER_SORT_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_CATALOG_GROUP_MODIFIER_SORT_FAIL:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_CATALOG_MODIFIER_SORT_START:
      return updateObject(state, { loading: true });
    case MenuV2ActionTypes.MENU_V2_CATALOG_MODIFIER_SORT_SUCCESS:
      return updateObject(state, { loading: false });
    case MenuV2ActionTypes.MENU_V2_CATALOG_MODIFIER_SORT_FAIL:
      return updateObject(state, { loading: false });

    default:
      return state;
  }
};

export default MenV2Reducer;
