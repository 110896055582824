import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListHeader from "app/main/common/List/ListHeader";
import {
  getPromotionList,
  setPromotionSearchText,
  deletePromotion,
} from "app/store/promotion/PromotionsAction";
import history from "@history";
import useSyncedPage from "app/hooks/useSyncedPage";

function PromotionList() {
  const dispatch = useDispatch();
  const {
    searchText,
    promotionList = [],
    loading,
  } = useSelector(({ banzzu }) => banzzu.promotion);
  const { languageStrings } = useLanguageHelper();
  const { currentPage, setCurrentPage } = useSyncedPage();
  useEffect(() => {
    dispatch(getPromotionList());
  }, []);
  const setSearchText = (ev) => {
    dispatch(setPromotionSearchText(ev));
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const headerRows = {
    data: [
      {
        id: "title",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.TITLE"],
        sort: true,
        type: "text",
      },
      {
        id: "promotionType",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.TYPE"],
        sort: true,
        type: "promotionType",
      },
      {
        id: "visibility",
        align: "left",
        disablePadding: false,
        label: languageStrings["VISIBILITY"],
        sort: true,
        type: "visibilityBanner",
      },
    ],
    checkbox: true,
    clickable: true,
    locationSearch: false,
    searchBy: ["title"],
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <ListHeader
                title={languageStrings["SIDEBAR_MENUE.PROMOTIONS"]}
                setSearchText={setSearchText}
                createUrl={{
                  pathname: "/catalogue/promotionDetail/new",
                  state: { page: currentPage },
                }}
                searchType={"promotion"}
                createButton
                buttonText={languageStrings["SIDEBAR_MENUE.CREATE_PROMOTION"]}
              />
            }
            content={
              <ListTable
                handleClick={(promotion) => {
                  history.push({
                    pathname: `/catalogue/promotionDetail/${promotion._id}`,
                    state: { page: currentPage },
                  });
                }}
                handleDelete={(ids) => {
                  dispatch(
                    deletePromotion(ids, () => {
                      history.replace({
                        pathname: history.location.pathname,
                        state: { page: 0 },
                      });
                    })
                  );
                }}
                headerRows={headerRows}
                data={promotionList}
                searchText={searchText}
                onPageChange={handlePageChange}
                page={currentPage}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
    </>
  );
}

export default PromotionList;
