import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Icon,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormGroup,
} from "@material-ui/core";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";
import {
  formatImageUrl,
  getSortedAllergenes,
  setDefaultLanguage,
  setDefaultLanguageV2,
} from "app/helpers/utilsHelper";
import LanguageDropdown from "./components/LanguageDropdownV2";
import { handleMenuTitle } from "app/helpers/utilsHelper";
import { ProductDropDown } from "app/main/common/ProductDropDown";
import {
  createMenuV2Item,
  editMenuV2Item,
  getMenuItemDetailV2,
} from "app/store/menuV2/MenuV2Actions";
import InputDropDown from "app/main/pos/components/InputDrpDown";
import { FuseUtils } from "@fuse";
import _ from "@lodash";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));
//here
const MenuItemDetailModal = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemCategory
  );
  const { products } = useSelector(({ banzzu }) => banzzu.pos);
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { locale } = user;
  const _products =
    products && products.products && products.products.length
      ? products.products
      : [];
  const isPOSIntegration = products && products.isPOSIntegration ? true : false;

  let menu = useSelector(({ banzzu }) => banzzu.menuV2.menuV2ItemDetail);
  const allergenesList = useSelector(
    ({ banzzu }) => banzzu.menu.menuItemAllergenes
  );
  let { venues, selectedLanguages } = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2Detail
  );

  const availableLanguages = selectedLanguages || ["Spanish"];

  let { venueList } = useSelector(({ banzzu }) => banzzu.venue);
  const client = useSelector(({ banzzu }) => banzzu.auth.user);

  const { languageStrings } = useLanguageHelper();
  const { productId, categoryId, menuId, onClose, currentVenues, isCombo } =
    props;

  const showPrepareMinutes = () => {
    let currentVenueList = [];
    if (isCombo) return currentVenueList;
    if (currentVenues && currentVenues.length !== 0) {
      currentVenueList = currentVenues;
    } else {
      currentVenueList = venues;
    }
    let checkAutoService = false;
    if (currentVenueList && currentVenueList.length) {
      venueList.filter((item) => {
        const checkVenues = currentVenueList.includes(item._id);
        if (checkVenues) {
          if (!checkAutoService) checkAutoService = item.autoServices;
        }
      });
    }

    return checkAutoService;
  };
  useEffect(() => {
    if (productId !== "new") {
      dispatch(getMenuItemDetailV2(productId, menuId));
    }
  }, [dispatch, productId, menuId]);

  if (productId === "new") {
    menu = {
      id: "0",
      image: "",
      title: "",
      description: "",
      idMenu: menuId,
      categoryId: categoryId,
      preparingMinutes: 0,
      foodType: "",
      languages: {},
      productDetail: null,
      productId: "",
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };
  const [dirty, setDirty] = useState(dirtyInitial);
  const defaultLanguage = setDefaultLanguageV2(locale);
  const getInitialSelectedLanguage = () => defaultLanguage.code;
  const [selectedLanguage, setSelectedLanguage] = useState(
    getInitialSelectedLanguage
  );
  const isDefaultLanguage = setDefaultLanguage(selectedLanguage, locale);

  const { form, handleChange, setForm } = useForm(null);

  if (form != null && form.categoryId === "0" && categoryList[0] != null) {
    setForm((form) => ({
      ...form,
      categoryId: categoryList[0]._id,
    }));
  }
  const setPreparingMinuts = (val) => {
    setForm({ ...form, preparingMinutes: Number(val.target.value) });
  };

  useEffect(() => {
    if ((menu && !form) || (menu && form && menu._id !== form._id)) {
      setForm({
        ...menu,
        isDefaultLanguage,
        languages: {},
      });
    }
  }, [form, menu, setForm]);

  useEffect(() => {
    setForm((form) => ({
      ...form,
      isDefaultLanguage,
    }));
  }, [selectedLanguage]);

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "" && form.productId !== "";
  }

  function hasNoErrors() {
    return !dirty.title && !dirty.description && !dirty.price;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  const handleChangeProduct = (_product, field) => {
    if (
      _product &&
      _product.price >= 0 &&
      Number(_product.price) >= 0 &&
      _product._id
    ) {
      const title = _product.title;
      const description =
        _product && _product.description ? _product.description : "";
      setForm((prevForm) => ({
        ...prevForm,
        title,
        productId: _product._id,
        languages: {
          ...prevForm.languages,
          [selectedLanguage]: {
            ...prevForm.languages[selectedLanguage],
            title,
            description,
          },
        },
        productDetail: _product,
      }));
    }
  };
  const handleFoodTypeChange = (event) => {
    const _foodType = event.target.value;
    setForm((form) => ({
      ...form,
      foodType: _foodType,
    }));
  };
  if (!form) {
    return <div />;
  }

  const handleCocacolaChange = (event) => {
    const cocacolaData = event.target.value;
    const name = event.target.name;
    if (form.cocacola && form.id !== "0") {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: cocacolaData,
        cocacola: { ...prevForm.cocacola, [name]: cocacolaData },
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: cocacolaData,
      }));
    }
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setForm((prevForm) => ({
      ...prevForm,
      language,
    }));
  };

  const handleNameChange = (language, field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      languages: {
        ...prevForm.languages,
        [language]: {
          ...prevForm.languages[language],
          [field]: value,
        },
      },
    }));
    form[field] = value;
  };
  const handleProductTitle = (product, language) => {
    const posName = "";
    const languageTitle = handleMenuTitle(product, language, locale, posName);
    setForm((prevForm) => ({
      ...prevForm,
      title: languageTitle.title,
      description: languageTitle.description,
    }));
  };

  const onProductSelect = (event) => {
    let _price = 0;
    let title = "";
    let posReference = "";
    if (_products && _products.length) {
      const foundObj = _products.find(
        (item) => item.posReference === event.target.value
      );
      if (
        foundObj &&
        foundObj.price >= 0 &&
        Number(foundObj.price) >= 0 &&
        foundObj.posReference
      ) {
        _price = Number(Number(foundObj.price).toFixed(2));
        title = foundObj.title;
        posReference = foundObj.posReference;
      }
      setForm((prevForm) => ({
        ...prevForm,
        title,
        productId: posReference,
        posReference,
        languages: {
          ...prevForm.languages,
          [selectedLanguage]: {
            ...prevForm.languages[selectedLanguage],
            title,
          },
        },
        productDetail: foundObj,
      }));
    }
  };
  function handleUploadChange(e) {
    const initialFile = e.target.files[0];

    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          posImage: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }
  const deleteMenuItemImage = () => {
    setForm((form) => ({
      ...form,
      image: null,
      posImage: null,
      imageFile: null,
      deleteImage: true,
    }));
  };
  const dropDownValue =
    form && form.productDetail
      ? form.productDetail
      : form.productId
      ? form.productId
      : null;
  const posImage = form && form.posImage ? form.posImage : "";
  const productImage = isPOSIntegration ? posImage : form.image;

  const onTarifChange = (value) => {
    setForm((prevForm) => ({
      ...prevForm,
      tarifPrice: value,
    }));
  };
  const handleAllergenesChange = (event) => {
    let allergenesFormatted;
    if (event.target.checked) {
      const allergenes = `${form.allergenes},${event.target.value},`;
      allergenesFormatted = _.replace(allergenes, ",,", ",");
    } else {
      const allergenes = _.replace(
        form.allergenes,
        `${event.target.value},`,
        ""
      );
      allergenesFormatted = _.replace(allergenes, ",,", ",");

      if (!_.startsWith(allergenesFormatted, ",")) {
        allergenesFormatted = "," + allergenesFormatted;
      }
      if (!_.endsWith(allergenesFormatted, ",")) {
        allergenesFormatted = allergenesFormatted + ",";
      }
    }

    setForm((form) => ({
      ...form,
      allergenes: allergenesFormatted,
    }));
  };
  const Allergenes = getSortedAllergenes({ allergenesList, languageStrings });

  return (
    <div className="p-16 sm:p-24 max-w-xl max-h-screen">
      <div className="flex flex-row justify-center sm:justify-start flex-wrap">
        {isPOSIntegration ? (
          <>
            <input
              accept="image/jpg, image/jpeg, image/png, image/gif"
              className="hidden"
              id={`button-file-${productId}`}
              type="file"
              onChange={handleUploadChange}
            />
            <label
              htmlFor={`button-file-${productId}`}
              className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
            >
              <Icon fontSize="large" color="action">
                cloud_upload
              </Icon>
            </label>
            {
              <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                <img
                  className="max-w-none w-auto h-full"
                  alt="whatson"
                  src={
                    productImage
                      ? formatImageUrl(productImage)
                      : "assets/images/avatars/noimage.png"
                  }
                />
                {productImage && (
                  <Icon
                    onClick={deleteMenuItemImage}
                    className="rounded-4 shadow-5 mb-2 text-2xl cursor-pointer absolute bottom-0"
                    style={{
                      color: "#f4516c",
                      backgroundColor: "#fff",
                    }}
                  >
                    delete
                  </Icon>
                )}
              </div>
            }
          </>
        ) : null}
        <LanguageDropdown
          languages={availableLanguages}
          onChange={handleLanguageChange}
          selectedLanguage={selectedLanguage}
          menuElement={menu ? menu : {}}
          handleLanguageTitle={handleProductTitle}
        />
        {isCombo ? null : (
          <>
            {isPOSIntegration ? (
              <InputDropDown
                label={languageStrings["POS_PRODUCTS"]}
                handleChange={onProductSelect}
                clientsList={_products}
                selectedValue={
                  form && form.posReference ? form.posReference : ""
                }
                keyPropFn={(option) => option.posReference}
                valuePropFn={(option) => option.posReferenceTitle}
              />
            ) : (
              <ProductDropDown
                selectedValue={dropDownValue}
                handleChangeValue={(item) => handleChangeProduct(item)}
              />
            )}
          </>
        )}
      </div>
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mr-8"
          required
          label={languageStrings["GENERAL.TITLE"]}
          id="title"
          name="title"
          value={
            form.languages &&
            form.languages[selectedLanguage] &&
            form.languages[selectedLanguage].title !== undefined
              ? form.languages[selectedLanguage].title
              : form.title
              ? form.title
              : ""
          }
          onValueChange={(e) =>
            handleNameChange(selectedLanguage, "title", e.target.value)
          }
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          hasError={(error) =>
            setDirty((prevState) => {
              return { ...prevState, title: error };
            })
          }
          minLength={3}
        />
        {!isCombo && showPrepareMinutes() && (
          <TextField
            className="mt-8 ml-8 mb-16"
            label={languageStrings["PREPARE_MINUTES"]}
            required
            id="prepareMinuts"
            name="preparingMinutes"
            type={"number"}
            value={form.preparingMinutes || ""}
            onChange={setPreparingMinuts}
            variant="outlined"
            disabled={!isDefaultLanguage}
            fullWidth
            //error
          />
        )}
      </div>
      {client.isCocacola && form && (
        <div className="flex">
          <TextField
            className="mt-8 mb-16 mr-8"
            label={"cocacolaID"}
            required
            id="cocacolaID"
            name="cocacolaID"
            type={"string"}
            value={
              form.cocacola ? form.cocacola.cocacolaID : form.cocacolaID || ""
            }
            onChange={handleCocacolaChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            className="mt-8 ml-8 mb-16"
            label={"cocacolaDescription"}
            required
            id="cocacolaDescription"
            name="cocacolaDescription"
            type={"string"}
            value={
              form.cocacola
                ? form.cocacola.cocacolaDescription
                : form.cocacolaDescription || ""
            }
            onChange={handleCocacolaChange}
            variant="outlined"
            fullWidth
          />
        </div>
      )}

      <CustomInput
        className="mt-8 mb-16 mr-8"
        label={languageStrings["GENERAL.DESCRIPTION"]}
        id="description"
        name="description"
        maxLength={1000}
        value={
          form.languages &&
          form.languages[selectedLanguage] &&
          form.languages[selectedLanguage].description !== undefined
            ? form.languages[selectedLanguage].description
            : form.description
            ? form.description
            : ""
        }
        onValueChange={(e) =>
          handleNameChange(selectedLanguage, "description", e.target.value)
        }
        errorMessage={languageStrings["ERRORS.MINIMUM_10_CHAR"]}
        hasError={(error) => error}
      />
      {isCombo && !isPOSIntegration ? (
        <CustomInput
          type={"number"}
          className="mt-8 mb-16 mr-8"
          label={languageStrings["COMBO_SUPPLEMENT_PRICE"]}
          id="comboSupplementPrice"
          name="comboSupplementPrice"
          value={
            form && form.comboSupplementPrice ? form.comboSupplementPrice : ""
          }
          onValueChange={handleChange}
        />
      ) : null}
      {/* //here */}
      {/* tarif */}
      {client.revo &&
        client.settings &&
        client.settings.revoExtraTraifEnabled && (
          <CustomInput
            className="mt-8 mb-16 mr-8"
            label={"tarifPrice"}
            id="tarifPrice"
            name="tarif Price"
            maxLength={10}
            value={form.tarifPrice || ""}
            onValueChange={(e) => {
              onTarifChange(e.target.value);
            }}
            errorMessage={languageStrings["ERRORS.MINIMUM_10_CHAR"]}
            hasError={(error) => error}
            variant={"outlined"}
          />
        )}
      {isCombo ? null : (
        <div className="flex flex-row">
          <div className="ml-auto mt-4">
            <FormControlLabel
              control={
                <Checkbox
                  name="isPopular"
                  onChange={handleChange}
                  checked={form.isPopular ? true : false}
                />
              }
              label={languageStrings["MENU_PRODUCT_FAVORITE"]}
            />
          </div>
        </div>
      )}
      {isPOSIntegration ? (
        <div className="flex flex-row">
          <div>
            <label className="mr-10 font-bold">
              {languageStrings["PRINT_TICKET"]}:
            </label>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              className="flex flex-row"
              value={form && form.foodType ? form.foodType : ""}
              onChange={handleFoodTypeChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={languageStrings["DRINKS"]}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={languageStrings["FOOD"]}
              />
            </RadioGroup>
          </div>
        </div>
      ) : null}
      {isPOSIntegration ? (
        <div>
          <div className="mt-8 mb-8 font-bold">
            {languageStrings["ALLERGENE.TITLE"]}
          </div>
          <FormGroup row>
            {Allergenes.map((item) => (
              <FormControlLabel
                key={item.title}
                control={
                  <Checkbox
                    checked={
                      form.allergenes
                        ? form.allergenes.includes(`,${item._id},`)
                        : false
                    }
                    onChange={handleAllergenesChange}
                    value={item._id}
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </div>
      ) : null}
      <Button
        className="whitespace-no-wrap mt-16"
        variant="contained"
        disabled={!canBeSubmitted()}
        onClick={() => {
          form.id !== "0"
            ? dispatch(editMenuV2Item(form, isPOSIntegration))
            : dispatch(createMenuV2Item(form, isPOSIntegration));
          onClose();
        }}
      >
        <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>save</Icon>
        {languageStrings["GENERAL.SAVE"]}
      </Button>
    </div>
  );
};

export default withReducer("MenuReducer", reducer)(MenuItemDetailModal);
