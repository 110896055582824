import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse";
import { MainConfig } from "app/MainConfig";
import { LoginPageConfig } from "app/main/login/LoginPageConfig";
import { RegisterPageConfig } from "app/main/register/RegisterPageConfig";
import { VenueListConfig } from "app/main/venue/VenueListConfig";
import { UserListConfig } from "app/main/user/UserListConfig";
import { StaffListConfig } from "app/main/staff/StaffListConfig";
import { OfferListConfig } from "app/main/offer/OfferListConfig";
import { WhatsOnListConfig } from "app/main/whatson/WhatsOnListConfig";
import { GalleryConfig } from "app/main/gallery/GalleryConfig";
import { LoyaltyConfig } from "app/main/loyalty/LoyaltyConfig";
import { ReservationConfig } from "app/main/reservation/ReservationConfig";
import { NoficationListConfig } from "app/main/notification/NoficationListConfig";
import { DrawListConfig } from "app/main/darws/DrawsListConfig";
import { VoucherListConfig } from "app/main/voucher/VoucherListConfig";
import { OrderConfig } from "app/main/orders/OrderConfig";
import { ScheduleOrderConfig } from "app/main/scheduleOrders/ScheduleOrderConfig";
import { OrderFromTableConfig } from "app/main/order-from-table/OrderFromTableConfig";
import { BrandingConfig } from "app/main/branding/BrandingConfig";
import { PricingConfig } from "app/main/pricing/PricingConfig";
import { DirectLoginConfig } from "app/main/directLogin/DirectLoginConfig";
import { PaymentConfig } from "app/main/payment/PaymentConfig";
import { AnalyticsConfig } from "app/main/analytics/AnalyticsConfig";
import { ContactSupportConfig } from "app/main/contactSupport/ContactSupportConfig";
import { ForgotPasswordPageConfig } from "app/main/forgotPassword/ForgotPasswordPageConfig";
import { MerchandisingConfig } from "app/main/merchandising/MerchandisingConfig";
import { AccountConfig } from "app/main/account/AccountConfig";
import { clientsSettings } from "config";
import { VirtualCartOrderConfig } from "app/main/PaymentOrdeFromtable/VirtualCartOrderConfig";
import { TipConfig } from "app/main/tip/TipListConfig";
import { ProductsPOSConfig } from "app/main/pos/ProductPOSListConfig";
import { PaymentHistoryConfig } from "app/main/PaymentHistory/PaymentHistoryConfig";
import { RatingConfig } from "app/main/ratings/RatingConfig";
import { LoyaltyProgramConfig } from "app/main/loyaltyProgram/LoyaltyProgramConfig";
import { ProductConfig } from "app/main/catalogue/products/ProductConfig";
import { ModifierConfig } from "app/main/catalogue/modifiers/ModifierConfig";
import { GroupModifierConfig } from "app/main/catalogue/groupModifiers/GroupModifierConfig";
import { MenuV2Config } from "app/main/menuV2/MenuV2Config";
import { PromotionListConfig } from "app/main/promotion/PromotionListConfig";
import { AutoServiceConfig } from "app/main/autoService/AutoServiceConfig";
import { ComboConfig } from "app/main/combos/ComboConfig";
import { PromotionsPOSConfig } from "app/main/posPromotions/PromotionsPOSListConfig";
import { KioskSettingsConfig } from "app/main/kiosk-settings/KioskSettingsConfig";

const routeConfigs = [
  LoginPageConfig,
  RegisterPageConfig,
  MainConfig,
  VenueListConfig,
  OfferListConfig,
  WhatsOnListConfig,
  GalleryConfig,
  LoyaltyConfig,
  ReservationConfig,
  PricingConfig,
  NoficationListConfig,
  DrawListConfig,
  VoucherListConfig,
  OrderConfig,
  OrderFromTableConfig,
  DirectLoginConfig,
  BrandingConfig,
  ContactSupportConfig,
  StaffListConfig,
  ForgotPasswordPageConfig,
  MerchandisingConfig,
  AccountConfig,
  AnalyticsConfig,
  PaymentConfig,
  VirtualCartOrderConfig,
  TipConfig,
  ProductConfig,
  ModifierConfig,
  ProductsPOSConfig,
  PaymentHistoryConfig,
  RatingConfig,
  LoyaltyProgramConfig,
  GroupModifierConfig,
  MenuV2Config,
  PromotionListConfig,
  AutoServiceConfig,
  ComboConfig,
  PromotionsPOSConfig,
  ScheduleOrderConfig,
  KioskSettingsConfig,
];

// if (clientsSettings.showPaymentMethod) routeConfigs.push(PaymentConfig);
if (clientsSettings.showUserSection) routeConfigs.push(UserListConfig);
const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    component: () => <Redirect to="/login" />,
  },
];
export default routes;
