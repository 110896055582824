import React, { Component } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import history from "@history";
import { connect } from "react-redux";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";

import {
  getVirtualCardOrderList,
  setVirtualCardOrderSearchText,
} from "app/store/virtualCardOrder/VirtualCardOrderActions";
import { translate } from "app/helpers/LanguageHelper/index";
import { checkOrderStatuses, orderStatuses } from "./components/OrdersStatus";
import { orderTypes } from "./components/OrdersType";
import UpgradeModal from "../common/UpgradeModal";
import moment from "moment";

import { clientsSettings } from "config";
import { Pagination } from "app/models/Pagination";

class VirtualCartOrderList extends Component {
  state = {
    orderList: [],
    orderStatus: "0",
    orderType: "0",
    orderTypesValues: [],
    open: false,
    showModal: false,
    userDetail: this.props.user,
  };

  static getDerivedStateFromProps(props, state) {
    const { userPermissions, languageStrings } = props;
    if (userPermissions) {
      let orderTypesValues = orderTypes.filter((type) => {
        if (userPermissions.hasCollection && type.id === 1) {
          return type;
        }

        if (userPermissions.hasDelivery && type.id === 2) {
          return type;
        }

        if (userPermissions.hasOrderFromTable && type.id === 3) {
          return type;
        }

        if (userPermissions.hasFutureOrder && type.id === 4) {
          return type;
        }
      });

      orderTypesValues = orderTypesValues.map((orderType) => ({
        ...orderType,
        name: languageStrings[orderType.name],
      }));
      return {
        ...state,
        orderTypesValues,
      };
    }
    return null;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.orderList.length !== this.props.orderList.length) {
      this.setState({
        orderList: this.props.orderList.map((order) => ({
          ...order,
          totalPrice: order.price + order.deliveryPrice,
        })),
      });
    }
    if (prevProps.user !== this.props.user) {
      let venues = [];
      if (
        this.props.user &&
        this.props.user.permission &&
        !this.props.user.permission.isMaster &&
        this.props.user.permission &&
        this.props.user.permission.venues &&
        this.props.user.permission.venues.length
      ) {
        venues = this.props.user.permission.venues;
      }

      this.props.getVirtualCardOrderList(venues || []);
    }
    const { user } = this.props;

    if (this.state.open === false && user.accessLevel == "1") {
      this.setState({
        open: true,
      });
    }
  }

  componentDidMount() {
    let venues = [];
    if (
      this.props.user &&
      this.props.user.permission &&
      !this.props.user.permission.isMaster &&
      this.props.user.permission &&
      this.props.user.permission.venues &&
      this.props.user.permission.venues.length
    ) {
      venues = this.props.user.permission.venues;
    }

    this.props.getVirtualCardOrderList(venues || []);
  }

  handleClick = (item) => {
    history.push("/virtualCartOrder/" + item._id);
  };

  filterByStatus = (ev) => {
    let { value } = ev.target;
    this.setState({ orderStatus: value }, () => this.filterOrderList());
  };

  filterByType = (ev) => {
    let { value } = ev.target;
    this.setState({ orderType: value }, () => this.filterOrderList());
  };

  filterOrderList = () => {
    let { orderList } = this.props;
    let { orderStatus, orderType } = this.state;
    let filteredOrderList = orderList;

    if (orderType !== "0") {
      filteredOrderList = filteredOrderList.filter(
        (order) => order.type == orderType
      );
    }

    if (orderStatus !== "0") {
      filteredOrderList = filteredOrderList.filter(
        (order) => order.orderStatus == orderStatus
      );
    }

    this.setState({
      orderList: filteredOrderList.map((order) => ({
        ...order,
        totalPrice: order.price + order.deliveryPrice,
      })),
    });
  };

  render() {
    const { languageStrings } = this.props;

    const headerRows = {
      data: [
        {
          id: "referenceNo",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.REF"],
          sort: true,
          type: "text",
        },
        {
          id: "title",
          align: "left",
          disablePadding: true,
          label: languageStrings["GENERAL.TITLE"],
          sort: true,
          type: "text",
        },

        {
          id: "totalPrice",
          align: "left",
          disablePadding: true,
          label: languageStrings["GENERAL.PRICE"],
          sort: true,
          type: "priceCell",
        },

        {
          id: "createdAt",
          align: "left",
          disablePadding: false,
          label: languageStrings["GENERAL.DATE"],
          sort: true,
          type: "time",
        },
      ],
      checkbox: false,
      clickable: true,
      locationSearch: false,
      searchBy: ["referenceNo"],
    };
    const _orderStatuses = clientsSettings.isCheckOrderStatuses
      ? checkOrderStatuses
      : orderStatuses;
    const orderStatusValues = _orderStatuses.map((status) => {
      return { ...status, name: languageStrings[status.name] };
    });

    const exportFiles = () => {
      const usersData = [];
      this.state.orderList.forEach((ord) => {
        const orderStausText = orderStatuses.filter(
          (item) => item.id === Number(ord.orderStatus)
        )[0].name;
        const orderTypeText = orderTypes.filter(
          (item) => item.id === Number(ord.type)
        )[0].name;
        const usersObj = {};
        usersObj["referenceNo"] = ord.referenceNo;
        usersObj["venueTitle"] = ord.venueTitle;
        usersObj["orderType"] = languageStrings[orderTypeText];
        usersObj["total"] = Number(ord.total).toFixed(2);
        usersObj["orderStatus"] = languageStrings[orderStausText];
        usersObj["createdDate"] = moment(ord.createdAt).format("L");
        usersObj["createdTime"] = moment(ord.createdAt).format("LT");

        usersData.push(usersObj);
      });
      return usersData;
    };

    return (
      <>
        <UpgradeModal
          open={this.state.open}
          setOpen={() => {
            history.goBack();
            this.setState({
              open: false,
            });
          }}
        />

        <LoadingOverlay
          active={this.props.loading}
          spinner
          styles={{
            wrapper: {
              width: "100%",
              height: "100%",
              overflow: this.props.loading ? "hidden" : "scroll",
            },
          }}
          text="Loading..."
        >
          <div
            style={{ flex: 1, filter: this.state.open ? "blur(3px)" : "none" }}
          >
            <FusePageCarded
              classes={{
                content: "flex",
                header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
              }}
              header={
                <div
                  className={
                    "flex w-full items-center flex-row justify-between"
                  }
                >
                  <ListHeader
                    title={languageStrings["SIDEBAR_MENUE.VirtualCartOrder"]}
                    setSearchText={this.props.setVirtualCardOrderSearchText}
                    searchType={"VirtualCartOrder"}
                  />
                </div>
              }
              content={
                <ListTable
                  handleClick={this.handleClick}
                  headerRows={headerRows}
                  data={this.state.orderList.map((order) => {
                    if (order.price) {
                      let obj = {
                        ...order,
                        price: Number(order.price).toFixed(2),
                        title:
                          order && order.tableId && order.tableId.title
                            ? order.tableId.title
                            : "",
                      };

                      return obj;
                    }

                    return order;
                  })}
                  searchText={this.props.searchText}
                  listType={Pagination.VIRTUAL_CART}
                />
              }
              innerScroll
            />
          </div>
          {/* <OrderFilter
            onCloseModal={() => {
              this.setState({ showModal: false })
            }}
            open={this.state.showModal}
            {...this.props}
          /> */}
        </LoadingOverlay>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.banzzu.VirtualCartOrder.loading,
    orderList: state.banzzu.VirtualCartOrder.orderList,
    searchText: state.banzzu.VirtualCartOrder.searchText,
    userPermissions: state.banzzu.auth.user.permission,
    user: state.banzzu.auth.user,
    venueList: state.banzzu.venue.venueList,
  };
};
const mapDispatchToProps = {
  getVirtualCardOrderList,
  setVirtualCardOrderSearchText,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(VirtualCartOrderList));
