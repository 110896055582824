import { axiosInstance as axios } from "app/api/axios";

import History from "@history";
import { getScheduleOrderDetailUrl } from "app/api/Endpoint";
import { getDashboardScheduleOrderListUrl } from "app/api/ScheduleOrderEndpoints";
import { errorAlert } from "app/helpers/alerts";
import { Pagination } from "app/models/Pagination";
import { OrderParams } from "../orders/OrdersActions";
import { setListLimitPage } from "../pagination/paginationActions";
import { ScheduleOrderActionTypes } from "../redux/actionTypes";

export function setOrderSearchText(event) {
  return {
    type: ScheduleOrderActionTypes.SET_ORDER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getOrderList = ({
  searchText = "",
  page = "",
  limit = "",
  status = "0",
  order = {},
  type = "",
  initialDate = "",
  finalDate = "",
  paidBy = 1,
}: OrderParams) => {
  return (dispatch) => {
    dispatch({
      type: ScheduleOrderActionTypes.SCHEDULE_ORDERS_LIST_START,
    });
    const listType = Pagination.ORDERS;

    setListLimitPage(dispatch, limit, page, listType);
    const url = getDashboardScheduleOrderListUrl();
    axios
      .get(
        url +
          `&searchText=${searchText}&page=${page}&limit=${limit}&status=${status}&order=${JSON.stringify(
            order
          )}&initialDate=${initialDate}&finalDate=${finalDate}&paymentType=${paidBy}`
      )
      .then((res) => {
        getOrderListSuccess(dispatch, res.data.data);
      })
      .catch((error) => {
        getOrderListFail(
          dispatch,
          error.response
            ? error.response.data.message
            : "There was an error connection"
        );
      });
  };
};

const getOrderListFail = (dispatch, errorMessage) => {
  dispatch({
    type: ScheduleOrderActionTypes.SCHEDULE_ORDERS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: ScheduleOrderActionTypes.SCHEDULE_ORDERS_LIST_SUCCESS,
    payload: data,
  });
};

export const getOrderDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: ScheduleOrderActionTypes.SCHEDULE_ORDER_DETAIL_START,
    });

    const url = getScheduleOrderDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getOrderDetailSuccess(dispatch, res.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          getOrderDetailFail(dispatch, "Unauthorized");
        } else {
          getOrderDetailFail(dispatch, "There was an error connection");
        }
      });
  };
};

const getOrderDetailFail = (dispatch, errorMessage) => {
  dispatch({
    type: ScheduleOrderActionTypes.SCHEDULE_ORDER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/schedule-orders`,
  });
};

const getOrderDetailSuccess = (dispatch, data) => {
  dispatch({
    type: ScheduleOrderActionTypes.SCHEDULE_ORDER_DETAIL_SUCCESS,
    payload: data,
  });
};