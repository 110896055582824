import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Typography,
  FormGroup,
  Switch,
  CardHeader,
  Card,
  Icon,
  makeStyles,
  Checkbox,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import CustomModal from "../../common/CustomModal";
import {
  createDiscountVoucher,
  getDiscountVoucherList,
  editDiscountVoucher,
  deleteDiscountVoucher,
} from "app/store/dicountVoucher/DiscountVoucherActions";
import FuseNavBadge from "@fuse/components/FuseNavigation/FuseNavBadge";
import { getOrderType } from "app/helpers/utilsHelper";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

export default function DiscountVoucherTab({
  createCouponVisible,
  toggleModal,
  form,
  handleChange,
  languageStrings,
  setForm,
  venueId,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [editingVoucher, setEditingVoucher] = useState(false);

  const { voucherList } = useSelector(({ banzzu }) => banzzu.discountVoucher);

  useEffect(() => {
    dispatch(getDiscountVoucherList(venueId));
  }, [venueId]);

  function errorDiscountCode() {
    return form && form.discountCode && form.discountCode.length < 3;
  }

  const discountCanBeCreated = () => {
    return (
      form &&
      form.discountCode &&
      form.discountCode.length > 3 &&
      form.discountType &&
      form.discountAmount
    );
  };

  const onSubmit = () => {
    const {
      discountCode,
      discountAmount,
      discountQuantity,
      discountType,
      expiryDate,
      discountVoucherId,
      usability,
      expireable,
      voucherOrderType,
    } = form;

    const payload = {
      discountVoucherId,
      discountCode,
      discountAmount,
      discountQuantity,
      discountType,
      expiryDate,
      usability,
      expireable,
      voucherOrderType: "3",
      venueId,
    };

    if (editingVoucher) {
      dispatch(editDiscountVoucher(payload, toggleModal));
    } else {
      dispatch(createDiscountVoucher(payload, toggleModal));
    }
  };

  const onClose = () => {
    setEditingVoucher(false);
    toggleModal();
  };

  const editVoucher = (id) => {
    const _data = voucherList.find((voucher) => voucher.id === id);
    const {
      discountCode,
      discountAmount,
      discountQuantity,
      discountType,
      expiryDate,
      usability,
      expireable,
    } = _data;

    setEditingVoucher(true);
    let voucherOrderType = "3";
    if (_data && _data.voucherOrderType) {
      voucherOrderType = _data.voucherOrderType;
    }
    setForm((prevForm) => ({
      ...prevForm,
      discountVoucherId: id,
      discountCode,
      discountAmount,
      discountQuantity,
      discountType,
      expiryDate,
      usability,
      expireable,
      voucherOrderType: "3",
      venueId,
    }));
    toggleModal();
  };

  const createVoucher = () => {
    setForm((prevForm) => ({
      ...prevForm,
      discountVoucherId: "",
      discountCode: "",
      discountAmount: "",
      discountQuantity: "",
      discountType: "percentage",
      usability: "onetime",
      expiryDate: new Date(),
      voucherOrderType: "3",
      venueId,
    }));
    toggleModal();
  };

  const deleteVoucher = () => {
    dispatch(
      deleteDiscountVoucher(form.discountVoucherId, venueId, toggleModal)
    );
    setEditingVoucher(false);
  };

  const onDateChange = (date) => {
    setForm((prevForm) => ({
      ...prevForm,
      expiryDate: date,
    }));
  };

  return (
    <div>
      <div className={"flex flex-row justify-between items-center mt-5"}>
        <Button
          className="whitespace-no-wrap"
          variant="contained"
          disabled={!form.hasDiscountVoucher}
          onClick={createVoucher}
        >
          <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
            create
          </Icon>
          {languageStrings["PAYMENT_SETTINGS_PAGE.CREATE_DISCOUNT_VOUCHER"]}
        </Button>
      </div>
      <div className={"flex md:flex-row flex-col flex-wrap mt-36"}>
        {form.hasDiscountVoucher &&
          voucherList.map((_item) => {
            const {
              _id,
              status,
              discountCode,
              discountType,
              discountAmount,
              discountQuantity,
            } = _item;
            return (
              <Card
                className={
                  "flex flex-col justify-center p-8 mt-5 cursor-pointer md:m-6"
                }
                style={{ minWidth: "25%" }}
                onClick={() => {
                  if (status == 1) editVoucher(_id);
                }}
              >
                {status == 2 && (
                  <div
                    style={{
                      display: "inline",
                      padding: " 0.4rem",
                      backgroundColor: "red",
                      color: "white",
                      width: 50,
                      marginBottom: 20,
                    }}
                    className="text-12 rounded truncate"
                  >
                    {languageStrings["GENERAL.USED"]}
                  </div>
                )}
                <div className="flex flex-row">
                  <Typography className="text-16 truncate">
                    {languageStrings["GENERAL.CODE"]}
                  </Typography>
                  <Typography className="text-16 truncate ml-12">
                    {discountCode}
                  </Typography>
                </div>

                <div className="flex flex-row mt-6">
                  <Typography className="text-16 truncate">
                    {languageStrings["GENERAL.TYPE"]}
                  </Typography>
                  <Typography className="text-16 truncate ml-12 capitalize">
                    {discountType == "percentage"
                      ? languageStrings["GENERAL.PERCENTAGE"]
                      : languageStrings["GENERAL.QUANTITY"]}
                  </Typography>
                </div>

                <div className="flex flex-row mt-6">
                  <Typography className="text-16 truncate">
                    {languageStrings["GENERAL.AMOUNT"]}
                  </Typography>
                  <Typography className="text-16 truncate ml-12 capitalize">
                    {discountAmount}
                  </Typography>
                </div>

                {discountQuantity && (
                  <div className="flex flex-row mt-6">
                    <Typography className="text-16 truncate">
                      {languageStrings["GENERAL.MINIMUM_AMOUNT"]}
                    </Typography>
                    <Typography className="text-16 truncate ml-12 capitalize">
                      {discountQuantity}
                    </Typography>
                  </div>
                )}
              </Card>
            );
          })}
      </div>
      <CustomModal showModal={createCouponVisible} onCloseModal={onClose}>
        <Card className={"flex flex-col bg-white p-16 rounded"}>
          <CardHeader
            title={
              editingVoucher
                ? languageStrings["PAYMENT_SETTINGS_PAGE.EDIT_DISCOUNT_VOUCHER"]
                : languageStrings[
                    "PAYMENT_SETTINGS_PAGE.CREATE_DISCOUNT_VOUCHER"
                  ]
            }
          />
          <TextField
            required
            label={languageStrings["GENERAL.CODE"]}
            id="discountCode"
            name="discountCode"
            variant="outlined"
            value={form.discountCode}
            error={errorDiscountCode()}
            className="mt-12"
            fullWidth
            inputProps={{
              maxLength: 200,
            }}
            onChange={handleChange}
            helperText={
              errorDiscountCode()
                ? languageStrings["ERRORS.FIELD_IS_REQUIRED"]
                : ""
            }
          />
          <div className="mt-12">
            <Typography>{languageStrings["GENERAL.TYPE"]}</Typography>
            <RadioGroup
              className="flex-row"
              aria-label="discountType"
              name="discountType"
              value={form.discountType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="percentage"
                control={<Radio />}
                label={languageStrings["GENERAL.PERCENTAGE"]}
              />
              <FormControlLabel
                value="quantity"
                control={<Radio />}
                label={languageStrings["GENERAL.QUANTITY"]}
              />
            </RadioGroup>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                name="expireable"
                value={form.expireable}
                checked={form.expireable}
                onChange={handleChange}
              />
            }
            label={languageStrings["GENERAL.EXPIREABLE"]}
          />
          {form.expireable && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className="mt-16"
                margin="normal"
                fullWidth
                id="expiryDate"
                name="expiryDate"
                label={languageStrings["GENERAL.EXPIRY_DATE"]}
                value={form.expiryDate}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                onChange={onDateChange}
              />
            </MuiPickersUtilsProvider>
          )}

          <div className="mt-16">
            <Typography>{languageStrings["GENERAL.USABILITY"]}</Typography>
            <RadioGroup
              className="flex-row"
              aria-label="usability"
              name="usability"
              value={form.usability}
              onChange={handleChange}
            >
              <FormControlLabel
                value="onetime"
                control={<Radio />}
                label={languageStrings["GENERAL.SINGLE_USE"]}
              />
              <FormControlLabel
                value="multipletimes"
                control={<Radio />}
                label={languageStrings["GENERAL.MULTIPLE_USE"]}
              />
            </RadioGroup>
          </div>

          <TextField
            required
            label={languageStrings["GENERAL.AMOUNT"]}
            id="discountAmount"
            name="discountAmount"
            variant="outlined"
            className="mt-12"
            value={form.discountAmount}
            fullWidth
            type={"number"}
            onChange={handleChange}
          />

          <TextField
            label={languageStrings["GENERAL.MINIMUM_QUANTITY"]}
            id="discountQuantity"
            name="discountQuantity"
            variant="outlined"
            className="mt-12"
            value={form.discountQuantity}
            fullWidth
            type={"number"}
            inputProps={{
              maxLength: 200,
            }}
            onChange={handleChange}
          />
          <Button
            className="whitespace-no-wrap mt-12"
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!discountCanBeCreated()}
          >
            {languageStrings["GENERAL.SAVE"]}
          </Button>
          {editingVoucher && (
            <Button
              className="whitespace-no-wrap mt-12 bg-red text-white"
              variant="contained"
              onClick={deleteVoucher}
            >
              {languageStrings["GENERAL.DELETE"]}
            </Button>
          )}
        </Card>
      </CustomModal>
    </div>
  );
}
