import PaymentOnline from "./PaymentOnline";
import PaymentMethods from "./PaymentMethods";
import { clientsSettings } from "config";

const _routes = [
  {
    path: "/payment-methods",
    component: PaymentMethods,
  },
];
if (clientsSettings.showOnlineOrderMenu)
  _routes.push({
    path: "/payment-settings/:tab?",
    component: PaymentOnline,
  });
export const PaymentConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: _routes,
};
