import { axiosInstance as axios } from "app/api/axios";
import { ComboActionTypes } from "../redux/actionTypes";
import {
  getComboDetailUrl,
  COMBOS_URL,
  CATALOG_COMBO_AVAILABILITY,
  SORT_COMBO_CATEGORY,
  SORT_COMBO_CATEGORY_PRODUCTS,
} from "app/api/Endpoint";
import History from "@history";
import { successAlert, errorAlert } from "app/helpers/alerts";
import { Combo } from "app/models/Combo";
import _ from "@lodash";
import { getMenuItemV2List } from "app/store/menuV2/MenuV2Actions";
import { ComboCategory } from "app/models/ComboCategory";

export function setComboSearchText(event) {
  return {
    type: ComboActionTypes.SET_COMBO_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getComboList = () => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.COMBOS_LIST_START,
    });

    axios
      .get(COMBOS_URL)
      .then((res) => {
        getComboListSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getComboListFail(dispatch, "There was an error connection");
      });
  };
};

const getComboListFail = (dispatch, errorMessage) => {
  dispatch({
    type: ComboActionTypes.COMBOS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getComboListSuccess = (dispatch, data) => {
  dispatch({
    type: ComboActionTypes.COMBOS_LIST_SUCCESS,
    payload: data,
  });
};

export const getComboDetail = (id: string, page: number) => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.COMBO_DETAIL_START,
    });

    const url = getComboDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        getComboDetailSuccess(dispatch, res.data.data);
      })
      .catch(() => {
        getComboDetailFail(dispatch, "There was an error connection", page);
      });
  };
};

const getComboDetailFail = (dispatch, errorMessage, page) => {
  dispatch({
    type: ComboActionTypes.COMBO_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  History.push({
    pathname: `/catalogue/combos`,
    state: { page },
  });
};

const getComboDetailSuccess = (dispatch, data) => {
  dispatch({
    type: ComboActionTypes.COMBO_DETAIL_SUCCESS,
    payload: data,
  });
};

export const createCombo = (combo: Combo, page: number) => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.COMBO_CREATE_START,
    });
    const formData = new FormData();
    const { image, imageFile, ...comboData } = combo;
    if (combo.imageFile) {
      formData.append("imageFile", combo.imageFile, combo.imageFile.name);
    }
    formData.append("comboData", JSON.stringify(comboData));
    axios
      .post(COMBOS_URL, formData)
      .then((res) => {
        const data = res.data;
        data.page = page;
        createComboSuccess(dispatch, data);
      })
      .catch(() => {
        createComboFail(dispatch, "There was an error connection");
      });
  };
};

const createComboFail = (dispatch, errorMessage) => {
  errorAlert(dispatch, errorMessage);
  dispatch({
    type: ComboActionTypes.COMBO_CREATE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const createComboSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: ComboActionTypes.COMBO_CREATE_SUCCESS,
    payload: data,
  });
  History.push({
    pathname: "/catalogue/combos",
    state: { page: data.page },
  });
};

export const editCombo = (combo: Combo, page: number) => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.CHANGE_COMBO_STATUS_START,
    });
    const formData = new FormData();
    const { imageFile, ...comboData } = combo;
    if (combo.imageFile) {
      formData.append("imageFile", combo.imageFile, combo.imageFile.name);
    }
    formData.append("comboData", JSON.stringify(comboData));
    axios
      .put(COMBOS_URL, formData)
      .then((res) => {
        const data = res.data;
        data.page = page;
        editComboSuccess(dispatch, data);
      })
      .catch(() => {
        editComboFail(dispatch, "There was an error connection");
      });
  };
};

const editComboFail = (dispatch, errorMessage) => {
  dispatch({
    type: ComboActionTypes.CHANGE_COMBO_STATUS_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const editComboSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch({
    type: ComboActionTypes.CHANGE_COMBO_STATUS_SUCCESS,
    payload: data,
  });

  History.push({
    pathname: "/catalogue/combos",
    state: { page: data.page },
  });
};

export const deleteCombo = (idArray: String[], callback: () => void) => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.COMBO_DELETE_START,
    });

    axios
      .delete(COMBOS_URL, { data: { comboIds: idArray } })
      .then((res) => {
        callback();
        deleteComboSuccess(dispatch, res.data);
      })
      .catch(() => {
        deleteComboFail(dispatch, "There was an error connection");
      });
  };
};

const deleteComboFail = (dispatch, errorMessage) => {
  dispatch({
    type: ComboActionTypes.COMBO_DELETE_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const deleteComboSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getComboList());
  dispatch({
    type: ComboActionTypes.COMBO_DELETE_SUCCESS,
    payload: data,
  });
};
const updateComboAvailabilityFail = (dispatch) => {
  dispatch({
    type: ComboActionTypes.CHANGE_COMBO_AVAILABILITY_FAIL,
  });
};

const updateComboAvailabilitySuccess = (dispatch, menuId) => {
  dispatch(getMenuItemV2List(menuId, false));
  dispatch({
    type: ComboActionTypes.CHANGE_COMBO_AVAILABILITY_SUCCESS,
  });
};
export const updateComboAvailability = (combo: Combo, menuId: string) => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.CHANGE_COMBO_AVAILABILITY_START,
    });

    axios
      .put(CATALOG_COMBO_AVAILABILITY, combo)
      .then((res) => {
        updateComboAvailabilitySuccess(dispatch, menuId);
      })
      .catch(() => {
        updateComboAvailabilityFail(dispatch);
      });
  };
};
export const updateComboVisibility = (data) => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.UPDATE_COMBO_AVAILABILITY_START,
    });

    axios
      .post(`/catalog/combo/visibility`, data)
      .then((res) => {
        updateComboVisibilitySuccess(dispatch, res.data);
      })
      .catch(() => {
        updateComboVisibilityFail(dispatch, "There was an error connection");
      });
  };
};

const updateComboVisibilityFail = (dispatch, errorMessage) => {
  dispatch({
    type: ComboActionTypes.UPDATE_COMBO_AVAILABILITY_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const updateComboVisibilitySuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  dispatch(getComboList());
  dispatch({
    type: ComboActionTypes.UPDATE_COMBO_AVAILABILITY_SUCCESS,
    payload: data,
  });
};

const sortComboCategoryFail = (dispatch, message) => {
  errorAlert(dispatch, message);
  dispatch({
    type: ComboActionTypes.SORT_COMBO_CATEGORY_FAIL,
  });
};

const sortComboCategorySuccess = (dispatch, message) => {
  successAlert(dispatch, message);
  dispatch({
    type: ComboActionTypes.SORT_COMBO_CATEGORY_SUCCESS,
  });
};
export const sortComboCategories = (
  categories: ComboCategory[],
  languageStrings: {
    [key: string]: string;
  }
) => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.SORT_COMBO_CATEGORY_START,
    });

    axios
      .post(SORT_COMBO_CATEGORY, { categories })
      .then(() => {
        sortComboCategorySuccess(dispatch, languageStrings["CATEGORY_SORTED"]);
      })
      .catch(() => {
        sortComboCategoryFail(dispatch, languageStrings["SORTING_FAILED"]);
      });
  };
};

const sortComboCategoryProductsFail = (dispatch, message) => {
  errorAlert(dispatch, message);
  dispatch({
    type: ComboActionTypes.SORT_COMBO_CATEGORY_PRODUCT_FAIL,
  });
};

const sortComboCategoryProductsSuccess = (dispatch, message) => {
  successAlert(dispatch, message);
  dispatch({
    type: ComboActionTypes.SORT_COMBO_CATEGORY_PRODUCT_SUCCESS,
  });
};
export const sortComboCategoryProducts = (
  productIds: string[],
  categoryId: string,
  languageStrings: {
    [key: string]: string;
  }
) => {
  return (dispatch) => {
    dispatch({
      type: ComboActionTypes.SORT_COMBO_CATEGORY_PRODUCT_START,
    });

    axios
      .post(SORT_COMBO_CATEGORY_PRODUCTS, { productIds, categoryId })
      .then(() => {
        sortComboCategoryProductsSuccess(
          dispatch,
          languageStrings["PRODUCT_SORTED"]
        );
      })
      .catch(() => {
        sortComboCategoryProductsFail(
          dispatch,
          languageStrings["SORTING_FAILED"]
        );
      });
  };
};
