import Order from "./Order";
import OrderList from "./OrderList";

export const OrderConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/orders",
      component: OrderList,
    },
    {
      path: "/order/:productId",
      component: Order,
    },
  ],
};
