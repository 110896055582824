import React, { useState } from "react";

import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import AlertDialog from "app/main/common/AlertDialog";
import * as Actions from "app/store/actions";
import ProductCard from "./ProductCard";
import history from "@history";

const SelectedProductList = ({
  selectedProducts = [],
  onRemove = () => {},
}) => {
  const dispatch = useDispatch();
  const { languageStrings } = useLanguageHelper();

  return (
    <Droppable droppableId="list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {selectedProducts &&
            selectedProducts.map((item, index) => (
              <ProductCard
                key={item._id}
                index={index}
                product={item}
                onDelete={() => {
                  dispatch(
                    Actions.openDialog({
                      children: (
                        <AlertDialog
                          onSuccess={() => {
                            onRemove(index);
                            Actions.closeDialog();
                          }}
                          title={languageStrings["CONFIRM_DELETE_ALERT_TITLE"]}
                          message={languageStrings["CONFIRM_DELETE_ALERT_BODY"]}
                        />
                      ),
                    })
                  );
                }}
              />
            ))}
        </div>
      )}
    </Droppable>
  );
};

export default SelectedProductList;
