import React from "react";
import clsx from "clsx";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";

export const paymentTypes = [
  {
    id: 0,
    name: "",
  },
  {
    id: 1,
    name: "GENERAL_CARD",
  },
  {
    id: 2,
    name: "GENERAL_CASH",
  },
  {
    id: 3,
    name: "GENERAL_MIXED_PARTIAL",
  },
];

const PaymentType = (props) => {
  const { languageStrings } = useLanguageHelper();
  const { paymentType } = props;
  const type = _.find(paymentTypes, { id: Number(paymentType) });
  return (
    <div
      style={{
        display: "inline",
        padding: "0.4rem",
        textAlign: "center",
      }}
      className="inline text-14 rounded truncate"
    >
      {languageStrings[type.name]}
    </div>
  );
};

export default PaymentType;
