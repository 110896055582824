const CLIENT_NAME = "Check";

const clientsSettings = {
  isCheck: true,
  isCheckOrderStatuses: true,
  showRegistration: false,
  showUpgradePlan: false,
  showAdditionalServices: false,

  hasWhatson: false,
  hasGallery: false,
  hasOffers: false,
  hasReservation: false,
  hasDraw: false,
  hasVoucher: false,
  hasNotification: false,

  metaTitle: "Check",
  metaDiscription: "Tu carta digital para pedidos desde la mesa",
  showWebViewButton: false,
  showPaymentMethod: false,
  showSubscription: false,
  showGoalsBar: false,
  showGoals: false,
  showQRCode: false,
  showUserRegistration: false,
  showOpeningHourTab: false,
  showOnlineDeliveryAlert: false,
  showDuplicateMenuItem: false,
  showMenuType: false,
  availableOnOrderFromTable: true,
  showSavePreviewButton: false,
  showUserSection: false,
  showOrderTypeButton: false,
  showOrderTypeColumn: false,
  showOnlineOrderMenu: false,
  showMenuAvailable: false,

  showAnalyticTab: false,
  showProgress: false,
  showUserDetail: false,
  showAnalyticTitle: false,
  showSetUpTitles: false,

  showCoordinates: false,
  hasVirtualBasket: true,

  showOrderTab: false,
  showDiscountVouchers: false,
  showRedSys: false,

  showStripeAdvatages: false,
  showStripeDisadvatages: false,
  showStripeUserGuide: false,

  showAnalyticOrderType: false,
  showCIF: true,
  showRazonSocial: true,
  showVenueToCalaborator: false,

  showBrandingLogo: false,

  showNotificationAlert: false,

  showWhatsappIcon: false,
  showPreselectedOption: false,
  showProfileImage: false,
};
export { clientsSettings, CLIENT_NAME };
