export interface Product {
  _id?: string;
  image?: string;
  imageFile?: File;
  title?: string;
  description?: string;
  price?: string;
  allergenes?: string;
  foodType?: string;
  sizes?: Size[];
  skuType?: SkuType;
  skuList?: [];
  promotionId?: string;
  comboSupplementPrice?: string;
  position?: number;
}

export enum SkuType {
  SIMPLE = "1",
  MULTIPLE = "2",
}

export interface Size {
  _id: string;
  title: string;
  position: number;
  sku: string;
}

export interface Modifier {
  _id?: string;
  title?: string;
  price?: number;
  image?: string;
}

//TODO REFACTOR to confirm
export interface GroupModifier {
  _id?: string;
  title?: string;
  description?: string;
  hasMaxMin?: boolean;
  type?: string;
  menuItem?: string;
  maxQuantity?: string;
  minQuantity?: string;
  connectedCategory?: string;
  languages?: {};
  modifiers?: Modifier[];
}
export interface Modifier {
  _id?: string;
  title?: string;
  price?: number;
  sku?: number;
}
