import { FuseAnimate } from "@fuse";
import { Button } from "@material-ui/core";
import React from "react";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { generateRandomString } from "app/helpers/utilsHelper";

export const SkuButton = ({ onCreateRandomString = (val) => {} }) => {
  const { languageStrings } = useLanguageHelper();
  return (
    <FuseAnimate animation="transition.slideRightIn" delay={300}>
      <Button
        className="mt-8 mb-16 mr-8 whitespace-no-wrap"
        variant="contained"
        onClick={() => {
          const randomString = generateRandomString();
          onCreateRandomString(randomString);
        }}
      >
        <p className="capitalize">{languageStrings["GENERATE_SKU"]}</p>
      </Button>
    </FuseAnimate>
  );
};

export default SkuButton;
