import React from "react";
import { TableCell } from "@material-ui/core";

const RefundedAmountCell = (props) => {
  const { n } = props;
  const refundedAmount =
    n && n.refundedAmount && Number(n.refundedAmount)
      ? Number(n.refundedAmount)
      : 0;
  return (
    <TableCell className="truncate" component="th" scope="row">
      <font className="ml-20">{`${refundedAmount.toFixed(2)}`}</font>
    </TableCell>
  );
};

export default RefundedAmountCell;
