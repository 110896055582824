import OrderList from './VirtualCartOrderList'
import VirtualCartOrder from './VirtualCartOrder'
import VirtualCartOrderList from './VirtualCartOrderList'

export const VirtualCartOrderConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/virtualCartOrders',
      component: VirtualCartOrderList,
    },
    {
      path: '/virtualCartOrder/:productId',
      component: VirtualCartOrder,
    },
  ],
}
