export default {
  "ALLERGENE.CELERY": "Céleri",
  "ALLERGENE.CEREALS": "Céréales",
  "ALLERGENE.CRUSTACEANS": "Crustacés",
  "ALLERGENE.EGGS": "Œufs",
  "ALLERGENE.FISH": "Poisson",
  "ALLERGENE.LUPIN": "Lupins",
  "ALLERGENE.MILK": "Lait",
  "ALLERGENE.MOLLUSCS": "Mollusques",
  "ALLERGENE.MUSTARD": "Moutarde",
  "ALLERGENE.NUTS": "Noix",
  "ALLERGENE.PEANUTS": "Cacahuètes",
  "ALLERGENE.SESAME_SEEDS": "Graines de sésame",
  "ALLERGENE.SULPHER_DIOXIDE": "Dioxyde de soufre",
  "ALLERGENE.TITLE": "ALLERGENEs",
  "ALLERGENE.SOYA": "Soja",
  "HOME_PAGE.RESTAURANT": "Restaurant",
  "HOME_PAGE.YOUR_CODE": "Votre code QR",
  "HOME_PAGE.PRINT": "Imprimer",
  "HOME_PAGE.DOWNLOAD": "Télécharger",
  "HOME_PAGE.CHANGE_URL": "Modifier l'URL",
  "HOME_PAGE.URL_TAKEN": "L'url saisie existe déjà",
  "HOME_PAGE.ANDROID_USER": "Utilisateurs Android",
  "HOME_PAGE.IOS_USER": "Utilisateurs IOS",
  "HOME_PAGE.ANALYTICS": "Statistiques",
  "HOME_PAGE.USER_REGISTERED": "Utilisateurs enregistrés",
  "HOME_PAGE.ORDERS_AMOUNT": "Revenu des commandes en ligne",
  "HOME_PAGE.PENDING_ORDERS": "Commandes en attente",
  FUTURE_ORDERS: "Programmée",
  "HOME_PAGE.PROGRESS_TITLE": "Votre compte, vos possibilités",
  "HOME_PAGE.PROGRESS_DESCRIPTION":
    "Nous adorons voir vos progrès, c'est la raison pour laquelle nous vous montrons toutes les fonctionnalités qu'il vous reste à découvrir... et celles que vous avez déjà découvertes !",
  "HOME_PAGE.HIDE_TEXT": "Masquez les objectifs complétés",
  "HOME_PAGE.SHOW_TEXT": "Affichez les objectifs atteints",
  "HOME_PAGE_GOALS.RESTAURANT_LOGO": "Téléchargez le logo de votre restaurant",
  "HOME_PAGE_GOALS.CUSTOMIZE_COLOR":
    "Personnalisez les couleurs de votre site Web",
  "HOME_PAGE_GOALS.CUSTOMIZE_HEADER":
    "Choisissez un en-tête pour votre site Web",
  "HOME_PAGE_GOALS.PREVIEW_WEB": "Aperçu de votre site Web",
  "HOME_PAGE_GOALS.CREATE_FIRST_CATEGORY":
    "Créez la première catégorie de votre menu",
  "HOME_PAGE_GOALS.UPLOAD_FIRST_MENU":
    "Téléchargez le premier plat de votre menu",
  "HOME_PAGE_GOALS.ADD_ALLERGENS": "Ajoutez des ALLERGENEs au menu !",
  "HOME_PAGE_GOALS.UPLOAD_PRODUCT_PHOTOS":
    "Téléchargez des photos de vos produits",
  "HOME_PAGE_GOALS.ADD_EXTRA_OPTIONS":
    "Ajoutez des options supplémentaires à vos plats",
  "HOME_PAGE_GOALS.CREATE_SECOND_CATEGORY": "Créer une deuxième catégorie",

  "HOME_PAGE_GOALS.ADD_STRIPE_KEY":
    "Ajoutez votre clé Stripe pour recevoir des paiements en ligne",
  "GENERAL.STRIPE_PUB_KEY": "Clé Stripe publiable",
  "HOME_PAGE_GOALS.ACTIVATE_ORDER_FROM_TABLE":
    "Activer les commandes depuis le tableau",
  "HOME_PAGE_GOALS.ACTIVATE_DELIVERY": "Activer les commandes à domicile",
  "HOME_PAGE_GOALS.ACTIVATE_PICK_UP": "Activer le ramassage local",
  "HOME_PAGE_GOALS.CREATE_FIRST_TABLE": "Créez votre premier tableau",
  "HOME_PAGE_GOALS.RECEIVE_FIRST_ORDER": "Recevez votre première commande",
  "HOME_PAGE_GOALS.ADD_PICKUP_AVAILABILITY":
    "Créez votre première disponibilité pour les commandes en retrait",
  "HOME_PAGE_GOALS.ADD_DELIVERY_AVAILABILITY":
    "Créez votre première disponibilité pour les bons de livraison",
  "HOME_PAGE_GOALS.ADD_DELIVERY_AREA": "Définissez votre zone de livraison",
  "HOME_PAGE.SELECT_YEAR": "Sélectionner l'année",

  "BOOKING_DETAIL_PAGE.BACK_BUTTON": "Liste de réservation",
  "BOOKING_DETAIL_PAGE.N_PEOPLE": "N. Personnes",
  "BOOKING_DETAIL_PAGE.SELECT_VENUE": "Sélectionner un lieu",
  "BOOKING_DETAIL_PAGE.TITLE": "Détail de la réservation",
  "BOOKING_LIST_HEADER_PAGE.TITLE": "Liste de réservation",
  "BRANDING_HEADER.TITLE": "Personnalisez votre site Web",
  "BRANDING.UPLOAD_LOGO": "Téléchargez votre logo",
  "BRANDING.UPLOAD_HEADER": "Téléchargez votre image principale",
  "BRANDING.CHOOSE_COLOR": "Choisissez les couleurs de votre entreprise",
  "CALENDER_APP_PAGE.SELECT_VENUE": "Sélectionner un lieu",
  "CALENDER_HEADER_PAGE.TITLE": "Calendrier",
  "CHANGE_PASSWORD_PAGE.TITLE": "Modifier le mot de passe",
  "DRAW_DETAIL_PAGE.BACK_BUTTON": "Liste des tirages",
  "DRAW_DETAIL_PAGE.EVENT_DURATIONL": "Fin de l'événement",
  "DRAW_DETAIL_PAGE.NEW_DRAW": "Nouveau tirage",
  "DRAW_DETAIL_PAGE.SELECT_DRAW": "Sélectionner Dessin",
  "DRAW_DETAIL_PAGE.TITLE": "Détail du dessin",
  "DRAW_LIST_HEADER_PAGE.TITLE": "Dessiner",
  "DRAW_LIST_PAGE.BUTTON_TEXT": "Créer un tirage",
  "DRAW_LIST_PAGE.TITLE": "Concours",

  "EXTRA_CATEGORIES.CATEGORY_TYPES": "Type de catégorie",
  "EXTRA_CATEGORIES.MINIMUM_QUANTITY": "Quantité minimale",
  "EXTRA_CATEGORIES.MAXIMUM_QUANTITY": "Quantité maximale",
  "EXTRA_CATEGORIES.ENABLE_QUANTITY": "Activer la limitation",
  "EXTRA_CATEGORIES.ADD": "Nouveau choix",
  "EXTRA_CATEGORIES.EXTRA_PRICE": "Prix supplémentaire",
  "EXTRA_CATEGORIES.SELECTED": "Pré-sélectionné",
  "EXTRA_CATEGORIES.CONNECT_CATEGORY": "Catégorie associée",

  "MATERIAL_TABLE.DELETE": "Êtes-vous sûr de vouloir supprimer cette ligne ?",
  "MATERIAL_TABLE.NO_RECORDS": "Aucune donnée à afficher",
  "MATERIAL_TABLE.ROW_SELECTED": "{0} ligne(s) sélectionnée(s)",
  "MATERIAL_TABLE.DISPLAYED_ROWS": "{from}-{to} de {count}",
  "MATERIAL_TABLE.ROWS": "Lignes",

  "ERRORS.STRIPE_ID_INVALID": "La clé de bande saisie est invalide",
  "ERRORS.STRIPE_KEY_INVALID": "La clé publique Stripe introduite est invalide",
  "ERRORS.REDSYS_SECRET_KEY": "La clé secrète redsys saisie est invalide",
  "ERRORS.REDSYS_MERCHANT_CODE": "Le code marchand redsys saisi est invalide ",
  "ERRORS.REDSYS_MERCHANT_NAME":
    "Le nom du marchand redsys saisi est invalide ",
  "ERRORS.IMAGE_IS_REQUIRED": "L'image est requise",
  "ERRORS.FIELD_IS_REQUIRED": "Champ Obligatoire",
  "ERRORS.INVALID_EMAIL": "Mauvais e-mail",
  "ERRORS.INVALID_PHONE": "Mauvais téléphone",
  "ERRORS.INVALID_URL": "URL invalide",
  "ERRORS.INVALID_LOGIN": "Connexion erronée. Veuillez réessayer",
  "ERRORS.MINIMUM_10_CHAR": "Taille minimale 10 caractères",
  "ERRORS.MINIMUM_3_CHAR": "Taille minimum 3 caractères",
  "ERRORS.MINIMUM_1_CHAR": "Taille minimale 1 caractère",
  "ERRORS.MINIMUM_4_CHAR": "Taille minimale 4 caractères",
  "ERRORS.MINIMUM_6_CHAR": "Taille minimale 6 caractères",
  "ERRORS.EMAIL_TAKEN": "L'email saisi existe déjà",
  "ERRORS.MUST_BE_NUMBER": "Doit être un nombre",
  "ERRORS.ORIGINAL_PRICE_SMALLER":
    "Le prix d'origine doit être supérieur au prix réduit",
  "ERRORS.REDUCED_PRICE_GREATER":
    "Le prix réduit doit être inférieur au prix d'origine",
  "ERRORS.SELECT_ONE": "Sélectionnez au moins une option",
  "ERRORS.PASSWORD_MUST_BE_7":
    "Le mot de passe doit comporter plus de 6 caractères",
  "GALLERY_DETAIL_PAGE.SELECT_VENUE": "Sélectionner un lieu",
  "GALLERY_DETAIL_PAGE.TITLE": "Galerie",
  "GENERAL.ADDRESS": "Adresse",
  "GENERAL.DUPLICATE": "Dupliquer",
  "GENERAL.CITY": "Ville",
  "GENERAL.CREATE": "Créer",
  "GENERAL.JANUARY": "Janvier",
  "GENERAL.FEBRUARY": "Février",
  "GENERAL.MARCH": "Mars",
  "GENERAL.APRIL": "Avril",
  "GENERAL.MAY": "Mai",
  "GENERAL.JUNE": "Juin",
  "GENERAL.JULY": "juillet",
  "GENERAL.AUGUST": "Août",
  "GENERAL.SEPTEMBER": "Septembre",
  "GENERAL.OCTOBER": "Octobre",
  "GENERAL.NOVEMBER": "Novembre",
  "GENERAL.DECEMBER": "Décembre",
  "GENERAL.ADD_BUTTON": "Ajouter",
  "GENERAL.BASIC_INFO": "Informations de base",
  "GENERAL.CANCEL_BUTTON": "Annuler",
  "GENERAL.CLIENT": "Client",
  "GENERAL.COMMENTS": "Commentaires",
  "GENERAL.CONFIRM_BUTTON": "Confirmer",
  "GENERAL.CONTACT_INFO": "Coordonnées",
  "GENERAL.DATE": "Date",
  "GENERAL.SCHEDULE_DATE_TIME": "Schedule Time",
  "GENERAL.DELETE": "Supprimer",
  "GENERAL.DENY_BUTTON": "Annuler",
  "GENERAL.DESCRIPTION": "Description",
  "GENERAL.DETAIL": "Détail",
  "GENERAL.EMAIL": "E-mail",
  "GENERAL.FRIDAY": "Vendredi",
  "GENERAL.IMAGE": "Image",
  "GENERAL.MONDAY": "Lundi",
  "GENERAL.NAME": "Nom",
  "GENERAL.NUMBER": "Numéro",
  "GENERAL.OPENING_HOURS": "Heures d'ouverture",
  "GENERAL.DELIVERY_INFO": "Livraison à domicile",
  "GENERAL.DELIVERY_TIME": "Délai de livraison",
  "GENERAL.MIN_DELIVERY_PRICE": "Montant d'expédition",
  "GENERAL.MIN_ORDER_PRICE": "Montant minimum de la commande",
  "GENERAL.COLLECTION_TIME": "Heure de collecte",
  "GENERAL.ORIGINAL_PRICE": "Prix d'origine",
  "GENERAL.DISCOUNTED_PRICE": "Prix réduit",
  "GENERAL.PASSWORD": "Mot de passe",
  "GENERAL.EXPORT": "Exporter",
  "GENERAL.EXPORT_CSV": "Télécharger csv",
  "GENERAL.EXPORT_XLS": "Télécharger xls",
  "GENERAL.PHONE": "Téléphone",
  "GENERAL.POINTS": "Points",
  "GENERAL.POSTCODE": "Code postal",
  "GENERAL.PRICE": "Montant",
  "GENERAL.REDUCED_PRICE": "Prix réduit",
  "GENERAL.ROWS_PER_PAGE": "Enregistrements par page",
  "GENERAL.SATURDAY": "Samedi",
  "GENERAL.SAVE": "Enregistrer",
  "GENERAL.SAVE_PREVIEW": "Enregistrer et prévisualiser",
  "GENERAL.SAVE_BUTTON": "Enregistrer",
  "GENERAL.SEARCH": "Rechercher",
  "GENERAL.SEND": "Envoyer",
  "GENERAL.SUNDAY": "Dimanche",
  "GENERAL.THURSDAY": "Jeudi",
  "GENERAL.TIME": "Heure",
  "GENERAL.TITLE": "Titre",
  "GENERAL.TUESDAY": "mardi",
  "GENERAL.TYPE": "Type",
  "GENERAL.USER_LIST": "Liste des utilisateurs",
  "GENERAL.WINNERS_LIST": "Gagnants",
  "GENERAL.VENUE": "Local",
  "GENERAL.STATUS": "Statut",
  "GENERAL.WEBSITE": "Page Web",
  "GENERAL.WEDNESDAY": "Mercredi",
  "GENERAL.CATEGORY": "Catégorie",
  "GENERAL.ITEMS": "Articles",
  "GENERAL.ACTIONS": "Actions",
  "GENERAL.REF": "Réf #",
  "GENERAL.ALL_VENUES": "Tous les lieux",
  "GENERAL.ALERT_TITLE": "Confirmation",
  "GENERAL.ORDER_TYPE": "Type de commande",
  "GENERAL.LATITUDE": "Latitude",
  "GENERAL.LONGITUDE": "Longueur",
  "GENERAL.NO_ORDER_LIST": "Il n'y a pas de commandes pour cette sélection",
  "ORDER_FROM_TABLE.TITLE_ERROR": "Le titre est manquant",
  "GENERAL.ALERT_DESC":
    "Êtes-vous sûr de vouloir revenir en arrière sans enregistrer les modifications ?",
  "GENERAL.AREA_REMOVE_ALERT_DESC":
    "Êtes-vous sûr de vouloir supprimer cette zone ?",
  "GENERAL.REMOVE": "Supprimer",
  "GENERAL.NO_OF_PEOPLE": "N. personnes",
  "GENERAL.PLATFORM": "Plateforme",
  "GENERAL.STRIPE_KEY": "Clé secrète Stripe",
  "GENERAL.REDSYS_KEY": "Clé secrète",
  "GENERAL.MERCHENT_CODE": "Code marchand",
  "GENERAL.MERCHENT_NAME": "Nom du marchand",
  "GENERAL.REDSYS_IS_TEST_MODE": "Mode d'essai",
  TIME_SETTINGS_COLLECTION: "Collection",
  TIME_SETTINGS_DELIVERY: "Livraison",
  "GENERAL.MIN": "min",
  "GENERAL.ENTER_TIME": "Entrez l'heure par défaut",
  "GENERAL.COLLECTION_ORRDER_CONFIRMATION_TITLE": "Heure de collecte estimée",
  "GENERAL.DELIVERY_ORRDER_CONFIRMATION_TITLE": "Délai de livraison estimé",
  "GENERAL.ESTIMATED_TIME": "Temps estimé",

  "GENERAL.ADV_TITLE": "Avantages",
  "GENERAL.DIS_ADV_TITLE": "Inconvénients",
  "GENERAL.USER_GUIDE": "Guide de l'utilisateur",
  "GENERAL.REDSYS_ADV1":
    "Commissions faibles : entre 0,15 et 0,3 % selon le traitement reçu",
  "GENERAL.REDSYS_ADV2": "avec votre banque",
  "GENERAL.REDSYS_ADV3":
    "Travailler avec la plupart des banques : comme Banco Sabadell, ING Direct.",
  "GENERAL.REDSYS_ADV4": "BBVA, Caixabank, Santander, Banco Popular.",
  "GENERAL.REDSYS_DIS_ADV1":
    "Carte de paiement non stockée : vos clients doivent renseigner leur moyen de paiement à chaque achat.",
  "GENERAL.REDSYS_DIS_ADV2":
    "De longs processus d'examen pour être acceptés et vous devez faire une demande auprès de votre banque actuelle ou votre demande peut être rejetée.",

  "GENERAL.STRIPE_ADV1":
    "Configuration simple et rapide pour commencer à recevoir vos commandes en ligne.",
  "GENERAL.STRIPE_ADV2":
    "Tableau de bord intuitif et moderne où vous gérez toutes vos transactions d'argent.",
  "GENERAL.STRIPE_ADV3":
    "Travaillez avec toutes les banques et vous recevrez l'argent sur votre compte quelques jours après la transaction.",
  "GENERAL.STRIPE_ADV4":
    "Cartes de paiement stockées en toute sécurité : ainsi, votre utilisateur n'a besoin de saisir sa carte que la première fois qu'il effectue un achat.",
  "GENERAL.STRIPE_DIS_ADV1": "Commissions supérieures : 1,4 % + .",

  "GENERAL.TO": "Jusqu'à",
  "GENERAL.YES": "Oui",
  "GENERAL.FIRST_NAME": "Prénom",
  "GENERAL.LAST_NAME": "Nom de famille",
  "GENERAL.YOUR_ARE_THERE": "Vous y êtes presque !",
  "GENERAL.ONLY_THE_LAST":
    "Seul le dernier ingrédient manque pour profiter pleinement de Banzzu",
  "GENERAL.MAKE_DEFAULT": "Par défaut",
  "GENERAL.DEFAULT": "Par défaut",
  "GENERAL.BACK": "Retour",
  "GENERAL.PAY": "Payer",
  "GENERAL.FULL_NAME": "Nom complet",
  "GENERAL.CONTINUE": "Continuer",
  "GENERAL.DELIVERY_COST": "Frais de port",
  "GENERAL.ADDRESS_LIST": "Adresses",
  "GENERAL.AVERAGE_ORDER": "Commande moyenne",
  "GENERAL.TOP_FIVE_DISHES": "Les 15 meilleurs plats",
  "GENERAL.ICOME_PER_DAY": "Revenu par jour",
  "GENERAL.CODE": "Code",
  "GENERAL.PERCENTAGE": "Pourcentage",
  "GENERAL.QUANTITY": "Montant",
  "GENERAL.EXPIREABLE": "Expiration",
  "GENERAL.AMOUNT": "Montant",
  "GENERAL.USED": "Utilisé",
  "GENERAL.MINIMUM_QUANTITY": "Quantité minimale",
  "GENERAL.ACTIVATE": "Activer",
  "GENERAL.DISOCUNT_CODE": "Code de réduction",
  "GENERAL.MINIMUM_AMOUNT": "Montant minimum",
  "GENERAL.EXPIRY_DATE": "Date d'expiration",
  "GENERAL.USABILITY": "Utilisabilité",
  "GENERAL.SINGLE_USE": "Usage unique",
  "GENERAL.MULTIPLE_USE": "Utilisations multiples",
  "GENERAL.CONFIRMATION_TITLE": "Confirmation",
  "GENERAL.PAYMENT_METHOD": "Méthode de paiement",
  "GENERAL.CONFIRMATION_SUBTITLE":
    "Souhaitez-vous demander des informations sur ce service supplémentaire ?",
  "GENERAL.TOTAL_SPENT": "Coût total",

  "LOGIN_PAGE.LOGIN_BUTTON": "Connexion",
  "LOGIN_PAGE.LOGIN_TO_ACCOUNT": "CONNEXION À VOTRE COMPTE",
  "LOGIN_PAGE.REGISTER_LINK": "Vous n'avez pas de compte ? Inscrivez-vous",
  "LOGIN_PAGE.FORGOT_PASSWORD": "Mot de passe oublié ?",
  "LOGIN_PAGE.ARE_YOU_SURE.ARE_YOU_SURE":
    "Êtes-vous sûr de vouloir récupérer votre mot de passe ?",
  "LOGIN_PAGE.CONFIRM": "Confirmer",
  "LOGIN_PAGE.CANCEL": "Annuler",
  "LOGIN_PAGE.ENTER_VALID_EMAIL": "Entrez un email valide",
  "LOGIN_PAGE_.EMAIL_EXIST": "Cet e-mail n'existe pas",
  "LOGIN_PAGE_.CHECK_EMAIL": "Vérifiez votre messagerie",
  "LOGIN_PAGE_.REMEMBER_ME": "Se souvenir de moi",

  "FORGOT_PASSWORD_PAGE.RESET_PASSWORD": "Réinitialisez votre mot de passe",
  "FORGOT_PASSWORD_PAGE.SAVE_BUTTON": "enregistrer",
  "FORGOT_PASSWORD_PAGE.PASSWORD": "Nouveau mot de passe",
  "FORGOT_PASSWORD_PAGE.CONFIRM_PASSWORD": "Confirmer le mot de passe",
  FORGOT_PASSWORD_PAGE_TOKEN_EXPIRED: "Le jeton a expiré",
  "FORGOT_PASSWORD_PAGE.SUCCESS": "Mot de passe modifié avec succès",
  "FORGOT_PASSWORD_PAGE.PASSWORD_MUST_BE_7":
    "Le mot de passe doit comporter plus de 6 caractères",
  "FORGOT_PASSWORD_PAGE.CONFIRM_PASSWORD_MUST_BE_7":
    "Confirmer le mot de passe doit comporter plus de 6 caractères",
  "FORGOT_PASSWORD_PAGE.PASSWORD_SAME":
    "Le mot de passe et le mot de passe de confirmation doivent être identiques",

  "MENU_CATEGORY_PAGE.ADD_CATEGORY": "Ajouter une catégorie",
  "MENU_CATEGORY_PAGE.PRODUCT_LIST": "Liste des produits",
  MENU_NEW_MENU_CREATED: "Votre menu a été créé avec succès",
  "MENU_DETAIL_PAGE.CREATE_CATEGORY": "Créer une catégorie",
  "MENU_DETAIL_PAGE.UPDATE_CATEGORY": "Mettre à jour la catégorie",
  "MENU_DETAIL_PAGE.CREATE_EXTRA_CATEGORY":
    "Créer une catégorie supplémentaire",
  "MENU_DETAIL_PAGE.BACK_BUTTON": "Liste des menus",
  "MENU_DETAIL_PAGE.MENU_TYPE": "Type de menus",
  "MENU_DETAIL_PAGE.NEW_MENUE": "Nouveau Menu",
  "MENU_DETAIL_PAGE.NEW_PRODUCT": "Nouveau produit",
  "MENU_DETAIL_PAGE.PDF_IS_REQUIRED": "PDF est requis",
  "MENU_DETAIL_PAGE.TITLE": "Menu Détail",
  "MENU_DETAIL_PAGE.HELP": "Besoin d'aide",
  "MENU_DETAIL_PAGE.MENU_SHARED":
    "Ce menu est partagé et ne peut pas être modifié",
  "MENU_DETAIL_PAGE.VENUES": "Lieux",
  "MENU_DETAIL_PAGE.VIEW_PDF": "Afficher le PDF",
  "MENU_DETAIL_PAGE.DELETE_CATEGORY": "Supprimer la catégorie",
  "MENU_DETAIL_PAGE.VIRTUAL_MENU_PRODUCTS": "Produits de menu virtuel",
  "MENU_DETAIL_PAGE.MENU_AVAILABILITY": "Menu disponible dans :",
  "MENU_DETAIL_PAGE.FUTURE_ORDERS": "Commandes futures",
  "MENU_DETAIL_PAGE.DELIVERY": "Livraison à domicile",
  "MENU_DETAIL_PAGE.COLLECTION": "Retrait local",
  "MENU_DETAIL_PAGE.ALLERGENES": "PDF ALLERGENEs (facultatif)",
  "MENU_DETAIL_PAGE.ORDER_FROM_TABLE": "Commander à partir du tableau",
  MENU_URL_ERROR: "L'URL n'est pas valide",

  "MENU_ITEM_DETAIL_PAGE.PRODUCT_DETAIL": "Détails du produit",
  "MENU_ITEM_DETAIL_PAGE.NEW_PRODUCT": "Nouveau produit",
  "MENU_ITEM_DETAIL_PAGE.PRODUCT_LIST": "Liste des produits",
  "MENU_ITEM_DETAIL_PAGE.SELECT_CATEGORY": "Sélectionner une catégorie",
  "MENU_ITEM_LIST_HEADER_PAGE.SELECT_CATEGORY": "Sélectionner une catégorie",
  "MENU_ITEM_LIST_HEADER_PAGE.CREATE_PRODUCT": "Créer des produits",
  "MENU_ITEM_LIST_HEADER_PAGE.PRODUCT": "Produits",
  "MENU_ITEM_LIST_PAGE.PRODUCT_LIST": "Liste de produits",
  "MENU_ITEM_LIST_PAGE.CATEGORIES": "Liste des catégories",
  "MENU_LIST_PAGE.BUTTON_TEXT": "Créer un menu",
  "MENU_LIST_PAGE.TITLE": "Liste des menus",
  "MENU_LIST_CATEGORY_PAGE.TITLE": "Catégories de menus",
  MENU_URL: "URL Menu",

  "NAVBAR.MASTER_ACCOUNT": "Compte administrateur",
  "NOTIFICATION_DETAIL_PAGE.BUTTON_BACK": "Notification poussée",
  "NOTIFICATION_DETAIL_PAGE.TITLE": "Envoyer une notification",
  "NOTIFICATION_LIST_PAGE.TITLE": "Notification poussée",
  "OFFER_DETAIL_PAGE.BACK_BUTTON": "Liste des offres",
  "OFFER_DETAIL_PAGE.DISABLE_PRICE": "Prix désactivé",
  "OFFER_DETAIL_PAGE.NEW_OFFER": "Offre",
  "OFFER_DETAIL_PAGE.SELECT_VENUE": "Sélectionner un lieu",
  "OFFER_DETAIL_PAGE.TITLE": "Détail de l'offre",
  "OFFER_LIST_HEADER_PAGE.BUTTON_TEXT": "Créer des offres",
  "OFFER_LIST_HEADER_PAGE.TITLE": "Liste des offres",
  "ORDER_LIST_PAGE.TITLE": "Commandes",
  "ORDER_LIST_PAGE.ALL_STATUS": "Tous les statuts",
  "ORDER_LIST_PAGE.ALL_TYPES": "Tous types",
  "ORDER_LIST_PAGE.STATUS_PLACEHOLDER": "Sélectionner le statut",
  "ORDER_LIST_PAGE.TYPES_PLACEHOLDER": "Type de lieu",
  "ORDER_PAGE.CUSTOMER": "Client",
  "ORDER_PAGE.EMAIL": "E-mail",
  "ORDER_PAGE.ID": "ID",
  "ORDER_PAGE.NAME": "Nom",
  "ORDER_PAGE.STATUS_BUTTON.CONFIRM": "Accepter les commandes",
  "ORDER_PAGE.STATUS_BUTTON.CANCEL": "Annuler",
  "ORDER_PAGE.STATUS_BUTTON.COLLECT": "Prêt à collecter",
  "ORDER_PAGE.STATUS_BUTTON.COLLECTD": "Commande récupérée",
  "ORDER_PAGE.STATUS_BUTTON.START_PREPARING": "Préparation",
  "ORDER_PAGE.STATUS_BUTTON.START_DELIVERY": "Démarrer la livraison",
  "ORDER_PAGE.STATUS_BUTTON.DELIVERY_COPLETED": "Terminé",
  "ORDER_PAGE.EXTRAS": "Extras",
  "ORDER_PAGE.ORDER": "Commande",
  "ORDER_PAGE.ORDERS": "Commandes",
  "ORDER_PAGE.ORDER_DETAIL": "Détail de la commande",
  "ORDER_PAGE.PHONE": "Téléphone",
  "ORDER_PAGE.PRODUCTS": "Produits",
  "ORDER_PAGE.QUANTITY": "Quantité",
  "ORDER_PAGE.DELIVERY": "Adresse de livraison",
  "ORDER_PAGE.COMMENTS": "Commentaires",

  "SCHEDULE_ORDER_LIST_PAGE.TITLE": "Planifier des commandes",
  "SCHEDULE_ORDER_PAGE.ORDERS": "Planifier des commandes",

  "REWARD_DETAIL_PAGE.BACK_BUTTON": "Liste des récompenses",
  "REWARD_DETAIL_PAGE.NEW_REWARD": "Nouvelle récompense",
  "REWARD_DETAIL_PAGE.TITLE": "Détail de la récompense",
  "REWARD_LIST_PAGE.BUTTON_TEXT": "Créer des récompenses",
  "REWARD_LIST_PAGE.TITLE": "Récompenses",
  "SIDEBAR_MENUE.CREATE_MENUE": "Créer un menu",
  "SIDEBAR_MENUE.CREATE_OFFER": "Créer une offre",
  "SIDEBAR_MENUE.CREATE_WHATSON": "Créer des nouvelles",
  "SIDEBAR_MENUE.HOME": "Accueil",
  "SIDEBAR_MENUE.ANALYTICS": "Analytique",
  "SIDEBAR_MENUE.DRAWS": "Dessins",
  "SIDEBAR_MENUE.DRAWS_CREATE": "Créer un dessin",
  "SIDEBAR_MENUE.DRAWS_LIST": "Liste des tirages",
  "SIDEBAR_MENUE.GALLERY": "Galerie",
  "SIDEBAR_MENUE.LOYALTY_PROGRAM": "Programme de fidélité",
  "SIDEBAR_MENUE.MENUE_LIST": "Liste des menus",
  "SIDEBAR_MENUE.MENUE_TITLE": "Menu",
  "SIDEBAR_MENUE.MENUE_TITLE_V2": "Menu V2",
  "SIDEBAR_MENUE.NOTIFICATIONS": "Notification",
  "SIDEBAR_MENUE.NOTIFICATIONS_PUSH": "Notification poussée",
  "SIDEBAR_MENUE.NOTIFICATIONS_SEND": "Envoyer Push",
  "SIDEBAR_MENUE.OFFERS": "Offres",
  "SIDEBAR_MENUE.OFFER_LIST": "Liste des offres",
  "SIDEBAR_MENUE.ORDERS": "Commandes",
  "SIDEBAR_MENUE.ORDERS_LIST": "Liste des commandes",
  "SIDEBAR_MENUE.SCHEDULE_ORDERS": "Commandes de Calendrier",
  "SIDEBAR_MENUE.PROFILE": "Profil",
  "SIDEBAR_MENUE.BRANDING": "Image d'entreprise",
  "SIDEBAR_MENUE.CATALOGUE": "Catalogue",
  "SIDEBAR_MENUE.PRODUCTS": "Produits",
  "SIDEBAR_MENUE.GROUPMODIFIERS": "Groupe des Modificateurs",
  "SIDEBAR_MENUE.MODIFIERS": "Modificateurs",
  "SIDEBAR_MENUE.RESERVATION": "Réservations",
  "SIDEBAR_MENUE.RESERVATION_BOOKING": "Calendrier de réservation",
  "SIDEBAR_MENUE.RESERVATION_CONFIRM": "Confirmer les réservations",
  "SIDEBAR_MENUE.REWARDS_CREATE": "Créer des récompenses",
  "SIDEBAR_MENUE.REWARDS_LIST": "Liste des récompenses",
  "SIDEBAR_MENUE.TITLE": "Menu",
  "SIDEBAR_MENUE.TRANSACTION": "Liste des transactions",
  "SIDEBAR_MENUE.USER_LIST": "Utilisateurs",
  "SIDEBAR_MENUE.VENUE": "Lieux",
  "SIDEBAR_MENUE.VENUE_DETAILS": "Créer un lieu",
  "SIDEBAR_MENUE.VENUE_LIST": "Liste des lieux",
  "SIDEBAR_MENUE.VOUCHER": "Coupons",
  "SIDEBAR_MENUE.VOUCHER_CREATE_VOUCHER": "Créer un bon",
  "SIDEBAR_MENUE.VOUCHER_LIST": "Liste des bons",
  "SIDEBAR_MENUE.WHATSON": "Actualités",
  "SIDEBAR_MENUE.WHATSON_lIST": "Liste des actualités",
  "SIDEBAR_MENUE.ORDER_FROM_TABLE": "Commander à partir du tableau",
  "SIDEBAR_MENUE.ORDER_FROM_TABLE_LIST": "Liste des tableaux",
  "SIDEBAR_MENUE.CONFIGURATION": "Configuration initiale",
  "SIDEBAR_MENUE.ONLINE_ORDERS": "Configurer les commandes en ligne",
  "SIDEBAR_MENUE.STAFF": "Employés",
  "SIDEBAR_MENUE.STAFF_LIST": "Liste des employés",
  "SIDEBAR_MENUE.CREATE_STAFF": "Créer un personnel",
  "SIDEBAR_MENUE.MERCHANDISING": "Services supplémentaires",
  "SIDEBAR_MENUE.PaymentOrdeFromTable": "Ordre de paiement à partir du tableau",
  "SIDEBAR_MENUE.VirtualCartOrder": "Historique du compte",
  "SIDEBAR_MENUE.RATINGS": "Notations",
  "STAFF_DETAIL.BASIC_INFO": "Informations de base",
  "STAFF_DETAIL.PERMISSIONS": "Autorisations et rôles",
  "STAFF_DETAIL.BACK_LIST": "Liste des employés",
  "STAFF_DETAIL.CREATE_STAFF": "Créer un employé",
  "STAFF_DETAIL.ASSIGNED_VENUES": "Locaux affectés",
  "SIDEBAR_MENUE.LOYALTY": "Programme de fidélité",
  "SIDEBAR_MENUE.PROMOTIONS": "Promotions",
  "SIDEBAR_MENUE.PROMOTION_LIST": "Liste des promotions",
  "SIDEBAR_MENUE.CREATE_PROMOTION": "Créer une promotion",
  "SIDEBAR_MENUE.KIOSK_MODE": "Mode Kiosque",

  "ORDER_FROM_TABLE.LIST": "Liste",
  "ORDER_FROM_TABLE.CREATE_TABLE": "Créer un tableau",
  "ORDER_FROM_TABLE.DOWNLOAD_QR": "Télécharger QR",
  "ORDER_FROM_TABLE.VENUES": "Lieux",

  "TRANSACTION_LIST_PAGE.TITLE": "Transactions",
  "USER_LIST_PAGE.TITLE": "Liste d'utilisateurs",

  "VENUE_DETAIL_PAGE.BUTTON_BACK": "Liste des lieux",
  "VENUE_DETAIL_PAGE.MAIN_TITLE": "Nouveau lieu",
  "VENUE_DETAIL_PAGE.DLIVERY_AREA_TITLE": "Zone de livraison",
  "VENUE_DETAIL_PAGE.REMOVE_AREA": "Supprimer la zone d'expédition",
  "VENUE_DETAIL_PAGE.CREATE_AREA": "Créer une zone de livraison",
  "VENUE_DETAIL_PAGE.AREA": "Zone",
  "VENUE_DETAIL_PAGE.CLEAR_AREA": "Effacer la zone du site",
  "VENUE_DETAIL_PAGE.ALERT_TIME.TITLE": "Ajouter une disponibilité",
  "VENUE_DETAIL_PAGE.ALERT_TIME.INITIAL_TIME": "Heure de début",
  "VENUE_DETAIL_PAGE.ALERT_TIME.END_TIME": "Heure de fin",
  "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_TITLE": "Supprimer la disponibilité",
  "VENUE_DETAIL_PAGE.DELETE_AVAILABILITY_SUBTITLE":
    "Êtes-vous sûr de vouloir supprimer cette disponibilité ?",
  "VENUE_DETAIL_PAGE.PASSWORD":
    "Mot de passe (Remplissez ce champ uniquement si vous souhaitez modifier votre mot de passe)",
  "VENUE_DETAIL_PAGE.VALID_URL": "Veuillez entrer une URL valide",
  "VENUE_DETAIL_PAGE.TITLE": "Détail local",
  "VENUE_DETAIL_PAGE.START_DATE_SHOULD_BE_LESS":
    "La date de début doit être antérieure à la date de fin",
  "VENUE_DETAIL_PAGE.END_DATE_SHOULD_BE_GREATER":
    "La date de fin doit être supérieure à la date de début",
  "VENUE_DETAIL_PAGE.DATE_RANGE":
    "La plage de dates ne peut pas être supérieure à 30 jours",
  "VENUE_DETAIL_PAGE.DELIVERY_ACTIVATION_ALERT":
    "Veuillez activer les commandes en ligne pour pouvoir explorer toute cette section",

  "VISIBILITY.ALWAYS_VISIBLE": "Toujours disponible",
  "VISIBILITY.DISABLED": "Désactivé",
  "VISIBILITY.SHOW_ONLY": "Afficher uniquement",
  "VISIBILITY.PARTIALY_DISABLED": "Partiellement désactivé",
  "VISIBILITY.TITLE": "Visibilité",
  "VISIBILITY.TIME_RANGE": "Disponibilité",

  "VENUE_LIST_HEADER_PAGE.CREATE_NEW_VENUE": "Créer un lieu",
  "VENUE_LIST_HEADER_PAGE.NEW": "Nouveau",
  "VENUE_LIST_HEADER_PAGE.TITLE": "Liste des lieux",
  "VOUCHER_DETAIL_PAGE.BUTTON_BACK": "Liste des bons",
  "VOUCHER_DETAIL_PAGE.NEW_VOUCHER": "Créer un bon",
  "VOUCHER_DETAIL_PAGE.TITLE": "Détail du bon",
  "VOUCHER_DETAIL_PAGE.VOUCHER_TYPE": "Sélectionner le nombre de tampons",
  "VOUCHER_LIST_HEADER_PAGE.BUTTON_TEXT": "Créer un coupon",
  "VOUCHER_LIST_HEADER_PAGE.NEW": "Nouveau",
  "VOUCHER_LIST_HEADER_PAGE.TITLE": "Liste des bons",
  "WHATS_ON_LIST_HEADER_PAGE.BUTTON_TEXT": "Créer des nouvelles",
  "WHATS_ON_LIST_HEADER_PAGE.MAIN_TITLE": "Nouvelles nouvelles",
  "WHATS_ON_LIST_HEADER_PAGE.TITLE": "Actualités",
  "WHAT_ON_DETAIL_PAGE.BACK_BUTTON": "Liste des actualités",
  "WHAT_ON_DETAIL_PAGE.DATE_FROM": "De",
  "WHAT_ON_DETAIL_PAGE.DATE_TO": "Jusqu'à",
  "WHAT_ON_DETAIL_PAGE.EVENT_DURATIONL": "Durée de l'événement",
  "WHAT_ON_DETAIL_PAGE.SELECT_VENUE": "Sélectionner un lieu",
  "WHAT_ON_DETAIL_PAGE.TITLE": "Détail de l'actualité",
  "WHAT_ON_DETAIL_PAGE.ONE_DAY": "1 jour",
  "WHAT_ON_DETAIL_PAGE.MULTIPLE_DAYS": "Plusieurs jours",

  "ORDER_TYPE.CASH": "Paiement en espèces",
  "ORDER_TYPE.DELIVERY": "Livraison",
  "ORDER_TYPE.COLLECT": "Enlèvement local",
  "ORDER_TYPE.FUTURE_ORDER": "Commande future",
  "ORDER_TYPE.ORDER_FROM_TABLE": "Commander à partir du tableau",

  "ORDER_STATUS.ORDER_NUMBER": "Référence de la commande",
  "ORDER_STATUS.UPDATED_AT": "Date de création",
  "ORDER_STATUS.PAYMENT_BY_CASH": "Payer en espèces",
  "ORDER_STATUS.PAYMENT_ACCEPTED": "Paiement accepté",
  "ORDER_STATUS.ORDER_ACCEPTED": "Commande acceptée",
  "ORDER_STATUS.PREPARING": "Préparation de la commande",
  "ORDER_STATUS.ON_DELIVERY": "À la livraison",
  "ORDER_STATUS.DELIVERED": "Terminé",
  "ORDER_STATUS.CANCELED": "Annulé",
  "ORDER_STATUS.COLLECT": "Prêt à collecter",
  "ORDER_STATUS.COLLECTED": "Commande récupérée",
  "ORDER_STATUS.ORDER_TIME": "Heure de la commande",
  "ORDER_STATUS.ORDER_TABLE": "Tableau des commandes",

  "USER_DETAIL.BACK_LIST": "Liste des utilisateurs",
  "USER_DETAIL.ORDER_LIST": "Commandes",
  "USER_DETAIL.REWARD_LIST": "Récompenses",
  "USER_DETAIL.LOYALTY_LIST": "Programme de fidélité",
  "USER_DETAIL.BOOKING_LIST": "Réservations",
  "USER_DETAIL.DRAW_LIST": "Tirages",
  "USER_DETAIL.ADDRESS_LIST": "Adresses",
  "VENUE.ADD_LONGITUDE_ALERT":
    "Veuillez ajouter la latitude et la longitude dans les informations de contact",
  "MENU.SHARED":
    "Ce menu est partagé avec d'autres lieux, il ne peut pas être modifié",
  "TABLE_HEAD.CONFIRMATION_TITLE": "Confirmation",
  "TABLE_HEAD.CONFIRMATION_DESCRIPTION":
    "Êtes-vous sûr de vouloir supprimer cet enregistrement ?",
  "PRICE_REDUCE_CELL.DISABLED_PRICE": "Prix désactivé",

  "REGISTER_PAGE.TITLE": "Inscription gratuite",
  "REGISTRER_PAGE.SUBTITLE":
    "Commencez à proposer des commandes en ligne sans commissions, fidélisez vous-même vos clients et augmentez vos revenus",
  "REGISTER_PAGE.FIRST_NAME": "Prénom",
  "REGISTER_PAGE.LAST_NAME": "Nom de famille",
  "REGISTER_PAGE.PHONE_NUMBER": "Téléphone",
  "REGISTER_PAGE.EMAIL": "E-mail",
  "REGISTER_PAGE.TELEPHONE": "Téléphone",
  "REGISTER_PAGE.PASSWORD": "Mot de passe",
  "REGISTER_PAGE.TERMS_CONDITIONS": "Conditions d'utilisation",
  "REGISTER_PAGE.PRIVACY_POLICY": "Politique de confidentialité",
  "REGISTER_PAGE.ACCEPT": "Accepter",
  "REGISTER_PAGE.BUTTON_TEXT": "Continuer",
  "REGISTER_PAGE.LOGIN_LINK": "Vous avez déjà un compte ?",
  "REGISTER_PAGE.LOGIN": "Connexion",
  "REGISTER_PAGE.NEW_IN_BANZZU": "Nouveau sur Banzzu ?",
  "REGISTER_PAGE.SUB_TITLE_1":
    "Partez à la conquête de l'univers numérique de votre local",
  "REGISTER_PAGE.SUB_TITLE_2": "et découvrez toutes vos possibilités",

  "REGISTER_STEPS.REGISTER_RESTAURANT": "Inscrivez votre restaurant",
  "REGISTER_STEPS.LOCATION": "Emplacement",
  "REGISTER_STEPS.SELECT_SERVICES": "Sélection de services",
  "REGISTER_STEPS.ADDITIONAL_INFORMATION": "Informations supplémentaires",
  "REGISTER_STEPS.RESTAURANT_NAME": "Nom du restaurant",
  "REGISTER_STEPS.TELEPHONE": "Téléphone du restaurant",
  "REGISTER_STEPS.ADDRESS": "Adresse",
  "REGISTER_STEPS.POSTAL_CODE": "Code postal",
  "REGISTER_STEPS.MAP_TITLE":
    "Voulez-vous changer l'emplacement de votre restaurant ?",
  "REGISTER_STEPS.MOVE_PIN": "Déplacer l'épingle sur la carte",
  "REGISTER_STEPS.SERVICES_TITLE": "Quels services proposez-vous ?",
  "REGISTER_STEPS.PICK_UP_SERVICE": "Livraison à domicile",
  "REGISTER_STEPS.DELIVERY_SERVICE": "Enlèvement local",
  "REGISTER_STEPS.CONGRATULATION": "Félicitations !",
  "REGISTER_STEPS.CONGRATULATION_DESCRIPTION": "Votre compte a été configuré",
  "REGISTER_STEPS.PROCEED": "Continuer",
  "REGISTER_STEPS.SELECT_COUNTRY": "Sélectionner un pays",
  "REGISTER_STEPS.BACK": "Retour",
  "REGISTER_STEPS.NEXT": "Suivant",
  "REGISTER_STEPS.SUBMIT": "Terminer",

  "PAYMENT_SETTINGS_PAGE.SETTINGS": "Commandes en ligne",
  "PAYMENT_SETTINGS_PAGE.TITLE": "Le paramètre",
  "PAYMENT_SETTINGS_PAGE.ORDER_SETTINGS": "Commandes en ligne",
  "PAYMENT_SETTINGS_PAGE.DISCOUNT_VOUCHERS": "Bons de réduction",
  "PAYMENT_SETTINGS_PAGE.PAYMENT_INTEGRATION": "Intégration des paiements",
  "PAYMENT_SETTINGS_PAGE.CREATE_DISCOUNT_VOUCHER": "Créer un bon de réduction",
  "PAYMENT_SETTINGS_PAGE.EDIT_DISCOUNT_VOUCHER": "Modifier le bon de réduction",
  "PAYMENT_SETTINGS_PAGE.HELPER_TEXT":
    "En activant les options suivantes, les différentes commandes en ligne seront disponibles pour vos clients",
  "PAYMENT_SETTINGS_PAGE.ONLINE_ORDERS_TITLE":
    "Activer ou désactiver les commandes en ligne",
  "PAYMENT_SETTINGS_PAGE.ALERT_HELPER_TEXT":
    "Ajoutez la clé secrète de votre compte Stripe dans l'intégration de paiement pour pouvoir activer ces options",
  "PAYMENT_SETTINGS_PAGE.ALERT_STRIPE":
    "Entrez la clé secrète de votre compte Stripe et vérifiez que les paiements fonctionnent correctement",
  "PAYMENT_SETTINGS_PAGE.DELIVERY": "Domicile",
  "PAYMENT_SETTINGS_PAGE.COLLECTION": "Retrait local",
  "PAYMENT_SETTINGS_PAGE.ORDER_FROM_TABLE": "Commander à partir du tableau",
  "PAYMENT_SETTINGS_PAGE.MENU_AVAILABILITY":
    "Disponible dans les menus suivants:",
  "PAYMENT_SETTINGS_PAGE.STRIP_KEY_ERROR":
    "Les commandes en ligne ne peuvent pas être activées tant que vous n'avez pas correctement configuré votre mot de passe Stripe. Cliquez ici pour le configurer",
  "PAYMENT_SETTINGS_PAGE.PAY_BY_CASH": "Payer en espèces",
  "PAYMENT_SETTINGS_PAGE.PAY_BY_CARD": "Payer par carte",
  "PAYMENT_SETTINGS_PAGE.ONE_ACTIVE_PAYMENT":
    "Une des options doit être activée",
  "PAYMENT_SETTINGS_PAGE.PAYMENT_METHODS": "Modes de paiement",
  "PAYMENT_SETTINGS_PAGE.ADD_STRIPE_KEY":
    "Ajouter une clé de bande valide pour activer cette option",

  "PAYMENT_PAGE.VENUE": "Restaurants",
  "PAYMENT_PAGE.MONTH": "mois",
  "PAYMENT_PAGE.RESTAURANT": "Restaurant",
  "PAYMENT_PAGE.IVA": "Taxes non incluses",
  "PAYMENT_PAGE.EXTRA_RESTAURANT": "Restaurant supplémentaire",
  "PAYMENT.CHOOSE_PAYMENT_METHOD": "Choisir le mode de paiement",
  "PAYMENT.ADD_CARD": "Ajouter une carte",
  "PAYMENT.HOW_MANY_RESTAURANTS": "Combien de restaurants avez-vous ?",
  "PAYMENT.HOW_MANY_RESTAURANTS_DO_YOU_WANT_TO_ADD":
    "Combien de restaurants voulez-vous ajouter ?",

  "HELP_MENU.CONTACT_SUPPORT": "Contacter l'assistance",
  "HELP_MENU.PAYMENTS": "Modes de paiement",
  "HELP_MENU.CHANGE_PASSWORD": "Modifier le mot de passe",
  "HELP_MENU.LOGOUT": "Déconnexion",

  "CONTACT_SUPPORT.TITLE": "Contactez-nous",
  "CONTACT_SUPPORT.SUB_TITLE":
    "Utilisez le formulaire suivant, ou envoyez-nous un email à ",
  "CONTACT_SUPPORT.SUBJECT": "Raison",
  "CONTACT_SUPPORT.MESSAGE": "Message",
  "CONTACT_SUPPORT.SEND": "Envoyer",
  "CONTACT_SUPPORT.SUCCESS_MESSAGE": "Votre message a été envoyé avec succès",

  "PRICING.UPGRADE": "inclut plus de restaurants",
  "PRICING.PLANES": "Forfaits",
  "PRICING.THE_MOST_ADVANCED":
    "Le logiciel le plus avancé et sophistiqué pour votre restaurant avec des prix clairs et transparents.",
  "PRICING.YOU_PLANE": "VOTRE PLAN",
  "PRICING.PER_MONTH": "PAR MOIS",
  "PRICING.STANDARD": "Standard",
  "PRICING.BASIC_ANALYTICS": "Analyse de base",
  "PRICING.PAGE": "Page",
  "PRICING.CUSTOM_WEB": "site Web personnalisé",
  "PRICING.FOR_YOUR_BUSINESS": "pour votre entreprise",
  "PRICING.ACCOMMODATION": "Hébergement",
  "PRICING.FREE_HOSTING": "site Web gratuit",
  "PRICING.YOUR_OWN_INTERACTIVE_MENU": "Votre propre menu interactif",
  "PRICING.ADD_IMAGES_OF_YOUR_PRODUCT":
    "Ajoutez des images de vos produits, des descriptions, des prix, des ingrédients et des ALLERGENEs de chacun de vos plats.",
  "PRICING.CUSTOM_QR_CODE": "Code QR",
  "PRICING.WITH_YOUR_MENU": "personnalisé avec votre menu",
  "PRICING.24th_SUPPORT": "Assistance 24h/24",
  "PRICING.BY_CHAT_AND_EMAIL": "par chat et e-mail",
  "PRICING.ACCESS_ALL_RESTAURANT": "Accès à toutes les données du restaurant :",
  "PRICING.ADDRESS_PHONE":
    "adresse, numéros de téléphone, réseaux sociaux, site web…",
  "PRICING.INCLUDE_ONE_VENUE": "Comprend un lieu",
  "PRICING.YOUR_PLAN": "VOTRE FORFAIT",
  "PRICING.ADVANCED": "Avancé",
  "PRICING.BEFORE": "Avant",
  "PRICING.EVERYTHING_INCLUDE":
    "Tout ce qui est inclus dans le forfait gratuit",
  "PRICING.PROP_ANALYTICS": "Analytique Pro",
  "PRICING.USER_CREATION_REGISTRATION":
    "Création et enregistrement des utilisateurs",
  "PRICING.TO_RECIVE": "Recevoir",
  "PRICING.HOME_DELIVER_ORDER": "Commandes à domicile",
  "PRICING.ORDER_TO_COLLECT": "Commandes à récupérer",
  "PRICING.ORDER_FROM_TABLE": "Commandes du tableau",
  "PRICING.LET_YOUR_IMGINATION":
    "Laissez libre cours à votre imagination et créez",
  "PRICING.SLEEP_PACE_FULLY": "Dormez tranquille avec la sécurité de notre",
  "PRICING.ANTI_FRAUD_SYSTEM": "système anti-fraude",
  "PRICING.UNLIMITED_VENUES": "Lieux illimités",
  "PRICING.PER_VENUE": "par lieu",
  "PRICING.RECIVE_ORDERES_WITH_PAYMENT": "Recevoir des commandes avec paiement",
  "PRICING.CASH_CARD": "espèces/carte",
  "PRICING.INTEGRATION": "Intégration de la passerelle de paiement",
  "PRICING.PRINTING_ORDER_BY_PRINTER":
    "Commande d'impression imprimante bluetooth",
  "PRICING.UPGRADE_": "Mettre à niveau",
  "PRICING.SINCE": "Depuis",
  "PRICING.EVERY_THING_INCLUDED":
    "Tout ce qui est inclus dans le forfait Advancé",
  "PRICING.WE_ARE_CREATE": "Nous créons votre entièrement optimisé pour",
  "PRICING.IOS_AND_ANDROID": "IOS et Android",
  "PRICING.DONT_MISS":
    "Ne ratez rien grâce au système de notification push sur votre smartphone",
  "PRICING.PUBLICATION_IN": " Publication dans",
  "PRICING.APP_STORE": "Magasin d'applications",
  "PRICING.AND": "et",
  "PRICING.INCLUDE": " inclus",
  "PRICING.ORGANIZE": "Organiser",
  "PRICING.EXCLUSIVE_CONTESTS": "concours exclusifs",
  "PRICING.FOR_ALL_APP_USERS": "pour tous les utilisateurs de l'application.",
  "PRICING.MAINTAIN_A_CLOSE_RELATIONSHIP":
    "Garder une relation de proximité avec vos clients grâce au ",
  "PRICING.LOYALTY_PROGRAM": "programme de fidélité",
  "PRICING.EXCLUSIVE_OFFERS": "offres exclusives",
  "PRICING.MANAGE_YOUR_RESTAURANTS_BOOKINGS":
    "Gérez vos réservations de restaurant depuis votre smartphone",
  "PRICING.AND_MUCH_MORE": "Et bien plus encore....",
  "PRICING.REQUEST_INFORMATION": "Demande d'informations",

  "PRICING.FREQUENT_QUESTIONS": "Foire aux questions",
  "PRICING.HOW_CAN_I_UPGRADE_MY_PLAN":
    "Comment puis-je mettre à jour mon forfait ?",
  "PRICING.IF_YOU_WANT_TO_UNLOCK":
    "Si vous souhaitez déverrouiller les fonctionnalités de commandes -de n'importe où-, de cartes ou de menus illimités et de commandes à partir d'appareils, il vous suffit d'aller au-dessus et de choisir entre les plans Advanced ou Premium, selon vos besoins.",
  "PRICING.WHAT_ARE_AVAILABLE_PAYMENT_METHOD":
    "Quelles sont les méthodes de paiement disponibles ?",
  "PRICING.YOU_CAN_PAY":
    "Vous pouvez payer par carte de crédit depuis tous les pays, si vous rencontrez des problèmes avec le paiement, contactez-nous et nous le résoudrons dans les plus brefs délais",
  "PRICING.WHO_CAN_ACCESS_MY_QR_CODE": "Qui peut accéder à mon code QR ?",
  "PRICING.YOUR_CODE_CAN":
    "Votre code peut être scanné par n'importe qui. Ce qui signifie que plus vous distribuez et publiez votre code, plus les gens verront votre restaurant et votre menu.",
  "PRICING.HOW_CURRENT_ACCURATE":
    "Dans quelle mesure les données collectées sont-elles à jour et exactes ?",
  "PRICING.ALL_DATA_IS_COLLECTED":
    "Toutes les données sont collectées en temps réel, nous générons donc des statistiques quotidiennement. Nous nous efforçons chaque jour de vous fournir les informations les plus à jour et les plus réelles pour votre entreprise.",
  "PRICING.CAN_I_EDIT": "Puis-je modifier la lettre moi-même ?",
  "PRICING.YES_YOU_WILL_HAVE_ACCESS":
    "Oui ! Vous aurez accès à un gestionnaire très simple, où vous pourrez modifier les plats, les photos, les prix, les descriptions et bien plus encore...",
  "PRICING.WHAT_ARE_THE_FEES": "Quels sont les frais ?",
  "PRICING.AT_BANZZU_WE_DO_NOT":
    "A Banzzu, nous n'appliquons aucun type de commission, ce que vous voyez est ce que vous payez.",
  "PRICING.IF_I_AM_OTHER":
    "Si je suis avec d'autres sociétés de livraison, puis-je travailler avec Banzzu ?",
  "PRICING.YES_OUR_RECOMMENDATION":
    "Oui ! Notre recommandation est de continuer à vous faire connaître de cette manière et de transférer tous les nouveaux clients vers Banzzu, car avec nous, vous ne paierez pas de commissions.",
  "PRICING.CAN_THE_CUSTOMER":
    "Le client peut-il commander depuis son appareil ? et payer à partir de celui-ci ?",
  "PRICING.YES_THANK_TO_BANZZU":
    "Grâce à Banzzu tous vos clients pourront passer leurs commandes depuis leur propre smartphone, y compris leur paiement",
  "PRICING.WHAT_TYPE_OF_RESTAURANT":
    "Quel type de restaurant est le plus approprié pour travailler avec Banzzu ?",
  "PRICING.BANZZU_IS_SOFTWARE":
    " Banzzu est un logiciel conçu pour tous les types de restaurants. Numérisez votre restaurant et appliquez de nouvelles améliorations technologiques, quel que soit le type de restaurant que vous avez, commencez à optimiser votre entreprise avec Banzzu.",
  "PRICING.CAN_I_CREATE": "Puis-je créer plusieurs menus ?",
  "PRICING.YES_ONE_OF":
    "Oui ! L'un des avantages de nos plans Advanced et Premium est les menus illimités. Laissez libre cours à votre imagination et créez des menus de jour comme de nuit ou de vacances. Tout est possible avec Banzzu.",

  "UPGRADE_MODAL.YOUR_PLAN_IS_TOO_SMALL":
    "Votre forfait est trop petit pour vous",
  "UPGRADE_MODAL.ITS_SEEMS":
    "Oups ! Il semble que ces fonctionnalités ne soient pas incluses dans votre forfait actuel.",
  "UPGRADE_MODAL.INCREASE_PLAN":
    "Améliorez votre plan et profitez de toutes les possibilités que Banzzu a à offrir.",
  "UPGRADE_MODAL.UPGRADE_MY_PLAN": "Mettre à jour mon forfait",
  "UPGRADE_MODAL.I_STICK": "Je m'en tiens à mon plan",

  "PRICING.14_DAY_FREE_TRAIL": "14 jours d'essai gratuit",
  "PRICING.GET_THIS_PLAN": "Obtenir ce forfait",
  "PRICING.START_FREE_TRAIL": "Démarrer l'essai gratuit",
  "PRICING.YOU_WILL_NOT_BE":
    "Vous ne serez pas débité pendant la période gratuite de 14 jours, vous pouvez résilier votre abonnement à tout moment",

  "TOOLBAR.VIEW_WEB": "Afficher votre site Web",
  "TOOLBAR.IMPROVE_PLAN": "Améliorez votre plan",

  "CATALOGUE.CREATE_PRODUCT": "Crear Producto",
  "CATALOGUE.CREATE_MODIFIER": "Crear Modificador",
  "CATALOGUE.CREATE_GROUPMODIFIER": "Crear Grupo Modificador",
  "CATALOGUE.SKU": "SKU",
  "CATALOGUE.MULTISIZE": "MultiTamaño",
  "CATALOGUE.SIMPLE": "Simple",
  "CATALOGUE.PRODUCT_LIST": "Lista de Productos",
  "CATALOGUE.PRODUCT_DETAIL": "Detalle Producto",
  "CATALOGUE.PRODUCT_NEW": "Nuevo Producto",

  "MERCHANDISE.ITEM.QR_TITLE": "Votre code QR personnalisé",
  "MERCHANDISE.ITEM.QR_TEXT": "Scannez-moi pour commander !",
  "MERCHANDISE.ITEM.QR_SUB_TITLE":
    "Vous imaginez votre QR sur toutes les tables ? Maintenant c'est possible !",
  "MERCHANDISE.ITEM.QR_DESCRIPTION":
    "Récupérez votre QR code sur des stickers personnalisables. Nous vous les envoyons prêts pour que vous ne vous occupiez que de les coller et que vos clients puissent les scanner.",
  "MERCHANDISE.ITEM.QR_CUSTOMIZE": "Personnalisez votre autocollant QR",
  "MERCHANDISE.ITEM.QR_QUANTITY_TITLE":
    "Combien d'autocollants QR voulez-vous imprimer ?",
  "MERCHANDISE.ITEM.ADDRESS_TITLE": "Entrez l'adresse de livraison",
  "MERCHANDISE.ITEM.SEND_TO_RESTAURANT": "Utiliser l'adresse du restaurant",

  "MERCHANDISE.ITEM.CREATE_MENUE": "Nous créons votre menu",
  "MERCHANDISE.ITEM.CREATE_MENUE_DESCRIPTION":
    "Nous vous aidons à avoir votre menu en ligne parfait sans vous soucier de rien. Nous avons juste besoin que vous nous envoyiez un PDF avec votre menu et nous le numériserons pour vous.",
  "MERCHANDISE.ITEM.DELIVERY_FLEET": "Votre flotte de livraison",
  "MERCHANDISE.ITEM.DELIVERY_FLEET_DESCRIPTION":
    "Désormais, il est possible d'avoir votre propre flotte louée à l'heure. Nous mettons à votre disposition autant de passagers et autant d'heures que nécessaire pour répondre à vos besoins.",
  "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF": "Faites-vous connaître en ligne",
  "MERCHANDISE.ITEM.INTRODUCE_YOUR_SELF_DESCRIPTION":
    "Faites la promotion de votre lettre digitale dans le comparateur de livraison Delitbee et obtenez plus de visites",
  "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE":
    "Nous créons votre site web d'entreprise",
  "MERCHANDISE.ITEM.WE_CREATE_YOUR CORPORATE_WEBSITE_DESCRIPTION":
    "Je suis sûr qu'en dehors de la section de commande que vous créez avec Banzzu, vous voudrez un site Web d'entreprise où vous pourrez montrer le charme unique de votre restaurant.",

  "MERCHANDISE.UP_SELL_SEND_EMAIL_MESSAGE":
    "Information demandée correctement, nous vous contacterons pour vous donner plus d'informations.",
  "MERCHANDISE.WANT_IT": "Je le veux",

  "ACCOUNT.ARE_YOU_SURE":
    "Êtes-vous sûr de vouloir supprimer votre compte, allez-vous perdre tous les menus que vous avez créés ?",
  "ACCOUNT.DELETE_ACCOUNT": "Supprimer le compte",
  "ACCOUNT.YOUR_CURRENT_SUBSCRIPTION": "Votre abonnement actuel est gratuit",
  "ACCOUNT.YOUR_SUBSCRIPTION": "Votre abonnement",
  "ACCOUNT.CURRENT_SUBSCRIPTION_IS_ADVANCED":
    "Votre abonnement actuel est Avancé",
  "ACCOUNT.CANCEL_SUBSCRIPTION": "Annuler l'abonnement",
  "ACCOUNT.ARE_YOU_SURE_TO_CANCEL_SUBSCRIPTION":
    "Êtes-vous sûr de vouloir annuler votre abonnement actuel et revenir au forfait gratuit ?",

  "SETUP_ACCOUNT.ACCOUNT_ALREADY_SETUPED": "Compte déjà configuré",
  "SETUP_ACCOUNT.TITLE":
    "Se lancer n'a jamais été aussi facile, il suffit de sélectionner le mot de passe de votre compte",
  "SETUP_ACCOUNT.PASSWORD": "Mot de passe",
  "SETUP_ACCOUNT.CONFIRM_PASSWORD": "Confirmer le mot de passe",
  "SETUP_ACCOUNT.SAVE_BUTTON": "Continuer",

  "LANGUAGE.SPANISH": "Espagnol",
  "LANGUAGE.ENGLISH": "Anglais",
  "LANGUAGE.ITALIAN": "Italien",
  "LANGUAGE.FRENCH": "Français",
  "LANGUAGE.GERMAN": "Allemand",
  "LANGUAGE.CATALAN": "Catalan",
  "LANGUAGE.EUSKERA": "Basque",
  "LANGUAGE.MANDARIN": "Mandarin",
  "LANGUAGE.JAPANESE": "Japonais",
  "LANGUAGE.PORTUGUESE": "Portugais",
  "LANGUAGE.RUSSIAN": "Russe",
  "LANGUAGE.GREEK": "Grec",
  "LANGUAGE.CHINESE": "Chinois",

  SELECT_TAX: "Sélectionner la taxe",
  Virtual_Basket: "Panier virtuel",
  TOTAL: "Total",
  PAIS_ON_VENUE: "Payé localement",
  QUANTITY: "Quantité",
  UNIT_PRICE: "Prix unitaire",
  BILLS: "Comptes",
  PAID_ON_VENUE_MODEL:
    "Êtes-vous sûr de vouloir marquer le compte comme payé ?",
  PENDIGN_ORDERS: "Paiement en attente",
  "NEW.ORDER_FROM_TABLE": "Tableaux QR",
  VIRTUAL_CARD_ORDER: "Commandes de cartes virtuelles",
  VIRTUAL_CARD_ORDER_TABLE: "Tableau",
  VIRTUAL_CARD_ORDER_UPDATE_ON: "Date de création",
  STRIPE_CONNECTED_ACCOUNT: " Compte Connecté Stripe ",

  PARTIAL_PAYMENT: "Paiement partiel",
  PAID_ON_VENUE: "Payé localement",
  FULL_PAYMENT: "Paiement intégral",
  TABLE_CARD_ITEM: "Produits",

  SAVE_CHANGES: "Sauvegarder les changements",
  ADD_EXTRA_OPTIONS: "Ajouter des options supplémentaires",
  VIRTUAL_CARD_ORDER_NUMBER: "Numéro de commande",
  ADD_PRODUCT_IN: "Ajouter un produit",

  ROZAN_SOCIAL: "Nom de l'entreprise",
  CIF: "SIRET",
  MENU: "Menu",
  AUTO_SERVICES: "Libre-Service",
  PREPARE_MINUTES: "Prepare Minutes",
  RANGE: "Range",
  EXTRA_TIME: "Extra time",
  BEHAVIOUR: "Behaviour",
  ADD_RANGE: "Ajouter un Rang",
  AUTOSERVICE_ALERT_TITLE: "Confirmer la Suppression",
  AUTOSERVICE_ALERT_BODY: "Etes-vous sûr que vous voulez supprimer?",
  REVIEW_ORDER_IN_THE_LAST: "Examiner les commandes des dernières",
  DELETE_TABLE_CONFIRMATION_MESSAGE:
    "Êtes-vous sûr de vouloir supprimer cette ligne ?",
  MENU_ID: "Menu Id",

  CONFIRM_DELETE_ALERT_TITLE: "Confirmer la Suppression",
  CONFIRM_DELETE_ALERT_BODY: "Etes-vous sûr que vous voulez supprimer?",
  PARTIAL_DELIVERY: "Livraison Partielle",
  IN_PROGRESS: "En Cours",
  WHATSAPP: "Whatsapp",
  SERVICE: "Service supplémentaire",
  REFOUND: "Remboursement",
  FULL_REFOUND: "Remboursement intégral",
  PARTIAL_REFOUND: "Remboursement partiel",
  AMOUNT: "Montant",
  CONFIRMATION: "confirmation",
  "SPLIT_BILL_MODEL.AMONT_IS_MANDATORY": "Le montant est requis",
  "SPLIT_BILL_MODEL.THE_AMOUNT_EXCEEDS_THE_AMOUNT_TO_BE_PAID":
    "Le montant dépasse le montant à payer",
  TICKET_NUMBER: "Ticket #",
  REFUNDED_AMOUNT: "Montant remboursé",
  TIP: "Conseil",
  ENABLE: "Activar",
  DISABLE: "Desactivar",
  DRINKS: "Boire",
  FOOD: "Alimentation",
  BILL_DETAIL: "Détail des factures",
  TIPS_RANGE: "Sélectionner les dates",
  SHOW_TIPS: "Conseils pour l'exposition",
  TIPS_LIST: "Liste des Pourboires",
  TOTAL_TIPS: "Total des Pourboires",
  FETCH_PRODUCTS: "Recherche de produits",
  DISCONNECTED_PRODUCTS: "POS déconnecté",
  POS_PRODUCTS: "Produit POS",
  ALL_CATEGORIES: "Toutes les catégories",
  ERROR_FETCHING_ORDERS: "Erreur dans l'extraction des ordres",
  "SIDEBAR_MENUE.PAYMENT_HISTORY": "Historique des paiements",
  STRIPE_ID: "Stripe ID",
  PAID_ON_CASH: "Espèces",
  PARTIAL_PAYMENT_CASH: "Paiement partiel en espèces",
  PAYMENT_TYPE: "Type de paiement",
  GENERAL_CASH: "Espèces",
  GENERAL_CARD: "Carte de crédit",
  GENERAL_MIXED_PARTIAL: "Espèces et carte de crédit",
  GENERAL_LANGUAGE: "Langue",
  GENERAL_LANGUAGES: "Langues",
  MENU_CATEGORY_DIVIDE: "Diviser en catégories",
  MENU_PRODUCT_FAVORITE: "Ajouter comme populaire",
  BRANDING_PRIMARY_COLOUR: "Couleur primaire",
  BRANDING_SECONDARY_COLOUR: "Couleur secondaire",
  BRANDING_TERTIARY_COLOUR: "Couleur tertiaire",
  "GENERAL.RATING": "Notation",
  AVERAGE_RATING: "Note moyenne",
  LOYALTY_PROGRAM: "Programme de fidélité",
  RATING_RANGE: "Fourchette de valeurs",
  PAY_BY_CARD: "Carte",
  COMPLETE_ORDER: "Livraison complète",
  LOYALTY_CATEGORY_BRONZE: "Bronze",
  LOYALTY_CATEGORY_SILVER: "Silver",
  LOYALTY_CATEGORY_GOLD: "Or",
  LOYALTY_CATEGORY_PLATINUM: "Platine",
  LOYALTY_PROGRAM_DETAIL: "Détail du client",
  LOYALTY_PROGRAM_CLIENT_AVERAGE: "Ticket moyen",
  LOYALTY_PROGRAM_CLIENT_TIP_NUMBER: "Nombre de pourboires",
  LOYALTY_PROGRAM_CLIENT_TIP_TOTAL: "Total des pourboires",
  LOYALTY_PROGRAM_CLIENT_ORDER_NUMBER: "Nombre de commandes",
  LOYALTY_PROGRAM_CLIENT_ORDER_TOTAL: "Total des commandes",
  LOYALTY_PROGRAM_CLIENT_FREQUENCY: "Fréquence des commandes",
  LOYALTY_PROGRAM_FREQUENCY_HIGH: "Élevé",
  LOYALTY_PROGRAM_FREQUENCY_MEDIUM: "Moyen",
  LOYALTY_PROGRAM_FREQUENCY_LOW: "Bas",
  VISIBILITY_DESCRIPTION: "Description de la visibilité",
  SET_MENU_VISIBILITY: "Calendrier des menus",
  DELIVERY_DATE: "Date de livraison",
  DELIVERY_TIME: "Délai de livraison",
  MENU_VISIBILITY_DESCRIPTION: "Menu Désactivé - Description",
  GENERATE_SKU: "Générer un SKU",
  SELECT_MODIFIERS: "Sélectionnez les modificateurs",
  SELECT_GROUP_MODIFIERS: "Sélectionner Les Modificateurs De Groupe",
  SELECT_PRODUCT: "Sélectionner Un Produit",
  ACTION: "Action",
  VISIBILITY: "Visibilité",
  PRINT_TICKET: "Imprimer le billet",
  GLOBALL_VISIBILITY: "Visibilité Mondiale",
  TICKET_PRINT: "Impression du Ticket",
  POPUP: "Pop-up",
  POPUP_SWITCH: "Activer Pop-up",
  PROMOTION_DETAIL: "Détail de la promotion",
  NEW_PROMOTION: "Nouvelle promotion",
  TOTAL_RATINGS: "No d'évaluations",
  TOTAL_5_RATINGS: "Commentaires de 5 étoiles",
  FILTER: "Filtre",
  FILTER_RANGE: "Filtre de plage",
  APPLY: "Appliquer",
  TABLE_VENUE: "Lieu de la table",
  REVIEW: "Revoir",
  NUMBER_OF_PAYMENTS: "No de paiements",
  TOTAL_AMOUNT: "Montant total",
  TOTAL_PRICE: "Prix total",
  SELECT_PAYMENT_TYPE: "Sélectionner le type de paiement",
  "GENERAL.START_DATE": "Date de début",
  "GENERAL.END_DATE": "Date de fin",
  READY_TO_PICK: "COLLECTER",
  PREPARATION: "EN PREPARATION",
  SELECT_VENUE_TO_PROCEED: "Sélectionnez le lieu pour continuer",
  SUBMIT: "Soumettre",
  "BRANDING.AUTOSERVICE_SCREEN_COLOR":
    "Choisissez la couleur de votre écran Auto Service",
  AUTOSERVICE_SCREEN_LOGO: "Téléchargement du logo de l'écran AutoService",

  COMBO_LIST: "Liste des combinaison",
  COMBOS: "Combinaisons",
  COMBO_DETAIL: "Détail de la combinaison",
  CREATE_COMBO: "Créer une combinaison",
  NEW_COMBO: "Nouvelle combinaison",
  NEW_CATEGORY: "Nouvelle catégorie",
  ADD_PRODUCT_TO_CATEGORY: "Ajouter un produit dans la catégorie",
  ADD_PRODUCT: "Ajouter un produit",
  CATEGORIES: "Catégories",
  COMBO_SUPPLEMENT_PRICE: "Prix du supplément Combo",
  SELECT_COMBO: "Sélectionner le Combo",
  ADD_COMBO: "Ajouter Combo",

  GENERAL_ALL: "Tous",
  ALL_RESULTS: "Tous les résultats",
  AVERAGE_REVIEW: "Note moyenne",
  POS_TITLE: "Titre du POS",

  "CATALOGUE.MODIFIER_LIST": "LISTE DE MODIFICATEURS",
  "SIDEBAR_MENUE.GROUPMODIFIER_LIST": "LISTE DES GROUPES DE MODIFICATEURS",
  Table_Tarif: "tableau des tarifs",
  CATEGORY_SORTED: "Catégorie triée",
  PRODUCT_SORTED: "Produit trié",
  SORTING_FAILED: "Échec du tri",
  FOR_FREE: "Gratuit",
  AUTOSERVICE_LOGO:
    "Téléchargement de logo pour l'écran de service automatique",
  STOCK: "Action",
  STOCK_QUANTITY: "Quantité en stock",
  POS_PROMOTIONS: "Promotions TPV",
  GENERAL_PRODUCTS: "Produits",
  GENERAL_COMBOS: "Combos",
  "MENU_CATEGORY_PAGE.COMBO_LIST": "Liste Des Combinaisons",
  MESSAGE_NO_DATA_FOUND: "Aucune donnée n'a été trouvée",
  EXTRA_OPTIONS: "Options Supplémentaires",
  KIOSK_SETTINGS: "Paramètres du kiosque",
  MAIN_COLOR: "Couleur Principale",
  SECONDARY_COLOR: "Couleur secondaire",
  LEFT_COLOR: "Couleur Gauche",
  RIGHT_COLOR: "Couleur Droite",
  UPLOAD_ENTRY_LOGO_IMAGE: "Image du logo d'entrée",
  UPLOAD_ENTRY_LOGO_VIDEO: "Vidéo du logo d'entrée",
  UPLOAD_NAME_SCREEN_LOGO: "Logo de l'écran de nom",
  UPLOAD_ORDER_TYPE_LOGO: "Télécharger le logo de type de commande",
  UPLOAD_CATEGORY_LOGO: "Télécharger le logo de catégorie",
  UPLOAD_PAYMENT_LOGO: "Télécharger le logo de paiement",
  UPLOAD_AUTO_SERVICE_LOGO: "Télécharger le logo de service automobile",
  TABLES: "Tables",
  MENUS: "Menus",
  SCHEDULE_ORDER_CREATION_DATE: "Date de création de l'ordre de programmation",
  SCHEDULE_ORDER_CREATION_TIME:
    "Programmation de l'heure de création de la commande",
};
