import { PaymentHistoryType } from "../redux/actionTypes";
import { updateObject } from "../utility";

const INITIAL_STATE = {
  paymentList: [],
  loading: false,
  searchText: "",
  paymentDetail: {},
};

interface Action {
  payload: any;
  type: string;
}

const paymentHistoryReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case PaymentHistoryType.PAYMENT_HISTORY_SEARCH_TEXT:
      return updateObject(state, { searchText: action.payload.searchText });
    case PaymentHistoryType.PAYMENT_HISTORY_LIST_START:
      return updateObject(state, { loading: true, paymentList: [] });
    case PaymentHistoryType.PAYMENT_HISTORY_LIST_SUCCESS:
      return updateObject(state, {
        loading: false,
        paymentList: action.payload.payments,
        totalPayment: action.payload.totalPayment,
        totalPaymentCountByClient: action.payload.totalPaymentCountByClient,
      });
    case PaymentHistoryType.PAYMENT_HISTORY_LIST_FAIL:
      return updateObject(state, {
        loading: false,
      });
    case PaymentHistoryType.PAYMENT_HISTORY_DETAIL_START:
      return updateObject(state, { loading: true, paymentDetail: {} });
    case PaymentHistoryType.PAYMENT_HISTORY_DETAIL_SUCCESS:
      return updateObject(state, {
        loading: false,
        paymentDetail: action.payload,
      });
    case PaymentHistoryType.PAYMENT_HISTORY_DETAIL_FAIL:
      return updateObject(state, {
        loading: false,
      });
    case PaymentHistoryType.PAYMENT_HISTORY_REFUND_START:
      return updateObject(state, { loading: true });
    case PaymentHistoryType.PAYMENT_HISTORY_REFUND_SUCCESS:
      return updateObject(state, {
        loading: false,
      });
    case PaymentHistoryType.PAYMENT_HISTORY_REFUND_FAIL:
      return updateObject(state, {
        loading: false,
      });

    default: {
      return state;
    }
  }
};

export default paymentHistoryReducer;
