import CustomModal from "app/main/common/CustomModal";
import React, { FC } from "react";
import Rating from "@material-ui/lab/Rating";
import { styled } from "@material-ui/core";

interface IProps {
  showModal: boolean;
  onCloseModal: () => void;
  rating: number;
  review?: string;
}
const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "red",
  },
});
export const ReviewModal: FC<IProps> = ({
  showModal,
  onCloseModal,
  rating,
  review = "",
}) => {
  return (
    <CustomModal showModal={showModal} onCloseModal={onCloseModal}>
      <div
        className={"flex flex-col  bg-white p-16 rounded items-center"}
        style={{ minWidth: "30%", maxWidth: "30%" }}
      >
        <StyledRating
          name="simple-controlled"
          value={rating}
          size="large"
          style={{
            width: "45%",
            display: "flex",
            justifyContent: "space-between",
          }}
          readOnly={true}
        />
        <span className="mt-8">{review}</span>
      </div>
    </CustomModal>
  );
};
