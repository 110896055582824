import React, { useEffect, useState } from "react";
import {
  Icon,
  IconButton,
  makeStyles,
  Slider,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootSlider: {
    color: "#ff8330",
    flex: 2,
  },
}));

export const MultiSlider = ({
  onChange,
  form,
  onDelete = () => {},
  updatedHours = [],
  data = [],
}) => {
  const classes = useStyles();
  const [value, setValue] = useState([0, 48]);
  const valuetext = (value) => {
    return `${value}°C`;
  };
  const getValue = () => {
    const _start = updatedHours.indexOf(form.start.replace(/:[^:]+$/, ""));
    const _end = updatedHours.indexOf(form.end.replace(/:[^:]+$/, ""));
    const filteredStart =
      _start >= 0 ? _start : updatedHours.indexOf(form.start);
    const filteredEnd = _end >= 0 ? _end : updatedHours.indexOf(form.end);
    const newValue = [filteredStart, filteredEnd];
    setValue(newValue);
  };
  useEffect(getValue, [form]);
  return (
    <div>
      <Typography
        variant="body1"
        style={{
          color: "#ff8330",
          fontSize: 14,
          fontWeight: "bold",
          textAlign: "right",
        }}
      >
        {form.start + " - " + form.end}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Slider
          min={0}
          max={48}
          classes={{ root: classes.rootSlider }}
          value={value}
          onChange={(event, newValue) => {
            onChange(event, newValue);
          }}
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
        />
        <IconButton
          disabled={data.length <= 1}
          onClick={onDelete}
          className="ml-2"
        >
          <Icon color="error" className="text-22">
            delete_outline
          </Icon>
        </IconButton>
      </div>
    </div>
  );
};
