import Pricing from "./Pricing";
import PaymentPage from "./PaymentPage";
import UpgradeSubscriptionPage from "./UpgradeSubscriptionPage";
import { clientsSettings } from "config";
import HomePage from "../home/HomePage";

const _routes = clientsSettings.showUpgradePlan?[
  {
    path: "/pricing",
    component: Pricing,
  },
  {
    path: "/payment",
    component: PaymentPage,
  },
  {
    path: "/upgrade",
    component: UpgradeSubscriptionPage,
  },
]:[

]

export const PricingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: _routes,
};
