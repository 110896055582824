import React from "react";
import { TableCell, Typography } from "@material-ui/core";
import { formatDate } from "app/helpers/validators";

const TextWithExtracategory = ({ n, obj }) => {
  return (
    <TableCell component="th" scope="row">
      <Typography variant="subtitle2">{n.title}</Typography>
      {n.extraCategories &&
        n.extraCategories.length > 0 &&
        n.extraCategories.map(({ title, items }) => {
          return (
            <>
              <Typography variant="body2">{title}</Typography>
              {items.map(({ title, selected }) => {
                if (selected) {
                  return (
                    <Typography style={{ color: "#c3c3c3" }} variant="body2">
                      {title}
                    </Typography>
                  );
                }
                return null;
              })}
            </>
          );
        })}
    </TableCell>
  );
};

export default TextWithExtracategory;
