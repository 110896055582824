import { clientsSettings } from "config";
import MerchandisingPage from "./MerchandisingPage";
import MerchantProductDetail from "./MerchantProductDetail";

const _routes = clientsSettings.showAdditionalServices
  ? [
      {
        path: "/merchandising",
        component: MerchandisingPage,
      },
      {
        path: "/product-detail",
        component: MerchantProductDetail,
      },
    ]
  : [];

export const MerchandisingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: _routes,
};

/**
 * Lazy load Example
 */
/*
import React from 'react'

export const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
}
*/
