const MENU_ITEM = "menuItem";
const MENU_DUPLICATE = "duplicate-menu";
const MENU_ITEMS_LIST_SORT = "menuItem/sort";
const MENU_CATEGORY_SORT = "menuCategory/sort";
const MENU_DUPLICATE_ITEM = "duplicate-menu-item";
const MENU_ITEM_EXTRA_CATEGORY = "menuItemExtraCategory";
const MENU_EXTRA_ITEMS_SORT = "menuItemExtraCategoryItem/sort";
const MENU_ITEM_EXTRA_CATEGORY_ITEM = "menuItemExtraCategoryItem";
const MENU_ITEM_EXTRA_CATEGORY_SORT = "menuItemExtraCategory/sort";
const MENU_ITEM_UPDATE_TIME = "update-availability";
const MENU_UPDATE_TIME = "menu/update-availability";

export {
  MENU_ITEM,
  MENU_DUPLICATE,
  MENU_CATEGORY_SORT,
  MENU_DUPLICATE_ITEM,
  MENU_ITEMS_LIST_SORT,
  MENU_EXTRA_ITEMS_SORT,
  MENU_ITEM_EXTRA_CATEGORY,
  MENU_ITEM_EXTRA_CATEGORY_ITEM,
  MENU_ITEM_EXTRA_CATEGORY_SORT,
  MENU_ITEM_UPDATE_TIME,
  MENU_UPDATE_TIME,
};
