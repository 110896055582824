import React from "react";
import { TableCell } from "@material-ui/core";
import useClientCurrency from "app/hooks/useClientCurrency";

const UnitPriceCell = (props) => {
  const { n } = props;
  const { clientCurrency } = useClientCurrency();
  const _price =
    n && n.unitPrice && Number(n.unitPrice) ? Number(n.unitPrice) : 0;
  return (
    <TableCell className="truncate" component="th" scope="row">
      <font>
        {_price.toFixed(2)} {`${clientCurrency.symbol}`}
      </font>
    </TableCell>
  );
};

export default UnitPriceCell;
