import React, { useState, FC, useEffect } from "react";

import { TextField } from "@material-ui/core";
import { useDebounce } from "@fuse/hooks";

interface Props {
  disabled?: boolean;
  className?: string;
  required?: boolean;
  autoFocus?: boolean;
  label: string;
  value: string;
  id?: string;
  name: string;
  hasError?: (error) => void;
  minLength?: Number;
  onValueChange: (value) => void;
  type?: string;
  errorMessage?: string;
  maxLength?: Number;
}

const CustomInput: FC<Props> = ({
  disabled,
  className,
  required,
  autoFocus,
  label,
  value,
  id,
  name,
  hasError,
  minLength,
  onValueChange,
  type,
  errorMessage,
  maxLength = 100,
}) => {
  const [error, setError] = useState({ errorMessage, exists: false });

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      errorMessage,
    }));
  }, [errorMessage]);

  const handleChange = (event) => {
    onValueChange(event);
    debounceValue(event.target.value);
  };

  const debounceValue = useDebounce((value) => {
    let error = false;
    if (minLength && value.length < minLength) {
      error = true;
    }

    hasError && hasError(error);
    setError((prevState) => {
      return { ...prevState, exists: error };
    });
  }, 500);

  return (
    <TextField
      className={className}
      required={required}
      autoFocus={autoFocus}
      label={label}
      disabled={disabled}
      id={id}
      type={type}
      name={name}
      value={value}
      error={error.exists}
      onChange={handleChange}
      helperText={error.exists ? error.errorMessage : ""}
      inputProps={{
        maxLength,
      }}
      variant="outlined"
      fullWidth
      onPaste={handleChange}
    />
  );
};

export default CustomInput;
