import React, { useState } from "react";
import { TableCell, IconButton, Icon } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  actionsButtonWrapper: {
    flexDirection: "row",
  },
}));
const ActionsCell = ({ actions, n }) => {
  const classes = useStyles();

  return (
    <TableCell>
      <div className={clsx(classes.actionsButtonWrapper)}>
        {actions.map((action) => {
          return (
            <IconButton
              onClick={() => {
                action.onclick(n);
              }}
            >
              <Icon>{action.iconName}</Icon>
            </IconButton>
          );
        })}
      </div>
    </TableCell>
  );
};

export default ActionsCell;
