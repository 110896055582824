import React, { useEffect, useState } from "react";
import { Button, Icon } from "@material-ui/core";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import {
  setDefaultLanguage,
  setDefaultLanguageV2,
} from "app/helpers/utilsHelper";
import LanguageDropdown from "app/main/menuV2/menuDetailV2Pro/components/LanguageDropdownV2.tsx";
import { handleMenuTitle } from "app/helpers/utilsHelper";
import { ProductDropDown } from "app/main/common/ProductDropDown";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const AddProductModal = (props) => {
  const { onClose, onSaveProduct } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemCategory
  );
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { locale } = user;

  let menu = useSelector(({ banzzu }) => banzzu.menuV2.menuV2ItemDetail);
  let { selectedLanguages } = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2Detail
  );
  const availableLanguages = selectedLanguages || ["Spanish"];

  const { languageStrings } = useLanguageHelper();

  const defaultLanguage = setDefaultLanguageV2(locale);
  const getInitialSelectedLanguage = () => defaultLanguage.code;
  const [selectedLanguage, setSelectedLanguage] = useState(
    getInitialSelectedLanguage
  );
  const isDefaultLanguage = setDefaultLanguage(selectedLanguage, locale);

  const { form, setForm } = useForm(null);

  if (form != null && form.categoryId === "0" && categoryList[0] != null) {
    setForm((form) => ({
      ...form,
      categoryId: categoryList[0]._id,
    }));
  }

  useEffect(() => {
    if ((menu && !form) || (menu && form && menu._id !== form._id)) {
      setForm({
        ...menu,
        isDefaultLanguage,
        languages: {},
      });
    }
  }, [form, menu, setForm]);

  useEffect(() => {
    setForm((form) => ({
      ...form,
      isDefaultLanguage,
    }));
  }, [selectedLanguage]);

  function canBeSubmitted() {
    return form.productId !== undefined;
  }

  const handleChangeProduct = (_product, field) => {
    if (
      _product &&
      _product.price >= 0 &&
      Number(_product.price) >= 0 &&
      _product._id
    ) {
      const productTitle = _product.title;
      const description =
        _product && _product.description ? _product.description : "";
      setForm((prevForm) => ({
        ...prevForm,
        title: productTitle,
        productId: _product._id,
        languages: {
          ...prevForm.languages,
          [selectedLanguage]: {
            ...prevForm.languages[selectedLanguage],
            title: productTitle,
            description,
          },
        },
        productDetail: _product,
      }));
    }
  };

  if (!form) {
    return <div />;
  }

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setForm((prevForm) => ({
      ...prevForm,
      language,
    }));
  };

  const handleTitle = (product, language) => {
    const posName = "";
    const languageTitle = handleMenuTitle(product, language, locale, posName);
    setForm((prevForm) => ({
      ...prevForm,
      title: languageTitle.title,
    }));
  };
  const dropDownValue =
    form && form.productDetail
      ? form.productDetail
      : form.productId
      ? form.productId
      : null;
  return (
    <div className="p-16 sm:p-24  max-h-screen min-w-[620px]">
      <ProductDropDown
        selectedValue={dropDownValue}
        handleChangeValue={(item) => handleChangeProduct(item)}
      />
      <Button
        className="whitespace-no-wrap mt-16"
        variant="contained"
        disabled={!canBeSubmitted()}
        onClick={() => {
          onSaveProduct(form);
          onClose();
        }}
      >
        <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>save</Icon>
        {languageStrings["GENERAL.SAVE"]}
      </Button>
    </div>
  );
};

export default withReducer("combosReducer", reducer)(AddProductModal);
