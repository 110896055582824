import { axiosInstance as axios } from "app/api/axios";
import { getAutoServiceUrl, getVenueListUrl } from "app/api/Endpoint";
import { getVenueListSuccess } from "../venues/VenuesActions";
import { toast } from "react-toastify";
export const getAutoServiceOrderStatus = (
  action,
  setOrderList,
  setSelectedVenueIO,
  languageStrings,
  setLoading
) => {
  return (dispatch) => {
    const venueListUrl = getVenueListUrl();

    axios
      .get(venueListUrl)
      .then((res) => {
        if (res.data.data.length > 0) {
          getVenueListSuccess(dispatch, res.data.data);
          const filteredVenueList = res.data.data.filter(
            (venue) => venue.autoServices
          );
          if (filteredVenueList.length > 1) {
            action(true);
            setLoading(false);
          } else {
            dispatch(
              getAutoServiceOrderStatusById(
                filteredVenueList[0]._id,
                setOrderList,
                setSelectedVenueIO,
                languageStrings,
                setLoading
              )
            );
          }
        }
      })
      .catch((error) => {
        toast.error(languageStrings["ERROR_FETCHING_ORDERS"]);
        console.log({ error });
        setLoading(false);
      });
  };
};

export const getAutoServiceOrderStatusById = (
  venueId,
  setOrderList,
  setSelectedVenueIO,
  languageStrings,
  setLoading
) => {
  return (dispatch) => {
    const url = getAutoServiceUrl(venueId);

    axios
      .get(url)
      .then((res) => {
        setOrderList(res.data.data[0]);
        setSelectedVenueIO(venueId);
      })
      .catch((error) => {
        toast.error(languageStrings["ERROR_FETCHING_ORDERS"]);
        console.log({ error });
        setLoading(false);
      });
  };
};
