import { getUserId } from "./Endpoint";

export const ORDERS = "orders";
export const ORDER_REVENUE = "/orders/revenue";
export const ORDER_TABLE = "order/table";
export const PAY_ORDER_TABLE = "/order/table/paidOnVeue";
export const ORDER_ANALYTICS = "/orders/ordersAnalytics";
export const TABLE_SCAN = "order/table/scan";
export const ORDER_DASHBOARD = "/orders/dashboard";
export const EXPORT_ORDERS = "/export/orders";

export const getOrderListUrl = (): string => {
  return encodeURI(ORDERS + "?userId=" + getUserId());
};
export const getDashboardOrderListUrl = (): string => {
  return encodeURI(ORDER_DASHBOARD + "?userId=" + getUserId());
};

export const getOrderDetailUrl = (id: string): string => {
  return encodeURI(ORDERS + "/" + id);
};

export const getOrderRevenueUrl = (): string => {
  return encodeURI(ORDER_REVENUE + "?userId=" + getUserId());
};
export const getOrderAnalyticsUrl = (): string => {
  return encodeURI(ORDER_ANALYTICS + "?userId=" + getUserId());
};
export const getOrderTableDetail = (tableNo: string): string => {
  return encodeURI(TABLE_SCAN + "?tableNo=" + tableNo);
};
