import React from "react";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { Button, Icon, Typography } from "@material-ui/core";
import { FuseAnimate, FuseUtils } from "@fuse";
import AlertDialog from "app/main/common/AlertDialog";
import * as Actions from "app/store/actions";
import { useDispatch } from "react-redux";
import History from "@history";

const ComboDetailHeader = ({
  initialForm,
  form,
  onSaveCombo,
  disabled,
  page = 0,
}) => {
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };
  function goBack() {
    History.push({
      pathname: `/catalogue/combos`,
      state: { page },
    });
  }
  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex flex-col items-start max-w-full">
        <FuseAnimate animation="transition.slideRightIn" delay={300}>
          <Button onClick={previousPage}>
            <Icon className="mr-4 text-20">arrow_back</Icon>
            {languageStrings["COMBO_LIST"]}
          </Button>
        </FuseAnimate>

        <div className="flex items-center max-w-full">
          <div className="flex flex-col min-w-0">
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography className="text-16 sm:text-20 truncate">
                {form.id !== "0" ? form.title : languageStrings["NEW_COMBO"]}
              </Typography>
            </FuseAnimate>
            <FuseAnimate animation="transition.slideLeftIn" delay={300}>
              <Typography variant="caption">
                {languageStrings["COMBO_DETAIL"]}
              </Typography>
            </FuseAnimate>
          </div>
        </div>
      </div>
      <FuseAnimate animation="transition.slideRightIn" delay={300}>
        <Button
          className="whitespace-no-wrap"
          variant="contained"
          disabled={disabled}
          onClick={onSaveCombo}
        >
          {languageStrings["GENERAL.SAVE"]}
        </Button>
      </FuseAnimate>
    </div>
  );
};

export default ComboDetailHeader;
