import venue from "app/store/venues/VenuesReducer";
import draw from "app/store/draws/DrawReducer";
import { combineReducers } from "redux";
import auth from "app/store/auth/AuthReducer";
import user from "app/store/user/UserReducer";
import offer from "app/store/offers/OffersReducer";
import whatson from "app/store/whatson/WhatsonReducer";
import gallery from "app/store/gallery/GalleryReducer";
import menu from "app/store/menu/MenuReducer";
import loyalty from "app/store/loyalty/LoyaltyReducer";
import reservation from "app/store/reservation/ReservationReducer";
import notification from "app/store/notification/NotificationReducer";
import voucher from "app/store/voucher/VouchersReducer";
import catalogue from "app/store/catalogue/CatalogueReducer";
import discountVoucher from "app/store/dicountVoucher/DiscountVoucherReducer";
import order from "app/store/orders/OrdersReducer";
import scheduleOrder from "app/store/scheduleOrders/ScheduleOrdersReducer";
import payment from "app/store/payment/PaymentReducer";
import staff from "app/store/staff/StaffReducer";
import merchant from "app/store/merchant/MerchantReducer";
import VirtualCartOrder from "../virtualCardOrder/VirtualCardOrderReducer";
import tip from "../tip/TipReducer";
import rating from "../rating/RatingReducer";
import loyaltyProgram from "../loyaltyProgram/LoyaltyProgramReducer";
import pos from "../pos/PosReducer";
import paymentHistory from "../paymentHistory/paymentHistoryReducer";
import pagination from "../pagination/paginationReducer";
import menuV2 from "../menuV2/MenuV2Reducer";
import VenuePopupReducer from "../venuePopup/VenuePopupReducer";
import promotion from "app/store/promotion/PromotionsReducer";
import combos from "app/store/combos/CombosReducer";
import kiosk from "app/store/kiosk/KisokReducer";

const banzzuReducers = combineReducers({
  auth,
  venue,
  user,
  offer,
  whatson,
  gallery,
  menu,
  loyalty,
  reservation,
  notification,
  draw,
  voucher,
  order,
  payment,
  staff,
  merchant,
  discountVoucher,
  VirtualCartOrder,
  tip,
  rating,
  pos,
  paymentHistory,
  pagination,
  loyaltyProgram,
  catalogue,
  menuV2,
  VenuePopupReducer,
  promotion,
  combos,
  scheduleOrder,
  kiosk,
});

export default banzzuReducers;
