import { RATINGS, TIPS } from "app/api/Endpoint";
import { axiosInstance as axios } from "app/api/axios";
import { RatingActionTypes } from "../redux/actionTypes";
export function setRatingSearchText(event) {
  return {
    type: RatingActionTypes.SET_RATINGS_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}
export const getRatingList = (
  clientId,
  data,
) => {
  return (dispatch) => {
    dispatch({
      type: RatingActionTypes.RATINGS_LIST_START,
    });
    axios
      .get(`${RATINGS}/${clientId}`, { params: data })
      .then((res) => {
        if (res.data.statusCode === 200) {
          getRatingListSuccess(dispatch, res.data);
        } else {
          getRatingListFail(dispatch, res.data.errorMessage);
        }
      })
      .catch(() => {
        getRatingListFail(dispatch, "There was an error connection");
      });
  };
};

const getRatingListFail = (dispatch, errorMessage) => {
  dispatch({
    type: RatingActionTypes.RATINGS_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getRatingListSuccess = (dispatch, data) => {
  dispatch({
    type: RatingActionTypes.RATINGS_LIST_SUCCESS,
    payload: data,
  });
};
