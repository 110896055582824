import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import LoadingOverlay from "react-loading-overlay";
import { useSelector, useDispatch } from "react-redux";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import ListHeader from "app/main/common/List/ListHeader";
import {
  getPosCombos,
  getPosProductList,
  setPosSearchText,
} from "app/store/pos/PosActions";
import { Tab, Tabs } from "@material-ui/core";

const ProductList = () => {
  const { searchText, products, loading, combos } = useSelector(
    ({ banzzu }) => banzzu.pos
  );
  const [tabValue, setTabValue] = useState(0);

  const [selectedCategory, setSelectedCategory] = useState("0");
  const user = useSelector(({ banzzu }) => banzzu.auth.user);
  const dispatch = useDispatch();
  const _products =
    products && products.products && products.products.length
      ? products.products
      : [];
  const _combos =
    combos && combos.combos && combos.combos.length ? combos.combos : [];
  const _categories =
    products && products.categories && products.categories.length
      ? products.categories
      : [];

  const { languageStrings } = useLanguageHelper();
  useEffect(() => {
    if (user && user._id) {
      dispatch(
        getPosProductList(user._id, {
          fetch: false,
        })
      );
      dispatch(getPosCombos());
    }
  }, [user]);

  const setSearchText = (ev) => {
    dispatch(setPosSearchText(ev));
  };
  const filteredProducts = () => {
    if (selectedCategory === "0") {
      return _products;
    } else {
      const __ = _products.filter(
        (item) => item.categoryTitle === selectedCategory
      );
      return __;
    }
  };
  const filteredCombos = () => {
    if (selectedCategory === "0") {
      return _combos;
    } else {
      const __ = _combos.filter(
        (item) => item.categoryTitle === selectedCategory
      );
      return __;
    }
  };
  const searchBy =
    tabValue === 1
      ? ["posReference", "title"]
      : ["posReference", "title", "categoryTitle"];
  const headerRows = {
    data: [
      {
        id: "posReference",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.REF"],
        sort: true,
        type: "text",
      },
      {
        id: "title",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.TITLE"],
        sort: true,
        type: "text",
      },
      {
        id: "price",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.PRICE"],
        sort: true,
        type: "priceCell",
      },
      ...(tabValue !== 1
        ? [
            {
              id: "categoryTitle",
              align: "left",
              disablePadding: true,
              label: languageStrings["GENERAL.CATEGORY"],
              sort: true,
              type: "text",
            },
          ]
        : []),
    ],
    checkbox: false,
    clickable: false,
    locationSearch: false,
    searchBy,
  };

  const filterByStatus = (ev) => {
    const { value } = ev.target;
    setSelectedCategory(value);
  };
  const orderStatusValues = _categories.map((status) => {
    return { ...status, name: status.title };
  });
  const handleChangeTab = (event, tabValue) => {
    setTabValue(tabValue);
  };
  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
            }}
            header={
              <div
                className={"flex w-full items-center flex-row justify-between"}
              >
                {tabValue === 1 ? (
                  <ListHeader
                    title={languageStrings["MENU_CATEGORY_PAGE.COMBO_LIST"]}
                    setSearchText={setSearchText}
                    searchType={"pos"}
                    fetchButton
                    onFetchProducts={() => {
                      if (user && user._id)
                        dispatch(
                          getPosProductList(user._id, {
                            fetch: true,
                          })
                        );
                    }}
                  />
                ) : (
                  <ListHeader
                    title={languageStrings["MENU_CATEGORY_PAGE.PRODUCT_LIST"]}
                    setSearchText={setSearchText}
                    searchType={"pos"}
                    fetchButton
                    onFetchProducts={() => {
                      if (user && user._id)
                        dispatch(
                          getPosProductList(user._id, {
                            fetch: true,
                          })
                        );
                    }}
                    selectors={[
                      {
                        onChange: filterByStatus,
                        values: orderStatusValues,
                        keys: ["id", "name"],
                        title:
                          languageStrings["MENU_ITEM_LIST_PAGE.CATEGORIES"],
                        firstOption: languageStrings["ALL_CATEGORIES"],
                      },
                    ]}
                  />
                )}
              </div>
            }
            content={
              <>
                {tabValue === 0 && (
                  <ListTable
                    headerRows={headerRows}
                    data={filteredProducts()}
                    searchText={searchText}
                  />
                )}
                {tabValue === 1 && (
                  <ListTable
                    headerRows={headerRows}
                    data={filteredCombos()}
                    searchText={searchText}
                  />
                )}
              </>
            }
            contentToolbar={
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                classes={{ root: "w-full h-64" }}
              >
                <Tab
                  className={"h-64 normal-case"}
                  label={languageStrings["GENERAL_PRODUCTS"]}
                />
                <Tab
                  className={"h-64 normal-case"}
                  label={languageStrings["GENERAL_COMBOS"]}
                />
              </Tabs>
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
    </>
  );
};

export default ProductList;
