import KioskSettings from "./KioskSettings";

export const KioskSettingsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/kiosk-settings",
      component: KioskSettings,
    },
  ],
};
