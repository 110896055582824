import React, { useEffect, useState } from "react";
import { FusePageCarded } from "@fuse";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import ListHeader from "app/main/common/List/ListHeader";
import ListTable from "app/main/common/List/ListTable";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentHistoryList,
  paymentHistoryRefund,
  setPaymentHistorySearchText,
} from "app/store/paymentHistory/paymentHistoryActions";
import history from "@history";
import SplitBillModal from "../common/SplitBillModal";
import { Pagination } from "app/models/Pagination";
import DateRange from "./components/DateRange";
import { exportPaymentHistoryInExcel } from "./paymentExcelExport";

const _start = new Date();
_start.setMonth(_start.getMonth() - 1);

const VirtualCartOrderList = () => {
  const { languageStrings } = useLanguageHelper();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const {
    paymentList = [],
    loading,
    totalPaymentCountByClient,
    totalPayment,
    searchText,
  } = useSelector(({ banzzu }) => banzzu.paymentHistory);
  const [refund, setRefund] = useState({
    showSplitBillmodel: false,
    price: 0,
    refundedAmount: 0,
    orderId: "",
    isCashOrder: false,
  });
  useEffect(() => {
    const start = new Date();
    const end = new Date();
    start.setMonth(start.getMonth() - 12);
    dispatch(getPaymentHistoryList(searchText, "", ""));
  }, []);
  const handleClick = (item) => {
    history.push("/paymentDetail/" + item._id);
  };
  const onRefund = (data, event) => {
    let tipAmount = data && data.tipAmount > 0 ? data.tipAmount : 0;
    setRefund({
      showSplitBillmodel: true,
      price: Number(Number(data.price + tipAmount).toFixed(2)),
      refundedAmount: data && data.refundedAmount ? data.refundedAmount : 0,
      orderId: data._id,
      isCashOrder: data && data.paidBy && data.paidBy === 2 ? true : false,
    });
    event.stopPropagation();
  };
  const headerRows = {
    data: [
      {
        id: "referenceNo",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.REF"],
        sort: true,
        type: "text",
      },
      {
        id: "tableTitle",
        align: "left",
        disablePadding: true,
        label: languageStrings["VIRTUAL_CARD_ORDER_TABLE"],
        sort: true,
        type: "text",
      },
      {
        id: "totalPrice",
        align: "left",
        disablePadding: true,
        label: languageStrings["GENERAL.PRICE"],
        sort: true,
        type: "priceCell",
      },
      {
        id: "createdAt",
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.DATE"],
        sort: true,
        type: "time",
      },
      {
        id: [""],
        align: "left",
        disablePadding: false,
        label: languageStrings["GENERAL.ACTIONS"],
        actions: {
          text: languageStrings["REFOUND"],
          onclick: onRefund,
        },
        sort: true,
        type: "singleBtn",
      },
    ],
    checkbox: false,
    clickable: true,
    locationSearch: false,
    searchBy: [],
  };
  const setSearchText = (ev) => {
    dispatch(setPaymentHistorySearchText(ev));
  };
  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        text="Loading..."
      >
        <div style={{ flex: 1 }}>
          <FusePageCarded
            classes={{
              content: "flex",
              header: "min-h-72 h-72 sm:h-136 sm:min-h-136 items-center",
            }}
            header={
              <div className={"flex w-full flex-col"}>
                <div
                  className={
                    "flex w-full items-center flex-row justify-between"
                  }
                >
                  <ListHeader
                    title={languageStrings["SIDEBAR_MENUE.PAYMENT_HISTORY"]}
                    numberOfPayments={languageStrings["NUMBER_OF_PAYMENTS"]}
                    totalAmountOfPayments={languageStrings["TOTAL_AMOUNT"]}
                    setSearchText={setSearchText}
                    searchType={"paymentHistory"}
                    filterButton
                    onTipPress={() => {
                      setShowModal(true);
                    }}
                    exportButton
                    onExportFile={() => {
                      exportPaymentHistoryInExcel(paymentList, languageStrings);
                    }}
                    totalPaymentCountByClient={totalPaymentCountByClient}
                    totalPayment={totalPayment}
                  />
                </div>
                <div className="mt-4">
                  {languageStrings["RANGE"]}:{" "}
                  {startDate && endDate
                    ? `${moment(startDate).format("DD MMM")} - ${moment(
                        endDate
                      ).format("DD MMM")}`
                    : languageStrings["ALL_RESULTS"]}
                </div>
              </div>
            }
            content={
              <ListTable
                handleClick={handleClick}
                headerRows={headerRows}
                data={paymentList}
                searchText={""}
                listType={Pagination.PAYMENT_HISTORY}
              />
            }
            innerScroll
          />
        </div>
      </LoadingOverlay>
      <SplitBillModal
        orderId={refund.orderId}
        price={refund.price}
        showSplitModal={refund.showSplitBillmodel}
        refundedAmount={refund.refundedAmount}
        onCloseModal={() => {
          setRefund({
            showSplitBillmodel: false,
            price: 0,
            refundedAmount: 0,
          });
        }}
        onRefundPress={(amount, id) => {
          if (amount > 0 && id) dispatch(paymentHistoryRefund(amount, id));
        }}
      />
      <DateRange
        headerText={languageStrings["FILTER_RANGE"]}
        buttonText={languageStrings["APPLY"]}
        onDateSelect={(start, end) => {
          if (start && end) {
            setStartDate(start);
            setEndDate(end);
          }
        }}
        onCloseModal={() => {
          setShowModal(false);
        }}
        open={showModal}
      />
    </>
  );
};

export default VirtualCartOrderList;
