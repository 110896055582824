import History from "@history";
import { getOrderDetailUrl, getOrderListUrl } from "app/api/OrderEndpoints";
import { errorAlert } from "app/helpers/alerts";
import { axiosInstance as axios } from "app/api/axios";

import { VirtualCardOrdersType } from "../redux/actionTypes";
import {
  getPaidVirtualBasketOrderDetailUrl,
  getPaidVirtualBasketOrders,
  getUserId,
  PaidVirtualBasketOrders,
} from "app/api/Endpoint";

export function setVirtualCardOrderSearchText(event) {
  console.log({ search: event.target.value });

  return {
    type: VirtualCardOrdersType.SET_VIRTUAL_ORDER_SEARCH_TEXT,
    payload: {
      searchText: event.target.value,
    },
  };
}

export const getVirtualCardOrderList = (venues) => {
  return (dispatch) => {
    dispatch({
      type: VirtualCardOrdersType.VIRTUAL_ORDER_LIST_START,
    });

    const url = PaidVirtualBasketOrders;

    const body = {
      clientId: getUserId(),
      venueIds: venues,
    };
    axios
      .post(url, body)
      .then((res) => {
        console.log({ res: res.data });

        getVirtualCardOrderListSuccess(dispatch, res.data);
      })
      .catch((error) => {
        getVirtualCardOrderListFail(
          dispatch,
          error.response
            ? error.response.data.message
            : "There was an error connection"
        );
      });
  };
};

const getVirtualCardOrderListFail = (dispatch, errorMessage) => {
  dispatch({
    type: VirtualCardOrdersType.VIRTUAL_ORDER_LIST_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getVirtualCardOrderListSuccess = (dispatch, data) => {
  dispatch({
    type: VirtualCardOrdersType.VIRTUAL_ORDER_LIST_SUCCESS,
    payload: data,
  });
};

export const getVirtualCardOrderDetail = (id: string) => {
  return (dispatch) => {
    dispatch({
      type: VirtualCardOrdersType.VIRTUAL_ORDER_DETAIL_START,
    });

    const url = getPaidVirtualBasketOrderDetailUrl(id);

    axios
      .get(url)
      .then((res) => {
        console.log({ data: res.data });

        getVirtualCardOrderDetailSuccess(dispatch, res.data);
      })
      .catch((error) => {
        if (error.response && error.response === 401) {
          getVirtualCardOrderDetailFail(dispatch, "Unauthorized", error);
        }
        getVirtualCardOrderDetailFail(
          dispatch,
          "There was an error connection",
          error
        );
      });
  };
};

const getVirtualCardOrderDetailFail = (dispatch, errorMessage, error) => {
  dispatch({
    type: VirtualCardOrdersType.VIRTUAL_ORDER_DETAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
  errorAlert(dispatch, errorMessage);
  if (error.response && error.response.status === 401) {
    return;
  } else {
    History.push({
      pathname: `/virtualCartOrders`,
    });
  }
};

const getVirtualCardOrderDetailSuccess = (dispatch, data) => {
  dispatch({
    type: VirtualCardOrdersType.VIRTUAL_ORDER_DETAIL_SUCCESS,
    payload: data,
  });
};
