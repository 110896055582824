import React, { FC, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core";
import { useLanguageHelper } from "app/helpers/LanguageHelper";
import { TimeSlider } from "./TimeSlider";
import { timeList } from "./timeData";
import { PhonelinkLockOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  checked: {
    color: "#ff8330 !important",
  },
  formRoot: {
    "&$checked": {
      color: "#ff8330",
    },
  },
  rootSlider: {
    color: "#ff8330",
  },
}));
interface DateRange {
  start: string;
  end: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}
interface IProps {
  tabs: DateRange[];
  tabValue: number;
  selectedTabValue: (tab: number) => void;
  handleChange: (newData: DateRange, tab: number) => void;
  onDelete: (index: number) => void;
}
const VisibilityTabs: FC<IProps> = ({
  tabs = [],
  tabValue,
  selectedTabValue,
  handleChange,
  onDelete,
}) => {
  const classes = useStyles();
  const { languageStrings } = useLanguageHelper();
  const [updatedHours] = useState(timeList);
  const getValues = () => {
    let data = {
      start: "00:00",
      end: "24:00",
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    };
    const findData: DateRange =
      tabValue >= 0 && tabs && tabs.length && tabs[tabValue]
        ? tabs[tabValue]
        : null;

    if (findData && findData.start && findData.end) {
      data.start = findData.start;
      data.end = findData.end;
      data.monday = !!findData.monday;
      data.tuesday = !!findData.tuesday;
      data.wednesday = !!findData.wednesday;
      data.thursday = !!findData.thursday;
      data.friday = !!findData.friday;
      data.saturday = !!findData.saturday;
      data.sunday = !!findData.sunday;
    }
    return data;
  };
  const timeData = getValues();

  const handleSliderChange = (
    _: React.ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    _.stopPropagation();
    const existingValues = getValues();
    const newData = {
      ...existingValues,
      start: updatedHours[newValue[0]],
      end: updatedHours[newValue[1]],
    };
    handleChange && handleChange(newData, tabValue);
  };
  const onDayChange = (day: string) => {
    const existingValues = getValues();

    const newData = {
      ...existingValues,
      [`${day}`]: !existingValues[`${day}`],
    };
    handleChange && handleChange(newData, tabValue);
  };
  const hasVisibility = !!(tabs && tabs.length > 1);
  return (
    <>
      <Tabs
        value={tabValue}
        onChange={(e, newValue) => {
          selectedTabValue(newValue);
        }}
        indicatorColor="secondary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        classes={{ root: "w-full mb-10" }}
      >
        {tabs.map((_, index) => {
          return (
            <Tab
              icon={
                hasVisibility ? (
                  <IconButton
                    onClick={() => onDelete && onDelete(index)}
                    className="ml-2 h-12 w-12"
                  >
                    <Icon color="error" className="text-22">
                      delete_outline
                    </Icon>
                  </IconButton>
                ) : null
              }
              className="h-16 normal-case min-w-28 mr-10 border-0	"
              label={`${languageStrings["VISIBILITY"]} ${index + 1}`}
            />
          );
        })}
      </Tabs>
      <div>
        <TimeSlider
          start={timeData.start}
          end={timeData.end}
          onChange={handleSliderChange}
        />

        <div className="flex">
          <FormControlLabel
            labelPlacement="end"
            className="w-1/2"
            name="monday"
            classes={{ root: classes.formRoot }}
            control={
              <Checkbox
                classes={{ checked: classes.checked }}
                disableRipple
                checked={timeData.monday}
                onChange={() => {
                  onDayChange("monday");
                }}
                color="primary"
              />
            }
            label={languageStrings["GENERAL.MONDAY"]}
          />
          <FormControlLabel
            labelPlacement="end"
            className="w-1/2"
            name="tuesday"
            classes={{ root: classes.formRoot }}
            control={
              <Checkbox
                classes={{ checked: classes.checked }}
                disableRipple
                onChange={() => {
                  onDayChange("tuesday");
                }}
                color="primary"
                checked={timeData.tuesday}
              />
            }
            label={languageStrings["GENERAL.TUESDAY"]}
          />
        </div>
        <div className="flex">
          <FormControlLabel
            labelPlacement="end"
            className="w-1/2"
            name="wednesday"
            classes={{ root: classes.formRoot }}
            control={
              <Checkbox
                classes={{ checked: classes.checked }}
                checked={timeData.wednesday}
                disableRipple
                onChange={() => {
                  onDayChange("wednesday");
                }}
                color="primary"
              />
            }
            label={languageStrings["GENERAL.WEDNESDAY"]}
          />
          <FormControlLabel
            labelPlacement="end"
            className="w-1/2"
            name="thursday"
            classes={{ root: classes.formRoot }}
            control={
              <Checkbox
                classes={{ checked: classes.checked }}
                disableRipple
                checked={timeData.thursday}
                onChange={() => {
                  onDayChange("thursday");
                }}
                color="primary"
              />
            }
            label={languageStrings["GENERAL.THURSDAY"]}
          />
        </div>
        <div className="flex">
          <FormControlLabel
            labelPlacement="end"
            className="w-1/2"
            name="friday"
            classes={{ root: classes.formRoot }}
            control={
              <Checkbox
                classes={{ checked: classes.checked }}
                disableRipple
                onChange={() => {
                  onDayChange("friday");
                }}
                color="primary"
                checked={timeData.friday}
              />
            }
            label={languageStrings["GENERAL.FRIDAY"]}
          />
          <FormControlLabel
            labelPlacement="end"
            className="w-1/2"
            name="saturday"
            classes={{ root: classes.formRoot }}
            control={
              <Checkbox
                classes={{ checked: classes.checked }}
                disableRipple
                onChange={() => {
                  onDayChange("saturday");
                }}
                color="primary"
                checked={timeData.saturday}
              />
            }
            label={languageStrings["GENERAL.SATURDAY"]}
          />
        </div>
        <FormControlLabel
          labelPlacement="end"
          className="w-1/2"
          name="sunday"
          classes={{ root: classes.formRoot }}
          control={
            <Checkbox
              checked={timeData.sunday}
              classes={{ checked: classes.checked }}
              disableRipple
              onChange={() => {
                onDayChange("sunday");
              }}
              color="primary"
            />
          }
          label={languageStrings["GENERAL.SUNDAY"]}
        />
      </div>
    </>
  );
};

export default VisibilityTabs;
