import React, { useEffect, useState } from "react";
import {
  Icon,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Button,
  TextField,
  TextareaAutosize,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { FuseAnimate, FusePageCarded, FuseUtils } from "@fuse";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import LoadingOverlay from "react-loading-overlay";
import _ from "@lodash";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useForm, useDebounce } from "@fuse/hooks";
import MenuInformationModal from "../common/MenuInformationModal";
import { updateUserGoals } from "app/store/auth/LoginActions";
import LanguageDropdown from "./menuDetailV2Pro/components/LanguageDropdownV2";
import { getCatalogCombosList } from "app/store/catalogue/CatalogueActions";
import CategoryItem from "./menuDetailV2Pro/CategoryItemV2";
import MenuDetailForm from "./menuDetailV2Pro/MenuDetailFormV2";
import History from "@history";
import { getPosProductList } from "app/store/pos/PosActions";
import {
  handleMenuTitle,
  setDefaultLanguage,
  setDefaultLanguageV2,
  formatImageUrl,
} from "app/helpers/utilsHelper";
import {
  createMenuV2Category,
  editMenuV2Category,
  getMenuItemCategoryV2,
  getMenuItemV2List,
  getMenuV2Detail,
  sortMenuListV2Category,
} from "app/store/menuV2/MenuV2Actions";
import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";
import { getMenuItemAllergenes } from "app/store/menu/MenuActions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperN: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 1024,
  },
}));

const MenuDetailProV2 = ({ match }) => {
  const classes = useStyles();
  const language = getStorageItem(STORAGE_KEY.LOCALE);
  let loading = useSelector(({ banzzu }) => banzzu.menuV2.loading);
  const catalogComboLoading = useSelector(
    ({ banzzu }) => banzzu.catalogue.loading
  );
  const comboLoading = useSelector(({ banzzu }) => banzzu.combos.loading);
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  let { user } = useSelector(({ banzzu }) => banzzu.auth);
  const { goals, kioskEnabled } = user;
  const { languageStrings } = useLanguageHelper();
  const [open, setOpen] = useState(false);
  const [newCreated, setNewCreated] = useState(false);
  const [openInformationModal, setOpenInformationModal] = useState(false);
  const [isMenuUrl, setIsMenuUrl] = useState(false);
  const { locale = "es", settings = {} } = user || {};
  const { form, setForm } = useForm({
    languages: {},
    type: "1",
    title: "",
    description: "",
    image: null,
    imageFile: null,
  });
  const [getCurrentVenues, setCurrentVenues] = useState([]);
  const defaultLanguage = setDefaultLanguageV2(locale);
  const getInitialSelectedLanguage = () => defaultLanguage.code;

  const [selectedLanguage, setSelectedLanguage] = useState(
    getInitialSelectedLanguage
  );

  const isDefaultLanguage = setDefaultLanguage(selectedLanguage, locale);
  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemCategory
  );
  const menuDetail = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  const availableLanguages = (menuDetail && menuDetail.selectedLanguages) || [
    "Spanish",
  ];
  const [isMenuDivided, setIsMenuDivided] = useState(false);
  const [onlyRead, setOnlyRead] = useState(menuDetail.onlyRead);
  const [list, setList] = useState(categoryList);
  const dispatch = useDispatch();
  const { productId } = match.params;

  useEffect(() => {
    setList(categoryList);
  }, [categoryList]);
  useEffect(() => {
    if (user && user._id)
      dispatch(
        getPosProductList(user._id, {
          fetch: false,
        })
      );
  }, [user]);
  useEffect(() => {
    dispatch(getMenuItemAllergenes());
  }, []);
  useEffect(() => {
    if (
      goals &&
      goals.firstView == false &&
      locale !== "fr" &&
      locale !== "en"
    ) {
      setOpenInformationModal(true);
    }
  }, [goals, locale]);
  useEffect(() => {
    setOnlyRead(menuDetail.onlyRead);
  }, [menuDetail]);

  useEffect(() => {
    setIsMenuDivided(menuDetail.isCategoryDivide);
  }, [dispatch, menuDetail]);

  useEffect(() => {
    if (productId != "new") {
      dispatch(getMenuItemCategoryV2(productId));
      dispatch(getMenuItemV2List(productId));
      dispatch(getMenuV2Detail(productId));
      dispatch(getCatalogCombosList(productId));
    }
  }, [dispatch, productId]);
  useEffect(() => {
    if (venueList && menuDetail.venues) {
      let hasMenuUrl = false;
      venueList.forEach((venue) => {
        if (venue.menuType === "1" && menuDetail.venues.includes(venue._id)) {
          hasMenuUrl = true;
        }
      });
      setIsMenuUrl(hasMenuUrl);
    }
  }, [venueList, isMenuUrl, menuDetail]);

  useEffect(() => {
    const setInitialLanguage = async () => {
      const language = await setDefaultLanguageV2(locale);
      setSelectedLanguage(language.code);
    };
    setInitialLanguage();
  }, [locale]);

  const dirtyInitial = {
    title: false,
    // description: false,
  };

  const errorInitial = {
    title: "",
    // description: "",
  };

  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);

  function handleTitleChange(language, field, value) {
    setForm((prevForm) => ({
      ...prevForm,
      languages: {
        ...prevForm.languages,
        [language]: {
          ...prevForm.languages[language],
          [field]: value,
        },
      },
    }));

    debouncedTitle(value);
  }

  const handleTypeChange = (event) => {
    const _foodType = event.target.value;
    setForm((form) => ({
      ...form,
      type: _foodType,
    }));
  };

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return { ...prevState, title: "Minimum size 3 characters" };
      });
    }
  }, 500);

  const previousPage = () => {
    History.push({
      pathname: `/menuV2/menuList`,
    });
  };
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const handleCategoryTitle = (categoryId, language) => {
    const category = categoryList.find((item) => item._id === categoryId);
    const languageTitle = handleMenuTitle(category, language, locale);
    form.title = languageTitle.title;
    form.description = languageTitle.description;
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let sortOrder = {
      from: source.index,
      to: destination.index,
      id: draggableId,
      menuId: productId,
    };

    const { from, to } = sortOrder;

    const sortedCategoryList = [...list];
    let movedMenu = sortedCategoryList.splice(from, 1);
    sortedCategoryList.splice(to, 0, _.first(movedMenu));
    setList(sortedCategoryList);

    dispatch(sortMenuListV2Category(sortOrder, productId));
  };
  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };
      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }
  return (
    <>
      <MenuInformationModal
        open={openInformationModal}
        setOpen={() => {
          setOpenInformationModal(false);
        }}
        updateUserGoals={updateUserGoals}
        user={user}
      />
      <LoadingOverlay
        active={loading || catalogComboLoading || comboLoading}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            zIndex: 9999,
          },
          overlay: {
            position: "fixed",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            display: "flex",
            textAlign: "center",
            fontSize: "1.2em",
            color: "#FFF",
            background: "rgba(0,0,0,0.7)",
            zIndex: "1400",
            transition: "opacity 500ms ease-in",
            opacity: 1,
            zIndex: 9999,
          },
        }}
        text="Loading..."
      >
        <FusePageCarded
          classes={{
            toolbar: "p-0",
            header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
          }}
          header={
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={() => previousPage()}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["MENU_DETAIL_PAGE.BACK_BUTTON"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["MENU_DETAIL_PAGE.NEW_MENUE"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["MENU_DETAIL_PAGE.TITLE"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              {onlyRead && (
                <Typography variant="h5">
                  {languageStrings["MENU_DETAIL_PAGE.MENU_SHARED"]}
                </Typography>
              )}
              {language === "es" ? (
                <Typography
                  className={"cursor-pointer"}
                  variant="caption"
                  onClick={() => {
                    setOpenInformationModal(true);
                  }}
                >
                  {languageStrings["MENU_DETAIL_PAGE.HELP"]}
                </Typography>
              ) : null}
            </div>
          }
          content={
            <div className="p-16 sm:p-24 w-full">
              <div className="justify-center sm:justify-start">
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={() => setOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paperN}>
                      <h2 id="transition-modal-title" className="pb-32">
                        {languageStrings["MENU_CATEGORY_PAGE.ADD_CATEGORY"]}
                      </h2>
                      <p id="transition-modal-description">
                        <LanguageDropdown
                          languages={availableLanguages}
                          onChange={handleLanguageChange}
                          selectedLanguage={selectedLanguage}
                          menuElement={form && form._id ? form._id : ""}
                          handleLanguageTitle={handleCategoryTitle}
                        />
                        <TextField
                          className="mt-8 mb-16 mr-8"
                          required
                          label={languageStrings["GENERAL.NAME"]}
                          id="title"
                          name="title"
                          value={
                            form.languages &&
                            form.languages[selectedLanguage] &&
                            form.languages[selectedLanguage].title !== undefined
                              ? form.languages[selectedLanguage].title
                              : form.title
                          }
                          onlyRead={onlyRead}
                          onChange={(e) =>
                            handleTitleChange(
                              selectedLanguage,
                              "title",
                              e.target.value
                            )
                          }
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            maxLength: 100,
                          }}
                        />
                        <TextareaAutosize
                          className="mt-8 mb-16 mr-8 w-full"
                          aria-label="minimum height"
                          minRows={3}
                          placeholder={languageStrings["GENERAL.DESCRIPTION"]}
                          label={languageStrings["GENERAL.DESCRIPTION"]}
                          id="description"
                          name="description"
                          value={
                            form.languages &&
                            form.languages[selectedLanguage] &&
                            form.languages[selectedLanguage].description !==
                              undefined
                              ? form.languages[selectedLanguage].description
                              : form.description
                          }
                          onChange={(e) =>
                            handleTitleChange(
                              selectedLanguage,
                              "description",
                              e.target.value
                            )
                          }
                          inputProps={{
                            maxLength: 100,
                          }}
                          style={{
                            borderWidth: "1px",
                            minHeight: "8rem",
                            maxHeight: "8rem",
                            borderRadius: "5px",
                            padding: "5px",
                            borderColor: "#B8C2CC",
                          }}
                        />
                        {isMenuDivided && (
                          <div className="mb-20">
                            <label className="mr-10 font-bold">
                              {languageStrings["GENERAL.TYPE"]}:
                            </label>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              className="flex flex-row"
                              value={form && form.type ? form.type : ""}
                              onChange={handleTypeChange}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={languageStrings["FOOD"]}
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label={languageStrings["DRINKS"]}
                              />
                            </RadioGroup>
                          </div>
                        )}
                      </p>
                      {kioskEnabled && (
                        <div className="flex flex-row justify-center sm:justify-start flex-wrap">
                          <input
                            accept="image/jpg, image/jpeg, image/png, image/gif"
                            className="hidden"
                            id={`button-file-${productId}`}
                            type="file"
                            onChange={handleUploadChange}
                          />
                          <label
                            htmlFor={`button-file-${productId}`}
                            className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                          >
                            <Icon fontSize="large" color="action">
                              cloud_upload
                            </Icon>
                          </label>
                          <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                            <img
                              className="max-w-none w-auto h-full"
                              alt="whatson"
                              src={
                                form.image
                                  ? formatImageUrl(form.image)
                                  : "assets/images/avatars/noimage.png"
                              }
                            />
                          </div>
                        </div>
                      )}
                      <Button
                        className="whitespace-no-wrap"
                        variant="contained"
                        hidden={onlyRead ? true : false}
                        onClick={() => {
                          if (form._id === undefined) {
                            dispatch(
                              createMenuV2Category(
                                productId,
                                form.title,
                                form.description,
                                categoryList.length,
                                form.type,
                                form.languages,
                                locale,
                                form.imageFile
                              )
                            );
                            setNewCreated(true);
                            setSelectedLanguage(defaultLanguage.code);
                          } else {
                            dispatch(
                              editMenuV2Category(
                                form._id,
                                form.title,
                                productId,
                                form.description,
                                selectedLanguage,
                                isDefaultLanguage,
                                form.type,
                                form.languages,
                                locale,
                                form.imageFile
                              )
                            );
                          }
                          setForm({
                            languages: {},
                            type: "1",
                          });
                          setOpen(false);
                          setSelectedLanguage(defaultLanguage.code);
                        }}
                      >
                        {languageStrings["GENERAL.SAVE"]}
                      </Button>
                    </div>
                  </Fade>
                </Modal>
                <MenuDetailForm
                  menuId={productId}
                  onVenuePress={(val) => {
                    setCurrentVenues(val);
                  }}
                  isMenuUrl={isMenuUrl}
                />
                {productId !== "new" && (
                  <>
                    {settings &&
                    settings.integrationType !== "deliverect" &&
                    !isMenuUrl ? (
                      <>
                        <Typography
                          variant="h5"
                          component="h4"
                          className="mb-16"
                        >
                          {languageStrings["MENU"]}
                        </Typography>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <DropeableCategory
                            form={form}
                            productId={productId}
                            categoryList={list}
                            setOpen={setOpen}
                            onlyRead={onlyRead}
                            newCreated={newCreated}
                            currentVenues={getCurrentVenues}
                            setSelectedLanguage={setSelectedLanguage}
                            defaultLanguage={defaultLanguage}
                          />
                        </DragDropContext>

                        <Button
                          className="mt-16 bg-grey-lightest"
                          variant="contained"
                          onClick={() => {
                            setForm({
                              languages: {},
                              type: "1",
                              title: "",
                              description: "",
                            });
                            setOpen(true);
                          }}
                          hidden={onlyRead ? true : false}
                        >
                          <Icon
                            className={clsx(
                              classes.leftIcon,
                              classes.iconSmall
                            )}
                          >
                            save
                          </Icon>
                          {languageStrings["MENU_CATEGORY_PAGE.ADD_CATEGORY"]}
                        </Button>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          }
          innerScroll
        />
      </LoadingOverlay>
    </>
  );
};

const DropeableCategory = ({
  categoryList,
  productId,
  form,
  setOpen,
  onlyRead,
  newCreated,
  currentVenues,
  setSelectedLanguage,
  defaultLanguage,
  setOpenComboModal,
}) => {
  return (
    <Droppable droppableId="list">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {categoryList.map((category, index) => {
            if (categoryList.length == index + 1) {
            }
            return (
              <CategoryItem
                key={category._id}
                index={index}
                category={category}
                productId={productId}
                onlyRead={onlyRead}
                defaultExpanded={
                  newCreated && categoryList.length == index + 1 ? true : false
                }
                onClick={() => {
                  form._id = category._id;
                  form.title = category.title;
                  form.description = category.description;
                  form.image = category.image ? category.image : null;
                  setSelectedLanguage(defaultLanguage.code);
                  form.type = category.type ? category.type : "1";
                  setOpen(true);
                }}
                currentVenues={currentVenues}
              />
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default withReducer("GalleryReducer", reducer)(MenuDetailProV2);
