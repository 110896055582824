import React, { Component } from "react";
import { connect } from "react-redux";
import { axiosInstance as axios } from "app/api/axios";

import english from "./Languages/english";
import spanish from "./Languages/spanish";
import { LanguageContext } from "./LanguageContext";
import { changeUserLanguage } from "../../store/auth/LoginActions";
import french from "./Languages/french";
import chinese from "./Languages/chinese";
import { STORAGE_KEY, setStorageItem } from "../storageHelper";
interface OwnProps {
  initialLocale: string;
  children?: any;
}

interface State {
  locale: string;
  messages: any;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
class LanguageProvider extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      locale: this.props.initialLocale || "en",
      messages: english,
    };
  }

  componentDidUpdate(prevProps) {
    const { locale } = prevProps;
    if (this.props.locale && locale !== this.props.locale) {
      this.chooseLanguage(this.props.locale);
    }
  }

  chooseLanguage = (locale) => {
    locale = locale.toLowerCase();
    setStorageItem(STORAGE_KEY.LOCALE, locale);
    axios.defaults.headers.common["Accept-Language"] = locale;
    switch (locale) {
      case "en":
        this.setState({ messages: english });
        break;
      case "es":
        this.setState({ messages: spanish });
        break;
      case "fr":
        this.setState({ messages: french });
        break;
      case "zh":
        this.setState({ messages: chinese });
        break;
      default:
        break;
    }
  };

  changeLocale = (locale, changeOnFrontend) => {
    locale = locale.toLowerCase();
    this.chooseLanguage(locale);

    if (!changeOnFrontend) {
      this.props.changeUserLanguage(locale);
    }
  };

  render() {
    const { children } = this.props;
    return (
      <LanguageContext.Provider
        value={{
          locale: this.state.locale,
          changeLocale: this.changeLocale,
          messages: this.state.messages,
        }}
      >
        {children}
      </LanguageContext.Provider>
    );
  }
}

const mapStateToProps = ({ banzzu }) => {
  return {
    locale: banzzu.auth.user.locale,
  };
};

const mapDispatchToProps = {
  changeUserLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
