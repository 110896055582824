import React from "react";

import { Card, Typography, Button } from "@material-ui/core";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import useClientCurrency from "app/hooks/useClientCurrency";

const QuantityCard = ({
  onSubmit,
  increaseQuantity,
  decreaseQuantity,
  quantity,
  price,
  title,
  showText,
}) => {
  const { languageStrings } = useLanguageHelper();
  const { clientCurrency } = useClientCurrency();
  return (
    <Card
      className="mx-24 mt-12 p-24 flex flex-col items-center justify-around shadow-lg md:w-1/3 w-full"
      style={{ borderRadius: "2rem" }}
    >
      {showText && (
        <Typography variant="body1" className="text-center px-32">
          {languageStrings["PRICING.YOU_WILL_NOT_BE"]}
        </Typography>
      )}
      <Typography
        variant="h6"
        className="font-bold text-center"
        style={{ color: "#f5aa00" }}
      >
        {title}
      </Typography>
      <div className="flex flex-row items-center justify-around w-full">
        <RemoveCircle
          className="cursor-pointer"
          style={{ color: "#f5aa00" }}
          onClick={decreaseQuantity}
        />
        <Typography variant="h5">{quantity}</Typography>
        <AddCircle
          className="cursor-pointer"
          style={{ color: "#f5aa00" }}
          onClick={increaseQuantity}
        />
      </div>

      <div className="flex flex-row items-center justify-around w-full">
        <Typography variant="subtitle1" style={{ color: "#f5aa00" }}>
          Total
        </Typography>
        <Typography variant="h5">
          {price} {clientCurrency.symbol} / {languageStrings["PAYMENT_PAGE.MONTH"]}
        </Typography>
      </div>
      <Button
        variant="contained"
        className={"mt-24"}
        style={{ backgroundColor: "#f5aa00", color: "white" }}
        onClick={onSubmit}
      >
        {languageStrings["REGISTER_STEPS.PROCEED"]}
      </Button>
    </Card>
  );
};
export default QuantityCard;
