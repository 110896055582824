import { SearchableSelect } from "app/main/common/SearchableSelect";
import * as React from "react";

export default function InputDropDown({
  handleChange,
  selectedValue = "",
  clientsList = [],
  label = "Product List",
  keyPropFn,
  valuePropFn,
}) {
  return (
    <SearchableSelect
      removeSelectionText=""
      maxVisibleOptions={20}
      label={label}
      value={selectedValue}
      onChange={handleChange}
      options={clientsList}
      keyPropFn={keyPropFn}
      valuePropFn={valuePropFn}
    />
  );
}
