import React, { FC } from "react";

interface Props {
  title?: string;
  classes?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  value?: string;
  txtColor?: string;
  backgroundColor?: string;
  color?: string;
  style?: any;
  icon?: any;
  fontSize?: any;
}

const RoundedButton: FC<Props> = ({
  title,
  onClick,
  classes,
  disabled,
  value,
  backgroundColor,
  color,
  style,
  icon,
  fontSize,
  txtColor,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: disabled ? "#c3c3c3" : backgroundColor,
        color,

        ...style,
      }}
      className={[classes, "cursor-pointer outline-none"].join(" ")}
      value={value}
      disabled={disabled}
    >
      {icon}
      <h6
        style={{
          fontSize: fontSize ? fontSize : "17px",
          color: txtColor ? txtColor : "white",
        }}
      >
        {title}
      </h6>
    </button>
  );
};

export default RoundedButton;
