import React from "react";
import { axiosInstance as axios } from "app/api/axios";

import history from "@history";
import { STORAGE_KEY, setStorageItem } from "app/helpers/storageHelper";

const DirectLogin = (props) => {
  const { accessToken, userId, masterId } = props.match.params;
  if (accessToken && userId) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    setStorageItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
    setStorageItem(STORAGE_KEY.USER_ID, userId);
    setStorageItem(STORAGE_KEY.MASTER_ID, masterId ? masterId : 0);
    history.replace({
      pathname: "/home",
    });
  }

  return <></>;
};

export default DirectLogin;
