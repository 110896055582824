export enum Pagination {
  ORDERS = 1,
  PAYMENT_HISTORY = 2,
  VIRTUAL_CART = 3,
  GENERAL = 4,
  LOYALTY = 5,
  TIP = 6,
  RATING = 7,
}

export interface PaginationParams {
  limit: string | number;
  page: string | number;
}
