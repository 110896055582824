import { orderStatuses } from "app/main/orders/components/OrdersStatus";
import moment from "moment";
import _ from "@lodash";
import { orderTypes } from "app/main/orders/components/OrdersType";
import { utils, writeFile } from "xlsx";
import { languagesListData } from "app/main/menu/menuDetailPro/components/data";
import { promotionTypes } from "app/main/promotion/components/PromotionForm";

export function formatImageUrl(imageUrl) {
  return (
    imageUrl &&
    typeof imageUrl === "string" &&
    imageUrl.replace(
      "http://server.banzzu.com:8003/",
      "https://server.banzzu.com:8009/"
    )
  );
}

export const getOrderType = (orderType: string): string => {
  switch (orderType) {
    case "0":
      return "ORDER_LIST_PAGE.ALL_TYPES";
    case "1":
      return "ORDER_TYPE.COLLECT";
    case "2":
      return "ORDER_TYPE.DELIVERY";
    case "3":
      return "MENU_DETAIL_PAGE.ORDER_FROM_TABLE";
    default:
      return "";
  }
};
export const filterOrderList = (
  startDate = new Date(),
  endDate = new Date(),
  venue = null,
  orderList = []
) => {
  let _data = [];
  orderList.map((ord) => {
    const compareDate = moment(ord.createdAt);
    if (compareDate.isBetween(startDate, endDate, "days", "[]")) {
      _data.push(ord);
    }
  });

  if (venue && venue !== "All Venues") {
    _data = _data.filter((item) => item.venueTitle === venue);
  }

  return _data;
};

export const getOrderStatus = (orderStatus = "1") => {
  const _status = _.find(orderStatuses, { id: Number(orderStatus) });
  return _status.name;
};
export const convertCommaToDecimal = (amount: string) => {
  let _price = 0;
  if (amount && amount.includes(",")) {
    _price = Number(amount.replace(/,/g, "."));
  } else {
    _price = Number(amount);
  }
  return _price;
};
export const getTipAmount = (order = []) => {
  let totalTip = 0;
  for (let i = 0; i < order.length; i++) {
    const orderDetail = order[i];
    if (orderDetail && orderDetail.tip && orderDetail.tip.amount)
      totalTip += orderDetail.tip.amount;
  }

  return { totalTip: totalTip > 0 ? totalTip.toFixed(2) : 0 };
};
export const getLabelWith = (label = "", val = 6.8) => {
  let labelWith = 100;
  if (label) labelWith = label.length * val;
  return { labelWith };
};
export const filterTipsByDate = (
  startDate = new Date(),
  endDate = new Date(),
  tips = []
) => {
  let filteredTips = [];
  let totalTip = 0;

  tips.map((ord) => {
    const compareDate = moment(ord.createdAt);
    if (compareDate.isBetween(startDate, endDate, "days", "[]")) {
      filteredTips.push(ord);
      if (ord && ord.price && Number(ord.price)) {
        totalTip += Number(ord.price);
      }
    }
  });

  return { filteredTips, totalTip: Number(totalTip.toFixed(2)) };
};
export const exportOrders = (orderList = [], languageStrings) => {
  if (!languageStrings) return null;
  const isScheduledOrderVenue =
    orderList && orderList.some((venue) => venue.scheduleOrderCreationDate);

  const data = orderList.map((ord) => {
    const {
      referenceNo = "",
      venueTitle = "",
      type = "",
      createdAt = new Date(),
      orderStatus = "",
      price = 0,
      paidBy = 0,
      partialPayByCash = false,
      refundedAmount = 0,
      scheduleOrderCreationDate = null,
    } = ord || {};
    const orderTypeText = orderTypes.filter((item) => item.id === Number(type));
    const typeText =
      orderTypeText &&
      orderTypeText.length &&
      orderTypeText[0] &&
      orderTypeText[0].name
        ? orderTypeText[0].name
        : "";

    let paidByMessage;
    if (paidBy === 1 || paidBy === "1") {
      paidByMessage = languageStrings["GENERAL_CASH"];
    } else if ((paidBy === 2 || paidBy === "2") && !partialPayByCash) {
      paidByMessage = languageStrings["PAID_ON_CASH"];
    } else if (partialPayByCash) {
      paidByMessage = languageStrings["PARTIAL_PAYMENT_CASH"];
    } else {
      paidByMessage = languageStrings["PAY_BY_CARD"];
    }
    const data = {
      [languageStrings["ORDER_PAGE.ID"]]: referenceNo,
      [languageStrings["PAYMENT_PAGE.RESTAURANT"]]: venueTitle,
      [languageStrings["GENERAL.ORDER_TYPE"]]: languageStrings[typeText],
      [languageStrings["GENERAL.DATE"]]: moment(createdAt).format("DD/MM/YYYY"),
      [languageStrings["GENERAL.TIME"]]: moment(createdAt).format("HH:mm"),
      [languageStrings["GENERAL.STATUS"]]:
        languageStrings[getOrderStatus(orderStatus)],
      [languageStrings["GENERAL.PAYMENT_METHOD"]]:
        orderStatus === -1 || orderStatus === "-1" ? "" : paidByMessage,
      [languageStrings["REFUNDED_AMOUNT"]]: Number(refundedAmount),
      [languageStrings["GENERAL.PRICE"]]: Number(price),
    };
    if (isScheduledOrderVenue) {
      data[languageStrings["SCHEDULE_ORDER_CREATION_DATE"]] =
        scheduleOrderCreationDate
          ? moment(scheduleOrderCreationDate).format("DD/MM/YYYY")
          : "";

      data[languageStrings["SCHEDULE_ORDER_CREATION_TIME"]] =
        scheduleOrderCreationDate
          ? moment(scheduleOrderCreationDate).format("HH:mm")
          : "";
    }
    return data;
  });
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  writeFile(workbook, "order-list-file.xlsx");
};

const splitName = (name = "") => {
  const match = name.match(/([^0-9.]+)?([0-9.]+)?/);

  const nonNumericPart = (match && match[1]) || "";
  const numericPart = (match && match[2]) || "";

  return [nonNumericPart, numericPart];
};

export const sortTitle = (a, b) => {
  const nameA = a.title.toLowerCase();
  const nameB = b.title.toLowerCase();

  const [nonNumericPartA, numericPartA] = splitName(nameA);
  const [nonNumericPartB, numericPartB] = splitName(nameB);

  const nonNumericComparison = nonNumericPartA.localeCompare(nonNumericPartB);
  if (nonNumericComparison !== 0) {
    return nonNumericComparison;
  }

  const numericA = parseFloat(numericPartA) || 0;
  const numericB = parseFloat(numericPartB) || 0;

  return numericA - numericB;
};

export const getPaginationType = (
  pagination = {},
  listType: number | string
) => {
  let paginationType;
  let listLimit = 10;
  let listPage = 0;
  if ((listType === 2 || listType === 3 || listType === 5) && pagination) {
    if (listType === 2) {
      paginationType = "paymentHistoryList";
    } else if (listType === 3) {
      paginationType = "virtualCartList";
    } else if (listType === 5) {
      paginationType = "loyaltyList";
    }
    listLimit = pagination[paginationType].limit || 10;
    listPage = pagination[paginationType].page || 0;
  }
  return { listLimit, listPage };
};

export const handleMenuTitle = (
  menuItem,
  language = "1",
  locale,
  posTitle = ""
) => {
  const languageValue = Number(language);
  const isDefault = setDefaultLanguage(languageValue, locale);
  let title;
  let description;
  const defaultTitle = (menuItem && menuItem.title) || "";
  const defaultDescription = (menuItem && menuItem.description) || "";

  if (!isDefault && menuItem) {
    const languageTitle =
      menuItem && menuItem.multiTitle
        ? menuItem.multiTitle.find((item) => item.language === languageValue)
        : {};
    const languageDescription =
      menuItem && menuItem.multiDescription
        ? menuItem.multiDescription.find(
            (item) => item.language === languageValue
          )
        : {};
    title = (languageTitle && languageTitle.title) || defaultTitle;
    description =
      languageDescription && languageDescription.description !== undefined
        ? languageDescription.description
        : defaultDescription;
  } else if (isDefault && posTitle) {
    title = (menuItem && menuItem.title) || posTitle;
    description = (menuItem && menuItem.description) || "";
  } else {
    title = (menuItem && menuItem.title) || "";
    description = (menuItem && menuItem.description) || "";
  }
  return { title, description };
};

export const setDefaultLanguage = (code = 1, clientLocale = "es") => {
  const selectedLanguage = languagesListData.find((item) => item.code === code);
  return selectedLanguage.locale === clientLocale;
};

export const setDefaultLanguageV2 = (clientLocale = "es") => {
  const selectedLanguage = languagesListData.find(
    (item) => item.locale === clientLocale
  );
  const language = { name: selectedLanguage.name, code: selectedLanguage.code };
  return language;
};
export const getTypeText = (type = "uniSelect"): string => {
  let typeText = "Uni Select";
  if (type === "multiSelect") {
    typeText = "Multi Select";
  } else if (type == "multiselectQuantity") {
    typeText = "Multi Select Quantity";
  }

  return typeText;
};
export const generateRandomString = (): string => {
  const length = 16;
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
};

export const getPromotionLabel = (value) => {
  for (const [label, enumValue] of Object.entries(promotionTypes)) {
    if (enumValue === value) {
      return label;
    }
  }
  return "";
};
export const allergenesTranslation = {
  Cereals: "ALLERGENE.CEREALS",
  Celery: "ALLERGENE.CELERY",
  Crustaceans: "ALLERGENE.CRUSTACEANS",
  Eggs: "ALLERGENE.EGGS",
  Fish: "ALLERGENE.FISH",
  Lupin: "ALLERGENE.LUPIN",
  Milk: "ALLERGENE.MILK",
  Molluscs: "ALLERGENE.MOLLUSCS",
  Mustard: "ALLERGENE.MUSTARD",
  Nuts: "ALLERGENE.NUTS",
  Peanuts: "ALLERGENE.PEANUTS",
  "Sesame Seeds": "ALLERGENE.SESAME_SEEDS",
  "Sulphur dioxide": "ALLERGENE.SULPHER_DIOXIDE",
  Allergenes: "ALLERGENE.TITLE",
  Soya: "ALLERGENE.SOYA",
};
export const getSortedAllergenes = ({
  allergenesList = [],
  languageStrings,
}) => {
  let sortedAllegenes = [];
  if (allergenesList && allergenesList.length) {
    const __ = allergenesList.map((item) => {
      return {
        ...item,
        label: languageStrings[allergenesTranslation[item.title]],
      };
    });
    __.sort((a, b) => a.label.localeCompare(b.label));
    sortedAllegenes = __;
  }
  return sortedAllegenes;
};

export const getSortedPositions = (action, itemId, itemsArray) => {
  const arrayCopy = [...itemsArray].sort((a, b) => a.position - b.position);
  const updatedPositions = {};
  const currentIndex = arrayCopy.findIndex((item) => item._id === itemId);
  const swapIndex = action === "increase" ? currentIndex - 1 : currentIndex + 1;
  if (currentIndex !== -1 && swapIndex >= 0 && swapIndex < arrayCopy.length) {
    [arrayCopy[currentIndex], arrayCopy[swapIndex]] = [
      arrayCopy[swapIndex],
      arrayCopy[currentIndex],
    ];
  }
  arrayCopy.forEach((item, idx) => {
    if (item._id) {
      updatedPositions[item._id] = idx;
      item.position = idx;
    }
  });
  const sortedArray = arrayCopy.sort((a, b) => a.position - b.position);
  return { updatedPositions, array: sortedArray };
};
