import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import LoadingOverlay from "react-loading-overlay";
import { SkuType } from "app/models/Catalogue";
import { DragDropContext } from "react-beautiful-dnd";

import { FuseUtils } from "@fuse";
import History from "@history";
import * as Actions from "app/store/actions";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { formatImageUrl, getSortedAllergenes } from "app/helpers/utilsHelper";
import AlertDialog from "app/main/common/AlertDialog";
import CustomInput from "app/main/common/CustomInput";
import { getMenuItemAllergenes } from "app/store/menu/MenuActions";
import _ from "@lodash";
import SizesSection from "./components/SizesSection";
import { makeStyles } from "@material-ui/styles";
import SizesForm from "./components/SizesForm";
import {
  arrangeSizes,
  createProduct,
  createSize,
  editProduct,
  getProductDetail,
  updateSize,
} from "app/store/catalogue/CatalogueActions";
import SkuButton from "app/main/common/List/components/SkuButton";
import { GroupModifierDropdown } from "../../common/GroupModifierDropdown";
import GroupModifierList from "./components/GroupModifierList";
import { CatalogueActionTypes } from "app/store/redux/actionTypes";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ProductDetail(props) {
  const dispatch = useDispatch();
  const loading = useSelector(({ banzzu }) => banzzu.catalogue.loading);
  let product = useSelector(({ banzzu }) => banzzu.catalogue.productDetail);
  const productSizes = useSelector(
    ({ banzzu }) => banzzu.catalogue.productSizes
  );
  const allergenesList = useSelector(
    ({ banzzu }) => banzzu.menu.menuItemAllergenes
  );
  const client = useSelector(({ banzzu }) => banzzu.auth.user);
  const integrationType =
    (client.settings && client.settings.integrationType) || false;
  const { languageStrings } = useLanguageHelper();
  const { productId } = props.match.params;
  const page = History.location.state.page || 0;
  useEffect(() => {
    if (productId !== "new") {
      dispatch(getProductDetail(productId, page));
    } else {
      dispatch({
        type: CatalogueActionTypes.EMPTY_SKU_LIST,
      });
    }
  }, [dispatch, productId]);
  useEffect(() => {
    dispatch(getMenuItemAllergenes());
  }, [dispatch]);

  if (productId === "new") {
    product = {
      _id: "0",
      title: "",
      description: "",
      skuType: SkuType.SIMPLE,
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
    description: "",
  };

  //TODO REFACTOR Allergenes to utils????

  const Allergenes = getSortedAllergenes({ allergenesList, languageStrings });

  const [tabValue, setTabValue] = useState(0);
  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);
  const [openSizes, setOpenSizes] = useState({
    show: false,
    title: "",
    sku: "",
    price: 0,
    isEditing: false,
    _id: "",
  });

  const modalRef = useRef(document.createElement("div"));
  const classes = useStyles();

  const { form, handleChange, setForm } = useForm(null);
  const { form: initialForm, setForm: setInitialForm } = useForm(null);

  useEffect(() => {
    if ((product && !form) || (product && form && product._id !== form._id)) {
      setForm(product);
      setInitialForm(product);
    }
  }, [form, product, setForm]);
  useEffect(() => {
    if (Array.isArray(productSizes)) {
      setForm((form) => ({
        ...form,
        skuList: productSizes,
      }));
    }
  }, [productSizes, setForm]);

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  const hasNoStockError = () => {
    if (form.stockEnabled && (!form.stockQuantity || form.stockQuantity < 0)) {
      return false;
    }
    return true;
  };
  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty() && hasNoSku() && hasNoStockError();
  }

  //ERRORS
  function hasNoEmpty() {
    const { title, price, skuType } = form;

    if (skuType === "1") {
      return title && price && title !== "" && price !== "";
    }

    return title && title !== "";
  }

  function hasNoSku() {
    if (form.skuType === "2") {
      const _skuList =
        form && form.skuList && form.skuList.length ? form.skuList : [];
      return !!_skuList.length;
    } else if (
      form.skuType === "1" &&
      form.sku !== undefined &&
      form.sku !== ""
    ) {
      return true;
    }
    return false;
  }

  function hasNoErrors() {
    return !errorName() && !errorImage();
  }

  function errorName() {
    return dirty.title && form.title.length < 3;
  }

  //TODO REFACTOR IMAGE LOGIC
  function errorImage() {
    return false;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
          deleteImage: "false",
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleDescriptionChange(event) {
    handleChange(event);
    debouncedDescription(event.target.value);
  }

  const debouncedDescription = useDebounce((description) => {
    setDirty((prevState) => {
      return { ...prevState, description: true };
    });
    if (description.length < 10) {
      setError((prevState) => {
        return {
          ...prevState,
          description: languageStrings["ERRORS.MINIMUM_10_CHAR"],
        };
      });
    }
  }, 500);

  function handleSkuChange(event) {
    handleChange(event);
    debouncedSku(event.target.value);
  }

  const debouncedSku = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleAllergenesChange(event) {
    let allergenesFormatted;
    if (event.target.checked) {
      const allergenes = `${form.allergenes},${event.target.value},`;
      allergenesFormatted = _.replace(allergenes, ",,", ",");
    } else {
      const allergenes = _.replace(
        form.allergenes,
        `${event.target.value},`,
        ""
      );
      allergenesFormatted = _.replace(allergenes, ",,", ",");

      if (!_.startsWith(allergenesFormatted, ",")) {
        allergenesFormatted = "," + allergenesFormatted;
      }
      if (!_.endsWith(allergenesFormatted, ",")) {
        allergenesFormatted = allergenesFormatted + ",";
      }
    }

    setForm((form) => ({
      ...form,
      allergenes: allergenesFormatted,
    }));
  }

  const handleFoodTypeChange = (event) => {
    const _foodType = event.target.value;
    setForm((form) => ({
      ...form,
      foodType: _foodType,
    }));
  };

  const handleSKUTypeChange = (event) => {
    const _skuType = event.target.value;
    setForm((form) => ({
      ...form,
      skuType: _skuType,
    }));
  };

  function goBack() {
    History.push({
      pathname: `/catalogue/products`,
      state: { page },
    });
  }

  const previousPage = () => {
    if (FuseUtils.unSavedChanges(form, initialForm)) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  };
  const _productId = form && form._id;
  const isAlreadyProduct = _productId && _productId !== "0";

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const list = [...(form && form.skuList ? form.skuList : [])];

    const draggedItemIndex = list.findIndex((item) => item._id === draggableId);

    if (draggedItemIndex !== -1) {
      let [draggedItem] = list.splice(draggedItemIndex, 1);

      list.splice(destination.index, 0, draggedItem);
    }
    setForm((prevForm) => ({
      ...prevForm,
      skuList: list,
    }));
    if (isAlreadyProduct) {
      dispatch(arrangeSizes({ sizeList: list, productId }));
    }
  };
  const getGroupIds = (list = []) => {
    const groupIds = [];
    for (let i = 0; i < list.length; i++) {
      const { _id } = list[i];
      groupIds.push(_id);
    }
    return groupIds;
  };
  const onGroupDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const list = [...groupModifiers];

    const draggedItemIndex = list.findIndex((item) => item._id === draggableId);

    if (draggedItemIndex !== -1) {
      let [draggedItem] = list.splice(draggedItemIndex, 1);

      list.splice(destination.index, 0, draggedItem);
    }
    const groupIds = getGroupIds(list);
    setForm((prevForm) => ({
      ...prevForm,
      groupModifiers: list,
      groupIds,
    }));
  };
  const onDeleteImage = () => {
    setForm((form) => ({
      ...form,
      image: null,
      imageFile: null,
      deleteImage: true,
    }));
  };
  const findIndexById = (data = [], sku) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].sku === sku) {
        return i; // Return index if found
      }
    }
    return -1; // Return -1 if ID is not found
  };
  const groupModifiers = form && form.groupModifiers ? form.groupModifiers : [];
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["CATALOGUE.PRODUCT_LIST"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings["CATALOGUE.PRODUCT_NEW"]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {languageStrings["CATALOGUE.PRODUCT_DETAIL"]}
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>
              <FuseAnimate animation="transition.slideRightIn" delay={300}>
                <Button
                  className="whitespace-no-wrap"
                  variant="contained"
                  disabled={!canBeSubmitted()}
                  onClick={() => {
                    form._id !== "0"
                      ? dispatch(editProduct(form, page))
                      : dispatch(createProduct(form, page));
                  }}
                >
                  {languageStrings["GENERAL.SAVE"]}
                </Button>
              </FuseAnimate>
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div className="p-16 sm:p-24 max-w-xl max-h-screen">
                  <Modal
                    className={classes.modal}
                    open={openSizes.show}
                    onClose={() => {
                      setOpenSizes({
                        show: false,
                        sku: "",
                        title: "",
                        isEditing: false,
                      });
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openSizes.show}>
                      <div
                        className={["max-h-640 min-w-1/2", classes.paper].join(
                          " "
                        )}
                        ref={modalRef}
                      >
                        <h2 id="transition-modal-title" className="pb-32">
                          {languageStrings["CATALOGUE.ADD_TAMAÑO"]}
                        </h2>
                        <SizesForm
                          existingSku={openSizes.sku}
                          existingTitle={openSizes.title}
                          existingPrice={openSizes.price}
                          createParenthesizedType={(data) => {
                            let datalist = [
                              ...(form && form.skuList ? form.skuList : []),
                            ];
                            if (openSizes.isEditing) {
                              if (isAlreadyProduct) {
                                dispatch(
                                  updateSize({
                                    ...data,
                                    sizeId: openSizes._id,
                                    productId,
                                  })
                                );
                              }
                              const findIndex = findIndexById(
                                datalist,
                                openSizes.sku
                              );
                              if (findIndex !== -1) {
                                datalist[findIndex] = {
                                  ...data,
                                  _id: openSizes._id,
                                };
                              }
                            } else {
                              if (isAlreadyProduct) {
                                dispatch(
                                  createSize({ ...data, productId: _productId })
                                );
                                return;
                              } else {
                                const newData = { ...data };
                                datalist.push(newData);
                              }
                            }

                            setForm((prevForm) => ({
                              ...prevForm,
                              skuList: datalist,
                            }));
                          }}
                          onClose={() =>
                            setOpenSizes({
                              show: false,
                              sku: "",
                              title: "",
                              isEditing: false,
                              price: 0,
                            })
                          }
                        />
                      </div>
                    </Fade>
                  </Modal>
                  <div className="flex flex-row justify-center sm:justify-start flex-wrap">
                    <input
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                      className="hidden"
                      id={`button-file-${productId}`}
                      type="file"
                      onChange={handleUploadChange}
                    />
                    <label
                      htmlFor={`button-file-${productId}`}
                      className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                    >
                      <Icon fontSize="large" color="action">
                        cloud_upload
                      </Icon>
                    </label>
                    <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                      <img
                        className="max-w-none w-auto h-full"
                        alt="whatson"
                        src={
                          form.image
                            ? formatImageUrl(form.image)
                            : "assets/images/avatars/noimage.png"
                        }
                      />
                      {form.image && (
                        <Icon
                          onClick={onDeleteImage}
                          className="rounded-4 shadow-5 mb-2 text-2xl cursor-pointer absolute bottom-0"
                          style={{
                            color: "#f4516c",
                            backgroundColor: "#fff",
                          }}
                        >
                          delete
                        </Icon>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <CustomInput
                      className={`mt-8 mb-16 ${
                        form.skuType === "2" ? "mr-0" : "mr-8"
                      }`}
                      required
                      label={languageStrings["GENERAL.TITLE"]}
                      id="title"
                      name="title"
                      value={form.title ? form.title : ""}
                      onValueChange={handleTitleChange}
                      errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
                      hasError={(error) =>
                        setDirty((prevState) => {
                          return { ...prevState, title: error };
                        })
                      }
                      minLength={3}
                    />

                    {form.skuType === "1" && (
                      <TextField
                        className="mt-8 ml-8 mb-16"
                        label={languageStrings["GENERAL.PRICE"]}
                        required
                        id="price"
                        name="price"
                        type={"number"}
                        value={form.price || ""}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  </div>
                  <CustomInput
                    className="mt-8 mb-16 mr-8"
                    label={languageStrings["GENERAL.DESCRIPTION"]}
                    id="description"
                    name="description"
                    maxLength={1000}
                    value={form.description || ""}
                    onValueChange={handleDescriptionChange}
                    errorMessage={languageStrings["ERRORS.MINIMUM_10_CHAR"]}
                    hasError={(error) => error}
                  />
                  {client.stockEnabled && (
                    <div className="mt-8 mb-8">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            id="stockEnabled"
                            name="stockEnabled"
                            color="primary"
                            checked={form.stockEnabled ? true : false}
                          />
                        }
                        label={languageStrings["STOCK"]}
                      />
                      {!integrationType && form.stockEnabled && (
                        <TextField
                          className="mt-8 mb-8"
                          label={languageStrings["STOCK_QUANTITY"]}
                          id="stockQuantity"
                          name="stockQuantity"
                          type={"number"}
                          value={
                            form.stockQuantity === 0
                              ? 0
                              : form.stockQuantity || ""
                          }
                          onChange={handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    </div>
                  )}
                  <div className="flex flex-row">
                    <div>
                      <label className="mr-10 font-bold">
                        {languageStrings["PRINT_TICKET"]}:
                      </label>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        className="flex flex-row"
                        value={form && form.foodType ? form.foodType : ""}
                        onChange={handleFoodTypeChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={languageStrings["DRINKS"]}
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={languageStrings["FOOD"]}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  <div className="mt-12">
                    <div>
                      <label className="mr-10 font-bold">
                        {languageStrings["CATALOGUE.SKU"]}
                      </label>
                      <div className="ml-auto mt-8">
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          className="flex flex-row"
                          value={form && form.skuType ? form.skuType : "1"}
                          onChange={handleSKUTypeChange}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={languageStrings["CATALOGUE.SIMPLE"]}
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label={languageStrings["CATALOGUE.MULTISIZE"]}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    {form.skuType === "1" && (
                      <div className="flex">
                        <CustomInput
                          className="mt-8 mb-16 mr-8"
                          required
                          label={languageStrings["CATALOGUE.SKU"]}
                          id="sku"
                          name="sku"
                          value={form.sku ? form.sku : ""}
                          onValueChange={handleSkuChange}
                          errorMessage={
                            languageStrings["ERRORS.MINIMUM_3_CHAR"]
                          }
                          hasError={(error) =>
                            setDirty((prevState) => {
                              return { ...prevState, title: error };
                            })
                          }
                          minLength={3}
                        />
                        <SkuButton
                          onCreateRandomString={(value) => {
                            setForm((prevForm) => ({
                              ...prevForm,
                              sku: value,
                            }));
                          }}
                        />
                      </div>
                    )}
                    {form.skuType === "2" && (
                      <div>
                        <Button
                          className="mt-8 ml-16 bg-grey-lightest"
                          variant="contained"
                          onClick={() => {
                            setOpenSizes({
                              show: true,
                              sku: "",
                              title: "",
                              isEditing: false,
                            });
                          }}
                        >
                          <Icon className="mr-8">add</Icon>
                          {languageStrings["CATALOGUE.ADD_TAMAÑO"]}
                        </Button>

                        <div className="mt-16 mb-16">
                          <DragDropContext
                            draggableId="list-drag"
                            onDragEnd={onDragEnd}
                          >
                            <SizesSection
                              productId={_productId}
                              onRemove={(i) => {
                                const _skuList =
                                  form && form.skuList ? form.skuList : [];
                                const existingList = [..._skuList];
                                existingList.splice(i, 1);
                                setForm((form) => ({
                                  ...form,
                                  skuList: existingList,
                                }));
                              }}
                              onEditPress={(data) => {
                                setOpenSizes({
                                  ...data,
                                  show: true,
                                  isEditing: true,
                                });
                              }}
                              list={form && form.skuList ? form.skuList : []}
                            />
                          </DragDropContext>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="mt-8 mb-8 font-bold">
                      {languageStrings["ALLERGENE.TITLE"]}
                    </div>
                    <FormGroup row>
                      {Allergenes.map((item) => (
                        <FormControlLabel
                          key={item._id}
                          control={
                            <Checkbox
                              checked={
                                form.allergenes
                                  ? form.allergenes.includes(`,${item._id},`)
                                  : false
                              }
                              onChange={handleAllergenesChange}
                              value={item._id}
                            />
                          }
                          label={item.label}
                        />
                      ))}
                    </FormGroup>
                  </div>
                  <div className="mt-16 pb-16 w-full">
                    <GroupModifierDropdown
                      handleChangeModifier={(selected, id) => {
                        const existingList = [...groupModifiers];
                        const groupIds =
                          form && form.groupModifiers
                            ? form.groupModifiers
                            : [];
                        const existingIds = [...groupIds];
                        existingList.push(selected);
                        existingIds.push(id);
                        setForm((form) => ({
                          ...form,
                          groupModifiers: existingList,
                          groupIds: existingIds,
                        }));
                      }}
                    />
                    <DragDropContext onDragEnd={onGroupDragEnd}>
                      <GroupModifierList
                        onRemove={(i) => {
                          const existingList = [...groupModifiers];
                          existingList.splice(i, 1);
                          const groupIds = getGroupIds(existingList);

                          setForm((form) => ({
                            ...form,
                            groupModifiers: existingList,
                            groupIds,
                          }));
                        }}
                        groupModifiers={groupModifiers}
                      />
                    </DragDropContext>
                  </div>
                </div>
              )}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
}

export default withReducer("VenuesReducer", reducer)(ProductDetail);
