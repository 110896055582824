import React, { useEffect } from "react";
import { Icon, Typography } from "@material-ui/core";
import { useForm } from "@fuse/hooks";
import { formatImageUrl } from "app/helpers/utilsHelper";

function FilePicker(props) {
  const { title, file, name, onChange, type } = props;
  const { form, setForm } = useForm({ formFile: "", formTitle: "" });

  useEffect(() => {
    setForm({
      formFile: file,
      formTitle: title,
    });
  }, [title, file, setForm]);

  return (
    <div>
      <div className="flex items-center ml-10 mr-5">
        <Typography variant="h6" className="mr-25 w-200">
          {form.formTitle}
        </Typography>
        <div>
          {type === "video" ? (
            <input
              accept="video/mp4"
              className="hidden"
              id={name}
              type="file"
              onChange={onChange}
            />
          ) : (
            <input
              accept="image/jpg, image/jpeg, image/png, image/gif"
              className="hidden"
              id={name}
              type="file"
              onChange={onChange}
            />
          )}

          <div className="flex justify-center sm:justify-start flex-wrap">
            <label
              htmlFor={name}
              className={
                "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 "
              }
            >
              <Icon fontSize="large" color="action">
                cloud_upload
              </Icon>
            </label>
            <div className="flex items-center min-w-128 h-128 max-w-128 max-h-128 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
              {type === "video" && form.formFile ? (
                <video
                  key={form.formFile}
                  className="max-w-none w-auto h-full"
                  height={100}
                  width={200}
                  controls
                >
                  <source
                    src={
                      form.formFile
                        ? form.formFile
                        : "assets/images/avatars/noimage.png"
                    }
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  key={form.formFile}
                  className="max-w-none w-auto h-full"
                  src={
                    form.formFile
                      ? formatImageUrl(form.formFile)
                      : "assets/images/avatars/noimage.png"
                  }
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilePicker;
