import React, { useEffect, useState } from "react";
import { Button, Icon } from "@material-ui/core";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const ComboCategoryForm = (props) => {
  const classes = useStyles();
  const { initialTitle, onClose } = props;
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(initialTitle);
  }, []);

  const { languageStrings } = useLanguageHelper();

  return (
    <div className="p-16 sm:p-24 max-w-xl max-h-screen">
      <div className="flex">
        <CustomInput
          className="mt-8 mb-16 mr-8"
          required
          label={languageStrings["GENERAL.TITLE"]}
          id="title"
          name="title"
          value={title}
          onValueChange={(e) => setTitle(e.target.value)}
          errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
          minLength={3}
        />
      </div>
      <Button
        className="whitespace-no-wrap mt-16"
        variant="contained"
        disabled={title.length <= 3}
        onClick={() => {
          onClose(title);
        }}
      >
        <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>save</Icon>
        {languageStrings["GENERAL.SAVE"]}
      </Button>
    </div>
  );
};

export default withReducer("CombosReducer", reducer)(ComboCategoryForm);
