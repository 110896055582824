import { Reservation } from "app/models/Reservation";

import {
  getVoucherDetailUrl,
  VOUCHER_URL,
  SORT_VOUCHER,
  DISCOUNT_VOUCHER_URL,
} from "./VoucherEndpoints";

import {
  getVenueListUrl,
  getVenueDetailUrl,
  getDeliveryAvailabilityUrl,
  getCollectionAvailabilityUrl,
  COLLECTION_AVAILABILITY,
  DELIVERY_AVAILABILITY,
  VENUE,
  CLIENT_VENUE,
  VENUE_VALIDATE_EMAIL,
  GET_TAXES,
  AUTO_SERVICES,
} from "./VenueEndpoints";

import {
  MENU_ITEM,
  MENU_ITEMS_LIST_SORT,
  MENU_EXTRA_ITEMS_SORT,
  MENU_ITEM_EXTRA_CATEGORY,
  MENU_CATEGORY_SORT,
  MENU_ITEM_EXTRA_CATEGORY_ITEM,
  MENU_DUPLICATE,
  MENU_DUPLICATE_ITEM,
  MENU_ITEM_EXTRA_CATEGORY_SORT,
  MENU_ITEM_UPDATE_TIME,
} from "./MenuEndpoints";

import {
  getOrderListUrl,
  getOrderDetailUrl,
  getOrderRevenueUrl,
  ORDERS,
  ORDER_TABLE,
  getOrderAnalyticsUrl,
} from "./OrderEndpoints";

import {
  SCHEDULE_ORDERS,
  SCHEDULE_ORDER_DASHBOARD,
  getDashboardScheduleOrderListUrl,
  getScheduleOrderDetailUrl,
  getScheduleOrderListUrl,
} from "./ScheduleOrderEndpoints";
import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";

//NEW ENDPOINTS
export const LOGIN = "auth/login";
export const CLIENT = "auth/client";
export const CLIENT_WEB = "auth/client/web";
export const OFFER = "offer";
export const OFFERS_LIST_SORT = "offer/sort";
export const WHATSON = "whatson";
export const DRAW = "draw";
export const CHANGE_PASSWORD = "auth/changePassword";
export const LOYALTY = "reward";
export const CATALOG_PRODUCT = "/catalog/products";
export const CATALOG_PRODUCT_DETAIL = "/catalog/product";
export const CATALOG_MODIFIER = "/catalog/modifiers";
export const CATALOG_MODIFIER_DETAIL = "/catalog/modifier";
export const CATALOG_GROUP_MODIFIER = "/catalog/group-modifier";
export const CATALOG_GROUP_MODIFIER_DETAIL = "/catalog/group-modifier-detail";
export const MENU_V2_GROUP_MODIFIER = "/catalog-modifier-group";
export const COMBO_CATEGORY = "/catalog/combo/category";
export const MENU_V2_GROUP_MODIFIER_SORT = "/catalog-modifier-group/sort";
export const MENU_V2_MODIFIER = "catalog-modifier";
export const PRODUCT_SIZE = "/catalog/size";
export const ARRANGE_PRODUCT_SIZE = "/catalog/arrange-size";

export const REWARD_TRANSACTIONS = "reward/transactions";
export const GALLERY = "gallery";
export const MENU = "menu";
export const CATALOG_MENU = "catalog/menu";
export const CATALOG_CATEGORY_ITEM = "/catalog/category/item";
export const POS_PRODUCT_IMAGE = "/catalog/category/pos-item/image";
export const POS_COMBO_IMAGE = "/catalog/combo/pos-item/image";
export const MENU_CATEGORY = "menuCategory";
export const CATALOG_CATEGORY = "/catalog/category";
export const ALLERGEN = "allergenes";
export const USERS = "users";
export const TIPS = "tips";
export const RATINGS = "ratings";
export const LOYALTY_PROGRAM = "loyalty";
export const POS_PRODUCTS = "/pos/products";
export const POS_COMBOS = "/pos/combos";
export const USERS_DETAILS = "userDetails";
export const RESERVATION = "bookings";
export const USER_NUMBER = "analytics";
export const NOTIFICATION = "push";
export const USER = "user";
export const USER_BRANDING = "user/branding";
export const PREVIEW_WEB = "user/preview";
export const DOWNLOAD_QR = "user/download-qr";
export const SUBSCRIPTION = "subscription";
export const SEND_PREMIUM_LEAD_EMAIL = "user/sendPremiumLeadEmail";
export const SEND_CONTACT_SUPPORT_EMAIL = "user/sendContactSupportEmail";
export const ADD_LEAD_VISITING_PRICING = "user/addLeadVisitPricingKlaviyo";
export const CARDS = "cards";
export const STAFF = "auth/staff";
export const FORGOT_PASSWORD = "auth/forgotPassword";
export const RESET_PASSWORD = "auth/resetPassword";
export const CREATE_PASSWORD = "auth/create-password";
export const CHECK_TOKEN = "auth/validateToken";
export const CHECK_VALID_ACCOUNT_SETUP = "auth/validate-setup-account";
export const CLICKED_QR = "merchant/clicked-qr";
export const SECRET = "merchant/secret";
export const PaidVirtualBasketOrders = "order/table/paidVirtualBasketOrders";
export const PAYMENT_HISTORY = "payment-history";
export const PAYMENT_HISTORY_REFUND = "payment-history/refund";
export const AUTOSERVCIEORDER = "/autoservice_orders";
const PaidVirtualBasketOrderDetail = "order/table/paidVirtualBasketOrderDetail";
export const getPaidVirtualBasketOrderDetailUrl = (id: string): string => {
  return encodeURI(PaidVirtualBasketOrderDetail + "/" + id);
};
export const getPaymentHistoryUrl = (id: string): string => {
  return encodeURI(PAYMENT_HISTORY + "/" + id);
};

export const PAYMENT_REFOUND = "paymentRefund";

export const getPaidVirtualBasketOrders = (venus): string => {
  return encodeURI(PaidVirtualBasketOrders + "/" + getUserId() + "/" + venus);
};
//OLD ENDPOINTS
export const INDEX =
  "index.php?apikey=67a74306b06d0c01624fe0d0249a570f4d093747&";
export const MERCHANT = "merchant/purchase";
export const MERCHANT_UP_SELL_EMAIL = "merchant/sendUpSellEmail";

export const APP_CODE = "1";

export const getUserId = (): string => {
  return getStorageItem(STORAGE_KEY.USER_ID);
};

export const getMasterId = (): string => {
  return getStorageItem(STORAGE_KEY.MASTER_ID);
};

export const loginUserUrl = (): string => {
  return LOGIN;
};

export const getUserInfoUrl = (): string => {
  return CLIENT + "/" + getUserId();
};

export const getOfferDetailUrl = (id: string): string => {
  return OFFER + "/" + id;
};

export const getWhatsOnDetailUrl = (id: string): string => {
  return WHATSON + "/" + id;
};

export const getDrawDetailUrl = (id: string): string => {
  return DRAW + "/" + id;
};

export const getCreateReservationUrl = (reservation: Reservation): string => {
  return encodeURI("");
};

export const getEditReservationUrl = (reservation: Reservation): string => {
  return encodeURI("");
};

export const getUserNumberUrl = (): string => {
  return encodeURI(USER_NUMBER + "/" + getUserId());
};

export const getNotificationListUrl = (): string => {
  return NOTIFICATION + "client=" + getUserId();
};
export const getCreateNotificationUrl = (): string => {
  return encodeURI(NOTIFICATION);
};

export const getTableListUrl = (venueId: string): string => {
  return encodeURI(ORDER_TABLE + "?venueId=" + venueId);
};

export const PROMOTIONS_URL = "promotions";
export const PROMOTION = "promotion";

export const getPromotionDetailUrl = (id: string): string => {
  return PROMOTION + "/" + id;
};
export const getAutoServiceUrl = (id: string): string => {
  return AUTOSERVCIEORDER + "/" + id;
};

export const COMBOS_URL = "combos";
export const COMBO = "combo";

export const getComboDetailUrl = (id: string): string => {
  return COMBO + "/" + id;
};
export const CATALOG_COMBOS_URL = "/catalog/catalogCombos";
export const CATALOG_COMBO_AVAILABILITY = "/catalog/catalogComboAvailability";
export const SORT_COMBO_CATEGORY = "/catalog/combo/comboCategory/sort";
export const SORT_COMBO_CATEGORY_PRODUCTS =
  "/catalog/combo/comboCategory/products/sort";
export const SORT_CATALOG_COMBO_CATEGORY = "/catalog/combo/category/sort";
export const SORT_CATALOG_PRODUCT = "/catalog/products/sort";

export const SORT_CATALOG_GROUP_MODIFIER = "/catalog/group-modifier/sort";
export const SORT_CATALOG_MODIFIER = "/catalog/modifier/sort";

export const KIOSK_BRANDING = "client/branding";
export {
  getVoucherDetailUrl,
  getVenueListUrl,
  getVenueDetailUrl,
  getDeliveryAvailabilityUrl,
  getCollectionAvailabilityUrl,
  getOrderListUrl,
  getOrderDetailUrl,
  getOrderRevenueUrl,
  getOrderAnalyticsUrl,
  MENU_ITEM,
  VENUE,
  ORDERS,
  VOUCHER_URL,
  SORT_VOUCHER,
  MENU_ITEMS_LIST_SORT,
  MENU_EXTRA_ITEMS_SORT,
  MENU_ITEM_EXTRA_CATEGORY,
  MENU_CATEGORY_SORT,
  COLLECTION_AVAILABILITY,
  DELIVERY_AVAILABILITY,
  MENU_ITEM_EXTRA_CATEGORY_ITEM,
  MENU_DUPLICATE,
  MENU_DUPLICATE_ITEM,
  MENU_ITEM_EXTRA_CATEGORY_SORT,
  ORDER_TABLE,
  CLIENT_VENUE,
  VENUE_VALIDATE_EMAIL,
  MENU_ITEM_UPDATE_TIME,
  DISCOUNT_VOUCHER_URL,
  GET_TAXES,
  AUTO_SERVICES,
  SCHEDULE_ORDERS,
  SCHEDULE_ORDER_DASHBOARD,
  getDashboardScheduleOrderListUrl,
  getScheduleOrderDetailUrl,
  getScheduleOrderListUrl,
};
export const updateVenuePopupUrl = (): string => {
  return "/admin/venue/popup";
};

export const getVenuePopupUrl = (venueId): string => {
  return "/admin/venue/popup/" + venueId;
};
