import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Icon,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  TextareaAutosize,
} from "@material-ui/core";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import { useDebounce } from "@fuse/hooks";
import { getVenueList } from "app/store/venues/VenuesActions";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { FuseUtils } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import { getMenuList } from "app/store/menu/MenuActions";
import { formatImageUrl } from "app/helpers/utilsHelper";
import { clientsSettings } from "config";
import { languagesListData } from "./components/data";
import { setDefaultLanguageV2 } from "app/helpers/utilsHelper";
import { MenuVisibilityModal } from "./components/MenuVisibilityModalV2";
import {
  createMenuV2,
  editMenuV2,
  getMenuV2Detail,
  updateMenuVisibilityV2,
} from "app/store/menuV2/MenuV2Actions";
import VisibilityModal from "app/main/menu/menuDetailPro/components/VisibilityModal";
import VisibilityModalV2 from "app/main/common/VisibilityModalV2";
import VisibilityBanner from "app/main/common/VisibilityBanner";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  enable: {
    pointerEvents: "none",
  },
  disabled: {
    pointerEvents: "auto",
  },
  resize: {
    fontSize: 30,
  },
  description: {
    width: "50%",
  },
}));

function MenuDetailForm({ menuId, onVenuePress, isMenuUrl }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const venueList = useSelector(({ banzzu }) => banzzu.venue.venueList);
  let menu = useSelector(({ banzzu }) => banzzu.menuV2.menuV2Detail);
  let menuList = useSelector(({ banzzu }) => banzzu.menuV2.menuV2List);
  let user = useSelector(({ banzzu }) => banzzu.auth.user);
  const { languageStrings } = useLanguageHelper();
  const { selectedLanguages } = menu || {};
  const { settings, locale } = user || {};
  const defaultLanguage = setDefaultLanguageV2(locale);
  useEffect(() => {
    if (menuId !== "new") {
      dispatch(getMenuV2Detail(menuId));
    }
  }, [dispatch, menuId]);
  useEffect(() => {
    dispatch(getVenueList());
    dispatch(getMenuList());
  }, [dispatch]);

  if (menuId === "new") {
    menu = {
      id: "0",
      title: "",
      description: "",
      isPdf: "0",
      venues: [],
      availableOnFutureOrders: false,
      availableOnDelivery: false,
      availableOnCollection: false,
      availableOnOrderFromTable: clientsSettings.availableOnOrderFromTable,
      onlyRead: false,
      selectedLanguages: [defaultLanguage.name],
      deliverectMenuId: "",
      isCategoryDivide: false,
    };
  }
  const dirtyInitial = {
    title: false,
    description: false,
  };

  const errorInitial = {
    title: "",
  };

  const urlErrorInitial = {
    isError: false,
    title: "",
  };

  const [error, setError] = useState(errorInitial);
  const [dirty, setDirty] = useState(dirtyInitial);
  const [openVisibility, setOpenVisibility] = useState(false);
  const [urlError, setUrlError] = useState(urlErrorInitial);
  const { form, handleChange, setForm } = useForm(null);

  useEffect(() => {
    if ((menu && !form) || (menu && form && menu._id !== form._id)) {
      let _languages =
        selectedLanguages && selectedLanguages.length > 0
          ? selectedLanguages
          : [defaultLanguage.name];
      if (!_languages.includes(defaultLanguage.name)) {
        _languages.push(defaultLanguage.name);
      }
      setForm({
        ...menu,
        selectedLanguages: _languages,
      });
    }
  }, [form, menu, setForm, user, menuList, selectedLanguages]);

  useEffect(() => {
    if (menu && selectedLanguages) {
      let _languages =
        selectedLanguages && selectedLanguages.length > 0
          ? selectedLanguages
          : [defaultLanguage.name];
      if (!_languages.includes(defaultLanguage.name)) {
        _languages.push(defaultLanguage.name);
      }
      form.selectedLanguages = _languages;
    }
  }, [menu, selectedLanguages]);

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty() && !menu.onlyRead;
  }
  //ERRORS
  function hasNoEmpty() {
    return (
      form.title !== "" &&
      form.title != undefined &&
      form.venues &&
      form.venues.length > 0
    );
  }

  function hasNoErrors() {
    return !errorName();
  }

  function errorName() {
    return dirty.title && form.title && form.title.length < 3;
  }

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }
    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleTitleChange(event) {
    handleChange(event);
    debouncedTitle(event.target.value);
  }

  function handleVenueChange(event) {
    let venuesFormatted = form.venues;

    if (event.target.checked) {
      venuesFormatted.push(event.target.value);
    } else {
      venuesFormatted.splice(venuesFormatted.indexOf(event.target.value), 1);
    }
    onVenuePress(venuesFormatted);
    setForm((form) => ({
      ...form,
      venues: venuesFormatted,
    }));
  }

  const debouncedTitle = useDebounce((title) => {
    setDirty((prevState) => {
      return { ...prevState, title: true };
    });
    if (title.length < 3) {
      setError((prevState) => {
        return {
          ...prevState,
          title: languageStrings["ERRORS.MINIMUM_3_CHAR"],
        };
      });
    }
  }, 500);

  function handleUrlChange(event) {
    handleChange(event);
    debouncedUrl(event.target.value);
  }
  const debouncedUrl = useDebounce((url) => {
    const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/[\w.-]*)*\/?$/i;
    if (url.length && !urlPattern.test(url)) {
      setUrlError((prevState) => {
        return {
          ...prevState,
          isError: true,
          title: languageStrings["MENU_URL_ERROR"],
        };
      });
    } else {
      setUrlError((prevState) => {
        return { ...prevState, isError: false, title: "" };
      });
    }
  }, 500);

  const onLanguageSelect = (event) => {
    const lang = event.target.value;
    const array = form.selectedLanguages;
    let index = array.findIndex((l) => l === lang);
    if (index === -1) array.push(lang);
    else array.splice(index, 1);
    setForm({
      ...form,
      selectedLanguages: array,
    });
  };

  return (
    <>
      {form && (
        <div>
          <div className="mb-16">
            <VisibilityBanner visibility={form && form.visibility} />
          </div>
          <input
            accept="image/jpg, image/jpeg, image/png, image/gif"
            className="hidden"
            id="button-file"
            type="file"
            onChange={handleUploadChange}
          />
          <div className="flex justify-center sm:justify-start flex-row">
            <label
              htmlFor="button-file"
              className={
                "flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5 " +
                clsx(menu.onlyRead ? classes.enable : classes.disabled)
              }
            >
              <Icon fontSize="large" color="action">
                cloud_upload
              </Icon>
            </label>
            <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
              <img
                className="max-w-none w-auto h-full"
                alt="whatson"
                src={
                  form.image
                    ? formatImageUrl(form.image)
                    : "assets/images/avatars/noimage.png"
                }
              />
            </div>
            <div className="flex-col w-640">
              <TextField
                className="mt-8 mb-16 flex"
                error={errorName()}
                required
                label={languageStrings["GENERAL.TITLE"]}
                id="title"
                size="medium"
                name="title"
                disabled={menu.onlyRead}
                value={form.title || ""}
                onChange={handleTitleChange}
                variant="outlined"
                inputProps={{
                  classes: {
                    input: classes.resize,
                  },
                  maxLength: 100,
                  size: 30,
                }}
                helperText={errorName() ? error.title : ""}
              />
              <TextareaAutosize
                className="mt-8 mb-16 flex w-full"
                aria-label="minimum height"
                minRows={3}
                placeholder="Description"
                label="Description"
                id="description"
                name="description"
                value={form.description}
                onChange={handleChange}
                style={{
                  borderWidth: "1px",
                  minHeight: "8rem",
                  maxHeight: "8rem",
                  borderRadius: "5px",
                  padding: "5px",
                  borderColor: "#B8C2CC",
                }}
              />
            </div>
            {menuId !== "new" && (
              <div className="flex justify-items-end mb-40 ml-12">
                <Card className="bg-grey-lightest m-8 p-16 flex-1 self-end">
                  <>
                    <div className="font-bold">
                      {languageStrings["GENERAL_LANGUAGES"]}
                    </div>
                    <FormGroup row>
                      {languagesListData.map((item) => {
                        return (
                          <FormControlLabel
                            key={item.name}
                            control={
                              <Checkbox
                                checked={form.selectedLanguages.includes(
                                  item.name
                                )}
                                disabled={item.locale === locale ? true : false}
                                onChange={onLanguageSelect}
                                value={item.name}
                              />
                            }
                            label={languageStrings[item.label]}
                          />
                        );
                      })}
                    </FormGroup>
                  </>
                </Card>
              </div>
            )}
          </div>

          <div className="flex md:flex-row md:justify-evenly flex-col">
            {venueList.length > 0 && user.accessLevel != "1" && (
              <Card className="bg-grey-lightest m-8 p-16 flex-1">
                <React.Fragment>
                  <div className="font-bold">
                    {`${languageStrings["MENU_DETAIL_PAGE.VENUES"]} *`}
                  </div>
                  <FormGroup row>
                    {venueList.map((item) => {
                      return (
                        <FormControlLabel
                          key={item.title}
                          control={
                            <Checkbox
                              checked={
                                form.venues && form.venues.length > 0
                                  ? form.venues.includes(item._id)
                                  : false
                              }
                              onChange={handleVenueChange}
                              value={item._id}
                            />
                          }
                          label={item.title}
                        />
                      );
                    })}
                  </FormGroup>
                </React.Fragment>
              </Card>
            )}
            {user.permission &&
            clientsSettings.showMenuAvailable &&
            (user.permission.hasFutureOrder ||
              user.permission.hasDelivery ||
              user.permission.hasCollection ||
              user.permission.hasOrderFromTable) ? (
              <Card className="bg-grey-lightest m-8 p-16 flex-1">
                <React.Fragment>
                  <div className="font-bold">
                    " {languageStrings["MENU_DETAIL_PAGE.MENU_AVAILABILITY"]}"
                  </div>
                  <FormGroup row>
                    {user.permission.hasFutureOrder && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="availableOnFutureOrders"
                            onChange={handleChange}
                            disabled={menu.onlyRead}
                            checked={form.availableOnFutureOrders}
                          />
                        }
                        label={
                          languageStrings["MENU_DETAIL_PAGE.FUTURE_ORDERS"]
                        }
                      />
                    )}

                    {user.permission.hasDelivery && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="availableOnDelivery"
                            onChange={handleChange}
                            disabled={menu.onlyRead}
                            checked={form.availableOnDelivery ? true : false}
                          />
                        }
                        label={languageStrings["MENU_DETAIL_PAGE.DELIVERY"]}
                      />
                    )}

                    {user.permission.hasCollection && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="availableOnCollection"
                            onChange={handleChange}
                            disabled={menu.onlyRead}
                            checked={form.availableOnCollection ? true : false}
                          />
                        }
                        label={languageStrings["MENU_DETAIL_PAGE.COLLECTION"]}
                      />
                    )}

                    {user.permission.hasOrderFromTable && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="availableOnOrderFromTable"
                            onChange={handleChange}
                            disabled={menu.onlyRead}
                            checked={
                              form.availableOnOrderFromTable ? true : false
                            }
                          />
                        }
                        label={
                          languageStrings["MENU_DETAIL_PAGE.ORDER_FROM_TABLE"]
                        }
                      />
                    )}
                  </FormGroup>
                </React.Fragment>
              </Card>
            ) : null}
          </div>
          <div className="ml-10">
            <FormControlLabel
              control={
                <Checkbox
                  name="isCategoryDivide"
                  onChange={handleChange}
                  checked={form.isCategoryDivide ? true : false}
                />
              }
              label={languageStrings["MENU_CATEGORY_DIVIDE"]}
            />
          </div>
          {settings && settings.integrationType === "deliverect" && (
            <div className="flex">
              <TextField
                className="mt-8 mb-16"
                error={errorName()}
                required
                label={languageStrings["MENU_ID"]}
                id="deliverectMenuId"
                name="deliverectMenuId"
                value={form.deliverectMenuId || ""}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={handleTitleChange}
                variant="outlined"
                fullWidth
                helperText={errorName() ? error.title : ""}
              />
            </div>
          )}
          {isMenuUrl && (
            <TextField
              className="mt-8 mb-16 mr-8"
              required
              label={languageStrings["MENU_URL"]}
              id="menuUrl"
              name="menuUrl"
              value={form.menuUrl ? form.menuUrl : ""}
              // onlyRead={onlyRead}
              onChange={handleUrlChange}
              variant="outlined"
              fullWidth
              error={urlError.isError}
              helperText={urlError.title}
              inputProps={{
                maxLength: 100,
              }}
            />
          )}
          <div className="w-full flex items-center justify-between">
            <Button
              className="mt-16 mb-16 bg-grey-lightest"
              variant="contained"
              onClick={() => {
                setOpenVisibility(true);
              }}
            >
              <Icon className="mr-8">visibility</Icon>
              {languageStrings[`SET_MENU_VISIBILITY`]}
            </Button>
            <Button
              className="mt-16 mb-16 bg-grey-lightest"
              variant="contained"
              disabled={!canBeSubmitted()}
              onClick={() => {
                const _successMessage =
                  languageStrings["MENU_NEW_MENU_CREATED"];
                form.id !== "0"
                  ? dispatch(editMenuV2(form, isMenuUrl))
                  : dispatch(createMenuV2(form, _successMessage));
              }}
            >
              <Icon className="mr-8">save</Icon>
              {languageStrings[`SAVE_CHANGES`]}
            </Button>
          </div>
        </div>
      )}
      <VisibilityModalV2
        data={form}
        showGlobal={false}
        onVisibilityChange={(item = {}) => {
          dispatch(
            updateMenuVisibilityV2({
              ...item,
              menuId: form._id,
            })
          );
        }}
        onClose={() => {
          setOpenVisibility(false);
        }}
        open={openVisibility}
      />
    </>
  );
}

export default withReducer("MenuReducer", reducer)(MenuDetailForm);
