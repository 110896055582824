import {
  getUserId,
  MERCHANT,
  CLICKED_QR,
  SECRET,
  MERCHANT_UP_SELL_EMAIL,
} from "app/api/Endpoint";
import { MerchantActionTypes } from "app/store/redux/actionTypes";
import { axiosInstance as axios } from "app/api/axios";
import { successAlert } from "app/helpers/alerts";
import History from "@history";
import { STORAGE_KEY, getStorageItem } from "app/helpers/storageHelper";

export const purchaseProduct = (data) => {
  return (dispatch) => {
    dispatch({
      type: MerchantActionTypes.PURCHASE_PRODUCT_START,
    });
    const { stripe, payment_method } = data;

    dispatch(getPaymentSecret(data.quantity))
      .then((res) => {
        stripe
          .confirmCardPayment(res.data.data.secret, {
            payment_method,
          })
          .then(function (response) {
            if (response.error) {
              purchaseProductFail(dispatch, "There was an error connection");
            } else if (
              response.paymentIntent &&
              response.paymentIntent.status === "succeeded"
            ) {
              axios
                .post(MERCHANT, {
                  address: data.address,
                  title: data.title,
                  color: data.color,
                  quantity: data.quantity,
                  userId: getUserId(),
                  chargeId: response.paymentIntent.id,
                })
                .then((res) => {
                  purchaseProductSuccess(dispatch, res.data);
                })
                .catch((err) => {
                  purchaseProductFail(
                    dispatch,
                    "There was an error connection"
                  );
                });
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const purchaseProductFail = (dispatch, errorMessage) => {
  dispatch({
    type: MerchantActionTypes.PURCHASE_PRODUCT_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const purchaseProductSuccess = (dispatch, data) => {
  successAlert(dispatch, data.message);
  History.push("/merchandising");
  dispatch({
    type: MerchantActionTypes.PURCHASE_PRODUCT_SUCCESS,
    payload: data,
  });
};

export const clickedQr = () => {
  return async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.CLICKED_QR_START,
    });

    await axios.patch(CLICKED_QR, {});
  };
};

export const getPaymentSecret = (quantity: number, paymentId?: string) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: MerchantActionTypes.GET_CART_SECRET_START,
      });

      const url = SECRET;
      axios
        .post(url, { quantity, paymentId })
        .then((res) => {
          resolve(res);
          getPaymentSecretSuccess(dispatch, res.data);
        })
        .catch((err) => {
          reject(err);
          getPaymentSecretFail(dispatch, "There was an error connection");
        });
    });
};

const getPaymentSecretFail = (dispatch, errorMessage) => {
  dispatch({
    type: MerchantActionTypes.GET_CART_SECRET_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const getPaymentSecretSuccess = (dispatch, { data }) => {
  dispatch({
    type: MerchantActionTypes.GET_CART_SECRET_SUCCESS,
    payload: { ...data },
  });
};

export const sendUpSellEmail = (upSell, successMessage) => {
  return (dispatch) => {
    dispatch({
      type: MerchantActionTypes.SEND_UP_SELL_EMAIL_START,
    });

    const data = { upSell, userId: getStorageItem(STORAGE_KEY.USER_ID) };
    axios
      .post(MERCHANT_UP_SELL_EMAIL, data)
      .then((res) => {
        sendUpSellEmailSuccess(dispatch, res.data, successMessage);
      })
      .catch((error) => {
        sendUpSellEmailFail(
          dispatch,
          error.response
            ? error.response.data.message
            : "There was an error connection"
        );
      });
  };
};

const sendUpSellEmailFail = (dispatch, errorMessage) => {
  dispatch({
    type: MerchantActionTypes.SEND_UP_SELL_EMAIL_FAIL,
    payload: {
      errorMessage,
    },
  });
};

const sendUpSellEmailSuccess = (dispatch, data, successMessage) => {
  successAlert(dispatch, successMessage);
  dispatch({
    type: MerchantActionTypes.SEND_UP_SELL_EMAIL_SUCCESS,
    payload: data,
  });
};
