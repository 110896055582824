import React, { useEffect, useState } from "react";
import {
  Button,
  Tab,
  Tabs,
  TextField,
  Icon,
  Typography,
  InputLabel,
  Select,
  OutlinedInput,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { FuseAnimate, FusePageCarded } from "@fuse";
import { useForm } from "@fuse/hooks";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "app/store/withReducer";
import reducer from "app/store/venues/VenuesReducer";
import LoadingOverlay from "react-loading-overlay";
import ExtraCategories from "../menuItemV2List/CategoriesItemsV2/ExtraCategoriesV2";
import ExtraCategoryItems from "../menuItemV2List/CategoriesItemsV2/ExtraCategoryItemsV2";

import _ from "@lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { FuseUtils } from "@fuse";
import History from "@history";
import * as Actions from "app/store/actions";
import AlertDialog from "../../common/AlertDialog";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import CustomInput from "app/main/common/CustomInput";
import { formatImageUrl } from "app/helpers/utilsHelper";
import {
  createMenuV2Item,
  editMenuV2Item,
  editMenuV2ItemExtraCategories,
  getMenuItemCategoryV2,
  getMenuItemDetailV2,
  getMenuItemExtraCategoriesV2,
  getMenuItemV2Allergenes,
} from "app/store/menuV2/MenuV2Actions";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

const MenuItemV2Detail = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const categoryList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemCategory
  );
  const allergenesList = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ItemAllergenes
  );
  const loading = useSelector(({ banzzu }) => banzzu.menuV2.loading);
  let menu = useSelector(({ banzzu }) => banzzu.menuV2.menuV2ItemDetail);
  let menuExtras = useSelector(
    ({ banzzu }) => banzzu.menuV2.menuV2ExtraCategories
  );
  const { languageStrings } = useLanguageHelper();
  const { productId, menuId } = props.match.params;

  useEffect(() => {
    if (productId !== "new") {
      dispatch(getMenuItemExtraCategoriesV2(productId));
      dispatch(getMenuItemDetailV2(productId, menuId));
    }
  }, [dispatch, productId, menuId]);

  useEffect(() => {
    dispatch(getMenuItemCategoryV2(menuId));
  }, [dispatch, menuId]);

  useEffect(() => {
    dispatch(getMenuItemV2Allergenes());
  }, [dispatch]);

  if (productId === "new") {
    menu = {
      id: "0",
      title: "",
      price: "",
      description: "",
      allergenes: "",
      idMenu: menuId,
      categoryId: categoryList[0] ? categoryList[0]._id : "0",
    };
  }

  const dirtyInitial = {
    title: false,
    description: false,
  };

  const [tabValue, setTabValue] = useState(0);
  const [dirty, setDirty] = useState(dirtyInitial);
  const [extraTabs, setExtraTabs] = useState([
    <Tab
      className="h-64 normal-case"
      label={languageStrings["MENU_DETAIL_PAGE.CREATE_EXTRA_CATEGORY"]}
    />,
  ]);

  const { form, handleChange, setForm } = useForm(null);
  const { form: initialForm, setForm: setInitialForm } = useForm(null);

  const initialCategory = {
    title: "",
    type: "uniSelect",
    minQuantity: "",
    maxQuantity: "",
    description: "",
    hasMaxMin: true,
    connectedCategory: "",
  };

  const {
    form: categoryForm,
    handleChange: handleCategoryChange,
    setForm: setCategoryForm,
  } = useForm(initialCategory);
  const { form: initialCategoryForm, setForm: initialSetCategoryForm } =
    useForm(initialCategory);

  useEffect(() => {
    const data = [
      <Tab
        className="h-64 normal-case"
        label={languageStrings["MENU_DETAIL_PAGE.CREATE_EXTRA_CATEGORY"]}
      />,
    ];
    // menuExtras.map((extra, index) => {
    //   data.push(
    //     <Tab
    //       className="h-64 normal-case"
    //       label={
    //         <div className="flex items-center justify-between">
    //           {index !== 0 && (
    //             <Icon
    //               onClick={() => {
    //                 let sortDetails = {
    //                   id: extra._id,
    //                   menuItemId: productId,
    //                   action: "decrease",
    //                 };

    //                 dispatch(sortExtraCategoriesV2(sortDetails, productId));
    //               }}
    //               style={{
    //                 marginRight: 10,
    //               }}
    //               color="action"
    //               className="text-20"
    //             >
    //               arrow_back
    //             </Icon>
    //           )}
    //           {extra.title}
    //           {index + 1 !== menuExtras.length && (
    //             <Icon
    //               onClick={() => {
    //                 let sortDetails = {
    //                   id: extra._id,
    //                   menuItemId: productId,
    //                   action: "increase",
    //                 };

    //                 dispatch(sortExtraCategoriesV2(sortDetails, productId));
    //               }}
    //               style={{
    //                 marginLeft: 10,
    //               }}
    //               color="action"
    //               className="text-20"
    //             >
    //               arrow_forward
    //             </Icon>
    //           )}
    //         </div>
    //       }
    //     />
    //   );
    // });
    setExtraTabs(data);
  }, [menuExtras]);

  if (form != null && form.categoryId === "0" && categoryList[0] != null) {
    setForm((form) => ({
      ...form,
      categoryId: categoryList[0]._id,
    }));
  }

  useEffect(() => {
    if ((menu && !form) || (menu && form && menu._id !== form._id)) {
      setForm(menu);
      setInitialForm(menu);
    }
  }, [form, menu, setForm, setInitialForm]);

  const checkUnSavedChanges = () => {
    let initialFormValues = Object.values(initialForm);
    let formValues = Object.values(form);
    let unSavedChanges = false;
    if (initialFormValues.length !== formValues.length) {
      unSavedChanges = true;
    }

    for (let i = 0; i < initialFormValues.length; i++) {
      if (initialFormValues[i] !== formValues[i]) {
        unSavedChanges = true;
      }
    }

    let initialCategoryFormValues = Object.values(initialCategoryForm);
    let categoryFormValues = Object.values(categoryForm);
    if (initialCategoryFormValues.length !== categoryFormValues.length) {
      unSavedChanges = true;
    }

    for (let i = 0; i < initialFormValues.length; i++) {
      if (initialCategoryFormValues[i] !== categoryFormValues[i]) {
        unSavedChanges = true;
      }
    }

    return unSavedChanges;
  };

  const createCategoryHandler = () => {
    let request = {};

    if (categoryForm.type === "uniSelect") {
      request = {
        ...categoryForm,
        hasMaxMin: false,
        menuItem: productId,
      };
    } else {
      request = {
        ...categoryForm,
        menuItem: productId,
      };
    }

    setCategoryForm(initialCategory);
  };

  function handleChangeTab(event, tabValue) {
    setTabValue(tabValue);
  }

  function canBeSubmitted() {
    return hasNoErrors() && hasNoEmpty();
  }

  function categoryCanBeSaved() {
    return (
      categoryIsNoEmpty() &&
      (categoryForm.type !== "uniSelect" && categoryForm.hasMaxMin === true
        ? categoryForm.minQuantity !== "" && categoryForm.maxQuantity !== ""
        : categoryForm.type !== "")
    );
  }

  function categoryIsNoEmpty() {
    return (
      categoryForm.title !== "" &&
      categoryForm.title.length > 3 &&
      categoryForm.type !== ""
    );
  }

  //ERRORS
  function hasNoEmpty() {
    return form.title !== "";
  }

  function hasNoErrors() {
    return !dirty.title && !dirty.description;
  }

  //***   HANDLE CHANGE WHEN IS NEEDED

  function handleUploadChange(e) {
    const initialFile = e.target.files[0];
    if (!initialFile) {
      return;
    }

    FuseUtils.lighterImage(initialFile).then((file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        setForm((form) => ({
          ...form,
          image: `data:${file.type};base64,${btoa(reader.result)}`,
          imageFile: file,
        }));
      };

      reader.onerror = function () {
        console.log("error on load image");
      };
    });
  }

  function handleAllergenesChange(event) {
    let allergenesFormatted;
    if (event.target.checked) {
      const allergenes = `${form.allergenes},${event.target.value},`;
      allergenesFormatted = _.replace(allergenes, ",,", ",");
    } else {
      const allergenes = _.replace(
        form.allergenes,
        `${event.target.value},`,
        ""
      );
      allergenesFormatted = _.replace(allergenes, ",,", ",");

      if (!_.startsWith(allergenesFormatted, ",")) {
        allergenesFormatted = "," + allergenesFormatted;
      }
      if (!_.endsWith(allergenesFormatted, ",")) {
        allergenesFormatted = allergenesFormatted + ",";
      }
    }

    setForm((form) => ({
      ...form,
      allergenes: allergenesFormatted,
    }));
  }

  const goBack = () => {
    History.push({
      pathname: `/menuItemList/${menuId}`,
    });
  };

  function previousPage() {
    if (checkUnSavedChanges()) {
      dispatch(
        Actions.openDialog({
          children: (
            <AlertDialog
              onSuccess={goBack}
              title={languageStrings["GENERAL.ALERT_TITLE"]}
              message={languageStrings["GENERAL.ALERT_DESC"]}
            />
          ),
        })
      );
    } else {
      goBack();
    }
  }

  const deleteMenuItemImage = () => {
    setForm((form) => ({
      ...form,
      image: null,
      imageFile: null,
      deleteImage: true,
    }));
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      <FusePageCarded
        classes={{
          toolbar: "p-0",
          header: "min-h-72 h-72 sm:h-136 sm:min-h-136",
        }}
        header={
          form && (
            <div className="flex flex-1 w-full items-center justify-between">
              <div className="flex flex-col items-start max-w-full">
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button onClick={previousPage}>
                    <Icon className="mr-4 text-20">arrow_back</Icon>
                    {languageStrings["MENU_ITEM_DETAIL_PAGE.PRODUCT_LIST"]}
                  </Button>
                </FuseAnimate>

                <div className="flex items-center max-w-full">
                  <div className="flex flex-col min-w-0">
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography className="text-16 sm:text-20 truncate">
                        {form.id !== "0"
                          ? form.title
                          : languageStrings[
                              "MENU_ITEM_DETAIL_PAGE.NEW_PRODUCT"
                            ]}
                      </Typography>
                    </FuseAnimate>
                    <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                      <Typography variant="caption">
                        {" "}
                        {
                          languageStrings[
                            "MENU_ITEM_DETAIL_PAGE.PRODUCT_DETAIL"
                          ]
                        }
                      </Typography>
                    </FuseAnimate>
                  </div>
                </div>
              </div>

              {tabValue === 0 && (
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    className="whitespace-no-wrap"
                    variant="contained"
                    disabled={!canBeSubmitted()}
                    onClick={() => {
                      form.id !== "0"
                        ? dispatch(editMenuV2Item(form))
                        : dispatch(createMenuV2Item(form));
                      setInitialForm(form);
                    }}
                  >
                    <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                      save
                    </Icon>
                    {languageStrings["GENERAL.SAVE"]}
                  </Button>
                </FuseAnimate>
              )}

              {tabValue === 1 && (
                <FuseAnimate animation="transition.slideRightIn" delay={300}>
                  <Button
                    className="whitespace-no-wrap"
                    variant="contained"
                    disabled={!categoryCanBeSaved()}
                    onClick={createCategoryHandler}
                  >
                    <Icon className={clsx(classes.leftIcon, classes.iconSmall)}>
                      add
                    </Icon>
                    {languageStrings["MENU_DETAIL_PAGE.CREATE_CATEGORY"]}
                  </Button>
                </FuseAnimate>
              )}

              {tabValue > 1 && (
                <div className="flex">
                  <FuseAnimate animation="transition.slideRightIn" delay={300}>
                    <>
                      <Button
                        className="whitespace-no-wrap mr-5 color-red"
                        variant="contained"
                        onClick={() => {
                          setTabValue(0);
                        }}
                      >
                        <Icon
                          className={clsx(classes.leftIcon, classes.iconSmall)}
                        >
                          delete
                        </Icon>
                        {languageStrings["MENU_DETAIL_PAGE.DELETE_CATEGORY"]}
                      </Button>

                      <Button
                        className="whitespace-no-wrap"
                        variant="contained"
                        disabled={!categoryCanBeSaved()}
                        onClick={() =>
                          dispatch(
                            editMenuV2ItemExtraCategories(
                              categoryForm,
                              productId
                            )
                          )
                        }
                      >
                        <Icon
                          className={clsx(classes.leftIcon, classes.iconSmall)}
                        >
                          save
                        </Icon>
                        {languageStrings["MENU_DETAIL_PAGE.UPDATE_CATEGORY"]}
                      </Button>
                    </>
                  </FuseAnimate>
                </div>
              )}
            </div>
          )
        }
        contentToolbar={
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: "w-full h-64" }}
          >
            <Tab
              className="h-64 normal-case"
              label={languageStrings["GENERAL.BASIC_INFO"]}
            />
            {productId !== "new" && extraTabs.map((extraTab) => extraTab)}
          </Tabs>
        }
        content={
          form && (
            <div className="p-16 sm:p-24 max-w-2xl">
              {tabValue === 0 && (
                <div>
                  <input
                    accept="image/jpg, image/jpeg, image/png, image/gif"
                    className="hidden"
                    id="button-file"
                    type="file"
                    onChange={handleUploadChange}
                  />
                  <div className="flex justify-center sm:justify-start flex-wrap">
                    <label
                      htmlFor="button-file"
                      className="flex items-center justify-center relative w-128 h-128 rounded-4 mr-16 mb-16 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
                    >
                      <Icon fontSize="large" color="action">
                        cloud_upload
                      </Icon>
                    </label>
                    {
                      <div className="flex items-center min-w-128 h-128 max-w-256 justify-center relative rounded-4 mr-16 mb-16 overflow-hidden shadow-1">
                        <img
                          className="max-w-none w-auto h-full"
                          alt="whatson"
                          src={
                            form.image
                              ? formatImageUrl(form.image)
                              : "assets/images/avatars/noimage.png"
                          }
                        />
                        {form.image && (
                          <Icon
                            onClick={deleteMenuItemImage}
                            className="rounded-4 shadow-5 mb-2 text-2xl cursor-pointer absolute bottom-0"
                            style={{
                              color: "#f4516c",
                              backgroundColor: "#fff",
                            }}
                          >
                            delete
                          </Icon>
                        )}
                      </div>
                    }
                  </div>
                  <div className="flex">
                    <CustomInput
                      className="mt-8 mb-16 mr-8"
                      required
                      label={languageStrings["GENERAL.TITLE"]}
                      id="title"
                      name="title"
                      value={form.title}
                      onValueChange={handleChange}
                      errorMessage={languageStrings["ERRORS.MINIMUM_3_CHAR"]}
                      hasError={(error) =>
                        setDirty((prevState) => {
                          return { ...prevState, title: error };
                        })
                      }
                      minLength={3}
                    />

                    <TextField
                      className="mt-8 ml-8 mb-16"
                      label={languageStrings["GENERAL.PRICE"]}
                      id="price"
                      name="price"
                      type={"number"}
                      value={form.price}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-venue-native-simple">
                        {
                          languageStrings[
                            "MENU_ITEM_DETAIL_PAGE.SELECT_CATEGORY"
                          ]
                        }
                      </InputLabel>
                      <Select
                        className="flex mt-8 mb-16 ml-8 min-w-128"
                        native
                        value={form.categoryId}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            name="categoryId"
                            labelWidth={100}
                            id="outlined-venue-native-simple"
                          />
                        }
                      >
                        {categoryList &&
                          categoryList.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.title}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="mt-8 ml-8 mb-8 font-bold">
                    {languageStrings["ALLERGENE.TITLE"]}
                  </div>

                  <FormGroup className="ml-8" row>
                    {allergenesList &&
                      allergenesList.map((item) => (
                        <FormControlLabel
                          key={item.title}
                          control={
                            <Checkbox
                              checked={
                                form.allergenes
                                  ? form.allergenes.includes(`,${item._id},`)
                                  : false
                              }
                              onChange={handleAllergenesChange}
                              value={item._id}
                            />
                          }
                          label={item.title}
                        />
                      ))}
                  </FormGroup>

                  <CustomInput
                    className="mt-8 mb-16 mr-8"
                    label={languageStrings["GENERAL.DESCRIPTION"]}
                    id="description"
                    name="description"
                    maxLength={1000}
                    value={form.description}
                    onValueChange={handleChange}
                    errorMessage={languageStrings["ERRORS.MINIMUM_10_CHAR"]}
                    hasError={(error) => error}
                  />
                </div>
              )}
              {tabValue === 1 && (
                <ExtraCategories
                  menu={initialCategory}
                  handleCategoryChange={handleCategoryChange}
                  setCategoryForm={setCategoryForm}
                  categoryForm={categoryForm}
                  setInitialForm={initialSetCategoryForm}
                />
              )}
              {menuExtras.map((tab, i) => {
                if (tabValue === 2 + i) {
                  return (
                    <React.Fragment key={menuExtras[i]._id}>
                      <ExtraCategories
                        menu={menuExtras[i]}
                        handleCategoryChange={handleCategoryChange}
                        setCategoryForm={setCategoryForm}
                        categoryForm={categoryForm}
                        setInitialForm={initialSetCategoryForm}
                      />
                      <ExtraCategoryItems
                        category={menuExtras[i] && menuExtras[i]._id}
                      />
                    </React.Fragment>
                  );
                }
              })}
            </div>
          )
        }
        innerScroll
      />
    </LoadingOverlay>
  );
};

export default withReducer("MenuReducer", reducer)(MenuItemV2Detail);
