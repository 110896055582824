import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import Confetti from "react-confetti";
import { connect } from "react-redux";

import history from "@history";
import { FusePageSimple } from "@fuse";
import { useLanguageHelper } from "app/helpers/LanguageHelper/index";
import OrdersNotCompletedCard from "./components/OrdersNotCompletedCard";
import {
  getPermaLinks,
  getUserNumber,
  previewWeb,
} from "app/store/user/UserActions";
import { registerToken, getUserInfo } from "app/store/auth/LoginActions";
import { SUBSCRIPTION } from "app/models/User";
import { askForPermissionToReceiveNotifications } from "app/helpers/push-notifications";
import RegisterStepsPage from "../register/components/RegisterStepsPage";
import LinearProgressBar from "app/main/common/LinearProgressBar";
import GoalButton from "app/main/common/GoalButton";
import { getMenuList } from "app/store/menu/MenuActions";
import { getVenueList } from "app/store/venues/VenuesActions";
import QrCode from "./components/QrCode";
import { clientsSettings } from "config";

const styles = (theme) => ({
  layoutRoot: {},
});

const HomePage = (props) => {
  const {
    classes,
    iosUsers,
    androidUsers,
    getUserNumber,
    registerToken,
    getUserInfo,
    previewWeb,
    getMenuList,
    menuList,
    getVenueList,
    venueList,
    user,
    subscribed,
    venueloading,
    loading,
    getPermaLinks,
    permaLinks,
  } = props;

  const { languageStrings } = useLanguageHelper();

  const [progress, setProgress] = React.useState(0);
  const [completedGoalsHidden, setCompletedGoalsHidden] = React.useState(0);
  useEffect(() => {
    getPermaLinks();
  }, []);
  useEffect(() => {
    if (user && user.goals && Object.values(user.goals).length > 0) {
      const goals = Object.values(user.goals);

      let activeGoalsLength = goals.filter((goal) => goal).length;
      let length = user.accessLevel !== "1" ? goals.length : goals.length - 9;
      let progress = (activeGoalsLength / length) * 100;

      setProgress(progress);
    }
  }, [user]);

  useEffect(() => {
    let getToken = async () => {
      let token = await askForPermissionToReceiveNotifications();
      registerToken(token);
    };
    getToken();

    getUserNumber();
    getUserInfo();
    getMenuList();
    getVenueList();
  }, []);

  const redirectToMenu = () => {
    const menuId = menuList[0] && menuList[0]._id;
    let url =
      process.env.NODE_ENV === "production"
        ? `menuDetailPro/${menuId}`
        : `menuDetailPro/${menuId}`;
    history.push(url);
  };
  const redirectToSettings = (stripe) => {
    let url = "payment-settings";

    if (stripe) {
      url += "/1";
    }

    history.push(url);
  };

  const redirectToAvailability = (tab) => {
    const venueId = venueList[0] && venueList[0]._id;
    history.push(`venueDetail/${venueId}/${tab}`);
  };

  const getGoals = () => {
    const {
      addRestaurantLogo,
      addCustomColor,
      addCustomHeader,
      previewWeb: webPreviewed,
      addFirstCategory,
      addFirstMenu,
      addProductPhoto,
      addSecondCategory,
      addAllergens,
      addExtraOption,
      addStripeKey,
      activateOrderFromTable,
      activateDelivery,
      activatePickUp,
      createFirstTable,
      receiveFirstOrder,
      addPickupAvailability,
      addDeliveryAvailability,
      addDeliveryArea,
    } = user.goals;

    const { permalink } = user.settings;

    return [
      {
        title: languageStrings["HOME_PAGE_GOALS.RESTAURANT_LOGO"],
        active: addRestaurantLogo,
        onClick: () => history.push("branding"),
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.CUSTOMIZE_COLOR"],
        active: addCustomColor,
        onClick: () => history.push("branding"),
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.CUSTOMIZE_HEADER"],
        active: addCustomHeader,
        onClick: () => history.push("branding"),
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.PREVIEW_WEB"],
        active: webPreviewed,
        onClick: () => {
          previewWeb();
          window.open(
            process.env.NODE_ENV === "production"
              ? `https://web.banzzu.com/${permalink}`
              : `http://localhost:3001/${permalink}`
          );
          getUserInfo();
        },
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.CREATE_FIRST_CATEGORY"],
        active: addFirstCategory,
        onClick: redirectToMenu,
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.UPLOAD_FIRST_MENU"],
        active: addFirstMenu,
        onClick: redirectToMenu,
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ADD_ALLERGENS"],
        active: addAllergens,
        onClick: redirectToMenu,
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.UPLOAD_PRODUCT_PHOTOS"],
        active: addProductPhoto,
        onClick: redirectToMenu,
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ADD_EXTRA_OPTIONS"],
        active: addExtraOption,
        onClick: redirectToMenu,
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.CREATE_SECOND_CATEGORY"],
        active: addSecondCategory,
        onClick: redirectToMenu,
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ADD_STRIPE_KEY"],
        active: addStripeKey,
        onClick: () => redirectToSettings(true),
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ACTIVATE_ORDER_FROM_TABLE"],
        active: activateOrderFromTable,
        onClick: redirectToSettings,
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ACTIVATE_DELIVERY"],
        active: activateDelivery,
        onClick: redirectToSettings,
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ACTIVATE_PICK_UP"],
        active: activatePickUp,
        onClick: redirectToSettings,
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.CREATE_FIRST_TABLE"],
        active: createFirstTable,
        onClick: () => history.push("orderFromTable"),
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.RECEIVE_FIRST_ORDER"],
        active: receiveFirstOrder,
        onClick: redirectToMenu,
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ADD_PICKUP_AVAILABILITY"],
        active: addPickupAvailability,
        onClick: () => redirectToAvailability(4),
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ADD_DELIVERY_AVAILABILITY"],
        active: addDeliveryAvailability,
        onClick: () => redirectToAvailability(3),
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
      {
        title: languageStrings["HOME_PAGE_GOALS.ADD_DELIVERY_AREA"],
        active: addDeliveryArea,
        onClick: () => redirectToAvailability(3),
        permission: [SUBSCRIPTION.PRO, SUBSCRIPTION.PREMIUM],
      },
    ];
  };

  return (
    <LoadingOverlay
      active={loading || venueloading}
      spinner
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: loading ? "hidden" : "scroll",
        },
      }}
      text="Loading..."
    >
      {user.settings ? (
        !user.settings.configured ? (
          <div className="bg-gray-200 h-full">
            <RegisterStepsPage />
          </div>
        ) : (
          <FusePageSimple
            classes={{ root: classes.layoutRoot }}
            // header={
            //   // <div className="p-24">
            //   //   <h4>Header</h4>
            //   // </div>
            // }
            content={
              <>
                {subscribed && (
                  <Confetti
                    style={{ position: "absolute" }}
                    width={window.innerWidth}
                    height={window.innerHeight}
                  />
                )}
                <div className="p-24">
                  {user.permission.isMaster && user.goals && (
                    <div className="md:w-5/6 pt-32">
                      {clientsSettings.showProgress && (
                        <h4 className="mt-12 mb-12">
                          {languageStrings["HOME_PAGE.PROGRESS_TITLE"]}
                        </h4>
                      )}
                      {clientsSettings.showProgress && (
                        <Typography variant="body1">
                          {languageStrings["HOME_PAGE.PROGRESS_DESCRIPTION"]}
                        </Typography>
                      )}
                      {clientsSettings.showGoalsBar && (
                        <div className="pt-24 pb-32">
                          <LinearProgressBar progress={progress} />
                          <Typography
                            variant="body1"
                            className="cursor-pointer text-right w-full pt-8"
                            style={{ color: "#ff8330" }}
                            onClick={() =>
                              setCompletedGoalsHidden(!completedGoalsHidden)
                            }
                          >
                            {!completedGoalsHidden
                              ? languageStrings["HOME_PAGE.HIDE_TEXT"]
                              : languageStrings["HOME_PAGE.SHOW_TEXT"]}
                          </Typography>
                        </div>
                      )}

                      {clientsSettings.showGoals && (
                        <div className="flex flex-wrap">
                          {getGoals().map((goal) => {
                            if (
                              (completedGoalsHidden && goal.active) ||
                              (goal.permission &&
                                !goal.permission.includes(user.accessLevel))
                            ) {
                              return null;
                            }
                            return <GoalButton {...goal} />;
                          })}
                        </div>
                      )}
                    </div>
                  )}
                  {clientsSettings.showQRCode && (
                    <QrCode user={user} permaLinks={permaLinks} />
                  )}
                  <br />
                  {user.accessLevel !== "1" && (
                    <>
                      {clientsSettings.showAnalyticTitle && (
                        <h4> {languageStrings["HOME_PAGE.ANALYTICS"]}</h4>
                      )}
                      <br />
                      <div className="flex flex-col sm:flex sm:flex-row pb-32">
                        <OrdersNotCompletedCard />
                      </div>
                    </>
                  )}
                </div>
              </>
            }
          />
        )
      ) : null}
    </LoadingOverlay>
  );
};

const mapStateToProps = ({ banzzu }) => {
  const { androidUsers, iosUsers, permaLinks } = banzzu.user;
  const { menuList } = banzzu.menu;
  const { loading: venueLoading, venueList } = banzzu.venue;

  return {
    androidUsers,
    iosUsers,
    user: banzzu.auth.user,
    menuList,
    venueLoading,
    venueList,
    subscribed: banzzu.payment.subscribed,
    loading: banzzu.auth.loading,
    permaLinks,
  };
};
const mapDispatchToProps = {
  getUserNumber,
  registerToken,
  previewWeb,
  getUserInfo,
  getMenuList,
  getVenueList,
  getPermaLinks,
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
